import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import api from '../lib/api';
import global from '../lib/global';
import format from './format';
import action from './action';
import uiControls from './ui';
import data from './data';

import { GlobalNavControl } from '../lib/global';
import { Button } from '@material-ui/core';
import { globalPushUserFormModal, globalPushAddUserFormModal } from './user.form';

import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper } from '@material-ui/core';

import MdDelete from '@material-ui/icons/DeleteForever';
import MdEdit from '@material-ui/icons/Edit';
import MdPassword from '@material-ui/icons/Lock';

const Child = ({ autoSize = true }) => {
  const { t } = useTranslation();

  const uiState = observable({
    data: null,
  });

  const setData = (data = []) => uiState.data = data;

  const onFetchDataAsyncHandler = async ({ filter, sort, skip, limit, own }) => {
    const result = await api.queryUsers({ own: own, filter: filter, sort: sort, skip: skip, limit: limit });

    return result;
  };

  const onDataHandler = (data) => {
    setData([ ...data.data ]);
  };

  const AuxActions = ({ user }) => {
    const list = [];

    const handleRemoveFromOrgUnit = async () => {
      api.confirm('deleteOrgUnitUserByIds', null, async result => {
        if (!result) return;

        const response = await api.deleteOrgUnitUserByIds(global.user.orgUnit._id, user.body._id);

        if (response.success) {
          api.reload();
        }
      });
    };

    const handleChangePassword = (user) => {
      api.prompt('newPassword', {}, async (password) => {
        if (!password) return;

        const response = await api.updateUserPasswordById(user.body._id, password);

        if (response.success) {
          api.alert('passwordChangeSuccess', {}, () => {});
        }
      });
    };

    if (api.isPlatformAdmin()) {
      list.push(<action.Action mode="icon" action="setPassword" onClick={() => handleChangePassword(user)}>
        <MdPassword />
      </action.Action>);

      list.push(<action.Action mode="icon" action="edit" onClick={() => globalPushUserFormModal(user)}>
        <MdEdit />
      </action.Action>);
    }

    if (api.isOrgAdmin()) {
      const isMyUser = user.body._id === global.user.user._id;

      list.push(<action.Action mode="icon" action="delete" onClick={handleRemoveFromOrgUnit} disabled={isMyUser}>
        <MdDelete />
      </action.Action>);
    }

    return <>
      {list.map((i, index) => <React.Fragment key={index}>{i}</React.Fragment>)}
    </>;
  };

  const Actions = ({ user }) => {
    const list = [];
    
    return <span className="nowrap">
      {list.map((i, index) => <React.Fragment key={index}>{i}</React.Fragment>)}
    </span>;
  };

  const getFilterOptions = () => {
    const options = [];

    options.push({
      value: {},
      label: i18n.t('common:actions.showAllRecords.label'),
    });

    options.push({
      value: {
        filter: {
          enabled: true,
        },
      },
      label: i18n.t(`common:states.user.enabled`),
    });

    options.push({
      value: {
        filter: {
          enabled: false,
        },
      },
      label: i18n.t(`common:states.user.disabled`),
    });

    if (api.isPlatformAdmin()) {
      options.push({
        value: {
          filter: {
            isPlatformAdmin: true,
          },
        },
        label: i18n.t(`common:states.user.platformAdmin`),
      });
    }

    return options;
  };

  const getSortOptions = () => {
    const options = [];

    options.push({
      label: i18n.t('common:sort.user.name'),
      value: {
        sort: { name: 'asc' }
      }
    });

    options.push({
      label: i18n.t('common:sort.user.email'),
      value: {
        sort: { email: 'asc' }
      }
    });

    options.push({
      label: i18n.t('common:sort.user.updatedAt'),
      value: {
        sort: { updatedAt: 'desc' }
      }
    });

    return options;
  };

  return <uiControls.MainContentContainer autoSize={autoSize}>
    <uiControls.TableContainer
      autoSize={autoSize}
      header={<>
        <data.SimpleDataToolbar
          onFetchDataAsync={onFetchDataAsyncHandler}
          onData={onDataHandler}
          sortOptions={getSortOptions()}
          filterOptions={getFilterOptions()}
        />
      </>}
    >
      <Observer>{() => {
        if (uiState.data === null) return <uiControls.TableSkeleton />;
        if (uiState.data.length === 0) return <uiControls.EmptyTable />;

        const showOrgUnits = api.isPlatformAdmin();
        const showRole = !api.isPlatformAdmin();

        return (
          <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('common:fields:user.email')}</TableCell>
            <TableCell>{t('common:fields:user.name')}</TableCell>
            <TableCell className="text-center">{t('common:fields:user.updatedAt')}</TableCell>
            { showRole && <TableCell className="text-center">{t('common:fields:orgUnit.role')}</TableCell> }
            { showOrgUnits && <TableCell className="text-center">{t('common:fields:user.orgUnits')}</TableCell> }
            <TableCell className="text-center">{t('common:fields:user.enabled')}</TableCell>
            <TableCell className="text-center">{t('common:fields:user.isPlatformAdmin')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(uiState.data || []).map((dataItem, index) => <TableRow  key={index}>
            <TableCell>
              <action.Cell label={dataItem.body.email}>
                <Actions user={dataItem} />
              </action.Cell>
            </TableCell>
            <TableCell>{dataItem.body.name}</TableCell>
            { showRole && <TableCell className="text-center">
              { (dataItem.body.orgUnits[0] || { role: {} }).role.name }
            </TableCell> }
            <TableCell className="text-center">
              <format.DateText value={dataItem.body.updatedAt} />
            </TableCell>
            { showOrgUnits && <TableCell className="text-center">
              <action.UserOrgUnitsDetails value={dataItem} />
            </TableCell> }
            <TableCell className="text-center">
              <format.BooleanString value={dataItem.body.enabled} />
            </TableCell>
            <TableCell className="text-center">
              <format.BooleanString value={dataItem.body.isPlatformAdmin} />
            </TableCell>
            <TableCell className="text-right nowrap"><AuxActions user={dataItem} /></TableCell>
          </TableRow >)}
        </TableBody>
      </Table>
        );
      }}</Observer>
    </uiControls.TableContainer>
    <GlobalNavControl>
      <action.Action
        variant="contained"
        mode="button"
        action="createNewUser"
        color="secondary"
        onClick={globalPushAddUserFormModal}
        id="global-nav-create-user"
      >
        {t('common:navControls.newUser.label')}
      </action.Action>
    </GlobalNavControl>
  </uiControls.MainContentContainer>;
};

const Main = ({ autoSize = true }) => {
  return <Child autoSize={autoSize} />;
};

export default Main;
