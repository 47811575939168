import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import input from '../input';
import { v1 as uuid } from 'uuid';

import { Form, Container, Row, Col } from 'react-bootstrap';

import {
  Button, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio,
  Box, Tooltip,
} from '@material-ui/core';

import containerIcon from '../../assets/icons/container.svg';
import palletIcon from '../../assets/icons/pallet.svg';
import hazmatClassesList from '../../assets/icons/hazmat';

import format from '../format';
import action from '../action';
import val from '../validation';

import { globalPushModal, globalPopModal } from '../../lib/global';

import container_DVHC from '../../assets/containers/DVHC.svg';
import container_FL from '../../assets/containers/FL.svg';
import container_RF from '../../assets/containers/RF.svg';
import container_OT from '../../assets/containers/OT.svg';
import container_45 from '../../assets/containers/45.svg';

const hazmatClassesMap = hazmatClassesList.reduce((acc, curr) => {
  acc[curr.class] = curr;
  return acc;
}, {});

export const ShipmentContentControl = ({ field, value, transportMode, readOnly, onChange }) => {
  const [ state, setState ] = React.useState(value || []);

  const startEdit = index => {
    let edit = { index: index, value: index !== null ? { ...state[index] } : null };

    console.log('startEdit: index: ', index, ' edit: ', edit);

    const handleEditChange = val => {
      edit.value = val;
    };
    
    const saveChanges = async () => {
      const val = edit.value;

      if (edit.index !== null) {
        state[edit.index] = val;
      } else {
        state.push(val);
      }


      const data = [ ...state ];
      console.log('saveChanges: ', state, data);

      setState([ ...state ]);
  
      if (onChange) onChange([ ...state ]);
    };
  
    globalPushModal({
      title: 'Shipment Item',
      body: <>
        <val.Container field={field} index={edit.index}>
          <PackageControl value={edit.value} transportMode={transportMode} onChange={handleEditChange} />
        </val.Container>
      </>,
      footer: <action.ModalButtons onAsyncSave={saveChanges} />,
      options: {
        large: false
      }
    });
  };

  const startAddNew = () => {
    startEdit(null);
  };

  const remove = index => {
    const temp = state.filter((item, itemIndex) => index !== itemIndex);

    setState([ ...temp ]);

    if (onChange) onChange([ ...temp ]);
  };

  const flexStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  return <>
    <div>
      <Container fluid>
        { state.map((i, index) => <React.Fragment key={index}>
          <Row className="list-item justify-content-md-center">
            <Col style={{ paddingLeft: 0, ...flexStyle }} size="auto">
              <val.Container field={field} index={index}>
                  <val.Frame softError={true}>
                    <PackageCompactView value={i} />
                  </val.Frame>
              </val.Container>
            </Col>
            { readOnly ? <></> : <>
              <Col style={{ paddingRight: 0, ...flexStyle }} lg="auto">
                <Button variant="contained" color="secondary" onClick={() => startEdit(index)}>Edit Shipment Item</Button>
              </Col>
              <Col style={{ paddingRight: 0, ...flexStyle }} lg="auto">
                <Button onClick={() => remove(index)}>X</Button>
              </Col>
            </>}
          </Row>
        </React.Fragment>)}
      </Container>
    </div>
    { readOnly ? <></> : <>
      <div>
        <Button variant="contained" color="secondary" onClick={startAddNew}>+ Add Shipment Item</Button>
      </div>
    </> }
  </>;
};

export const PackageCompactView = ({ value }) => {
  if (!value) return 'N/A';

  const F = ({ label, value, danger = null, last }) => {
    if (!value || value.toString() === '') return <></>;

    const style = {
      fontWeight: 'bold',
    };

    if (danger) {
      style.color = 'red';
    }

    return <>
      {label}:
      {' '}
      <span style={style}>{value}</span>
      {last ? '' : ', '}
    </>;
  };

  const dims = () => {
    const p = value.physicalProps;

    console.log(p);
    const volume = p ? Math.max(0.01, ((p.width / 100) * (p.height / 100) * (p.depth / 100)).toFixed(2)) : NaN;
    const weight = p ? Math.max(0.01, Number(p.weight)).toFixed(2) : NaN;
    
    console.log('weight: ', weight);
    return <>
      {format.NumericString({ value: volume * value.quantity, feedback: true })} CBM / {format.NumericString({ value: weight * value.quantity, feedback: true })} Kg
    </>;
  };

  const physicalProps = () => {
    const p = value.physicalProps;

    if (!p) return null;

    return <>
      {format.NumericString({ value: p.width, feedback: true })} x{' '}
      {format.NumericString({ value: p.depth, feedback: true })} x{' '}
      {format.NumericString({ value: p.height, feedback: true })} cm
    </>;
  };

  const title = value.type === 'container'
    ? <>Container ({value.containerType})</>
    : <>Pallets (Compound {dims()})</>;

  const haz = value.hazardousMaterial || {};

  const fields = [
    { label: i18n.t('common:fields.shipmentContent.quantity'), value: value.quantity },
    { label: i18n.t('common:fields.shipmentContent.physicalProps@summary'), value: physicalProps() },
    { label: i18n.t('common:fields.physicalProps.weight'), value: value.type === 'pallet' ? value.physicalProps.weight + ' Kg' : null },
    { label: i18n.t('common:fields.shipmentContent.hsCode'), value: value.hsCode },
    { label: i18n.t('common:fields.shipmentContent.comments'), value: value.comments },
    { label: i18n.t('common:fields.shipmentContent.hazardousMaterial'), value: value.hazardousMaterial ? i18n.t('common:fields.shipmentContent.hazardousMaterial') : 'None' },
    { label: i18n.t('common:fields.hazardousMaterial.unNumber'), value: haz.unNumber },
    { label: i18n.t('common:fields.hazardousMaterial.class'), value: value.hazardousMaterial ? <HazardousMaterialSelect value={haz.class} readOnly={true} /> : null },
    { label: i18n.t('common:fields.hazardousMaterial.MSDS'), value: value.hazardousMaterial ? (haz.MSDS ? <input.Attachment value={haz.MSDS} readOnly={true} /> : '???') : null, danger: !haz.MSDS },
  ].filter(i => i.value && i.value.toString() !== '');

  const style = {
  };

  return <>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div className="icon-container">
        <img className="medium-icon" style={{ margin: '1em' }} src={value.type === 'container' ? containerIcon : palletIcon } alt={title} />
      </div>
      <div className="block-vsep-50" style={{ display: 'flex', width: '100%' }}>
        <div>
          <div>
            <h5>{title}</h5>
          </div>
          <div>
            <div style={style}>
              {fields.map((i, index) => <F key={index} label={i.label} value={i.value} danger={i.danger} last={index + 1 >= fields.length} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export const PackageControl = ({ value, transportMode, readOnly = false, onChange }) => {
  const nullValue = {
    type: 'pallet',
    containerType: null,
    physicalProps: null,
    quantity: 1,
    comments: '',
    hsCode: '',
    hazardousMaterial: null,
  };

  let [ state, setState ] = React.useState(value);

  if (!state) {
    state = { ...nullValue };
    if (onChange) onChange({ ...nullValue });
  }

  const [ packageType, setPackageType ] = React.useState(state.type);

  const set = (key) => {
    return (val) => {
      state[key] = val;

      if (key === 'type') {
        if (val === 'container') {
          state.physicalProps = null;
          state.containerType = '40DV';
        } else if (val === 'pallet') {
          state.containerType = null;
          state.physicalProps = {};
        }

        setPackageType(val);
        setState({ ...state });
      }

      console.log('set: ', key, val);

      if (onChange) onChange(state);
    };
  };

  return <>
    <input.Field label="Package Item">
      <Box mt={1}>
        <input.PositiveIntegerNumber field="quantity" readOnly={readOnly} label="Quantity" value={state.quantity} onChange={set('quantity')} />
        <PackageType label="Item Type" field="type" value={packageType} transportMode={transportMode} onChange={set('type')} readOnly={readOnly} />
        { packageType === 'pallet' ? <>
          <PhysicalProps label="Physical Properties" field="physicalProps" value={state.physicalProps} onChange={set('physicalProps')} readOnly={readOnly} />
        </> : <></>}
        { state.type === 'container' ? <>
          <ContainerType label="Container Type" field="containerType" value={state.containerType} onChange={set('containerType')} readOnly={readOnly} />
        </> : <></>}
        <input.HSCode readOnly={readOnly} field="hsCode" label="HS Code" value={state.hsCode} onChange={set('hsCode')} />
        <input.Text readOnly={readOnly} label={i18n.t('common:fields.shipmentContent.comments')} /*field="comments"*/ type="textarea" value={state.comments} onChange={set('comments')} required />
        <Observer>{() => <>
          <HazardousMaterial type={state.type} field="hazardousMaterial" label="Hazardous Material" value={state.hazardousMaterial} onChange={set('hazardousMaterial')} readOnly={readOnly} />
        </>}</Observer>
      </Box>
    </input.Field>
  </>;
};

const PhysicalProps = ({ field, value, label, readOnly = false, onChange }) => {
  const [ state ] = React.useState(value || { width: 1, height: 1, depth: 1, weight: 1 });

  if (!value && onChange) {
    onChange(state);
  }

  const set = (key) => {
    return (val) => {
      state[key] = Math.max(1, Number(val));

      if (onChange) onChange(state);
    };
  };

  return <>
    <input.Field label={label}>
      <val.Container field={field}>
        <Container fluid>
          <Row>
            <Col style={{ paddingLeft: 0 }}>
              <input.PositiveIntegerNumber field="width" readOnly={readOnly} label="Width (cm)" value={state.width??1} onChange={set('width')} required />
            </Col>
            <Col>
              <input.PositiveIntegerNumber field="depth" readOnly={readOnly} label="Depth (cm)" value={state.depth??1} onChange={set('depth')} required />
            </Col>
            <Col>
              <input.PositiveIntegerNumber field="height" readOnly={readOnly} label="Height (cm)" value={state.height??1} onChange={set('height')} required />
            </Col>
            <Col style={{ paddingRight: 0 }}>
              <input.PositiveIntegerNumber field="weight" readOnly={readOnly} label="Weight (Kg)" value={state.weight??1} onChange={set('weight')} required />
            </Col>
          </Row>
        </Container>
      </val.Container>
    </input.Field>
  </>;
};

const ContainerType = ({ field, value, label, readOnly = false, onChange }) => {
  const [ id ] = React.useState(uuid());
  const [ state, setState ] = React.useState(value || '40DV');

  const handleChange = (e) => {
    setState(e.target.value);

    onChange(e.target.value);
  };

  const containerTypes = [ '40DV', '40HC', '40FL', '40OT', '40RF', '45', '20FL', '20OT', '20RF', '20DV' ];
  const containerIcons = [
    container_DVHC, container_DVHC, container_FL, container_OT,
    container_RF, container_45, container_FL, container_OT,
    container_RF, container_DVHC,
  ];

  return <>
    <input.Field>
      { readOnly ? (value || 'N/A') : <>
        <val.Context field={field}>{({ error, errorText }) => {
          return <>
            { label && <FormLabel component="label">{label}</FormLabel> }
            <RadioGroup value={state} onChange={handleChange} readOnly={readOnly} disabled={readOnly} row>
              { containerTypes.map((i, index) => {
                return <FormControlLabel
                  value={i} control={<Radio />} label={<>
                    <div>
                      <Tooltip title={
                        <Box align="center" p={1}>
                          <p>
                            <img src={containerIcons[index]} style={{ height: '10em' }} />
                          </p>

                          {i18n.t(`common:containerType.${i}`)}
                        </Box>
                      }>
                        <div><img src={containerIcons[index]} className="icon" /> {i}</div>
                      </Tooltip>
                    </div>
                  </>} readOnly={readOnly} disabled={readOnly}
                />
              }) }
            </RadioGroup>
          </>;
        }}</val.Context>
      </> }
    </input.Field>
  </>;
};

const PackageType = ({ field, label, value, transportMode, readOnly = false, onChange }) => {
  const [ id ] = React.useState(uuid());

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return <>
    <input.Field>
      { readOnly ? (value || 'N/A') : <>
        <val.Context field={field}>{({ error, errorText }) => {
          return <>
            { label && <FormLabel component="label">{label}</FormLabel> }
            <RadioGroup value={value} onChange={handleChange} readOnly={readOnly} disabled={readOnly} row>
              <FormControlLabel value="pallet" control={<Radio />} label={<format.Label text="Pallet" icon={palletIcon} />} readOnly={readOnly} disabled={readOnly} />
              { transportMode !== 'air' && <FormControlLabel value="container" control={<Radio />} label={<format.Label text="Container" icon={containerIcon} />} readOnly={readOnly} disabled={readOnly || transportMode === 'air'} /> }
            </RadioGroup>
          </>;
        }}</val.Context>
      </>}
    </input.Field>
  </>;
};

const HazardousMaterial = ({ field, type, value, readOnly = false, onChange }) => {
  const [ id ] = React.useState(uuid());
  const [ state, setState ] = React.useState(value || null);

  const emptyState = {
    unNumber: null,
    class: null,
    MSDS: null,
  };

  const classChange = (val) => {
    if (!val) {
      setState(null);
      if (onChange) onChange(null);
    } else {
      if (!state) {
        const data = { ...emptyState, class: val };
        setState(data);
        if (onChange) onChange(data);
      } else {
        const data = { ...state, class: val };
        setState(data);
        if (onChange) onChange(data);
      }
    }
  };

  const set = (key) => {
    return (val) => {
      state[key] = val;

      //setState({ ...state });

      if (onChange) onChange(state);
    };
  };

  return <>
    <input.Field label={i18n.t('common:fields.shipmentContent.hazardousMaterial')}>
      <HazardousMaterialSelect field="hazardousMaterial" value={(state || {}).class} onChange={classChange} />
    </input.Field>
    { state !== null ? <>
      <val.Container field={field}>
        <input.Text max={20} field="unNumber" label={i18n.t('common:fields.hazardousMaterial.unNumber')} value={state.unNumber} onChange={set('unNumber')} readOnly={readOnly} required />
        <input.Field field="MSDS" label={i18n.t('common:fields.hazardousMaterial.MSDS')}>
          <input.Attachment value={state.MSDS} onChange={set('MSDS')} readOnly={readOnly} />
        </input.Field>
      </val.Container>
    </> : <></>}
  </>;
};

export const HazardousMaterialIcon = ({ value, showAlways }) => {
  const hazmat = hazmatClassesMap[value];

  if (!hazmat) return showAlways ? <>
    <div style={{ display: 'inline-block', width: '1.5em', height: '1.5em' }} />
  </> : <></>;

  return <>
    <img src={hazmat.icon} style={{ width: '1.5em', height: '1.5em' }} />
  </>;
};

export const HazardousMaterialSelect = ({ field, label, value, readOnly, onChange }) => {
  const [ id ] = React.useState(uuid());
  const [ state, setState ] = React.useState(value);

  const handleChange = val => {
    setState(val);

    if (onChange) onChange(val);
  };
  
  const list = [
    {
      class: null,
      icon: '',
      label: 'None',
    },
    ...hazmatClassesList,
  ];

  const curr = hazmatClassesMap[state] || {
    icon: null,
    class: null,
    label: 'None',
  };

  if (readOnly) {
    return <>
      <HazardousMaterialIcon value={state} />{' '}{curr.class}{' '}{curr.label}
    </>
  } else {
    return <val.Context field={field}>{({ error, errorText }) => (
      <Select
        fullWidth
        disabled={readOnly}
        variant="outlined"
        defaultValue={value}
        label={label}
        onChange={e => handleChange(e.target.value)}
        value={state}
      >
          { list.map((i, index) => {
            return <MenuItem key={index} value={i.class}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <HazardousMaterialIcon value={i.class} showAlways={true} />
                </div>
                <div style={{ width: 10 }}>{' '}</div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <div>
                      {i.class}{' '}{i.label}
                    </div>
                    <div>
                      <div style={{ fontSize: '0.8em' }}>
                        {i.desc}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MenuItem>;
          }) }
      </Select>
    )}</val.Context>
  }
};

