import React, { useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';

import { Button } from '@material-ui/core';
import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';

import api from '../lib/api';
import { globalPushModal, GlobalNavControl } from '../lib/global';

import format from './format';
import action from './action';
import input from './input';
import uiControls from './ui';
import data from './data';
import { LocationModal } from './LocationModal';

const OrgUnitForm = ({ onChange }) => {
  const { t } = useTranslation();

  const state = {
    name: '',
    modules: [],
    options: {
      forwarder: {
        state: 'normal',
      }
    }
  };

  const change = () => {
    if (onChange) onChange(state);
  };

  const handleChangeName = val => {
    state.name = val.replace(/^\s+/g, '').replace(/\s+$/g, '');

    change();
  };

  const handleChangeCurrencyCode = val => {
    state.currencyCode = val;

    change();
  };

  const handleChangeModule = (moduleReferenceId) => {
    return (val) => {
      state.modules = state.modules.filter(i => i !== moduleReferenceId);
    
      if (val) {
        state.modules.push(moduleReferenceId)
      }

      change();
    };
  }

  const handleChangePremium = val => {
    state.options.forwarder.state = val ? 'premium' : 'normal';

    change();
  };

  const handleChangePremiumForwardersLock = val => {
    state.options.initiator.disablePremiumForwardersLock = val;

    change();
  };

  return <>
    <input.Field label={t('common:fields.orgUnit.name')}>
      <input.Text onChange={handleChangeName} />
    </input.Field>
    <input.Field label={t('common:fields.orgUnit.currencyCode')}>
      <input.CurrencyCode onChange={handleChangeCurrencyCode} />
    </input.Field>
    <input.Check onChange={handleChangeModule('initiator')} label={t('common:fields.orgUnit.modules.initiator')} />
    <input.Check onChange={handleChangePremiumForwardersLock} label={t('common:fields.orgUnit.options.initiator.disablePremiumForwardersLock')} />
    <input.Check onChange={handleChangeModule('forwarder')} label={t('common:fields.orgUnit.modules.forwarder')} />
    <input.Check onChange={handleChangePremium} label={t('common:states.forwarderOptions.premium')} />
  </>;
};

export const globalPushCreateOrgUnitModal = () => {
  const ui = observable({
    valid: false,
  });

  let state = null;

  const validate = () => {
    ui.valid = state !== null && state.name !== '';
  };

  const handleChange = val => {
    state = val;

    validate();
  }

  const handleAsyncSave = async () => {
    const response = await api.createOrgUnit(state);

    if (!response.success) throw new Error('Failed');

    await api.navigateOrgUnits();
  };

  globalPushModal({
    title: i18n.t('common:title.orgUnit.new'),
    body: <OrgUnitForm onChange={handleChange} />,
    footer: <Observer>{() => <>
      <action.ModalButtons disabled={!ui.valid} onAsyncSave={handleAsyncSave} />
    </>}</Observer>,
    options: {
      large: false,
    },
  });
};

const OrgUnitUsersDetails = ({ orgUnit }) => {
  const { t } = useTranslation();

  const handleClick = React.useCallback(() => {
    globalPushModal({
      title: orgUnit.body.name,
      body: <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('common:fields.user.email')}</TableCell>
                <TableCell>{t('common:fields.user.name')}</TableCell>
                <TableCell>{t('common:fields.user.enabled')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { orgUnit.body.users.map((user, index) => {
                return <TableRow key={index}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    <format.BooleanString value={user.enabled} />
                  </TableCell>
                </TableRow>;
              }) }
            </TableBody>
          </Table>
        </TableContainer>
      </>,
      footer: <action.ModalButtons />,
      options: {
        large: false,
      },
    })
  }, [ orgUnit ]);

  return <>
    <action.Action action="edit" onClick={handleClick} disabled={orgUnit.body.usersCount === 0}>{orgUnit.body.usersCount}</action.Action>
  </>;
};

export const OrgUnitsList = ({ autoSize = true }) => {
  const { t } = useTranslation();

  const uiState = observable({
    orgUnits: null,
  });

  const setOrgUnits = (data = []) => uiState.orgUnits = data;

  const onFetchDataAsyncHandler = async ({ filter, sort, skip, limit, own, search }) => {
    const result = await api.queryOrgUnits({ own: own, filter: filter, sort: sort, skip: skip, limit: limit, search: search });

    return result;
  };

  const onDataHandler = (data) => {
    setOrgUnits([ ...data.data ]);
  };

  const asyncChange = async (index, update) => {
    const orgUnit = toJS(uiState.orgUnits[index]);

    const response = await api.updateOrgUnit(orgUnit.body._id, update);

    if (response.success) {
      uiState.orgUnits[index] = response.data;
    }
  };

  const asyncNameChange = async (index, val) => {
    const text = val.replace(/^\s+/g, '').replace(/\s+$/g, '');

    await asyncChange(index, { name: text });
  };

  const asyncChangeModule = async (index, moduleReferenceId, value) => {
    const orgUnit = toJS(uiState.orgUnits[index]);

    orgUnit.body.modules = orgUnit.body.modules.filter(i => i !== moduleReferenceId);
    
    if (value) {
      orgUnit.body.modules.push(moduleReferenceId)
    }

    return await asyncChange(index, { modules: orgUnit.body.modules });
  };

  const asyncChangePremium = async (index, value) => {
    return await asyncChange(index, {
      'options.forwarder.state': value ? 'premium' : 'normal',
    });
  };

  const asyncChangePublicProfileEnabled = async (index, value) => {
    return await asyncChange(index, {
      'options.forwarder.publicProfileEnabled': value,
    });
  };

  const asyncChangeInitiatorOption = async (index, key, value) => {
    return await asyncChange(index, {
      [`options.initiator.${key}`]: value,
    });
  };

  const getFilterOptions = () => {
    const options = [];

    options.push({
      value: {},
      label: i18n.t('common:actions.showAllRecords.label'),
    });

    options.push({
      value: {
        filter: {
          enabled: true,
        },
      },
      label: i18n.t(`common:states.orgUnit.enabled`),
    });

    options.push({
      value: {
        filter: {
          enabled: false,
        },
      },
      label: i18n.t(`common:states.orgUnit.disabled`),
    });

    options.push({
      value: {
        filter: {
          modules: 'initiator',
        },
      },
      label: i18n.t(`common:fields.orgUnit.modules.initiator`),
    });

    options.push({
      value: {
        filter: {
          modules: 'forwarder',
        },
      },
      label: i18n.t(`common:fields.orgUnit.modules.forwarder`),
    });

    options.push({
      value: {
        filter: {
          modules: 'forwarder',
          'options.forwarder.state': 'premium',
        },
      },
      label: i18n.t(`common:states.forwarderOptions.premium`),
    });

    return options;
  };

  const getSortOptions = () => {
    const options = [];

    options.push({
      label: i18n.t('common:fields.orgUnit.name'),
      value: {
        sort: { name: 'asc' }
      }
    });

    return options;
  };

  return <uiControls.MainContentContainer autoSize={autoSize}>
    <GlobalNavControl>
      <action.Action
        variant="contained"
        mode="button"
        action="createNewOrgUnit"
        color="secondary"
        onClick={globalPushCreateOrgUnitModal}
        id="global-nav-create-orgUnit"
      >
        {t('common:navControls.newOrgUnit.label')}
      </action.Action>
    </GlobalNavControl>
    <uiControls.TableContainer
      autoSize={autoSize}
      header={<>
        <data.SimpleDataToolbar
          onFetchDataAsync={onFetchDataAsyncHandler}
          onData={onDataHandler}
          sortOptions={getSortOptions()}
          filterOptions={getFilterOptions()}
          showSearch
        />
      </>}
    >
      <Observer>{() => {
        const [selectedId,setSelectedId]=useState('');

        const handleClick = (id) => {
          setSelectedId(id)
          
        }

        const handleClose = () => {
          setSelectedId('');
        }


        if (uiState.orgUnits === null) return <uiControls.TableSkeleton />;
        if (uiState.orgUnits.length === 0) return <uiControls.EmptyTable />;
        
        return (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('common:fields.orgUnit.name')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.currencyCode')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.enabled')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.modules.initiator')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.options.initiator.disablePremiumForwardersLock')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.modules.forwarder')}</TableCell>
                <TableCell align="center">{t('common:states.forwarderOptions.premium')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.options.forwarder.publicProfileEnabled')}</TableCell>
                <TableCell align="center">{t('common:fields.orgUnit.usersCount')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { (uiState.orgUnits || []).map((i, index) => {
                return <TableRow key={index}>
                  <TableCell>
                    <action.AsyncText value={i.body.name} onAsyncChange={val => asyncNameChange(index, val)} />
                  </TableCell>
                  <TableCell align="center">
                    <format.CurrencySymbol value={i.body.currencyCode} />
                    {' '}
                    {i.body.currencyCode}
                  </TableCell>
                  <TableCell align="center">
                    <action.AsyncSwitch value={i.body.enabled} onAsyncChange={val => asyncChange(index, { enabled: val })} />
                  </TableCell>
                  <TableCell align="center">
                    <action.AsyncSwitch value={i.body.modules.includes('initiator')} onAsyncChange={val => asyncChangeModule(index, 'initiator', val)} />
                  </TableCell>
                  <TableCell align="center">
                    <action.AsyncSwitch value={i.body.options.initiator.disablePremiumForwardersLock} onAsyncChange={val => asyncChangeInitiatorOption(index, 'disablePremiumForwardersLock', val)} />
                  </TableCell>
                  <TableCell align="center">
                    <action.AsyncSwitch value={i.body.modules.includes('forwarder')} onAsyncChange={val => asyncChangeModule(index, 'forwarder', val)} />
                  </TableCell>
                  <TableCell align="center">
                    <action.AsyncSwitch
                      value={i.body.options.forwarder.state === 'premium'}
                      onAsyncChange={val => asyncChangePremium(index, val)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <action.AsyncSwitch
                      value={!!i.body.options.forwarder.publicProfileEnabled}
                      onAsyncChange={val => asyncChangePublicProfileEnabled(index, val)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <OrgUnitUsersDetails orgUnit={i} />
                  </TableCell>
                  <TableCell>
                    <Button variant='contained' color='primary' onClick={() => handleClick(i.body._id)}>Show Locations</Button>
                    <LocationModal changeDestination={null} changeOrigin={null} isActive={i.body._id===selectedId} orgUnitId={i.body._id} handleClose={handleClose}/>
                  </TableCell>
                </TableRow>;
              })}
            </TableBody>
          </Table>
        );
      }}</Observer>
    </uiControls.TableContainer>
  </uiControls.MainContentContainer>;
};
