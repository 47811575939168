import React from 'react';
import i18n from 'i18next';
import classNames from 'clsx';
import { stackOffsetExpand } from 'd3-shape';
import { withStyles } from '@material-ui/core/styles';
import {
  Stack,
  Animation,
  EventTracker,
  ArgumentScale,
} from '@devexpress/dx-react-chart';

import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  AreaSeries,
  BarSeries,
  PieSeries,
  LineSeries,
  Legend,
  Tooltip,
  Title,
} from '@devexpress/dx-react-chart-material-ui';

import "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow
} from "@devexpress/dx-react-grid-material-ui";

import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';

const ENABLE_ANIMATION = true;

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return Target => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const LegendRoot = setStyle({
  display: 'flex',
  margin: 'auto',
  flexDirection: 'row',
  '& li': {
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  }
})(Legend.Root);

const LegendLabel = setStyle({
  whiteSpace: 'nowrap',
  '& span': {
    fontSize: '0.8m',
  },
  paddingRight: 0,
})(Legend.Label);

const formatForFullstack = scale => scale.tickFormat(null, '%');
const formatPercent = num => {
  return `${Math.floor(num * 100).toString(10)} %`;
};
const formatNumber = num => {
  if (!Number.isInteger(num)) {
    return num.toPrecision(2).toLocaleString();
  } else {
    return num.toLocaleString();
  }
};

const EmptyDataSet = ({ title, ...rest }) => {
  return <>
    <Box
      width='100%'
      {...rest}
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
    >
      { title && <Typography variant='h6'>
        {title}
      </Typography> }
      <Typography variant='subtitle1'>
        { i18n.t('common:charts.messages.emptyDataSet') }
      </Typography>
    </Box>
  </>;
};

export const StackedAreaChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  if (data.length === 1) {
    return <StackedBarChart
      data={data}
      series={series}
      title={title}
      valueFormat={valueFormat}
      {...rest}
    />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis tickFormat={() => tick => tick} />
      <ValueAxis />
      { series.map((i, index) => {
        return <AreaSeries
          key={index}
          name={i.name}
          valueField={i.valueField}
          argumentField={i.argumentField}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack stacks={[{ series: series.map(i => i.name) }]} />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent({ data: data, series: series })} />
    </Chart>
  </>;
});

export const FullStackedAreaChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  if (data.length === 1) {
    return <FullStackedBarChart
      data={data}
      series={series}
      title={title}
      valueFormat={valueFormat}
      {...rest}
    />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis tickFormat={() => tick => tick} />
      <ValueAxis tickFormat={formatForFullstack} />
      { series.map((i, index) => {
        return <AreaSeries
          key={index}
          name={i.name}
          valueField={i.valueField}
          argumentField={i.argumentField}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack stacks={[{ series: series.map(i => i.name) }]} offset={stackOffsetExpand} />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent({ data: data, series: series, format: formatPercent })} />
    </Chart>
  </>;
});

export const BarChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis />
      <ValueAxis />
      { series.map((i, index) => {
        return <BarSeries
          key={index}
          name={i.name.toString()}
          valueField={i.valueField}
          argumentField={i.argumentField}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent({ data: data, series: series })} />
    </Chart>
  </>;
});

export const PieChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data}>
      { title && <Title text={title} position='top' /> }
      { series.map((i, index) => {
        return <PieSeries
          key={ index }
          name={ i.name.toString() }
          valueField={ i.valueField }
          argumentField={ i.argumentField }
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <EventTracker />
      <Tooltip enabled={true} />
    </Chart>
  </>;
});

export const DoughnutChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data}>
      { title && <Title text={title} position='top' /> }
      { series.map((i, index) => {
        return <PieSeries
          key={ index }
          name={ i.name.toString() }
          valueField={ i.valueField }
          argumentField={ i.argumentField }
          innerRadius={0.6}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <EventTracker />
      <Tooltip enabled={true} />
    </Chart>
  </>;
});

export const LineChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis argumentField='daynum' /*tickFormat={ () => tick => {

        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setDate(date.getDate() - parseInt(tick));

        return date.toString().slice(3, 10).replace('-', '/');
      }}*//>
      <ValueAxis />

      { series.map((i, index) => {
        return <LineSeries
          key={ index }
          name={ i.name.toString() }
          valueField={ i.valueField }
          argumentField={ i.argumentField }
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <EventTracker />
      <Tooltip enabled={true} />
    </Chart>
  </>;
});

export const EmptyBarChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis />
      { series.map((i, index) => {
        return <BarSeries
          key={index}
          name={i.name.toString()}
          valueField={i.valueField}
          argumentField={i.argumentField}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack stacks={[{ series: series.map(i => i.name) }]} />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent({ data: data, series: series })} />
    </Chart>
  </>;
});

export const TextBox = React.memo(({ text, title, textColor, icon, ...rest }) => {
  // if (!text || text.length === 0) {
  //   return <EmptyDataSet title={title} {...rest} />
  // }

  const { height } = rest;

  return <>
      <div style={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gridTemplateRows: '1fr 1fr 1fr',
        width: '100%',
        height: `${height}px`,
        alignItems: 'center',
        justifyContent: 'center'
      }}>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          { title && <p style={{
            fontSize: '1.5rem',
            fontWeight: '400',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            textAlign: 'center',
            marginBottom: '1rem'
          }}>{title}</p> }

          { icon && icon() } 
        </div>
        
        <div style={{
          flex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <p style={{
            fontSize: '2em',
            fontWeight: '500',
            color: textColor,
            textAlign: 'center',
          }}>{text}</p>
        </div>
      </div>
    {/* </Chart> */}
  </>;
});

export const StackedBarChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis />
      <ValueAxis />
      { series.map((i, index) => {
        return <BarSeries
          key={index}
          name={i.name.toString()}
          valueField={i.valueField}
          argumentField={i.argumentField}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack stacks={[{ series: series.map(i => i.name) }]} />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent({ data: data, series: series })} />
    </Chart>
  </>;
});

export const FullStackedBarChart = React.memo(({ data, series, title, valueFormat, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }
      <ArgumentAxis />
      <ValueAxis tickFormat={formatForFullstack} />
      { series.map((i, index) => {
        return <BarSeries
          key={index}
          name={i.name.toString()}
          valueField={i.valueField}
          argumentField={i.argumentField}
        />;
      }) }
      { ENABLE_ANIMATION && <Animation /> }
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack stacks={[{ series: series.map(i => i.name) }]} offset={stackOffsetExpand} />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent({ data: data, series: series, format: formatPercent })} />
    </Chart>
  </>;
});

const TooltipContent = ({ data, series, format }) => {
  const seriesMap = series.reduce((acc, curr) => {
    acc[curr.name] = curr;
    return acc;
  }, {});

  const Content = ({ text, targetItem }) => {
    const dataItem = data[targetItem.point];
    const seriesItem = seriesMap[targetItem.series];
    const totalValue = series.reduce((acc, curr) => {
      return acc + dataItem[curr.valueField];
    }, 0);

    return <>
      <Box
        display='grid'
        gridTemplateColumns='max-content max-content'
        gridColumnGap='1em'
      >
        { series.map(s => {
          const label = s.name;
          const value = dataItem[s.valueField];
          const actualFormat = s.format || formatNumber;

          const display = format ? format(totalValue > 0 ? value / totalValue : 0) : actualFormat(value);

          const isActive = s.valueField === seriesItem.valueField;
          const variant = isActive ? 'subtitle2' : 'caption';
          const color = isActive ? 'secondary' : 'inherit';

          return <span key={s.name}>
            <Typography variant={variant} color={color}>{label}:</Typography>
            <Typography variant={variant} color={color} style={{ justifySelf: 'end' }}>{display}</Typography>
          </span>;
        })}
      </Box>
    </>;
  };

  return Content;
};

export const DataTable = React.memo(({ columns, rows, title, ...rest }) => {
  if (!columns || columns.length === 0 || !rows || rows.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  const CellComponent = props => (
    <Table.Cell
      {...props}
      style={{
        padding: '0.5rem',
        fontSize: '16px',
        textAlign: 'center'
      }}
    />
  );
  
  const HeaderCellComponent = props => (
    <TableHeaderRow.Cell
      {...props}
      style={{
        padding: '0.5rem',
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: 'bold',
        // Centered through App.css
      }}
    />
  );

  return <>
    { title && <Typography style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} 
        variant="h5" position='top' align='center'>{title}</Typography> }
    <Grid
      columns={columns}
      rows={rows}
      {...rest}
    >      
      <Table cellComponent={CellComponent}/>
      <TableHeaderRow cellComponent={HeaderCellComponent}/>
    </Grid>
  </>;
});

export const SideBySideBarChart = React.memo(({ data, quarters, title, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  const COLORS = ["#ff9800", "#ff5722", "#f44336", "#e91e63", "#9c27b0"];

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }

      <ArgumentAxis />
      <ValueAxis />

      { quarters.map((i, index) => {
          return <BarSeries
            key={index}
            name={i}
            valueField={i}
            argumentField="continent"
            color={COLORS[index]}
            />
        })
      }

      <Animation />
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
      <Stack />
      <EventTracker />
    </Chart>
  </>;
});

export const TrendChart = React.memo(({ data, quarters, title, ...rest }) => {
  if (!data || data.length === 0) {
    return <EmptyDataSet title={title} {...rest} />
  }

  const format = () => tick => tick;

  return <>
    <Chart data={data} {...rest}>
      { title && <Title text={title} position='top' /> }

      <ArgumentAxis tickFormat={format} />
      <ValueAxis />

      <LineSeries
        name="PPK"
        valueField="ppk"
        argumentField="quarter"
      />

      <Animation />
      <Legend position='bottom' rootComponent={LegendRoot} labelComponent={LegendLabel} />
    </Chart>
  </>;
});

export default {
  StackedAreaChart,
  FullStackedAreaChart,
  BarChart,
  StackedBarChart,
  EmptyBarChart,
  FullStackedBarChart,
  PieChart,
  DoughnutChart,
  LineChart,
  TextBox,
  DataTable,
  SideBySideBarChart,
  TrendChart
};
