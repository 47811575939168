import React from 'react';
import i18n from 'i18next';

import { globalPushModal } from '../lib/global';
import api from '../lib/api';
import action from './action';

import premiumIllustration from '../assets/illustrations/premium.svg';

import {
  Box,
  Typography,
} from '@material-ui/core';

export const globalPushPremiumFeature = (feature) => {
  api.trackEvent({
    category: 'Features',
    action: 'Interest',
    label: feature,
  });

  const title = i18n.exists(`premium:feature.${feature}.title`)
    ? i18n.t(`premium:feature.${feature}.title`)
    : i18n.t('premium:feature.default.title');

  const body = i18n.exists(`premium:feature.${feature}.body`)
    ? i18n.t(`premium:feature.${feature}.body`)
    : i18n.t('premium:feature.default.body');

  globalPushModal({
    title: title,
    body: <>
      <Box
        display="grid"
        gridTemplateColumns="min-content auto"
        alignItems="start"
      >
        <Box>
          <img src={premiumIllustration} style={{ maxWidth: '200px' }} />
        </Box>
        <Box ml={1}>
          {body.split('\n').map((i, index) => {
            return <Box key={index} width="100%" mb={2}><Typography variant="body1">{i}</Typography></Box>;
          }) }
        </Box>
      </Box>
    </>,
    footer: <action.ModalButtons />,
    options: {
      maxWidth: 'md',
    },
  });
};
