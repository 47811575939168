import React from 'react';
import i18n from 'i18next';
import { css } from 'emotion';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';

import api from '../lib/api';
import format from './format';
import action from './action';
import ui from './ui';
import data from './data';
import val, { ShipmentContractValidationSchema } from './validation';

import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper, Box } from '@material-ui/core';

const styles = {
  auxActions: css({
    '& button': {
      whiteSpace: 'nowrap',
    }
  }),
};

const Main = ({ state = null, mode = 'compact', hideChat = false, dashboard = false, autoSize = true, ...rest }) => {
  const { t } = useTranslation();

  const uiState = observable({
    data: null,
  });

  const setData = (data = []) => uiState.data = data;

  const onFetchDataAsyncHandler = async ({ filter, sort, skip, limit, own }) => {
    const result = await api.queryShipmentContracts({ own: own, filter: filter, sort: sort, skip: skip, limit: limit });

    return result;
  };

  const onDataHandler = (data) => {
    setData([ ...data.data ]);
  };

  const State = ({ value }) => {
    if (value.body.state === 'draft') {
      // Validate
      const summary = val.getValidationResultsSummary(value.validation, ShipmentContractValidationSchema);

      if (summary.total > 0) {
        const parts = [];

        if (summary.errors) {
          parts.push(t('common:validation.summary.errors', { count: summary.errors }));
        }

        if (summary.warnings) {
          parts.push(t('common:validation.summary.warnings', { count: summary.warnings }));
        }

        return <action.ShowValidationSummary
            value={value}
            schema={ShipmentContractValidationSchema}
            color={summary.errors ? 'error.main' : 'warning.main'}
            startButtons={<>
              <Box mr={2}>
                <action.ShipmentContractDetails mode="button" showLabel={true} value={value} />
              </Box>
            </>}
          />;
      }
    }

    if (value.body.state !== 'closed' || !value.body.shipmentContractRating) {
      return <>
        { [ 'contract' ].includes(value.body.state) ? (<>
          <Box mb={1} fontWeight="fontWeightLight" fontSize="0.8em">
            <format.ShipmentContractState value={value.body.state} />
          </Box>
          <Box>
            <format.ShipmentContractOperationalState value={value.body.operationalState} />
          </Box>
        </>) : (
          <Box>
            <format.ShipmentContractState value={value.body.state} />
          </Box>
        )}
      </>;
    } else {
      return <>
        <Box mb={1} fontWeight="fontWeightLight" fontSize="0.8em">
          <format.ShipmentContractState value={value.body.state} />
        </Box>
        <Box>
          <action.RatingItem value={value.body.shipmentContractRating} singleItem />
        </Box>
      </>;
    }
  };

  const Actions = ({ value }) => {
    const list = [];

    list.push(<action.ShipmentContractDetails value={value} shipment={value.body.shipment} />);
    if (value.body.state === 'bid') {
      list.push(<action.UpdateShipmentContractState value={value} state="archived" />);
    } else {
      list.push(<action.DeleteContractDraft value={value} />);
    }

    return <span className="nowrap">
      {list.map((i, index) => <React.Fragment key={index}>{i}</React.Fragment>)}
    </span>;
  };

  const AuxActions = ({ value }) => {
    const list = [];

    if (value.body.state === 'draft') {
      // Validate
      const summary = val.getValidationResultsSummary(value.validation, ShipmentContractValidationSchema);

      if (!summary.errors && new Date(value.body.shipment.body.expiresAt) > api.now()) {
        list.push(<action.UpdateShipmentContractState value={value} state="bid" className="block-rsep-50" />);
      }
    }

    if ([ 'contract', 'dispute' ].includes(value.body.state)) {
      list.push(<action.UpdateShipmentContractState value={value} state="completed" className="block-rsep-50" />);
    }

    return <div className={styles.auxActions} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
      {list.map((i, index) => <React.Fragment key={index}>{i}</React.Fragment>)}
    </div>;
  };

  const getFilterOptions = () => {
    const bid = [ 'draft', 'bid', 'expired', 'archived' ];
    const active = [ 'contract', 'completed', 'closed', 'dispute' ];

    const hasBids = (state || '') === '' || state.split(/,/g).find(i => bid.includes(i));
    const hasActive = (state || '') === '' || state.split(/,/g).find(i => active.includes(i));

    const states = [
      ...(hasBids ? bid : []),
      ...(hasActive ? active : []),
    ];

    const options = [];

    options.push({
      value: {
        filter: {
          state: states.join(','),
        },
      },
      label: i18n.t('common:actions.showAllRecords.label'),
    });

    states.forEach(i => {
      options.push({
        value: {
          filter: {
            state: i,
          },
        },
        label: i18n.t(`common:states.shipmentContract.${i}`),
      });
    });

    options.push({
      value: {
        filter: {
          state: state,
        },
        own: true
      },
      label: i18n.t('common:actions.showOwnRecords.label'),
    });

    return options;
  };

  const getSortOptions = () => {
    const options = [];

    options.push({
      label: i18n.t('common:sort.shipmentContract.updatedAt'),
      value: {
        sort: { updatedAt: 'desc' }
      }
    });

    options.push({
      label: i18n.t('common:sort.shipmentContract.globalRecordNumber'),
      value: {
        sort: { globalRecordNumber: 'desc' }
      }
    });

    return options;
  };

  return <ui.MainContentContainer autoSize={autoSize}>
    <ui.TableContainer
      autoSize={autoSize}
      header={<>
        <data.SimpleDataToolbar
          onFetchDataAsync={onFetchDataAsyncHandler}
          onData={onDataHandler}
          sortOptions={getSortOptions()}
          filterOptions={getFilterOptions()}
        />
      </>}
    >
      <Observer>{() => {
        const hasBids = (state || '') === '' || state.split(/,/g).find(i => ['draft', 'bid', 'expired', 'archived'].includes(i));

        if (uiState.data === null) return <ui.TableSkeleton />;
        if (uiState.data.length === 0) return <ui.EmptyTable />;

        return (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('common:fields:shipment.globalRecordNumber')}</TableCell>
                {/* <TableCell className="text-center">{t('common:fields:shipment.updatedAt')}</TableCell> */}
                { hasBids && <TableCell className="text-center">{t('common:fields:shipment.expiresAt')}</TableCell> }
                <TableCell>{t('common:fields:shipmentContract.globalRecordNumber')}</TableCell>
                <TableCell className="text-center">{t('common:fields.shipmentContractPrices.total')}</TableCell>
                { !hideChat && <TableCell className="text-center">{t('common:fields.common.ops')}</TableCell> }
                {/* <TableCell className="text-center">{t('common:fields:shipmentContract.freightCarrierName')}</TableCell> */}
                { hasBids && <TableCell className="text-center">{t('common:fields:shipmentContract.expiresAt')}</TableCell> }
                <TableCell className="text-center">{t('common:fields:shipment.transportMode')}</TableCell>
                { !dashboard && <TableCell className="text-center">{t('common:fields:shipment.shipmentType')}</TableCell> } 
                <TableCell>{t('common:fields:orgUnit.name@forwarder')}</TableCell>
                <TableCell className="text-center">{t('common:fields:shipment.pickupFrom')}</TableCell>
                <TableCell className="text-center">{t('common:fields:shipment.deliverTo')}</TableCell>
                { !dashboard && <TableCell className="text-center">{t('common:fields:shipmentContract.state')}</TableCell> }
                { !dashboard && <TableCell></TableCell> }
              </TableRow>
            </TableHead>
            <TableBody>
              <Observer>{() => (uiState.data || []).map((dataItem, index) => {
                return <TableRow key={index}>
                  <TableCell>
                    <action.Cell label={
                      <action.ShipmentDetails value={dataItem.body.shipment} mode="link">
                        {dataItem.body.shipment.body.globalRecordNumber}
                      </action.ShipmentDetails>
                    }>
                      { !dashboard && <action.ShipmentDetails value={dataItem.body.shipment} /> }
                    </action.Cell>
                  </TableCell>
                  {/* <TableCell className="text-center"><format.DateText value={dataItem.body.shipment.body.updatedAt} /></TableCell> */}
                  { hasBids && <TableCell className="text-center">
                    {dataItem.body.state !== 'archived' ?
                      <format.Deadline
                        value={dataItem.body.shipment.body.expiresAt}
                        onComplete={() => dataItem.body.readOnly = true}
                      /> : i18n.t('common:states.shipmentContract.archived')
                    }
                  </TableCell> }
                  <TableCell>
                    <action.Cell label={
                      <action.ShipmentContractDetails shipment={dataItem.body.shipment} value={dataItem} mode="link">
                        {dataItem.body.globalRecordNumber}
                      </action.ShipmentContractDetails>
                    }>
                      { !dashboard && <Actions value={dataItem} /> }
                    </action.Cell>
                  </TableCell>
                  <TableCell className="text-right nowrap">
                    <action.ShipmentContractDetails shipment={dataItem.body.shipment} value={dataItem}>
                      <format.Money value={dataItem.body.prices.total} currencyCode={dataItem.body.shipment.body.currencyCode} />
                    </action.ShipmentContractDetails>
                  </TableCell>
                  { !hideChat && <TableCell className="text-center">
                    <action.ShowShipmentContractChatSession value={dataItem} />
                  </TableCell> }
                  {/* <TableCell className="text-center">
                    {dataItem.body.freightCarrierName}
                  </TableCell> */}
                  { hasBids && <TableCell className="text-center"><format.DateText value={dataItem.body.expiresAt} /></TableCell> }
                  <TableCell className="text-center"><format.TransportMode value={dataItem.body.shipment.body.transportMode} mode="icon" /></TableCell>
                  { !dashboard && <TableCell className="text-center"><format.ShipmentType value={dataItem.body.shipment.body.shipmentType} /></TableCell> }
                  <TableCell>
                    {dataItem.body.shipment.body.orgUnit.name}
                  </TableCell>
                  <TableCell className="text-center"><format.ShortAddress value={dataItem.body.shipment.body.pickupFrom} /></TableCell>
                  <TableCell className="text-center"><format.ShortAddress value={dataItem.body.shipment.body.deliverTo} /></TableCell>
                  { !dashboard && <TableCell className="text-center"><State value={dataItem} /></TableCell> }
                  { !dashboard && <TableCell className="text-right"><AuxActions value={dataItem} /></TableCell> }
                </TableRow>;
              })}</Observer>
            </TableBody>
          </Table>
        );
      }}</Observer>
    </ui.TableContainer>
  </ui.MainContentContainer>;
};

export default Main;
