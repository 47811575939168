import class11 from './1.1.svg';
import class12 from './1.2.svg';
import class13 from './1.3.svg';
import class14 from './1.4.svg';
import class15 from './1.5.svg';
import class16 from './1.6.svg';

import class21 from './2.1.svg';
import class22 from './2.2.svg';
import class23 from './2.3.svg';

import class3 from './3.svg';

import class41 from './4.1.svg';
import class42 from './4.2.svg';
import class43 from './4.3.svg';

import class51 from './5.1.svg';
import class52 from './5.2.svg';

import class61 from './6.1.svg';
import class62 from './6.2.svg';

import class7 from './7.svg';

import class81 from './8.1.svg';
import class82 from './8.2.svg';

import class9 from './9.svg';

const icon = (name) => {
  return import(`./${name}.svg`);
};

const data = [
  { class: '1.1', icon: class11, label: 'Explosives with a mass explosion hazard', desc: 'Ex: TNT, dynamite, nitroglycerine, ANFO' },
  { class: '1.2', icon: class12, label: 'Explosives with a blast/projection hazard', },
  { class: '1.3', icon: class13, label: 'Explosives with a minor blast hazard', desc: 'Ex: rocket propellant, display fireworks' },
  { class: '1.4', icon: class14, label: 'Explosives with a major fire hazard', desc: 'Ex: consumer fireworks, ammunition' },
  { class: '1.5', icon: class15, label: 'Blasting agents' },
  { class: '1.6', icon: class16, label: 'Extremely insensitive explosives' },

  { class: '2.1', icon: class21, label: 'Flammable Gas', desc: 'Ex: acetylene, hydrogen, propane' },
  { class: '2.2', icon: class22, label: 'Non-Flammable Gases' },
  { class: '2.3', icon: class22, label: 'Poisonous Gases' },

  { class: '3', icon: class3, label: 'Flammable liquids' },

  { class: '4.1', icon: class41, label: 'Flammable Solids' },
  { class: '4.2', icon: class42, label: 'Spontaneously Combustible Solids' },
  { class: '4.3', icon: class43, label: 'Dangerous when Wet Solids' },

  { class: '5.1', icon: class51, label: 'Oxidizing Agents other than organic peroxides', desc: 'Ex: calcium hypochlorite, ammonium nitrate, hydrogen peroxide, potassium permanganate' },
  { class: '5.2', icon: class52, label: 'Organic Peroxides, liquid or solid form', desc: 'Ex: benzoyl peroxides, cumene hydroperoxide' },

  { class: '6.1', icon: class61, label: 'Poison' },
  { class: '6.2', icon: class62, label: 'Biohazard' },

  { class: '7', icon: class7, label: 'Radioactive' },

  { class: '8.1', icon: class81, label: 'Acids', desc: 'Ex: sulfuric acid, hydrochloric acid' },
  { class: '8.2', icon: class82, label: 'Alkalis', desc: 'Ex: potassium hydroxide, sodium hydroxide' },

  { class: '9', icon: class9, label: 'Miscellaneous', desc: 'Ex: asbestos, air-bag inflators, self inflating life rafts, dry ice' },
];

export default data;