import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import * as ui from './ui';
import input from './input';

import api from '../lib/api';

import {
  Paper, Box, Fab, Button,
} from '@material-ui/core';

import MdSort from '@material-ui/icons/Sort';
import MdFilter from '@material-ui/icons/FilterList';
import MdExcel from '../assets/icons/microsoft-excel.svg';


export const SimpleDataToolbar = ({
  onFetchDataAsync,
  onData,
  sortOptions = [],
  filterOptions = [],
  showSearch = false,
}) => {
  const uiState = observable({
    sortIndex: 0,
    filterIndex: 0,
    search: '',
    skip: 0,
    limit: 10,
    count: 0,
    data: [],
  });

  React.useEffect(() => {
    refresh();
  }, [ sortOptions, filterOptions ]);

  const refresh = api.throttle(async () => {
    if (!onFetchDataAsync) return;

    const args = {
      ...(sortOptions.length > 0 ? sortOptions[uiState.sortIndex].value : {}),
      ...(filterOptions.length > 0 ? filterOptions[uiState.filterIndex].value : {}),
      skip: uiState.skip,
      limit: uiState.limit,
      search: uiState.search,
    };

    const response = await onFetchDataAsync(args);

    if (response.success) {
      uiState.count = response.summary.count;
      uiState.data = response.data;

      if (onData) {
        onData(response);
      }
    }
  }, 1000);

  const handleChangeWindow = ({ skip, limit }) => {
    uiState.skip = skip;
    uiState.limit = limit;

    refresh();
  };

  const handleSortChange = (index) => {
    uiState.sortIndex = index;
    uiState.skip = 0;

    refresh();
  };

  const handleFilterChange = (index) => {
    uiState.filterIndex = index;
    uiState.skip = 0;

    refresh();
  };

  const handleSearchChange = (val) => {
    const newVal = val.replace(/^\s+/g, '').replace(/\s+$/g, '');

    if (newVal === uiState.search) return;

    uiState.search = newVal;
    uiState.skip = 0;

    refresh();
  };

  const sortLabels = sortOptions.reduce((acc, curr, index) => {
    acc[index] = curr.label;
    return acc;
  }, {});

  const filterLabels = filterOptions.reduce((acc, curr, index) => {
    acc[index] = curr.label;
    return acc;
  }, {});

  return <Paper>
    <Box bgcolor="background.default">
      <Box
        p={1}
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="auto"
        gridColumnGap="1em"
        justifyContent="stretch"
        alignItems="center"
      >
        { showSearch && <Box>
          <input.Search
            onChange={handleSearchChange}
          />
        </Box> }
        { sortOptions && sortOptions.length > 1 && <Box>
          <Observer>{() => (
            <input.StaticSelect
              value={uiState.sortIndex}
              values={sortLabels}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleSortChange}
              startAdornment={<Box pr={1}>
                <MdSort />
              </Box>}
            />
          )}</Observer>
        </Box> }
        { filterOptions && filterOptions.length > 1 && <Box>
          <Observer>{() => (
            <input.StaticSelect
              value={uiState.filterIndex}
              values={filterLabels}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleFilterChange}
              startAdornment={<Box pr={1}>
                <MdFilter />
              </Box>}
            />
          )}</Observer>
        </Box> }
        <Box>
          <Observer>{() => (
            <ui.TablePager
              count={uiState.count}
              skip={uiState.skip}
              limit={uiState.limit}
              onChange={handleChangeWindow}
            />
          )}</Observer>
        </Box>
      </Box>
    </Box>
  </Paper>;
};

export const DateDataToolbar = ({
  onFetchDataAsync,
  onData,
  onExport,
  sortOptions = [],
  filterOptions = [],
  showSearch = false,
  showExport = false,
}) => {
  const uiState = observable({
    sortIndex: 0,
    filterIndex: 0,
    search: '',
    skip: 0,
    limit: 10,
    count: 0,
    data: [],
    startDate: 0,
    endDate: 0,
  });

  const [ enableExport, setEnableExport ] = React.useState(false);

  React.useEffect(() => {
    refresh();
  }, [ sortOptions, filterOptions ]);

  const refresh = api.throttle(async () => {
    if (!onFetchDataAsync) return;

    const args = {
      ...(sortOptions.length > 0 ? sortOptions[uiState.sortIndex].value : {}),
      ...(filterOptions.length > 0 ? filterOptions[uiState.filterIndex].value : {}),
      skip: uiState.skip,
      limit: uiState.limit,
      search: uiState.search,
      startDate: uiState.startDate,
      endDate: uiState.endDate,
    };

    const response = await onFetchDataAsync(args);

    if (response.success) {
      uiState.count = response.summary.count;
      uiState.data = response.data;

      if (onData) {
        onData(response);
      }
    }
  }, 1000);

  const handleChangeWindow = ({ skip, limit }) => {
    uiState.skip = skip;
    uiState.limit = limit;

    refresh();
  };

  const handleSortChange = (index) => {
    uiState.sortIndex = index;
    uiState.skip = 0;

    refresh();
  };

  const handleFilterChange = (index) => {
    uiState.filterIndex = index;
    uiState.skip = 0;
    const theRightPage = window.location.href;
    setEnableExport(showExport && uiState.filterIndex == 2 && (theRightPage === "http://localhost:7171/shipments/active,completed,closed,dispute" || theRightPage === "https://cargosys.net/shipments/active,completed,closed,dispute"));
    refresh();
  };

  const handleSearchChange = (val) => {
    const newVal = val.replace(/^\s+/g, '').replace(/\s+$/g, '');

    if (newVal === uiState.search) return;

    uiState.search = newVal;
    uiState.skip = 0;

    refresh();
  };

  const handleStartDateChange = (val) => {
    uiState.startDate = new Date(val).getTime();

    refresh();
  }

  const handleEndDateChange = (val) => {
    uiState.endDate = new Date(val).getTime();

    refresh();
  }

  const sortLabels = sortOptions.reduce((acc, curr, index) => {
    acc[index] = curr.label;
    return acc;
  }, {});

  const filterLabels = filterOptions.reduce((acc, curr, index) => {
    acc[index] = curr.label;
    return acc;
  }, {});

  return <Paper>
    <Box bgcolor="background.default">
      <Box
        p={1}
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="auto"
        gridColumnGap="1em"
        justifyContent="stretch"
        alignItems="center"
      >
        { showSearch && <Box>
          <input.Search
            onChange={handleSearchChange}
          />
        </Box> }
        { sortOptions && sortOptions.length > 1 && <Box>
          <Observer>{() => (
            <input.StaticSelect
              value={uiState.sortIndex}
              values={sortLabels}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleSortChange}
              startAdornment={<Box pr={1}>
                <MdSort />
              </Box>}
            />
          )}</Observer>
        </Box> }
        { filterOptions && filterOptions.length > 1 && <Box>
          <Observer>{() => (
            <input.StaticSelect
              value={uiState.filterIndex}
              values={filterLabels}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleFilterChange}
              startAdornment={<Box pr={1}>
                <MdFilter />
              </Box>}
            />
          )}</Observer>
        </Box> }

        {/* ======================= */}
        <Box style={{ display: 'flex', flexDirection: 'row', gap: '1em', alignItems: 'top' }}>
          <Observer>{() => (
            <input.DatePicker
              label={ "Start Date" }
              disablePast={ false }
              minDate={ new Date(0) }
              onChange={handleStartDateChange}
            />
          )}</Observer>

          <Observer>{() => (
            <input.DatePicker
              label={ "End Date" }
              disablePast={ false }
              minDate={ new Date(0) }
              onChange={handleEndDateChange}
            />
          )}</Observer>

          { enableExport && <Box style={{ height: '50px', marginTop: '8px' }}>
            <Button onClick={onExport} variant="contained" style={{ height: '100%' }}
              color="default"
              startIcon={<img src={MdExcel} border={0} className="icon" />}
            >Export</Button>
          </Box> }
        </Box>
        {/* ======================= */}

        <Box>
          <Observer>{() => (
            <ui.TablePager
              count={uiState.count}
              skip={uiState.skip}
              limit={uiState.limit}
              onChange={handleChangeWindow}
            />
          )}</Observer>
        </Box>
      </Box>
    </Box>
  </Paper>;
};

export const YearQuarterDataToolbar = ({
  onFetchDataAsync,
  onData,
  sortOptions = [],
  filterOptions = [],
  showSearch = false,
  changeWindow = false,
}) => {
  const now = new Date();
  const quarter = (now.getMonth() >= 9 ? 3 : now.getMonth() >= 6 ? 2 : now.getMonth() >= 3 ? 1 : 0);
  /* Q1 = 0, Q2 = 1, Q3 = 2, Q4 = 3 */

  const uiState = observable({
    sortIndex: 0,
    filterIndex: 0,
    search: '',
    skip: 0,
    limit: 10,
    count: 0,
    data: [],
    year: now.getFullYear(),
    quarter: quarter,
  });

  React.useEffect(() => {
    refresh();
  }, [ sortOptions, filterOptions ]);

  const refresh = api.throttle(async () => {
    if (!onFetchDataAsync) return;

    const startDate = new Date(uiState.year-1, (uiState.quarter == 0 /*Q1*/ ? 0 : uiState.quarter == 1 /*Q2*/ ? 3 : uiState.quarter == 2 /*Q3*/ ? 6 : 9), 1);
    const endDate = new Date(uiState.year, (uiState.quarter == 0 ? 3 : uiState.quarter == 1 ? 6 : uiState.quarter == 2 ? 9 : 12), 0);

    const args = {
      ...(sortOptions.length > 0 ? sortOptions[uiState.sortIndex].value : {}),
      ...(filterOptions.length > 0 ? filterOptions[uiState.filterIndex].value : {}),
      skip: uiState.skip,
      limit: uiState.limit,
      search: uiState.search,
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    };

    const response = await onFetchDataAsync(args);

    if (response.success) {
      uiState.count = response.summary.count;
      uiState.data = response.data;

      if (onData) {
        onData({ ...response, year: uiState.year, quarter: uiState.quarter });
      }
    }
  }, 1000);

  const handleChangeWindow = ({ skip, limit }) => {
    uiState.skip = skip;
    uiState.limit = limit;

    refresh();
  };

  const handleSortChange = (index) => {
    uiState.sortIndex = index;
    uiState.skip = 0;

    refresh();
  };

  const handleFilterChange = (index) => {
    uiState.filterIndex = index;
    uiState.skip = 0;

    refresh();
  };

  const handleYearChange = (val) => {
    // uiState.year = new Date(val, 0, 1).getTime(); // Jan 1st
    uiState.year = val.year();

    refresh();
  }

  const handleQuarterChange = (val) => {
    // uiState.endDate = new Date(val, 11, 31).getTime(); // Dec 31st
    uiState.quarter = val;

    refresh();
  }

  const sortLabels = sortOptions.reduce((acc, curr, index) => {
    acc[index] = curr.label;
    return acc;
  }, {});

  const filterLabels = filterOptions.reduce((acc, curr, index) => {
    acc[index] = curr.label;
    return acc;
  }, {});

  return <Paper>
    <Box bgcolor="background.default">
      <Box
        p={1}
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="auto"
        gridColumnGap="1em"
        justifyContent="stretch"
        alignItems="center"
      >
        { showSearch && <Box>
          <input.Search
            onChange={handleSearchChange}
          />
        </Box> }
        { sortOptions && sortOptions.length > 1 && <Box>
          <Observer>{() => (
            <input.StaticSelect
              value={uiState.sortIndex}
              values={sortLabels}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleSortChange}
              startAdornment={<Box pr={1}>
                <MdSort />
              </Box>}
            />
          )}</Observer>
        </Box> }
        { filterOptions && filterOptions.length > 1 && <Box>
          <Observer>{() => (
            <input.StaticSelect
              value={uiState.filterIndex}
              values={filterLabels}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleFilterChange}
              startAdornment={<Box pr={1}>
                <MdFilter />
              </Box>}
            />
          )}</Observer>
        </Box> }

        {/* ======================= */}
        <Box style={{ display: 'flex', flexDirection: 'row', gap: '1em', alignItems: 'top' }}>
          <Observer>{() => (
            <input.DatePicker
              label={ "Year" }
              disablePast={ false }
              minDate={ new Date(0) }
              onChange={handleYearChange}
              format={'YYYY'}
              views={['year']}
            />
          )}</Observer>

          <Observer>{() => (
            <input.StaticSelect
              value={uiState.quarter}
              values={['Q1', 'Q2', 'Q3', 'Q4']}
              showKeys={false}
              formGroup={false}
              sort={false}
              onChange={handleQuarterChange}
            />
          )}</Observer>
        </Box>
        {/* ======================= */}

        { changeWindow && <Box>
          <Observer>{() => (
            <ui.TablePager
              count={uiState.count}
              skip={uiState.skip}
              limit={uiState.limit}
              onChange={handleChangeWindow}
            />
          )}</Observer>
        </Box> }
      </Box>
    </Box>
  </Paper>;
};

export default {
  SimpleDataToolbar,
  DateDataToolbar,
  YearQuarterDataToolbar
};
