import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { SnackbarProvider } from 'notistack';
import App from './App';
import * as serviceWorker from './serviceWorker';

import i18next from 'i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import config from './lib/config';
import action from './components/action';
import api from './lib/api';

import CloseIcon from '@material-ui/icons/Close';

const cache1min = Math.floor((new Date()).valueOf() / (1000 * 60)) * 1000 * 60;

intervalPlural.setOptions({
  // these are the defaults
  intervalSeparator: ';',
  intervalRegex: /\((\S*)\).*{((.|\n)*)}/,
  intervalSuffix: '_interval'
});

i18next
  .use(i18nextHttpBackend)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    ns: [ 'common', 'help', 'tags', 'premium', 'reports' ],
    defaultNS: 'common',
    backend: {
      loadPath: `/i18n/{{lng}}/{{ns}}.json?nocache=${cache1min}`,
      allowMultiLoading: false,
      crossDomain: false,
    }
  }).then((t) => {
    api.init().then(() => {
      ReactDOM.render(
        // TODO: reactivate strict mode
        // <React.StrictMode>
          <Main />,
        // </React.StrictMode>,
        document.getElementById('root')
      );
      
      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.unregister();   
    });
  });

window.heap.load(config.analytics.heap.id);

const Main = ({}) => {
  const snackbarRef = React.useRef();

  return <>
    <SnackbarProvider
      ref={snackbarRef}
      maxSnack={10}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={5000}
      action={(key => {
        return <action.Action onClick={() => snackbarRef.current.closeSnackbar(key)} color="inherit">
          <CloseIcon fontSize="small" />
        </action.Action>
      })}
    >
      <App />
    </SnackbarProvider>
  </>;
};
