import React from 'react'
import i18n from 'i18next';
import { useSnackbar } from 'notistack';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import copyToClipboard from 'copy-to-clipboard';
import { Form, Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import MdSearch from '@material-ui/icons/Search';

import InputMask from 'react-input-mask';
import { 
  TextField, Select, InputAdornment, MenuItem, Button, Divider, Grid, InputLabel,
  RadioGroup, FormControlLabel, Radio, FormLabel, FormHelperText, CircularProgress,
  FormGroup, Box, Switch, Checkbox, IconButton, Input, OutlinedInput,
  Card, CardActionArea, CardMedia, CardContent, CardActions, Typography,
  Chip,
} from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';

import { AddressEditorControl } from './input/places';
import { ShipmentContentControl } from './input/goods';
import { FreightCarrierNameTypeaheadControl } from './input/freightCarriers';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import 'react-datepicker/dist/react-datepicker.css';
import bsCustomFileInput from 'bs-custom-file-input';
import { useDropzone } from 'react-dropzone'
import { v1 as uuid } from 'uuid';
import api from '../lib/api';
import global from '../lib/global';
import config from '../lib/config';
import format, { getCurrencySymbolString } from './format';
import val from './validation';
import action from './action';
import ui, { globalPushAttachmentContentViewerModal } from './ui';
import { globalPushPremiumFeature } from './premium';
import { FileInputControl } from './upload';
import MdCopy from '@material-ui/icons/FileCopy';

bsCustomFileInput.init();

const FieldContext = React.createContext(1);

const Output = ({ label = null, value = null }) => {
  const displayValue = typeof value === 'string'
    ? value.split('\n').map(i => <Box key={i} display="block" width="100%" mb={i === '' ? 2 : 0}>{i}</Box>)
    : <Box  display="block" width="100%">{value}</Box>;

  return <Box mb={1}>
    { label && <Typography component={'span'} variant="subtitle2">{label}</Typography> }
    { value && <Typography component={'span'} variant="body2">{displayValue}</Typography> }
  </Box>;
};

const Field = ({ label = null, field = null, formGroup = true, type = null, children }) => {
  const Content = ({ nestingLevel }) => {
    return <>
      <Box mt={1}>
        { label && <Typography variant="h6">{label}</Typography> }
        <Box mb={2}>
          { children }
        </Box>
        { field ? <val.Text field={field} type={type} /> : <></> }
      </Box>
    </>;
  };

  return <>
    <FieldContext.Consumer>{
      nestingLevel => {
        const nextLevel = (nestingLevel || 1) + 1;

        return (
          <FieldContext.Provider value={nextLevel}>
            <Content nestingLevel={nestingLevel} />
          </FieldContext.Provider>
        );
      }
    }</FieldContext.Consumer>
  </>;
};

const FreightCarrierName = ({ field = null, label, value, readOnly = false, transportMode = null, onChange }) => {
  const handleChange = (val) => {
    if (onChange) onChange((val || { name: '' }).name);
  };

  return <>
    <FreightCarrierNameTypeaheadControl
      field={field}
      label={label}
      value={value}
      readOnly={readOnly}
      onChange={handleChange}
      transportMode={transportMode}
    />
  </>;
};

const HSCode = ({ field = null, label, value, readOnly = false, required = false, onChange, ...rest }) => {
  const initialValue = (value || '').toString().replace(/^\s+/g, '').replace(/\s+$/g, '');
  const requiredError = initialValue === '' && required;

  if (readOnly) {
    return <Output label={label} value={value} />
  }

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const mask = '99.99.9999999';

  return <>
    <Field>
      <val.Context field={field}>{({ error, errorText }) => {
        return (
          <InputMask
            mask={mask}
            maskChar="_"
            readOnly={readOnly}
            disabled={readOnly}
            onChange={handleChange}
            defaultValue={initialValue}
            alwaysShowMask
          >{(inputProps) => (
            <TextField
              {...inputProps}
              label={label}
              fullWidth
              variant="outlined"
              error={error || requiredError}
              helperText={errorText}
              required={required}
              {...rest}
            >
            </TextField>
          )}</InputMask>
        );
      }}</val.Context>
    </Field>
  </>;
};

const TrackingCode = ({ field = null, label, value, transportMode, readOnly = false, required = false, onChange, ...rest }) => {
  const initialValue = (value || '').toString().replace(/^\s+/g, '').replace(/\s+$/g, '');
  const requiredError = initialValue === '' && required;

  if (readOnly) {
    return <Output label={label} value={value} />
  }

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const mask = config.shipmentTracking[transportMode]?.mask;

  return <>
    <Field>
      <val.Context field={field}>{({ error, errorText }) => {
        return (
          <InputMask
            mask={mask}
            maskChar="_"
            readOnly={readOnly}
            disabled={readOnly}
            onChange={handleChange}
            defaultValue={initialValue}
            alwaysShowMask
          >{(inputProps) => (
            <TextField
              {...inputProps}
              label={label}
              fullWidth
              variant="outlined"
              error={error || requiredError}
              helperText={errorText}
              required={required}
              {...rest}
            >
            </TextField>
          )}</InputMask>
        );
      }}</val.Context>
    </Field>
  </>;
};

const Text = ({ field = null, label, value, type = 'text', forceTextField = false, readOnly = false, required = false,max=null, onChange, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValue = (value || '').toString().replace(/^\s+/g, '').replace(/\s+$/g, '');
  const requiredError = initialValue === '' && required;

  let currValue = initialValue;

  const handleChange = (e) => {
    if(max && e.target.value.length > max){
      e.target.value = e.target.value.slice(0, max);
      currValue = e.target.value.slice(0, max);
      onChange(e.target.value);
    } else {
    currValue = e.target.value;

    onChange(e.target.value);
    }
  };

  const handleCopyToClipboard = (e) => {
    copyToClipboard(currValue);

    enqueueSnackbar(i18n.t('common:actions.copyToClipboard.successMessage'), { variant: 'success' });
  };

  if (readOnly && !forceTextField) {
    return <Output label={label} value={value} />
  }

  return <>
    <Field>
      <val.Context field={field}>{({ error, errorText }) => {
        if (type === 'textarea') {
          return <TextField
            label={label}
            readOnly={readOnly}
            disabled={readOnly}
            defaultValue={value}
            onChange={handleChange}
            multiline
            fullWidth
            variant="outlined"
            error={error || requiredError}
            helperText={errorText}
            required={required}
            {...rest}
          />;
        } else if (type === 'key') {
          return <TextField
            label={label}
            readOnly={readOnly}
            defaultValue={value}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={error || requiredError}
            helperText={errorText}
            required={required}
            InputProps={{
              endAdornment: <Box pl={2}>
                <IconButton size="small" onClick={handleCopyToClipboard}><MdCopy /></IconButton>
              </Box>,
              readOnly: readOnly,
            }}
            {...rest}
          />;
        } else if (type === 'number') {
          return <TextField
            type="number"
            label={label}
            readOnly={readOnly}
            disabled={readOnly}
            defaultValue={value}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={error || requiredError}
            helperText={errorText}
            required={required}
            {...rest}
          />;
        } else if (type === 'file') {
          return <TextField
            type="file"
            label={label}
            readOnly={readOnly}
            disabled={readOnly}
            defaultValue={value}
            onChange={onChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={error || requiredError}
            helperText={errorText}
            required={required}
            {...rest}
          />;
        } else {
          return <TextField
            label={label}
            readOnly={readOnly}
            disabled={readOnly}
            defaultValue={value}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={error || requiredError}
            helperText={errorText}
            required={required}
            {...rest}
          />;
        }
      }}</val.Context>
    </Field>
  </>;
};

const Search = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return <>
    <OutlinedInput
      onChange={handleChange}
      fullWidth
      endAdornment={
        <InputAdornment position="start">
          <MdSearch />
        </InputAdornment>
      }
      {...rest}
    />
  </>;
});

const Money = ({ field, label, value, placeholder = null, readOnly = false, asField = true, onChange, ...rest }) => {
  const [ id ] = React.useState(uuid());

  const state = observable(value || {
    value: null,
    currencyCode: global.user.orgUnit.currencyCode || 'USD',
  });

  if (readOnly) {
    return <Output label={label} value={`${state.value} ${state.currencyCode}`} />
  }

  const change = () => {
    if (!state.currencyCode) {
      state.currencyCode = 'USD';
    }

    onChange(toJS(state));
  };

  const handleChange = (e) => {
    state.value = Number(e.target.value);

    change();
  };
  
  const handleCurrencyCodeChange = e => {
    state.currencyCode = e.target.value;

    change();
  };

  const keyDown = (e) => {
    if ([ '-' ].includes(e.key)) {
      e.preventDefault();
    }
  }

  const Wrapper = ({ children }) => {
    if (asField) {
      return <Field>{children}</Field>;
    } else {
      return <>{children}</>;
    }
  };

  return <Wrapper>
    <val.Context field={field}>{({ error, errorText }) => {
      return <Grid container spacing={1} alignItems="flex-start" justify="flex-end">
        <Grid item xs>
          <TextField
            label={label}
            readOnly={readOnly}
            disabled={readOnly}
            defaultValue={state.value || ''}
            onChange={handleChange}
            onKeyDown={keyDown}
            error={error}
            helperText={errorText}
            variant="outlined"
            fullWidth
            InputProps={{
              type: 'number',
              min: 0,
              step: 1,
            }}
          />
        </Grid>
        <Grid item>
          <Observer>{() => {
            console.log("cuur exchange rates: ", global.currencyExchangeRates);
            return <Select
              disabled={readOnly}
              onChange={handleCurrencyCodeChange}
              variant="outlined"
              defaultValue={state.currencyCode}
              fullWidth
              label={label}
            >
              { global.preferredCurrencyCodes.map(currencyCode => {
                return <MenuItem key={currencyCode} value={currencyCode}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', width: '100%', minWidth: '10em' }}>
                    <div>{currencyCode}</div>
                    <div>{' '}</div>
                    <div className="text-right"><format.CurrencySymbol value={currencyCode} /></div>
                  </div>
                </MenuItem>
              }) }
              <Divider />
              { Object.keys(global.currencyExchangeRates).filter(currencyCode => !global.preferredCurrencyCodes.includes(currencyCode)).sort().map(currencyCode => {
                return <MenuItem key={currencyCode} value={currencyCode} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', width: '100%', minWidth: '10em' }}>
                    <div>{currencyCode}</div>
                    <div>{' '}</div>
                    <div className="text-right"><format.CurrencySymbol value={currencyCode} /></div>
                  </div>
                </MenuItem>
              }) }
            </Select>
          }}</Observer>
        </Grid>
      </Grid>
    }}</val.Context>
  </Wrapper>;
};

const PositiveIntegerNumber = ({ field, label, value = null, readOnly = false, required = false, onChange }) => {
  const initialValue = (value ?? '').toString().replace(/^\s+/g, '').replace(/\s+$/g, '');
  const requiredError = initialValue === '' && required;

  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/[-.]/g, '');

    onChange(e.target.value);
  };

  const keyDown = (e) => {
    if ([ '-', '.' ].includes(e.key)) {
      e.preventDefault();
    }
  }

  return <>
    <val.Context field={field}>{({ error, errorText }) => {
      return <TextField
        label={label}
        fullWidth
        variant="outlined"
        type="number"
        min="1"
        step="1"
        error={error || requiredError}
        helperText={errorText}
        readOnly={readOnly}
        disabled={readOnly}
        defaultValue={value ?? ''}
        onChange={handleChange}
        onKeyDown={keyDown}
        required={required}
      />
    }}</val.Context>
  </>;
};

const Check = ({ label, value, readOnly = false, onChange, type = 'switch', formGroup = true, forceControl = false }) => {
  const [ id ] = React.useState(uuid().toString());

  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  if (readOnly && !forceControl) {
    return <Output label={label} value={<format.BooleanString value={value} />} />;
  }

  const Wrap = ({ children }) => {
    if (!formGroup) return <>{children}</>;

    return <Field>
      {children}
    </Field>;
  };

  switch (type) {
    case 'switch':
      return <Wrap>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              disabled={readOnly}
              label={label}
              onChange={handleChange}
              color="primary"
            />
          }
          label={label}
        />
      </Wrap>
    
    default:
      return <Wrap>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              disabled={readOnly}
              label={label}
              onChange={handleChange}
              color="primary"
            />
          }
          label={label}
        />
      </Wrap>
  }
  return <Form.Check disabled={readOnly} custom id={id} type={type} label={label} checked={value} onChange={handleChange} readOnly={readOnly} />
};

const TransportMode = ({ field, label, value, readOnly = false, mode = 'form', onChange }) => {
  const DISABLE_LAND = true;

  const invokeChange = (val) => {
    if (DISABLE_LAND && val === 'land') {
      globalPushPremiumFeature('transportModeLand');

      return;
    }

    onChange(val);
  };

  const handleChange = (e) => {
    invokeChange(e.target.value);
  };

  if (readOnly) {
    return <Output label={label} value={<format.TransportMode value={value} />} />;
  }

  return mode === 'form' ? <>
    <val.Context field={field}>{({ error, errorText }) => {
      return <Field>
        <Output label={label} />
        <RadioGroup value={value} onChange={handleChange} readOnly={readOnly} disabled={readOnly} row>
          <FormControlLabel value="air" control={<Radio />} label={<format.TransportMode value="air" />} readOnly={readOnly} disabled={readOnly} />
          <FormControlLabel value="sea" control={<Radio />} label={<format.TransportMode value="sea" />} readOnly={readOnly} disabled={readOnly} />
          <FormControlLabel value="land" control={<Radio />} label={<format.TransportMode value="land" />} readOnly={readOnly} disabled={readOnly || DISABLE_LAND} />
        </RadioGroup>
      </Field>;
    }}</val.Context>
  </> : <>
    <Box
      display="grid"
      justifyContent="center"
      gridColumnGap="3rem"
      gridTemplateColumns="min-content min-content min-content"
    >
      <div>
        <action.Action mode="icon" onClick={() => invokeChange('air')}>
          <format.TransportMode value="air" mode="icon-huge" />
        </action.Action>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" component="p" align="center">
            {i18n.t('help:transportMode.air')}
          </Typography>
        </Box>
      </div>
      <div>
        <action.Action mode="icon" onClick={() => invokeChange('sea')}>
          <format.TransportMode value="sea" mode="icon-huge" />
        </action.Action>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" component="p" align="center">
            {i18n.t('help:transportMode.sea')}
          </Typography>
        </Box>
      </div>
      <div>
        <action.Action mode="icon" onClick={() => invokeChange('land')}>
          <format.TransportMode value="land" mode="icon-huge" />
        </action.Action>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" component="p" align="center">
            {i18n.t('help:transportMode.land')}
          </Typography>
        </Box>
      </div>
    </Box>
  </>;
};

const TransportType = ({ field, label, value, readOnly = false, onChange }) => {
  const [ state, setState ] = React.useState(value);

  const [ id ] = React.useState(uuid());

  const handleChange = (e) => {
    onChange(e.target.value);
    setState(e.target.value);
  };

  if (readOnly) {
    return <Output label={label} value={<format.TransportType value={value} />} />
  }

  return <>
    <val.Context field={field}>{({ error, errorText }) => {
      return <Field>
        { label && <FormLabel component="label">{label}</FormLabel> }
        <RadioGroup value={value} onChange={handleChange} readOnly={readOnly} disabled={readOnly} row>
          <FormControlLabel value="any" control={<Radio />} label={<format.TransportType value="any" />} readOnly={readOnly} disabled={readOnly} />
          <FormControlLabel value="direct" control={<Radio />} label={<format.TransportType value="direct" />} readOnly={readOnly} disabled={readOnly} />
          <FormControlLabel value="transshipment" control={<Radio />} label={<format.TransportType value="transshipment" />} readOnly={readOnly} disabled={readOnly} />
        </RadioGroup>
        { error && <FormHelperText error>{errorText}</FormHelperText> }
      </Field>;
    }}</val.Context>
  </>;
};

const ShipmentOperationalState = ({ value, readOnly = false, formGroup = true, onChange, ...rest }) => {
  const list = [
    'pending',
    'pickedup',
    'inwarehouse',
    'inport',
    'incustomsclearance',
    'departed',
    'arrived',
    'customscleared',
    'delivered'
  ];

  const options = list.reduce((acc, curr) => {
    acc[curr] = i18n.t(`common:states.shipmentOperationalStates.${curr}`);
    return acc;
  }, {});

  return <StaticSelect
    value={value || 'pending'}
    values={options}
    formGroup={formGroup}
    readOnly={readOnly}
    showKeys={false}
    onChange={onChange}
    sort={false}
    {...rest}
  />;
};

const StaticSelect = ({ field, label, value, values, readOnly = false, showKeys = true, formGroup = true, sort = true, onChange, ...rest }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const list = sort
    ? Object.keys(values).sort()
    : Object.keys(values);

  if (readOnly) {
    return <Output label={label} value={`${showKeys ? value + ': ' : ''}${values[value]}`} />;
  }

  return <>
    <Field formGroup={formGroup}>
      <val.Context field={field}>{({ error, errorText }) => {
        return <>
          { label && <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {label}
          </InputLabel> }
          <Select
            value={value}
            variant="outlined"
            label={label}
            onChange={handleChange}
            readOnly={readOnly}
            disabled={readOnly}
            fullWidth
            error={error}
            //helperText={errorText}
            {...rest}
          >
            {list.map((i, index) => <MenuItem key={index} value={i}>
              { showKeys
                ? `${i} (${values[i]})`
                : `${values[i]}`
              }
            </MenuItem>)}
          </Select>
        </>
      }}</val.Context>
    </Field>
  </>;
};

export const StaticTags = ({
  readOnly = false,
  value = [],
  values,
  sort,
  label = null,
  onChange,
  ...rest
}) => {
  const options = React.useMemo(() => {
    const list = Object.keys(values).map(i => ({ value: i, label: values[i] }));
    if (sort) {
      return list.sort((a, b) => {
        // ascending order
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
    } else {
      return list;
    }
  }, [ values ]);

  const selectedValues = React.useMemo(() => options.filter(i => {
    return value.includes(i.value);
  }), [ values, value, options ]);

  const handleChange = React.useCallback((e, selectedOptions) => {
    if (onChange) {
      onChange(selectedOptions.map(i => i.value));
    }
  }, [ onChange ]);

  if (readOnly) {
    return <Output label={label} value={<Box display="flex" flexWrap="wrap">
      { selectedValues.map((i, index) => {
        return <Box key={index} m={0.5} ml={index === 0 ? 0 : 0.5} mr={index === selectedValues.length - 1 ? 0 : 0.5}>
          <Chip label={i.label} variant="outlined" {...rest} />
        </Box>;
      }) }
    </Box>} />;
  };

  return (
    <MuiAutocomplete
      onChange={handleChange}
      multiple
      defaultValue={selectedValues}
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option.label}
      renderOption={(option, { selected }) => {
        return <>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>;
      }}
      fullWidth
      renderInput={(params) => {
        return <>
          <TextField {...params} variant="outlined" label={label} />
        </>;
      }}
    />
  );
};

const CustomsClearance = ({ field, value, shipmentType, incoterms, readOnly = false, onChange }) => {
  const label = shipmentType === 'import'
    ? i18n.t('common:fields.shipment.customsClearance@destination')
    : i18n.t('common:fields.shipment.customsClearance@origin');

  const enabled = shipmentType === 'import'
    ? [ 'CFR', 'CIF', 'CIP', 'CPT', 'EXW', 'FAS', 'FCA', 'FOB' ].includes(incoterms)
    : [ 'CFR', 'CIF', 'CIP', 'CPT', 'DAP', 'DDP', 'DPU', 'FAS', 'FCA', 'FOB' ].includes(incoterms);

  return <>
    { enabled && <Check readOnly={readOnly} value={value} label={label} onChange={onChange} /> }
  </>;
};

const Incoterms = ({ field, label, value, shipmentType, shippingTerms = null, readOnly = false, onChange }) => {
  const list = [
    { key: "CFR", D2D: false, D2P: true, P2D: false, P2P: true, import: true, export: true },
    { key: "CIF", D2D: false, D2P: true, P2D: false, P2P: true, import: true, export: true },
    { key: "CIP", D2D: false, D2P: true, P2D: false, P2P: true, import: true, export: true },
    { key: "CPT", D2D: false, D2P: true, P2D: false, P2P: true, import: true, export: true },
    { key: "DAP", D2D: true, D2P: false, P2D: true, P2P: false, import: false, export: true },
    { key: "DDP", D2D: true, D2P: false, P2D: true, P2P: false, import: false, export: true },
    { key: "DPU", D2D: true, D2P: false, P2D: true, P2P: false, import: false, export: true },
    { key: "EXW", D2D: true, D2P: true, P2D: false, P2P: false, import: true, export: false },
    { key: "FAS", D2D: false, D2P: false, P2D: true, P2P: true, import: true, export: true },
    { key: "FCA", D2D: true, D2P: true, P2D: false, P2P: false, import: true, export: true },
    { key: "FOB", D2D: false, D2P: false, P2D: true, P2P: true, import: true, export: true },
  ];

  const filtered = list.filter(i => i[shipmentType] && (!shippingTerms || i[shippingTerms]));

  const terms = filtered.reduce((acc, curr) => {
    acc[curr.key] = i18n.t(`common:incoterms.${curr.key}`);
    return acc;
  }, {});

  if (!(value in terms)) {
    value = Object.keys(terms)[0];

    onChange(value);
  }

  return <>
    <StaticSelect field={field} label={label} value={value} onChange={onChange} values={terms} readOnly={readOnly} />
  </>;
};

const ShippingTerms = ({ field, label, value, readOnly = false, onChange }) => {
  const terms = {
    'D2D': i18n.t(`common:fieldValues.shipment.shippingTerms.D2D`),
    'D2P': i18n.t(`common:fieldValues.shipment.shippingTerms.D2P`),
    'P2P': i18n.t(`common:fieldValues.shipment.shippingTerms.P2P`),
    'P2D': i18n.t(`common:fieldValues.shipment.shippingTerms.P2D`),
  };

  return <StaticSelect field={field} label={label} value={value} onChange={onChange} values={terms} readOnly={readOnly} />;
};

const Address = ({ field, label, value, readOnly = false, type = null, transportMode = null, onChange }) => {
  const handleChange = address => {
    console.log('address: ', address);
    if (onChange) onChange(address);
  };

  return <Field>
    <val.Context field={field}>{({ error, errorText }) => <>
      <val.Container field={field} type="location">
        <AddressEditorControl readOnly={readOnly} value={value} onChange={handleChange} type={type} transportMode={transportMode} label={label} field={field} error={error} errorText={errorText}  />
      </val.Container>
    </>}</val.Context>
  </Field>;
};

const DatePicker = ({ field, label, value, onChange, minDate = new Date(), maxDate = null, readOnly = false, disablePast = true, format = 'YYYY-MM-DD',hasError=false, ...rest }) => {
  const [startDate, setStartDate] = React.useState(new Date(value || new Date()));

  if (readOnly) {
    return <Output label={label} value={moment(value).format('YYYY-MM-DD')} />
  }

  const changeValue = (val) => {
    setStartDate(val);

    if (onChange) onChange(val);
  };

  if (value === null) {
    changeValue(new Date());
  }

  const hardMinDate = new Date(minDate || api.today());
  const hardMaxDate = new Date(maxDate || api.future().months(3));

  return <Field>
    <val.Context field={field}>{({ error, errorText }) => {
      return <MuiDatePicker
        label={label}
        value={startDate}
        onChange={changeValue}
        onAccept={changeValue}
        autoOk
        disablePast={disablePast}
        showTodayButton
        readOnly={readOnly}
        disabled={readOnly}
        format={format}
        inputVariant="outlined"
        variant="dialog"
        error={hasError}
        helperText={errorText}
        minDate={hardMinDate}
        maxDate={hardMaxDate}
        orientation="landscape"
        DialogProps={{
          size: 'sm',
        }}
        {...rest}
      />
    } }</val.Context>
  </Field>;
};

const Attachment = ({ value, placeholder, mode = 'json', showRemove = true, readOnly = false, label, onChange }) => {
  const nullFile = { name: null, size: 0, mimetype: 'application/octet-stream', data: null, url: null };

  const [ state, setState ] = React.useState(value || { ...nullFile });
  const [ loading, setLoading ] = React.useState(false);
  const [ id ] = React.useState(uuid().toString());

  const Buffer =  require('buffer/').Buffer;
  window.Buffer = window.Buffer || Buffer;
  const arrayBufferToBuffer = require('arraybuffer-to-buffer');
  if (value && value !== state) {
    setState(value);
  }

  const setFile = (file) => {
    if (!file) {
      setState({ ...nullFile });
      if (onChange) onChange(null);
    } else {
      if (mode === 'file') {
        if (onChange) onChange(file);
        return;
      }

      const reader = new FileReader();
      reader.onload = (readEvent) => {
        console.log(readEvent);
        setLoading(false);

        const data = {
          name: file.name,
          mimetype: file.type,
          size: file.size,
          data: arrayBufferToBuffer(readEvent.target.result),
        };

        console.debug('data: ', data);

        setState(data);

        if (onChange) onChange(data);
      };
      reader.onerror = (readEvent) => {
        setLoading(false);

        api.setLastError(`Failed reading file '${file.name}': ${readEvent.target.error}`);
      };

      setLoading(true);

      reader.readAsArrayBuffer(file);
    }
  };

  const handleRemove = () => {
    const data = { ...nullFile };

    setState(data);

    if (onChange) onChange(null);
  };

  const handleChange = (file) => {
    setFile(file);
  };

  const handleDownload = () => {
    if (state.data) {
      api.downloadBlob(new Blob([ state.data ]), state.name);
    } else if (state.url) {
      console.log('view: ', state);
      globalPushAttachmentContentViewerModal(state);

      /*
      setLoading(true);
     
      api.browserDownloadUrl(state.url).then(() => {
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });*/
    }
  }

  const text = (state.name ? `${state.name} (${format.FileSizeString({ bytes: state.size })})` : null) || placeholder || '';

  const Download = ({ label, asLink }) => {
    return (state.data || state.url) ? <>
      { asLink ? <>
        <a className="link" onClick={handleDownload} disabled={loading}>{label}</a>
      </> : <>
        <IconButton color="primary" onClick={handleDownload} disabled={loading}>
          <CloudDownloadIcon />
        </IconButton>
      </> }
    </> : <>{asLink ? label : <></>}</>;
  };

  return <>
    { readOnly ? <>
      <Download label={text} asLink={true} />
    </> : <>
      <Grid container spacing={1} alignItems="center" justify="flex-end">
        <Grid item xs>
          <FileInputControl value={text} readOnly={readOnly || loading} onChange={handleChange} label={label} />
        </Grid>
        { (state.data || state.url) && <>
          <Grid item>
            <Download asLink={false} />
          </Grid>

          { showRemove && <>
            <Grid item>
              <IconButton onClick={handleRemove}><DeleteForeverIcon /></IconButton>
            </Grid>
          </> }
        </> }
      </Grid>
    </>}
  </>;
};

const ShipmentContent = ({ field, value, transportMode, readOnly = false, onChange }) => {
  return <ShipmentContentControl field={field} transportMode={transportMode} value={value} onChange={onChange} readOnly={readOnly} />;
};

export const Attachments = ({ value, readOnly, onChange }) => {
  const [ state, setState ] = React.useState(value || []);
  const [ loading, setLoading ] = React.useState(0);

  const remove = index => {
    const temp = state.filter((i, itemIndex) => itemIndex !== index);

    setState([ ...temp ]);

    if (onChange) onChange(temp);
  };

  const handleChange = (index, val) => {
    state[index] = val;

    setState([ ...state ]);

    if (onChange) onChange([ ...state ]);
  };

  const addFile = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (readEvent) => {
      setLoading(loading - 1);

      const data = {
        name: file.name,
        mimetype: file.type,
        size: file.size,
        // data: arrayBufferToBuffer(readEvent.target.result),
      };

      state.push(data);

      setState([ ...state ]);

      if (onChange) onChange(state);

      callback();
    };
    reader.onerror = (readEvent) => {
      setLoading(loading - 1);

      api.setLastError(`Failed reading file '${file.name}': ${readEvent.target.error}`);

      callback();
    };

    setLoading(loading + 1);

    reader.readAsArrayBuffer(file);
  };
  
  const onDrop = acceptedFiles => {
    const list = [ ...acceptedFiles ];

    const check = () => {
      if (list.length === 0) return;

      const file = list.pop();

      addFile(file, check);
    };

    check();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return <>
    <div>
      <Container fluid>
        { state.map((i, index) => <React.Fragment key={index}>
          <Row className="list-item justify-content-md-center">
            <Col style={{ paddingLeft: 0 }}>
              <Attachment value={i} showRemove={false} readOnly={readOnly} onChange={() => handleChange(index, i)} />
            </Col>
            { readOnly ? <></> : <>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} lg="auto">
                <Button onClick={() => remove(index)}>X</Button>
              </Col>
            </>}
          </Row>
        </React.Fragment>)}
      </Container>
    </div>
    { (readOnly || loading > 0) ? <></> : <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant={isDragActive ? 'outline-success' : 'light' } style={{ padding: 40, width: '100%' }}>
        {
          isDragActive ?
            <>Drop the files here ...</> :
            <>Drag 'n' drop some files here, or click to select files</>
        }
        </Button>
      </div>
    </> }
  </>;
};

export const ShipmentType = ({ field, label, value, readOnly, onChange }) => {
  return <StaticSelect field={field} showKeys={false} label={label} value={value} values={{ export: 'Export', import: 'Import' }} readOnly={readOnly} onChange={onChange} />
};

export const CurrencyCode = ({ field, label, value, readOnly, onChange }) => {
  const list = {};

  global.preferredCurrencyCodes.forEach(currencyCode => {
    list[currencyCode] = getCurrencySymbolString(currencyCode);
  });

  Object.keys(global.currencyExchangeRates).filter(currencyCode => !global.preferredCurrencyCodes.includes(currencyCode)).sort().forEach(currencyCode => {
    list[currencyCode] = getCurrencySymbolString(currencyCode);
  });

  return <StaticSelect field={field} showKeys={true} label={label} value={value} values={list} readOnly={readOnly} onChange={onChange} />
};

export const ForwarderTags = ({
  label = null,
  value = [],
  onChange,
  readOnly,
  ...rest
}) => {
  const TAGS = [
    'dangerousGoods',
    'valuableItems',
    'vulnerableItems',
    'perishableShipments',
    'bioPharmTransport',
    'bioSampleNextDay',
    'medicalSupplies',
    'liveAnimals',
    'import',
    'export',
    'customsClearance',
    'conventionalCargo',
    'oversizeCargo',
    'heavyCargo',
    'transportManagement',
    'containerManagement',
    'doorToDoorService',
    'portToPortService',
    'airFreight',
    'seaFreight',
    'landFreight',    
  ];

  const options = TAGS.reduce((acc, curr) => {
    acc[curr] = i18n.t(`tags:forwarder.${curr}`);
    return acc;
  }, {});

  return <StaticTags
    label={label}
    value={value}
    values={options}
    onChange={onChange}
    readOnly={readOnly}
    sort
    color="primary"
    {...rest}
  />;
};

export default {
  Field,
  Text,
  Search,
  Money,
  Check,
  StaticSelect,
  StaticTags,
  TransportMode,
  TransportType,
  CustomsClearance,
  Incoterms,
  ShippingTerms,
  Address,
  DatePicker,
  Attachment,
  Attachments,
  PositiveIntegerNumber,
  ShipmentContent,
  ShipmentType,
  CurrencyCode,
  FreightCarrierName,
  ShipmentOperationalState,
  ForwarderTags,
  TrackingCode,
  HSCode,
};
