import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container as BootstrapContainer, Row, Col } from 'react-bootstrap';
import { Paper, Box } from '@material-ui/core';
import MdWarning from '@material-ui/icons/Warning';
import MdError from '@material-ui/icons/Error';

export const ValidationContext = React.createContext(null);

export const ShipmentValidationSchema = {
  type: 'shipment',
  keys: {
    pickupFrom: {
      type: 'location',
    },
    deliverTo: {
      type: 'location',
    },
    shipmentContent: {
      type: 'shipmentContent',
      keys: {
        hazardousMaterial: {
          type: 'hazardousMaterial'
        },
        physicalProps: {
          type: 'physicalProps'
        }
      }
    }
  }
};

export const ShipmentContractValidationSchema = {
  type: 'shipmentContract',
  keys: {
    prices: {
      type: 'shipmentContractPrices',
    },
  },
};

export const ShipmentContentValidationSchema = {
  type: 'shipmentContent',
  keys: {
    physicalProps: {
      type: 'physicalProps',
    },
    hazardousMaterial: {
      type: 'hazardousMaterial'
    }
  },
};

const Container = ({ field = null, index = null, value = undefined, type = null, children }) => {
  if (value !== undefined) { // <null> is valid!!!
    return <>
      <ValidationContext.Provider value={value || {}}>
        {children}
      </ValidationContext.Provider>
    </>;
  } else if (field) {
    return <>
      <ValidationContext.Consumer>{ parentValue => {
        const parent = parentValue || {};
        let curr = parent[field] || {};

        if (index !== null) {
          curr = curr[index];
        }

        if (type != null) {
          curr.__type = type;
        }

        return <ValidationContext.Provider value={curr}>
          {children}
        </ValidationContext.Provider>
      }}</ValidationContext.Consumer>
    </>;
  } else {
    return <>validation Context.Container with no /value/ and no /field/ props</>;
  }
};

const Context = ({ field = null, type = null, children }) => {
  const { t } = useTranslation();

  return <>
    <ValidationContext.Consumer>{ parentValue => {
      const parent = parentValue || {};
      const curr = parent[field];

      let error = false;
      let text = null;

      if (curr) {
        const textPrefx = type ? t(`common:fields:${type || parent.__type}.${field}`) + ': ' : '';

        error = true;
        text = textPrefx + t(`common:validation.check.${curr.check || 'object'}`);
      }

      return <>
        {children({ error: error, errorText: text })}
      </>;
    }}</ValidationContext.Consumer>
  </>;
};

const Text = ({ field = null, type = null }) => {
  const { t } = useTranslation();

  return <>
    <ValidationContext.Consumer>{ parentValue => {
      const parent = parentValue || {};
      const curr = parent[field];

      if (curr) {
        const textPrefx = type ? t(`common:fields:${type || parent.__type}.${field}`) + ': ' : '';
        const item = {
          text: textPrefx + t(`common:validation.check.${curr.check || 'object'}`),
          severity: curr.severity || 'error',
        };

        const style = {
        };
        let Icon = <></>;
        let color = '';

        if (item.severity === 'error') {
          color = 'error.main';
          Icon = MdError;
        } else {
          color = 'warning.main';
          Icon = MdWarning;
        }

        return <>
          <Box color={color} mb={1}>
            <Icon />
            {' '}
            {item.text}
          </Box>
        </>;
      } else {
        // No validation messages
        return <></>;
      }
    }}</ValidationContext.Consumer>
  </>;
};

const Frame = ({ field = null, softError = false, children }) => {
  const maxSeverity = (curr) => {
    const str = JSON.stringify(curr);
    if (str.indexOf('"severity":"error"') >= 0) return 'error';
    if (str.indexOf('"severity":"warn"') >= 0) return 'warn';
    return null;
  }

  return <>
    <ValidationContext.Consumer>{ parentValue => {
      const parent = parentValue || {};
      const curr = field ? parent[field] : parent;

      const severity = maxSeverity(curr);

      const style = {
      };
      const iconStyle = { width: '1.5em', height: '1.5em', marginLeft: '1.5em', marginRight: '1.5em' };
      let Icon = <></>;

      if (severity === 'error' && !softError) {
        style.backgroundColor = 'rgba(255, 0, 0, 0.2)';
        iconStyle.color = 'red';
        Icon = <MdError />;
      } else if (severity) {
        style.backgroundColor = 'rgba(255, 128, 0, 0.2)';
        iconStyle.color = 'orange';
        Icon = <MdWarning style={iconStyle} />;
      } else {
        style.backgroundColor = 'rgba(0, 0, 0, 0.03)';
      }

      return <>
        <div style={{ padding: 5, width: '100%', ...style }}>
        <BootstrapContainer fluid>
          <Row className="justify-content-md-center">
            <Col style={{ margin: 0, padding: 0 }} size="auto">
              {children}
            </Col>
            <Col style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center' }} sm="auto">
              {Icon}
            </Col>
          </Row>
        </BootstrapContainer>
        </div>
      </>;
    }}</ValidationContext.Consumer>
  </>;
};

const SummaryContainer = ({ field = null, softError = false, children }) => {
  const maxSeverity = (curr) => {
    const str = JSON.stringify(curr);
    if (str.indexOf('"severity":"error"') >= 0) return 'error';
    if (str.indexOf('"severity":"warn"') >= 0) return 'warn';
    return null;
  }

  return <>
    <ValidationContext.Consumer>{ parentValue => {
      const parent = parentValue || {};
      const curr = field ? parent[field] : parent;

      const severity = maxSeverity(curr);

      if (!severity) {
        return <></>;
      }

      return <>
        {children}
      </>;
    }}</ValidationContext.Consumer>
  </>;
};

const SuccessContainer = ({ field = null, softError = false, children }) => {
  const maxSeverity = (curr) => {
    const str = JSON.stringify(curr);
    if (str.indexOf('"severity":"error"') >= 0) return 'error';
    if (str.indexOf('"severity":"warn"') >= 0) return 'warn';
    return null;
  }

  return <>
    <ValidationContext.Consumer>{ parentValue => {
      const parent = parentValue || {};
      const curr = field ? parent[field] : parent;

      const severity = maxSeverity(curr);

      if (!severity) {
        return <>
          {children}
        </>;
      } else {
        return <></>;
      }
    }}</ValidationContext.Consumer>
  </>;
};

export const getValidationResults = (validation, schema) => {
  const getAllResults = (parent, schemaNode = null, path = [], parentKey = null) => {
    if (!parent) return [];
    if (!schemaNode) schemaNode = schema;

    const type = schemaNode.type;

    const result = [];

    if (parent.severity && parent.field) {
      parent.__type = type;
      parent.__path = [ ...path ];
      result.push({ ...parent });
    }
    else if (parent instanceof Array) {
      const childSchema = (schemaNode.keys || {})[parentKey] || schemaNode;

      path.push({
        type: type,
        field: parentKey
      });
  
      parent.forEach(i => {
        getAllResults(i, childSchema, path).forEach(r => result.push(r));
      });

      path.pop();
    }
    else if (parent instanceof Object) {
      Object.keys(parent).forEach(key => {
        const val = parent[key];

        const childSchema = (schemaNode.keys || {})[key] || schemaNode;

        path.push({
          type: type,
          field: key
        });

        getAllResults(val, childSchema, path, key).forEach(r => result.push(r));

        path.pop();
      });
    }

    return result;
  };

  return getAllResults(validation, schema);
};

export const getValidationResultsSummary = (validation, schema) => {
  const issues = getValidationResults(validation, schema);

  if (issues.length > 0) {
    const summary = issues.reduce((acc, curr) => {
      if (curr.severity === 'error') {
        ++acc.errors;
      } else {
        ++acc.warnings;
      }

      ++acc.total;

      return acc;
    }, { total: 0, errors: 0, warnings: 0 });

    return summary;
  } else {
    return {
      errors: 0,
      warnings: 0,
      total: 0,
    };
  }
};

const Summary = ({ schema = {} }) => {
  const { t } = useTranslation();


  return <>
    <ValidationContext.Consumer>{ parentValue => {
      const all = getValidationResults(parentValue, schema);

      console.log('getAllResults: ', all);

      if (!all.length) {
        return <></>;
      }

      return <Paper><Box p={2}>{ all.map((i, index) => {
        const severity = i.severity;
      
        const style = {
        };
        
        const iconStyle = { width: '1.5em', height: '1.5em', marginRight: '0.5em' };
        
        let Icon = <></>;
        let color = null;
  
        if (severity === 'error') {
          style.backgroundColor = 'rgba(255, 0, 0, 0.2)';
          iconStyle.color = 'red';
          Icon = <MdError />;
          color = 'error.main';
        } else {
          style.backgroundColor = 'rgba(255, 128, 0, 0.2)';
          iconStyle.color = 'orange';
          Icon = <MdWarning style={iconStyle} />;
          color = 'warning.main';
        }
  
        const pathPrefix = i.__path.map(path => t(`common:fields:${path.type}.${path.field}`)).join(': ');
        const text = pathPrefix + ': ' + t(`common:validation.check.${i.check}`);

        return <React.Fragment key={index}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <Box color={color} pr={1}>
              {Icon}
            </Box>
            <Box color={color}>
              {text}
            </Box>
          </Box>
        </React.Fragment>;
      })}</Box></Paper>;
    }}
    </ValidationContext.Consumer>
  </>;
};

export default {
  ValidationContext,
  Context,
  Container,
  Text,
  Frame,
  SuccessContainer,
  SummaryContainer,
  Summary,

  getValidationResults,
  getValidationResultsSummary,
};
