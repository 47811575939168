import React from 'react';
import i18n from 'i18next';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';

import {
  Typography, Box, Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';

import {
  Rating,
} from '@material-ui/lab';

import ui from './ui';
import input from './input';
import format from './format';
import action from './action';

import api from '../lib/api';

export const ForwarderFeedbackForm = ({ value = {}, onChange }) => {
  const uiState = observable({
    value: {
      availabilityScore: null,
      professionalismScore: null,
      punctualityScore: null,
      comments: null,

      ...(value || {})
    },
  });

  const change = () => {
    if (onChange) onChange(toJS(uiState.value));
  };

  const set = (key, val) => {
    uiState.value[key] = val;

    change();
  };

  const FieldHeader = ({ name }) => {
    const titleKey = `common:rating.forwarder.${name}.title`;
    const subtitleKey = `common:rating.forwarder.${name}.subtitle`;

    const title = i18n.exists(titleKey) ? i18n.t(titleKey) : '';
    const subtitle = i18n.exists(subtitleKey) ? i18n.t(subtitleKey) : '';
    
    return <>
      <Box mb={1}>
        { title !== '' && <Typography variant="h6">{title}</Typography>}
        { subtitle !== '' && <div><Typography variant="subtitle">{subtitle}</Typography></div>}
      </Box>
    </>;
  };

  const RatingField = ({ name }) => {
    return <Observer>{() => <>
      <Box>
        <input.Field>
          <FieldHeader name={name} />
          <Rating
            value={uiState.value[name]}
            onChange={(_, newValue) => set(name, newValue)}
          />
        </input.Field>
      </Box>
    </>}</Observer>;
  };

  return <div>
    <RatingField name="availabilityScore" />
    <RatingField name="professionalismScore" />
    <RatingField name="punctualityScore" />
    <Box>
      <input.Field>
        <FieldHeader name="comments" />
        <input.Text
          type="textarea"
          value={uiState.value.comments}
          onChange={val => set('comments', val)}
        />
      </input.Field>
    </Box>
  </div>;
};

export const OrgUnitFeedbackList = ({ orgUnitId }) => {
  const uiState = observable({
    list: [],
    offset: 0,
    limit: 100,
    endOfList: false,
    busy: false,
  });

  React.useEffect(() => {
    handleLoadMoreData();
  }, [ orgUnitId ]);

  const handleLoadMoreData = React.useCallback(() => {
    if (uiState.busy || uiState.endOfList) return;

    uiState.busy = true;

    api.querySharedOrgUnitRating(orgUnitId, { skip: uiState.offset, limit: uiState.limit }).then(response => {
      uiState.busy = false;

      if (!response.success) return;

      if (response.data.length === 0) {
        uiState.endOfList = true;
        return;
      }

      uiState.offset += response.data.length;

      uiState.list = [
        ...response.data,
      ];
    });
  }, [ orgUnitId ]);

  return <Observer>{() => {
    return <Box p={1}>
      {uiState.list.map(rating => <RatingItemView value={rating} />)}
      <action.AsyncMoreDataSentinel onAsyncLoad={handleLoadMoreData} />
    </Box>;
  }}</Observer>;
};

export const RatingItemView = ({ value, singleItem = false, ...rest }) => {
  const sumScore =
    value.body.availabilityScore +
    value.body.professionalismScore +
    value.body.punctualityScore;

  const avgScore = sumScore / 3;

  const Score = ({ name }) => {
    const val = value.body[name] || null;

    const textKey = `common:rating.forwarder.${name}.title`;

    return <Box ml={singleItem ? 0 : 1} mr={1}>
      <div style={{ display: 'inline-grid', alignItems: 'center', gridTemplateColumns: 'min-content min-content', gridColumnGap: '0.5em', columnGap: '0.5em' }}>
        <Typography variant="body1" noWrap>
          {i18n.t(textKey)}:
        </Typography>
        <Typography variant="h6">
          <format.NumericString value={val} />
        </Typography>
      </div>
    </Box>;
  };

  const Comments = ({}) => {
    if (!value.body.comments || value.body.comments === '') return <></>;

    return <Box mt={2}>
      { !singleItem && <hr /> }
      <Typography variant="h6">
        {i18n.t('common:rating.forwarder.comments.title')}
      </Typography>
      <Typography variant="body1">
        {value.body.comments}
      </Typography>
    </Box>;
  };

  const Wrap = ({ children }) => {
    if (singleItem) return <>{children}</>;

    return <Box mb={2}>
      <ui.Container {...rest}>
        <Box p={2} width="100%">
          {children}
        </Box>
      </ui.Container>
    </Box>;
  };

  return <>
    <Wrap>
      <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Score name="availabilityScore" />
          <Score name="professionalismScore" />
          <Score name="punctualityScore" />
        </div>
        <div>
          <format.Rank value={avgScore} />
        </div>
      </div>
      <Comments />
      { !singleItem && <hr /> }
      { !singleItem && <Box mt={2} width="100%" align="right">
        <Typography variant="body2"><format.DateText value={value.body.createdAt} /></Typography>
      </Box> }
    </Wrap>
  </>;
};
