import { Box, Button, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {AiFillFile,AiFillFilePdf} from "react-icons/ai"
import api from "../../lib/api";
import Format from "../format";
import { useMemo } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const options = [
//     {
//         id:"13123",
//         name:"forwarder1"
//     },
//     {
//         id:"13123",
//         name:"forwarder2"
//     }
// ]

const Name = ({id}) => {
    const [name, setName] = useState("");
    useEffect(() => {
        api.queryOrgUnitNameNiv(id).then(response => {
            setName(response.data)
        })
    },[])
    return <>{name}</>
}

export function ShowFilesModal({isActive,handleClose, shipment}){
    const [contracts, setContracts] = useState([]); 
    const [files, setFiles] = useState([]);
    const [names, setNames] = useState([]);

    const handleClick = async (id) =>{
        await api.downloadAttachmentById(id);
    }

    const renderSwitch = (param) => {
        switch(param) {
          case 'application/pdf':
            return <AiFillFilePdf/>;
          default:
            return <AiFillFile/>;
        }
    }

    useEffect(()=> {
      if(!shipment) return;
        api.getShipmentContractsNiv(shipment?.body._id).then(response => {
          setContracts(response)
        }).catch(error => {
          setContracts([])
        })
        api.queryAttachments({
            filter: {
              shipmentContractId: api.ObjectId(shipment?.body.shipmentContractId),
            }
          }).then(response => {
            if (!response.success) return;
            setFiles(response.data)
          });
          console.log(names);
    },[])

    const findName = async (orgUnitId) => {
      const name = await api.queryOrgUnitNameNiv(orgUnitId);
      return name.data
    }

const contractsFiles = useMemo(()=>contracts.map(contract => contract.files).flat(),[contracts]) 

    
    return <>
        <Modal
            open={isActive}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box sx={style}>
                <h2 id="parent-modal-title">Files of shipment #{shipment?.body.globalRecordNumber}</h2>
                {(files.length === 0 && contractsFiles.length === 0)&&<div>files not exists on this shipment yet!</div>}
                <div style={{display:"flex",flexDirection:"column",gap:"1rem", paddingBottom:"2rem"}}>
                    {files.map(file => {
                        return (<div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
                            <div style={{"maxWidth":"300px","overflowWrap":"break-word"}}>{file.body.content.name}</div>
                            {renderSwitch(file.body.content.mimetype)}
                            <div><Format.FileSizeString bytes={file.body.content.size} /></div>
                            <Button variant="contained" color="primary" onClick={() => handleClick(file.body._id)}>download</Button>
                        </div>)
                    })}
                    {(Array.isArray(contracts)?contracts : []).map(contract => {
                        return <div>
                          <h5><Name id={contract.orgUnitId}></Name></h5>
                          {contract.files.map(file => {
                              return (<div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
                                  <div style={{"maxWidth":"300px","overflowWrap":"break-word"}}>{file}</div>
                                  <Button variant="contained" color="primary" onClick={() => handleClick(file.body._id)}>download</Button>
                              </div>)
                          })}
                        </div>
                        })
                    }
                </div>
            </Box>
        </Modal>
    </>
}