import { Box, Button, Input, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../lib/api";
import global from "../../lib/global";
import input from '../input';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export function AddModal({ isActive, handleClose, orgUnitId }) {
    const nameRef = useRef();
    const [location, setLocation] = useState(null);
    const [type, setType] = useState("airport");

    const handleTypeSelect = (event) => {
        setLocation(null);
        setType(event.target.value)
    }

    const handleSave = async () => {
        api.addCompanyLocations({ orgUnitId: orgUnitId ?? global.user.orgUnit._id, name: nameRef.current.value, type: type, port: location.port ?? null, country: location.country, city: location.city, state: location.continent, comments: location.comments }).then(() => {
            setLocation(null);
            handleClose();
        });
    }

    return <>
        <Modal
            open={isActive}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2 id="parent-modal-title">Add Supplier</h2>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingBottom: "2rem" }}>
                    <Input inputRef={nameRef} placeholder="Name" />
                    {/* <Select
                        style={{ paddingTop: "1rem" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="type"
                        value={type}
                        onChange={handleTypeSelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="airport">airport</MenuItem>
                        <MenuItem value="seaport">seaport</MenuItem>
                    </Select> */}
                    <input.Address readOnly={false} value={location} label={"Location"} onChange={(setLocation)} field="pickupFrom" type={type} transportMode={null} />
                </div>
                <Button variant="contained" onClick={handleSave} color="primary">Save</Button>
            </Box>
        </Modal>
    </>
}