import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { css } from 'emotion';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import * as XLSX from 'xlsx';
import api from '../lib/api';
import format from './format';
import action, { globalPushShipmentSealModal } from './action';
import ui from './ui';
import data from './data';
import val, { ShipmentValidationSchema } from './validation';
import global, { globalPushModal, GlobalNavControl } from '../lib/global';
import { getShipmentRequiredPriceFields } from './shipmentContract.view';
import {Buffer} from 'buffer';

import createExcel from '../lib/excelSummary';

import MdViewList from '@material-ui/icons/Pageview';
import MdReview from '@material-ui/icons/ThumbsUpDown';

import {
  Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper, Button, Box,
  Typography,
} from '@material-ui/core';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';
import { ShowFilesModal } from './ShowFilesModal';

const styles = {
  auxActions: css({
    '& button': {
      whiteSpace: 'nowrap',
    }
  }),
};

const ShowFilesCell = ({handleClick,dataItem,selectedId,handleClose}) => {

  const [files, setFiles] = useState([]);
  const [contracts, setContracts] = useState([]); 

  useEffect(()=> {
    api.getShipmentContractsNiv(dataItem?.body._id).then(response => {
      setContracts(response)
    }).catch(err => {
      console.log(err)
    })
    api.queryAttachments({
        filter: {
          shipmentContractId: api.ObjectId(dataItem?.body.shipmentContractId),
        }
      }).then(response => {
        if (!response.success) return;
        setFiles(response.data)
      });
},[])

const contractsFiles = useMemo(()=>{
  if(!contracts || !Array.isArray(contracts) || contracts.length===0) return [];
  return contracts.map(contract => contract.files).flat()
}
  ,[contracts]
  ) 

  
  return (
    <>
    {
      (files.length>0 || contractsFiles.length>0 )&&
    <Button variant='contained' color='primary' onClick={()=>handleClick(dataItem.body._id)}>Show files</Button>
  }
    <ShowFilesModal isActive={dataItem.body._id===selectedId} handleClose={handleClose} shipment={dataItem}/>
    </>
  )
}

const Main = ({ state = null, marketplace = false, dashboard = false, mode = 'compact', hideChat = false, autoSize = true, ...rest }) => {
  const { t } = useTranslation();

  const uiState = observable({
    data: null,
  });

  const setData = (data = []) => {
    uiState.data = data;
  }

  const onFetchDataAsyncHandler = async ({ filter, sort, skip, limit, own, startDate = null, endDate = null }) => {
    const result = marketplace
      ? await api.querySharedShipmentsRFPs({ own: !!own, filter: filter, sort: sort, skip: skip, limit: limit, startDate: startDate, endDate: endDate })
      : await api.queryShipments({ own: !!own, filter: filter, sort: sort, skip: skip, limit: limit, startDate: startDate, endDate: endDate });

    return result;
  };

  const onDataHandler = (data) => {
    setData([ ...data.data ]);
  };

  const State = ({ shipment }) => {
    if (shipment?.body.state === 'draft') {
      // Validate
      const summary = val.getValidationResultsSummary(shipment?.validation, ShipmentValidationSchema);

      if (summary.total > 0) {
        const parts = [];

        if (summary.errors) {
          parts.push(t('common:validation.summary.errors', { count: summary.errors }));
        }

        if (summary.warnings) {
          parts.push(t('common:validation.summary.warnings', { count: summary.warnings }));
        }

        return <action.ShowValidationSummary
          value={shipment}
          schema={ShipmentValidationSchema}
          color="error.main"
          shadow={false}
          startButtons={<>
            <action.ShipmentDetails value={shipment} showLabel={true} mode="button" />
          </>}
        />;
      }
    }

    const list = [];

    switch (shipment?.body.state) {
      case 'completed':
        list.push(<format.InvoiceValidationState value={shipment?.body.shipmentContract.body.invoiceValidationState} />);
        break;

      case 'active':
        list.push(<format.ShipmentContractOperationalState value={shipment?.body.shipmentContract.body.operationalState} />);
        break;

      default:
        list.push(<format.ShipmentState value={shipment?.body.state} />);
        break;
    }

    return <>
      {list.map((i, index) => <React.Fragment key={index}>{i}</React.Fragment>)}
    </>;
  };

  const Seal = ({ shipment }) => {
    const { t } = useTranslation();

    const onClick = () => {
      globalPushShipmentSealModal({ shipment: shipment});
    };

    const isActive = shipment?.body.state === 'active';

    return <Button
      variant="contained"
      color={isActive ? "primary" : "red"}
      onClick={onClick}
    >{isActive ? t('common:fields:shipment.seal') : t('common:fields:shipment.sealed')}</Button>
  }

  const AuxActions = ({ shipment }) => {
    const list = [];

    if (shipment?.body.state === 'draft') {
      // Validate
      const summary = val.getValidationResultsSummary(shipment?.validation, ShipmentValidationSchema);

      if (!summary.errors) {
        list.push(<action.UpdateShipmentState value={shipment} state="rfp" className="block-rsep-50" />);
      }
    } else if (marketplace && shipment.body.state === 'rfp' && !shipment?.body.shipmentContractId) {
      list.push(<action.CreateShipmentBid shipment={shipment} />);
    } else if(shipment.body.state === 'active') {
      list.push(<Seal shipment={shipment} />);
    } else if (shipment.body.state === 'completed') {
      list.push(
        // comment: this is not needed for now ( review button )
        // <action.ShowShipmentChatSession
        //   value={shipment}
        //   mode="chat"
        //   className="block-rsep-50"
        //   actionMode="button"
        //   startIcon={<MdReview />}
        // >
        //   {t('common:actions.reviewCompletedShipment.label')}
        // </action.ShowShipmentChatSession>
      );
    }

    return <div className={styles.auxActions} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
      {list.map((i, index) => <React.Fragment key={index}><Box ml={2}>{i}</Box></React.Fragment>)}
    </div>;
  };

  const Actions = ({ shipment }) => {
    const list = [];

    list.push(<action.ShipmentDetails value={shipment} />);

    if (!marketplace) {
      list.push(<action.CreateShipmentCopy value={shipment} key={3} />);


      if (shipment?.body.state === 'rfp') {
        list.push(<action.UpdateShipmentState value={shipment} state="cancelled" key={4} />);
      } else {
        list.push(<action.DeleteShipmentDraft value={shipment} key={5} />);
      }
    } else {
      if (shipment?.body.state === 'rfp' && !shipment?.body.shipmentContractId) {
        list.push(<action.ArchiveShipmentBidRequest shipment={shipment} />)
      }
    }
    
    return <span className="nowrap">
      {list.map((i, index) => <React.Fragment key={index}>{i}</React.Fragment>)}
    </span>;
  };

  const BidsDetails = ({ shipment }) => {
    if (shipment?.body.state === 'draft') return <></>;
  
    const canAcceptBids = shipment?.body.state === 'rfp' && new Date(shipment?.body.expiresAt) <= api.now();

    const handleAccept = (bid) => {
      api.confirm('acceptShipmentContractById', null, confirmed => {
        if (confirmed) {
          api.acceptShipmentContractById(bid.body._id).then(response => {
            if (!response.success) return;

            api.navigateShipmentState('active');
          });
        }
      });
    }

    const translateBidState = (state) => {
      const keyState = state === 'archived' ? `${state}@client` : state;

      const key = `common:states.shipmentContract.${keyState}`;

      return t(key);
    };

    const handleSendMail = async (orgUnit,shipment) => {
      const from = `${shipment?.pickupFrom.street??""}, ${shipment?.pickupFrom.city??""}, ${shipment?.pickupFrom.countery??""}`
      const to = `${shipment?.deliverTo.street??""}, ${shipment?.deliverTo.city??""}, ${shipment?.deliverTo.countery??""}`
      const title = `הצעת מחיר #${shipment?.globalRecordNumber} לשלוח מ-${from} אל ${to}`
      const forwarders = (await api.getMyForwarders()).data.forwarders;
      const found = forwarders.find(i => i.orgName === orgUnit.name)
      await api.logSendMail(`${shipment.name}(${shipment.globalRecordNumber})`,orgUnit.name,found.email)
      if(!found){
        //handle error if user dont have the org in his settings
        return;
      }
      const lineBreak = "%0D%0A";
      const bodyTitle = `שלום ${found.name},${lineBreak}אבקש לקבל הצעת מחיר עבור המשלוח כמפורט:${lineBreak}${"http://forms.cargosys.net/"+shipment._id+"?id="+orgUnit._id}${lineBreak} תודה ${lineBreak}${global.user.user.name}`
      window.open(`mailto:${found.email}?subject=${title}&body=${bodyTitle}`, '_blank');
    }

    return <>
      <ui.TableContainer autoSize>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('common:fields.orgUnit.name')}</TableCell>
              <TableCell align="center">{i18n.t('common:actions.publicForwarderProfile.label')}</TableCell>
              {/* <TableCell align="center">{t('common:fields.common.chat')}</TableCell> */}
              <TableCell align="center">{t('common:fields.shipmentContract.freightCarrierName')}</TableCell>
              <TableCell align="center">{t('common:fields.shipmentContract.transitDays')}</TableCell>
              <TableCell className="text-center">{t('common:fields.shipmentContractPrices.total')}</TableCell>
              <TableCell className="text-center">{t('common:fields.shipmentContract.expiresAt')}</TableCell>
              {/* <TableCell className="text-center">{t('common:fields.orgUnit.avgRank')}</TableCell> */}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {shipment.body.sharedWith.orgUnits.map((orgUnit, index) => {
              const bid = orgUnit.body.shipmentContract;

              if (!bid.body) bid.body = {};

              const active = bid.body.state === 'bid';

              return <TableRow key={index}>
                <TableCell>{orgUnit.body.name}</TableCell>
                <TableCell align="center">
                  <action.OrgUnitPublicForwarderProfile orgUnit={orgUnit} />
                </TableCell>
                {/* <TableCell align="center" className="nowrap">
                  <action.ShowShipmentContractChatSession value={bid} />
                </TableCell> */}
                <TableCell align="center">
                  {active && bid.body.freightCarrierName}
                </TableCell>
                <TableCell align="center">
                  { active && <format.PositiveNumericString value={bid.body.transitDays} feedback={false} /> }
                </TableCell>
                <TableCell className="text-right">
                  { active && <action.ShipmentContractDetails value={bid} shipment={shipment} readOnly={true}>
                    <format.Money value={bid.body.prices.total} currencyCode={shipment?.body.currencyCode} />
                  </action.ShipmentContractDetails> }
                </TableCell>
                <TableCell className="text-center nowrap">
                  { active && <format.DateText value={bid.body.expiresAt} /> }
                </TableCell>
                {/* <TableCell className="text-center nowrap">
                  { active && <action.OrgUnitRank orgUnit={orgUnit} /> }
                </TableCell> */}
                <TableCell className="text-center nowrap">
                { !canAcceptBids &&
                  <Button variant='contained' onClick={()=>handleSendMail(orgUnit.body,shipment.body)} color='primary'>Send Mail</Button>}
                </TableCell>
                <TableCell className="text-center nowrap">
                  {active && canAcceptBids && <>
                    <action.Action mode="button" action="acceptBid" onClick={() => handleAccept(bid)}>
                      {t('common:actions.acceptBid.label')}
                    </action.Action>
                  </>}
                  { !active && bid.body.state && translateBidState(bid.body.state) }
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </ui.TableContainer>
    </>;
  };

  const BidsDeadline = ({ bids, onComplete, onChange }) => {
    const onEndDeadline = () => {
      api.confirm('endRFPBidding', null, async (confirmed) => {
        if (!confirmed) return;

        const response = await api.updateShipmentExpiresAtById(bids.body._id, api.today());
        
        if (!response.success) return;

        onChange();
      });
    };

    const onChangeDeadline = () => {
      const today = api.today();
      const expires = new Date(bids.body.expiresAt);

      const state = observable({
        value: today < expires ? expires : today,
      });

      const changeValue = (val) => {
        const date = new Date(val);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        state.value = date;
      };

      const onSave = async () => {
        const response = await api.updateShipmentExpiresAtById(bids.body._id, state.value);

        if (response.success) {
          onChange();
        }
      };
    
      globalPushModal({
        body: <Observer>{() => (
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            padding: '2rem',
          }}>
            <MuiDatePicker
              value={state.value}
              onChange={changeValue}
              onAccept={changeValue}
              autoOk
              disablePast
              showTodayButton
              format={'YYYY-MM-DD'}
              variant="static"
              minDate={today}
              orientation="landscape"
              {...rest}
            />
          </div>
        )}</Observer>,
        footer: <action.ModalButtons onAsyncSave={onSave} />,
        options: {
          maxWidth: 'sm',
        },
      });
    };

    return <>
      <Box style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Box mr={2}>
          <Typography variant="subtitle2" color="primary">
            {t('common:fields.shipment.expiresAt')}:
          </Typography>
        </Box>
        <Paper variant="outlined">
          <Box p={1} style={{ width: '10em', textAlign: 'center' }}>
            <Typography variant="subtitle2" color="secondary">
              <format.Deadline value={bids.body.expiresAt} onComplete={onComplete} />
            </Typography>
          </Box>
        </Paper>
        
        <Box
          ml={4}
          display="inline-grid"
          gridTemplateColumns="min-content min-content"
          gridColumnGap="1em"
          columngap="1em"
          alignItems="center"
        >
            <span>
              <action.Action action="changeRFPBiddingDeadline" mode="button" color="default" onClick={onChangeDeadline}>
                {t('common:actions.changeRFPBiddingDeadline.label')}
              </action.Action>
            </span>
          { new Date(bids.body.expiresAt) > api.now() && <>
            <span className="bulge">
              <action.Action action="endRFPBidding" mode="button" color="secondary" onClick={onEndDeadline}>
                {t('common:actions.endRFPBidding.label')}
              </action.Action>
            </span>
          </> }
        </Box>
      </Box>
    </>;
  };

  const BidsSummary = ({ shipment }) => {
    const { t } = useTranslation();

    if (shipment.body.state === 'draft') return <></>;

    const handleClick = () => {
      api.queryShipmentBidsByShipmentId(shipment?.body._id).then(response => {
        if (!response.success) return;

        const state = observable({
          bids: response.data,
        });

        const onRefresh = async () => {
          const r = await api.queryShipmentBidsByShipmentId(shipment?.body._id);
          if (!r.success) return;

          state.bids = r.data;

          // Update caller
          shipment.body.expiresAt = state.bids.body.expiresAt;
        };

        const onExport = () => {
          // Export to excel
          const priceFields = [ 'total', ...getShipmentRequiredPriceFields(state.bids.body) ];

          const hasDemurrage = !!state.bids.body.shipmentContent.find(item => item.type === 'container');

          const priceFieldsTitles = [];

          priceFields.forEach(f => {
            priceFieldsTitles.push(i18n.t(`common:fields.shipmentContractPrices.${f}`));
            priceFieldsTitles.push(i18n.t(`common:fields.shipmentContractPrices.${f}`) + ' (' + i18n.t('common:fields.common.currencyCode') + ')');
          });

          const data = [
            [
              i18n.t('common:fields.shipmentContract.globalRecordNumber'),
              i18n.t('common:fields.shipmentContract.orgUnit'),
              i18n.t('common:fields.orgUnit.avgRank'),
              i18n.t('common:fields.shipmentContract.freightCarrierName'),
              i18n.t('common:fields.shipmentContract.transitDays'),
              ...(hasDemurrage ? [ i18n.t('common:fields.shipmentContract.demurrageFreeDays') ] : []),
              i18n.t('common:fields.shipmentContract.expiresAt'),
              ...priceFieldsTitles,
              i18n.t('common:fields.shipmentContract.comments'),
            ],
            ...state.bids.body.sharedWith.orgUnits.map(orgUnit => {
              const contract = orgUnit.body.shipmentContract.body;

              const pricesFieldValues = [];

              priceFields.forEach(f => {
                if (contract.prices && contract.prices[f]) {
                  pricesFieldValues.push(contract.prices[f].value);
                  pricesFieldValues.push(contract.prices[f].currencyCode);
                } else {
                  pricesFieldValues.push(null);
                  pricesFieldValues.push(null);
                }
              });

              return [
                contract.globalRecordNumber,
                orgUnit.body.name,
                orgUnit.body?.summary?.rating?.avgScore,
                contract.freightCarrierName,
                contract.transitDays,
                ...(hasDemurrage ? [ contract.demurrageFreeDays ] : []),
                format.DateString(contract.expiresAt),
                ...pricesFieldValues,
                contract.comments,
              ];
            })
          ];

          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);

          const datasetName = 
            `${state.bids.body.globalRecordNumber} - ${i18n.t('common:fields.shipment.rfpResponsesCount')} - ${i18n.t('common:product.name')}`;

          XLSX.utils.book_append_sheet(wb, ws, datasetName);

          const fileName = `${datasetName}.xlsx`;
          
          XLSX.writeFile(wb, fileName);
        };

        globalPushModal({
          title: <>{shipment.body.globalRecordNumber} - {t('common:fields.shipment.rfpResponsesCount')}</>,
          titleActions: <Observer>{() => {
            return shipment.body.state === 'rfp' ? <BidsDeadline bids={state.bids} onComplete={onRefresh} onChange={onRefresh} /> : null;
          }}</Observer>,
          body: <Observer>{() => {
            return <BidsDetails shipment={state.bids} />;
          }}</Observer>,
          footer: <action.ModalButtons onExport={onExport} />,
          options: {
            fixedHeight: true,
          }
        })
      });
    };

    const text = `${shipment?.body.rfpResponsesCount} / ${(shipment?.body.sharedWith || {orgUnitIds: []}).orgUnitIds.length}`;

    return <>
      <action.Action
        mode="button"
        action="view"
        onClick={handleClick}
        color={shipment.body.rfpResponsesCount > 0 ? 'primary' : 'default'}
        fullWidth
        startIcon={<MdViewList />}
      >
        {text}
      </action.Action>
    </>;
  };

  const getFilterOptions = () => {
    if (marketplace) return [];

    const rfp = [ 'draft', 'rfp', 'cancelled' ];
    const active = [ 'active', 'completed', 'closed', 'dispute' ];

    const hasRFPs = (state || '') === '' || state.split(/,/g).find(i => rfp.includes(i));
    const hasActive = (state || '') === '' || state.split(/,/g).find(i => active.includes(i));

    const states = [
      ...(hasRFPs ? rfp : []),
      ...(hasActive ? active : []),
    ];

    const options = [];

    options.push({
      value: {
        filter: {
          state: states.join(','),
        },
      },
      label: i18n.t('common:actions.showAllRecords.label'),
    });

    states.forEach(i => {
      options.push({
        value: {
          filter: {
            state: i,
          },
        },
        label: i18n.t(`common:states.shipment.${i}`),
      });
    });

    options.push({
      value: {
        filter: {
          state: state,
        },
        own: true
      },
      label: i18n.t('common:actions.showOwnRecords.label'),
    });

    return options;
  };

  const getSortOptions = () => {
    const options = [];

    options.push({
      label: i18n.t('common:sort.shipment.updatedAt'),
      value: {
        sort: { updatedAt: 'desc' }
      }
    });

    options.push({
      label: i18n.t('common:sort.shipment.name'),
      value: {
        sort: { createdAt: 'asc' }
      }
    });

    options.push({
      label: i18n.t('common:sort.shipment.globalRecordNumber'),
      value: {
        sort: { globalRecordNumber: 'desc' }
      }
    });

    return options;
  };

  const onExportCompletedShipments = async () => {
    await createExcel(uiState.data, (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "summary.xlsx");
      link.click();
    });
  }

  return <ui.MainContentContainer autoSize={autoSize}>
    <ui.TableContainer
      autoSize={autoSize}
      header={<>
        <data.DateDataToolbar
          onFetchDataAsync={onFetchDataAsyncHandler}
          onData={onDataHandler}
          onExport={ onExportCompletedShipments }
          sortOptions={getSortOptions()}
          filterOptions={getFilterOptions()}
          showExport={true}
        />
      </>}
    >
      <Observer>{() => {
        const hasRFPs = (state || '') === '' || state.split(/,/g).find(i => ['draft', 'rfp', 'expired', 'cancelled'].includes(i)) !== undefined;
        const hasActive = (state || '') === '' || state.split(/,/g).find(i => ['active', 'completed', 'closed', 'dispute'].includes(i)) !== undefined;
        const hasChat = !hideChat && ((state || '') === '' || state.split(/,/g).find(i => ['active', 'completed', 'closed', 'dispute'].includes(i)) !== undefined);

        const [selectedId,setSelectedId]=useState('');

        if (uiState.data === null) return <ui.TableSkeleton />;
        if (uiState.data.length === 0) return <ui.EmptyTable />;

        // const checkIfGotFiles = (id) => {
        //   api.queryAttachments({
        //     filter: {
        //       shipmentContractId: api.ObjectId(id),
        //     }
        //   }).then(response => {
        //     if (!response.success) return false;
        //     return true
        //   });
        // }

        const handleClick = (id) => {
          setSelectedId(id)
          
        }

        const handleClose = () => {
          setSelectedId('');
        }

        return (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('common:fields:shipment.globalRecordNumber')}</TableCell>
                { !dashboard && hasRFPs && <TableCell className="text-center">{t('common:fields:shipment.updatedAt')}</TableCell> }
                { hasRFPs && <TableCell className="text-center">{t('common:fields.shipment.expiresAt')}</TableCell> }
                { !marketplace && hasActive && <>
                  <TableCell className="text-center">{t('common:fields:shipmentContract.globalRecordNumber')}</TableCell>
                  <TableCell className="text-center">{t('common:fields.shipmentContractPrices.total')}</TableCell>
                </> }
                <TableCell className="text-center">{t('common:fields:shipment.transportMode')}</TableCell>
                { !dashboard && hasRFPs && <TableCell className="text-center">{t('common:fields:shipment.shipmentType')}</TableCell> }
                <TableCell>
                  {!marketplace ? t('common:fields:shipment.name') : t('common:fields:orgUnit.name@forwarder')}
                </TableCell>
                {/* ToDo: CS7605 Issue */}
                {/* !marketplace && !dashboard && hasActive && <TableCell className="text-center">{t('common:fields:shipment.shipmentTracking')}</TableCell> */}
                {/* { !marketplace && hasChat && <TableCell className="text-center">{t('common:fields:common.ops')}</TableCell>} */}
                <TableCell className="text-center">Files</TableCell>
                <TableCell className="text-center">{t('common:fields:shipment.pickupFrom')}</TableCell>
                <TableCell className="text-center">{t('common:fields:shipment.deliverTo')}</TableCell>
                { !marketplace && !dashboard && <>
                  <TableCell className="text-center">{t('common:fields:shipment.state')}</TableCell>
                  { hasRFPs && <TableCell>{t('common:fields:shipment.rfpResponsesCount')}</TableCell> }
                </> }
                { !dashboard && <TableCell></TableCell> }
              </TableRow>
            </TableHead>
            <TableBody>
              <Observer>{() => (uiState.data || []).map((dataItem, index) => <TableRow key={index}>
                <TableCell>
                  <action.Cell label={
                    <action.ShipmentDetails value={dataItem} mode="link">
                      {dataItem.body.globalRecordNumber}
                    </action.ShipmentDetails>
                  }>
                    { !dashboard && <Actions shipment={dataItem} /> }
                  </action.Cell>
                </TableCell>
                { !dashboard && hasRFPs && <TableCell className="text-center"><format.DateText value={dataItem.body.updatedAt} /></TableCell> }
                { hasRFPs && <TableCell className="text-center">
                  <format.Deadline mode={ marketplace ? 'countdown' : 'date' } value={dataItem.body.expiresAt} />
                </TableCell> }
                { !marketplace && hasActive && <>
                  <TableCell className="text-center">{
                    dataItem.body.shipmentContract && dataItem.body.shipmentContract.body && dataItem.body.shipmentContract.body.globalRecordNumber ? <>
                      <action.ShipmentContractDetails shipment={dataItem} value={dataItem.body.shipmentContract} mode="link">
                        {dataItem.body.shipmentContract.body.globalRecordNumber}
                      </action.ShipmentContractDetails>
                    </> : <></>
                  }</TableCell>
                  <TableCell className="nowrap text-right">{
                    dataItem.body.shipmentContract && dataItem.body.shipmentContract.body && dataItem.body.shipmentContract.body.globalRecordNumber ? <>
                      <action.ShipmentContractDetails shipment={dataItem} value={dataItem.body.shipmentContract}>
                        <format.Money value={dataItem.body.shipmentContract.body.prices.total} currencyCode={dataItem.body.currencyCode} />
                      </action.ShipmentContractDetails>
                    </> : <></>
                  }</TableCell>
                </> }
                <TableCell className="text-center"><format.TransportMode value={dataItem.body.transportMode} mode="icon" /></TableCell>
                { !dashboard && hasRFPs && <TableCell className="text-center"><format.ShipmentType value={dataItem.body.shipmentType} /></TableCell> }
                <TableCell>
                  { !marketplace ? dataItem.body.name : dataItem.body.orgUnit.name }
                </TableCell>
                { !marketplace && <TableCell className="text-center nowrap">
                  
                <ShowFilesCell
                dataItem={dataItem}
                selectedId={selectedId}
                handleClick={handleClick}
                handleClose={handleClose}
                />
                </TableCell>}
                <TableCell className="text-center"><format.ShortAddress value={dataItem.body.pickupFrom} /></TableCell>
                <TableCell className="text-center"><format.ShortAddress value={dataItem.body.deliverTo} /></TableCell>
                { !marketplace && !dashboard && <>
                  <TableCell className="text-center nowrap"><State shipment={dataItem} /></TableCell>
                  { hasRFPs && <TableCell className="nowrap"><BidsSummary shipment={dataItem} /></TableCell> }
                </> }
                { !dashboard && <TableCell className="text-right nowrap"><AuxActions shipment={dataItem} /></TableCell> }
              </TableRow>)}</Observer>
            </TableBody>
          </Table>
        );
      }}</Observer>
    </ui.TableContainer>
    { api.hasModule('initiator') && !marketplace && <GlobalNavControl>
      <action.CreateNewShipment className="block-vsep-50" id="global-nav-create-shipment" />
    </GlobalNavControl> }
  </ui.MainContentContainer>;
};

export default Main;
