import React from 'react';
import i18n from 'i18next';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';

import {
  Box,
} from '@material-ui/core';

import input from './input';
import action from './action';

import { globalPushModal } from '../lib/global';
import api from '../lib/api';

export const globalPushPublicForwarderProfileModal = async ({ readOnly = false, orgUnit = null } = {}) => {
  let resolvedOrgUnit = orgUnit;

  if (!resolvedOrgUnit) {
    const orgUnitResponse = await api.queryOrgUnit();

    if (!orgUnitResponse.success) return;

    resolvedOrgUnit = orgUnitResponse.data;
  }

  console.log('resolvedOrgUnit: ', resolvedOrgUnit);
  const { body = '', tags = [] } = ((resolvedOrgUnit.body.options || {}).forwarder || {}).publicProfile || {};

  const uiState = observable({
    value: {
      body,
      tags,
    },
    dirty: false,
  });

  const handleAsyncSave = async () => {
    await api.updateOrgUnitPublicForwarderProfile(toJS(uiState.value));
  };

  const handleChange = (val) => {
    uiState.value = val;
    uiState.dirty = true;
  };

  globalPushModal({
    title: i18n.t('common:navigation.adminPublicForwarderProfile.text'),
    footer: <Observer>{() => (
      <action.ModalButtons onAsyncSave={!readOnly ? handleAsyncSave : null} disabled={!uiState.dirty} />
    )}</Observer>,
    body: <PublicForwarderProfileForm value={uiState.value} onChange={handleChange} readOnly={readOnly} />
  });
};

export const PublicForwarderProfileForm = ({ readOnly = false, value = {}, onChange }) => {
  const uiState = observable({
    value: {
      body: '',
      tags: [],

      ...(value || {})
    },
  });

  const change = () => {
    if (onChange) onChange(toJS(uiState.value));
  };

  const set = (key, val) => {
    uiState.value[key] = val;

    change();
  };

  return <Box p={1}>
    <Box>
      <input.Text
        label={readOnly ? null : i18n.t('common:fields.orgUnit.options.forwarder.publicProfile.body')}
        type="textarea"
        value={uiState.value.body}
        onChange={val => set('body', val)}
        rows={10}
        rowsMax={10}
        readOnly={readOnly}
      />
      <input.ForwarderTags
        label={readOnly ? null : i18n.t('common:fields.orgUnit.options.forwarder.publicProfile.tags')}
        value={uiState.value.tags}
        onChange={val => set('tags', val)}
        readOnly={readOnly}
      />
    </Box>
  </Box>;
};

export const PublicForwarderProfileView = ({ value, ...rest }) => {
  return <PublicForwarderProfileForm readOnly={true} value={value} {...rest} />;
};
