const list = [
  'ACL',
  'Alianca',
  'Allalouf',
  'ANL',
  'APL',
  'Arkas',
  'Atlanticargo',
  'Avana Logistek',
  'Balaji Shipping',
  'Beacon Intermodal',
  'BLPL Singapore',
  'Blue Sky',
  'BMC Line',
  'Bridgehead',
  'Brointermed lines',
  'CAI',
  'Canadian National Railway',
  'Canadian Pacific Railway',
  'Carlisle Leasing',
  'CARU',
  'CCNI',
  'China Navigation Company',
  'CHINA SHIPPING',
  'CMA CGM',
  'CNC Line',
  'Combi Line',
  'Concor',
  'Containerships Group',
  'COSCO',
  'Cosiarma',
  'Cronos',
  'Crowley',
  'CSAV NORASIA',
  'CULines',
  'DAL',
  'DB Schenker',
  'Delmas',
  'DFDS LYS',
  'DHL',
  'Dolphin Line',
  'Dong Fang',
  'Dongjin Shipping',
  'Dongnama',
  'Dong Young Shipping',
  'DP World Nhava Sheva',
  'Econ Shipping',
  'ECU Worldwide',
  'Emirates Shipping Line',
  'Emkay Line',
  'ESL',
  'EVERGREEN',
  'FESCO',
  'Finnlines',
  'Flexi-Van',
  'Florens container services',
  'Focus Trucking',
  'GCL',
  'GESeaCo',
  'GOLD',
  'Gold Star Line',
  'Gothong Southern Shipping',
  'Grimaldi',
  'GVC',
  'Haian',
  'Hamburg Sud',
  'Hanjin Shipping',
  'HAPAG LLOYD',
  'Heung-A Shipping',
  'Hyde Logistics',
  'HYUNDAI',
  'Interasia Lines',
  'Interport',
  'IRISL',
  'ITALIA MARITTIMA',
  'Kambara Kisen',
  'KiwiRail',
  'K-LINE',
  'Korea Marine Transport',
  'Kuehne + Nagel',
  'Leschaco',
  'Libra',
  'Linea Messina',
  'MacAndrews',
  'MACS',
  'MAERSK',
  'MAG shipping',
  'Maldives Ports',
  'Marfret',
  'Marguisa',
  'Mariana Express Lines',
  'Matson',
  'Maxicon Container Line',
  'Medkon Lines',
  'Meratus Line',
  'Minsheng Ocean Shipping',
  'MOL',
  'MSC',
  'Namsung',
  'NileDutch',
  'Nirint Shipping',
  'NSCSA',
  'NYK Line',
  'Ocean Axis',
  'Ocean World Lines',
  'ONE Line',
  'OOCL',
  'OTAL',
  'Pacific Direct Line',
  'Pan Asia Line',
  'Pan Continental Shipping',
  'Pasha Hawaii',
  'Perma Shipping Line',
  'PIL',
  'Pollux & Castor',
  'PSL Navegacao',
  'Qatar Navigation',
  'RCL',
  'Roberto Bucci',
  'Royal Arctic',
  'Safmarine',
  'Saigon Newport Corporation',
  'Samskip',
  'Samudera',
  'Sarjak Container Lines',
  'SCI',
  'Seaboard Marine',
  'SeaCastle Inc, (Interpool)',
  'Sealand',
  'Sea Star Line',
  'SENATOR LINES',
  'SETH Shipping',
  'Sinokor Merchant Marine',
  'Sinotrans',
  'SITC',
  'SM Line',
  'Sofrana ANL',
  'Sofrana Unilines',
  'SPIL',
  'Star Shipping',
  'St. John Freight Systems',
  'STX Pan Ocean',
  'Sunmarine',
  'TAL International',
  'Tanto',
  'Tarros',
  'Textainer',
  'TIS-logistic',
  'TOTE Maritime',
  'Touax',
  'Trailer Bridge',
  'Trans Asia',
  'TransContainer',
  'Transmar',
  'Transvision Shipping',
  'Triton Container',
  'T.S. Lines',
  'Turkon',
  'UES',
  'United Arab Shipping',
  'VL Logistic',
  'Wan Hai Lines',
  'Waterfront Container',
  'W.E.C. Lines',
  'White Line',
  'World Transport Overseas',
  'WorldWide Alliance',
  'XPO Logistics',
  'YANG MING (YML)',
  'ZIM',
];

export default list;
