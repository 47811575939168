import React, { useEffect } from 'react';
import { v1 as uuid } from 'uuid';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import i18n from 'i18next';
import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';

import input from './input';
import format from './format';
import action from './action';
import val from './validation';

import api from '../lib/api';
import global, { globalPushModal, globalPopModal } from '../lib/global';
import { Button } from '@material-ui/core';
import { AddFowarderModal } from './AddFowarderModal';

const safeUser = (value) => {
  if (value) return value;
  return {
    body: {
      email: '',
      name: '',
      enabled: true,
      isPlatformAdmin: false,
      orgUnits: [],
      fowarders: []
    },
    validation: null,
  };
}

const trim = (str) => {
  return str.replace(/^\s+/g, '').replace(/\s+$/g, '');
};

export const globalPushAddUserFormModal = () => {
  const ui = observable({ valid: false });

  let state = { email: '', name: '', roleId: null };

  const validate = () => {
    ui.valid = !!state.roleId && EmailValidator.validate(state.email) && trim(state.name).length > 0;
  };

  const handleChange = val => {
    state = val;

    validate();
  };

  const onSave = async () => {
    const response = await api.createOrgUnitUserById(global.user.orgUnit._id, {
      email: state.email,
      roleId: state.roleId,
      name: state.name,
    });

    if (response.success) {
      api.reload();
    }
  };

  validate();

  globalPushModal({
    title: i18n.t('common:title.user.new'),
    body: <AddUserForm onChange={handleChange} />,
    footer: <Observer>{() => <>
      <action.ModalButtons onAsyncSave={onSave} disabled={!ui.valid} />
    </>}</Observer>,
    options: {
      large: false,
    }
  });
};

export const globalPushUserFormModal = (user = null) => {
  let state = user;

  const ui = observable({
    valid: false,
  });

  const validate = () => {
    ui.valid = state && user && user.body._id && (trim(state.body.email) !== '' && trim(state.body.name) !== '');
  };

  const handleChange = val => {
    state = val;

    validate();
  };

  const onSave = async () => {
    const response = await api.updateUserById(user.body._id, state.body);

    if (response.success) {
      api.reload();
    }
  };

  validate();

  globalPushModal({
    title: user ? `${user.body.email}` : i18n.t('common:title.user.new'),
    body: <UserForm value={state} onChange={handleChange} />,
    footer: <Observer>{() => {
      return <>
        <action.ModalButtons onAsyncSave={onSave} disabled={!ui.valid} />
      </>;
    }}</Observer>,
    options: {
      large: false
    }
  });
};

const UserRole = ({ roles, roleId, onChange, ...rest }) => {
  const [ id ] = React.useState(uuid());
  const [ state, setState ] = React.useState(roleId);

  const role = roles.find(i => i.body._id === state);
  const name = role && role.body.name;
  const perms = role ? role.body.perms : {};

  const variant =
    perms.orgAdmin ? 'danger' : (
      perms.orgWrite ? 'success' : (
        perms.orgRead ? 'warning' : 'secondary'
      )
    );

  const rolesMap = roles.reduce((acc, curr) => {
    acc[curr.body._id] = curr.body.name;
    return acc;
  }, {});

  const handleChange = (val) => {
    setState(val);

    if (onChange) onChange(val);
  };

  return <>
    <input.StaticSelect
      value={state}
      values={rolesMap}
      showKeys={false}
      onChange={handleChange}
    />
  </>;
};

const UserOrgUnits = ({ value, readOnly = false, onChange }) => {
  const { t } = useTranslation();

  const [ id ] = React.useState(uuid());
  const [ state, setState ] = React.useState(value);
  const [ loaded ] = React.useState(observable({ roles: [], orgUnits: [], defaultRole: null }));
  const [ ui ] = React.useState(observable({ search: '' }));

  if (loaded.roles.length === 0 || loaded.orgUnits.length === 0) {
    Promise.all([
      api.queryRoles({}),
      api.queryOrgUnits({})
    ]).then(([ rolesResponse, orgUnitsResponse ]) => {
      if (rolesResponse.success && orgUnitsResponse.success) {
        loaded.roles = rolesResponse.data;
        loaded.orgUnits = orgUnitsResponse.data;
        loaded.defaultRole = loaded.roles.find(i => i.body.referenceId === 'org.user');
      }
    });
  }

  const handleSearchChange = val => {
    const text = val.replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase();

    if (ui.search !== text) {
      ui.search = text;
    }
  };

  return <>
    <input.Search onChange={handleSearchChange} />
    <p></p>
    <Observer>{() => {
      if (loaded.roles.length === 0 || loaded.orgUnits.length === 0) {
        return <format.Loading />;
      }

      const orgUnits = ui.search === '' ? loaded.orgUnits : loaded.orgUnits.filter(i => i.body.name.toLowerCase().indexOf(ui.search) >= 0);

      return <>
        <div style={{ height: '15em', overflowY: 'scroll' }}>
          <table width="100%">
            <tbody>
              { orgUnits.map((orgUnit, index) => {
                const stateOrgUnit = state.find(i => i.orgUnitId === orgUnit.body._id);
                const isSelected = stateOrgUnit ? true : false;

                const handleChangeSelected = select => {
                  const newState = state.filter(i => i.orgUnitId !== orgUnit.body._id);
                  
                  if (select) {
                    newState.push({
                      orgUnitId: orgUnit.body._id,
                      roleId: loaded.defaultRole.body._id,
                    });
                  }

                  setState([ ...newState ]);

                  if (onChange) onChange(newState);
                };

                const handleRoleIdChange = roleId => {
                  const item = state.find(i => i.orgUnitId === orgUnit.body._id);

                  item.roleId = roleId;

                  setState([ ...state ]);

                  if (onChange) onChange(state);
                };

                return <tr key={index}>
                  <td>
                    <input.Check readOnly={readOnly} value={isSelected} label={orgUnit.body.name} onChange={handleChangeSelected} id={`${id}-${orgUnit.body._id}`} type="checkbox" />
                  </td>
                  <td>
                    { isSelected && <UserRole roles={loaded.roles} roleId={stateOrgUnit.roleId} onChange={handleRoleIdChange} /> }
                  </td>
                </tr>
              }) }
            </tbody>
          </table>
        </div>
      </>;
    }}</Observer>
  </>;
};

const FowarderOrgUnits = ({ value, readOnly = false, onChange , userId }) => {
  const { t } = useTranslation();

  const [ id ] = React.useState(uuid());
  const [fowarderPopUpActive, setFowarderPopUpActive] = React.useState(false);
  const [fowardersList, setFowardersList] = React.useState([])
  const [userForwardersList, setUserForwardersList] = React.useState([]);
  const [ state, setState ] = React.useState(value);
  const [ loaded ] = React.useState(observable({ roles: [], orgUnits: [], defaultRole: null }));
  const [ ui ] = React.useState(observable({ search: '' }));

  if (loaded.roles.length === 0 || loaded.orgUnits.length === 0) {
    Promise.all([
      api.queryRoles({}),
      api.queryOrgUnits({})
    ]).then(([ rolesResponse, orgUnitsResponse ]) => {
      if (rolesResponse.success && orgUnitsResponse.success) {
        loaded.roles = rolesResponse.data;
        loaded.orgUnits = orgUnitsResponse.data;
        loaded.defaultRole = loaded.roles.find(i => i.body.referenceId === 'org.user');
        setFowardersList(orgUnitsResponse.data.map(orgUnit => {
          if(orgUnit.body.modules.includes("forwarder")){
            return {
              id: orgUnit.body._id,
              name: orgUnit.body.name,
              picture: orgUnit.body.picture,
            }
          }
        }).filter(_=>_))
      }
    });
  }

  useEffect(() => {
    api.getUserForwarders(userId).then((res)=> {
      setUserForwardersList(res.data.forwarders);
    })
  },[]);
  
  const handlePopUp = () => {
    setFowarderPopUpActive(!fowarderPopUpActive)
  }

  const handleSearchChange = val => {
    const text = val.replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase();

    if (ui.search !== text) {
      ui.search = text;
    }
  };

  const handleDeleteForwarder = async (forwarderId) => {
    await api.deleteUserForwarders(userId,forwarderId);
    api.getUserForwarders(userId).then((res)=> {
      setUserForwardersList(res.data.forwarders);
    })
  }

  return <>
    <p></p>
    <Observer>{() => {
      if (loaded.roles.length === 0 || loaded.orgUnits.length === 0) {
        return <format.Loading />;
      }

      const orgUnits = ui.search === '' ? loaded.orgUnits : loaded.orgUnits.filter(i => i.body.name.toLowerCase().indexOf(ui.search) >= 0);

      return <>
        <div style={{ height: '15em', overflowY: 'scroll' }}>
          <table width="100%">
            <tbody>
              { userForwardersList.map((forwarder) => {

                return <tr key={forwarder._id}>
                  <td>
                    {forwarder.orgName}
                  </td>
                  <td>
                    {forwarder.name}
                  </td>
                  <td>
                    {forwarder.email}
                  </td>
                  <td>
                    <Button variant="contained" color='primary' onClick={() => handleDeleteForwarder(forwarder._id)}>Delete</Button>
                    {/* <Button variant="contained" color='primary' onClick={handlePopUp}>Edit</Button> */}
                  </td>
                </tr>
              }) }
            </tbody>
          </table>
          <Button variant="contained" color='primary' onClick={handlePopUp}>Add Fowarder</Button>
          <AddFowarderModal setUserForwardersList={setUserForwardersList} isActive={fowarderPopUpActive} forwardersThatAvailable={fowardersList??[]} userForwardersList={userForwardersList} handleClose={handlePopUp} userId={userId}/>
        </div>
      </>;
    }}</Observer>
  </>;
};

export const UserForm = ({ value, readOnly = false, mode = 'create', onChange }) => {
  const { t } = useTranslation();

  const [ id ] = React.useState(uuid());

  const state = observable({ ...safeUser(value) });

  const isEdit = mode === 'edit';

  const set = (key) => {
    const func = val => {
      state.body[key] = val;

      if (onChange) onChange(state);
    };

    return func;
  };

  const setOrgUnits = (val) => {
    state.body.orgUnits = val;

    if (onChange) onChange(state);
  };

  const setFowarders = (val) => {
    state.body.fowarders = val;

    if (onChange) onChange(state);
  };

  return <>
    <val.Container value={state.validation}>
      <input.Field field="email" label={t('common:fields.user.email')}>
        <input.Text value={state.body.email} onChange={set('email')} readOnly={isEdit || readOnly}/>
      </input.Field>
      <input.Field field="name" label={t('common:fields.user.name')}>
        <input.Text value={state.body.name} onChange={set('name')} />
      </input.Field>
      <Observer>{() => <>
        <input.Field field="enabled">
          <input.Check disabled={state.body._id === global.user.user._id} readOnly={false} value={state.body.enabled} label={t('common:fields.user.enabled')} onChange={set('enabled')} type="switch" id={`${id}-enabled`} />
        </input.Field>
        <input.Field field="isPlatformAdmin">
          <input.Check disabled={state.body._id === global.user.user._id} readOnly={false} value={state.body.isPlatformAdmin} label={t('common:fields.user.isPlatformAdmin')} onChange={set('isPlatformAdmin')} type="switch" id={`${id}-isPlatformAdmin`} />
        </input.Field>
      </>}</Observer>
      <input.Field field="orgUnits" label={t('common:fields:user.orgUnits')}>
        <UserOrgUnits value={state.body.orgUnits} onChange={setOrgUnits} />
      </input.Field>
      <input.Field field="fowarders" label={t('Forwarders')}>
        <FowarderOrgUnits value={state.body.orgUnits} onChange={setFowarders} userId={state.body._id}/>
      </input.Field>
    </val.Container>
  </>;
};

const AddUserRole = ({ onChange, ...rest }) => {
  const [ loaded ] = React.useState(observable({ roles: [], defaultRole: null }));

  if (loaded.roles.length === 0) {
    api.queryRoles({}).then(rolesResponse => {
      if (rolesResponse.success) {
        loaded.roles = rolesResponse.data;
        loaded.defaultRole = loaded.roles.find(i => i.body.referenceId === 'org.user');

        if (onChange) onChange(loaded.defaultRole.body._id);
      }
    });
  }

  return <Observer>{() => {
    if (!loaded.defaultRole) return <format.Loading />;

    const roleId = loaded.defaultRole.body._id;

    return <div style={{ width: '100%' }}>
      <UserRole roleId={roleId} roles={loaded.roles} onChange={onChange} {...rest} />
    </div>;
  }}</Observer>;
};

export const AddUserForm = ({ onChange }) => {
  const { t } = useTranslation();

  let state = { email: '', name: '', roleId: null };

  const set = (key) => {
    const func = (val) => {
      state[key] = val;

      if (onChange) onChange(state);
    };

    return func;
  }

  if (onChange) onChange(state);

  return <>
    <input.Field field="email" label={t('common:fields.user.email')}>
      <input.Text value={state.email} onChange={set('email')} />
    </input.Field>
    <input.Field field="name" label={t('common:fields.user.name')}>
      <input.Text value={state.name} onChange={set('name')} />
    </input.Field>
    <input.Field label={t('common:fields:orgUnit.role')}>
      <AddUserRole onChange={set('roleId')} />
    </input.Field>
  </>;
};
