import React, { forwardRef, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { css } from 'emotion';
import { observable, action, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import IntersectionVisible from 'react-intersection-visible';
import { MdKeyboardArrowDown, MdSend, MdAttachFile } from 'react-icons/md';
import { FileDropZone, FileBrowseButton } from './upload';

import i18n from 'i18next';

import realtime from '../lib/realtime';
import global, { ModalCleanupDetector } from '../lib/global';
import api from '../lib/api';
import config from '../lib/config';

import format from './format';

import 'react-chat-elements/dist/main.css';
import * as chat from 'react-chat-elements';

import 'react-tiny-fab/dist/styles.css';
import * as fab from 'react-tiny-fab';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar } from 'react-circular-progressbar';

import './chat.css';
import { Button, TextField, Typography } from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';

const TAIL_FETCH_LIMIT = 30;
const MAX_FETCH_LIMIT = 15;
const ADVISE_KEYBOARD_ACTIVITY_THROTTLE_MILLISECONDS = 15000;
const SCROLL_TOLERANCE_PIXELS = 100;
const ACCEPT_FILE_TYPES = config.acceptedFileTypes;

const isAcceptedFileType = (file) => {
  const found = ACCEPT_FILE_TYPES.find(accept => {
    // match by extension
    if (file.name.substr(accept.length).toLowerCase() === accept) return true;

    // match by MIME type
    const ap = accept.split('/', 2);
    const fp = file.type.split('/', 2);

    for (let i = 0; i < ap.length; ++i) {
      if (ap[i] === '*') return true;
      if (ap[i].toLowerCase() !== fp[i].toLowerCase()) return false;
    }

    return true;
  });

  return !!found;
};

const isRTLText = (() => {
  const rtlChars = [
    /* arabic ranges*/
    '\u0600-\u06FF',
    '\u0750-\u077F',
    '\uFB50-\uFDFF',
    '\uFE70-\uFEFF',
    /* hebrew range*/
    '\u05D0-\u05FF'
  ].join("");

  const reRTL = new RegExp("[" + rtlChars + "]", "g");
  const multiLang = /[0-9\s\\\/.,\-+="\'\\(\\)\\{\\}\\[\\]]/g;

  return (text) => {
    const clean = text.replace(multiLang, '');
    const rtlCount = (clean.match(reRTL) || []).length;
    const ltrCount = clean.length - rtlCount;

    return (rtlCount > ltrCount);
  }; 
})();

const styles = {
  connecting: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '0.5em',
  }),
  history: css({
    overflow: 'auto',
  }),
  typingIndicator: css({
    fontSize: '0.8em',
    lineHeight: '0.8em',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.8)',
  }),
  keyboardHint: css({
    fontSize: '0.8em',
    lineHeight: '0.8em',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.8)',
    whiteSpace: 'nowrap',
  }),
  inputBarContainer: css({
  }),
  inputBarTextContainer: css({
    padding: '1em',
    paddingLeft: '1em',
    paddingRight: '1em',
    borderTopLeftRadius: '0.5em',
    borderTopRightRadius: '0.5em',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderBottom: 'none',
  }),
  inputBarActionsContainer: css({
    backgroundColor: '#eeeeee',
    borderBottomLeftRadius: '0.5em',
    borderBottomRightRadius: '0.5em',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '0.5em',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',

    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',

    '& div': {
      display: 'flex',
      alignItems: 'center',
    }
  }),
  fileDropZoneActive: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px dashed rgba(0, 0, 0, 0.5)',
    borderRadius: '0.5em',
    fontSize: '2rem',
    backgroundColor: '#eeeeff',
    padding: '2em',
  }),
};

export const ChatSession = ( shipmentId ) => {
  const [comment,setComment] = useState("")
  const [saved,setSaved] = useState(false)

  React.useEffect(() => {
    api.getShipmentCommentById(shipmentId.shipmentId).then((foundComment) => {
      setComment(foundComment.comment)
    })
  },[]);

  const isAdmin = global.user.user.isPlatformAdmin

  const handleChange = (event) => {
    setSaved(false);
    setComment(event.target.value??"")
  }

  const handleSave = async () => {
    await api.updateShipmentCommentById(shipmentId.shipmentId,comment);
    setSaved(true)
  }

  return(
  <div
  style={{
    display: 'flex',
    flexDirection:"column",
    width: '100%',
    paddingTop:"10px"
  }}
  >
  {!isAdmin&&<div>
    <Typography
      variant='h6'
    >
      {comment||"No comments."}
    </Typography>
  </div>}

   {isAdmin&& <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
    }}
  >
  <TextField
    id="filled-multiline-static"
    load
    onChange={handleChange}
    label="Comments"
    multiline
    fullWidth={true}
    rows={6}
    value={comment}
    variant="filled"
  />
  </div>}
  {isAdmin&&<div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'stretch',
        paddingTop:"5px"
      }}>
    <Button
      onClick={handleSave}
      color='primary'
    variant='contained'
    fullWidth={true}>
      save
    </Button>
  </div>}
  <div>
    {saved && <Typography 
      variant='h5'
      align='center'
      >
      Save!
      </Typography>}
  </div>
  </div>);
};

const ChatSessionView = ({ chatSessionId, chatSessionState, viewState }) => {
  const inputRef = React.useRef();
  console.debug("viewState: ", viewState.buffer)
  const chatScrollViewRef = React.useRef();
  const fileDropZoneRef = React.useRef();

  const ui = observable({
    sendEnabled: false,
    activeParticipants: [],
    adviseChatSessionKeyboardActivityTimestampMs: 0,
    readOnly: chatSessionState.body.readOnly,
  });

  const handleChatSessionUpdate = action((data) => {
    ui.readOnly = data.body.readOnly;

    fileDropZoneRef.current.disable(data.body.readOnly);
  });

  const loadDataHandler = async ({ skip, limit }) => {
    const response = await api.queryChatSessionItemsById(chatSessionId,
      { 
        limit: limit,
        skip: skip,
        sort: { createdAt: 'asc' }
      }
    );

    if (!response.success) return null;

    const result = {
      buffer: response.data,
      bufferSkip: response.summary.skip,
    };

    return result;
  };

  const handleSendText = async (e) => {
    const text = inputRef.current.text();

    if (text !== '') {
      inputRef.current.clear();

      await api.postChatSessionTextItem(chatSessionId, { text });

      ui.adviseChatSessionKeyboardActivityTimestampMs = new Date().valueOf() - ADVISE_KEYBOARD_ACTIVITY_THROTTLE_MILLISECONDS;
    }
  };

  const handleInputChange = async (e) => {
    const text = inputRef.current.text();

    ui.sendEnabled = text.length > 0;

    handleActivity();
  };

  const handleActivity = api.throttleInterval(() => {
    const now = new Date().valueOf();
    const delta = now - ui.adviseChatSessionKeyboardActivityTimestampMs;

    if (delta > ADVISE_KEYBOARD_ACTIVITY_THROTTLE_MILLISECONDS) {
      api.adviseChatSessionKeyboardActivity(chatSessionId);
      
      ui.adviseChatSessionKeyboardActivityTimestampMs = now;
    }
  }, 1000);

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13 && e.ctrlKey && !e.altKey && !e.metaKey && !e.shiftKey) {
      await handleSendText();
    }
  };

  const activeParticipantsChangeHandler = async (activeParticipants) => {
    ui.activeParticipants = activeParticipants || [];
  };

  const uploadFileAsync = async (file) => {
    chatScrollViewRef.current.addFileUpload(file);
  }

  const onDropFiles = (acceptedFiles) => {
    acceptedFiles.filter(file => isAcceptedFileType(file)).map(file => uploadFileAsync(file));
  };

  return <FileDropZone ref={fileDropZoneRef} onDrop={onDropFiles} disabled={chatSessionState.body.readOnly} activeContent={
    <div
      className={styles.fileDropZoneActive}
      style={{
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
        pointerEvents: 'none',
      }}
    >
      <div>
        <h3 className="text-center">{i18n.t('common:chat.upload.dropZoneCallToAction')}</h3>
        <p></p>
        <h5 className="text-center">{i18n.t('common:chat.upload.acceptedFilesTitle')}</h5>
        <div className="text-center">
          {i18n.t('common:chat.upload.acceptedFileTypesList')}
        </div>
      </div>
    </div>
  }>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto min-content min-content',
        rowGap: '0.5em',
        gridRowGap: '0.5em',
        justifyItems: 'stretch',
        alignItems: 'stretch',
        justifyContent: 'stretch',
      }}
    >
      <ChatScrollView
        chatSessionId={chatSessionId}
        chatSessionState={chatSessionState}
        initialBuffer={viewState.buffer}
        initialBufferSkip={viewState.bufferSkip}
        onFetchDataAsync={loadDataHandler}
        onActiveParticipantsChange={activeParticipantsChangeHandler}
        onChatSessionUpdate={handleChatSessionUpdate}
        ref={chatScrollViewRef}
      />
      <Observer>{() => <>
        { !ui.readOnly && <div className={styles.typingIndicator}>
          { ui.activeParticipants.length > 0 && i18n.t('common:chat.indicators.typing', {
            count: ui.activeParticipants.length,
            names: ui.activeParticipants.map(i => `${i.user.body.name} (${i.orgUnit.body.name})`).join(', ')
          }) }
        </div> }
      </>}</Observer>
      <Observer>{() => {
        if (ui.readOnly) return <></>;

        return <>
          <ChatInputBar
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            leftButtons={<>
              <Observer>{() => <>
                <div className={styles.keyboardHint}>
                  {ui.sendEnabled && i18n.t('common:chat.input.keyboardHint')}
                </div>
              </>}</Observer>
            </>}

            rightButtons={<>
              <Observer>{() => <>
                <ChatButton
                  onClick={handleSendText}
                >
                  <FileBrowseButton
                    onChange={onDropFiles}
                    multiple={true}
                    accept={ACCEPT_FILE_TYPES.join(',')}
                  >
                    <MdAttachFile />
                  </FileBrowseButton>
                </ChatButton>
                <ChatButton
                  onClick={handleSendText}
                  disabled={!ui.sendEnabled}
                >
                  <MdSend />
                </ChatButton>
              </>}</Observer>
            </>}
          />
        </>;
      }}</Observer>
    </div>
  </FileDropZone>
};

const ChatScrollView = React.memo(React.forwardRef(
  ({
    chatSessionId,
    chatSessionState,
    initialBuffer,
    initialBufferSkip,
    onFetchDataAsync,
    onActiveParticipantsChange,
    onChatSessionUpdate,
    ...rest
  }, selfRef) => {
    console.debug("initial buffer: ", initialBuffer);
    
  const ui = observable({
    bufferSkip: initialBufferSkip,
    buffer: initialBuffer,
    loading: true,
    armScrollDistance: null,
    armScrollToEnd: true,
    chatSessionState: chatSessionState,
    hasIncomingUnreadMessages: false,
    hasActiveParticipants: false,
  });

  const uploadFileAsync = async (item) => {
    const onUploadProgress = (progressEvent) => {
      item.maxValue = progressEvent.total;
      item.value = progressEvent.loaded;
      
      selfRef.current.update(item);
    };
  
    try {
      const response = await api.uploadChatSessionFileItem(chatSessionId, {
        file: item.file,
        onUploadProgress: onUploadProgress,
        silent: true,
      });

      if (response.success) {
        selfRef.current.remove(item);
      } else {
        selfRef.current.error(item);
      }
    } catch (e) {
      item.error = e;
    }
  };

  selfRef.current = {
    addFileUpload: (file) => {
      const item = {
        volatile: true,
        id: uuid(),
        type: 'file',
        text: file.name,
        name: file.name,
        maxValue: file.size,
        value: 0,
        error: false,
        file: file,
        retry: () => {
          item.error = false;
          item.value = 0;

          uploadFileAsync(item);
        },
      };

      ui.buffer = [ ...ui.buffer, item ];

      ui.armScrollToEnd = true;

      uploadFileAsync(item);

      return item;
    },
    remove: (item) => {
      ui.buffer = ui.buffer.filter(i => {
        if (!i.volatile) return true;
        
        return (i.id !== item.id);
      });

      scrollToEnd(false);
    },
    update: (item) => {
      const index = ui.buffer.findIndex(i => i.volatile && i.id === item.id);
      if (index < 0) return;
      ui.buffer[index] = item;
    },
    error: (item) => {
      item.error = true;
      selfRef.current.update(item);
    },
  };

  const endMarkerRef = React.useRef();
  const ref = React.useRef();
  const scrollToEndButtonRef = React.useRef();

  React.useEffect(() => {
    realtime.chatSession(chatSessionId).on('insert:chatSessionItem', handleServerPushItem);
    realtime.chatSession(chatSessionId).on('update:chatSession', handleServerPushChatSessionUpdate);

    scrollToEnd(false);

    ui.loading = false;

    return () => {
      realtime.chatSession(chatSessionId).off('update:chatSession', handleServerPushChatSessionUpdate);
      realtime.chatSession(chatSessionId).off('insert:chatSessionItem', handleServerPushItem);

      if (volatile.chatSessionTimer) {
        clearTimeout(volatile.chatSessionTimer);
        volatile.chatSessionTimer = null;
      }
    };
  });

  const scrollToEnd = (smooth = true) => {
    endMarkerRef.current && endMarkerRef.current.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
  };

  const volatile = {
    chatSessionTimer: null,
    lastReadHistoryItemDateTime: null,
    lastReadHistoryItemId: null,
  };

  const handleServerPushChatSessionUpdate = action(data => {
    const scheduleUpdate = () => {
      if (volatile.chatSessionTimer) {
        clearTimeout(volatile.chatSessionTimer);
        volatile.chatSessionTimer = null;
      }

      const now = new Date();
      const MAX_DELTA_MS = 15000;

      let activeParticipants = (ui.chatSessionState.body.lastParticipantState || [])
        .filter(i => {
          if (i.userId === global.user.user._id && i.orgUnitId === global.user.orgUnit._id) return false;
          if (now.valueOf() - new Date(i.keyboardActivityTimestamp).valueOf() > MAX_DELTA_MS) return false;
          if (new Date(i.keyboardActivityTimestamp) < new Date(i.lastMessageSentTimestamp)) return false;

          return true;
        })
        .sort((a, b) => a.keyboardActivityTimestamp - b.keyboardActivityTimestamp);

      if (activeParticipants.length > 0) {
        const first = activeParticipants[0];

        const checkDate = new Date(first.keyboardActivityTimestamp);
        checkDate.setMilliseconds(checkDate.getMilliseconds() + MAX_DELTA_MS);
        const futureMs = checkDate.valueOf() - now.valueOf();

        volatile.chatSessionTimer = setTimeout(scheduleUpdate, futureMs);
      }

      ui.hasActiveParticipants = activeParticipants.length > 0;

      onActiveParticipantsChange(activeParticipants);
    };

    ui.chatSessionState = data;

    onChatSessionUpdate(data);

    scheduleUpdate();
  });

  const handleServerPushItem = action(data => {
    if (data.body.chatSessionId !== chatSessionId) return;

    ui.buffer = [ ...ui.buffer, data ];

    if (ui.chatSessionState) {
      // Remove typing indicator for the user which just sent a msg
      ui.chatSessionState.body.lastParticipantState =
        (ui.chatSessionState.body.lastParticipantState || [])
          .filter(i => i.userId !== data.body.userId || i.orgUnitId !== data.body.orgUnitId);

      handleServerPushChatSessionUpdate(ui.chatSessionState);
    }

    if (isScrolledAway()) {
      ui.hasIncomingUnreadMessages = true;

      scrollToEndButtonRef.current.style.animation = 'pulse 1s infinite';
    } else {
      ui.armScrollToEnd = true;
    }

    ui.hasActiveParticipants = false;
  });

  const sendAdviseReadToServer = api.throttle((chatSessionId) => {
    return api.adviseChatSessionReadHistoryItemId(chatSessionId, volatile.lastReadHistoryItemId);
  }, 1000);

  const handleAdviseRead = async msg => {
    const msgTime = new Date(msg.body.createdAt);

    if (!volatile.lastReadHistoryItemDateTime || msgTime > volatile.lastReadHistoryItemDateTime) {
      volatile.lastReadHistoryItemDateTime = msgTime;
      volatile.lastReadHistoryItemId = msg.body._id;
    }

    sendAdviseReadToServer(msg.body.chatSessionId);
  };

  const isScrolledAway = () => {
    if (!ref.current) return false;

    const scrollDistance = ref.current.scrollTop + ref.current.offsetHeight;
    
    return scrollDistance < ref.current.scrollHeight - SCROLL_TOLERANCE_PIXELS;
  };

  const handleOnScroll = async e => {
    if (ui.loading) return;
    if (ref.current.scrollTop < 100 && ui.bufferSkip > 0) {
      ui.loading = true;

      const skip = Math.max(0, ui.bufferSkip - MAX_FETCH_LIMIT);
      const limit = ui.bufferSkip - skip;

      const data = await onFetchDataAsync({ skip: skip, limit: limit });

      if (data) {
        ui.armScrollDistance = ref.current.scrollHeight - ref.current.scrollTop;

        ui.buffer = data.buffer.concat(ui.buffer);
        ui.bufferSkip = data.bufferSkip;
      }

      ui.loading = false;
    }

    // Scroll down button
    const scrolledAway = isScrolledAway();
    scrollToEndButtonRef.current.style.opacity = scrolledAway ? 1 : 0;
    
    if (!scrolledAway) {
      scrollToEndButtonRef.current.style.animation = null;
    }
  };

  return <div
    style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'stretch', alignContent: 'stretch', position: 'relative' }}
    className={styles.history}
  >
    <div 
      ref={scrollToEndButtonRef}
      style={{
        position: 'absolute',
        bottom: '2em',
        left: '50%',
        transform: 'translate(-50%,0)',
        zIndex: 6000000,
        transition: 'opacity 0.5s',
        opacity: 0,
        animation: null,
      }}
    >
      <ScrollDownButton onClick={scrollToEnd} />
    </div>

    <div style={{ overflowY: 'auto', width: '100%' }} {...rest} ref={ref} onScroll={handleOnScroll}>
      <Observer>{() => {
        setTimeout(() => {
          if (ui.armScrollDistance !== null) {
            ref.current.scrollTop = ref.current.scrollHeight - ui.armScrollDistance;

            ui.armScrollDistance = null;
          }

          if (ui.armScrollToEnd || !isScrolledAway()) {
            scrollToEnd();

            ui.armScrollToEnd = false;
          }
        }, 0);

        return <ChatContentContainer
          items={ui.buffer}
          hasActiveParticipants={ui.hasActiveParticipants}
          chatSession={ui.chatSessionState}
          onAdviseRead={handleAdviseRead}
        />;
      }}</Observer>
      <div ref={endMarkerRef}></div>
    </div>
  </div>;
}));

const ChatContentContainer = ({ items, chatSession, onAdviseRead, hasActiveParticipants, ...props }) => {
  if (!chatSession) return <></>;

  const otherUsers = (chatSession.body.lastParticipantState || [])
    .filter(i => /*i.userId !== global.user.user._id ||*/ i.orgUnitId !== global.user.orgUnit._id);

  const lastReadHistoryItemDateTime = otherUsers.reduce((acc, curr) => {
    const lastReadHistoryItemDateTime = new Date(curr.lastReadHistoryItemDateTime);

    if (acc === null) {
      acc = lastReadHistoryItemDateTime;
    } else {
      if (lastReadHistoryItemDateTime > acc) {
        acc = lastReadHistoryItemDateTime;
      }
    }

    return acc;
  }, null);

  return <Observer>{() => {
    return <>
      { items.map((value, index) => {
        if (value.volatile) {
          return <ChatVolatileItem
            key={index}
            item={value}
          />
        } else {
          return <Message
            key={index}
            value={value}
            chatSession={chatSession}
            onAdviseRead={onAdviseRead}
            lastReadHistoryItemDateTime={lastReadHistoryItemDateTime}
          />
        }
      }) }
      { hasActiveParticipants === true && <TypingAnimation  /> }
      { chatSession.body.readOnly === true && <chat.SystemMessage text={i18n.t('common:chat.state.readOnly')} /> }
      { !chatSession.body.readOnly && items.length === 0 && <chat.SystemMessage text={i18n.t('common:chat.state.empty')} />}
    </>
  }}</Observer>;
};

const BidiString = ({ value }) => {
  if (isRTLText(value)) {
    return <div style={{ direction: 'rtl', textAlign: 'right' }}>
      {value}
    </div>;
  } else {
    return value;
  }
};

const Message = ({ value, chatSession, lastReadHistoryItemDateTime, onAdviseRead }) => {
  let isImage = value.body.attachment && value.body.attachment.content.mimetype.substr(0, 6) === 'image/';

  const ui = {
    isRead: false,
    status: null,
    msgDate: new Date(value.body.createdAt),
    them: global.user.user._id !== value.body.userId || global.user.orgUnit._id !== value.body.orgUnitId,
    // type: isImage ? 'photo' : 'text', // If preview is needed, replace this with below line
    type:'text',
    preview: isImage ? config.apiBaseUrl + '/' + value.body.attachment.content.preview_url : null
  };

  ui.color = ui.them ? 'blue' : 'green';

  ui.isRead = lastReadHistoryItemDateTime !== null && ui.msgDate <= lastReadHistoryItemDateTime;
  if (global.user.orgUnit._id === value.body.orgUnitId) {
    ui.status = ui.isRead ? 'read' : 'sent';
  }

  const onShow = () => {
    // Don't care about own msgs
    if (global.user.orgUnit._id === value.body.orgUnitId) return;

    onAdviseRead(value);
  };

  const onDownload = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      //api.downloadUrl(value.body.attachment.content.url, value.body.attachment.content.name);
      api.browserDownloadUrl(value.body.attachment.content.url);
    } catch (err) {

    }
  }, [ value ]);

  return <IntersectionVisible onShow={onShow}>
    <div className={ui.them ? 'them' : 'us'}>
      <chat.MessageBox
        key={value.body._id}
        position={ui.them ? 'left' : 'right'}
        type={ui.type}
        title={ui.them ? `${value.body.user.body.name} (${value.body.orgUnit.body.name})` : null}
        notch={false}
        // Preview if needed
        // data={{
        //   uri: ui.preview,
        //   status: {
        //     click: false
        //   }
        // }}
        text={<>
          <div
            className="text-left"
          >
            {!value.body.attachment && (value.body.text || '').split(/\n/g).map((i, index) => {
              return <div key={index}><BidiString value={i} /></div>;
            })}
            { value.body.attachment && <div>
              <BidiString value={value.body.attachment.content.name} />
              {' '}
              (<format.FileSizeString bytes={value.body.attachment.content.size} />)
            </div> }
            { value.body.attachment && <div>
              <small><a href="#" onClick={onDownload}>{i18n.t('common:chat.file.callToAction')}</a></small>
            </div> }
          </div>
        </>}
        dateString={format.DateTimeString(ui.msgDate)}
        avatar={ui.them ? value.body.user.body.photoUrl : null}
        status={ui.status} // waiting, sent, received, read
      />
    </div>
  </IntersectionVisible>;
};

const ScrollDownButton = ({ onClick }) => {
  return (
    <div
      style={{
        backgroundColor: '#eeeeee',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '5em',
        opacity: 0.8,
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '1px 1px 1px 1px rgba(20, 20, 20, 0.5)',
      }}
      onClick={onClick}
    >
      <MdKeyboardArrowDown />
    </div>
  );
};

const ChatInputBar = React.forwardRef(({ onChange, onKeyDown, autoFocus, leftButtons, rightButtons, disabled }, ref) => {
  const textRef = React.useRef();

  React.useEffect(() => {
    ref.current = {
      text: () => {
        return textRef.current.value.replace(/^\s+/g, '').replace(/\s+$/g, '');
      },

      clear: () => {
        textRef.current.value = '';
        textRef.current.style.height = null;
        
        handleChange();
      },
    };

    if (autoFocus) {
      textRef.current.focus();
    }
  });

  const handleKeyDown = React.useCallback((e) => {
    if (disabled) return;

    if (onKeyDown) onKeyDown(e);
  }, [ onKeyDown ]);

  const handleChange = React.useCallback((e) => {
    if (disabled) return;

    textRef.current.style.height = `${textRef.current.scrollHeight}px`;

    if (onChange) onChange(e);
  }, [ onChange ]);

  return <div className={styles.inputBarContainer}>
    <div className={styles.inputBarTextContainer}>
      <div style={{
        maxHeight: '10em',
        overflowY: 'auto',
      }}>
        <textarea
          ref={textRef}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          rows={1}
          style={{
            resize: 'none',
            padding: 0,
            overflow: 'hidden',
            boxSizing: 'border-box',
            width: '100%',
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
          }}
          disabled={disabled}
          readOnly={disabled}
        ></textarea>
      </div>
    </div>
    <div className={styles.inputBarActionsContainer}>
      <div>
        {leftButtons}
      </div>
      <div></div>
      <div>
        {rightButtons}
      </div>
    </div>
  </div>;
});

const ChatButton = ({ onClick, disabled, title = null, children }) => {
  const handleClick = React.useCallback((e) => {
    if (disabled) return;
    if (!onClick) return;

    onClick();
  }, [ onClick, disabled ]);

  return <>
    <div
      onClick={handleClick}
      title={title}
      style={{
        padding: '4px',
        cursor: 'pointer',
        opacity: disabled ? 0.5 : 1,
        backgroundColor: '#aaaaaa',
        marginRight: '0.5em',
        '& :lastChild': {
          marginRight: 0,
        },
        '& *': {
          cursor: 'pointer',
        }
      }}
    >
      {children}
    </div>
  </>;
}

const ChatVolatileItem = ({ item }) => {
  const percent = Math.floor((item.maxValue > 0) ? item.value * 100 / item.maxValue : 0);

  const handleRetryClick = React.useCallback((e) => {
    e.preventDefault();

    item.retry();
  }, [ item ]);

  return <div className="us">
    <chat.MessageBox
      position="right"
      type="file"
      notch={false}
      dateString={format.DateTimeString(item.msgDate)}
      size={item.file.size}
      text={
        <div
          style={{
            display: 'inline-grid',
            gridTemplateColumns: item.error ? 'min-content' : 'min-content auto',
            justifyContent: 'start',
            placeItems: 'center',
            columnGap: '1em',
            gridColumnGap: '1em',
          }}
        >
          { !item.error && <div>
            <CircularProgressbar
              value={item.value}
              minValue={0}
              maxValue={item.maxValue}
              text={`${percent}%`}
              styles={{
                root: {
                  width: '3em',
                  height: '3em',
                }
              }}
            />
          </div> }
          <div>
            <div className="text-left">
              {item.name} (<format.FileSizeString bytes={item.maxValue} />)
            </div>
            { item.error && <>
              <div className="text-left error">
                {i18n.t('common:chat.upload.error')}
              </div>
              { item.retry && <>
                <div className="text-left">
                  <small><a href="#" onClick={handleRetryClick}>{i18n.t('common:chat.upload.retry')}</a></small>
                </div>
              </>}
            </>}
          </div>
        </div>
      }
      avatar={global.user.user.photoUrl}
      status="waiting"
    />;
  </div>
};

const TypingAnimation = () => {
  return <>
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </>;
};
