import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { css } from 'emotion';
import { Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import api from '../lib/api';

import ui from './ui';
import data from './data';
import chart from './chart';
import { Box, Typography } from '@material-ui/core';

const ChartContainer = ({ children }) => {
  return <Box>
    <ui.Container variant='outlined'>
      <Box width='100%' className={css({
        '@media screen and (max-height: 900px)': {
          zoom: 0.8,
        },
        '@media screen and (max-width: 1800px)': {
          zoom: 0.8,
        }
      })}>
        {children}
      </Box>
    </ui.Container>
  </Box>;
};

const Main = ({ state = null, marketplace = false, dashboard = false, mode = 'compact', hideChat = false, autoSize = true, ...rest }) => {
  const { t } = useTranslation();

  const uiState = observable({
    data: null,
    year: null,
    quarter: null
  });

  const setData = (data = []) => {
    uiState.data = data;
  }

  const onFetchDataAsyncHandler = async ({ filter, sort, skip, limit, own, startDate = null, endDate = null }) => {
    const result = marketplace
      ? await api.querySharedShipmentsRFPs({ own: !!own, filter: filter, sort: sort, skip: skip, limit: limit, startDate: startDate, endDate: endDate })
      : await api.queryShipments({ own: !!own, filter: filter, sort: sort, skip: skip, limit: limit, startDate: startDate, endDate: endDate });

    return result;
  };

  const onDataHandler = (data) => {
    setData([...data.data]);
    uiState.year = data.year;
    uiState.quarter = data.quarter;
    uiState.quarterText = parseInt(uiState.quarter) + 1;
  };

  const getFilterOptions = () => {
    if (marketplace) return [];

    const rfp = ['draft', 'rfp', 'cancelled'];
    const active = ['active', 'completed', 'closed', 'dispute'];

    const hasRFPs = (state || '') === '' || state.split(/,/g).find(i => rfp.includes(i));
    const hasActive = (state || '') === '' || state.split(/,/g).find(i => active.includes(i));

    const states = [
      ...(hasRFPs ? rfp : []),
      ...(hasActive ? active : []),
    ];

    const options = [];

    options.push({
      value: {
        filter: {
          state: states.join(','),
        },
      },
      label: i18n.t('common:actions.showAllRecords.label'),
    });

    states.forEach(i => {
      options.push({
        value: {
          filter: {
            state: i,
          },
        },
        label: i18n.t(`common:states.shipment.${i}`),
      });
    });

    options.push({
      value: {
        filter: {
          state: state,
        },
        own: true
      },
      label: i18n.t('common:actions.showOwnRecords.label'),
    });

    return options;
  };


  const getQuarters = () => {
    const quarters = [];

    if (!uiState.year || !uiState.quarter || !uiState.quarterText || !uiState.data)
      return quarters;

    let startYear = uiState.year;
    let startQuarter = uiState.quarterText;

    for (let i = 0; i < 5; i++) {
      if (startQuarter == 0) {
        startQuarter = 4;
        startYear--;
      }

      startQuarter--;
    }

    for (let i = 0; i < 5; i++) {
      startQuarter++;

      if (startQuarter == 5) {
        startQuarter = 1;
        startYear++;
      }

      quarters.push(`Q${startQuarter}-${startYear}`);
    }

    return quarters;
  }

  const getQuarterYear = (shipment) => {
    const date = new Date(shipment.acceptedAt);
    const year = date.getFullYear();
    const quarter = (date.getMonth() >= 9 ? 4 : date.getMonth() >= 6 ? 3 : date.getMonth() >= 3 ? 2 : 1);

    return `Q${quarter}-${year}`;
  }

  // Q? 20?? Summary
  const getSummaryColums = () => {
    const columns = [];

    columns.push({ name: ' ', title: ' ' });
    getQuarters().forEach(i => columns.push({ name: i, title: i }));

    return columns;
  };

  const getSummaryRows = (type = "import") => {
    const shipments = uiState.data.filter(shipment => shipment.body.shipmentType === type);

    const rows = [{}, {}, {}, {}];

    getQuarters().forEach(i => rows[0][i] = 0);
    getQuarters().forEach(i => rows[1][i] = 0);
    getQuarters().forEach(i => rows[2][i] = 0);
    getQuarters().forEach(i => rows[3][i] = 0);

    /* First row (מספר משלוחים) */
    shipments.forEach(shipment => rows[0][getQuarterYear(shipment.body)]++);
    rows[0][' '] = "Number of Shipments";

    shipments.forEach(shipment => rows[1][getQuarterYear(shipment.body)] +=
      (shipment.body.shipmentContent.reduce((acc, content) =>
        acc + (content.quantity * content.physicalProps ? content.physicalProps?.weight : 0), 0)));
    rows[1][' '] = "Total Shipments Weight (Kg)";

    shipments.forEach(shipment => rows[2][getQuarterYear(shipment.body)] += (shipment.body.lausd || 0));
    rows[2][' '] = 'Total Earnings (USD)';
    getQuarters().forEach(quarter => rows[2][quarter] = "$" + rows[2][quarter]);

    shipments.forEach(shipment => rows[3][getQuarterYear(shipment.body)] +=
      (shipment.body.lausd || 0) / (shipment.body.shipmentContent.reduce((acc, content) =>
        acc + (content.quantity * content.physicalProps ? content.physicalProps?.weight : 0), 0)));
    rows[3][' '] = 'Price per Kg (USD)';
    getQuarters().forEach(quarter => rows[3][quarter] = "$" + rows[3][quarter]);

    return rows;
  };

  // PPK by Continent
  const getPPKData = (type = "import") => {
    const shipments = uiState.data.filter(shipment => shipment.body.shipmentType === type);

    const data = [
      { continent: "ASIA" },
      { continent: "AFRICA" },
      { continent: "EUROPE" },
      { continent: "NORTH AMERICA" },
      { continent: "SOUTH AMERICA" },
      { continent: "AUSTRALIA" },
      { continent: "ANTARCTICA" },
    ];

    getQuarters().forEach(i => data[0][i] = 0);

    const holder = [];
    shipments.forEach(shipment => {
      const quarter = getQuarterYear(shipment.body);
      const continent = shipment.body.deliverTo.continent.toUpperCase();

      if (continent !== "" && shipment.body.lausd) {
        if (!holder[continent])
          holder[continent] = [];

        if (!holder[continent][quarter])
          holder[continent][quarter] = { weight: 0, lausd: 0 };

        holder[continent][quarter].weight += (shipment.body.shipmentContent.reduce((acc, content) =>
          acc + (content.quantity * content.physicalProps?.weight), 0));

        holder[continent][quarter].lausd += (shipment.body.lausd);
      }
    });

    getQuarters().forEach(quarter => {
      data.forEach(continent => {
        if (!holder[continent.continent])
          holder[continent.continent] = [];

        if (!holder[continent.continent][quarter])
          holder[continent.continent][quarter] = { weight: 0, lausd: 0 };

        if (holder[continent.continent][quarter]?.weight !== 0)
          continent[quarter] = holder[continent.continent][quarter].lausd / holder[continent.continent][quarter]?.weight;
        else
          continent[quarter] = 0;
      });
    });

    return data;
  };

  // PPK Trend
  const getPPKTrendData = (type = "import") => {
    const shipments = uiState.data.filter(shipment => shipment.body.shipmentType === type);

    const data = [];

    const holder = {};
    shipments.forEach(shipment => {
      const quarter = getQuarterYear(shipment.body);

      if (shipment.body.lausd) {
        if (!holder[quarter])
          holder[quarter] = { weight: 0, lausd: 0 };

        holder[quarter].weight += (shipment.body.shipmentContent.reduce((acc, content) =>
          acc + (content.quantity * content.physicalProps?.weight), 0));

        holder[quarter].lausd += (shipment.body.lausd);
      }
    });

    getQuarters().forEach(i => {
      let currentPpk = 0;
      if (holder[i] && holder[i]?.weight !== 0)
        currentPpk = holder[i].lausd / holder[i]?.weight;

      data.push({ quarter: i, ppk: currentPpk });
    });

    return data;
  };


  return <ui.MainContentContainer autoSize={autoSize} scroll={true}>
    <div style={{ position: 'relative', width: '100%' }}>
      <Box
        display="grid"
        gridTemplateRows="min-content auto min-content"
        width="100%"
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Box width="100%">
          <Box pb={2}>
            <data.YearQuarterDataToolbar
              onFetchDataAsync={onFetchDataAsyncHandler}
              onData={onDataHandler}
              sortOptions={[]}
              filterOptions={getFilterOptions()}
              changeWindow={false}
            />
          </Box>
        </Box>

        <Box width="100%" display="flex" alignItems="stretch" justifyContent="stretch">
          <Observer>{() => {
            if (uiState.data === null || uiState.data.length === 0) {
              return <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h5">{t('common:summary.noData')}</Typography>
              </Box>;
            }

            return (
              <Box width='100%'>

                {/* Import Q? 20?? Summary */}
                <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={1}>
                  <ChartContainer>
                    <chart.DataTable
                      title={"Import Q" + uiState.quarterText + " " + uiState.year + " Summary"}
                      columns={getSummaryColums()}
                      rows={getSummaryRows("import")}
                    />
                  </ChartContainer>
                </ui.PrecisedColumnLayout>

                {/* Import PPK */}
                <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={1}>
                  <ChartContainer>
                    <chart.SideBySideBarChart
                      title={"Import PPK Per Continent"}
                      data={getPPKData("import")}
                      quarters={getQuarters()}
                    />
                  </ChartContainer>
                </ui.PrecisedColumnLayout>

                {/* Import PPK Trend */}
                <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={1}>
                  <ChartContainer>
                    <chart.TrendChart
                      title={"Import PPK Trend"}
                      data={getPPKTrendData("import")}
                    />
                  </ChartContainer>
                </ui.PrecisedColumnLayout>


                {/* Export Q? 20?? Summary */}
                <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={1}>
                  <ChartContainer>
                    <chart.DataTable
                      title={"Export Q" + uiState.quarterText + " " + uiState.year + " Summary"}
                      columns={getSummaryColums()}
                      rows={getSummaryRows("export")}
                    />
                  </ChartContainer>
                </ui.PrecisedColumnLayout>

                {/* Export PPK */}
                <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={1}>
                  <ChartContainer>
                    <chart.SideBySideBarChart
                      title={"Export PPK Per Continent"}
                      data={getPPKData("export")}
                      quarters={getQuarters()}
                    />
                  </ChartContainer>
                </ui.PrecisedColumnLayout>

                {/* Export PPK Trend */}
                <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={1}>
                  <ChartContainer>
                    <chart.TrendChart
                      title={"Export PPK Trend"}
                      data={getPPKTrendData("export")}
                    />
                  </ChartContainer>
                </ui.PrecisedColumnLayout>
              </Box>
            );
          }}</Observer>
        </Box>
      </Box>
    </div>
  </ui.MainContentContainer>;
};

export default Main;
