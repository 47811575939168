import ReconnectableWebSocket from 'reconnectable-websocket';
import config from './config';
import appApi from './api';

const chatSessionSubscriptions = {};

let ws = null;
let consecutiveErrorCounter = 0;

const MAX_CONSECUTIVE_ERRORS = 60;

const init = () => {
  if (ws) return;

  ws = new ReconnectableWebSocket (config.realtimeBaseUrl, undefined, {
    debug: true,
    automaticOpen: true,
    reconnectOnError: true,
    reconnectInterval: 5000,
    timeoutInterval: 15000,
    binaryType: 'blob',
    reconnectOnCleanClose: true,
  });

  ws.onopen = async () => {
    consecutiveErrorCounter = 0;

    // When websocket connection opens, we want to re-subscribe to all the chat sessions
    for (const id in chatSessionSubscriptions) {
      await chatSessionSubscribeInternal(id, false);
    }
  };

  ws.onclose = async () => {
    dispatchEventInternal(null, 'close', {});
  };

  ws.onerror = async (e) => {
    console.log('ws.error: ', e);
    dispatchEventInternal(null, 'error', {});

    ++consecutiveErrorCounter;

    if (consecutiveErrorCounter > MAX_CONSECUTIVE_ERRORS) {
      await appApi.navigateToLogin();
    }
  };

  ws.onmessage = async ({ data }) => {
    try {
      const packet = JSON.parse(data);

      switch (packet.type) {
        case 'error:auth':
          console.log('ws.message: authorization error');

          await appApi.navigateToLogin();
          break;

        case 'subscribe:chatSession':
        case 'unsubscribe:chatSession':
        case 'update:chatSession':
          dispatchEventInternal(packet.data._id, packet.type, packet.data);
          break;

        case 'insert:chatSessionItem':
        case 'update:chatSessionItem':
          dispatchEventInternal(packet.data.chatSessionId, packet.type, packet.data);
          break;

        default:
          // We don't know who this belongs to since we do not recognize the
          // message type
          dispatchEventInternal(null, packet.type, packet.data);
          break;
      }
    } catch (e) {
      console.error('JSON parse error: ', e);
    }
  };
};

const dispatchEventInternal = (chatSessionId, eventType, data) => {
  const dispatchToListeners = (listeners) => {
    if (!listeners) return;
    if (!listeners[eventType]) return;

    // Send notifications to all subscribers and remove those who subscribed _once_
    listeners[eventType] = listeners[eventType].filter(item => {
      item.func(data);

      return !item.once;
    });
  };

  if (chatSessionId && chatSessionSubscriptions[chatSessionId]) {
    dispatchToListeners(chatSessionSubscriptions[chatSessionId].listeners);
  } else {
    Object.keys(chatSessionSubscriptions).forEach(id => {
      dispatchToListeners(chatSessionSubscriptions[id].listeners);
    });
  }
};

const sendJson = (obj) => {
  if (!ws) return;

  ws.send(JSON.stringify(obj));
};

const chatSessionSubscribeInternal = async (id, tail = 100) => {
  sendJson({
    type: 'subscribe:chatSession',
    data: {
      _id: id,
      tail: tail,
    }
  });
};

const api = {};

api.connect = () => {
  // init();
};

api.chatSessionSubscribe = (id, tail = 100) => {
  if (id in chatSessionSubscriptions) return;

  chatSessionSubscriptions[id] = {
    listeners: {}
  };

  return chatSessionSubscribeInternal(id, tail);
};

api.chatSessionUnsubscribe = (id) => {
  if (!(id in chatSessionSubscriptions)) return;

  delete chatSessionSubscriptions[id];

  sendJson({
    type: 'unsubscribe:chatSession',
    data: {
      _id: id
    }
  });
};

api.chatSession = (id) => {
  const result = {
    on: (eventType, func) => {
      api.chatSessionSubscribe(id);

      // Remove current subscription if any to prevent duplicates
      result.off(eventType, func);

      chatSessionSubscriptions[id].listeners[eventType] = chatSessionSubscriptions[id].listeners[eventType] || [];
      chatSessionSubscriptions[id].listeners[eventType].push({
        func: func,
        once: false,
      });
    },
    once: (eventType, func) => {
      api.chatSessionSubscribe(id);

      // Remove current subscription if any to prevent duplicates
      result.off(eventType, func);

      chatSessionSubscriptions[id].listeners[eventType] = chatSessionSubscriptions[id].listeners[eventType] || [];
      chatSessionSubscriptions[id].listeners[eventType].push({
        func: func,
        once: true,
      });
    },
    off: (eventType, func) => {
      if (!chatSessionSubscriptions[id]) return;

      chatSessionSubscriptions[id].listeners[eventType] = chatSessionSubscriptions[id].listeners[eventType] || [];
      
      if (chatSessionSubscriptions[id].listeners[eventType].length === 0) return;

      chatSessionSubscriptions[id].listeners[eventType] = chatSessionSubscriptions[id].listeners[eventType].filter(i => {
        return i.func !== func;
      });

      if (chatSessionSubscriptions[id].listeners[eventType].length === 0) {
        api.chatSessionUnsubscribe(id);
      }
    },
  };

  result.addEventListener = result.on;
  result.removeEventListener = result.off;
  
  return result;
};

export default api;
