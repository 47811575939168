import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper } from '@material-ui/core';

import api from '../lib/api';
import uiControls from './ui';
import data from './data';

const Text = ({ value }) => {
  return <span className="nowrap">
    <React.Fragment>{value}</React.Fragment>
  </span>;
};

export const Logs = ({ autoSize = true }) => {
  const { t } = useTranslation();

  const uiState = observable({
    logs: null,
  });

  const setLogs = (data = []) => uiState.logs = data;

  const onFetchDataAsyncHandler = async ({ filter, sort, skip, limit, own, search }) => {
    const result = await api.queryLogs({ own: own, filter: filter, sort: sort, skip: skip, limit: limit, search: search });

    return result;
  };

  const onDataHandler = (data) => {
    setLogs([ ...data.data ]);
  };

  const getFilterOptions = () => {
    const options = [];

    options.push({
      value: {},
      label: i18n.t('common:actions.showAllRecords.label'),
    });

    options.push({
      value: {
        filter: {
          state: 'shipmentCreation',
        },
      },
      label: i18n.t(`common:states.log.shipmentCreation`),
    });

    options.push({
      value: {
        filter: {
          state: 'bidPlacement'
        },
      },
      label: i18n.t(`common:states.log.bidPlacement`),
    });

    return options;
  };

  const getSortOptions = () => {
    const options = [];

    options.push({
      label: i18n.t('common:fields.log.date'),
      value: {
        sort: { createdAt: 'asc' }
      }
    });

    return options;
  };

  return <>
  <uiControls.MainContentContainer autoSize={autoSize}>
    <uiControls.TableContainer
      autoSize={autoSize}
      header={<>
        <data.SimpleDataToolbar
          onFetchDataAsync={onFetchDataAsyncHandler}
          onData={onDataHandler}
          sortOptions={getSortOptions()}
          filterOptions={getFilterOptions()}
          showSearch
        />
      </>}
    >
      <Observer>{() => {
        if (uiState.logs === null) return <uiControls.TableSkeleton />;
        if (uiState.logs.length === 0) return <uiControls.EmptyTable />;
        
        return (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('common:fields.log.user')}</TableCell>
                <TableCell align="center">{t('common:fields.log.orgUnit')}</TableCell>
                <TableCell align="center">{t('common:fields.log.state')}</TableCell>
                <TableCell align="center">{t('common:fields.log.date')}</TableCell>
                <TableCell align="center">{t('common:fields.log.text')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { (uiState.logs || []).map((i, index) => {
                return <TableRow key={index}>
                  <TableCell>
                    <Text value={i.body.users[0]?.name ?? '-'} />
                  </TableCell>

                  <TableCell align="center">
                    { <Text value={i.body.state === 'bidPlacement' ? 
                      i.body.organizations[0]?.name ?? '-' : 'Not relevant'} /> }
                  </TableCell>

                  <TableCell align="center">
                    <Text value={i.body.state} />
                  </TableCell>

                  <TableCell align="center">
                    <Text value={new Date(i.body.createdAt).toUTCString()} />
                  </TableCell>
                  <TableCell align="center">
                    <Text value={i.body.text} />
                  </TableCell>
                </TableRow>;
              })}
            </TableBody>
          </Table>
        );
      }}</Observer>
    </uiControls.TableContainer>
  </uiControls.MainContentContainer>;
  </> 
};
