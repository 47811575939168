import React from 'react';
import { observable, observe, computed } from 'mobx';
import { Observer } from 'mobx-react';

import config from '../lib/config';

import {
  TextField, Grid, Button, FormHelperText, Box, IconButton
} from '@material-ui/core';

import BackupIcon from '@material-ui/icons/Backup';


const DEFAULT_ACCEPT = config.acceptedFileTypes.join(',');

export const FileDropZone = React.forwardRef(({ activeContent, children, onDrop, disabled }, ref) => {
  let ui = {
    disabled: !!disabled,
  };

  const rootRef = React.useRef();
  const normalRef = React.useRef();
  const activeRef = React.useRef();

  ref.current = {
    disable: (value) => {
      ui.disabled = value;
    },
  };

  React.useEffect(() => {
    const newValGetter = computed(() => disabled);

    const unobserve = observe(newValGetter, (change) => {
      const newVal = newValGetter.get();

      ui.disabled = !!newVal;

      if (ui.disabled) {
        activeRef.current.style.display = 'none';
      }
    });

    return () => {
      unobserve();
    };
  });

  const onDragOver = (e) => {
    if (ui.disabled) return;

    e.preventDefault();
    activeRef.current.style.display = 'block';
  };

  const onDragLeave = (e) => {
    if (ui.disabled) return;

    e.preventDefault();
    activeRef.current.style.display = 'none';
  }

  const onDragDrop = (e) => {
    if (ui.disabled) return;

    e.preventDefault();
    activeRef.current.style.display = 'none';

    const { dataTransfer } = e;

    const files = [];

    if (dataTransfer.items) {
      [ ...dataTransfer.items ].filter(i => i.kind === 'file').map(i => i.getAsFile()).forEach(i => files.push(i));
    } else {
      [ ...dataTransfer.files ].forEach(i => files.push(i));
    }

    if (files.length > 0) {
      onDrop(files);
    }
  };

  return <div
    ref={rootRef}
    style={{
      display: 'flex',
      justifyContent: 'stretch',
      alignItems: 'stretch',
      width: '100%',
    }}
  >
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
      onDrop={onDragDrop}
      onDragOver={onDragOver}
    >
      <div
        style={{
          position: 'absolute',
          top: 0, left: 0, right: 0, bottom: 0, zIndex: 6000000,
          display: 'none',
          //pointerEvents: 'none',
          '& *': {
            pointerEvents: 'none',
          }
        }}
        ref={activeRef}
        onDragLeaveCapture={onDragLeave}
      >
        {activeContent}
      </div>
      <div
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'block' }}
        ref={normalRef}
      >
        {children}
      </div>
    </div>
  </div>;
});

export const FileBrowseButton = ({ onChange, children, accept = DEFAULT_ACCEPT, multiple = false, disabled, ...rest }) => {
  const fileRef = React.useRef();

  const handleChange = React.useCallback(() => {
    if (disabled) return;

    const files = fileRef.current.files;

    if (files.length === 0) return;

    const result = [];
    for (let i = 0; i < files.length; ++i) {
      result[i] = files[i];
    }

    if (onChange) onChange(result);
  }, [ onChange ]);

  const handleClick = React.useCallback(() => {
    if (disabled) return;

    fileRef.current.click();
  }, [ fileRef.current ]);

  return <>
    <div
      style={{
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <input
        ref={fileRef}
        type="file"
        style={{
          position: 'absolute',
          top: 0, left: 0, right: 0, bottom: 0,
          display: 'none',
          opacity: 1,
          boxShadow: 'none',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
        disabled={disabled}
      />
      {children}
    </div>
  </>;
};

export const FileInputControl = ({ onChange, accept = DEFAULT_ACCEPT, disabled, value, label, error, errorText, ...rest }) => {
  const uiState = observable({
    label: value || label,
  });

  //const inputRef = React.useRef();

  const handleChange = ([ file ]) => {
    if (!file) return;

    uiState.label = file.name;
    //inputRef.current.value = file.name;

    if (onChange) onChange(file);
  };

  return <>
    <FileBrowseButton
      onChange={handleChange}
      accept={accept}
      disabled={disabled}
    >
      <Grid container spacing={1} alignItems="center" justify="flex-end">
        <Grid item xs>
          <Observer>{() => <>
            <TextField
              //inputRef={inputRef}
              //defaultValue={value}
              variant="outlined"
              label={uiState.label}
              readOnly
              fullWidth
              disabled
              error={error}
            />
          </>}</Observer>
        </Grid>
        <Grid item>
          <IconButton variant="contained" color="primary">
            <BackupIcon />
          </IconButton>
        </Grid>
      </Grid>
      { errorText && error && <Grid container spacing={1} alignItems="center" justify="flex-end">
        <Grid item xs>
          <Box ml={2}>
            <FormHelperText error={error}>{errorText}</FormHelperText>
          </Box>
        </Grid>
      </Grid> }
    </FileBrowseButton>
  </>;
};
