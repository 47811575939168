import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer, toJS } from 'mobx-react';

import {
  Box,
  Paper,
} from '@material-ui/core';

import ApproveIcon from '@material-ui/icons/ThumbUp';
import DisputeIcon from '@material-ui/icons/ThumbDown';

import { GlobalPortalControl } from '../lib/global';

import ui from './ui';
import action from './action';

import api from '../lib/api';

import ShipmentContractView, { CompactShipmentPricesView, CompactShipmentContractDetailsView } from './shipmentContract.view';

const blobToDataURL = (blob) => {
  return new Promise((resolve, reject) => {
    var a = new FileReader();
    a.onload = e => resolve(e.target.result);
    a.onerror = () => reject(new Error('Failed converting Blob into dataUrl'));

    a.readAsDataURL(blob);
  });
};

export const ShipmentInvoiceValidationForm = ({ }) => {
  const uiState = observable({
    shipmentContract: null,
    attachment: null,
    attachmentUrl: null,
    attachmentName: null,
    queueLength: 0,
  });

  React.useEffect(() => {
    loadData();
  });

  const loadData = async () => {
    uiState.queueLength = 0;
    uiState.attachmentUrl = null;
    uiState.attachmentName = null;
    uiState.shipmentContract = null;
    uiState.attachment = null;

    const queue_response = await api.queryPlatformShipmentContracts({
      filter: {
        invoiceValidationState: 'pending',
        enabled: true,
        state: 'completed,closed,dispute',
      },
      limit: 1,
    });

    if (!queue_response.success || queue_response.data.length === 0) return;

    uiState.queueLength = queue_response.summary.count;
    uiState.shipmentContract = queue_response.data[0];

     const att = await api.queryAttachmentMetadataById(
      uiState.shipmentContract.body.invoiceAttachmentId, {
        silent: true
      });

    if (!att.success || att.data.length === 0) {
      await api.putPlatformShipmentContractInvoiceValidationStateById(uiState.shipmentContract.body._id, 'invalid');

      setTimeout(loadData, 1000);

      return;
    }

    uiState.attachment = att.data.body;

    uiState.attachmentUrl = uiState.attachment.content.url;
    uiState.attachmentName = uiState.attachment.content.name;
  };

  return <>
    <GlobalPortalControl anchor="footer">
      <Observer>{() => <>
        { uiState.queueLength > 0 && <Paper elevation={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            pl={2}
            pr={2}
          >
            <Box>
              <action.Action mode="text" onClick={() => {
                  api.downloadAttachmentById(uiState.attachment._id);
              }}>
                Download Invoice
              </action.Action>
            </Box>
            <Box>
              Invoices in Queue: <b>{uiState.queueLength}</b>
            </Box>
          </Box>
        </Paper> }
      </>}</Observer>
    </GlobalPortalControl>
    <Observer>{() => {
      if (!uiState.shipmentContract || !uiState.attachment || !uiState.attachmentUrl) {
        if (!uiState.queueLength) {
          return <Box p={1}>
            Great job! There are no invoices left in the queue to validate!
          </Box>;
        } else {
          return <Box p={1}>Please wait...</Box>;
        }
      }

      return <>
        <Box
          p={1}
          display="grid"
          gridTemplateColumns="auto auto 200px"
          gridGap="1em"
        >
          <ui.Container autoSize title="Final Invoice">
            <ui.DocumentViewer
              fileName={uiState.attachmentName}
              fileUrl={uiState.attachmentUrl}
            />
          </ui.Container>

          <ui.Container autoSize title="Initial Price Quote">
            <Box
              width="100%"
              p={1}
              display="grid"
              gridTemplateRows="min-content min-content auto"
            >
              <Box p={1}>
                <CompactShipmentContractDetailsView value={uiState.shipmentContract} />
              </Box>
              <Box p={1}>
                <h6 className="block-hsep-50">{i18n.t('common:fields.bid.prices')}</h6>
              </Box>
              <ui.Container autoSize autoScroll>
                <Box p={1} width="100%">
                  <CompactShipmentPricesView value={uiState.shipmentContract} />
                </Box>
              </ui.Container>
            </Box>
          </ui.Container>

          <ui.Container autoSize title="Validation">
            <Box
              display="grid"
              gridTemplateRows="min-content auto min-content"
              justifyContent="center"
              alignItems="center"
              width="100%"
              m={2}
            >
              <action.Action mode="icon" color="success.main" onClick={async () => {
                const response = await api.putPlatformShipmentContractInvoiceValidationStateById(
                  uiState.shipmentContract.body._id, 'valid');

                if (response.success) loadData();
              }}>
                <ApproveIcon style={{ fontSize: '8em' }} />
              </action.Action>
              <div></div>
              <action.Action mode="icon" color="error.main" onClick={async () => {
                const response = await api.putPlatformShipmentContractInvoiceValidationStateById(
                  uiState.shipmentContract.body._id, 'invalid');

                if (response.success) loadData();
              }}>
                <DisputeIcon style={{ fontSize: '8em' }} />
              </action.Action>
            </Box>
          </ui.Container>
        </Box>
      </>;
    }}</Observer>
  </>
};
