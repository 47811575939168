import React from 'react';

import { Observer } from 'mobx-react';

import { TextField, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import airNamesList from './freightCarriers/airNames';
import seaNamesList from './freightCarriers/seaNames';

import val from '../validation';
//import format from '../format';

const allCarriers = [
  ...airNamesList.map(i => { return { transportMode: 'air', name: i }; }),
  ...seaNamesList.map(i => { return { transportMode: 'sea', name: i }; }),
].sort((a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
});

export const FreightCarrierNameTypeaheadControl = ({
  value = null,
  field = null,
  label = null,
  onChange,
  readOnly = false,
  transportMode = null, // air, sea, land
}) => {
  const [ text, setText ] = React.useState((value || {}).name || (value || ''));

  const defaultSelected = [];
  if (value) {
    const filtered = allCarriers.filter(i => i.name === value);
    
    if (filtered.length > 0) {
      defaultSelected.push(filtered[0]);
    }
  }

  const handleChange = ([ val ]) => {
    const { name = '' } = val || {};

    setText(name);

    if (!onChange) return;

    onChange(val);
  };

  const isValid = () => {
    return text !== '';
  }

  const filterOptions = (options, state) => {
    const result = options.filter(i => {
      if (transportMode && i.transportMode !== transportMode) {
        return false;
      }

      if (state.inputValue === '') return true;

      const lowerInput = state.inputValue.toLowerCase();

      return i.name.toLowerCase().indexOf(lowerInput) >= 0;
    });

    return result;
  };

  return <>
    <Observer>{() => <>
      <val.Context field={field}>{({ error, errorText }) => <>
        <Autocomplete
          value={{ name: text }}
          options={allCarriers}
          getOptionLabel={(option) => option.name}
          /*renderOption={(option) => {
            // Performance penalty :(
            return <>
              <Box display="flex" flexWrapping="nowrap">
                <Box pr={1}>
                  <format.TransportMode mode="icon" value={option.transportMode} />
                </Box>
                <Box>
                  {option.name}
                </Box>
              </Box>
            </>;
          }}*/
          readOnly={readOnly}
          disabled={readOnly}
          renderInput={(params) => <TextField {...params} label={label} value={text} variant="outlined"
            error={error || !isValid()}
            helperText={errorText}
            readOnly={readOnly}
            disabled={readOnly}
            //onChange={e => handleChange([{ name: e.target.value }])}
          />}
          filterOptions={filterOptions}
          onChange={(e, val) => handleChange([val])}
          label={label}
          freeSolo
        />
      </>}</val.Context>
    </>}</Observer>
  </>;
};
