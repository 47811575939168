import * as Excel from "exceljs";
import axios from "axios";
import { Buffer } from "buffer";

// Sheets Data
const sheetsData = {
  export: {
    properties: {
      name: "Export",
      columns: 13,
      rows: 100,
    },
    cells: {
      A2: {
        value: "PKU",
        width: 16.86
      },
      B2: {
        value: "Arrival Date at Final Destination",
        width: 20.14
      },
      C2: {
        value: "Carrier",
        width: 23
      },
      D2: {
        value: "PO List",
        width: 20.71
      },
      E2: {
        value: "Forwarder",
        width: 19.71
      },
      F2: {
        value: "Final Destination Country",
        width: 18.86
      },
      G2: {
        value: "Origin Zone Name",
        width: 18.86
      },
      H2: {
        value: "Weight",
        width: 16.29
      },
      I2: {
        value: "Packages",
        width: 16.29
      },
      J2: {
        value: "Incoterms",
        width: 16.29
      },
      K2: {
        value: "Line Amount in USD",
        width: 16.29
      },
      L2: {
        value: "PPK",
        width: 16.29
      },
      M2: {
        value: "SLA",
        width: 16.29
      },
    }
  },
  import: {
    properties: {
      name: "Import",
      columns: 16,
      rows: 100,
    },
    cells: {
      A2: {
        value: "Open Date",
        width: 16.75
      },
      B2: {
        value: "PO List",
        width: 16.75
      },
      C2: {
        value: "Forwarder Name",
        width: 16.75
      },
      D2: {
        value: "Transportation Mode",
        width: 16.75
      },
      E2: {
        value: "Origin Name",
        width: 16.75
      },
      F2: {
        value: "Origin Country Name",
        width: 16.75
      },
      G2: {
        value: "Origin Zone Name",
        width: 16.75
      },
      H2: {
        value: "Destination Name",
        width: 16.75
      },
      I2: {
        value: "Incoterms ID",
        width: 16.75
      },
      J2: {
        value: "Total Weight",
        width: 16.75
      },
      K2: {
        value: "PKU",
        width: 16.75
      },
      L2: {
        value: "Actual Arrival Date",
        width: 16.75
      },
      M2: {
        value: "Released From Customs",
        width: 16.75
      },
      N2: {
        value: "Line Amount in USD",
        width: 16.75
      },
      O2: {
        value: "PPK",
        width: 16.75
      },
      P2: {
        value: "SLA",
        width: 16.75
      },
    }
  }
}

export const createExcel = async (shipments, callback = null) => {
  const workbook = new Excel.Workbook();
  workbook.creator = "CargoSys";
  workbook.created = new Date();

  workbook.properties.date1904 = true;           // Set workbook dates to 1904 date system
  workbook.calcProperties.fullCalcOnLoad = true; // Force workbook calculation on load

  // ===== Variables =====
  // We gotta send a get request to get the image buffer because we cannot import images from the
  // public folder, and exceljs does not support url images.
  const response = await axios.get("/CargoSys Logo.png", { responseType: "arraybuffer" });
  const buffer = Buffer.from(response.data, "utf-8");

  const cargoSysLogo = workbook.addImage({
    buffer: buffer,
    extension: 'png',
  });

  // Looping over every sheet in sheetData and creating a new sheet
  Object.keys(sheetsData).forEach((sheetName, index) => {
    const sheetData = sheetsData[sheetName];

    // ===== Header =====
    const sheet = workbook.addWorksheet(sheetData.properties.name);
    sheet.mergeCells(`A1:${String.fromCharCode(64 + sheetData.properties.columns)}1`);
    sheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' };

    const firstRow = sheet.getRow(1);
    firstRow.height = 80;

    const secondRow = sheet.getRow(2);
    secondRow.height = 40;
    secondRow.font = { bold: true, name: "Assistant", size: 11, color: { argb: "FFFFFF" } };

    sheet.addImage(cargoSysLogo, {
      tl: { col: 5.25, row: 0.75 },
      ext: { width: 280, height: 72 }
    });

    // ===== Table =====
    Object.keys(sheetData.cells).forEach((key, index) => {
      sheet.getColumn(index + 1).width = sheetData.cells[key].width;

      let cell = sheet.getCell(key);

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "0B64A0" }
      };

      cell.value = sheetData.cells[key].value;
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    });

    for (let i = 0; i < sheetData.properties.columns; i++) {
      for (let j = 0; j < sheetData.properties.rows + 2 /* Extra 2 are for header & titles */; j++) {
        const cell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 2}`);

        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      }
    }
  });

  let exportCounter = 0, improtCounter = 0;

  shipments.forEach((shipmentData, index) => {
    const shipment = shipmentData.body;


    if (shipment.shipmentType === "export") {
      const sheet = workbook.getWorksheet(sheetsData.export.properties.name);
      const row = sheet.getRow(exportCounter + 3);


      row.getCell(1).value = new Date(shipment.pku).toLocaleDateString('en-GB');  // Final Arrival Date to Port
      row.getCell(2).value = new Date(shipment.aad).toLocaleDateString('en-GB');  // Arrival Date At Final Destination
      row.getCell(3).value = shipment.shipmentContract.body.freightCarrierName;   // Carrier
      row.getCell(4).value =   shipment.po                                             // Shipper Invoices
      row.getCell(5).value = shipment.shipmentContract.body.orgUnit.body.name;    // Cosignee
      row.getCell(6).value = shipment.deliverTo.country;                          // Destination Country
      row.getCell(7).value = shipment.deliverTo.city;                             // Destination City
      row.getCell(8).value = shipment.shipmentContent.reduce((acc, content) => acc + (content.quantity * (content.physicalProps?.weight || 0)), 0);  // Weight
      row.getCell(9).value = shipment.shipmentContent.reduce((acc, content) => acc + content.quantity, 0);                     // Packages
      row.getCell(10).value = shipment.incoterms;                                 // Incoterms
      row.getCell(11).value = shipment.lausd;                                     // LAUSD
      row.getCell(12).value = {
        formula: `k${exportCounter + 3} / h${exportCounter + 3}`,
        result: parseFloat(shipment.lausd) / parseFloat(row.getCell(8).value)
      }; // PPK (Price per Kg)
      row.getCell(13).value = Math.ceil(Math.abs(new Date(shipment.aad) - new Date(shipment.pku)) / (1000 * 60 * 60 * 24)) + " days"      // SLA

      exportCounter++;
    } else if (shipment.shipmentType === "import") {
      const sheet = workbook.getWorksheet(sheetsData.import.properties.name);
      const row = sheet.getRow(improtCounter + 3);

      row.getCell(1).value = new Date(shipment.acceptedAt).toLocaleDateString('en-GB'); // Open Date
      row.getCell(2).value = shipment.po;                                         // PO List
      row.getCell(3).value = shipment.shipmentContract.body.orgUnit.body.name;    // Exporter Name
      row.getCell(4).value = shipment.transportMode;                              // Transport mode
      row.getCell(5).value = shipment.pickupFrom.city;                            // Origin Name
      row.getCell(6).value = shipment.pickupFrom.country;                         // Origin Country
      row.getCell(7).value = shipment.pickupFrom.continent;                       // Zone name
      row.getCell(8).value = shipment.deliverTo.city;                             // Destination Name
      row.getCell(9).value = shipment.incoterms;                                  // Incoterms
      row.getCell(10).value = shipment.shipmentContent.reduce((acc, content) => acc + (content.quantity * (content.physicalProps?.weight || 0)), 0); // Weight 
      row.getCell(11).value = new Date(shipment.pku).toLocaleDateString('en-GB');                                       // PKU
      row.getCell(12).value = new Date(shipment.aad).toLocaleDateString('en-GB');                                       // Actual Arrival Date
      // row.getCell(13).value =                                                  // Released From Customs
      row.getCell(14).value = shipment.lausd;                                     // LAUSD
      // row.getCell(15).value = parseFloat(shipment.lausd) / parseFloat(row.getCell(10).value);; // PPK (Price per Kg)
      row.getCell(15).value = { formula: `N${improtCounter + 3}/J${improtCounter + 3}`, result: shipment.lausd / row.getCell(10).value };     // PPK (Price per Kg)
      row.getCell(16).value = Math.ceil(Math.abs(new Date(shipment.aad) - new Date(shipment.pku)) / (1000 * 60 * 60 * 24)) + " days"       // SLA

      improtCounter++;
    }
  });

  workbook.xlsx.writeBuffer().then((data) => {
    callback && callback(data);
  });
}

export default createExcel;