import React from 'react';
import i18n from 'i18next';
import { useSnackbar } from 'notistack';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import * as EmailValidator from 'email-validator';

import { v1 as uuid } from 'uuid';

import { Container, Row, Col, Accordion, useAccordionToggle } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {
  Button, Box, Typography,
} from '@material-ui/core';

import api from '../lib/api';
import { globalPushModal } from '../lib/global';
import input from './input';
import format from './format';
import action from './action';

export const globalPushProposeForwarderUserModal = (orgUnitId) => {
  const uiState = observable({
    value: null,
  });
  
  const handleSave = async () => {
    const response = await api.postForwarderUserProposal(orgUnitId, uiState.value);

    if (!response.success) throw new Error(response.error);
  };

  const handleChange = (val) => {
    uiState.value = val;
  };

  globalPushModal({
    title: i18n.t('common:forms.proposeForwarderUser.title'),
    body: <>
      <Box mb={2} width="100%">
        <Typography variant="subtitle">{i18n.t('common:forms.proposeForwarderUser.prompt')}</Typography>
      </Box>
      <ProposeForwarderUserForm onChange={handleChange} />
    </>,
    footer: <Observer>{() => {
      return <action.ModalButtons onAsyncSave={handleSave} disabled={uiState.value === null} action="confirm" affirmation />;
    }}</Observer>,
    options: {
      maxWidth: 'md',
      fullWidth: false,
    },
  });
};

export const globalPushProposeForwarderModal = () => {
  const uiState = observable({
    value: null,
  });
  
  const handleSave = async () => {
    const response = await api.postForwarderProposal(uiState.value);

    if (!response.success) throw new Error(response.error);
  };

  const handleChange = (val) => {
    uiState.value = val;
  };

  globalPushModal({
    title: i18n.t('common:forms.proposeForwarder.title'),
    body: <>
      <Box mb={2} width="100%">
        <Typography variant="subtitle">{i18n.t('common:forms.proposeForwarder.prompt')}</Typography>
      </Box>
      <ProposeForwarderForm onChange={handleChange} />
    </>,
    footer: <Observer>{() => {
      return <action.ModalButtons onAsyncSave={handleSave} disabled={uiState.value === null} action="confirm" affirmation />;
    }}</Observer>,
    options: {
      maxWidth: 'md',
      fullWidth: false,
    },
  });
};

export const ProposeForwarderUserForm = ({ onChange }) => {
  const uiState = observable({
    email: '',
  });

  const change = () => {
    if (!onChange) return;

    const valid =
      uiState.email !== '';
    
    if (valid) {
      onChange(toJS(uiState));
    } else {
      onChange(null);
    }
  };

  const set = (key, val) => {
    uiState[key] = val.replace(/^\s+/g, '').replace(/\s+$/g);

    if (key === 'email' && !EmailValidator.validate(uiState[key])) {
      uiState[key] = '';
    }

    change();
  };

  return <>
    <Box
      width="100%"
    >
      <input.Text
        label={i18n.t('common:forms.proposeForwarderUser.fields.email')}
        onChange={val => set('email', val)}
        required
        value={uiState.email}
      />
    </Box>
  </>;
};

export const ProposeForwarderForm = ({ onChange }) => {
  const uiState = observable({
    name: '',
    contactName: '',
    phone: '',
    email: '',
  });

  const change = () => {
    if (!onChange) return;

    const valid =
      uiState.name !== '' &&
      uiState.contactName !== '' &&
      uiState.phone !== '' &&
      uiState.email !== '';
    
    if (valid) {
      onChange(toJS(uiState));
    } else {
      onChange(null);
    }
  };

  const set = (key, val) => {
    uiState[key] = val.replace(/^\s+/g, '').replace(/\s+$/g);

    if (key === 'email' && !EmailValidator.validate(uiState[key])) {
      uiState[key] = '';
    }

    change();
  };

  return <>
    <Box
      width="100%"
    >
      <input.Text
        label={i18n.t('common:forms.proposeForwarder.fields.name')}
        onChange={val => set('name', val)}
        required
        value={uiState.name}
      />
      <input.Text
        label={i18n.t('common:forms.proposeForwarder.fields.contactName')}
        onChange={val => set('contactName', val)}
        required
        value={uiState.contactName}
      />
      <input.Text
        label={i18n.t('common:forms.proposeForwarder.fields.phone')}
        onChange={val => set('phone', val)}
        required
        value={uiState.phone}
      />
      <input.Text
        label={i18n.t('common:forms.proposeForwarder.fields.email')}
        onChange={val => set('email', val)}
        required
        value={uiState.email}
      />
    </Box>
  </>;
};

const Item = ({ value, onChange }) => {
  const [ id ] = React.useState(uuid());
  const [ users, setUsers ] = React.useState([]);

  const isSelected = value.body.options.forwarder.dynamic.ui.isSelected;
  const readOnly = !value.body.options.forwarder.dynamic.ui.canChangeSelection;
  
  const handleChange = () => {
    onChange(value);
  };

  const CustomToggle = ({ children, eventKey }) => {
    const currentEventKey = React.useContext(AccordionContext);

    const isExpanded = currentEventKey === eventKey;

    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      const newState = !isExpanded;

      if (newState) {
        api.querySharedForwarderUsers(value.body._id).then(response => {
          if (response.success) {
            setUsers(response.data);
          } else {
            setUsers([]);
          }
        });
      } else {
        setUsers([]);
      }
    });

    return <>
      <Button variant="text" onClick={decoratedOnClick}>
        { isExpanded ? 'Hide Contacts' : 'Show Contacts' }
      </Button>
    </>;
  };

  return <>
    <Accordion defaultActiveKey="0">
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col className="pl-0 pr-0">
            <input.Check forceControl readOnly={readOnly} value={isSelected} label={value.body.name} onChange={handleChange} id={id} type="checkbox" />
          </Col>
          <Col className="text-center col-align-middle">
            <action.OrgUnitRank orgUnit={value} />
          </Col>
          <Col className="text-center col-align-middle">
            <action.OrgUnitPublicForwarderProfile orgUnit={value} />
          </Col>
          <Col className="col-align-middle pr-0" xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
            <CustomToggle eventKey={value.body._id} />
          </Col>
        </Row>
        <Accordion.Collapse eventKey={value.body._id}>
          <Row className="justify-content-md-center">
            <Col className="pt-0 pb-0 pl-0">
              {users.map(user => format.UserListItem({ value: user }))}
              { !users.length && <>
                <div className="primary-list-item">
                  No active contacts found. Please try again later.
                </div>
              </> }
              <action.ProposeForwarderContact forwarder={value} onClick={() => globalPushProposeForwarderUserModal(value.body._id)} />
            </Col>
          </Row>
        </Accordion.Collapse>
      </Container>
    </Accordion>
  </>;
};

const Main = ({ maxCount = 5, onChange }) => {
  const [ data, setData ] = React.useState(null);
  const [ search, setSearch ] = React.useState('');
  const [ count, setCount ] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!data) {
      api.querySharedUserForwarders().then(result => {
        if (result.success) {
          setData([ ...result.data ]);

          setCount(result.data.filter(i => i.body.options.forwarder.dynamic.ui.isSelected && !i.body.options.forwarder.dynamic.ui.canChangeSelection).length);

          if (onChange) {
            onChange(result.data);
          }
        }
      });
    }
  });

  const handleProposeForwarder = React.useCallback(() => {
    globalPushProposeForwarderModal();
  });
  
  const handleChange = (dataItem) => {
    dataItem.body.options.forwarder.dynamic.ui.isSelected = !dataItem.body.options.forwarder.dynamic.ui.isSelected;

    if (dataItem.body.options.forwarder.dynamic.ui.isSelected) {
      const optionalCount =
        data.filter(i =>
          i.body.options.forwarder.dynamic.ui.isSelected &&
          i.body.options.forwarder.dynamic.ui.canChangeSelection
        ).length;

      if (optionalCount <= maxCount) {
        setCount(count + 1);
      } else {
        dataItem.body.options.forwarder.dynamic.ui.isSelected = false;

        enqueueSnackbar(<>
          <format.PlainText value={i18n.t('common:forwardersForm.messages.tooManyOptionalForwarders', { count: maxCount })} />
        </>);
      }
    } else {
      setCount(count - 1);
    }

    if (onChange) {
      onChange(data);
    }

    setData([ ...data ]);
  };

  const handleChangeSearch = (text) => {
    const trimmed = text.replace(/^\s+/g, '').replace(/\s+$/g, '');
    if (search !== trimmed) {
      setSearch(trimmed);
    }
  };

  return <>
    <input.Search value={search} onChange={handleChangeSearch} />
    <p></p>
    <div style={{ height: '15em', overflowY: 'scroll' }}>
      {data && data.filter(i => i.body.name.toLowerCase().indexOf(search.toLowerCase()) >= 0).map((dataItem, index) => <Item key={index} value={dataItem} onChange={handleChange} />)}
      <div className="text-center">
        <action.ProposeForwarder onClick={handleProposeForwarder} />
      </div>
    </div>
  </>;
};

export default Main;
