import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import input from './input';
import format from './format';
import action from './action';
import ui from './ui';

import api from '../lib/api';

import { ChatSession } from './chat';
import { AttachmentsList } from './attachments.list';

import {
  Paper, Box, Grid, AppBar, Toolbar,
} from '@material-ui/core';

import TrackingIcon from '@material-ui/icons/Room';
import ApproveIcon from '@material-ui/icons/ThumbUp';
import DisputeIcon from '@material-ui/icons/ThumbDown';
import { InvoiceCompare } from './InvoidCompare';

export const ShipmentOpCenter = ({ chatSessionId, shipmentId, shipmentContractId, mode = 'chat' }) => {
  const uiState = observable({
    contract: null,
    shipment: null,
    shipmentTracking: null,
    lead: null,
  });

  React.useEffect(() => {
    if (shipmentContractId) {
      api.queryShipmentContractById(shipmentContractId).then(response => {
        if (!response) return;
        if (!response.success) return;

        uiState.contract = response.data;
        uiState.shipment = response.data.body.shipment;
        uiState.shipmentTracking = response.data.body.shipmentTracking;
        uiState.lead = 'shipmentContract';
      });
    } else if (shipmentId) {
      api.queryShipmentById(shipmentId).then(response => {
        if (!response.success) return;

        uiState.contract = response.data.body.shipmentContract;
        uiState.shipment = response.data;
        uiState.shipmentTracking = response.data.body.shipmentTracking;
        uiState.lead = 'shipment';
      });
    }
  });

  const handleOpStateChange = async (val) => {
    const response = await api.updateShipmentOperationalStateByShipmentContractId(
      uiState.contract.body._id,
      val
    );

    if (response.success) {
      uiState.contract.body.operationalState = val;
    }
  };

  const getActiveTabIndex = React.useCallback(() => {
    switch (mode) {
      case 'attachments':
        return 1;

      default:
        return 0;
    }
  }, [ mode ]);

  return <Observer>{() => {
    const enableStates = [ 'contract', 'dispute', 'completed' ];
    const enableInputStates = [ 'contract' ];

    const disableToolbar = (!uiState.contract || !uiState.shipment || !enableStates.includes(uiState.contract.body.state));
    const disableToolbarInput = disableToolbar || !enableInputStates.includes(uiState.contract.body.state);

    const trackingCodeCount =
      (uiState.shipmentTracking && uiState.shipmentTracking.body)
        ? uiState.shipmentTracking.body.length
        : 0;

    return <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto min-content',
          width: '100%',
        }}
      >
        <Box m={1} style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: disableToolbar ? 'auto' : 'min-content auto',
              width: '100%',
            }}
          >
            { !disableToolbar && <Box mb={2}>
              <Paper variant="outlined">
                <Box padding={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid container item xs alignItems="center"> 
                      { !disableToolbarInput && <>
                        <action.AsyncShipmentOperationalState
                          value={uiState.contract.body.operationalState}
                          showKeys={false}
                          formGroup={false}
                          readOnly={disableToolbarInput}
                          onAsyncChange={handleOpStateChange}
                        />
                      </> }
                      { disableToolbarInput && <>
                        <div style={{ display: 'inline-grid', gridTemplateColumns: 'auto auto'}}>
                          { uiState.contract.body.invoiceAttachmentId && <>
                            <Box mr={2}>
                              <action.ViewAttachment
                                attachmentId={uiState.contract.body.invoiceAttachmentId}
                                color="default"
                                size="large"
                                mode="button"
                              />
                              
                              {/* <action.DownloadAttachment
                                attachmentId={uiState.contract.body.invoiceAttachmentId}
                                label={i18n.t('common:actions.downloadInvoice.label')}
                                size="large"
                                color="default"
                              /> */}
                             
                              { uiState.lead === 'shipmentContract' && uiState.contract.body.state === 'dispute' && <>
                                <Box ml={1} display="inline-block">
                                  <action.UpdateShipmentContractState
                                    size="large"
                                    value={uiState.contract}
                                    state="completed"
                                  />
                                </Box>
                              </> }
                            </Box>
                          </> }
                          { shipmentId && <format.InvoiceValidationState value={uiState.contract.body.invoiceValidationState} /> }
                        </div>
                      </> }
                    </Grid>
                    <Grid item>
                    </Grid>
                    <Grid container item alignItems="center">
                      <action.ShipmentTracking
                        mode={disableToolbarInput ? 'view' : 'action'}
                        value={uiState.shipmentTracking}
                        shipmentContract={uiState.contract}
                        shipment={uiState.shipment}
                        lead={uiState.lead}
                        color="primary"
                        size="large"
                        readOnly={disableToolbarInput}
                      >
                        <TrackingIcon />
                        {' '}
                        { trackingCodeCount > 0
                          ? i18n.t('common:shipmentOpCenter.shipmentTracking.track', { count: trackingCodeCount })
                          : i18n.t('common:shipmentOpCenter.shipmentTracking.none')
                        }
                      </action.ShipmentTracking>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box> }
            <div style={{ width: '100%', display: 'flex', justifyContent: 'stretch', alignItems: 'stretch' }}>
              <ui.Tabs
                activeIndex={getActiveTabIndex()}
                tabs={[
                  {
                    label: i18n.t('common:shipmentOpCenter.tabs.chat'),
                    content: <ChatSession shipmentId={shipmentId} />,
                  },
                  {
                    label: i18n.t('common:shipmentOpCenter.tabs.attachments'),
                    content: () => uiState.contract && <AttachmentsList shipmentContractId={uiState.contract.body._id} />,
                  },
                  {
                    label: "Comparing invoices",
                    content: () => <InvoiceCompare shipmentContractId={shipmentContractId} shipmentId={shipmentId}/>,
                  }
                ]}
                key={"tabs"}
              />
            </div>
          </div>
        </Box>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'stretch',
          }}
        >
          { shipmentId && uiState.contract && [ 'completed', 'dispute' ].includes(uiState.contract.body.state) && <>
            <Box m={1} style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', width: '15em' }}>
              <ui.Container autoSize autoScroll bgcolor="grey.800">
                <Box p={2} style={{ textAlign: 'center' }} pt={4}>
                  <Box>
                    <action.CloseShipment mode="icon" value={uiState.shipment} color="success.main">
                      <ApproveIcon style={{ fontSize: '8em' }} />
                    </action.CloseShipment>
                    <Box mt={1} color="common.white">
                      {i18n.t('common:actions.closeShipment.label')}
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <action.DisputeShipment
                      mode="icon"
                      value={uiState.shipment}
                      disabled={uiState.contract.body.state !== 'completed'}
                      color={ uiState.contract.body.state === 'completed' ? 'error.main' : null }
                    >
                      <DisputeIcon style={{ fontSize: '8em' }} />
                    </action.DisputeShipment>
                    <Box mt={1} color="common.white">
                      {i18n.t('common:actions.disputeShipment.label')}
                    </Box>
                  </Box>
                </Box>
              </ui.Container>
            </Box>
          </> }
        </div> */}
      </div>
    </>;
  }}</Observer>;
};

export default ShipmentOpCenter;
