import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { v1 as uuid } from 'uuid';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { toJS } from 'mobx';
import { css } from 'emotion';

import {
  Button, Link, IconButton, Box, ButtonGroup, TextField, CircularProgress,
  OutlinedInput, InputAdornment, Badge, MenuList, MenuItem, Typography,
  Tooltip,
  Grid,
  Checkbox,
} from '@material-ui/core';

import { Input as MaterialUIInput } from '@material-ui/core';

import MdSave from '@material-ui/icons/Check';
import MdCancel from '@material-ui/icons/Close';
import MdDispute from '@material-ui/icons/Gavel';
import MdShipmentClosed from '@material-ui/icons/CheckCircle';
import MdEdit from '@material-ui/icons/Edit';
import MdDuplicate from '@material-ui/icons/FileCopy';
import MdDetails from '@material-ui/icons/Pageview';
import MdDelete from '@material-ui/icons/DeleteForever';
import MdSubmit from '@material-ui/icons/CheckCircle';
import MdError from '@material-ui/icons/Error';
import MdAcceptBidRequest from '@material-ui/icons/PlayForWork';
import MdTrackingIcon from '@material-ui/icons/Room';
import MdRadarIcon from '@material-ui/icons/TrackChanges';
import MdCompany from '@material-ui/icons/Business';
import MdView from '@material-ui/icons/Pageview';
import MdExcel from '../assets/icons/microsoft-excel.svg';
import { MdAttachFile } from 'react-icons/md';


import { Container, Row, Col, Form, Dropdown, FormControl } from 'react-bootstrap';

import format from './format';
import input from './input';
import ui from './ui';
import val from './validation';
import api from '../lib/api';

import global, { globalPushModal, globalPopModal } from '../lib/global';
import { globalPushUserFormModal } from './user.form';
import { globalPushPublicForwarderProfileModal} from './publicForwarderProfile.form';

import ShipmentForm, { createBlankShipment } from './shipment.form';
import ShipmentContractForm from './shipmentContract.form';
import ForwardersForm from './forwarders.form';
import { CompactShipmentContractDetailsView, CompactShipmentPricesView } from './shipmentContract.view';
import { ShipmentContractTrackingForm, ShipmentTrackingView } from './shipmentTracking';
import { ShipmentOpCenter } from './shipmentOpCenter';
import { ShipmentContractValidationSchema } from './validation';
import { CompleteShipmentContractForm } from './completeShipmentContract.form';
import { ForwarderFeedbackForm, OrgUnitFeedbackList, RatingItemView } from './feedback';
import Input from './input';
import config from '../lib/config';
import { options } from 'numeral';
import moment from 'moment';

const ACCEPT_FILE_TYPES = config.acceptedFileTypes;

export const globalPushValidationSummary = ({ value, schema, startButtons = null, ...rest }) => {
  globalPushModal({
    title: 'Validation Summary',
    body: <>
      <val.Container value={value}>
        <val.Summary schema={schema} />
      </val.Container>
    </>,
    footer: <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
      {startButtons}
      <ModalButtons />
    </div>,
    ...rest,
  });
};

export const globalPushShipmentContractModal = ({ value, readOnly: forceReadOnly, onChange }) => {
  let state = value;
  const ui = observable({
    locked: 0,
  });

  const readOnly = forceReadOnly || value.body.readOnly || ![ 'draft', 'bid' ].includes(value.body.state);

  const handleValidationError = (validation) => {
    return new Promise((resolve, reject) => {
      globalPushValidationSummary({
        value: validation,
        schema: ShipmentContractValidationSchema,
        onHide: resolve,
      });
    });
  };

  const onSave = async () => {
    try {
      ++ui.locked;
      const response = (state.body._id)
        ? await api.updateShipmentContract(state.body, { onValidationError: handleValidationError })
        : await api.createShipmentContract(state.body);

      if (!response.success) throw new Error('Failed saving');

      if (onChange) onChange(state);

      return await api.navigateShipmentContractState(state.body.state);
    } catch (e) {
      --ui.locked;

      throw e;
    }
  };

  const onPowerSave = async () => {
    try {
      ++ui.locked;

      const create = !state.body._id;

      const response = !create
        ? await api.updateShipmentContract(state.body, { onValidationError: handleValidationError })
        : await api.createShipmentContract(state.body, { onValidationError: handleValidationError });

      if (!response.success) throw new Error('Failed saving');

      state = response.data;

      if (onChange) onChange(state);

      if (state.validation && Object.keys(state.validation).length > 0) {
        if (!create) {
          api.reload({ replace: false });
        } else {
          api.navigateShipmentContractState(state.body.state);
        }

        await handleValidationError(state.validation);

        throw new Error('Validation error');
      }

      if (response.data.body.state !== 'bid') {
        const confirmed = await confirmUpdateShipmentContractState({ value: state, state: 'bid' });

        if (!confirmed) throw new Error('User rejected bidding terms');

        const response2 = await api.updateShipmentContractStateById(response.data.body._id, 'bid', { onValidationError: handleValidationError });

        if (!response2.success) throw new Error('Failed updating state');
      }

      return await api.navigateShipmentContractState(state.body.state);
    } catch (e) {
      throw e;
    } finally {
      --ui.locked;
    }
  };

  const handleChange = val => {
    state = val;
  };

  const ContractDetails = ({}) => {
    return <>
      {console.log({value})}
      <CompactShipmentContractDetailsView value={value} />
      <h6 className="block-hsep-50">{i18n.t('common:fields.bid.prices')}</h6>
      <CompactShipmentPricesView value={value} />
    </>;
  };

  const text = value.body.orgUnit ? ` - ${value.body.orgUnit.body.name}` : '';

  globalPushModal({
    title: `${value.body.globalRecordNumber || i18n.t('common:title.shipmentContract.new')} - ${value.body.shipment.body.globalRecordNumber}${text}`,
    body: <Observer>{() => (<>
      { readOnly ? <ContractDetails /> : <ShipmentContractForm value={state} readOnly={readOnly} disabled={ui.locked > 0} onChange={handleChange} /> }
    </>)}</Observer>,
    footer: !readOnly ? <ModalButtons
      onAsyncSave={value.body.state === 'draft' ? onSave : null}
      onAsyncPowerSave={onPowerSave}
      action={value.body.state === 'draft' ? 'saveDraft' : 'save'}
      powerAction="saveAndSubmitBid"
      powerAffirmation={true}
    /> : <ModalButtons />,
    titleActions: <Box width="100%" display="flex" justifyContent="flex-end">
      <ShipmentDetails readOnly={true} value={value.body.shipment} mode="button" action="viewShipmentDetails">
        {i18n.t('common:actions.viewShipmentDetails.label')}
      </ShipmentDetails>
    </Box>,
    options: {
      large: false,
      fullWidth: true,
    },
  });
};

export const globalPushShipmentModal = ({ value, readOnly, onChange }) => {
  let state = value;
  const ui = observable({
    locked: 0,
  });

  const onAsyncSave = async () => {
    try {
      ++ui.locked;

      const response = (state.body._id)
        ? await api.updateShipment(state.body)
        : await api.createShipment(state.body);

      if (!response.success) throw new Error('Failed saving');

      if (onChange) onChange(state);

      await api.navigateShipmentState(state.body.state);
    }
    catch (e) {
      --ui.locked;

      throw e;
    }
  };

  const handleChange = val => {
    state = val;
  };

  const wizardContext = observable({
    wizard: null,
    step: 0,
  });

  const onWizardInit = SW => {
    console.log('onwizardInit: ', SW);

    wizardContext.wizard = SW;
    wizardContext.step = SW.currentStep;
  };

  const onWizardStepChange = ({ previousStep, activeStep }) => {
    console.log('onWizardStepChange: ', activeStep);

    wizardContext.step = activeStep;
  };

  globalPushModal({
    title: state.body.globalRecordNumber || i18n.t('common:title.shipment.new'),
    body: <Observer>{() => (
            <ShipmentForm
              value={state}
              readOnly={readOnly || ui.locked > 0}
              onChange={handleChange}
              onWizardInit={onWizardInit}
              onWizardStepChange={onWizardStepChange}
              mode={state.body.globalRecordNumber ? 'edit' : 'create'}
            />
          )}</Observer>,
    footer: <>
      { !readOnly ? (<Observer>{() => {
        const wizard = wizardContext.wizard;
        const step = wizardContext.step;

        const showSave = !wizard || (step === wizard.totalSteps) || (step >= 2);
        // First step is always "welcome" and can't be returned to
        const enablePrev = wizard && (step > 2);
        // First step is always "welcome"
        const enableNext = wizard && (step < wizard.totalSteps && wizard.currentStep > 1);

        const handlePrev = () => {
          if (!enablePrev) return;

          wizard.previousStep();
        };

        const handleNext = () => {
          if (!enableNext) return;

          wizard.nextStep();
        };

        return <>
          <ModalButtons
            mode={wizard ? 'wizard' : 'normal'}
            disabled={!showSave}
            onAsyncSave={onAsyncSave}
            action="saveDraft"
            showNext={ step != 4}
            onPrevStep={wizard && enablePrev ? handlePrev : null}
            onNextStep={wizard && enableNext ? handleNext : null}
          />
        </>;
      }}</Observer>) : (<>
        <ModalButtons />
      </>) }
    </>,
    options: {
      large: true,
      fullWidth: true,
      fixedHeight: true,
    },
  });
};

// ====
export const globalPushShipmentSealModal = ({ shipment}) => {

  let state = {
    body: {
      _id: shipment.body._id,
      readOnly: shipment.body.readOnly,
      enabled: shipment.body.enabled,

      errorText:"",
      pku: moment(shipment.body.pku),
      pudError:false,
      aadError:false,
      aad: moment(shipment.body.aad),
      lausd: shipment.body.lausd,
      po: shipment.body.po,
      invoice: shipment.body.shipmentContract.body.invoice
    },
    validation: {},
  };

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);

  const ui = observable({
    locked: 0,
  });

  const onAsyncSave = async () => {
    try {
      ++ui.locked;

      const shipmentOne = { ...state.body };
      delete shipmentOne.invoice;
      
      const response = await api.updateShipment(shipmentOne);

      if (!response.success) throw new Error('Failed saving');

      if(state.body.invoice) {
        const response2 = await api.uploadShipmentContractInvoiceByIdClientPerms(shipment.body.shipmentContract.body._id, state.body.invoice);
        if (!response2.success) throw new Error('Failed saving');
      }

      await api.reload();
    }
    catch (e) {
      --ui.locked;

      throw e;
    }
  };

  const handleSealClick = async () => {
    await handleSaveClick();
    state.body.state = 'completed';
    
    if(state.body.invoice) {
      await api.completeShipment(state.body._id, {});
    }
  };

  const handleSaveClick = async () => {
    state.body.state = 'completed';
    state.body.errorText = "";
    state.body.pudError = false;
    state.body.aadError = false;
    if(!state.body.lausd){
      state.body["invoice"] = null;
      state.body.errorText = "lausd is required";
      globalPopModal()
      globalPushModal({
        title: state.body.globalRecordNumber,
        body: <Observer>{() => (
          <>
            <div>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["pku"] || new Date()} label={shipment.body.shipmentType=="export"?"Arrival date origin port / pick up date":"Final Arrival Date to Port"} hasError={state.body.pudError} onChange={(test) => set("pku",test)}/>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["aad"] || new Date()} label={"Final arrival date destination"} hasError={state.body.aadError} onChange={(test) => set("aad",test)} />
              <Input.Text readOnly={readOnly} error={true} type="number" value={state.body["lausd"] || 0} label={"Line Amount (Exclude Vat) (USD)"} onChange={(val) => set("lausd",val)} InputProps={{ inputProps: { min: 0 }}} />
            <Input.Text readOnly={readOnly} value={state.body["po"] || ""} label={"PO Number"} onChange={(val) => set("po",val)} />
                <>
                  <Input.Text readOnly={readOnly} value={state.body["invoice"] || ""} label={"Invoice"} type="file" onChange={setInvoice} multiple={ false } accept={ACCEPT_FILE_TYPES.join(',')} />
                </>
                {state.body.errorText}
            </div>
          </>
          )}</Observer>,
        footer: <>
              <Observer>{() => {
                return <Observer>{() => <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box m={2}><ModalCancelButton /></Box>
                    { !readOnly && !hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSaveClick}>{"Save"}</Button></Box> }
                    { !readOnly && hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSealClick}>{"Seal"}</Button></Box> }
                  </div>
              </>}</Observer>;
          }}</Observer>
        </>,
        options: {
          fullWidth: false,
          smallWidth: true
        }
      });
      return
    }
    if(!state.body.po){
      state.body["invoice"] = null;
      state.body.errorText = "po is required";
      globalPopModal()
      globalPushModal({
        title: state.body.globalRecordNumber,
        body: <Observer>{() => (
          <>
            <div>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["pku"] || new Date()} label={shipment.body.shipmentType=="export"?"Arrival date origin port / pick up date":"Final Arrival Date to Port"} hasError={state.body.pudError} onChange={(test) => set("pku",test)}/>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["aad"] || new Date()} label={"Final arrival date destination"} hasError={state.body.aadError} onChange={(test) => set("aad",test)} />
              <Input.Text readOnly={readOnly} type="number" value={state.body["lausd"] || 0} label={"Line Amount (Exclude Vat) (USD)"} onChange={(val) => set("lausd",val)} InputProps={{ inputProps: { min: 0 }}} />
            <Input.Text readOnly={readOnly} error={true}  value={state.body["po"] || ""} label={"PO Number"} onChange={(val) => set("po",val)} />
                <>
                  <Input.Text readOnly={readOnly} value={state.body["invoice"] || ""} label={"Invoice"} type="file" onChange={setInvoice} multiple={ false } accept={ACCEPT_FILE_TYPES.join(',')} />
                </>
                {state.body.errorText}
            </div>
          </>
          )}</Observer>,
        footer: <>
              <Observer>{() => {
                return <Observer>{() => <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box m={2}><ModalCancelButton /></Box>
                    { !readOnly && !hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSaveClick}>{"Save"}</Button></Box> }
                    { !readOnly && hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSealClick}>{"Seal"}</Button></Box> }
                  </div>
              </>}</Observer>;
          }}</Observer>
        </>,
        options: {
          fullWidth: false,
          smallWidth: true
        }
      });
      return
    }
    if(!state.body.invoice){
      state.body["invoice"] = null;
      state.body.errorText = "file is required";
      globalPopModal()
      globalPushModal({
        title: state.body.globalRecordNumber,
        body: <Observer>{() => (
          <>
            <div>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["pku"] || new Date()} label={shipment.body.shipmentType=="export"?"Arrival date origin port / pick up date":"Final Arrival Date to Port"} hasError={state.body.pudError} onChange={(test) => set("pku",test)}/>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["aad"] || new Date()} label={"Final arrival date destination"} hasError={state.body.aadError} onChange={(test) => set("aad",test)} />
              <Input.Text readOnly={readOnly} type="number" value={state.body["lausd"] || 0} label={"Line Amount (Exclude Vat) (USD)"} onChange={(val) => set("lausd",val)} InputProps={{ inputProps: { min: 0 }}} />
            <Input.Text readOnly={readOnly} value={state.body["po"] || ""} label={"PO Number"} onChange={(val) => set("po",val)} />
                <>
                  <Input.Text readOnly={readOnly} error={true} value={state.body["invoice"] || ""} label={"Invoice"} type="file" onChange={setInvoice} multiple={ false } accept={ACCEPT_FILE_TYPES.join(',')} />
                </>
                {state.body.errorText}
            </div>
          </>
          )}</Observer>,
        footer: <>
              <Observer>{() => {
                return <Observer>{() => <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box m={2}><ModalCancelButton /></Box>
                    { !readOnly && !hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSaveClick}>{"Save"}</Button></Box> }
                    { !readOnly && hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSealClick}>{"Seal"}</Button></Box> }
                  </div>
              </>}</Observer>;
          }}</Observer>
        </>,
        options: {
          fullWidth: false,
          smallWidth: true
        }
      });
      return
    }
    if(state.body.pku > state.body.aad){
      state.body["invoice"] = null;
      state.body.errorText = "actual arrival date cant be before pick up date";
      state.body.aadError = true;
      globalPopModal()
      globalPushModal({
        title: state.body.globalRecordNumber,
        body: <Observer>{() => (
          <>
            <div>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["pku"] || new Date()} label={shipment.body.shipmentType=="export"?"Arrival date origin port / pick up date":"Final Arrival Date to Port"} hasError={state.body.pudError} onChange={(test) => set("pku",test)}/>
            <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["aad"] || new Date()} label={"Final arrival date destination"} hasError={state.body.aadError} onChange={(test) => set("aad",test)} />
              <Input.Text readOnly={readOnly} type="number" value={state.body["lausd"] || 0} label={"Line Amount (Exclude Vat) (USD)"} onChange={(val) => set("lausd",val)} InputProps={{ inputProps: { min: 0 }}} />
            <Input.Text readOnly={readOnly} value={state.body["po"] || ""} label={"PO Number"} onChange={(val) => set("po",val)} />
                <>
                  <Input.Text readOnly={readOnly} value={state.body["invoice"] || ""} label={"Invoice"} type="file" onChange={setInvoice} multiple={ false } accept={ACCEPT_FILE_TYPES.join(',')} />
                </>
            </div>
          </>
          )}</Observer>,
        footer: <>
              <Observer>{() => {
                return <Observer>{() => <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box m={2}><ModalCancelButton /></Box>
                    { !readOnly && !hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSaveClick}>{"Save"}</Button></Box> }
                    { !readOnly && hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSealClick}>{"Seal"}</Button></Box> }
                  </div>
              </>}</Observer>;
          }}</Observer>
        </>,
        options: {
          fullWidth: false,
          smallWidth: true
        }
      });
      return
    }
    await onAsyncSave().then(() =>{
       globalPopModal()
      });
  };

  const set = (field,test) => {
    console.log(test);
    state.body[field] = test;
    console.log(state.body[field]);
    return (val) => {
    };
  };

  const setInvoice = (event) => {
    state.body["invoice"] = event.target.files[0];
  }

  const readOnly = shipment.body.shipmentContract.body.state === 'completed';
  const hasSeal = state.body["pku"] && state.body["aad"] && state.body["lausd"] && state.body["po"] && state.body["po"] !== "";
  
  globalPushModal({
    title: state.body.globalRecordNumber,
    body: <Observer>{() => (
      <>
        <div>
        <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["pku"] || new Date()} label={shipment.body.shipmentType=="export"?"Arrival date origin port / pick up date":"Final Arrival Date to Port"} hasError={state.body.pudError} onChange={(test) => set("pku",test)}/>
        <input.DatePicker  readOnly={readOnly} fullWidth minDate={minDate} disablePast={false} value={state.body["aad"] || new Date()} label={"Final arrival date destination"} hasError={state.body.aadError} onChange={(test) => set("aad",test)} />
          <Input.Text readOnly={readOnly} type="number" value={state.body["lausd"] || 0} label={"Line Amount (Exclude Vat) (USD)"} onChange={(val) => set("lausd",val)} InputProps={{ inputProps: { min: 0 }}} />
        <Input.Text readOnly={readOnly} value={state.body["po"] || ""} label={"PO Number"} onChange={(val) => set("po",val)} />
            <>
              <Input.Text readOnly={readOnly} value={state.body["invoice"] || ""} label={"Invoice"} type="file" onChange={setInvoice} multiple={ false } accept={ACCEPT_FILE_TYPES.join(',')} />
            </>
        </div>
      </>
      )}</Observer>,
    footer: <>
          <Observer>{() => {
            return <Observer>{() => <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box m={2}><ModalCancelButton /></Box>
                { !readOnly && !hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSaveClick}>{"Save"}</Button></Box> }
                { !readOnly && hasSeal && <Box m={2}><Button variant="contained" color="primary" onClick={handleSealClick}>{"Seal"}</Button></Box> }
              </div>
          </>}</Observer>;
      }}</Observer>
    </>,
    options: {
      fullWidth: false,
      smallWidth: true
    }
  });
};
// ====

export const globalPushNewShipmentModal = () => {
  globalPushShipmentModal({ value: { body: createBlankShipment() }, readOnly: false });
};

export const globalPushSelectForwardersModal = ({ onAccept, accept = 'Apply' }) => {
  const state = observable({
    items: [],
  });

  const onHide = () => globalPopModal();
  const onSave = async () => {
    if (onAccept) {
      onAccept(state.items.filter(value => value.body.options.forwarder.dynamic.ui.isSelected));
    }
  };
  const handleChange = (values) => {
    state.items = values;
  };

  globalPushModal({
    title: 'Choose Forwarders',
    body: <>
      <ForwardersForm maxCount={5} onChange={handleChange} />
    </>,
    footer: <Observer>{() => {
      const count = state.items.filter(value => value.body.options.forwarder.dynamic.ui.isSelected).length;

      return <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col className="pl-0 align-middle col-align-middle">
            <Box ml={2}>{count} items selected</Box>
          </Col>
          <Col size="auto" className="pr-0 text-right align-middle">
            <ModalButtons onAsyncSave={onSave} disabled={count <= 0} />
          </Col>
        </Row>
      </Container>
      </>;
    }}</Observer>,
  });
};

export const Action = (props) => {
  const { variant, mode = 'icon', color = 'primary', shadow = true, className = '', disabled = false, onClick, action, children, ...rest } = props;

  const buttonRef = React.useRef();

  const cssClass = ''; //className + ' button button-' + (disabled ? 'disabled' : (variant || 'normal'));

  const handleClick = (e) => {
    e.preventDefault();

    const label = action || buttonRef.current?.innerText || tooltip || null;

    if (label) {
      api.trackEvent({
        ...props,
        category: 'Action',
        action: 'Click',
        label: label,
      });
    }

    onClick(e);
  };

  const TOOLTIP_KEY = `common:actions.${action || 'default'}.tooltip`;
  const tooltip = i18n.exists(TOOLTIP_KEY) ? i18n.t(TOOLTIP_KEY) : null;

  const Wrapper = ({ children }) => {
    if (tooltip) {
      return <Tooltip title={tooltip}>
        <span>
          {children}
        </span>
      </Tooltip>;
    } else {
      return <>
        {children}
      </>;
    }
  };

  switch (mode) {
    case 'icon':
      return <Wrapper><IconButton
        size="small"
        color="primary"
        disabled={disabled}
        className={cssClass}
        onClick={handleClick}
        style={{ filter: !disabled && shadow && 'drop-shadow( 1px 1px 2px rgba(0, 0, 0, 0.4)' }}
        ref={buttonRef}
      >
        <Box color={color}>
          {children}
        </Box>
      </IconButton></Wrapper>;
    case 'button':
      return <Wrapper><Button size="small" color={color} variant="contained" disabled={disabled} className={cssClass} onClick={handleClick} {...rest} ref={buttonRef}>{children}</Button></Wrapper>;
    case 'link':
      return <Wrapper><Button style={{ minWidth: 'auto' }} size="small" color={color} variant="text" disabled={disabled} className={cssClass} onClick={handleClick} {...rest} ref={buttonRef}>{children}</Button></Wrapper>;
    case 'text':
    default:
      return <Wrapper><Link component="button" variant="body2" color={color} disabled={disabled} className={cssClass} onClick={handleClick} {...rest} ref={buttonRef}>{children}</Link></Wrapper>;
  }
};

export const Cell = ({ label, children }) => {
  return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div>{label}</div>
    <div>
      {children}
    </div>
  </div>
};

export const CreateNewShipment = ({ ...rest }) => {
  const handleClick = () => {
    globalPushNewShipmentModal();
  };

  return <Action mode="button" action="createNewShipment" color="secondary" size="medium" onClick={handleClick} {...rest}>
    {i18n.t('common:navControls.newShipment.label')}
  </Action>;
};

export const UpdateShipmentState = ({ value, state, ...rest }) => {
  const { t } = useTranslation();

  const onClick = () => {
    const shipmentId = value.body._id;

    if (state === 'rfp') {
      const result = api.updateShipmentStateById(shipmentId, state, {
        sharedWith: {
          orgUnitIds: [],
        }
      });

      api.reload();

      return result;
    } else {
      api.confirm('updateShipmentStateById', null, confirmed => {
        if (confirmed) {
          api.updateShipmentStateById(shipmentId, state, {}).then(shipment => {
            if (!shipment.success) return;
          
            api.navigateShipmentState(shipment.data.body.state);
          });
        }
      });
    }
  };

  let variant = 'warning';
  let text = <>{state}</>;
  let show = false;
  let action = null;
  let mode = 'icon';
  let icon = null;

  switch (state) {
    case 'rfp':
      show = value.body.state === 'draft';
      variant = 'normal';
      icon = <MdSubmit />;
      text = <>{t('common:actions.submitRfp.label')}</>;
      mode = 'button';
      action = 'submitRfp';
      break;

    case 'cancelled':
      if (value.body.state === 'rfp') {
        show = true;
        variant = 'normal';
        action = "cancel";
      }
      text = <MdDelete />;
      mode = 'icon';
      break;
  }

  return <Action startIcon={icon} mode={mode} action={action} onClick={onClick} variant={variant} disabled={!show} {...rest}>{text}</Action>;
};

export const DeleteShipmentDraft = ({ value, ...rest }) => {
  const onClick = () => {
    const shipmentId = value.body._id;

    api.confirm('deleteShipmentById', null, confirmed => {
      if (confirmed) {
        api.deleteShipmentById(shipmentId).then(response => {
          if (!response.success) return;
          
          api.navigateShipmentState(value.body.state);
        });
      }
    });
  };

  let variant = 'warning';
  let text = <MdDelete />;
  let show = false;
  let action = null;

  switch (value.body.state) {
    case 'draft':
      show = value.body.state === 'draft';
      variant = 'link';
      text = <MdDelete />;
      action = "delete";
      break;
  }

  return <Action action={action} onClick={onClick} variant={variant} disabled={!show} {...rest}>{text}</Action>;
};

export const CreateShipmentCopy = ({ value, editMode = false, ...rest }) => {
  const onClick = () => {
    const shipmentId = value.body._id;

    const name = window.prompt(`Please enter duplicate shipment's name:`, `Copy of ${value.body.name}`);

    if (!name) return;

    api.createShipmentCopyById(shipmentId, name).then(shipment => {
      if (!shipment.success) return;

      api.navigateShipmentState(shipment.data.body.state);
    });
  };

  const text = <MdDuplicate />

  return <Action action="duplicate" onClick={onClick} variant="link" {...rest}>{text}</Action>
};

export const ShipmentDetails = ({ value, showLabel = false, readOnly: forceReadOnly = false, onChange = () => {}, children, ...rest }) => {
  const { t } = useTranslation();

  const readOnly = value.body.readOnly || forceReadOnly || value.body.state !== 'draft';

  const onClick = () => {
    globalPushShipmentModal({ value: value, readOnly: readOnly });
  };

  let text = (typeof children === 'undefined')
    ? (readOnly
      ? <><MdDetails />{ showLabel && <Box ml={2}>{t('common:actions.view.label')}</Box> }</>
      : <><MdEdit />{ showLabel && <Box ml={2}>{t('common:actions.edit.label')}</Box> }</>
    ) : children;

  return <Action mode={!children ? 'icon' : 'text'} action={readOnly ? 'view' : 'edit'} onClick={onClick} {...rest}>{text}</Action>
};

export const CreateShipmentBid = ({ shipment, ...rest }) => {
  const { t } = useTranslation();

  const onClick = async () => {
    const contract = {
      body: {
        shipmentId: shipment.body._id,
        shipment: shipment,
        prices: {},
        state: 'draft',
        enabled: true,
      },
      validation: null
    };

    globalPushShipmentContractModal({ value: contract });

    //const response = await api.createShipmentContract(contract);

    //if (response.success) {
      //globalPushShipmentContractModal({ value: response.data });

      //api.navigateShipmentContractState(response.data.body.state);
    //}
  };

  const text = <>
    {t('common:actions.createBid.label')}
  </>;

  if (!shipment.body.orgUnitShipmentContractsCount) {
    return <Action
      mode="button"
      action="createBid"
      onClick={onClick}
      variant="normal"
      startIcon={<MdAcceptBidRequest />}
      {...rest}
    >
      {text}
    </Action>;
  } else {
    return <></>;
  }
};

export const ArchiveShipmentBidRequest = ({ shipment, ...rest }) => {
  const { t } = useTranslation();

  const onClick = async () => {
    api.confirm('archiveShipmentBidRequest', {}, async (result) => {
      if (!result) return;

      const contract = {
        shipmentId: shipment.body._id,
        state: 'archived',
      };

      const response = await api.createShipmentContract(contract);

      if (response.success) {
        // Reload current page
        api.reload();
      }
    });
  };

  if (!shipment.body.orgUnitShipmentContractsCount) {
    return <Action action="archiveBid" onClick={onClick} {...rest}><MdDelete /></Action>;
  } else {
    return <></>;
  }
};

export const DeleteContractDraft = ({ value, ...rest }) => {
  const onClick = () => {
    const contractId = value.body._id;

    api.confirm('deleteShipmentContractById', null, confirmed => {
      if (confirmed) {
        api.deleteShipmentContractById(contractId).then(response => {
          if (!response.success) return;
          
          api.navigateShipmentContractState(value.body.state);
        });
      }
    });
  };

  let variant = 'normal';
  let text = <MdDelete />;
  let action = "delete";
  let show = value.body.state === 'draft';

  return <Action mode="icon" action={action} onClick={onClick} variant={variant} disabled={!show} {...rest}>{text}</Action>;
};

const confirmUpdateShipmentContractState = ({ value, state }) => {
  return new Promise((resolve, reject) => {
    const contractId = value.body._id;

    const confirmKey = state !== 'bid' ? 'updateShipmentContractStateById' : 'updateShipmentContractStateById_submitBid';

    const confirmOptions = {
    };

    if (state === 'bid') {
      confirmOptions.action = 'submitBid';
    }

    api.confirm(confirmKey, confirmOptions, confirmed => {
      resolve(confirmed);
    });
  });
};

export const UpdateShipmentContractState = ({ value, state, ...rest }) => {
  const { t } = useTranslation();

  const onClickStateChange = async () => {

    const confirmed = await confirmUpdateShipmentContractState({ value: value, state: state });

    if (confirmed) {
      const response = await api.updateShipmentContractStateById(value.body._id, state);

      if (!response.success) return;
        
      api.navigateShipmentContractState(response.data.body.state);
    }
  };

  const onClickCompleteContract = () => {
    const uiState = observable({
      invoice: null,
    });

    const onAsyncSave = async () => {
      const contractId = value.body._id;

      const response = await api.uploadShipmentContractInvoiceById(contractId, uiState.invoice);

      if (response.success) {
        api.navigateShipmentContractState(response.data.body.state);
      }
    };

    const handleChange = (val) => {
      uiState.invoice = val.invoice;
    };

    globalPushModal({
      title: i18n.t('common:forms.completeShipmentContract.title'),
      body: <>
        <CompleteShipmentContractForm onChange={handleChange} />
      </>,
      footer: <Observer>{() => <>
        <ModalButtons onAsyncSave={onAsyncSave} disabled={!uiState.invoice} />
      </>}</Observer>,
      options: {
        fullWidth: false,
      },
    });
  };

  let variant = 'warning';
  let text = <>{state}</>;
  let icon = null;
  let show = false;
  let action = null;
  let mode = 'icon';
  let handleClickEvent = onClickStateChange;

  switch (state) {
    case 'bid':
      show = value.body.state === 'draft';
      variant = 'normal';
      icon = <MdSubmit />;
      text = <>{t('common:actions.submitBid.label')}</>;
      action = "submitBid";
      mode = 'button';
      break;

    case 'archived':
      if (value.body.state !== 'draft') {
        show = false; // you can only archive a draft
        variant = 'normal';
        text = <MdDelete />;
        action = "cancel";
        mode = 'icon';
      }
      break;

    case 'completed':
      if ([ 'contract', 'dispute' ].includes(value.body.state)) {
        show = true;
        variant = 'normal';
        action = "completeContract";
        icon = <MdSubmit />;

        if (value.body.state === 'dispute') {
          text = <>{t('common:actions.completeDisputedContract.label')}</>;
        } else {
          text = <>{t('common:actions.completeContract.label')}</>;
        }
        mode = 'button';
        handleClickEvent = onClickCompleteContract;
      }
      break;
  }

  return <Action startIcon={icon} mode={mode} action={action} onClick={handleClickEvent} variant={variant} disabled={!show} {...rest}>{text}</Action>;
};

export const ShipmentContractDetails = ({ value, readOnly: forceReadOnly = false, showLabel = false, shipment = null, children, ...rest }) => {
  const { t } = useTranslation();

  value.body.shipment = shipment || value.body.shipment;

  const readOnly = forceReadOnly || value.body.readOnly;

  const handleShowDetails = () => {
    globalPushShipmentContractModal({ value: value, readOnly: readOnly });
  };

  let text = (typeof children === 'undefined')
    ? (readOnly
      ? <><MdDetails />{ showLabel && <Box ml={2}>{t('common:actions.view.label')}</Box> }</>
      : <><MdEdit />{ showLabel && <Box ml={2}>{t('common:actions.edit.label')}</Box> }</>
    ) : children;

  return <Action mode={!children ? 'icon' : 'text'} action={readOnly ? 'view' : 'edit'} onClick={handleShowDetails} {...rest}>{text}</Action>
};

const ProposeForwarder = ({ onClick }) => {
  return <>
    <Action mode="text" variant="link" action="proposeForwarder" onClick={onClick}>
      {i18n.t('common:actions.proposeForwarder.label')}
    </Action>
  </>;
};

const ProposeForwarderContact = ({ forwarder, onClick }) => {
  return <>
    <Action mode="text" variant="link" action="proposeContact" onClick={onClick}>
      {i18n.t('common:actions.proposeContact.label')}
    </Action>
  </>;
};

const ShowValidationSummary = ({ value, schema, startButtons = null, ...rest }) => {
  const handleClick = () => {
    globalPushValidationSummary({
      value: value,
      schema: schema,
      startButtons: startButtons,
      ...rest,
    });
  };

  return <>
    <Action action="viewValidationSummary" {...rest} onClick={handleClick} className="error">
      <MdError />
    </Action>
  </>;
};

const ShipmentTracking = ({ value, lead = 'shipmentContract', shipmentContract, shipment, readOnly: forceReadOnly = false, mode = 'action', ...rest }) => {
  const { t } = useTranslation();

  let shipmentTracking = toJS(value);

  const readOnly = forceReadOnly; // || value.body.state !== 'contract';
  const numItems =
    (shipmentTracking && shipmentTracking.body && shipmentTracking.body.length) || 0;

  const isEdit = !readOnly && numItems > 0;

  const action = mode !== 'action' ? 'viewShipmentTracking' : (isEdit ? 'editShipmentTracking' : 'createShipmentTracking');
  const label = t(`common:actions.${action}.label`, { count: numItems });

  const title = i18n.t('common:title.shipmentTracking.view');

  const handleClick = () => {
    const onSave = async () => {
      const response =
          await api.updateShipmentTrackingByShipmentContractId(
            shipmentContract.body._id, shipmentTracking.body);

      if (response.success) {
        api.reload();
      }
    };

    const onChange = (val) => {
      shipmentTracking = val;
    };

    globalPushModal({
      title: title,
      body: !readOnly
        ? <ShipmentContractTrackingForm lead={lead} shipmentTracking={shipmentTracking} shipmentContract={shipmentContract} shipment={shipment} onChange={onChange} />
        : <ShipmentTrackingView shipmentTracking={shipmentTracking} shipmentContract={shipmentContract} shipment={shipment} />,
      footer: !readOnly ? <ModalButtons onAsyncSave={onSave} /> : <ModalButtons />,
    });
  };

  if (mode !== 'action' && numItems === 0) {
    return <></>;
  } else {
    return <Action mode="button" action={action} {...rest} onClick={handleClick} startIcon={<MdTrackingIcon />}>
      {label}
      {' '}
      <Badge
        color="secondary"
        showZero={false}
        variant="standard"
        badgeContent={numItems}
        className={css({
          '& > span': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            transform: 'none',
            marginLeft: '1em',
          },
        })}
      />
    </Action>;
  }
};

const CloseShipment = ({ value, children, ...rest }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    const uiState = observable({
      value: null,
      valid: false,
    });

    const handleSave = async () => {
      const response = await api.updateShipmentStateById(
        value.body._id,
        'closed',
        { rating: uiState.value }
      );

      if (response.success) {
        api.navigateShipmentState('completed');
      } else {
        throw new Error('');
      }
    };

    const handleChange = (val) => {
      uiState.value = val;

      if (!uiState.value ||
        !uiState.value.availabilityScore ||
        !uiState.value.professionalismScore ||
        !uiState.value.punctualityScore) {
        uiState.valid = false;
      } else {
        uiState.valid = true;
      }
    };

    globalPushModal({
      title: i18n.t('common:rating.forwarder.form.title'),
      body: <>
        <ForwarderFeedbackForm onChange={handleChange} />
      </>,
      footer: <Observer>{() => {
        return <ModalButtons onAsyncSave={handleSave} action="closeShipmentButton" disabled={!uiState.valid} />;
      }}</Observer>,
      options: {
        fullWidth: false,
        maxWidth: 'md',
      }
    });
  };

  if (children) {
    return <>
      <Action mode="button" color="primary" action="closeShipment" onClick={handleClick} {...rest}>
        { children }
      </Action>
    </>;
  } else {
    return <>
      <Action mode="button" color="primary" action="closeShipment" onClick={handleClick} {...rest} startIcon={<MdShipmentClosed />}>
        {t('common:actions.closeShipment.label')}
      </Action>
    </>;
  }
};

const DisputeShipment = ({ value, children, ...rest }) => {
  const { t } = useTranslation();

  const handleClick = async () => {
    api.confirm('disputeShipment', null, async (confirmed) => {
      console.log('confirmed', confirmed);
      if (confirmed) {
        const response = await api.updateShipmentStateById(value.body._id, 'dispute', {});

        if (response.success) {
          await api.navigateShipmentState('dispute');
        }
      }
    });
  };

  if (children) {
    return <>
      <Action mode="button" action="disputeShipment" onClick={handleClick} {...rest}>
        { children }
      </Action>
    </>;
  } else {
    return <>
      <Action mode="button" action="disputeShipment" onClick={handleClick} startIcon={<MdDispute />} {...rest}>
        {t('common:actions.disputeShipment.label')}
      </Action>
    </>;
  }
};

const UserOrgUnitsDetails = ({ value, ...rest }) => {
  const { t } = useTranslation();

  return <>
    <Action mode="text" action="edit" onClick={() => globalPushUserFormModal(value)}>
      {value.body.orgUnits.length}
    </Action>
  </>;
};

const ActionButton = ({ action, loading, disabled, onClick, ...rest }) => {
  const { t } = useTranslation();

  return <Button disabled={loading || disabled || !onClick} onClick={onClick} {...rest}>
    { loading ? <format.Loading style={{ width: '1em', height: '1em' }} /> : t(`common:actions.${action}.label`)}
  </Button>;
};

// affirmation may be /true/ or a string
const ModalSaveButton = ({ disabled, onAsyncSave, action = 'save', affirmation = false, ...rest }) => {
  const [ loading, setLoading ] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    setLoading(true);

    onAsyncSave().then(() => {
      globalPopModal();

      if (affirmation) {
        const msg = affirmation === true ? i18n.t(`common:actions.${action}.affirmation`) : affirmation;

        enqueueSnackbar(msg, { variant: 'success' });
      }
    }).catch(err => {
      console.error('SaveButton caught an error: ', err);

      setLoading(false);
    });
  };

  return <ActionButton
    disabled={disabled}
    loading={loading}
    action={action || 'save'}
    onClick={handleClick}
    variant="contained"
    color="primary"
    {...rest}
  />;
};

const ModalCancelButton = ({ ...rest }) => {
  return <ActionButton
    variant="text"
    color="secondary"
    action="cancel"
    onClick={globalPopModal}
    {...rest}
  />;
};

const ModalDismissButton = ({ ...rest }) => {
  return <ActionButton
    action="dismiss"
    onClick={globalPopModal}
    variant="text"
    {...rest}
  />;
};

const ModalExportButton = ({ onClick, ...rest }) => {
  return <ActionButton
    action="export"
    onClick={onClick}
    variant="contained"
    color="default"
    startIcon={<img src={MdExcel} border={0} className="icon" />}
    {...rest}
  />;
};

const ModalButtons = ({ disabled, showNext = false, mode = 'normal', action = 'save', powerAction = null, onAsyncSave, onAsyncPowerSave, onExport, onPrevStep, onNextStep, affirmation = false, powerAffirmation = false, powerColor = 'primary' }) => {
  const MARGIN = 2;

  return <Observer>{() => <>
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      { (onAsyncSave || onAsyncPowerSave) ? <>
        <Box m={MARGIN}><ModalCancelButton /></Box>
        { mode === 'wizard' ? <>
          <Box m={MARGIN}><ActionButton action="prevStep" onClick={onPrevStep} /></Box>
          { (disabled || showNext) && <Box m={MARGIN}><ActionButton action="nextStep" onClick={onNextStep} /></Box> }
          { !disabled && onAsyncSave && <Box m={MARGIN}><ModalSaveButton disabled={disabled} onAsyncSave={onAsyncSave} action={action} affirmation={affirmation} /></Box> }
          { !disabled && onAsyncPowerSave && <Box m={MARGIN}><ModalSaveButton disabled={disabled} onAsyncSave={onAsyncPowerSave} action={powerAction || action} affirmation={powerAffirmation || affirmation} color={powerColor} /></Box> }
        </> : <>
          { onAsyncSave && <Box m={MARGIN}><ModalSaveButton disabled={disabled} onAsyncSave={onAsyncSave} action={action} affirmation={affirmation} /></Box> }
          { onAsyncPowerSave && <Box m={MARGIN}><ModalSaveButton disabled={disabled} onAsyncSave={onAsyncPowerSave} action={powerAction || action} affirmation={powerAffirmation || affirmation} color={powerColor} /></Box> }
        </> }
      </> : <>
        { onExport && <Box m={MARGIN}><ModalExportButton onClick={onExport} /></Box> }
        <Box m={MARGIN}><ModalDismissButton /></Box>
      </> }
    </div>
  </>}</Observer>;
};

const AsyncSwitch = ({ value, disabled, label = '', onAsyncChange }) => {
  const [ id ] = React.useState(uuid());
  const [ loading, setLoading ] = React.useState(false);

  const readOnly = disabled || !onAsyncChange;

  const handleChange = (val) => {
    setLoading(true);

    onAsyncChange(val)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  return <>
    { loading ? <format.Loading /> : <>
      <input.Check readOnly={readOnly} value={value} onChange={handleChange} formGroup={false} />
    </> }
  </>;
};

const AsyncText = ({ value, disabled, onAsyncChange }) => {
  const [ edit, setEdit ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);

  let text = value;

  const beginEdit = () => {
    text = value;
    setEdit(true);
  };

  const cancelEdit = () => {
    text = value;
    setEdit(false);
  };

  const handleTextChange = e => {
    text = e.target.value;
  };

  const handleChange = () => {
    setLoading(true);

    onAsyncChange(text)
      .then(() => { setLoading(false); setEdit(false); })
      .catch(() => setLoading(false));
  };

  const keyDown = e => {
    if (e.keyCode === 27) cancelEdit();
    if (e.keyCode === 13) handleChange();
  }

  if (!edit) {
    return <Button variant="text" onClick={beginEdit} style={{ textTransform: 'none' }}>
      {value}
    </Button>;
  } else {
    return <>
      <OutlinedInput
        autoFocus
        onChange={handleTextChange}
        onKeyDown={keyDown}
        fullWidth
        defaultValue={value}
        disabled={loading || disabled}
        endAdornment={
          <InputAdornment position="start">
            { loading ? <format.Loading /> : <>
              <IconButton size="small" onClick={handleChange}><MdSave /></IconButton>
              <IconButton size="small" onClick={cancelEdit}><MdCancel /></IconButton>
            </> }
          </InputAdornment>
        }
      />
    </>;
  }
};

const AsyncStaticSelect = ({ field, label, value, values, readOnly = false, showKeys = true, formGroup = true, onAsyncChange, ...rest }) => {
  const [ state, setState ] = React.useState(value);
  const [ loading, setLoading ] = React.useState(false);

  const onChange = (val) => {
    setLoading(true);

    onAsyncChange(val)
      .then(() => { setLoading(false); setState(val); })
      .catch(() => setLoading(false));
  };

  return <input.StaticSelect
    field={field}
    label={label}
    value={state}
    values={values}
    readOnly={readOnly || loading}
    showKeys={showKeys}
    formGroup={formGroup}
    onChange={onChange}
    {...rest}
  />;
};

const AsyncShipmentOperationalState = ({ value, readOnly = false, formGroup = true, onAsyncChange, ...rest }) => {
  const [ state, setState ] = React.useState(value);
  const [ loading, setLoading ] = React.useState(false);

  const onChange = (val) => {
    setLoading(true);

    onAsyncChange(val)
      .then(() => { setLoading(false); setState(val); })
      .catch(() => setLoading(false));
  };

  return <input.ShipmentOperationalState
    value={value}
    readOnly={readOnly}
    formGroup={formGroup}
    onChange={onChange}
    {...rest}
  />;
};

const GlobalUserOrganization = ({ ...rest }) => { 	
  const searchFieldRef = React.useRef(null); 	
 	
  const uiState = observable({ 	
    search: '', 	
    loading: 0, 	
    orgUnits: [], 	
  }); 	
 	
  React.useEffect(() => { 	
    refresh(); 	
  }) 	
 	
  const refresh = api.throttle(async () => { 	
    const filter = { 	
      enabled: true, 	
    }; 	
 	
    if (uiState.search !== '') { 	
      filter.name = uiState.search; 	
    } 	
 	
    ++uiState.loading; 	
    const response = await api.queryOrgUnits({ filter: filter, silent: true, sort: { name: 'asc' } }); 	
    --uiState.loading; 	
 	
    if (response.success) { 	
      uiState.orgUnits = response.data; 	
    } 	
  }, 500); 	
 	
  const handleSearchChange = async (val) => { 	
    uiState.search = val; 	
 	
    await refresh(); 	
  }; 	
 	
  const handleOrgUnitChange = async (orgUnitId) => { 	
    const response = await api.setOrgUnitById(orgUnitId); 	
 	
    if (response.success) { 	
      api.forcePageReload(); 	
    } 	
  }; 	
 	
  return <Observer>{() => <> 	
    <ui.NavPopover 	
      {...rest} 	
      label={<> 	
        <Box pr={1}> 	
          <MdCompany /> 	
        </Box> 	
        {' '} 	
        {global.user.orgUnit.name} 	
        {' '} 	
        <Box fontWeight="fontWeightLight" pl={1}> 	
          ( 	
            <format.CurrencySymbol value={global.user.orgUnit.currencyCode} /> 	
            {' '} 	
            {global.user.orgUnit.currencyCode} 	
          ) 	
        </Box> 	
      </>} 	
    > 	
      <div 	
      > 	
        <Box m={2}> 	
          <input.Search 	
            value={uiState.search} 	
            onChange={handleSearchChange} 	
            ref={searchFieldRef} 	
            autoFocus 	
            size="small" 	
          /> 	
        </Box> 	
        <Box m={2}> 	
          <Box 	
            maxHeight="50vh" 	
            pr={1} 	
            style={{ 	
              overflowY: 'auto', 	
            }} 	
          > 	
            { (uiState.loading > 0 && uiState.orgUnits == 0) && <format.Loading /> } 	
            { (uiState.loading <= 0 || uiState.orgUnits.length > 0) && <MenuList> 	
              {uiState.orgUnits.map(i => ( 	
                <MenuItem 	
                  key={i.body._id} 	
                  style={{ 	
                    display: 'flex', 	
                    width: '100%', 	
                    flexWrap: 'nowrap', 	
                    alignItems: 'stretch', 	
                    paddingLeft: 0, 	
                    paddingRight: 0, 	
                  }} 	
                  onClick={() => handleOrgUnitChange(i.body._id)} 	
                > 	
                  <Box 	
                    width="100%" 	
                    display="flex" 	
                    flexWrap="nowrap" 	
                    justifyContent="flex-start" 	
                    alignItems="center" 	
                    pl={1} 	
                    pr={1} 	
                  > 	
                    <Box align="left" justifySelf="stretch" width="100%"> 	
                      {i.body.name} 	
                    </Box> 	
                    <Box pl={1} fontWeight="fontWeightLight" fontSize="small" align="right" justifySelf="flex-end"> 	
                      <format.CurrencySymbol value={i.body.currencyCode} /> 	
                      {' '} 	
                      {i.body.currencyCode} 	
                    </Box> 	
                    <Box pl={1} fontWeight="fontWeightLight" justifySelf="flex-end"> 	
                      <format.OrgType value={i} /> 	
                    </Box> 	
                  </Box> 	
                </MenuItem> 	
              ))} 	
            </MenuList> } 	
          </Box> 	
        </Box> 	
      </div> 	
    </ui.NavPopover> 	
  </>}</Observer>; 	
};

const ShowShipmentContractChatSession = ({ value, children, mode = 'chat', actionMode = 'icon', startIcon = null }) => {
  const handleClick = React.useCallback(() => {
    value.body.chatSession.hasNewItems = false;

    globalPushModal({
      title: `${value.body.shipment.body.globalRecordNumber} - ${value.body.globalRecordNumber} - ${i18n.t('common:fields.common.ops')}`,
      body: <>
        <ShipmentOpCenter
          chatSessionId={value.body.chatSessionId}
          shipmentContractId={value.body._id}
          mode={mode}
        />
        {/* <ChatSession chatSessionId={value.body.chatSessionId} /> */}
      </>,
      footer: <></>,
      options: {
        fullWidth: true,
        fixedHeight: true,
      },
    });
  }, [ value, value.body.chatSessionId ]);

  if (!value || !value.body || !value.body.chatSession) return <></>;

  return <>
    { !children && <>
      <Action mode={actionMode} action="chat" onClick={handleClick} disabled={!value.body.chatSessionId}>
        <format.ChatState hasNewItems={value.body.chatSession.hasNewItems} />
      </Action>
      <Action mode={actionMode} action="chat" onClick={handleClick} disabled={!value.body.chatSessionId}>
        <MdTrackingIcon />
      </Action>
    </> }
    { children && <>
      <Action mode={actionMode} action="chat" onClick={handleClick} disabled={!value.body.chatSessionId} startIcon={startIcon}>
        { children }
      </Action>
    </> }
  </>;
};

const ShowShipmentChatSession = ({ value, children, action = 'chat', mode = 'chat', actionMode = 'icon', startIcon = null }) => {
  const handleClick = React.useCallback(() => {
    value.body.shipmentContract.body.chatSession.hasNewItems = false;

    globalPushModal({
      title: `${value.body.globalRecordNumber} - ${value.body.shipmentContract.body.globalRecordNumber} - ${i18n.t('common:fields.common.ops')}`,
      body: <>
        <ShipmentOpCenter
          chatSessionId={value.body.shipmentContract.body.chatSessionId}
          shipmentId={value.body._id}
          shipmentContractId={value.body.shipmentContract.body._id}
          mode={mode}
        />
        {/* <ChatSession chatSessionId={value.body.shipmentContract.body.chatSessionId} /> */}
      </>,
      footer: <></>,
      options: {
        fullWidth: true,
        fixedHeight: true,
      },
    });
  }, [ value, value.body.shipmentContract.body.chatSessionId ]);

  if (!value) return <></>;
  if (!value.body.shipmentContract) return <></>;
  if (!value.body.shipmentContract.body) return <></>;

  return <>
    { !children && <>
      <Action mode={actionMode} action={action} onClick={handleClick} disabled={!value.body.shipmentContract.body.chatSessionId}>
        <format.ChatState hasNewItems={value.body.shipmentContract.body.chatSession.hasNewItems} />
      </Action>
      <Action mode={actionMode} action={action} onClick={handleClick} disabled={!value.body.shipmentContract.body.chatSessionId}>
        <MdTrackingIcon />
      </Action>
      <Action mode={actionMode} action={action} onClick={handleClick} disabled={!value.body.shipmentContract.body.chatSessionId}>
        <MdRadarIcon />
      </Action>
    </> }
    { children && <>
      <Action mode={actionMode} action={action} onClick={handleClick} disabled={!value.body.shipmentContract.body.chatSessionId} startIcon={startIcon}>
        { children }
      </Action>
    </> }
  </>;
};

const ViewAttachment = ({ content, attachmentId, ...rest }) => {
  const handleClick = async () => {
    if (content) {
      ui.globalPushAttachmentContentViewerModal(content);
    } else {
      const response = await api.queryAttachmentMetadataById(attachmentId);

      if (!response.success) return;

      const attachment = response.data;

      ui.globalPushAttachmentContentViewerModal(attachment.body.content);
    }
  };

  return <>
    <Action mode="icon" action="view" onClick={handleClick} {...rest}>
      <MdView />
    </Action>
  </>;
};

const DownloadAttachment = ({ content, attachmentId, label = null, ...rest }) => {
  const handleClick = async () => {
    if (content) {
      console.log({url:content.url});
      const newLink  = content.url.replace('/secure/files/\\', 'files/').replace('/secure/files//', 'files/').replace('/secure/files/', 'files/')
      console.log({newLink});
      await api.browserDownloadUrl(newLink);
    } else {
      await api.downloadAttachmentById(attachmentId);
    }
  };

  return <>
    <Action mode="button" action="download" onClick={handleClick} {...rest}>
      { label || i18n.t('common:actions.download.label') }
    </Action>
  </>;
};

const RatingItem = ({ value, ...rest }) => {
  const handleClick = React.useCallback(() => {
    globalPushModal({
      title: i18n.t('common:rating.forwarder.item.title'),
      body: <RatingItemView value={value} {...rest} />,
      footer: <ModalButtons />,
      options: {
        maxWidth: 'md',
        fullWidth: false,
      },
    });
  }, [ value ]);

  return <>
    <Action mode="text" action="rating" onClick={handleClick}>
      <format.Rank value={value.body.avgScore} />
    </Action>
  </>;
};

const OrgUnitRank = ({ orgUnit }) => {
  const handleClick = React.useCallback(() => {
    if (!orgUnit.body.summary.rating.avgScore) return;

    globalPushModal({
      title: i18n.t('common:rating.forwarder.list.title'),
      body: <OrgUnitFeedbackList orgUnitId={orgUnit.body._id} />,
      footer: <ModalButtons />,
      options: {
        maxWidth: 'md',
        fullWidth: false,
      },
    });
  }, [ orgUnit ]);

  return <>
    <Action mode="text" action="rating" onClick={handleClick}>
      <format.Rank value={orgUnit.body.summary.rating.avgScore} />
    </Action>
  </>;
};

export const AsyncMoreDataSentinel = ({ onAsyncLoad }) => {
  const ref = React.useRef();

  React.useEffect(() => {
    let busy = false;

    const callback = async (entries, observer) => {
      if (busy) return;

      busy = true;

      try {
        await onAsyncLoad();
      } catch (e) {
      }

      busy = false;
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '100px 0px 100px 0px', // top right bottom left
      threshold: 0.01,
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  });

  return <div ref={ref} style={{ width: '100%' }}>
  </div>;
};

export const OrgUnitPublicForwarderProfile = ({ orgUnit, ...rest }) => {
  const hasPublicProfile = !!orgUnit.body.options.forwarder.publicProfileEnabled && !!orgUnit.body.options.forwarder.publicProfile;

  if (!hasPublicProfile) return <></>;

  const handleClick = () => {
    globalPushPublicForwarderProfileModal({ readOnly: true, orgUnit: orgUnit });
  };

  return <Action action="publicForwarderProfile" mode="button" onClick={handleClick} color="secondary" {...rest}>
    {i18n.t('common:actions.publicForwarderProfile.label')}
  </Action>;
};

export default {
  Action,
  Cell,

  ShipmentDetails,
  CreateNewShipment,
  CreateShipmentCopy,
  UpdateShipmentState,
  DeleteShipmentDraft,
  CreateShipmentBid,
  ArchiveShipmentBidRequest,
  DeleteContractDraft,
  UpdateShipmentContractState,
  ShipmentContractDetails,
  ProposeForwarder,
  ProposeForwarderContact,
  ShowValidationSummary,
  ShipmentTracking,
  CloseShipment,
  DisputeShipment,
  UserOrgUnitsDetails,
  
  RatingItem,
  OrgUnitRank,
  OrgUnitPublicForwarderProfile,

  ActionButton,
  ModalSaveButton,
  ModalCancelButton,
  ModalDismissButton,
  ModalButtons,

  AsyncSwitch,
  AsyncText,
  AsyncStaticSelect,
  AsyncShipmentOperationalState,
  AsyncMoreDataSentinel,

  ShowShipmentContractChatSession,
  ShowShipmentChatSession,

  GlobalUserOrganization,

  DownloadAttachment,
  ViewAttachment,
};
