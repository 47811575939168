import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import i18n from 'i18next';
import Countdown from 'react-countdown';

import api from '../lib/api';

import {
  DirectionsBoat as transportModeSea,
  Flight as transportModeAir,
  LocalShipping as transportModeLand,

  ChatBubbleOutline as ChatReadIcon,
  Announcement as ChatUnreadIcon,

  Warning as WarningIcon,
  VerifiedUser as ValidIcon,
} from '@material-ui/icons';

import transportModeSeaBig from '../assets/transportMode/big/sea.svg';
import transportModeAirBig from '../assets/transportMode/big/air.svg';
import transportModeLandBig from '../assets/transportMode/big/land.svg';

import {
  Badge, CircularProgress, Paper, Box, Typography, Tooltip
} from '@material-ui/core';

import {
  Rating,
} from '@material-ui/lab';

import * as SVGLoaders from 'svg-loaders-react';

import { CompactAddressView } from './input/places';

import currencyCodesTable from './format/currency';
import { MdCheck } from 'react-icons/md';
import MdDispute from '@material-ui/icons/ReportProblem';
import MdPremiumFeature from '@material-ui/icons/Stars';
import MdDotFull from '@material-ui/icons/FiberManualRecord';
import MdDotOutlined from '@material-ui/icons/FiberManualRecordOutlined';

export const OrgType = ({ value }) => {
  const colors = {
    initiator: '#4BC53A',
    forwarder: '#B43AC5',
  };

  return <Box>
    {value.body.modules.includes('initiator') ? <>
      <Tooltip title={i18n.t('common:fields.orgUnit.modules.initiator')}>
        <MdDotFull className="tiny-icon" htmlColor={colors.initiator} />
      </Tooltip>
    </> : <><MdDotOutlined className="tiny-icon" style={{ opacity: 0.5 }} /></>}
    {value.body.modules.includes('forwarder') ? <>
      <Tooltip title={i18n.t('common:fields.orgUnit.modules.forwarder')}>
        <MdDotFull className="tiny-icon" htmlColor={colors.forwarder} />
      </Tooltip>
    </> : <><MdDotOutlined className="tiny-icon" style={{ opacity: 0.5 }} /></>}
  </Box>;
};

export const Address = ({ value, showIcon = true }) => {
  return <CompactAddressView value={value} showIcon={showIcon} />
};

export const ShortAddress = ({ value }) => {
  if (!value) return '';

  const list = [];
  list.push(value.city);
  if ((value.state || '') !== '') list.push(value.state);
  list.push(value.country);

  return list.filter(i => i && i !== '').join(', ');
};

export const ShipmentState = ({ value }) => {
  const text = (() => {
    if (!value) {
      return i18n.t('common:states.shipment.{any}');
    } else {
      return i18n.t(`common:states.shipment.${value}`);
    }
  })();

  return <>
    { value === 'dispute' && <Box display="inline" color="warning.main" mr={1}>
      <MdDispute />
    </Box> }
    {text}
  </>;
};

export const ShipmentContractOperationalState = ({ value }) => {
  const text = (() => {
    if (!value) {
      return i18n.t('common:states.shipmentOperationalStates.{any}');
    } else {
      return i18n.t(`common:states.shipmentOperationalStates.${value}`);
    }
  })();

  return <>{text}</>;
};

export const ShipmentContractState = ({ value }) => {
  const text = (() => {
    if (!value) {
      return i18n.t('common:states.shipmentContract.{any}');
    } else {
      return i18n.t(`common:states.shipmentContract.${value}`);
    }
  })();

  return <>
    { value === 'dispute' && <Box display="inline" color="warning.main" mr={1}>
      <MdDispute />
    </Box> }
    {text}
  </>;
}

export const InvoiceValidationState = ({ value }) => {
  const icon = (() => {
    switch (value) {
      case 'pending':
        return <Box mr={1}><Loading size="1em" /></Box>;

      case 'invalid':
        return <Box mr={1} color="warning.main"><WarningIcon className="pulse-fast" /></Box>

      case 'valid':
        return <Box mr={1} color="success.main"><ValidIcon /></Box>;

      default:
        return <></>;
    }
  })();

  const text = (() => {
    if (!value) {
      return i18n.t('common:states.invoiceValidation.{null}');
    } else {
      return <>
        <div style={{ display: 'inline-grid', gridTemplateColumns: 'max-content max-content', alignItems: 'center' }}>
          {/* {icon} */}
          <div style={{ whiteSpace: 'nowrap' }}>{i18n.t(`common:states.invoiceValidation.${value}`)}</div>
        </div>
      </>;
    }
  })();

  return <>{text}</>;
};

export const TransportMode = ({ value, mode = 'text' }) => {
  const text = i18n.t(`common:fieldValues.shipment.transportMode.${value}`);
  if (mode === 'text') {
    return text;
  } else {
    const map = {
      sea: transportModeSea,
      air: transportModeAir,
      land: transportModeLand,
    };

    const style = {};

    if (mode === 'icon-huge') {
      style.width = '20rem';
      style.height = '20rem';

      map.sea = transportModeSeaBig;
      map.air = transportModeAirBig;
      map.land = transportModeLandBig;

      return <>
        <img alt={text} title={text} style={style} src={map[value]} />
      </>;
    } else {
      const ModeIcon = map[value];

      return <>
        <ModeIcon alt={text} title={text} style={style} />
      </>;
    }
  }
}

export const ShipmentType = ({ value }) => {
  switch (value) {
    case 'import':
      return 'Import';
    case 'export':
      return 'Export';
    default:
      return value;
  }
};

export const TransportType = ({ value, explicit = false }) => {
  switch (value) {
    case 'direct':
    case 'transshipment':
      return i18n.t(`common:fieldValues.shipment.transportType.${value}`);
    default:
      if (explicit) {
        return i18n.t(`common:fieldValues.shipment.transportType.${value}@explicit`);
      } else {
        return i18n.t(`common:fieldValues.shipment.transportType.${value}`);
      }
  }
};

export const ShipmentProgress = ({ value }) => {
  return <>{value}</>;
}

export const ShipmentTrackingCode = ({ value }) => {
  return <>{value}</>;
}

export const FileSizeString = ({ bytes, si=false, dp=1 }) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
};

export const NumericString = ({ value, feedback }) => {
  if (Number.isFinite(Number(value))) return value.toString();
  
  if (feedback) {
    return <span className="error">???</span>
  } else {
    return '???';
  }
};

const BooleanString = ({ value }) => {
  return <>
    { value ? i18n.t('common:boolean.true') : i18n.t('common:boolean.false') }
  </>;
};

export const PositiveNumericString = ({ value, feedback }) => {
  if (Number.isFinite(Number(value)) && Number(value) >= 0) return value.toString();
  
  if (feedback) {
    return <span className="error">???</span>
  } else {
    return '???';
  }
};

export const Text = ({ value, feedback }) => {
  if (value && value.toString().length > 0) {
    return <>{value}</>;
  }
  
  if (feedback) {
    return <span className="error">???</span>
  } else {
    return '???';
  }
};

export const Label = ({ text, icon = null }) => {
  return <><div style={{ whiteSpace: 'nowrap '}}>
    { icon ? <>
      { typeof icon === 'string' ? <img src={icon} alt={text} style={{ height: '1.5em', width: '1.5em' }} /> : {icon} } 
    </> : <></> }
    {' '}
    {text}
  </div></>;
};

export const DateText = ({ value }) => {
  return <span className="nowrap">{DateString(value)}</span>;
};

export const DateString = (value) => {
  return moment(value).format('YYYY-MM-DD');
};

export const DateTimeText = ({ value }) => {
  return <span className="nowrap">{DateTimeString(value)}</span>;
};

export const DateTimeString = (value) => {
  return moment(value).format('YYYY-MM-DD HH:mm');
};

export const DateDistance = ({ value }) => {
  const [ state, setState ] = React.useState(null);

  const calc = () => {
    const today = api.today();
    const deltaSeconds = Math.floor((new Date(value).valueOf() - today.valueOf()) / 1000);
    const deltaMinutes = Math.floor(deltaSeconds / 60);
    const deltaHours = Math.floor(deltaMinutes / 60);
    const deltaDays = Math.floor(deltaHours / 24);

    let format = '';
    let interval = 1000;

    if (deltaDays > 1) {
      format = `${deltaDays} days`;
      interval = 1000 * 60 * 60;
    } else if (deltaDays > 0) {
      format = `${deltaDays} day`;
      interval = 1000 * 60 * 5;
    } else if (deltaHours > 1) {
      format = `${deltaHours} hours`;
      interval = 1000 * 60 * 15;
    } else if (deltaHours > 0) {
      format = `${deltaHours} hour`;
      interval = 1000 * 60 * 15;
    } else if (deltaMinutes > 1) {
      format = `${deltaMinutes} minutes`;
      interval = 1000 * 5;
    } else if (deltaMinutes > 0) {
      format = `${deltaMinutes} minute`;
      interval = 1000 * 1;
    } else {
      format = 'a few seconds'
    }

    if (deltaSeconds < 0) {
      // Past
      format = `${format} ago`;
    } else if (deltaSeconds > 0) {
      // Future
      format = `in ${format}`;
    } else {
      format = 'now';
    }

    setState(format);
  };

  if (!state) {
    calc();
  }

  return <>
    {state}
  </>
};

const ShippingTerms = ({ value }) => {
  return i18n.t(`common:fieldValues.shipment.shippingTerms.${value}`);
};

const Incoterms = ({ value }) => {
  return value;
};

const Money = ({ value, showCurrencySymbol = true, showCurrencyCode = true, format = '0,0.00' }) => {
  if (!value) return <>???</>;

  return <>
    { showCurrencySymbol && <>
      <CurrencySymbol value={value.currencyCode} />
      {' '}
    </> }
    {numeral(value.value).format(format)}
    { showCurrencyCode && <>
      {' '}
      {value.currencyCode}
    </> }
  </>;
};

const CurrencySymbol = ({ value }) => {
  const entry = currencyCodesTable[value];

  return <>
    {entry.symbol_native}
  </>;
};

const UserListItem = ({ value }) => {
  return <Box mb={2}>
    <Paper variant="outlined">
      <Box p={2}>
        <div><strong>{value.body.name}</strong></div>
        <div>{value.body.email}</div>
        <div>{value.body.phone}</div>
      </Box>
    </Paper>
  </Box>;
};

export const Loading = ({ ...rest }) => {
  return <>
    <CircularProgress {...rest} />
  </>;
};

export const ChatState = ({ hasNewItems }) => {
  if (hasNewItems) {
    return <Badge color="secondary" variant="dot">
      <ChatUnreadIcon className="pulse bulge" />
    </Badge>;
  } else {
    return <ChatReadIcon />
  }
};

export const Deadline = ({ value, mode = 'countdown', onComplete = () => {} }) => {
  if (mode === 'date') {
    if (new Date(value) > api.now()) {
      return <DateText value={value} />;
    } else {
      return i18n.t('common:states.deadline.expired');
    }
  };

  const render = ({ total, days, hours, minutes, seconds, completed }) => {
    if (completed) return i18n.t('common:states.deadline.expired');

    const list = [];

    if (days > 0) list.push(`${days}d`);
    if (hours > 0) list.push(`${hours}h ${minutes}m`);

    if (list.length === 0) {
      if (minutes > 0) list.push(`${minutes}m`);
    }

    if (list.length === 0) {
      if (seconds > 0) list.push(`${seconds}s`);
    }

    return list.join(', ');
  };

  return <>
    <Countdown
      date={new Date(value)}
      onComplete={onComplete}
      renderer={render}
    />
  </>;
};

export const Rank = ({ value }) => {
  if (!value) return <></>;

  return <Rating value={value} readOnly={true} precision={0.5} />;
};

export const getCurrencySymbolString = (value) => {
  const entry = currencyCodesTable[value];

  return entry.symbol_native;
};

export const PremiumFeatureIcon = ({ ...rest }) => {
  return <Box display="inline" color="orange" {...rest}><MdPremiumFeature /></Box>;
};

export const HelpText = ({ value }) => {
  return <>
    <Typography variant="caption">
      {value}
    </Typography>
  </>;
};

export const PlainText = ({ value }) => {
  return <div>
    {value.split('\n').map(i => <div>{i !== '' ? i : <br />}</div>)}
  </div>;
};

export default {
  OrgType,
  Address,
  ShortAddress,
  TransportMode,
  TransportType,
  ShipmentType,
  ShippingTerms,
  Incoterms,
  ShipmentProgress,
  ShipmentTrackingCode,
  ShipmentState,
  ShipmentContractState,
  ShipmentContractOperationalState,
  InvoiceValidationState,
  FileSizeString,
  NumericString,
  BooleanString,
  PositiveNumericString,
  DateDistance,
  DateText,
  DateString,
  DateTimeText,
  DateTimeString,
  Label,
  Money,
  CurrencySymbol,
  Text,
  UserListItem,
  Loading,
  ChatState,
  Deadline,
  Rank,
  PremiumFeatureIcon,
  HelpText,
  PlainText,
};
