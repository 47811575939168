import React, { useState } from 'react';
import i18n from 'i18next';
import StepWizard from 'react-step-wizard';
import { toJS } from 'mobx';
import { css } from 'emotion';
import { useTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import input from './input';
import format from './format';

import api from './../lib/api';
import val from './validation';

import transportModeSea from '../assets/transportMode/big/sea.svg';
import transportModeAir from '../assets/transportMode/big/air.svg';
import transportModeLand from '../assets/transportMode/big/land.svg';

import {
  Grid, Stepper, Step, StepLabel, Paper, Box, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocationModal } from './LocationModal';

export const createBlankShipment = () => {
  const addr = () => {
    return {
      country: '',
      city: '',
      street: '',
      postalCode: '',
      port: '',
      comments: '',
    };
  };

  return {
    enabled: true,
    readOnly: false,
    state: 'draft',
    name: '',
    expiresAt: api.future().days(14),
    pickupFrom: addr(),
    deliverTo: addr(),
    incoterms: 'CIF',
    shippingTerms: 'D2D',
    transportMode: 'air',
    shipmentContent: [],
    shipmentType: 'export',
    shipmentProgress: null,
    shipmentTrackingCode: null,
    invoice: null,
    packingList: null,
    attachments: [],
    //transportType: 'any',
    insurance: true,
    customsClearance: true,
  };
};

const getSafeShipment = (shipment) => {
  return shipment || createBlankShipment();
};

const Main = ({ value = null, readOnly: forceReadOnly = false, mode = 'edit', onChange, onWizardInit, onWizardStepChange }) => {
  const { t } = useTranslation();

  const isWizard = mode !== 'edit';
  let wizard = null;

  // Create a deep copy of the data so we do not update parent
  // down the road this will have to be replaced by a more efficient mechanism
  const dataItem = toJS(value) || { body: getSafeShipment(null), validation: {} };
  const readOnly = dataItem.body.readOnly || !dataItem.body.enabled || forceReadOnly;

  const [ data ] = React.useState(getSafeShipment(dataItem.body));
  const [ validation ] = React.useState({ ...dataItem.validation });
  const [ changeCount, setChangeCount ] = React.useState(dataItem.body.transportMode);
  const [ wizardStepIndex, setWizardStepIndex ] = React.useState(1);

  console.log('validation: ', validation);
  const wizardContainerRef = React.useRef();

  const pack = (data, validation) => {
    return {
      body: data,
      validation: validation,
    };
  };

  const calcAddrType = (termsLetter) => {
    if (termsLetter === 'D') return null;

    switch (data.transportMode) {
      case 'air': return 'airport';

      case 'sea': return 'seaport';

      case 'land':
      default:
        return null; // anything goes
    }
  };

  const change = () => {
    setChangeCount(changeCount + 1);
  };

  const set = (field) => {
    return (val) => {
      const prevVal = data[field];

      data[field] = val;

      if ( field === 'shippingTerms' ) {
        const originAddrType = calcAddrType(data.shippingTerms.substr(0, 1));
        const destAddrType = calcAddrType(data.shippingTerms.substr(2, 1));

        if (data.pickupFrom && data.pickupFrom.type !== (originAddrType || data.pickupFrom.type)) {
          data.pickupFrom = null;
        }

        if (data.deliverTo && data.deliverTo.type !== (destAddrType || data.deliverTo.type)) {
          data.deliverTo = null;
        }
      }

      if ([ 'shipmentType', 'shippingTerms', 'transportMode', 'incoterms', 'insurance', 'customsClearance' ].includes(field)) {
        change();
      }

      if (onChange) onChange(pack(data));
    };
  };

  const Page1 = ({ nextStep, isActive, isWizard = true }) => {
    if (!isActive) return <></>;

    const fireChange = set('transportMode');

    const handleChange = val => {
      fireChange(val);

      if (nextStep) {
        nextStep();
      }
    };

    if (!isWizard) return <></>;

    return <>
      <Box mb={isWizard ? 0 : 2}>
        <input.TransportMode
          readOnly={readOnly || !isWizard}
          value={data.transportMode}
          label={t('common:fields.shipment.transportMode')}
          onChange={handleChange}
          field="transportMode"
          mode={isWizard ? 'wizard' : 'form'}
        />
      </Box>
    </>;
  };

  const Page2 = ({ isActive }) => {
    if (!isActive) return <></>;

    const [locationModalTrigger,setLocationModalTrigger] = useState(false)
    const originAddrType = calcAddrType(data.shippingTerms.substr(0, 1));
    const destAddrType = calcAddrType(data.shippingTerms.substr(2, 1));

    return <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <input.Text readOnly={readOnly} value={data.name} label={t('common:fields.shipment.name')} onChange={set('name')} field="name" />
        </Grid>
        <Grid item xs={4}>
          <input.DatePicker fullWidth readOnly={readOnly} value={data.pickupReadyDate} label={t('common:fields.shipment.pickupReadyDate')} onChange={set('pickupReadyDate')} field="pickupReadyDate" />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs>
          <input.ShipmentType readOnly={readOnly} value={data.shipmentType} label={t('common:fields.shipment.shipmentType')} onChange={set('shipmentType')} field="shipmentType" />
        </Grid>
        <Grid item xs>
          <input.ShippingTerms readOnly={readOnly} value={data.shippingTerms} label={t('common:fields.shipment.shippingTerms')} onChange={set('shippingTerms')} field="shippingTerms" />
        </Grid>
        <Grid item xs>
          <input.Incoterms readOnly={readOnly} shipmentType={data.shipmentType} shippingTerms={data.shippingTerms} value={data.incoterms} label={t('common:fields.shipment.incoterms')} onChange={set('incoterms')} field="incoterms" />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <input.CustomsClearance readOnly={readOnly} value={data.customsClearance} shipmentType={data.shipmentType} incoterms={data.incoterms} onChange={set('customsClearance')} id="customs" />
          <val.Text field="customsClearance" />
        </Grid>
      </Grid>

      <LocationModal isActive={locationModalTrigger} handleClose={() => setLocationModalTrigger(!locationModalTrigger)} changeOrigin={set('pickupFrom')} changeDestination={set('deliverTo')}/>
      <Button variant='contained' onClick={() => {setLocationModalTrigger(!locationModalTrigger)}} color='primary'>Suppliers</Button>

      <input.Address readOnly={readOnly} value={data.pickupFrom} label={t('common:fields.shipment.pickupFrom')} onChange={set('pickupFrom')} field="pickupFrom" type={originAddrType} transportMode={data.transportMode} />
      <input.Address readOnly={readOnly} value={data.deliverTo} label={t('common:fields.shipment.deliverTo')} onChange={set('deliverTo')} field="deliverTo" type={destAddrType} transportMode={data.transportMode} />

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <input.DatePicker readOnly={readOnly} value={data.expiresAt} label={t('common:fields.shipment.expiresAt')} onChange={set('expiresAt')} field="expiresAt" />
        </Grid>
        { !readOnly && 
          <Grid item>
            <format.HelpText value={i18n.t('common:fields.shipment.expiresAt@helpText')} />
          </Grid>
        }
      </Grid>
    </>;
  };

  const Page3 = ({ isActive }) => {
    if (!isActive) return <></>;

    return <>
      <input.TransportType readOnly={readOnly} value={data.transportType} label={t('common:fields.shipment.transportType')} onChange={set('transportType')} field="transportType" />
      <input.Check readOnly={readOnly} value={data.insurance} label={t('common:fields.shipment.insurance')} onChange={set('insurance')} id="insurance" />
      <val.Text field="insurance" />
      { data.insurance ? (
        <input.Money label={i18n.t('common:fields.shipment.shipmentValue')} readOnly={readOnly} value={data.shipmentValue} onChange={set('shipmentValue')} field="shipmentValue" />
      ) : <></>}
    </>;
  };

  const Page4 = ({ isActive }) => {
    if (!isActive) return <></>;

    return <>
      <input.Field label={t('common:fields.shipment.shipmentContent')}>
        <input.ShipmentContent readOnly={readOnly} transportMode={data.transportMode} value={data.shipmentContent} onChange={set('shipmentContent')} field="shipmentContent" />
      </input.Field>
    </>;
  };

  const setWizardInstance = SW => {
    wizard = SW;

    if (onWizardInit) {
      onWizardInit(SW);
    }
  };

  const nullTransitions = {
    enterRight: '',
    enterLeft : '',
    exitRight : '',
    exitLeft  : ''
  };

  const handleWizardStepChange = (wizardProps) => {
    const { activeStep } = wizardProps;

    console.log('handleWizardStepChange: ', wizardProps);

    setWizardStepIndex(activeStep);

    wizardContainerRef.current.style.alignItems =
      activeStep === 1 ? 'center' : 'stretch';

    if (onWizardStepChange) {
      onWizardStepChange(wizardProps);
    }
  };

  return <>
    <val.Container value={validation}>
      { isWizard ? <>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            gridTemplateColumns: '100%',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            width: '100%',
          }}
        >
          <Observer>{() => {
            return <>
              <Box mb={2}>
                <Paper variant="outlined">
                  <Box p={2}>
                    <Stepper activeStep={wizardStepIndex} orientation="horizontal">
                      { [1, 2, 3, 4].map(stepIndex => {
                        const stepProps = {};
                        const labelProps = {};

                        stepProps.completed = stepIndex < wizardStepIndex;
                        stepProps.active = stepIndex == wizardStepIndex;

                        let label = t(`common:wizard.newShipment.steps.${stepIndex}.label`);

                        if (stepIndex === 1 && wizardStepIndex > 1) {
                          label = <format.TransportMode value={data.transportMode} />;
                        }

                        return (
                          <Step key={stepIndex} {...stepProps}>
                            <StepLabel {...labelProps}>
                              {label}
                            </StepLabel>
                          </Step>
                        );
                      }) }
                    </Stepper>
                  </Box>
                </Paper>
              </Box>
            </>;
          }}</Observer>
          <Box pr={2} style={{
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'stretch',
            alignItems: 'stretch',
          }}>
            <div
              ref={wizardContainerRef}
              className={css({
                width: '100%',
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'center',
                '& > div': {
                  width: '100%',
                }
              })}
            >
              <StepWizard
                instance={setWizardInstance}
                onStepChange={handleWizardStepChange}
                transitions={nullTransitions}
              >
                <Page1 />
                <Page2 />
                <Page3 />
                <Page4 />
              </StepWizard>
            </div>
          </Box>
        </div>
      </> : <>
        <Box pr={2} style={{
          width: '100%',
          overflowY: 'auto'
        }}>
          <Box
            display="grid"
            gridTemplateColumns="min-content auto"
            gridColumnGap="1em"
          >
            <Box>
              <format.TransportMode mode="icon-huge" value={data.transportMode} />
            </Box>

            <Box 
              display="grid"
              gridTemplateColumns="100%"
              gridTemplateRows="repeat(4, min-content)"
            >
              <Page1 isActive={true} isWizard={false} />
              <Page2 isActive={true} isWizard={false} />
              <Page3 isActive={true} isWizard={false} />
              <Page4 isActive={true} isWizard={false} />
            </Box>
          </Box>
        </Box>
      </> }
    </val.Container>
  </>;
};

export default Main;
