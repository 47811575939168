import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

import api from '../lib/api';
import format from './format';
import action from './action';
import ui from './ui';

import {
  Table, TableHead, TableBody, TableRow, TableCell, Box,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MdView from '@material-ui/icons/Pageview';

export const AttachmentsList = React.memo(({ shipmentContractId, autoSize = true }) => {
  const { t } = useTranslation();
  const uiState = observable({
    items: null,
  });

  React.useEffect(() => {
    api.queryAttachments({
      filter: {
        shipmentContractId: api.ObjectId(shipmentContractId),
      }
    }).then(response => {
      if (!response.success) return;

      uiState.items = response.data;
    });
  });

  return <Observer>{() => {
    return <>
      <ui.TableContainer autoSize={autoSize}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell xs>{t('common:fields.attachment.name')}</TableCell>
              <TableCell align="center">
                <MdView />
              </TableCell>
              <TableCell className="text-center">{t('common:fields.attachment.size')}</TableCell>
              <TableCell className="text-center">{t('common:fields.attachment.createdAt')}</TableCell>
              <TableCell className="text-center">{t('common:fields.attachment.orgUnit.name')}</TableCell>
              <TableCell className="text-center">{t('common:fields.attachment.user.name')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { uiState.items && uiState.items.map((i, index) => {
              const onDownload = () => {
                //api.downloadUrl(i.body.content.url, i.body.content.name);
                api.browserDownloadUrl(i.body.content.url);
              };

              return <TableRow key={index}>
                <TableCell xs>
                  <action.Action mode="text" action="download" onClick={onDownload}>
                    {i.body.content.name}
                  </action.Action>
                </TableCell>
                <TableCell align="center">
                  <action.ViewAttachment
                    attachmentId={i.body._id}
                    mode="icon"
                  />
                </TableCell>
                <TableCell className="text-center">
                  <format.FileSizeString bytes={i.body.content.size} />
                </TableCell>
                <TableCell className="text-center">
                  <format.DateText bytes={i.body.createdAt} />
                </TableCell>
                <TableCell className="text-center">
                  {i.body.orgUnit.name}
                </TableCell>
                <TableCell className="text-center">
                  {i.body.user.name}
                </TableCell>
              </TableRow>;
            }) }
          </TableBody>
        </Table>
      </ui.TableContainer>
    </>;
  } }</Observer>;
});
