import { Box, Button, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {AiFillFile,AiFillFilePdf} from "react-icons/ai"
import { WindowScroller } from "react-virtualized";
import api from "../../lib/api";
import config from "../../lib/config";
import Format from "../format";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const options = [
//     {
//         id:"13123",
//         name:"forwarder1"
//     },
//     {
//         id:"13123",
//         name:"forwarder2"
//     }
// ]

export function ShowFilesContract({isActive,handleClose, files}){

    const handleClick = async (fileName) =>{
        window.open(config.apiBaseUrl+"/secure/files/"+fileName,"_blank")
        api.downloadFile(fileName)
    }

    const renderSwitch = (param) => {
        switch(param) {
          case 'application/pdf':
            return <AiFillFilePdf/>;
          default:
            return <AiFillFile/>;
        }
    }

    return <>
        <Modal
            open={isActive}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box sx={style}>
                <h2 id="parent-modal-title">Files of the contract</h2>
                {(files.length === 0)&&<div>files not exists on this contract yet!</div>}
                <div style={{display:"flex",flexDirection:"column",gap:"1rem", paddingBottom:"2rem"}}>
                    {files.map(file => {
                        return (<div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
                            <div>{file}</div>
                            {renderSwitch(file)}
                            <Button variant="contained" color="primary" onClick={() => handleClick(file)}>download</Button>
                        </div>)
                    })}
                </div>
            </Box>
        </Modal>
    </>
}