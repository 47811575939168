const data = 
[
    {
        "iata": "UTK",
        "lon": "169.86667",
        "iso": "MH",
        "status": 1,
        "name": "Utirik Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "11.233333",
        "size": "small"
    },
    {
        "iata": "FIV",
        "iso": "US",
        "status": 1,
        "name": "Five Finger CG Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "FAK",
        "iso": "US",
        "status": 1,
        "name": "False Island Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "BWS",
        "iso": "US",
        "status": 0,
        "name": "Blaine Municipal Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "WKK",
        "lon": "-158.61111",
        "iso": "US",
        "status": 1,
        "name": "Aleknagik / New Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.27778",
        "size": "medium"
    },
    {
        "iata": "TSS",
        "iso": "US",
        "status": 1,
        "name": "East 34th Street Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "FOB",
        "lon": "-123.79444",
        "iso": "US",
        "status": 1,
        "name": "Fort Bragg Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.474445",
        "size": "small"
    },
    {
        "iata": "ABP",
        "lon": "141.1",
        "iso": "PG",
        "status": 1,
        "name": "Atkamba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.066667",
        "size": "small"
    },
    {
        "iata": "ALV",
        "iso": "AD",
        "status": 1,
        "name": "Andorra la Vella Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "ADC",
        "lon": "145.73334",
        "iso": "PG",
        "status": 1,
        "name": "Andakombe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.133333",
        "size": "small"
    },
    {
        "iata": "TJP",
        "lon": "-66.563545",
        "iso": "PR",
        "status": 1,
        "name": "Areopuerto Internacional Michael Gonzalez",
        "continent": "NA",
        "type": "airport",
        "lat": "18.010702",
        "size": "large"
    },
    {
        "iata": "AEE",
        "iso": "SS",
        "status": 1,
        "name": "Adareil Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "AEI",
        "iso": "ES",
        "status": 1,
        "name": "Algeciras Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "AEK",
        "lon": "146.28334",
        "iso": "PG",
        "status": 1,
        "name": "Aseki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.366667",
        "size": "small"
    },
    {
        "iata": "OLR",
        "lon": "36.130333",
        "iso": "AF",
        "status": 1,
        "name": "Salerno Landing Zone Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.023167",
        "size": "small"
    },
    {
        "iata": "AFR",
        "lon": "148.38333",
        "iso": "PG",
        "status": 1,
        "name": "Afore Airstrip",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.133333",
        "size": "small"
    },
    {
        "iata": "AFT",
        "lon": "160.85",
        "iso": "SB",
        "status": 1,
        "name": "Afutara Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.2",
        "size": "small"
    },
    {
        "iata": "ATD",
        "lon": "161.03334",
        "iso": "SB",
        "status": 1,
        "name": "Uru Harbour Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.866667",
        "size": "small"
    },
    {
        "iata": "VEV",
        "lon": "159.55",
        "iso": "SB",
        "status": 1,
        "name": "Barakoma Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.85",
        "size": "small"
    },
    {
        "iata": "GEF",
        "lon": "156.59778",
        "iso": "SB",
        "status": 1,
        "name": "Geva Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.578333",
        "size": "small"
    },
    {
        "iata": "AGG",
        "lon": "144.07388",
        "iso": "PG",
        "status": 1,
        "name": "Angoram Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.168611",
        "size": "small"
    },
    {
        "iata": "AKS",
        "lon": "160.68083",
        "iso": "SB",
        "status": 1,
        "name": "Auki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.697778",
        "size": "small"
    },
    {
        "iata": "BAS",
        "lon": "155.88333",
        "iso": "SB",
        "status": 1,
        "name": "Ballalae Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.983333",
        "size": "small"
    },
    {
        "iata": "FRE",
        "lon": "159.58333",
        "iso": "SB",
        "status": 1,
        "name": "Fera/Maringe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.1",
        "size": "small"
    },
    {
        "iata": "HIR",
        "lon": "160.04819",
        "iso": "SB",
        "status": 1,
        "name": "Honiara International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.428592",
        "size": "medium"
    },
    {
        "iata": "MBU",
        "lon": "160.75",
        "iso": "SB",
        "status": 1,
        "name": "Babanakira Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.733333",
        "size": "small"
    },
    {
        "iata": "IRA",
        "lon": "161.83333",
        "iso": "SB",
        "status": 1,
        "name": "Ngorangora Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.5",
        "size": "small"
    },
    {
        "iata": "SCZ",
        "lon": "166.85",
        "iso": "SB",
        "status": 1,
        "name": "Santa Cruz/Graciosa Bay/Luova Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.616667",
        "size": "small"
    },
    {
        "iata": "MUA",
        "lon": "157.26971",
        "iso": "SB",
        "status": 1,
        "name": "Munda Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.32794",
        "size": "medium"
    },
    {
        "iata": "GZO",
        "lon": "156.83333",
        "iso": "SB",
        "status": 1,
        "name": "Nusatupe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.116667",
        "size": "small"
    },
    {
        "iata": "MNY",
        "lon": "155.56462",
        "iso": "SB",
        "status": 1,
        "name": "Mono Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.417428",
        "size": "small"
    },
    {
        "iata": "PRS",
        "lon": "158.0",
        "iso": "SB",
        "status": 1,
        "name": "Parasi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.166667",
        "size": "small"
    },
    {
        "iata": "OTV",
        "iso": "SB",
        "status": 0,
        "name": "Ontong Java Atoll Airstrip",
        "continent": "OC",
        "type": "closed",
        "size": null
    },
    {
        "iata": "RNL",
        "lon": "160.3",
        "iso": "SB",
        "status": 1,
        "name": "Rennell/Tingoa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.666667",
        "size": "small"
    },
    {
        "iata": "EGM",
        "lon": "157.87506",
        "iso": "SB",
        "status": 1,
        "name": "Sege Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.577956",
        "size": "small"
    },
    {
        "iata": "RUS",
        "lon": "161.25",
        "iso": "SB",
        "status": 1,
        "name": "Marau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.5",
        "size": "small"
    },
    {
        "iata": "VAO",
        "lon": "158.66667",
        "iso": "SB",
        "status": 1,
        "name": "Suavanao Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.566667",
        "size": "small"
    },
    {
        "iata": "AGK",
        "lon": "143.84666",
        "iso": "PG",
        "status": 1,
        "name": "Kagua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.403333",
        "size": "small"
    },
    {
        "iata": "KGE",
        "lon": "157.5861",
        "iso": "SB",
        "status": 1,
        "name": "Kagau Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.165",
        "size": "small"
    },
    {
        "iata": "AGL",
        "lon": "149.15527",
        "iso": "PG",
        "status": 1,
        "name": "Wanigela Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.340278",
        "size": "small"
    },
    {
        "iata": "RIN",
        "lon": "157.03334",
        "iso": "SB",
        "status": 1,
        "name": "Ringi Cove Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.2",
        "size": "small"
    },
    {
        "iata": "RBV",
        "lon": "157.63945",
        "iso": "SB",
        "status": 1,
        "name": "Ramata Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.165556",
        "size": "small"
    },
    {
        "iata": "AHT",
        "iso": "US",
        "status": 0,
        "name": "Amchitka Army Airfield",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "AHY",
        "lon": "45.533333",
        "iso": "MG",
        "status": 1,
        "name": "Ambatolhy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.016666",
        "size": "small"
    },
    {
        "iata": "AIE",
        "lon": "144.66667",
        "iso": "PG",
        "status": 1,
        "name": "Aiome Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.166667",
        "size": "small"
    },
    {
        "iata": "AIH",
        "lon": "141.26639",
        "iso": "PG",
        "status": 1,
        "name": "Aiambak Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.348611",
        "size": "small"
    },
    {
        "iata": "AIP",
        "lon": "168.81667",
        "iso": "MH",
        "status": 1,
        "name": "Ailinglaplap Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.266667",
        "size": "small"
    },
    {
        "iata": "AOS",
        "iso": "US",
        "status": 1,
        "name": "Amook Bay Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "AKM",
        "lon": "19.816668",
        "iso": "TD",
        "status": 1,
        "name": "Zakuoma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.883333",
        "size": "small"
    },
    {
        "iata": "ALZ",
        "iso": "US",
        "status": 1,
        "name": "Alitak Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "AMC",
        "lon": "20.283333",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "11.033333",
        "size": "large"
    },
    {
        "iata": "AME",
        "lon": "37.583332",
        "iso": "MZ",
        "status": 1,
        "name": "Alto Molocue Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.833333",
        "size": "small"
    },
    {
        "iata": "AMF",
        "lon": "141.66667",
        "iso": "PG",
        "status": 1,
        "name": "AMA Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.166667",
        "size": "small"
    },
    {
        "iata": "AMU",
        "lon": "141.15",
        "iso": "PG",
        "status": 1,
        "name": "Amanab Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.516667",
        "size": "small"
    },
    {
        "iata": "AMY",
        "lon": "45.666668",
        "iso": "MG",
        "status": 1,
        "name": "Ambatomainty Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.683332",
        "size": "small"
    },
    {
        "iata": "ANH",
        "iso": "SB",
        "status": 0,
        "name": "Anuha Island Resort Airport",
        "continent": "OC",
        "type": "closed",
        "size": null
    },
    {
        "iata": "INU",
        "lon": "166.91667",
        "iso": "NR",
        "status": 1,
        "name": "Nauru International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-0.533333",
        "size": "medium"
    },
    {
        "iata": "ANL",
        "lon": "17.25",
        "iso": "AO",
        "status": 1,
        "name": "Andulo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.25",
        "size": "small"
    },
    {
        "iata": "CNZ",
        "lon": "19.0",
        "iso": "AO",
        "status": 1,
        "name": "Cangamba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.5",
        "size": "small"
    },
    {
        "iata": "DRC",
        "lon": "20.7",
        "iso": "AO",
        "status": 1,
        "name": "Dirico Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.833332",
        "size": "small"
    },
    {
        "iata": "GGC",
        "lon": "22.566668",
        "iso": "AO",
        "status": 1,
        "name": "Lumbala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.65",
        "size": "small"
    },
    {
        "iata": "JMB",
        "lon": "16.083332",
        "iso": "AO",
        "status": 1,
        "name": "Jamba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.7",
        "size": "small"
    },
    {
        "iata": "KNP",
        "lon": "15.450278",
        "iso": "AO",
        "status": 1,
        "name": "Capanda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.766944",
        "size": "small"
    },
    {
        "iata": "NDF",
        "lon": "14.833333",
        "iso": "AO",
        "status": 1,
        "name": "Ndalatandos Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.333333",
        "size": "small"
    },
    {
        "iata": "AOB",
        "lon": "144.65",
        "iso": "PG",
        "status": 1,
        "name": "Annanberg Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.916667",
        "size": "small"
    },
    {
        "iata": "APP",
        "lon": "148.13333",
        "iso": "PG",
        "status": 1,
        "name": "Asapa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.983333",
        "size": "small"
    },
    {
        "iata": "APR",
        "lon": "145.0",
        "iso": "PG",
        "status": 1,
        "name": "April River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.5",
        "size": "small"
    },
    {
        "iata": "AQY",
        "lon": "-149.11667",
        "iso": "US",
        "status": 1,
        "name": "Girdwood Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.966667",
        "size": "small"
    },
    {
        "iata": "QRF",
        "lon": "-60.466667",
        "iso": "AR",
        "status": 1,
        "name": "Bragado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-35.13333",
        "size": "small"
    },
    {
        "iata": "CSZ",
        "lon": "-61.88333",
        "iso": "AR",
        "status": 1,
        "name": "Brigadier Hector Ruiz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-37.433334",
        "size": "small"
    },
    {
        "iata": "CVI",
        "lon": "-67.416664",
        "iso": "AR",
        "status": 1,
        "name": "Caleta Olivia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-46.416668",
        "size": "small"
    },
    {
        "iata": "CNT",
        "lon": "-61.233334",
        "iso": "AR",
        "status": 1,
        "name": "Charata Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.216667",
        "size": "small"
    },
    {
        "iata": "VGS",
        "lon": "-63.0",
        "iso": "AR",
        "status": 1,
        "name": "General Villegas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-35.083332",
        "size": "small"
    },
    {
        "iata": "LMD",
        "lon": "-68.13333",
        "iso": "AR",
        "status": 1,
        "name": "Los Menucos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.833332",
        "size": "small"
    },
    {
        "iata": "SZQ",
        "iso": "AR",
        "status": 0,
        "name": null,
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "VCF",
        "lon": "-66.15",
        "iso": "AR",
        "status": 1,
        "name": "Valcheta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.7",
        "size": "small"
    },
    {
        "iata": "VME",
        "lon": "-65.416664",
        "iso": "AR",
        "status": 1,
        "name": "Villa Mercedes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.63333",
        "size": "small"
    },
    {
        "iata": "NCJ",
        "iso": "AR",
        "status": 1,
        "name": "Sunchales Aeroclub Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CPG",
        "lon": "-62.979782",
        "iso": "AR",
        "status": 1,
        "name": "Carmen De Patagones Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.782757",
        "size": "small"
    },
    {
        "iata": "PRQ",
        "lon": "-60.666668",
        "iso": "AR",
        "status": 1,
        "name": "Termal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.833332",
        "size": "small"
    },
    {
        "iata": "ARP",
        "lon": "149.5",
        "iso": "PG",
        "status": 1,
        "name": "Aragip Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.8",
        "size": "small"
    },
    {
        "iata": "TAV",
        "lon": "-169.45",
        "iso": "AS",
        "status": 1,
        "name": "Tau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.25",
        "size": "small"
    },
    {
        "iata": "ASZ",
        "lon": "150.36667",
        "iso": "PG",
        "status": 1,
        "name": "Asirim Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.866667",
        "size": "small"
    },
    {
        "iata": "ATP",
        "lon": "142.28334",
        "iso": "PG",
        "status": 1,
        "name": "Aitape Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.166667",
        "size": "small"
    },
    {
        "iata": "AGW",
        "lon": "142.15",
        "iso": "AU",
        "status": 1,
        "name": "Agnew Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.15",
        "size": "small"
    },
    {
        "iata": "AYD",
        "lon": "135.95",
        "iso": "AU",
        "status": 1,
        "name": "Alroy Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.3",
        "size": "small"
    },
    {
        "iata": "BCK",
        "lon": "144.16667",
        "iso": "AU",
        "status": 1,
        "name": "Bolwarra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.4",
        "size": "small"
    },
    {
        "iata": "BFC",
        "lon": "145.33333",
        "iso": "AU",
        "status": 1,
        "name": "Bloomfield Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.9",
        "size": "small"
    },
    {
        "iata": "BVW",
        "lon": "143.21666",
        "iso": "AU",
        "status": 1,
        "name": "Batavia Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.65",
        "size": "small"
    },
    {
        "iata": "BYX",
        "lon": "136.23334",
        "iso": "AU",
        "status": 1,
        "name": "Baniyala Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.2",
        "size": "small"
    },
    {
        "iata": "COB",
        "lon": "130.93333",
        "iso": "AU",
        "status": 1,
        "name": "Coolibah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.55",
        "size": "small"
    },
    {
        "iata": "CQP",
        "lon": "147.5",
        "iso": "AU",
        "status": 1,
        "name": "Cape Flattery Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.2",
        "size": "small"
    },
    {
        "iata": "CRJ",
        "lon": "132.3",
        "iso": "AU",
        "status": 1,
        "name": "Coorabie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.9",
        "size": "small"
    },
    {
        "iata": "CRY",
        "lon": "128.61667",
        "iso": "AU",
        "status": 1,
        "name": "Carlton Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.466667",
        "size": "small"
    },
    {
        "iata": "CSD",
        "lon": "135.91667",
        "iso": "AU",
        "status": 1,
        "name": "Cresswell Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.966667",
        "size": "small"
    },
    {
        "iata": "CTR",
        "lon": "131.0",
        "iso": "AU",
        "status": 1,
        "name": "Cattle Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.583332",
        "size": "small"
    },
    {
        "iata": "DYM",
        "lon": "139.16667",
        "iso": "AU",
        "status": 1,
        "name": "Diamantina Lakes Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.75",
        "size": "small"
    },
    {
        "iata": "EDD",
        "lon": "133.2",
        "iso": "AU",
        "status": 1,
        "name": "Erldunda Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.25",
        "size": "small"
    },
    {
        "iata": "EKD",
        "lon": "135.55",
        "iso": "AU",
        "status": 1,
        "name": "Elkedra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.15",
        "size": "small"
    },
    {
        "iata": "HAT",
        "lon": "140.58333",
        "iso": "AU",
        "status": 1,
        "name": "Heathlands Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.720833",
        "size": "small"
    },
    {
        "iata": "HIG",
        "lon": "143.15",
        "iso": "AU",
        "status": 1,
        "name": "Highbury Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.416668",
        "size": "small"
    },
    {
        "iata": "HIS",
        "iso": "AU",
        "status": 1,
        "name": "Hayman Island Resort Seaplane Base",
        "continent": "OC",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "HLV",
        "lon": "145.2",
        "iso": "AU",
        "status": 1,
        "name": "Helenvale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.683333",
        "size": "small"
    },
    {
        "iata": "KBD",
        "lon": "124.35",
        "iso": "AU",
        "status": 1,
        "name": "Kimberley Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.333332",
        "size": "small"
    },
    {
        "iata": "KGR",
        "lon": "133.03334",
        "iso": "AU",
        "status": 1,
        "name": "Kulgera Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.833332",
        "size": "small"
    },
    {
        "iata": "KOH",
        "lon": "142.41667",
        "iso": "AU",
        "status": 1,
        "name": "Koolatah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.966667",
        "size": "small"
    },
    {
        "iata": "KYF",
        "lon": "120.075",
        "iso": "AU",
        "status": 1,
        "name": "Yeelirrie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.277779",
        "size": "small"
    },
    {
        "iata": "LIB",
        "lon": "129.8",
        "iso": "AU",
        "status": 1,
        "name": "Limbunya Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.2",
        "size": "small"
    },
    {
        "iata": "MNW",
        "lon": "135.21666",
        "iso": "AU",
        "status": 1,
        "name": "Macdonald Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.45",
        "size": "small"
    },
    {
        "iata": "MUP",
        "lon": "141.05",
        "iso": "AU",
        "status": 1,
        "name": "Mulga Park Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.133333",
        "size": "small"
    },
    {
        "iata": "MWY",
        "lon": "141.51666",
        "iso": "AU",
        "status": 1,
        "name": "Miranda Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.783333",
        "size": "small"
    },
    {
        "iata": "MYO",
        "lon": "124.26667",
        "iso": "AU",
        "status": 1,
        "name": "Camballin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.116667",
        "size": "small"
    },
    {
        "iata": "NKB",
        "lon": "124.85",
        "iso": "AU",
        "status": 1,
        "name": "Noonkanbah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.55",
        "size": "small"
    },
    {
        "iata": "OKB",
        "lon": "153.16667",
        "iso": "AU",
        "status": 1,
        "name": "Orchid Beach Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.25",
        "size": "small"
    },
    {
        "iata": "PEP",
        "lon": "130.1",
        "iso": "AU",
        "status": 1,
        "name": "Peppimenarti Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.15",
        "size": "small"
    },
    {
        "iata": "RDA",
        "lon": "135.16667",
        "iso": "AU",
        "status": 1,
        "name": "Rockhampton Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.95",
        "size": "small"
    },
    {
        "iata": "SSK",
        "lon": "128.13333",
        "iso": "AU",
        "status": 1,
        "name": "Sturt Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.2",
        "size": "small"
    },
    {
        "iata": "SWB",
        "lon": "119.36667",
        "iso": "AU",
        "status": 1,
        "name": "Shaw River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.516666",
        "size": "small"
    },
    {
        "iata": "TPR",
        "lon": "117.666664",
        "iso": "AU",
        "status": 1,
        "name": "Tom Price Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.75",
        "size": "small"
    },
    {
        "iata": "TWP",
        "lon": "143.75",
        "iso": "AU",
        "status": 1,
        "name": "Torwood Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.366667",
        "size": "small"
    },
    {
        "iata": "WRW",
        "lon": "120.71667",
        "iso": "AU",
        "status": 1,
        "name": "Warrawagine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.866667",
        "size": "small"
    },
    {
        "iata": "WSY",
        "lon": "148.75278",
        "iso": "AU",
        "status": 1,
        "name": "Whitsunday Airstrip",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.502777",
        "size": "small"
    },
    {
        "iata": "ZVG",
        "lon": "127.583336",
        "iso": "AU",
        "status": 1,
        "name": "Springvale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.866667",
        "size": "small"
    },
    {
        "iata": "AUI",
        "lon": "143.075",
        "iso": "PG",
        "status": 1,
        "name": "Aua Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-1.458333",
        "size": "small"
    },
    {
        "iata": "AUJ",
        "lon": "142.85",
        "iso": "PG",
        "status": 1,
        "name": "Ambunti Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.25",
        "size": "small"
    },
    {
        "iata": "AUP",
        "lon": "149.58333",
        "iso": "PG",
        "status": 1,
        "name": "Agaun Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.983333",
        "size": "small"
    },
    {
        "iata": "AUV",
        "lon": "148.43333",
        "iso": "PG",
        "status": 1,
        "name": "Aumo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.716667",
        "size": "small"
    },
    {
        "iata": "BUA",
        "lon": "154.67288",
        "iso": "PG",
        "status": 1,
        "name": "Buka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.422443",
        "size": "medium"
    },
    {
        "iata": "CMU",
        "lon": "144.97444",
        "iso": "PG",
        "status": 1,
        "name": "Chimbu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.993889",
        "size": "medium"
    },
    {
        "iata": "DAU",
        "lon": "143.20605",
        "iso": "PG",
        "status": 1,
        "name": "Daru Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.08351",
        "size": "medium"
    },
    {
        "iata": "GKA",
        "lon": "145.39293",
        "iso": "PG",
        "status": 1,
        "name": "Goroka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.075244",
        "size": "medium"
    },
    {
        "iata": "GUR",
        "lon": "150.33861",
        "iso": "PG",
        "status": 1,
        "name": "Gurney Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.310833",
        "size": "medium"
    },
    {
        "iata": "PNP",
        "lon": "148.30873",
        "iso": "PG",
        "status": 1,
        "name": "Girua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.807474",
        "size": "medium"
    },
    {
        "iata": "HKN",
        "lon": "150.403",
        "iso": "PG",
        "status": 1,
        "name": "Kimbe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.456876",
        "size": "medium"
    },
    {
        "iata": "LSA",
        "lon": "151.025",
        "iso": "PG",
        "status": 1,
        "name": "Losuia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.363889",
        "size": "small"
    },
    {
        "iata": "UNG",
        "lon": "141.28601",
        "iso": "PG",
        "status": 1,
        "name": "Kiunga Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.125552",
        "size": "small"
    },
    {
        "iata": "KRI",
        "lon": "144.26666",
        "iso": "PG",
        "status": 1,
        "name": "Kikori Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.483333",
        "size": "small"
    },
    {
        "iata": "KMA",
        "lon": "145.77155",
        "iso": "PG",
        "status": 1,
        "name": "Kerema Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.961702",
        "size": "medium"
    },
    {
        "iata": "KVG",
        "lon": "150.80653",
        "iso": "PG",
        "status": 1,
        "name": "Kavieng Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-2.58075",
        "size": "medium"
    },
    {
        "iata": "LNV",
        "lon": "152.6272",
        "iso": "PG",
        "status": 1,
        "name": "Londolovit Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.043223",
        "size": "small"
    },
    {
        "iata": "MAG",
        "lon": "145.78784",
        "iso": "PG",
        "status": 1,
        "name": "Madang Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.210852",
        "size": "medium"
    },
    {
        "iata": "HGU",
        "lon": "144.29738",
        "iso": "PG",
        "status": 1,
        "name": "Mount Hagen Kagamuga Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.828767",
        "size": "medium"
    },
    {
        "iata": "MDU",
        "lon": "143.65",
        "iso": "PG",
        "status": 1,
        "name": "Mendi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.158611",
        "size": "medium"
    },
    {
        "iata": "MAS",
        "lon": "147.42444",
        "iso": "PG",
        "status": 1,
        "name": "Momote Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-2.056667",
        "size": "medium"
    },
    {
        "iata": "MXH",
        "lon": "143.25055",
        "iso": "PG",
        "status": 1,
        "name": "Moro Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.3525",
        "size": "small"
    },
    {
        "iata": "MIS",
        "lon": "152.84166",
        "iso": "PG",
        "status": 1,
        "name": "Misima Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.5",
        "size": "small"
    },
    {
        "iata": "LAE",
        "lon": "146.72757",
        "iso": "PG",
        "status": 1,
        "name": "Lae Nadzab Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.562694",
        "size": "medium"
    },
    {
        "iata": "POM",
        "lon": "147.21446",
        "iso": "PG",
        "status": 1,
        "name": "Port Moresby Jacksons International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.444308",
        "size": "large"
    },
    {
        "iata": "TIZ",
        "lon": "142.94055",
        "iso": "PG",
        "status": 1,
        "name": "Tari Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.860556",
        "size": "small"
    },
    {
        "iata": "TBG",
        "lon": "141.227",
        "iso": "PG",
        "status": 1,
        "name": "Tabubil Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.274722",
        "size": "small"
    },
    {
        "iata": "RAB",
        "lon": "152.36667",
        "iso": "PG",
        "status": 1,
        "name": "Tokua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.333333",
        "size": "medium"
    },
    {
        "iata": "AYU",
        "lon": "145.9",
        "iso": "PG",
        "status": 1,
        "name": "Aiyura Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.333333",
        "size": "small"
    },
    {
        "iata": "VAI",
        "lon": "141.30194",
        "iso": "PG",
        "status": 1,
        "name": "Vanimo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-2.694722",
        "size": "medium"
    },
    {
        "iata": "WBM",
        "lon": "143.91667",
        "iso": "PG",
        "status": 1,
        "name": "Wapenamanda Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.616667",
        "size": "medium"
    },
    {
        "iata": "WWK",
        "lon": "143.66916",
        "iso": "PG",
        "status": 1,
        "name": "Wewak International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.580034",
        "size": "medium"
    },
    {
        "iata": "AYY",
        "iso": "LK",
        "status": 1,
        "name": "Arugam Bay SPB",
        "continent": "AS",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "AYZ",
        "iso": "US",
        "status": 0,
        "name": "Zahn's Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "AZB",
        "lon": "148.81667",
        "iso": "PG",
        "status": 1,
        "name": "Amazon Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.15",
        "size": "small"
    },
    {
        "iata": "BCW",
        "lon": "35.425",
        "iso": "MZ",
        "status": 1,
        "name": "Benguera Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.884722",
        "size": "medium"
    },
    {
        "iata": "HKV",
        "lon": "25.55",
        "iso": "BG",
        "status": 1,
        "name": "Haskovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.933334",
        "size": "small"
    },
    {
        "iata": "JAM",
        "lon": "26.483334",
        "iso": "BG",
        "status": 1,
        "name": "Bezmer Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "42.516666",
        "size": "medium"
    },
    {
        "iata": "SZR",
        "iso": "BG",
        "status": 0,
        "name": "Stara Zagora Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "JEG",
        "lon": "-52.75",
        "iso": "GL",
        "status": 1,
        "name": "Aasiaat Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.7",
        "size": "medium"
    },
    {
        "iata": "AAP",
        "iso": "GL",
        "status": 1,
        "name": "Aappilattoq (Qaasuitsup) Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "AGM",
        "iso": "GL",
        "status": 1,
        "name": "Tasiilaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "LLU",
        "iso": "GL",
        "status": 1,
        "name": "Alluitsup Paa Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QUV",
        "iso": "GL",
        "status": 1,
        "name": "Aappilattoq (Kujalleq) Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QUW",
        "iso": "GL",
        "status": 1,
        "name": "Ammassivik Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QGQ",
        "iso": "GL",
        "status": 1,
        "name": "Attu Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "UAK",
        "lon": "-45.316666",
        "iso": "GL",
        "status": 1,
        "name": "Narsarsuaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.166668",
        "size": "medium"
    },
    {
        "iata": "JCH",
        "iso": "GL",
        "status": 1,
        "name": "Qasigiannguit Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "CNP",
        "lon": "-22.658333",
        "iso": "GL",
        "status": 1,
        "name": "Neerlerit Inaat Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.74306",
        "size": "small"
    },
    {
        "iata": "QFG",
        "iso": "GL",
        "status": 1,
        "name": "Eqalugaarsuit Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QFN",
        "iso": "GL",
        "status": 1,
        "name": "Narsaq Kujalleq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JFR",
        "iso": "GL",
        "status": 1,
        "name": "Paamiut Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "GOH",
        "lon": "-51.725",
        "iso": "GL",
        "status": 1,
        "name": "Godthaab / Nuuk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.181946",
        "size": "medium"
    },
    {
        "iata": "JGO",
        "iso": "GL",
        "status": 1,
        "name": "Qeqertarsuaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "IKE",
        "iso": "GL",
        "status": 1,
        "name": "Ikerasak Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QFI",
        "iso": "GL",
        "status": 1,
        "name": "Iginniarfik",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "XIQ",
        "iso": "GL",
        "status": 1,
        "name": "Ilimanaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "INN",
        "iso": "GL",
        "status": 1,
        "name": "Innarsuit Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "ISO",
        "iso": "GL",
        "status": 1,
        "name": "Isortoq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QJI",
        "iso": "GL",
        "status": 1,
        "name": "Ikamiut Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JJU",
        "iso": "GL",
        "status": 1,
        "name": "Qaqortoq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JAV",
        "lon": "-51.05991",
        "iso": "GL",
        "status": 1,
        "name": "Ilulissat Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.24186",
        "size": "small"
    },
    {
        "iata": "QPW",
        "iso": "GL",
        "status": 1,
        "name": "Kangaatsiaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "KUS",
        "lon": "-37.11667",
        "iso": "GL",
        "status": 1,
        "name": "Kulusuk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "65.566666",
        "size": "small"
    },
    {
        "iata": "UPK",
        "iso": "GL",
        "status": 1,
        "name": "Upernavik Kujalleq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "KMT",
        "iso": "GL",
        "status": 1,
        "name": "Kuummiut Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "KLQ",
        "iso": "GL",
        "status": 1,
        "name": "Kullorsuaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "KAQ",
        "iso": "GL",
        "status": 1,
        "name": "Kangersuatsiaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QJE",
        "iso": "GL",
        "status": 1,
        "name": "Kitsissuarsuit Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "ILL",
        "iso": "GL",
        "status": 1,
        "name": "Illorsuit Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "MOR",
        "iso": "GL",
        "status": 1,
        "name": "Moriusaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JSU",
        "lon": "-52.93537",
        "iso": "GL",
        "status": 1,
        "name": "Maniitsoq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "65.41244",
        "size": "small"
    },
    {
        "iata": "QMK",
        "iso": "GL",
        "status": 1,
        "name": "Niaqornaarsuk Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JNN",
        "iso": "GL",
        "status": 1,
        "name": "Nanortalik Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "NUG",
        "iso": "GL",
        "status": 1,
        "name": "Nuugaatsiaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JNS",
        "iso": "GL",
        "status": 1,
        "name": "Narsaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "NIA",
        "iso": "GL",
        "status": 1,
        "name": "Niaqornat Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "NUS",
        "iso": "GL",
        "status": 1,
        "name": "Nuussuaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JFR",
        "lon": "-49.70028",
        "iso": "GL",
        "status": 1,
        "name": "Paamiut Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.00028",
        "size": "small"
    },
    {
        "iata": "QQT",
        "iso": "GL",
        "status": 1,
        "name": "Qeqertaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "NAQ",
        "lon": "-69.382996",
        "iso": "GL",
        "status": 1,
        "name": "Qaanaaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "77.48948",
        "size": "small"
    },
    {
        "iata": "OBY",
        "iso": "GL",
        "status": 1,
        "name": "Ittoqqortoormiit Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "SFJ",
        "lon": "-50.719723",
        "iso": "GL",
        "status": 1,
        "name": "Kangerlussuaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.01222",
        "size": "medium"
    },
    {
        "iata": "SMG",
        "iso": "GL",
        "status": 1,
        "name": "Sermiligaaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "SIO",
        "iso": "GL",
        "status": 1,
        "name": "Siorapaluk Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QUP",
        "iso": "GL",
        "status": 1,
        "name": "Saqqaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JHS",
        "lon": "-53.704166",
        "iso": "GL",
        "status": 1,
        "name": "Sisimiut Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.93694",
        "size": "small"
    },
    {
        "iata": "SAA",
        "iso": "GL",
        "status": 1,
        "name": "Saattut Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "SAV",
        "iso": "GL",
        "status": 1,
        "name": "Savissivik Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "THU",
        "lon": "-68.7",
        "iso": "GL",
        "status": 1,
        "name": "Thule Air Base",
        "continent": "NA",
        "type": "airport",
        "lat": "76.53333",
        "size": "medium"
    },
    {
        "iata": "TNT",
        "iso": "GL",
        "status": 1,
        "name": "Tiniteqilaaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JUV",
        "lon": "-56.130554",
        "iso": "GL",
        "status": 1,
        "name": "Upernavik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "72.790276",
        "size": "small"
    },
    {
        "iata": "UMD",
        "iso": "GL",
        "status": 1,
        "name": "Uummannaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JQA",
        "lon": "-52.702778",
        "iso": "GL",
        "status": 1,
        "name": "Qaarsut Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.73194",
        "size": "small"
    },
    {
        "iata": "UKK",
        "iso": "GL",
        "status": 1,
        "name": "Ukkusissat Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "BHL",
        "lon": "-113.55",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "28.95",
        "size": "small"
    },
    {
        "iata": "AEY",
        "lon": "-18.075068",
        "iso": "IS",
        "status": 1,
        "name": "Akureyri Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.654564",
        "size": "medium"
    },
    {
        "iata": "BIU",
        "lon": "-23.983334",
        "iso": "IS",
        "status": 1,
        "name": "Bildudalur Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.833336",
        "size": "small"
    },
    {
        "iata": "BGJ",
        "lon": "-14.5",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.0",
        "size": "small"
    },
    {
        "iata": "BJD",
        "lon": "-14.75",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.066666",
        "size": "small"
    },
    {
        "iata": "BLO",
        "lon": "-20.3",
        "iso": "IS",
        "status": 1,
        "name": "Hjaltabakki Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.666664",
        "size": "small"
    },
    {
        "iata": "BQD",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BXV",
        "lon": "-14.0",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.76667",
        "size": "small"
    },
    {
        "iata": "DJU",
        "lon": "-14.268333",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.650276",
        "size": "small"
    },
    {
        "iata": "EGS",
        "lon": "-14.402778",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.27778",
        "size": "medium"
    },
    {
        "iata": "FAS",
        "lon": "-14.016667",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.95",
        "size": "small"
    },
    {
        "iata": "FAG",
        "lon": "-16.65",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.88333",
        "size": "small"
    },
    {
        "iata": "GUU",
        "lon": "-23.166668",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.96667",
        "size": "small"
    },
    {
        "iata": "GJR",
        "lon": "-21.35",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.98333",
        "size": "small"
    },
    {
        "iata": "GRY",
        "lon": "-18.016666",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.566666",
        "size": "small"
    },
    {
        "iata": "HVK",
        "lon": "-22.466667",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.7",
        "size": "small"
    },
    {
        "iata": "HFN",
        "lon": "-15.266667",
        "iso": "IS",
        "status": 1,
        "name": "Hornafjordur Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.28333",
        "size": "medium"
    },
    {
        "iata": "FLI",
        "lon": "-23.7",
        "iso": "IS",
        "status": 1,
        "name": "Holt Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.98333",
        "size": "small"
    },
    {
        "iata": "HZK",
        "lon": "-17.427778",
        "iso": "IS",
        "status": 1,
        "name": "Husavik Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.95528",
        "size": "medium"
    },
    {
        "iata": "HVM",
        "lon": "-21.0",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.416664",
        "size": "small"
    },
    {
        "iata": "HLO",
        "lon": "15.5",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "29.5",
        "size": "small"
    },
    {
        "iata": "IFJ",
        "lon": "-23.132778",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.05972",
        "size": "medium"
    },
    {
        "iata": "KEF",
        "lon": "-22.624283",
        "iso": "IS",
        "status": 1,
        "name": "Keflavik International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.997765",
        "size": "large"
    },
    {
        "iata": "OPA",
        "lon": "-16.483334",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.416664",
        "size": "small"
    },
    {
        "iata": "SAK",
        "lon": "-19.65",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.75",
        "size": "small"
    },
    {
        "iata": "NOR",
        "lon": "-22.0",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.166664",
        "size": "small"
    },
    {
        "iata": "OFJ",
        "lon": "-19.833332",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.583336",
        "size": "small"
    },
    {
        "iata": "PFJ",
        "lon": "-19.008333",
        "iso": "IS",
        "status": 1,
        "name": "Patreksfjordur Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.03333",
        "size": "medium"
    },
    {
        "iata": "RHA",
        "lon": "-21.416668",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.166664",
        "size": "small"
    },
    {
        "iata": "OLI",
        "lon": "-23.983334",
        "iso": "IS",
        "status": 1,
        "name": "Rif Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.0",
        "size": "small"
    },
    {
        "iata": "RFN",
        "lon": "-15.016667",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.416664",
        "size": "small"
    },
    {
        "iata": "REK",
        "iso": "IS",
        "status": 1,
        "name": "Reykjavik Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "MVA",
        "lon": "-16.966667",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.61667",
        "size": "small"
    },
    {
        "iata": "SIJ",
        "lon": "-18.933332",
        "iso": "IS",
        "status": 1,
        "name": "Siglufjordur Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "66.166664",
        "size": "medium"
    },
    {
        "iata": "SYK",
        "lon": "-22.8",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.1",
        "size": "small"
    },
    {
        "iata": "TEY",
        "lon": "-23.45",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.88333",
        "size": "small"
    },
    {
        "iata": "THO",
        "iso": "IS",
        "status": 0,
        "name": null,
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "THO",
        "lon": "-15.335653",
        "iso": "IS",
        "status": 1,
        "name": "Thorshofn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "66.219734",
        "size": "small"
    },
    {
        "iata": "VEY",
        "lon": "-20.277779",
        "iso": "IS",
        "status": 1,
        "name": "Vestmannaeyjar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.426388",
        "size": "medium"
    },
    {
        "iata": "VPN",
        "lon": "-14.846748",
        "iso": "IS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.72167",
        "size": "small"
    },
    {
        "iata": "BJE",
        "iso": "SD",
        "status": 1,
        "name": "Baleela Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BJQ",
        "iso": "OM",
        "status": 1,
        "name": "Bahja Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "PRN",
        "lon": "21.035557",
        "iso": "KS",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "42.573612",
        "size": "large"
    },
    {
        "iata": "BLM",
        "lon": "-74.01667",
        "iso": "MX",
        "status": 1,
        "name": "Bahia De Los Angelos South",
        "continent": "NA",
        "type": "airport",
        "lat": "40.183334",
        "size": "small"
    },
    {
        "iata": "BMQ",
        "lon": "39.833332",
        "iso": "KE",
        "status": 1,
        "name": "Bamburi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.5",
        "size": "small"
    },
    {
        "iata": "RBQ",
        "lon": "-67.55",
        "iso": "BO",
        "status": 1,
        "name": "Rurenabaque Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.45",
        "size": "small"
    },
    {
        "iata": "BVL",
        "lon": "-63.583332",
        "iso": "BO",
        "status": 1,
        "name": "Baures Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.583333",
        "size": "small"
    },
    {
        "iata": "TUZ",
        "iso": "BR",
        "status": 0,
        "name": null,
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "ALT",
        "iso": "BR",
        "status": 0,
        "name": "Alenquer Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "SWM",
        "iso": "BR",
        "status": 0,
        "name": "Suia-Missu Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "NSB",
        "iso": "BS",
        "status": 1,
        "name": "Bimini North Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "HUK",
        "lon": "21.75",
        "iso": "BW",
        "status": 1,
        "name": "Hukuntsi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.983334",
        "size": "small"
    },
    {
        "iata": "BYV",
        "iso": "LK",
        "status": 1,
        "name": "Beira Lake Seaplane Base",
        "continent": "AS",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "BCV",
        "lon": "-88.77904",
        "iso": "BZ",
        "status": 1,
        "name": "Belmopan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.270498",
        "size": "small"
    },
    {
        "iata": "BGK",
        "lon": "-88.4",
        "iso": "BZ",
        "status": 1,
        "name": "Big Creek Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.516666",
        "size": "medium"
    },
    {
        "iata": "CUK",
        "lon": "-88.02956",
        "iso": "BZ",
        "status": 1,
        "name": "Caye Caulker Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.735527",
        "size": "medium"
    },
    {
        "iata": "CYC",
        "lon": "-88.833336",
        "iso": "BZ",
        "status": 1,
        "name": "Caye Chapel Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.666668",
        "size": "medium"
    },
    {
        "iata": "CZH",
        "lon": "-88.410675",
        "iso": "BZ",
        "status": 1,
        "name": "Corozal Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.382334",
        "size": "medium"
    },
    {
        "iata": "DGA",
        "lon": "-88.23039",
        "iso": "BZ",
        "status": 1,
        "name": "Dangriga Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.982765",
        "size": "medium"
    },
    {
        "iata": "INB",
        "lon": "-88.43826",
        "iso": "BZ",
        "status": 1,
        "name": "Independence Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.535536",
        "size": "small"
    },
    {
        "iata": "MDB",
        "lon": "-88.32056",
        "iso": "BZ",
        "status": 1,
        "name": "Melinda Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.998611",
        "size": "small"
    },
    {
        "iata": "ORZ",
        "lon": "-88.75",
        "iso": "BZ",
        "status": 1,
        "name": "Orange Walk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.166668",
        "size": "small"
    },
    {
        "iata": "PLJ",
        "lon": "-88.360886",
        "iso": "BZ",
        "status": 1,
        "name": "Placencia Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.53708",
        "size": "medium"
    },
    {
        "iata": "PND",
        "lon": "-88.816666",
        "iso": "BZ",
        "status": 1,
        "name": "Punta Gorda Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.1",
        "size": "medium"
    },
    {
        "iata": "SJX",
        "lon": "-88.333336",
        "iso": "BZ",
        "status": 1,
        "name": "Sartaneja Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.3",
        "size": "medium"
    },
    {
        "iata": "SPR",
        "lon": "-87.96571",
        "iso": "BZ",
        "status": 1,
        "name": "San Pedro Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.917067",
        "size": "medium"
    },
    {
        "iata": "SQS",
        "lon": "-89.03333",
        "iso": "BZ",
        "status": 1,
        "name": "Matthew Spain Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.183332",
        "size": "small"
    },
    {
        "iata": "STU",
        "lon": "-88.45",
        "iso": "BZ",
        "status": 1,
        "name": "Santa Cruz Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.266666",
        "size": "small"
    },
    {
        "iata": "SVK",
        "lon": "-88.34",
        "iso": "BZ",
        "status": 1,
        "name": "Silver Creek Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.7253",
        "size": "small"
    },
    {
        "iata": "TZA",
        "lon": "-88.191666",
        "iso": "BZ",
        "status": 1,
        "name": "Belize City Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.51639",
        "size": "medium"
    },
    {
        "iata": "BZB",
        "lon": "35.49167",
        "iso": "MZ",
        "status": 1,
        "name": "Bazaruto Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.534721",
        "size": "medium"
    },
    {
        "iata": "YUJ",
        "iso": "CA",
        "status": 0,
        "name": "Lady Franklin Point Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YWO",
        "iso": "CA",
        "status": 0,
        "name": "Lupin Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YTJ",
        "iso": "CA",
        "status": 0,
        "name": "Terrace Bay Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YMV",
        "iso": "CA",
        "status": 1,
        "name": "Mary River Aerodrome",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "YBW",
        "iso": "CA",
        "status": 1,
        "name": "Bedwell Harbour Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "ZNA",
        "iso": "CA",
        "status": 1,
        "name": "Nanaimo Harbour Water Airport",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YZZ",
        "lon": "-117.6",
        "iso": "CA",
        "status": 1,
        "name": "Trail Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.066666",
        "size": "small"
    },
    {
        "iata": "YWS",
        "iso": "CA",
        "status": 1,
        "name": "Whistler/Green Lake Water Aerodrome",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YPT",
        "iso": "CA",
        "status": 1,
        "name": "Pender Harbour Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YAA",
        "lon": "-125.3",
        "iso": "CA",
        "status": 1,
        "name": "Anahim Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.45",
        "size": "small"
    },
    {
        "iata": "JHL",
        "lon": "-111.41889",
        "iso": "CA",
        "status": 1,
        "name": "Fort MacKay/Albian Aerodrome",
        "continent": "NA",
        "type": "airport",
        "lat": "57.22389",
        "size": "small"
    },
    {
        "iata": "YMU",
        "iso": "CA",
        "status": 1,
        "name": "Mansons Landing Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YWM",
        "lon": "-56.01282",
        "iso": "CA",
        "status": 1,
        "name": "Williams Harbour Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.335438",
        "size": "small"
    },
    {
        "iata": "YFX",
        "lon": "-55.683334",
        "iso": "CA",
        "status": 1,
        "name": "St. Lewis (Fox Harbour) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.36667",
        "size": "small"
    },
    {
        "iata": "YHA",
        "lon": "-56.3",
        "iso": "CA",
        "status": 1,
        "name": "Port Hope Simpson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.533333",
        "size": "small"
    },
    {
        "iata": "YRG",
        "lon": "-58.416668",
        "iso": "CA",
        "status": 1,
        "name": "Rigolet Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.333332",
        "size": "small"
    },
    {
        "iata": "CDK",
        "lon": "-83.03333",
        "iso": "US",
        "status": 1,
        "name": "George T Lewis Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.133333",
        "size": "small"
    },
    {
        "iata": "YCK",
        "lon": "-126.09047",
        "iso": "CA",
        "status": 1,
        "name": "Colville Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.03993",
        "size": "small"
    },
    {
        "iata": "EE7",
        "iso": "CA",
        "status": 1,
        "name": "Edmonton/Cooking Lake Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YLE",
        "lon": "-117.24318",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "63.132942",
        "size": "small"
    },
    {
        "iata": "NML",
        "iso": "CA",
        "status": 1,
        "name": "Fort McMurray / Mildred Lake Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "DAS",
        "lon": "-119.712776",
        "iso": "CA",
        "status": 1,
        "name": "Great Bear Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.70306",
        "size": "small"
    },
    {
        "iata": "YFI",
        "iso": "CA",
        "status": 1,
        "name": "Fort Mackay / Firebag",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OKG",
        "lon": "15.0",
        "iso": "CG",
        "status": 1,
        "name": "Okoyo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.466667",
        "size": "small"
    },
    {
        "iata": "CGA",
        "iso": "US",
        "status": 1,
        "name": "Craig Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "SUR",
        "lon": "-88.5475",
        "iso": "CA",
        "status": 1,
        "name": "Summer Beaver Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.71528",
        "size": "small"
    },
    {
        "iata": "YAX",
        "lon": "-89.565",
        "iso": "CA",
        "status": 1,
        "name": "Wapekeka Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.251945",
        "size": "small"
    },
    {
        "iata": "WNN",
        "lon": "-89.166664",
        "iso": "CA",
        "status": 1,
        "name": "Wunnumin Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.916668",
        "size": "small"
    },
    {
        "iata": "YNO",
        "lon": "-92.416664",
        "iso": "CA",
        "status": 1,
        "name": "North Spirit Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.5",
        "size": "small"
    },
    {
        "iata": "CLG",
        "iso": "US",
        "status": 0,
        "name": "Coalinga Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "OHE",
        "lon": "122.42059",
        "iso": "CN",
        "status": 1,
        "name": "Gu-Lian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "52.92113",
        "size": "medium"
    },
    {
        "iata": "FUO",
        "lon": "113.28333",
        "iso": "CN",
        "status": 1,
        "name": "Foshan Shadi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.133333",
        "size": "medium"
    },
    {
        "iata": "HUZ",
        "lon": "114.36667",
        "iso": "CN",
        "status": 1,
        "name": "Huizhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.083332",
        "size": "medium"
    },
    {
        "iata": "JGS",
        "lon": "114.7375",
        "iso": "CN",
        "status": 1,
        "name": "Jinggangshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.899721",
        "size": "medium"
    },
    {
        "iata": "AEB",
        "lon": "106.96777",
        "iso": "CN",
        "status": 1,
        "name": "Tian Yang Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "23.71947",
        "size": "medium"
    },
    {
        "iata": "AAT",
        "lon": "88.08444",
        "iso": "CN",
        "status": 1,
        "name": "Altay Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "47.750362",
        "size": "medium"
    },
    {
        "iata": "YZY",
        "lon": "100.660835",
        "iso": "CN",
        "status": 1,
        "name": "Zhangye Southeast Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "38.8075",
        "size": "medium"
    },
    {
        "iata": "DDG",
        "lon": "124.28081",
        "iso": "CN",
        "status": 1,
        "name": "Dandong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.032368",
        "size": "medium"
    },
    {
        "iata": "NTG",
        "lon": "120.9756",
        "iso": "CN",
        "status": 1,
        "name": "Nantong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.07292",
        "size": "small"
    },
    {
        "iata": "XBE",
        "lon": "-90.98333",
        "iso": "CA",
        "status": 1,
        "name": "Bearskin Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.95",
        "size": "small"
    },
    {
        "iata": "KIF",
        "lon": "-89.85",
        "iso": "CA",
        "status": 1,
        "name": "Kingfisher Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.01389",
        "size": "small"
    },
    {
        "iata": "YOG",
        "lon": "-85.916664",
        "iso": "CA",
        "status": 1,
        "name": "Ogoki Post Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.666668",
        "size": "small"
    },
    {
        "iata": "PRM",
        "iso": "CO",
        "status": 1,
        "name": "Proma Heliport",
        "continent": "SA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "ISG",
        "iso": "CO",
        "status": 1,
        "name": "Isa Giron Heliport",
        "continent": "SA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "API",
        "lon": "-73.05194",
        "iso": "CO",
        "status": 1,
        "name": "Apiay Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.068889",
        "size": "small"
    },
    {
        "iata": "APO",
        "lon": "-76.71766",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "7.817678",
        "size": "small"
    },
    {
        "iata": "ARQ",
        "lon": "-71.43166",
        "iso": "CO",
        "status": 1,
        "name": "El Troncal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.033056",
        "size": "small"
    },
    {
        "iata": "LCR",
        "lon": "-73.01667",
        "iso": "CO",
        "status": 1,
        "name": "La Chorrera Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.733333",
        "size": "small"
    },
    {
        "iata": "SNT",
        "lon": "-73.0",
        "iso": "CO",
        "status": 1,
        "name": "Las Cruces Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.666667",
        "size": "small"
    },
    {
        "iata": "TCD",
        "lon": "-69.76667",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-2.933333",
        "size": "small"
    },
    {
        "iata": "YEB",
        "iso": "CA",
        "status": 1,
        "name": "Bar River Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "YHP",
        "lon": "-94.3",
        "iso": "CA",
        "status": 1,
        "name": "Poplar Hill Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.083332",
        "size": "small"
    },
    {
        "iata": "YNX",
        "iso": "CA",
        "status": 1,
        "name": "Snap Lake Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "YKU",
        "lon": "-78.924065",
        "iso": "CA",
        "status": 1,
        "name": "Chisasibi Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.806824",
        "size": "small"
    },
    {
        "iata": "ZTB",
        "lon": "-59.333332",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "50.716667",
        "size": "small"
    },
    {
        "iata": "YAU",
        "lon": "-73.31694",
        "iso": "CA",
        "status": 1,
        "name": "Donaldson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.662777",
        "size": "small"
    },
    {
        "iata": "TR7",
        "iso": "CA",
        "status": 1,
        "name": "Ottawa / Rockcliffe Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "ZLT",
        "lon": "-58.97601",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "50.828056",
        "size": "small"
    },
    {
        "iata": "PST",
        "lon": "-75.666664",
        "iso": "CU",
        "status": 1,
        "name": "Preston Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.75",
        "size": "small"
    },
    {
        "iata": "GEC",
        "lon": "33.716667",
        "iso": "CY",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "35.233334",
        "size": "medium"
    },
    {
        "iata": "NIC",
        "iso": "CY",
        "status": 0,
        "name": "Nicosia International Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YAC",
        "lon": "-91.816666",
        "iso": "CA",
        "status": 1,
        "name": "Cat Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.716667",
        "size": "medium"
    },
    {
        "iata": "YAG",
        "lon": "-93.44722",
        "iso": "CA",
        "status": 1,
        "name": "Fort Frances Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.65278",
        "size": "medium"
    },
    {
        "iata": "YAH",
        "lon": "-73.65",
        "iso": "CA",
        "status": 1,
        "name": "La Grande-4 Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.75",
        "size": "medium"
    },
    {
        "iata": "YAL",
        "lon": "-126.916664",
        "iso": "CA",
        "status": 1,
        "name": "Alert Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.583332",
        "size": "medium"
    },
    {
        "iata": "YAM",
        "lon": "-84.49995",
        "iso": "CA",
        "status": 1,
        "name": "Sault Ste Marie Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.485302",
        "size": "medium"
    },
    {
        "iata": "XKS",
        "lon": "-88.64667",
        "iso": "CA",
        "status": 1,
        "name": "Kasabonika Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.52",
        "size": "medium"
    },
    {
        "iata": "YKG",
        "lon": "-70.0046",
        "iso": "CA",
        "status": 1,
        "name": "Kangirsuk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.023083",
        "size": "medium"
    },
    {
        "iata": "YAT",
        "lon": "-82.4",
        "iso": "CA",
        "status": 1,
        "name": "Attawapiskat Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.941666",
        "size": "medium"
    },
    {
        "iata": "YAV",
        "lon": "-123.3",
        "iso": "CA",
        "status": 1,
        "name": "Winnipeg / St. Andrews Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.86667",
        "size": "medium"
    },
    {
        "iata": "YAW",
        "iso": "CA",
        "status": 1,
        "name": "Halifax / CFB Shearwater Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "YAX",
        "lon": "-89.565",
        "iso": "CA",
        "status": 1,
        "name": "Lac Du Bonnet Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.251945",
        "size": "medium"
    },
    {
        "iata": "YAY",
        "lon": "-55.583332",
        "iso": "CA",
        "status": 1,
        "name": "St Anthony Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.36667",
        "size": "medium"
    },
    {
        "iata": "YAZ",
        "lon": "-125.77639",
        "iso": "CA",
        "status": 1,
        "name": "Tofino / Long Beach Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.076942",
        "size": "medium"
    },
    {
        "iata": "YBB",
        "lon": "-89.85",
        "iso": "CA",
        "status": 1,
        "name": "Kugaaruk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.88333",
        "size": "medium"
    },
    {
        "iata": "YBC",
        "lon": "-68.26667",
        "iso": "CA",
        "status": 1,
        "name": "Baie Comeau Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.2",
        "size": "medium"
    },
    {
        "iata": "QBC",
        "lon": "-126.666664",
        "iso": "CA",
        "status": 1,
        "name": "Bella Coola Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.333332",
        "size": "medium"
    },
    {
        "iata": "YBE",
        "lon": "-108.48333",
        "iso": "CA",
        "status": 1,
        "name": "Uranium City Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.566666",
        "size": "medium"
    },
    {
        "iata": "YBY",
        "lon": "-110.73333",
        "iso": "CA",
        "status": 1,
        "name": "Bonnyville Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.266666",
        "size": "medium"
    },
    {
        "iata": "YBG",
        "lon": "-70.98861",
        "iso": "CA",
        "status": 1,
        "name": "CFB Bagotville",
        "continent": "NA",
        "type": "airport",
        "lat": "48.322224",
        "size": "medium"
    },
    {
        "iata": "YBK",
        "lon": "-96.083336",
        "iso": "CA",
        "status": 1,
        "name": "Baker Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.3",
        "size": "medium"
    },
    {
        "iata": "YBL",
        "lon": "-125.26813",
        "iso": "CA",
        "status": 1,
        "name": "Campbell River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.951725",
        "size": "medium"
    },
    {
        "iata": "YBN",
        "iso": "CA",
        "status": 1,
        "name": "CFB Borden",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "XTL",
        "lon": "-98.51344",
        "iso": "CA",
        "status": 1,
        "name": "Tadoule Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.707954",
        "size": "medium"
    },
    {
        "iata": "YBR",
        "lon": "-99.94677",
        "iso": "CA",
        "status": 1,
        "name": "Brandon Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.908688",
        "size": "medium"
    },
    {
        "iata": "YBT",
        "lon": "-101.67899",
        "iso": "CA",
        "status": 1,
        "name": "Brochet Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.88847",
        "size": "medium"
    },
    {
        "iata": "YBV",
        "lon": "-97.02141",
        "iso": "CA",
        "status": 1,
        "name": "Berens River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.358696",
        "size": "medium"
    },
    {
        "iata": "YBX",
        "lon": "-57.216667",
        "iso": "CA",
        "status": 1,
        "name": "Lourdes de Blanc Sablon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.433334",
        "size": "medium"
    },
    {
        "iata": "YRF",
        "lon": "-57.03828",
        "iso": "CA",
        "status": 1,
        "name": "Cartwright Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.682606",
        "size": "medium"
    },
    {
        "iata": "YCB",
        "lon": "-105.13333",
        "iso": "CA",
        "status": 1,
        "name": "Cambridge Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.1",
        "size": "medium"
    },
    {
        "iata": "YCC",
        "lon": "-74.566666",
        "iso": "CA",
        "status": 1,
        "name": "Cornwall Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.1",
        "size": "medium"
    },
    {
        "iata": "YCD",
        "lon": "-123.86667",
        "iso": "CA",
        "status": 1,
        "name": "Nanaimo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.05",
        "size": "medium"
    },
    {
        "iata": "YCE",
        "lon": "-81.48333",
        "iso": "CA",
        "status": 1,
        "name": "James T. Field Memorial Aerodrome",
        "continent": "NA",
        "type": "airport",
        "lat": "43.283333",
        "size": "medium"
    },
    {
        "iata": "YCG",
        "lon": "-117.632225",
        "iso": "CA",
        "status": 1,
        "name": "Castlegar/West Kootenay Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.295555",
        "size": "medium"
    },
    {
        "iata": "YCH",
        "lon": "-65.44744",
        "iso": "CA",
        "status": 1,
        "name": "Miramichi Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.014008",
        "size": "medium"
    },
    {
        "iata": "YCL",
        "lon": "-66.32881",
        "iso": "CA",
        "status": 1,
        "name": "Charlo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.99093",
        "size": "medium"
    },
    {
        "iata": "YCN",
        "lon": "-81.01289",
        "iso": "CA",
        "status": 1,
        "name": "Cochrane Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.106018",
        "size": "medium"
    },
    {
        "iata": "YCO",
        "lon": "-115.083336",
        "iso": "CA",
        "status": 1,
        "name": "Kugluktuk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.833336",
        "size": "medium"
    },
    {
        "iata": "YCQ",
        "lon": "-121.63333",
        "iso": "CA",
        "status": 1,
        "name": "Chetwynd Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.683334",
        "size": "medium"
    },
    {
        "iata": "YCR",
        "lon": "-97.75144",
        "iso": "CA",
        "status": 1,
        "name": "Cross Lake (Charlie Sinclair Memorial) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.61306",
        "size": "medium"
    },
    {
        "iata": "YCS",
        "lon": "-90.71667",
        "iso": "CA",
        "status": 1,
        "name": "Chesterfield Inlet Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.333332",
        "size": "medium"
    },
    {
        "iata": "YCV",
        "iso": "CA",
        "status": 0,
        "name": "Cartierville Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YCX",
        "iso": "CA",
        "status": 1,
        "name": "CFB Gagetown Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "YCY",
        "lon": "-68.5",
        "iso": "CA",
        "status": 1,
        "name": "Clyde River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.416664",
        "size": "medium"
    },
    {
        "iata": "YCZ",
        "lon": "-115.87361",
        "iso": "CA",
        "status": 1,
        "name": "Fairmont Hot Springs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.331944",
        "size": "small"
    },
    {
        "iata": "YDA",
        "lon": "-139.12083",
        "iso": "CA",
        "status": 1,
        "name": "Dawson City Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.041664",
        "size": "medium"
    },
    {
        "iata": "YDB",
        "lon": "-139.01666",
        "iso": "CA",
        "status": 1,
        "name": "Burwash Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.35",
        "size": "medium"
    },
    {
        "iata": "YDF",
        "lon": "-57.399727",
        "iso": "CA",
        "status": 1,
        "name": "Deer Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.21027",
        "size": "medium"
    },
    {
        "iata": "YDM",
        "lon": "138.8216",
        "iso": "CA",
        "status": 1,
        "name": "Ross River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "-17.939201",
        "size": "medium"
    },
    {
        "iata": "YDN",
        "lon": "-100.05",
        "iso": "CA",
        "status": 1,
        "name": "Dauphin Barker Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.1",
        "size": "medium"
    },
    {
        "iata": "YDO",
        "lon": "-72.23333",
        "iso": "CA",
        "status": 1,
        "name": "Dolbeau St Felicien Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.86667",
        "size": "medium"
    },
    {
        "iata": "YDP",
        "lon": "-61.666668",
        "iso": "CA",
        "status": 1,
        "name": "Nain Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.533333",
        "size": "medium"
    },
    {
        "iata": "YDQ",
        "lon": "-120.183334",
        "iso": "CA",
        "status": 1,
        "name": "Dawson Creek Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.733334",
        "size": "medium"
    },
    {
        "iata": "YED",
        "iso": "CA",
        "status": 1,
        "name": "Edmonton (CFB Namao) Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "YEG",
        "lon": "-113.584045",
        "iso": "CA",
        "status": 1,
        "name": "Edmonton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.307377",
        "size": "large"
    },
    {
        "iata": "YEK",
        "lon": "-94.05",
        "iso": "CA",
        "status": 1,
        "name": "Arviat Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.11667",
        "size": "medium"
    },
    {
        "iata": "YEL",
        "lon": "-82.56306",
        "iso": "CA",
        "status": 1,
        "name": "Elliot Lake Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.352222",
        "size": "medium"
    },
    {
        "iata": "YEM",
        "lon": "-81.858055",
        "iso": "CA",
        "status": 1,
        "name": "Manitoulin East Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.842777",
        "size": "medium"
    },
    {
        "iata": "YEN",
        "lon": "-102.96282",
        "iso": "CA",
        "status": 1,
        "name": "Estevan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.207542",
        "size": "medium"
    },
    {
        "iata": "YER",
        "lon": "-87.833336",
        "iso": "CA",
        "status": 1,
        "name": "Fort Severn Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.033333",
        "size": "medium"
    },
    {
        "iata": "YET",
        "lon": "-116.46667",
        "iso": "CA",
        "status": 1,
        "name": "Edson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.583332",
        "size": "medium"
    },
    {
        "iata": "YEU",
        "lon": "-85.933334",
        "iso": "CA",
        "status": 1,
        "name": "Eureka Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "80.0",
        "size": "small"
    },
    {
        "iata": "YEV",
        "lon": "-133.49767",
        "iso": "CA",
        "status": 1,
        "name": "Inuvik Mike Zubko Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.30665",
        "size": "medium"
    },
    {
        "iata": "YEY",
        "lon": "-78.25",
        "iso": "CA",
        "status": 1,
        "name": "Amos Magny Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.552223",
        "size": "medium"
    },
    {
        "iata": "YFA",
        "lon": "-81.59167",
        "iso": "CA",
        "status": 1,
        "name": "Fort Albany Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.24167",
        "size": "medium"
    },
    {
        "iata": "YFB",
        "lon": "-68.53658",
        "iso": "CA",
        "status": 1,
        "name": "Iqaluit Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.75175",
        "size": "medium"
    },
    {
        "iata": "YFC",
        "lon": "-66.52978",
        "iso": "CA",
        "status": 1,
        "name": "Fredericton Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.87316",
        "size": "medium"
    },
    {
        "iata": "YFE",
        "lon": "-69.066666",
        "iso": "CA",
        "status": 1,
        "name": "Forestville Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.75",
        "size": "medium"
    },
    {
        "iata": "YFH",
        "lon": "-87.89796",
        "iso": "CA",
        "status": 1,
        "name": "Fort Hope Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.559345",
        "size": "medium"
    },
    {
        "iata": "YTM",
        "lon": "-74.75528",
        "iso": "CA",
        "status": 1,
        "name": "La Macaza / Mont-Tremblant International Inc Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.515556",
        "size": "medium"
    },
    {
        "iata": "YFO",
        "lon": "-101.683334",
        "iso": "CA",
        "status": 1,
        "name": "Flin Flon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.683334",
        "size": "medium"
    },
    {
        "iata": "YFR",
        "lon": "-113.683334",
        "iso": "CA",
        "status": 1,
        "name": "Fort Resolution Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.183334",
        "size": "medium"
    },
    {
        "iata": "YFS",
        "lon": "-121.23333",
        "iso": "CA",
        "status": 1,
        "name": "Fort Simpson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.75",
        "size": "medium"
    },
    {
        "iata": "YMN",
        "lon": "-59.166668",
        "iso": "CA",
        "status": 1,
        "name": "Makkovik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.166668",
        "size": "medium"
    },
    {
        "iata": "YGB",
        "lon": "-124.53333",
        "iso": "CA",
        "status": 1,
        "name": "Texada Gillies Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.7",
        "size": "medium"
    },
    {
        "iata": "YGD",
        "iso": "CA",
        "status": 1,
        "name": "Goderich Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "YGH",
        "lon": "-128.65",
        "iso": "CA",
        "status": 1,
        "name": "Fort Good Hope Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.26667",
        "size": "medium"
    },
    {
        "iata": "YGK",
        "lon": "-76.59366",
        "iso": "CA",
        "status": 1,
        "name": "Kingston Norman Rogers Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.219513",
        "size": "medium"
    },
    {
        "iata": "YGL",
        "lon": "-77.70639",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "53.629723",
        "size": "medium"
    },
    {
        "iata": "YGM",
        "lon": "-97.0",
        "iso": "CA",
        "status": 1,
        "name": "Gimli Industrial Park Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.666668",
        "size": "medium"
    },
    {
        "iata": "YGO",
        "lon": "-94.49117",
        "iso": "CA",
        "status": 1,
        "name": "Gods Lake Narrows Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.5577",
        "size": "medium"
    },
    {
        "iata": "YGP",
        "lon": "-64.48333",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "48.766666",
        "size": "medium"
    },
    {
        "iata": "YGQ",
        "lon": "-86.93593",
        "iso": "CA",
        "status": 1,
        "name": "Geraldton Greenstone Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.779892",
        "size": "medium"
    },
    {
        "iata": "YGR",
        "lon": "-61.9",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "47.36667",
        "size": "medium"
    },
    {
        "iata": "YGT",
        "lon": "-81.816666",
        "iso": "CA",
        "status": 1,
        "name": "Igloolik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.4",
        "size": "medium"
    },
    {
        "iata": "YGV",
        "lon": "-63.583332",
        "iso": "CA",
        "status": 1,
        "name": "Havre St Pierre Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.25",
        "size": "medium"
    },
    {
        "iata": "YGW",
        "lon": "-77.76667",
        "iso": "CA",
        "status": 1,
        "name": "Kuujjuarapik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.279167",
        "size": "medium"
    },
    {
        "iata": "YGX",
        "lon": "-94.7",
        "iso": "CA",
        "status": 1,
        "name": "Gillam Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.35",
        "size": "medium"
    },
    {
        "iata": "YGZ",
        "lon": "-82.95722",
        "iso": "CA",
        "status": 1,
        "name": "Grise Fiord Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "76.41778",
        "size": "medium"
    },
    {
        "iata": "YQC",
        "lon": "-69.63333",
        "iso": "CA",
        "status": 1,
        "name": "Quaqtaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.333332",
        "size": "medium"
    },
    {
        "iata": "CXH",
        "iso": "CA",
        "status": 1,
        "name": "Vancouver Harbour Water Aerodrome",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YHD",
        "lon": "-92.833336",
        "iso": "CA",
        "status": 1,
        "name": "Dryden Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.783333",
        "size": "medium"
    },
    {
        "iata": "YHE",
        "lon": "-121.416664",
        "iso": "CA",
        "status": 1,
        "name": "Hope Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.416668",
        "size": "medium"
    },
    {
        "iata": "YHF",
        "lon": "-83.683334",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "49.716667",
        "size": "medium"
    },
    {
        "iata": "YNS",
        "lon": "-76.74167",
        "iso": "CA",
        "status": 1,
        "name": "Nemiscau Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.74167",
        "size": "medium"
    },
    {
        "iata": "YHI",
        "lon": "-117.71667",
        "iso": "CA",
        "status": 1,
        "name": "Ulukhaktok Holman Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.71667",
        "size": "medium"
    },
    {
        "iata": "YHK",
        "lon": "-95.95",
        "iso": "CA",
        "status": 1,
        "name": "Gjoa Haven Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.63333",
        "size": "medium"
    },
    {
        "iata": "YHM",
        "lon": "-79.92631",
        "iso": "CA",
        "status": 1,
        "name": "John C. Munro Hamilton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.16138",
        "size": "medium"
    },
    {
        "iata": "YHN",
        "lon": "-84.78333",
        "iso": "CA",
        "status": 1,
        "name": "Hornepayne Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.216667",
        "size": "medium"
    },
    {
        "iata": "YHO",
        "lon": "-60.229443",
        "iso": "CA",
        "status": 1,
        "name": "Hopedale Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.448334",
        "size": "medium"
    },
    {
        "iata": "YHR",
        "lon": "-59.5",
        "iso": "CA",
        "status": 1,
        "name": "Chevery Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.5",
        "size": "medium"
    },
    {
        "iata": "YHT",
        "lon": "-137.5",
        "iso": "CA",
        "status": 1,
        "name": "Haines Junction Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.75",
        "size": "medium"
    },
    {
        "iata": "YHU",
        "lon": "-73.6",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "45.5",
        "size": "medium"
    },
    {
        "iata": "YHY",
        "lon": "-115.778336",
        "iso": "CA",
        "status": 1,
        "name": "Hay River / Merlyn Carter Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.845554",
        "size": "medium"
    },
    {
        "iata": "YHZ",
        "lon": "-63.51425",
        "iso": "CA",
        "status": 1,
        "name": "Halifax / Stanfield International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.88496",
        "size": "large"
    },
    {
        "iata": "YIB",
        "lon": "-91.61667",
        "iso": "CA",
        "status": 1,
        "name": "Atikokan Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.75",
        "size": "medium"
    },
    {
        "iata": "YDG",
        "lon": "-65.76667",
        "iso": "CA",
        "status": 1,
        "name": "Digby / Annapolis Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.61667",
        "size": "medium"
    },
    {
        "iata": "YIF",
        "lon": "-58.666668",
        "iso": "CA",
        "status": 1,
        "name": "St Augustin Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.216667",
        "size": "medium"
    },
    {
        "iata": "YIK",
        "lon": "-76.083336",
        "iso": "CA",
        "status": 1,
        "name": "Ivujivik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.416668",
        "size": "medium"
    },
    {
        "iata": "YIO",
        "lon": "-78.0",
        "iso": "CA",
        "status": 1,
        "name": "Pond Inlet Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "72.683334",
        "size": "medium"
    },
    {
        "iata": "YIV",
        "lon": "-94.653046",
        "iso": "CA",
        "status": 1,
        "name": "Island Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.856785",
        "size": "medium"
    },
    {
        "iata": "YJF",
        "lon": "-123.46667",
        "iso": "CA",
        "status": 1,
        "name": "Fort Liard Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.25",
        "size": "medium"
    },
    {
        "iata": "YJN",
        "lon": "-73.333336",
        "iso": "CA",
        "status": 1,
        "name": "St Jean Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.333332",
        "size": "medium"
    },
    {
        "iata": "YJT",
        "lon": "-58.55",
        "iso": "CA",
        "status": 1,
        "name": "Stephenville Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.533333",
        "size": "medium"
    },
    {
        "iata": "YKA",
        "lon": "-120.442024",
        "iso": "CA",
        "status": 1,
        "name": "Kamloops Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.70545",
        "size": "medium"
    },
    {
        "iata": "LAK",
        "lon": "-134.98584",
        "iso": "CA",
        "status": 1,
        "name": "Aklavik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.22222",
        "size": "medium"
    },
    {
        "iata": "YKF",
        "lon": "-80.385056",
        "iso": "CA",
        "status": 1,
        "name": "Waterloo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.458977",
        "size": "medium"
    },
    {
        "iata": "YKJ",
        "lon": "-105.61864",
        "iso": "CA",
        "status": 1,
        "name": "Key Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.256287",
        "size": "medium"
    },
    {
        "iata": "YKL",
        "lon": "-64.816666",
        "iso": "CA",
        "status": 1,
        "name": "Schefferville Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.783333",
        "size": "medium"
    },
    {
        "iata": "AKV",
        "lon": "-78.583336",
        "iso": "CA",
        "status": 1,
        "name": "Akulivik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.733334",
        "size": "medium"
    },
    {
        "iata": "YKQ",
        "lon": "-78.75",
        "iso": "CA",
        "status": 1,
        "name": "Waskaganish Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.4875",
        "size": "medium"
    },
    {
        "iata": "YKX",
        "lon": "-80.03333",
        "iso": "CA",
        "status": 1,
        "name": "Kirkland Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.15",
        "size": "medium"
    },
    {
        "iata": "YKY",
        "lon": "-109.17811",
        "iso": "CA",
        "status": 1,
        "name": "Kindersley Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.517605",
        "size": "medium"
    },
    {
        "iata": "YKZ",
        "lon": "-79.36774",
        "iso": "CA",
        "status": 1,
        "name": "Buttonville Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.86131",
        "size": "medium"
    },
    {
        "iata": "YPJ",
        "lon": "-69.666664",
        "iso": "CA",
        "status": 1,
        "name": "Aupaluk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.3",
        "size": "medium"
    },
    {
        "iata": "YLC",
        "lon": "-69.88333",
        "iso": "CA",
        "status": 1,
        "name": "Kimmirut Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.85",
        "size": "medium"
    },
    {
        "iata": "YLD",
        "lon": "-83.352776",
        "iso": "CA",
        "status": 1,
        "name": "Chapleau Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.819443",
        "size": "medium"
    },
    {
        "iata": "YLH",
        "lon": "-87.93",
        "iso": "CA",
        "status": 1,
        "name": "Lansdowne House Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.183334",
        "size": "medium"
    },
    {
        "iata": "YLJ",
        "lon": "-108.666664",
        "iso": "CA",
        "status": 1,
        "name": "Meadow Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.5",
        "size": "medium"
    },
    {
        "iata": "YSG",
        "lon": "-110.075554",
        "iso": "CA",
        "status": 1,
        "name": "Lutselk'e Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.41861",
        "size": "medium"
    },
    {
        "iata": "YLL",
        "lon": "-110.072716",
        "iso": "CA",
        "status": 1,
        "name": "Lloydminster Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.31297",
        "size": "medium"
    },
    {
        "iata": "YLR",
        "lon": "-100.03333",
        "iso": "CA",
        "status": 1,
        "name": "Leaf Rapids Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.5",
        "size": "medium"
    },
    {
        "iata": "YLT",
        "lon": "-62.283333",
        "iso": "CA",
        "status": 1,
        "name": "Alert Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "82.51667",
        "size": "medium"
    },
    {
        "iata": "XGR",
        "lon": "-65.98333",
        "iso": "CA",
        "status": 1,
        "name": "Kangiqsualujjuaq (Georges River) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.5",
        "size": "medium"
    },
    {
        "iata": "YLW",
        "lon": "-119.38139",
        "iso": "CA",
        "status": 1,
        "name": "Kelowna Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.95154",
        "size": "medium"
    },
    {
        "iata": "YMA",
        "lon": "-135.87361",
        "iso": "CA",
        "status": 1,
        "name": "Mayo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.61639",
        "size": "medium"
    },
    {
        "iata": "YME",
        "lon": "-67.51667",
        "iso": "CA",
        "status": 1,
        "name": "Matane Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.833332",
        "size": "medium"
    },
    {
        "iata": "YMG",
        "lon": "-85.86667",
        "iso": "CA",
        "status": 1,
        "name": "Manitouwadge Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.083332",
        "size": "medium"
    },
    {
        "iata": "YMH",
        "lon": "-55.833332",
        "iso": "CA",
        "status": 1,
        "name": "Mary's Harbour Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.3",
        "size": "medium"
    },
    {
        "iata": "YMJ",
        "lon": "-105.53333",
        "iso": "CA",
        "status": 1,
        "name": "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.38333",
        "size": "medium"
    },
    {
        "iata": "YML",
        "lon": "-70.166664",
        "iso": "CA",
        "status": 1,
        "name": "Charlevoix Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.65",
        "size": "medium"
    },
    {
        "iata": "YMM",
        "lon": "-111.22395",
        "iso": "CA",
        "status": 1,
        "name": "Fort McMurray Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.65659",
        "size": "medium"
    },
    {
        "iata": "YMO",
        "lon": "-80.604164",
        "iso": "CA",
        "status": 1,
        "name": "Moosonee Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.29111",
        "size": "medium"
    },
    {
        "iata": "YMT",
        "lon": "-74.36667",
        "iso": "CA",
        "status": 1,
        "name": "Chapais Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.916668",
        "size": "medium"
    },
    {
        "iata": "YUD",
        "lon": "-76.51833",
        "iso": "CA",
        "status": 1,
        "name": "Umiujaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.53611",
        "size": "medium"
    },
    {
        "iata": "YMX",
        "lon": "-74.03",
        "iso": "CA",
        "status": 1,
        "name": "Montreal International (Mirabel) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.666668",
        "size": "medium"
    },
    {
        "iata": "YNA",
        "lon": "-61.8",
        "iso": "CA",
        "status": 1,
        "name": "Natashquan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.183334",
        "size": "medium"
    },
    {
        "iata": "YNC",
        "lon": "-78.816666",
        "iso": "CA",
        "status": 1,
        "name": "Wemindji Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.0",
        "size": "medium"
    },
    {
        "iata": "YND",
        "lon": "-75.56283",
        "iso": "CA",
        "status": 1,
        "name": "Ottawa / Gatineau Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.52063",
        "size": "medium"
    },
    {
        "iata": "YNE",
        "lon": "-97.84583",
        "iso": "CA",
        "status": 1,
        "name": "Norway House Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.954166",
        "size": "medium"
    },
    {
        "iata": "YNL",
        "lon": "-104.08111",
        "iso": "CA",
        "status": 1,
        "name": "Points North Landing Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.275",
        "size": "medium"
    },
    {
        "iata": "YNM",
        "lon": "-77.8",
        "iso": "CA",
        "status": 1,
        "name": "Matagami Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.75",
        "size": "medium"
    },
    {
        "iata": "YNN",
        "lon": "119.23333",
        "iso": "CA",
        "status": 1,
        "name": "Nejanilini Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "-22.766666",
        "size": "medium"
    },
    {
        "iata": "HZP",
        "lon": "-111.70111",
        "iso": "CA",
        "status": 1,
        "name": "Fort Mackay / Horizon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.381668",
        "size": "small"
    },
    {
        "iata": "YOA",
        "lon": "-110.61444",
        "iso": "CA",
        "status": 1,
        "name": "Ekati Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.69945",
        "size": "medium"
    },
    {
        "iata": "YOC",
        "lon": "-139.83333",
        "iso": "CA",
        "status": 1,
        "name": "Old Crow Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.583336",
        "size": "medium"
    },
    {
        "iata": "YOD",
        "lon": "-110.25",
        "iso": "CA",
        "status": 1,
        "name": "CFB Cold Lake",
        "continent": "NA",
        "type": "airport",
        "lat": "54.466667",
        "size": "medium"
    },
    {
        "iata": "YOH",
        "lon": "-95.27668",
        "iso": "CA",
        "status": 1,
        "name": "Oxford House Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.934242",
        "size": "medium"
    },
    {
        "iata": "YOJ",
        "lon": "-117.168335",
        "iso": "CA",
        "status": 1,
        "name": "High Level Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.618332",
        "size": "medium"
    },
    {
        "iata": "YOO",
        "lon": "-78.9",
        "iso": "CA",
        "status": 1,
        "name": "Oshawa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.916668",
        "size": "medium"
    },
    {
        "iata": "YOP",
        "lon": "-119.4",
        "iso": "CA",
        "status": 1,
        "name": "Rainbow Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.5",
        "size": "medium"
    },
    {
        "iata": "YOS",
        "lon": "-80.8375",
        "iso": "CA",
        "status": 1,
        "name": "Owen Sound / Billy Bishop Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.59028",
        "size": "medium"
    },
    {
        "iata": "YOW",
        "lon": "-75.672775",
        "iso": "CA",
        "status": 1,
        "name": "Ottawa Macdonald-Cartier International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.32083",
        "size": "large"
    },
    {
        "iata": "YOY",
        "iso": "CA",
        "status": 1,
        "name": "C J.H.L.(Joe) Lecomte) Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "YPA",
        "lon": "-105.683334",
        "iso": "CA",
        "status": 1,
        "name": "Prince Albert Glass Field",
        "continent": "NA",
        "type": "airport",
        "lat": "53.216667",
        "size": "medium"
    },
    {
        "iata": "YPC",
        "lon": "-124.07468",
        "iso": "CA",
        "status": 1,
        "name": "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.35514",
        "size": "medium"
    },
    {
        "iata": "YPS",
        "lon": "-61.35",
        "iso": "CA",
        "status": 1,
        "name": "Port Hawkesbury Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.61667",
        "size": "medium"
    },
    {
        "iata": "YPE",
        "lon": "-117.44028",
        "iso": "CA",
        "status": 1,
        "name": "Peace River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.22778",
        "size": "medium"
    },
    {
        "iata": "YPG",
        "lon": "-98.3",
        "iso": "CA",
        "status": 1,
        "name": "Southport Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.966667",
        "size": "medium"
    },
    {
        "iata": "YPH",
        "lon": "-78.166664",
        "iso": "CA",
        "status": 1,
        "name": "Inukjuak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.433334",
        "size": "medium"
    },
    {
        "iata": "YPL",
        "lon": "-90.208336",
        "iso": "CA",
        "status": 1,
        "name": "Pickle Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.208332",
        "size": "medium"
    },
    {
        "iata": "YPM",
        "lon": "-93.97287",
        "iso": "CA",
        "status": 1,
        "name": "Pikangikum Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.817375",
        "size": "medium"
    },
    {
        "iata": "YPN",
        "lon": "-64.28831",
        "iso": "CA",
        "status": 1,
        "name": "Port Menier Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.836533",
        "size": "medium"
    },
    {
        "iata": "YPO",
        "lon": "-85.433334",
        "iso": "CA",
        "status": 1,
        "name": "Peawanuck Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.983334",
        "size": "medium"
    },
    {
        "iata": "YPQ",
        "lon": "-78.316666",
        "iso": "CA",
        "status": 1,
        "name": "Peterborough Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.3",
        "size": "medium"
    },
    {
        "iata": "YPR",
        "lon": "-130.44083",
        "iso": "CA",
        "status": 1,
        "name": "Prince Rupert Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.287224",
        "size": "medium"
    },
    {
        "iata": "YPW",
        "lon": "-124.5",
        "iso": "CA",
        "status": 1,
        "name": "Powell River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.816666",
        "size": "medium"
    },
    {
        "iata": "YPX",
        "lon": "-77.166664",
        "iso": "CA",
        "status": 1,
        "name": "Puvirnituq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.033333",
        "size": "medium"
    },
    {
        "iata": "YPY",
        "lon": "-111.11406",
        "iso": "CA",
        "status": 1,
        "name": "Fort Chipewyan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.76799",
        "size": "medium"
    },
    {
        "iata": "YQA",
        "lon": "-79.3",
        "iso": "CA",
        "status": 1,
        "name": "Muskoka Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.966667",
        "size": "medium"
    },
    {
        "iata": "YQB",
        "lon": "-71.383385",
        "iso": "CA",
        "status": 1,
        "name": "Quebec Jean Lesage International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.792038",
        "size": "large"
    },
    {
        "iata": "YQD",
        "lon": "-101.1",
        "iso": "CA",
        "status": 1,
        "name": "The Pas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.966667",
        "size": "medium"
    },
    {
        "iata": "YQF",
        "lon": "-113.89333",
        "iso": "CA",
        "status": 1,
        "name": "Red Deer Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.176945",
        "size": "medium"
    },
    {
        "iata": "YQG",
        "lon": "-82.96389",
        "iso": "CA",
        "status": 1,
        "name": "Windsor Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.274166",
        "size": "medium"
    },
    {
        "iata": "YQH",
        "lon": "-128.825",
        "iso": "CA",
        "status": 1,
        "name": "Watson Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.12361",
        "size": "medium"
    },
    {
        "iata": "YQI",
        "lon": "-66.083336",
        "iso": "CA",
        "status": 1,
        "name": "Yarmouth Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.833332",
        "size": "medium"
    },
    {
        "iata": "YQK",
        "lon": "-94.35833",
        "iso": "CA",
        "status": 1,
        "name": "Kenora Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.7875",
        "size": "medium"
    },
    {
        "iata": "YQL",
        "lon": "-112.791664",
        "iso": "CA",
        "status": 1,
        "name": "Lethbridge County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.631943",
        "size": "medium"
    },
    {
        "iata": "YQM",
        "lon": "-64.68123",
        "iso": "CA",
        "status": 1,
        "name": "Greater Moncton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.104774",
        "size": "medium"
    },
    {
        "iata": "YQN",
        "lon": "-86.7",
        "iso": "CA",
        "status": 1,
        "name": "Nakina Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.166668",
        "size": "medium"
    },
    {
        "iata": "YQQ",
        "lon": "-124.907814",
        "iso": "CA",
        "status": 1,
        "name": "Comox Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.705864",
        "size": "medium"
    },
    {
        "iata": "YQR",
        "lon": "-104.65519",
        "iso": "CA",
        "status": 1,
        "name": "Regina International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.433327",
        "size": "medium"
    },
    {
        "iata": "YQS",
        "lon": "-81.2",
        "iso": "CA",
        "status": 1,
        "name": "St Thomas Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.783333",
        "size": "medium"
    },
    {
        "iata": "YQT",
        "lon": "-89.31167",
        "iso": "CA",
        "status": 1,
        "name": "Thunder Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.37184",
        "size": "medium"
    },
    {
        "iata": "YQU",
        "lon": "-118.873604",
        "iso": "CA",
        "status": 1,
        "name": "Grande Prairie Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.177074",
        "size": "medium"
    },
    {
        "iata": "YQV",
        "lon": "-102.45935",
        "iso": "CA",
        "status": 1,
        "name": "Yorkton Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.264095",
        "size": "medium"
    },
    {
        "iata": "YQW",
        "lon": "-108.25",
        "iso": "CA",
        "status": 1,
        "name": "North Battleford Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.766666",
        "size": "medium"
    },
    {
        "iata": "YQX",
        "lon": "-54.56333",
        "iso": "CA",
        "status": 1,
        "name": "Gander International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.946667",
        "size": "medium"
    },
    {
        "iata": "YQY",
        "lon": "-60.05",
        "iso": "CA",
        "status": 1,
        "name": "Sydney / J.A. Douglas McCurdy Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.166668",
        "size": "medium"
    },
    {
        "iata": "YQZ",
        "lon": "-122.50972",
        "iso": "CA",
        "status": 1,
        "name": "Quesnel Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.02667",
        "size": "medium"
    },
    {
        "iata": "YRA",
        "lon": "-117.35",
        "iso": "CA",
        "status": 1,
        "name": "Rae Lakes Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.11667",
        "size": "medium"
    },
    {
        "iata": "YRB",
        "lon": "-94.96667",
        "iso": "CA",
        "status": 1,
        "name": "Resolute Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "74.71667",
        "size": "medium"
    },
    {
        "iata": "YRI",
        "lon": "-69.53333",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "47.833332",
        "size": "medium"
    },
    {
        "iata": "YRJ",
        "lon": "-72.26733",
        "iso": "CA",
        "status": 1,
        "name": "Roberval Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.522343",
        "size": "medium"
    },
    {
        "iata": "YRL",
        "lon": "-93.80006",
        "iso": "CA",
        "status": 1,
        "name": "Red Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.068764",
        "size": "medium"
    },
    {
        "iata": "YRO",
        "lon": "-75.76459",
        "iso": "CA",
        "status": 1,
        "name": "Ottawa / Rockcliffe Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.4592",
        "size": "medium"
    },
    {
        "iata": "YRP",
        "iso": "CA",
        "status": 1,
        "name": "Ottawa / Carp Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "YRQ",
        "lon": "-72.683334",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "46.35",
        "size": "medium"
    },
    {
        "iata": "YRS",
        "lon": "-93.55762",
        "iso": "CA",
        "status": 1,
        "name": "Red Sucker Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.16757",
        "size": "medium"
    },
    {
        "iata": "YRT",
        "lon": "-92.10019",
        "iso": "CA",
        "status": 1,
        "name": "Rankin Inlet Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.80998",
        "size": "medium"
    },
    {
        "iata": "YRV",
        "lon": "-118.166664",
        "iso": "CA",
        "status": 1,
        "name": "Revelstoke Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.0",
        "size": "medium"
    },
    {
        "iata": "YSB",
        "lon": "-80.79592",
        "iso": "CA",
        "status": 1,
        "name": "Sudbury Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.62296",
        "size": "medium"
    },
    {
        "iata": "YSC",
        "lon": "-71.9",
        "iso": "CA",
        "status": 1,
        "name": "Sherbrooke Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.416668",
        "size": "medium"
    },
    {
        "iata": "YSD",
        "iso": "CA",
        "status": 1,
        "name": "Suffield Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "YSF",
        "lon": "-105.83657",
        "iso": "CA",
        "status": 1,
        "name": "Stony Rapids Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.251163",
        "size": "medium"
    },
    {
        "iata": "YSH",
        "lon": "-75.93959",
        "iso": "CA",
        "status": 1,
        "name": "Smiths Falls-Montague (Russ Beach) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.94829",
        "size": "medium"
    },
    {
        "iata": "YSJ",
        "lon": "-65.8902",
        "iso": "CA",
        "status": 1,
        "name": "Saint John Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.32948",
        "size": "medium"
    },
    {
        "iata": "YSK",
        "lon": "-79.21667",
        "iso": "CA",
        "status": 1,
        "name": "Sanikiluaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.55",
        "size": "medium"
    },
    {
        "iata": "YSL",
        "lon": "-67.9",
        "iso": "CA",
        "status": 1,
        "name": "St Leonard Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.183334",
        "size": "medium"
    },
    {
        "iata": "YSM",
        "lon": "-111.96667",
        "iso": "CA",
        "status": 1,
        "name": "Fort Smith Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.016666",
        "size": "medium"
    },
    {
        "iata": "YCM",
        "lon": "-79.166664",
        "iso": "CA",
        "status": 1,
        "name": "Niagara District Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.2",
        "size": "medium"
    },
    {
        "iata": "YSP",
        "lon": "-86.35",
        "iso": "CA",
        "status": 1,
        "name": "Marathon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.75",
        "size": "medium"
    },
    {
        "iata": "YSR",
        "iso": "CA",
        "status": 0,
        "name": "Nanisivik Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YST",
        "lon": "-94.8516",
        "iso": "CA",
        "status": 1,
        "name": "St. Theresa Point Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.846127",
        "size": "medium"
    },
    {
        "iata": "YSU",
        "lon": "-57.983334",
        "iso": "CA",
        "status": 1,
        "name": "Summerside Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.0",
        "size": "medium"
    },
    {
        "iata": "YSY",
        "lon": "-125.24104",
        "iso": "CA",
        "status": 1,
        "name": "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "71.99145",
        "size": "medium"
    },
    {
        "iata": "YTA",
        "lon": "-77.24944",
        "iso": "CA",
        "status": 1,
        "name": "Pembroke Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.861668",
        "size": "medium"
    },
    {
        "iata": "YTE",
        "lon": "-76.53333",
        "iso": "CA",
        "status": 1,
        "name": "Cape Dorset Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.23333",
        "size": "medium"
    },
    {
        "iata": "YTF",
        "lon": "-71.642715",
        "iso": "CA",
        "status": 1,
        "name": "Alma Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.509655",
        "size": "medium"
    },
    {
        "iata": "YTH",
        "lon": "-97.8605",
        "iso": "CA",
        "status": 1,
        "name": "Thompson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.79753",
        "size": "medium"
    },
    {
        "iata": "YTL",
        "lon": "-89.88952",
        "iso": "CA",
        "status": 1,
        "name": "Big Trout Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.81594",
        "size": "medium"
    },
    {
        "iata": "YTQ",
        "lon": "-69.95633",
        "iso": "CA",
        "status": 1,
        "name": "Tasiujaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.6678",
        "size": "medium"
    },
    {
        "iata": "YTR",
        "lon": "-77.583336",
        "iso": "CA",
        "status": 1,
        "name": "CFB Trenton",
        "continent": "NA",
        "type": "airport",
        "lat": "44.1",
        "size": "medium"
    },
    {
        "iata": "YTS",
        "lon": "-81.37164",
        "iso": "CA",
        "status": 1,
        "name": "Timmins/Victor M. Power",
        "continent": "NA",
        "type": "airport",
        "lat": "48.566372",
        "size": "medium"
    },
    {
        "iata": "YTZ",
        "lon": "-79.39585",
        "iso": "CA",
        "status": 1,
        "name": "Billy Bishop Toronto City Centre Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.632023",
        "size": "medium"
    },
    {
        "iata": "YUB",
        "lon": "-133.025",
        "iso": "CA",
        "status": 1,
        "name": "Tuktoyaktuk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.436386",
        "size": "medium"
    },
    {
        "iata": "YUL",
        "lon": "-73.74991",
        "iso": "CA",
        "status": 1,
        "name": "Montreal / Pierre Elliott Trudeau International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.457714",
        "size": "large"
    },
    {
        "iata": "YUT",
        "lon": "-86.25",
        "iso": "CA",
        "status": 1,
        "name": "Repulse Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.53333",
        "size": "medium"
    },
    {
        "iata": "YUX",
        "lon": "-81.25",
        "iso": "CA",
        "status": 1,
        "name": "Hall Beach Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.78333",
        "size": "medium"
    },
    {
        "iata": "YUY",
        "lon": "-78.83056",
        "iso": "CA",
        "status": 1,
        "name": "Rouyn Noranda Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.205555",
        "size": "medium"
    },
    {
        "iata": "YVB",
        "lon": "-65.45808",
        "iso": "CA",
        "status": 1,
        "name": "Bonaventure Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.069084",
        "size": "medium"
    },
    {
        "iata": "YVC",
        "lon": "-105.26066",
        "iso": "CA",
        "status": 1,
        "name": "La Ronge Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.15126",
        "size": "medium"
    },
    {
        "iata": "YVD",
        "lon": "146.18889",
        "iso": "CA",
        "status": 1,
        "name": "Virden/R.J. (Bob) Andrew Field Regional Aerodrome",
        "continent": "NA",
        "type": "airport",
        "lat": "-7.55",
        "size": "medium"
    },
    {
        "iata": "YVE",
        "lon": "-119.333336",
        "iso": "CA",
        "status": 1,
        "name": "Vernon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.25",
        "size": "medium"
    },
    {
        "iata": "YVM",
        "lon": "-63.833332",
        "iso": "CA",
        "status": 1,
        "name": "Qikiqtarjuaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.583336",
        "size": "medium"
    },
    {
        "iata": "YVO",
        "lon": "-77.78858",
        "iso": "CA",
        "status": 1,
        "name": "Val-d'Or Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.052605",
        "size": "medium"
    },
    {
        "iata": "YVP",
        "lon": "-68.41798",
        "iso": "CA",
        "status": 1,
        "name": "Kuujjuaq Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.10024",
        "size": "medium"
    },
    {
        "iata": "YVQ",
        "lon": "-126.79337",
        "iso": "CA",
        "status": 1,
        "name": "Norman Wells Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "65.277855",
        "size": "medium"
    },
    {
        "iata": "YVR",
        "lon": "-123.17919",
        "iso": "CA",
        "status": 1,
        "name": "Vancouver International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.1947",
        "size": "large"
    },
    {
        "iata": "YVT",
        "lon": "-108.46667",
        "iso": "CA",
        "status": 1,
        "name": "Buffalo Narrows Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.86667",
        "size": "small"
    },
    {
        "iata": "YVV",
        "lon": "-81.166664",
        "iso": "CA",
        "status": 1,
        "name": "Wiarton Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.666668",
        "size": "medium"
    },
    {
        "iata": "YVZ",
        "lon": "-94.5",
        "iso": "CA",
        "status": 1,
        "name": "Deer Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.666668",
        "size": "medium"
    },
    {
        "iata": "YWA",
        "lon": "-77.28333",
        "iso": "CA",
        "status": 1,
        "name": "Petawawa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.9",
        "size": "medium"
    },
    {
        "iata": "YWG",
        "lon": "-97.23333",
        "iso": "CA",
        "status": 1,
        "name": "Winnipeg / James Armstrong Richardson International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.9",
        "size": "large"
    },
    {
        "iata": "YWH",
        "iso": "CA",
        "status": 1,
        "name": "Victoria Harbour Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "YWJ",
        "lon": "-123.5",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "65.166664",
        "size": "medium"
    },
    {
        "iata": "YWK",
        "lon": "-66.874",
        "iso": "CA",
        "status": 1,
        "name": "Wabush Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.926315",
        "size": "medium"
    },
    {
        "iata": "YWL",
        "lon": "-122.04444",
        "iso": "CA",
        "status": 1,
        "name": "Williams Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.184723",
        "size": "medium"
    },
    {
        "iata": "YWP",
        "lon": "-87.36889",
        "iso": "CA",
        "status": 1,
        "name": "Webequie Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.95972",
        "size": "medium"
    },
    {
        "iata": "YWY",
        "lon": "-123.438614",
        "iso": "CA",
        "status": 1,
        "name": "Wrigley Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.210556",
        "size": "medium"
    },
    {
        "iata": "YXC",
        "lon": "-115.787025",
        "iso": "CA",
        "status": 1,
        "name": "Cranbrook Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.61437",
        "size": "medium"
    },
    {
        "iata": "YXD",
        "lon": "-113.51667",
        "iso": "CA",
        "status": 1,
        "name": "Edmonton City Centre (Blatchford Field) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.566666",
        "size": "medium"
    },
    {
        "iata": "YXE",
        "lon": "-106.69037",
        "iso": "CA",
        "status": 1,
        "name": "Saskatoon John G. Diefenbaker International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.169704",
        "size": "medium"
    },
    {
        "iata": "YXH",
        "lon": "-110.72083",
        "iso": "CA",
        "status": 1,
        "name": "Medicine Hat Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.015835",
        "size": "medium"
    },
    {
        "iata": "YXI",
        "iso": "CA",
        "status": 0,
        "name": "Bonnechere Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YXJ",
        "lon": "-120.73437",
        "iso": "CA",
        "status": 1,
        "name": "Fort St John Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.247883",
        "size": "medium"
    },
    {
        "iata": "YXK",
        "lon": "-68.55",
        "iso": "CA",
        "status": 1,
        "name": "Rimouski Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.433334",
        "size": "medium"
    },
    {
        "iata": "YXL",
        "lon": "-91.903",
        "iso": "CA",
        "status": 1,
        "name": "Sioux Lookout Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.115208",
        "size": "medium"
    },
    {
        "iata": "YXN",
        "lon": "-92.6",
        "iso": "CA",
        "status": 1,
        "name": "Whale Cove Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.233334",
        "size": "medium"
    },
    {
        "iata": "YXP",
        "lon": "-65.73333",
        "iso": "CA",
        "status": 1,
        "name": "Pangnirtung Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.13333",
        "size": "medium"
    },
    {
        "iata": "YXQ",
        "lon": "-141.0",
        "iso": "CA",
        "status": 1,
        "name": "Beaver Creek Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.0",
        "size": "medium"
    },
    {
        "iata": "YXR",
        "lon": "-79.851944",
        "iso": "CA",
        "status": 1,
        "name": "Earlton (Timiskaming Regional) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.697224",
        "size": "medium"
    },
    {
        "iata": "YXS",
        "lon": "-122.67396",
        "iso": "CA",
        "status": 1,
        "name": "Prince George Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.88381",
        "size": "medium"
    },
    {
        "iata": "YXT",
        "lon": "-128.57445",
        "iso": "CA",
        "status": 1,
        "name": "Terrace Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.465557",
        "size": "medium"
    },
    {
        "iata": "YXU",
        "lon": "-81.14965",
        "iso": "CA",
        "status": 1,
        "name": "London Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.02802",
        "size": "large"
    },
    {
        "iata": "YXX",
        "lon": "-122.361946",
        "iso": "CA",
        "status": 1,
        "name": "Abbotsford Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.025555",
        "size": "medium"
    },
    {
        "iata": "YXY",
        "lon": "-135.06667",
        "iso": "CA",
        "status": 1,
        "name": "Whitehorse / Erik Nielsen International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.716667",
        "size": "medium"
    },
    {
        "iata": "YXZ",
        "lon": "-84.785835",
        "iso": "CA",
        "status": 1,
        "name": "Wawa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.9625",
        "size": "medium"
    },
    {
        "iata": "YYB",
        "lon": "-79.42725",
        "iso": "CA",
        "status": 1,
        "name": "North Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.356823",
        "size": "medium"
    },
    {
        "iata": "YYC",
        "lon": "-114.01055",
        "iso": "CA",
        "status": 1,
        "name": "Calgary International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.131393",
        "size": "large"
    },
    {
        "iata": "YYD",
        "lon": "-127.180275",
        "iso": "CA",
        "status": 1,
        "name": "Smithers Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.825558",
        "size": "medium"
    },
    {
        "iata": "YYE",
        "lon": "-122.583336",
        "iso": "CA",
        "status": 1,
        "name": "Fort Nelson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.833332",
        "size": "medium"
    },
    {
        "iata": "YYF",
        "lon": "-119.6",
        "iso": "CA",
        "status": 1,
        "name": "Penticton Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.46472",
        "size": "medium"
    },
    {
        "iata": "YYG",
        "lon": "-63.131737",
        "iso": "CA",
        "status": 1,
        "name": "Charlottetown Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.285896",
        "size": "medium"
    },
    {
        "iata": "YYH",
        "lon": "-93.51667",
        "iso": "CA",
        "status": 1,
        "name": "Taloyoak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.53333",
        "size": "medium"
    },
    {
        "iata": "YYJ",
        "lon": "-123.43096",
        "iso": "CA",
        "status": 1,
        "name": "Victoria International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.640266",
        "size": "large"
    },
    {
        "iata": "YYL",
        "lon": "-101.066666",
        "iso": "CA",
        "status": 1,
        "name": "Lynn Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.86667",
        "size": "medium"
    },
    {
        "iata": "YYN",
        "lon": "-107.833336",
        "iso": "CA",
        "status": 1,
        "name": "Swift Current Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.283333",
        "size": "medium"
    },
    {
        "iata": "YYQ",
        "lon": "-94.07449",
        "iso": "CA",
        "status": 1,
        "name": "Churchill Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.74819",
        "size": "medium"
    },
    {
        "iata": "YYR",
        "lon": "-60.4124",
        "iso": "CA",
        "status": 1,
        "name": "Goose Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.313534",
        "size": "medium"
    },
    {
        "iata": "YYT",
        "lon": "-52.74334",
        "iso": "CA",
        "status": 1,
        "name": "St Johns International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.61282",
        "size": "medium"
    },
    {
        "iata": "YYU",
        "lon": "-82.47083",
        "iso": "CA",
        "status": 1,
        "name": "Kapuskasing Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.4125",
        "size": "medium"
    },
    {
        "iata": "YYW",
        "lon": "-88.91173",
        "iso": "CA",
        "status": 1,
        "name": "Armstrong Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.29431",
        "size": "medium"
    },
    {
        "iata": "YYY",
        "lon": "-68.2",
        "iso": "CA",
        "status": 1,
        "name": "Mont Joli Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.6",
        "size": "medium"
    },
    {
        "iata": "YYZ",
        "lon": "-79.61146",
        "iso": "CA",
        "status": 1,
        "name": "Lester B. Pearson International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.681583",
        "size": "large"
    },
    {
        "iata": "YZD",
        "lon": "-79.46555",
        "iso": "CA",
        "status": 1,
        "name": "Downsview Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.74278",
        "size": "medium"
    },
    {
        "iata": "YZE",
        "lon": "-82.56778",
        "iso": "CA",
        "status": 1,
        "name": "Gore Bay Manitoulin Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.885277",
        "size": "medium"
    },
    {
        "iata": "YZF",
        "lon": "-114.4375",
        "iso": "CA",
        "status": 1,
        "name": "Yellowknife Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.470867",
        "size": "medium"
    },
    {
        "iata": "YZG",
        "lon": "-75.63333",
        "iso": "CA",
        "status": 1,
        "name": "Salluit Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.2",
        "size": "medium"
    },
    {
        "iata": "YZH",
        "lon": "-114.78333",
        "iso": "CA",
        "status": 1,
        "name": "Slave Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.3",
        "size": "medium"
    },
    {
        "iata": "YZP",
        "lon": "-131.81277",
        "iso": "CA",
        "status": 1,
        "name": "Sandspit Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.254444",
        "size": "medium"
    },
    {
        "iata": "YZR",
        "lon": "-82.31111",
        "iso": "CA",
        "status": 1,
        "name": "Chris Hadfield Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.997223",
        "size": "medium"
    },
    {
        "iata": "YZS",
        "lon": "-83.36667",
        "iso": "CA",
        "status": 1,
        "name": "Coral Harbour Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.2",
        "size": "medium"
    },
    {
        "iata": "YZT",
        "lon": "-127.36389",
        "iso": "CA",
        "status": 1,
        "name": "Port Hardy Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.680557",
        "size": "medium"
    },
    {
        "iata": "YZU",
        "lon": "-115.78333",
        "iso": "CA",
        "status": 1,
        "name": "Whitecourt Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.15",
        "size": "medium"
    },
    {
        "iata": "YZV",
        "lon": "-66.26404",
        "iso": "CA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "50.21751",
        "size": "medium"
    },
    {
        "iata": "YZW",
        "lon": "-132.71666",
        "iso": "CA",
        "status": 1,
        "name": "Teslin Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.166668",
        "size": "medium"
    },
    {
        "iata": "YZX",
        "lon": "-118.666664",
        "iso": "CA",
        "status": 1,
        "name": "CFB Greenwood",
        "continent": "NA",
        "type": "airport",
        "lat": "49.166668",
        "size": "medium"
    },
    {
        "iata": "YZY",
        "lon": "100.660835",
        "iso": "CA",
        "status": 1,
        "name": "Mackenzie Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.8075",
        "size": "medium"
    },
    {
        "iata": "ZAC",
        "lon": "-96.09192",
        "iso": "CA",
        "status": 1,
        "name": "York Landing Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.089516",
        "size": "medium"
    },
    {
        "iata": "YSN",
        "lon": "-119.23333",
        "iso": "CA",
        "status": 1,
        "name": "Salmon Arm Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "50.683334",
        "size": "medium"
    },
    {
        "iata": "YDT",
        "lon": "-123.183334",
        "iso": "CA",
        "status": 1,
        "name": "Burlington Executive",
        "continent": "NA",
        "type": "airport",
        "lat": "49.183334",
        "size": "small"
    },
    {
        "iata": "ZBD",
        "iso": "CA",
        "status": 1,
        "name": "Boundary Bay Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZBF",
        "lon": "-65.73944",
        "iso": "CA",
        "status": 1,
        "name": "Ilford Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.629166",
        "size": "medium"
    },
    {
        "iata": "ZBF",
        "lon": "-65.73944",
        "iso": "CA",
        "status": 1,
        "name": "Bathurst Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.629166",
        "size": "medium"
    },
    {
        "iata": "ZBM",
        "lon": "-72.833336",
        "iso": "CA",
        "status": 1,
        "name": "Bromont Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.316666",
        "size": "medium"
    },
    {
        "iata": "ZEE",
        "iso": "CA",
        "status": 1,
        "name": "Kelsey Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZEM",
        "lon": "-78.51667",
        "iso": "CA",
        "status": 1,
        "name": "Eastmain River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.25",
        "size": "medium"
    },
    {
        "iata": "ZFA",
        "lon": "-133.36667",
        "iso": "CA",
        "status": 1,
        "name": "Faro Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.216667",
        "size": "medium"
    },
    {
        "iata": "ZFD",
        "lon": "-107.18119",
        "iso": "CA",
        "status": 1,
        "name": "Fond-Du-Lac Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.33421",
        "size": "medium"
    },
    {
        "iata": "ZFG",
        "iso": "CA",
        "status": 1,
        "name": "Pukatawagan Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZFM",
        "lon": "-134.95277",
        "iso": "CA",
        "status": 1,
        "name": "Fort Mcpherson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.477776",
        "size": "medium"
    },
    {
        "iata": "ZFN",
        "lon": "-125.566666",
        "iso": "CA",
        "status": 1,
        "name": "Tulita Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.916664",
        "size": "medium"
    },
    {
        "iata": "ZGF",
        "lon": "-118.433334",
        "iso": "CA",
        "status": 1,
        "name": "Grand Forks Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.016666",
        "size": "medium"
    },
    {
        "iata": "ZGI",
        "lon": "-94.07965",
        "iso": "CA",
        "status": 1,
        "name": "Gods River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.83914",
        "size": "medium"
    },
    {
        "iata": "ZGR",
        "lon": "-95.46667",
        "iso": "CA",
        "status": 1,
        "name": "Little Grand Rapids Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.066666",
        "size": "medium"
    },
    {
        "iata": "ZJG",
        "lon": "-98.04611",
        "iso": "CA",
        "status": 1,
        "name": "Jenpeg Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.519444",
        "size": "medium"
    },
    {
        "iata": "ZJN",
        "lon": "-101.23333",
        "iso": "CA",
        "status": 1,
        "name": "Swan River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.11667",
        "size": "medium"
    },
    {
        "iata": "ZKE",
        "lon": "-81.333336",
        "iso": "CA",
        "status": 1,
        "name": "Kashechewan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.333332",
        "size": "medium"
    },
    {
        "iata": "YTD",
        "lon": "-97.7",
        "iso": "CA",
        "status": 1,
        "name": "Thicket Portage Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.316666",
        "size": "medium"
    },
    {
        "iata": "MSA",
        "lon": "-91.98333",
        "iso": "CA",
        "status": 1,
        "name": "Muskrat Dam Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.816666",
        "size": "medium"
    },
    {
        "iata": "PIW",
        "lon": "-97.333336",
        "iso": "CA",
        "status": 1,
        "name": "Pikwitonei Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.7",
        "size": "medium"
    },
    {
        "iata": "ZMT",
        "lon": "-132.15",
        "iso": "CA",
        "status": 1,
        "name": "Masset Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.016666",
        "size": "medium"
    },
    {
        "iata": "ZNG",
        "lon": "-97.26667",
        "iso": "CA",
        "status": 1,
        "name": "Poplar River Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.0",
        "size": "medium"
    },
    {
        "iata": "ZPB",
        "lon": "-92.195274",
        "iso": "CA",
        "status": 1,
        "name": "Sachigo Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.890556",
        "size": "medium"
    },
    {
        "iata": "ZRJ",
        "lon": "-91.316666",
        "iso": "CA",
        "status": 1,
        "name": "Round Lake (Weagamow Lake) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.95",
        "size": "medium"
    },
    {
        "iata": "ZSJ",
        "lon": "-93.23333",
        "iso": "CA",
        "status": 1,
        "name": "Sandy Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.033333",
        "size": "medium"
    },
    {
        "iata": "ZSN",
        "iso": "CA",
        "status": 1,
        "name": "South Indian Lake Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZSW",
        "iso": "CA",
        "status": 1,
        "name": "Prince Rupert/Seal Cove Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "ZTM",
        "lon": "-92.08035",
        "iso": "CA",
        "status": 1,
        "name": "Shamattawa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.858505",
        "size": "medium"
    },
    {
        "iata": "ZUC",
        "lon": "-91.833336",
        "iso": "CA",
        "status": 1,
        "name": "Ignace Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "49.5",
        "size": "medium"
    },
    {
        "iata": "ZUM",
        "lon": "-64.10917",
        "iso": "CA",
        "status": 1,
        "name": "Churchill Falls Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.56278",
        "size": "small"
    },
    {
        "iata": "ZWH",
        "iso": "CA",
        "status": 1,
        "name": "Lac Brochet Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZWL",
        "lon": "-103.1683",
        "iso": "CA",
        "status": 1,
        "name": "Wollaston Lake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.106743",
        "size": "medium"
    },
    {
        "iata": "QLD",
        "iso": "DZ",
        "status": 1,
        "name": "Blida Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "BUJ",
        "lon": "4.15",
        "iso": "DZ",
        "status": 1,
        "name": "Bou Saada Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.166668",
        "size": "medium"
    },
    {
        "iata": "BJA",
        "lon": "5.083333",
        "iso": "DZ",
        "status": 1,
        "name": "Soummam Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.75",
        "size": "medium"
    },
    {
        "iata": "ALG",
        "lon": "3.21167",
        "iso": "DZ",
        "status": 1,
        "name": "Houari Boumediene Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.70058",
        "size": "large"
    },
    {
        "iata": "DJG",
        "lon": "9.488889",
        "iso": "DZ",
        "status": 1,
        "name": "Djanet Inedbirene Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "24.465279",
        "size": "medium"
    },
    {
        "iata": "QFD",
        "iso": "DZ",
        "status": 1,
        "name": "Boufarik Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "VVZ",
        "lon": "8.55",
        "iso": "DZ",
        "status": 1,
        "name": "Illizi Takhamalt Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "26.533333",
        "size": "medium"
    },
    {
        "iata": "QSF",
        "lon": "5.331111",
        "iso": "DZ",
        "status": 1,
        "name": "Ain Arnat Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.18139",
        "size": "medium"
    },
    {
        "iata": "TMR",
        "lon": "5.459167",
        "iso": "DZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "22.814722",
        "size": "medium"
    },
    {
        "iata": "GJL",
        "lon": "5.883333",
        "iso": "DZ",
        "status": 1,
        "name": "Jijel Ferhat Abbas Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.8",
        "size": "medium"
    },
    {
        "iata": "MZW",
        "lon": "-0.242177",
        "iso": "DZ",
        "status": 1,
        "name": "Mecheria Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.5354",
        "size": "medium"
    },
    {
        "iata": "QZN",
        "lon": "0.533333",
        "iso": "DZ",
        "status": 1,
        "name": "Relizane Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.833332",
        "size": "medium"
    },
    {
        "iata": "AAE",
        "lon": "7.811857",
        "iso": "DZ",
        "status": 1,
        "name": "Annaba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.821392",
        "size": "medium"
    },
    {
        "iata": "CZL",
        "lon": "6.618425",
        "iso": "DZ",
        "status": 1,
        "name": "Mohamed Boudiaf International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.286476",
        "size": "medium"
    },
    {
        "iata": "TEE",
        "lon": "8.124067",
        "iso": "DZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "35.428593",
        "size": "medium"
    },
    {
        "iata": "BLJ",
        "lon": "6.183333",
        "iso": "DZ",
        "status": 1,
        "name": "Batna Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.566666",
        "size": "medium"
    },
    {
        "iata": "HRM",
        "lon": "3.2",
        "iso": "DZ",
        "status": 1,
        "name": "Hassi R'Mel Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.166668",
        "size": "medium"
    },
    {
        "iata": "TID",
        "lon": "1.46946",
        "iso": "DZ",
        "status": 1,
        "name": "Bou Chekif Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.343273",
        "size": "medium"
    },
    {
        "iata": "TIN",
        "lon": "-8.163246",
        "iso": "DZ",
        "status": 1,
        "name": "Tindouf Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.701162",
        "size": "medium"
    },
    {
        "iata": "QAS",
        "iso": "DZ",
        "status": 1,
        "name": "Ech Cheliff Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "TAF",
        "lon": "-0.610556",
        "iso": "DZ",
        "status": 1,
        "name": "Tafaraoui Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.627777",
        "size": "medium"
    },
    {
        "iata": "TLM",
        "lon": "-1.45",
        "iso": "DZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "35.016666",
        "size": "medium"
    },
    {
        "iata": "ORN",
        "lon": "-0.606108",
        "iso": "DZ",
        "status": 1,
        "name": "Es Senia Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.620018",
        "size": "medium"
    },
    {
        "iata": "CBH",
        "lon": "-2.259722",
        "iso": "DZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "31.647778",
        "size": "medium"
    },
    {
        "iata": "MUW",
        "lon": "0.147669",
        "iso": "DZ",
        "status": 1,
        "name": "Ghriss Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.208534",
        "size": "medium"
    },
    {
        "iata": "EBH",
        "lon": "1.094105",
        "iso": "DZ",
        "status": 1,
        "name": "El Bayadh Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.717804",
        "size": "small"
    },
    {
        "iata": "INF",
        "lon": "5.866667",
        "iso": "DZ",
        "status": 1,
        "name": "In Guezzam Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "19.616667",
        "size": "small"
    },
    {
        "iata": "BMW",
        "lon": "0.926334",
        "iso": "DZ",
        "status": 1,
        "name": "Bordj Badji Mokhtar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "21.375816",
        "size": "small"
    },
    {
        "iata": "AZR",
        "lon": "-0.283333",
        "iso": "DZ",
        "status": 1,
        "name": "Touat Cheikh Sidi Mohamed Belkebir Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.883333",
        "size": "medium"
    },
    {
        "iata": "BSK",
        "lon": "5.738056",
        "iso": "DZ",
        "status": 1,
        "name": "Biskra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.79333",
        "size": "medium"
    },
    {
        "iata": "ELG",
        "lon": "2.864722",
        "iso": "DZ",
        "status": 1,
        "name": "El Golea Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.5675",
        "size": "medium"
    },
    {
        "iata": "GHA",
        "lon": "3.800556",
        "iso": "DZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "32.38222",
        "size": "medium"
    },
    {
        "iata": "HME",
        "lon": "6.145436",
        "iso": "DZ",
        "status": 1,
        "name": "Oued Irara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.675303",
        "size": "medium"
    },
    {
        "iata": "INZ",
        "lon": "2.516667",
        "iso": "DZ",
        "status": 1,
        "name": "In Salah Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.25",
        "size": "medium"
    },
    {
        "iata": "TGR",
        "lon": "6.089239",
        "iso": "DZ",
        "status": 1,
        "name": "Touggourt Sidi Madhi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.066948",
        "size": "medium"
    },
    {
        "iata": "LOO",
        "lon": "2.934811",
        "iso": "DZ",
        "status": 1,
        "name": "Laghouat Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.76102",
        "size": "medium"
    },
    {
        "iata": "ELU",
        "lon": "6.784614",
        "iso": "DZ",
        "status": 1,
        "name": "Guemar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.5095",
        "size": "medium"
    },
    {
        "iata": "TMX",
        "lon": "0.284086",
        "iso": "DZ",
        "status": 1,
        "name": "Timimoun Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "29.24174",
        "size": "medium"
    },
    {
        "iata": "OGX",
        "lon": "5.4",
        "iso": "DZ",
        "status": 1,
        "name": "Ain el Beida Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.916668",
        "size": "medium"
    },
    {
        "iata": "IAM",
        "lon": "9.638056",
        "iso": "DZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "28.051111",
        "size": "medium"
    },
    {
        "iata": "COO",
        "lon": "2.385271",
        "iso": "BJ",
        "status": 1,
        "name": "Cadjehoun Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.353097",
        "size": "medium"
    },
    {
        "iata": "KDC",
        "lon": "2.916667",
        "iso": "BJ",
        "status": 1,
        "name": "Kandi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.116667",
        "size": "small"
    },
    {
        "iata": "NAE",
        "lon": "1.366667",
        "iso": "BJ",
        "status": 1,
        "name": "Natitingou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.383333",
        "size": "small"
    },
    {
        "iata": "PKO",
        "lon": "2.616667",
        "iso": "BJ",
        "status": 1,
        "name": "Parakou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.35",
        "size": "small"
    },
    {
        "iata": "SVF",
        "lon": "2.483333",
        "iso": "BJ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "8.033333",
        "size": "small"
    },
    {
        "iata": "DCG",
        "iso": "AE",
        "status": 1,
        "name": "Dubai Creek SPB",
        "continent": "AS",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "XKY",
        "lon": "-1.083333",
        "iso": "BF",
        "status": 1,
        "name": "Kaya Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.083333",
        "size": "small"
    },
    {
        "iata": "OUG",
        "lon": "-2.333333",
        "iso": "BF",
        "status": 1,
        "name": "Ouahigouya Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.516667",
        "size": "small"
    },
    {
        "iata": "XDJ",
        "lon": "-1.633333",
        "iso": "BF",
        "status": 1,
        "name": "Djibo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.1",
        "size": "small"
    },
    {
        "iata": "XLU",
        "lon": "-2.1",
        "iso": "BF",
        "status": 1,
        "name": "Leo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.1",
        "size": "small"
    },
    {
        "iata": "PUP",
        "lon": "-1.166667",
        "iso": "BF",
        "status": 1,
        "name": "Po Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.183333",
        "size": "small"
    },
    {
        "iata": "XBO",
        "lon": "-0.566667",
        "iso": "BF",
        "status": 1,
        "name": "Boulsa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.65",
        "size": "small"
    },
    {
        "iata": "XBG",
        "lon": "-0.133333",
        "iso": "BF",
        "status": 1,
        "name": "Bogande Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.983333",
        "size": "small"
    },
    {
        "iata": "DIP",
        "lon": "2.033333",
        "iso": "BF",
        "status": 1,
        "name": "Diapaga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.033333",
        "size": "small"
    },
    {
        "iata": "DOR",
        "lon": "-0.033333",
        "iso": "BF",
        "status": 1,
        "name": "Dori Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.033333",
        "size": "small"
    },
    {
        "iata": "FNG",
        "lon": "0.35",
        "iso": "BF",
        "status": 1,
        "name": "Fada N'gourma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.066667",
        "size": "small"
    },
    {
        "iata": "XGG",
        "lon": "-0.233333",
        "iso": "BF",
        "status": 1,
        "name": "Gorom-Gorom Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.433333",
        "size": "small"
    },
    {
        "iata": "XKA",
        "lon": "1.516667",
        "iso": "BF",
        "status": 1,
        "name": "Kantchari Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.483333",
        "size": "small"
    },
    {
        "iata": "TMQ",
        "lon": "0.083333",
        "iso": "BF",
        "status": 1,
        "name": "Tambao Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.783333",
        "size": "small"
    },
    {
        "iata": "XPA",
        "lon": "0.7",
        "iso": "BF",
        "status": 1,
        "name": "Pama Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.25",
        "size": "small"
    },
    {
        "iata": "ARL",
        "lon": "1.483333",
        "iso": "BF",
        "status": 1,
        "name": "Arly Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.6",
        "size": "small"
    },
    {
        "iata": "XSE",
        "lon": "0.533333",
        "iso": "BF",
        "status": 1,
        "name": "Sebba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.433333",
        "size": "small"
    },
    {
        "iata": "TEG",
        "lon": "-0.316667",
        "iso": "BF",
        "status": 1,
        "name": "Tenkodogo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.9",
        "size": "small"
    },
    {
        "iata": "XZA",
        "lon": "-1.0",
        "iso": "BF",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "11.133333",
        "size": "small"
    },
    {
        "iata": "OUA",
        "lon": "-1.514283",
        "iso": "BF",
        "status": 1,
        "name": "Ouagadougou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.355019",
        "size": "medium"
    },
    {
        "iata": "BNR",
        "lon": "-4.666667",
        "iso": "BF",
        "status": 1,
        "name": "Banfora Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.666667",
        "size": "small"
    },
    {
        "iata": "DGU",
        "lon": "-3.483333",
        "iso": "BF",
        "status": 1,
        "name": "Dedougou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.466667",
        "size": "small"
    },
    {
        "iata": "XGA",
        "lon": "-3.183333",
        "iso": "BF",
        "status": 1,
        "name": "Gaoua Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.333333",
        "size": "small"
    },
    {
        "iata": "XNU",
        "lon": "-3.866667",
        "iso": "BF",
        "status": 1,
        "name": "Nouna Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.733333",
        "size": "small"
    },
    {
        "iata": "BOY",
        "lon": "-4.324444",
        "iso": "BF",
        "status": 1,
        "name": "Bobo Dioulasso Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.163611",
        "size": "medium"
    },
    {
        "iata": "TUQ",
        "lon": "-3.0",
        "iso": "BF",
        "status": 1,
        "name": "Tougan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.0",
        "size": "small"
    },
    {
        "iata": "XDE",
        "lon": "-3.25",
        "iso": "BF",
        "status": 1,
        "name": "Diebougou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.966667",
        "size": "small"
    },
    {
        "iata": "XAR",
        "lon": "-0.866667",
        "iso": "BF",
        "status": 1,
        "name": "Aribinda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.233333",
        "size": "small"
    },
    {
        "iata": "ACC",
        "lon": "-0.171769",
        "iso": "GH",
        "status": 1,
        "name": "Kotoka International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.60737",
        "size": "large"
    },
    {
        "iata": "TML",
        "lon": "-0.876603",
        "iso": "GH",
        "status": 1,
        "name": "Tamale Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.409979",
        "size": "medium"
    },
    {
        "iata": "KMS",
        "lon": "-1.591111",
        "iso": "GH",
        "status": 1,
        "name": "Kumasi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.7125",
        "size": "medium"
    },
    {
        "iata": "NYI",
        "lon": "-2.329254",
        "iso": "GH",
        "status": 1,
        "name": "Sunyani Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.360586",
        "size": "medium"
    },
    {
        "iata": "TKD",
        "lon": "-1.773912",
        "iso": "GH",
        "status": 1,
        "name": "Takoradi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.892727",
        "size": "medium"
    },
    {
        "iata": "DHB",
        "iso": "US",
        "status": 1,
        "name": "Deer Harbor SPB",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "ABJ",
        "lon": "-3.933015",
        "iso": "CI",
        "status": 1,
        "name": "Port Bouet Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.254879",
        "size": "medium"
    },
    {
        "iata": "OGO",
        "lon": "-3.45",
        "iso": "CI",
        "status": 1,
        "name": "Abengourou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.7",
        "size": "small"
    },
    {
        "iata": "BXI",
        "lon": "-6.5",
        "iso": "CI",
        "status": 1,
        "name": "Boundiali Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.75",
        "size": "small"
    },
    {
        "iata": "BYK",
        "lon": "-5.069167",
        "iso": "CI",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "7.739444",
        "size": "medium"
    },
    {
        "iata": "BQO",
        "lon": "-3.0",
        "iso": "CI",
        "status": 1,
        "name": "Bouna Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.266667",
        "size": "small"
    },
    {
        "iata": "BDK",
        "lon": "-2.8",
        "iso": "CI",
        "status": 1,
        "name": "Soko Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.033333",
        "size": "small"
    },
    {
        "iata": "DIM",
        "lon": "-4.766667",
        "iso": "CI",
        "status": 1,
        "name": "Dimbokro Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.75",
        "size": "small"
    },
    {
        "iata": "DJO",
        "lon": "-6.466667",
        "iso": "CI",
        "status": 1,
        "name": "Daloa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.866944",
        "size": "medium"
    },
    {
        "iata": "FEK",
        "lon": "-5.2",
        "iso": "CI",
        "status": 1,
        "name": "Ferkessedougou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.6",
        "size": "small"
    },
    {
        "iata": "GGN",
        "lon": "-5.933333",
        "iso": "CI",
        "status": 1,
        "name": "Gagnoa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.133333",
        "size": "small"
    },
    {
        "iata": "GGO",
        "lon": "-7.75",
        "iso": "CI",
        "status": 1,
        "name": "Guiglo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.333333",
        "size": "small"
    },
    {
        "iata": "HGO",
        "lon": "-5.616667",
        "iso": "CI",
        "status": 1,
        "name": "Korhogo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.413889",
        "size": "medium"
    },
    {
        "iata": "MJC",
        "lon": "-7.528333",
        "iso": "CI",
        "status": 1,
        "name": "Man Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.381667",
        "size": "medium"
    },
    {
        "iata": "KEO",
        "lon": "-7.570833",
        "iso": "CI",
        "status": 1,
        "name": "Odienne Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.49",
        "size": "small"
    },
    {
        "iata": "OFI",
        "lon": "-4.033333",
        "iso": "CI",
        "status": 1,
        "name": "Ouango Fitini Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.6",
        "size": "small"
    },
    {
        "iata": "SEO",
        "lon": "-6.666667",
        "iso": "CI",
        "status": 1,
        "name": "Seguela Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.966667",
        "size": "small"
    },
    {
        "iata": "SPY",
        "lon": "-6.626944",
        "iso": "CI",
        "status": 1,
        "name": "San Pedro Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.730278",
        "size": "medium"
    },
    {
        "iata": "ZSS",
        "lon": "-6.135556",
        "iso": "CI",
        "status": 1,
        "name": "Sassandra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.924444",
        "size": "small"
    },
    {
        "iata": "TXU",
        "lon": "-7.366667",
        "iso": "CI",
        "status": 1,
        "name": "Tabou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.433333",
        "size": "small"
    },
    {
        "iata": "ASK",
        "lon": "-5.283333",
        "iso": "CI",
        "status": 1,
        "name": "Yamoussoukro Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.816667",
        "size": "medium"
    },
    {
        "iata": "DKA",
        "lon": "7.660803",
        "iso": "NG",
        "status": 1,
        "name": "Katsina Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.003712",
        "size": "small"
    },
    {
        "iata": "ABV",
        "lon": "7.270447",
        "iso": "NG",
        "status": 1,
        "name": "Nnamdi Azikiwe International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.004614",
        "size": "large"
    },
    {
        "iata": "AKR",
        "lon": "5.083333",
        "iso": "NG",
        "status": 1,
        "name": "Akure Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.25",
        "size": "medium"
    },
    {
        "iata": "ABB",
        "lon": "6.667222",
        "iso": "NG",
        "status": 1,
        "name": "Asaba International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.203055",
        "size": "medium"
    },
    {
        "iata": "BNI",
        "lon": "5.603863",
        "iso": "NG",
        "status": 1,
        "name": "Benin Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.316943",
        "size": "medium"
    },
    {
        "iata": "CBQ",
        "lon": "8.347415",
        "iso": "NG",
        "status": 1,
        "name": "Margaret Ekpo International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.96889",
        "size": "medium"
    },
    {
        "iata": "ENU",
        "lon": "7.56772",
        "iso": "NG",
        "status": 1,
        "name": "Akanu Ibiam International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.472048",
        "size": "medium"
    },
    {
        "iata": "QUS",
        "iso": "NG",
        "status": 1,
        "name": "Gusau Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "IBA",
        "lon": "3.976361",
        "iso": "NG",
        "status": 1,
        "name": "Ibadan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.360181",
        "size": "medium"
    },
    {
        "iata": "ILR",
        "lon": "4.533333",
        "iso": "NG",
        "status": 1,
        "name": "Ilorin International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.5",
        "size": "medium"
    },
    {
        "iata": "QOW",
        "lon": "7.201676",
        "iso": "NG",
        "status": 1,
        "name": "Sam Mbakwe International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.427866",
        "size": "medium"
    },
    {
        "iata": "JOS",
        "lon": "8.893056",
        "iso": "NG",
        "status": 1,
        "name": "Yakubu Gowon Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.868056",
        "size": "medium"
    },
    {
        "iata": "KAD",
        "lon": "7.440278",
        "iso": "NG",
        "status": 1,
        "name": "Kaduna Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.595833",
        "size": "medium"
    },
    {
        "iata": "KAN",
        "lon": "8.522271",
        "iso": "NG",
        "status": 1,
        "name": "Mallam Aminu International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.045549",
        "size": "medium"
    },
    {
        "iata": "MIU",
        "lon": "13.082928",
        "iso": "NG",
        "status": 1,
        "name": "Maiduguri International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.855487",
        "size": "medium"
    },
    {
        "iata": "MDI",
        "lon": "8.533333",
        "iso": "NG",
        "status": 1,
        "name": "Makurdi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.75",
        "size": "medium"
    },
    {
        "iata": "LOS",
        "lon": "3.321178",
        "iso": "NG",
        "status": 1,
        "name": "Murtala Muhammed International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.577871",
        "size": "large"
    },
    {
        "iata": "MXJ",
        "lon": "6.533333",
        "iso": "NG",
        "status": 1,
        "name": "Minna Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.616667",
        "size": "medium"
    },
    {
        "iata": "PHC",
        "lon": "6.9499",
        "iso": "NG",
        "status": 1,
        "name": "Port Harcourt International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.006506",
        "size": "medium"
    },
    {
        "iata": "SKO",
        "lon": "5.242222",
        "iso": "NG",
        "status": 1,
        "name": "Sadiq Abubakar III International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.006389",
        "size": "medium"
    },
    {
        "iata": "YOL",
        "lon": "12.428114",
        "iso": "NG",
        "status": 1,
        "name": "Yola Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.266729",
        "size": "medium"
    },
    {
        "iata": "ZAR",
        "lon": "7.666667",
        "iso": "NG",
        "status": 1,
        "name": "Zaria Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.0",
        "size": "medium"
    },
    {
        "iata": "STI",
        "lon": "-70.603355",
        "iso": "DO",
        "status": 1,
        "name": "Santiago Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.404486",
        "size": "small"
    },
    {
        "iata": "DQA",
        "lon": "125.147156",
        "iso": "CN",
        "status": 1,
        "name": "Saertu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.75015",
        "size": "small"
    },
    {
        "iata": "MFQ",
        "lon": "7.125278",
        "iso": "NE",
        "status": 1,
        "name": "Maradi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.5025",
        "size": "medium"
    },
    {
        "iata": "NIM",
        "lon": "2.177158",
        "iso": "NE",
        "status": 1,
        "name": "Diori Hamani International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.476534",
        "size": "medium"
    },
    {
        "iata": "THZ",
        "lon": "5.263889",
        "iso": "NE",
        "status": 1,
        "name": "Tahoua Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.872778",
        "size": "medium"
    },
    {
        "iata": "AJY",
        "lon": "7.993056",
        "iso": "NE",
        "status": 1,
        "name": "Mano Dayak International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.964167",
        "size": "medium"
    },
    {
        "iata": "ZND",
        "lon": "8.983056",
        "iso": "NE",
        "status": 1,
        "name": "Zinder Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.779444",
        "size": "medium"
    },
    {
        "iata": "TBJ",
        "lon": "8.876389",
        "iso": "TN",
        "status": 1,
        "name": "Tabarka 7 Novembre Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.978333",
        "size": "medium"
    },
    {
        "iata": "MIR",
        "lon": "10.753155",
        "iso": "TN",
        "status": 1,
        "name": "Monastir Habib Bourguiba International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.76108",
        "size": "medium"
    },
    {
        "iata": "TUN",
        "lon": "10.21709",
        "iso": "TN",
        "status": 1,
        "name": "Tunis Carthage International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.847622",
        "size": "large"
    },
    {
        "iata": "OIZ",
        "iso": "TN",
        "status": 1,
        "name": "Sidi Ahmed Air Base",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "GAF",
        "lon": "8.816667",
        "iso": "TN",
        "status": 1,
        "name": "Gafsa Ksar International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.416668",
        "size": "medium"
    },
    {
        "iata": "GAE",
        "lon": "9.919377",
        "iso": "TN",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "33.73569",
        "size": "medium"
    },
    {
        "iata": "DJE",
        "lon": "10.775145",
        "iso": "TN",
        "status": 1,
        "name": "Djerba Zarzis International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.87118",
        "size": "medium"
    },
    {
        "iata": "EBM",
        "lon": "9.266667",
        "iso": "TN",
        "status": 1,
        "name": "El Borma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.716667",
        "size": "medium"
    },
    {
        "iata": "SFA",
        "lon": "10.683333",
        "iso": "TN",
        "status": 1,
        "name": "Sfax Thyna International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.716667",
        "size": "medium"
    },
    {
        "iata": "TOE",
        "lon": "8.133333",
        "iso": "TN",
        "status": 1,
        "name": "Tozeur Nefta International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.916668",
        "size": "medium"
    },
    {
        "iata": "DWR",
        "iso": "AF",
        "status": 1,
        "name": "Dywer Airbase",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LRL",
        "lon": "1.133333",
        "iso": "TG",
        "status": 1,
        "name": "Niamtougou International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.833333",
        "size": "medium"
    },
    {
        "iata": "LFW",
        "lon": "1.250347",
        "iso": "TG",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "6.167103",
        "size": "medium"
    },
    {
        "iata": "ANR",
        "lon": "4.450672",
        "iso": "BE",
        "status": 1,
        "name": "Antwerp International Airport (Deurne)",
        "continent": "EU",
        "type": "airport",
        "lat": "51.18916",
        "size": "medium"
    },
    {
        "iata": "BRU",
        "lon": "4.483602",
        "iso": "BE",
        "status": 1,
        "name": "Brussels Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.89717",
        "size": "large"
    },
    {
        "iata": "CRL",
        "lon": "4.451703",
        "iso": "BE",
        "status": 1,
        "name": "Brussels South Charleroi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.456696",
        "size": "medium"
    },
    {
        "iata": "KJK",
        "lon": "3.201667",
        "iso": "BE",
        "status": 1,
        "name": "Wevelgem Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.817223",
        "size": "medium"
    },
    {
        "iata": "LGG",
        "lon": "5.460149",
        "iso": "BE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "50.643333",
        "size": "large"
    },
    {
        "iata": "QNM",
        "iso": "BE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OST",
        "lon": "2.863611",
        "iso": "BE",
        "status": 1,
        "name": "Ostend-Bruges International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.199722",
        "size": "medium"
    },
    {
        "iata": "QHA",
        "iso": "BE",
        "status": 1,
        "name": "Hasselt Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OBL",
        "lon": "4.75",
        "iso": "BE",
        "status": 1,
        "name": "Oostmalle Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "51.266666",
        "size": "small"
    },
    {
        "iata": "MZD",
        "lon": "-78.316666",
        "iso": "EC",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-2.733333",
        "size": "small"
    },
    {
        "iata": "AOC",
        "lon": "12.45",
        "iso": "DE",
        "status": 1,
        "name": "Altenburg-Nobitz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.983334",
        "size": "medium"
    },
    {
        "iata": "HDF",
        "lon": "14.138242",
        "iso": "DE",
        "status": 1,
        "name": "Heringsdorf Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.87825",
        "size": "medium"
    },
    {
        "iata": "BBH",
        "lon": "12.711667",
        "iso": "DE",
        "status": 1,
        "name": "Barth Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.33972",
        "size": "small"
    },
    {
        "iata": "ZMG",
        "iso": "DE",
        "status": 1,
        "name": "Magdeburg Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "CBU",
        "lon": "14.299167",
        "iso": "DE",
        "status": 1,
        "name": "Cottbus-Drewitz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.77028",
        "size": "medium"
    },
    {
        "iata": "SXF",
        "lon": "13.521388",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "52.370277",
        "size": "large"
    },
    {
        "iata": "DRS",
        "lon": "13.766082",
        "iso": "DE",
        "status": 1,
        "name": "Dresden Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.124332",
        "size": "large"
    },
    {
        "iata": "ERF",
        "lon": "10.961163",
        "iso": "DE",
        "status": 1,
        "name": "Erfurt Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.974915",
        "size": "medium"
    },
    {
        "iata": "FRA",
        "lon": "8.570773",
        "iso": "DE",
        "status": 1,
        "name": "Frankfurt am Main International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.050735",
        "size": "large"
    },
    {
        "iata": "FMO",
        "lon": "7.694928",
        "iso": "DE",
        "status": 1,
        "name": "Muenster Osnabrueck Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.130054",
        "size": "large"
    },
    {
        "iata": "HAM",
        "lon": "10.006414",
        "iso": "DE",
        "status": 1,
        "name": "Hamburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.63128",
        "size": "large"
    },
    {
        "iata": "THF",
        "iso": "DE",
        "status": 0,
        "name": "Berlin-Tempelhof International Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "CGN",
        "lon": "7.122224",
        "iso": "DE",
        "status": 1,
        "name": "Cologne Bonn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.878365",
        "size": "large"
    },
    {
        "iata": "DUS",
        "lon": "6.76558",
        "iso": "DE",
        "status": 1,
        "name": "Dusseldorf International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.278328",
        "size": "large"
    },
    {
        "iata": "MUC",
        "lon": "11.790143",
        "iso": "DE",
        "status": 1,
        "name": "Munich International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.353004",
        "size": "large"
    },
    {
        "iata": "NUE",
        "lon": "11.077062",
        "iso": "DE",
        "status": 1,
        "name": "Nuremberg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.494167",
        "size": "large"
    },
    {
        "iata": "LEJ",
        "lon": "12.221202",
        "iso": "DE",
        "status": 1,
        "name": "Leipzig Halle Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.42026",
        "size": "large"
    },
    {
        "iata": "SCN",
        "lon": "7.112705",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.22009",
        "size": "medium"
    },
    {
        "iata": "STR",
        "lon": "9.193624",
        "iso": "DE",
        "status": 1,
        "name": "Stuttgart Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.69073",
        "size": "large"
    },
    {
        "iata": "TXL",
        "lon": "13.291722",
        "iso": "DE",
        "status": 1,
        "name": "Berlin-Tegel International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.553944",
        "size": "large"
    },
    {
        "iata": "HAJ",
        "lon": "9.694766",
        "iso": "DE",
        "status": 1,
        "name": "Hannover Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.459255",
        "size": "large"
    },
    {
        "iata": "BRE",
        "lon": "8.785352",
        "iso": "DE",
        "status": 1,
        "name": "Bremen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.05297",
        "size": "large"
    },
    {
        "iata": "QEF",
        "iso": "DE",
        "status": 1,
        "name": "Frankfurt-Egelsbach Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "HHN",
        "lon": "7.264167",
        "iso": "DE",
        "status": 1,
        "name": "Frankfurt-Hahn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.948334",
        "size": "medium"
    },
    {
        "iata": "MHG",
        "lon": "8.521081",
        "iso": "DE",
        "status": 1,
        "name": "Mannheim-City Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.476578",
        "size": "medium"
    },
    {
        "iata": "ZQV",
        "iso": "DE",
        "status": 1,
        "name": "Worms Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "QMZ",
        "iso": "DE",
        "status": 1,
        "name": "Mainz-Finthen Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SGE",
        "lon": "8.0",
        "iso": "DE",
        "status": 1,
        "name": "Siegerland Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.85",
        "size": "medium"
    },
    {
        "iata": "XFW",
        "lon": "9.83738",
        "iso": "DE",
        "status": 1,
        "name": "Hamburg-Finkenwerder Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.53596",
        "size": "medium"
    },
    {
        "iata": "KEL",
        "lon": "10.143333",
        "iso": "DE",
        "status": 1,
        "name": "Kiel-Holtenau Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.380833",
        "size": "medium"
    },
    {
        "iata": "LBC",
        "lon": "10.701162",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "53.80527",
        "size": "medium"
    },
    {
        "iata": "EUM",
        "lon": "9.966667",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "54.066666",
        "size": "small"
    },
    {
        "iata": "FMM",
        "lon": "10.239444",
        "iso": "DE",
        "status": 1,
        "name": "Memmingen Allgau Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.98861",
        "size": "medium"
    },
    {
        "iata": "AAH",
        "lon": "6.133333",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "50.75",
        "size": "small"
    },
    {
        "iata": "ESS",
        "lon": "6.941667",
        "iso": "DE",
        "status": 1,
        "name": "Essen Mulheim Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.40389",
        "size": "small"
    },
    {
        "iata": "BFE",
        "lon": "8.55",
        "iso": "DE",
        "status": 1,
        "name": "Bielefeld Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.016666",
        "size": "small"
    },
    {
        "iata": "MGL",
        "lon": "6.544444",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "51.35278",
        "size": "medium"
    },
    {
        "iata": "PAD",
        "lon": "8.619832",
        "iso": "DE",
        "status": 1,
        "name": "Paderborn Lippstadt Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.610332",
        "size": "medium"
    },
    {
        "iata": "NRN",
        "lon": "6.150168",
        "iso": "DE",
        "status": 1,
        "name": "Niederrhein Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.599285",
        "size": "medium"
    },
    {
        "iata": "DTM",
        "lon": "7.613139",
        "iso": "DE",
        "status": 1,
        "name": "Dortmund Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.514828",
        "size": "large"
    },
    {
        "iata": "AGB",
        "lon": "10.931667",
        "iso": "DE",
        "status": 1,
        "name": "Augsburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.425278",
        "size": "medium"
    },
    {
        "iata": "OBF",
        "lon": "11.283333",
        "iso": "DE",
        "status": 1,
        "name": "Oberpfaffenhofen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.083332",
        "size": "medium"
    },
    {
        "iata": "RBM",
        "lon": "12.519722",
        "iso": "DE",
        "status": 1,
        "name": "Straubing Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.901943",
        "size": "small"
    },
    {
        "iata": "FDH",
        "lon": "9.523482",
        "iso": "DE",
        "status": 1,
        "name": "Friedrichshafen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.672775",
        "size": "medium"
    },
    {
        "iata": "FRF",
        "lon": "8.683333",
        "iso": "DE",
        "status": 1,
        "name": "Oschersleben Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.1",
        "size": "small"
    },
    {
        "iata": "SZW",
        "lon": "11.790721",
        "iso": "DE",
        "status": 1,
        "name": "Schwerin Parchim Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.42529",
        "size": "medium"
    },
    {
        "iata": "BYU",
        "lon": "11.64",
        "iso": "DE",
        "status": 1,
        "name": "Bayreuth Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.985558",
        "size": "medium"
    },
    {
        "iata": "QOB",
        "iso": "DE",
        "status": 1,
        "name": "Ansbach-Petersdorf Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "HOQ",
        "lon": "11.862222",
        "iso": "DE",
        "status": 1,
        "name": "Hof-Plauen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.289165",
        "size": "medium"
    },
    {
        "iata": "BBJ",
        "lon": "6.516667",
        "iso": "DE",
        "status": 1,
        "name": "Bitburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.966667",
        "size": "small"
    },
    {
        "iata": "ZQW",
        "lon": "7.399708",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.21516",
        "size": "small"
    },
    {
        "iata": "FKB",
        "lon": "8.089752",
        "iso": "DE",
        "status": 1,
        "name": "Karlsruhe Baden-Baden Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.781033",
        "size": "large"
    },
    {
        "iata": "ZQL",
        "iso": "DE",
        "status": 1,
        "name": "Donaueschingen-Villingen Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "LHA",
        "lon": "7.883333",
        "iso": "DE",
        "status": 1,
        "name": "Lahr Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.333332",
        "size": "medium"
    },
    {
        "iata": "BWE",
        "lon": "10.533333",
        "iso": "DE",
        "status": 1,
        "name": "Braunschweig Wolfsburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.266666",
        "size": "medium"
    },
    {
        "iata": "KSF",
        "lon": "9.376578",
        "iso": "DE",
        "status": 1,
        "name": "Kassel-Calden Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.40568",
        "size": "medium"
    },
    {
        "iata": "BRV",
        "lon": "8.916667",
        "iso": "DE",
        "status": 1,
        "name": "Bremerhaven Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.75",
        "size": "small"
    },
    {
        "iata": "XLW",
        "lon": "8.6",
        "iso": "DE",
        "status": 1,
        "name": "Lemwerder Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.166668",
        "size": "small"
    },
    {
        "iata": "EME",
        "lon": "7.2",
        "iso": "DE",
        "status": 1,
        "name": "Emden Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.36667",
        "size": "small"
    },
    {
        "iata": "AGE",
        "lon": "7.916667",
        "iso": "DE",
        "status": 1,
        "name": "Wangerooge Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.783333",
        "size": "small"
    },
    {
        "iata": "WVN",
        "lon": "8.116667",
        "iso": "DE",
        "status": 1,
        "name": "Wilhelmshaven-Mariensiel Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.533333",
        "size": "small"
    },
    {
        "iata": "JUI",
        "lon": "7.066667",
        "iso": "DE",
        "status": 1,
        "name": "Juist Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.683334",
        "size": "small"
    },
    {
        "iata": "LGO",
        "lon": "7.533333",
        "iso": "DE",
        "status": 1,
        "name": "Langeoog Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.766666",
        "size": "small"
    },
    {
        "iata": "ZOW",
        "iso": "DE",
        "status": 1,
        "name": "Nordhorn-Lingen Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BMK",
        "lon": "6.7",
        "iso": "DE",
        "status": 1,
        "name": "Borkum Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.6",
        "size": "small"
    },
    {
        "iata": "NOD",
        "lon": "7.2",
        "iso": "DE",
        "status": 1,
        "name": "Norden-Norddeich Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.583332",
        "size": "small"
    },
    {
        "iata": "VAC",
        "lon": "8.05",
        "iso": "DE",
        "status": 1,
        "name": "Varrelbusch Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.916668",
        "size": "small"
    },
    {
        "iata": "NRD",
        "lon": "7.166667",
        "iso": "DE",
        "status": 1,
        "name": "Norderney Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.833332",
        "size": "small"
    },
    {
        "iata": "BMR",
        "lon": "7.4",
        "iso": "DE",
        "status": 1,
        "name": "Baltrum Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.716667",
        "size": "small"
    },
    {
        "iata": "HEI",
        "lon": "8.902778",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "54.155556",
        "size": "small"
    },
    {
        "iata": "FLF",
        "lon": "9.383333",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "54.77222",
        "size": "small"
    },
    {
        "iata": "HGL",
        "lon": "7.916667",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "54.186943",
        "size": "small"
    },
    {
        "iata": "PSH",
        "lon": "8.116667",
        "iso": "DE",
        "status": 1,
        "name": "St. Peter-Ording Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.5",
        "size": "small"
    },
    {
        "iata": "GWT",
        "lon": "8.343056",
        "iso": "DE",
        "status": 1,
        "name": "Westerland Sylt Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.91528",
        "size": "medium"
    },
    {
        "iata": "OHR",
        "lon": "8.529167",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "54.685833",
        "size": "small"
    },
    {
        "iata": "KDL",
        "lon": "22.8",
        "iso": "EE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.983334",
        "size": "medium"
    },
    {
        "iata": "URE",
        "lon": "22.5",
        "iso": "EE",
        "status": 1,
        "name": "Kuressaare Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.216667",
        "size": "medium"
    },
    {
        "iata": "EPU",
        "lon": "24.472778",
        "iso": "EE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.418888",
        "size": "medium"
    },
    {
        "iata": "TLL",
        "lon": "24.798702",
        "iso": "EE",
        "status": 1,
        "name": "Tallinn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.416622",
        "size": "large"
    },
    {
        "iata": "TAY",
        "lon": "26.733334",
        "iso": "EE",
        "status": 1,
        "name": "Tartu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.333332",
        "size": "medium"
    },
    {
        "iata": "ENF",
        "lon": "23.42208",
        "iso": "FI",
        "status": 1,
        "name": "Enontekio Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "68.36018",
        "size": "medium"
    },
    {
        "iata": "QVE",
        "iso": "FI",
        "status": 1,
        "name": "Forssa Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "KEV",
        "lon": "24.75",
        "iso": "FI",
        "status": 1,
        "name": "Halli Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.583332",
        "size": "medium"
    },
    {
        "iata": "HEM",
        "lon": "25.05",
        "iso": "FI",
        "status": 1,
        "name": "Helsinki Malmi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.25",
        "size": "medium"
    },
    {
        "iata": "HEL",
        "lon": "24.96645",
        "iso": "FI",
        "status": 1,
        "name": "Helsinki Vantaa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.31795",
        "size": "large"
    },
    {
        "iata": "HYV",
        "lon": "24.884443",
        "iso": "FI",
        "status": 1,
        "name": "Hyvinkaa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.654167",
        "size": "medium"
    },
    {
        "iata": "KTQ",
        "lon": "30.076668",
        "iso": "FI",
        "status": 1,
        "name": "Kitee Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.165833",
        "size": "medium"
    },
    {
        "iata": "IVL",
        "lon": "27.415556",
        "iso": "FI",
        "status": 1,
        "name": "Ivalo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "68.611115",
        "size": "medium"
    },
    {
        "iata": "JOE",
        "lon": "29.61354",
        "iso": "FI",
        "status": 1,
        "name": "Joensuu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.656788",
        "size": "medium"
    },
    {
        "iata": "JYV",
        "lon": "25.68143",
        "iso": "FI",
        "status": 1,
        "name": "Jyvaskyla Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.40362",
        "size": "medium"
    },
    {
        "iata": "KAU",
        "lon": "23.083332",
        "iso": "FI",
        "status": 1,
        "name": "Kauhava Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.1",
        "size": "medium"
    },
    {
        "iata": "KEM",
        "lon": "24.57677",
        "iso": "FI",
        "status": 1,
        "name": "Kemi-Tornio Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.78006",
        "size": "medium"
    },
    {
        "iata": "KAJ",
        "lon": "27.688889",
        "iso": "FI",
        "status": 1,
        "name": "Kajaani Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.27778",
        "size": "medium"
    },
    {
        "iata": "KHJ",
        "lon": "22.394722",
        "iso": "FI",
        "status": 1,
        "name": "Kauhajoki Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.463055",
        "size": "medium"
    },
    {
        "iata": "KOK",
        "lon": "23.133068",
        "iso": "FI",
        "status": 1,
        "name": "Kruunupyy Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.718838",
        "size": "medium"
    },
    {
        "iata": "KAO",
        "lon": "29.233889",
        "iso": "FI",
        "status": 1,
        "name": "Kuusamo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.99028",
        "size": "medium"
    },
    {
        "iata": "KTT",
        "lon": "24.859028",
        "iso": "FI",
        "status": 1,
        "name": "Kittila Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "67.695946",
        "size": "medium"
    },
    {
        "iata": "KUO",
        "lon": "27.788696",
        "iso": "FI",
        "status": 1,
        "name": "Kuopio Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.008907",
        "size": "medium"
    },
    {
        "iata": "QLF",
        "iso": "FI",
        "status": 1,
        "name": "Lahti Vesivehmaa Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LPP",
        "lon": "28.156668",
        "iso": "FI",
        "status": 1,
        "name": "Lappeenranta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.046112",
        "size": "medium"
    },
    {
        "iata": "MHQ",
        "lon": "19.896667",
        "iso": "FI",
        "status": 1,
        "name": "Mariehamn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.123333",
        "size": "medium"
    },
    {
        "iata": "MIK",
        "lon": "27.213057",
        "iso": "FI",
        "status": 1,
        "name": "Mikkeli Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.683887",
        "size": "medium"
    },
    {
        "iata": "OUL",
        "lon": "25.375425",
        "iso": "FI",
        "status": 1,
        "name": "Oulu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.93012",
        "size": "medium"
    },
    {
        "iata": "POR",
        "lon": "21.791382",
        "iso": "FI",
        "status": 1,
        "name": "Pori Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.46866",
        "size": "medium"
    },
    {
        "iata": "RVN",
        "lon": "25.829609",
        "iso": "FI",
        "status": 1,
        "name": "Rovaniemi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "66.559044",
        "size": "medium"
    },
    {
        "iata": "SVL",
        "lon": "28.95",
        "iso": "FI",
        "status": 1,
        "name": "Savonlinna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.95",
        "size": "medium"
    },
    {
        "iata": "SJY",
        "lon": "22.835285",
        "iso": "FI",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "62.6932",
        "size": "medium"
    },
    {
        "iata": "SOT",
        "lon": "26.616667",
        "iso": "FI",
        "status": 1,
        "name": "Sodankyla Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "67.38333",
        "size": "medium"
    },
    {
        "iata": "TMP",
        "lon": "23.617563",
        "iso": "FI",
        "status": 1,
        "name": "Tampere-Pirkkala Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.42045",
        "size": "medium"
    },
    {
        "iata": "TKU",
        "lon": "22.28098",
        "iso": "FI",
        "status": 1,
        "name": "Turku Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.512794",
        "size": "medium"
    },
    {
        "iata": "UTI",
        "lon": "26.933332",
        "iso": "FI",
        "status": 1,
        "name": "Utti Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "60.9",
        "size": "medium"
    },
    {
        "iata": "VAA",
        "lon": "21.760122",
        "iso": "FI",
        "status": 1,
        "name": "Vaasa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.04355",
        "size": "medium"
    },
    {
        "iata": "VRK",
        "lon": "27.933332",
        "iso": "FI",
        "status": 1,
        "name": "Varkaus Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.3",
        "size": "medium"
    },
    {
        "iata": "YLI",
        "lon": "24.72889",
        "iso": "FI",
        "status": 1,
        "name": "Ylivieska Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.05444",
        "size": "medium"
    },
    {
        "iata": "AUE",
        "lon": "33.183334",
        "iso": "EG",
        "status": 1,
        "name": "Abu Rudeis Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.9",
        "size": "small"
    },
    {
        "iata": "BFS",
        "lon": "-6.217616",
        "iso": "GB",
        "status": 1,
        "name": "Belfast International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.662395",
        "size": "large"
    },
    {
        "iata": "ENK",
        "lon": "-7.633333",
        "iso": "GB",
        "status": 1,
        "name": "St Angelo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.35",
        "size": "medium"
    },
    {
        "iata": "BHD",
        "lon": "-5.870215",
        "iso": "GB",
        "status": 1,
        "name": "George Best Belfast City Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.61452",
        "size": "large"
    },
    {
        "iata": "LDY",
        "lon": "-7.156924",
        "iso": "GB",
        "status": 1,
        "name": "City of Derry Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.040985",
        "size": "medium"
    },
    {
        "iata": "BHX",
        "lon": "-1.733256",
        "iso": "GB",
        "status": 1,
        "name": "Birmingham International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.45252",
        "size": "large"
    },
    {
        "iata": "CVT",
        "lon": "-1.478611",
        "iso": "GB",
        "status": 1,
        "name": "Coventry Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.369167",
        "size": "medium"
    },
    {
        "iata": "GLO",
        "lon": "-2.233333",
        "iso": "GB",
        "status": 1,
        "name": "Gloucestershire Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.88333",
        "size": "medium"
    },
    {
        "iata": "ORM",
        "lon": "-0.9",
        "iso": "GB",
        "status": 1,
        "name": "Sywell Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "52.233334",
        "size": "small"
    },
    {
        "iata": "NQT",
        "lon": "-1.166667",
        "iso": "GB",
        "status": 1,
        "name": "Nottingham Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.966667",
        "size": "medium"
    },
    {
        "iata": "MAN",
        "lon": "-2.273354",
        "iso": "GB",
        "status": 1,
        "name": "Manchester Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.362907",
        "size": "large"
    },
    {
        "iata": "XXB",
        "iso": "GB",
        "status": 1,
        "name": "Manchester Woodford Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "DSA",
        "lon": "-1.01155",
        "iso": "GB",
        "status": 1,
        "name": "Robin Hood Doncaster Sheffield Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.481003",
        "size": "large"
    },
    {
        "iata": "LYE",
        "lon": "-1.966667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Lyneham",
        "continent": "EU",
        "type": "airport",
        "lat": "51.516666",
        "size": "medium"
    },
    {
        "iata": "DGX",
        "iso": "GB",
        "status": 1,
        "name": "MOD St. Athan",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "YEO",
        "lon": "-2.55",
        "iso": "GB",
        "status": 1,
        "name": "RNAS Yeovilton",
        "continent": "EU",
        "type": "airport",
        "lat": "51.016666",
        "size": "medium"
    },
    {
        "iata": "CAL",
        "lon": "-5.686667",
        "iso": "GB",
        "status": 1,
        "name": "Campbeltown Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.43639",
        "size": "medium"
    },
    {
        "iata": "EOI",
        "lon": "-2.783333",
        "iso": "GB",
        "status": 1,
        "name": "Eday Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.183334",
        "size": "medium"
    },
    {
        "iata": "FIE",
        "lon": "-1.666667",
        "iso": "GB",
        "status": 1,
        "name": "Fair Isle Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.5",
        "size": "medium"
    },
    {
        "iata": "WHS",
        "lon": "-0.983333",
        "iso": "GB",
        "status": 1,
        "name": "Whalsay Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.36667",
        "size": "small"
    },
    {
        "iata": "COL",
        "lon": "-6.616667",
        "iso": "GB",
        "status": 1,
        "name": "Coll Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.61667",
        "size": "small"
    },
    {
        "iata": "NRL",
        "lon": "-2.433333",
        "iso": "GB",
        "status": 1,
        "name": "North Ronaldsay Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.370277",
        "size": "small"
    },
    {
        "iata": "OBN",
        "lon": "-5.396494",
        "iso": "GB",
        "status": 1,
        "name": "Oban Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.465275",
        "size": "small"
    },
    {
        "iata": "PPW",
        "lon": "-2.898974",
        "iso": "GB",
        "status": 1,
        "name": "Papa Westray Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.351265",
        "size": "small"
    },
    {
        "iata": "SOY",
        "lon": "-2.640495",
        "iso": "GB",
        "status": 1,
        "name": "Stronsay Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.154053",
        "size": "small"
    },
    {
        "iata": "NDY",
        "lon": "-2.583333",
        "iso": "GB",
        "status": 1,
        "name": "Sanday Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.25",
        "size": "small"
    },
    {
        "iata": "LWK",
        "lon": "-1.15",
        "iso": "GB",
        "status": 1,
        "name": "Lerwick / Tingwall Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.15",
        "size": "small"
    },
    {
        "iata": "WRY",
        "lon": "-2.95",
        "iso": "GB",
        "status": 1,
        "name": "Westray Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.35",
        "size": "small"
    },
    {
        "iata": "CSA",
        "lon": "-6.2",
        "iso": "GB",
        "status": 1,
        "name": "Colonsay Airstrip",
        "continent": "EU",
        "type": "airport",
        "lat": "56.083332",
        "size": "small"
    },
    {
        "iata": "HAW",
        "lon": "-4.966667",
        "iso": "GB",
        "status": 1,
        "name": "Haverfordwest Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.8",
        "size": "medium"
    },
    {
        "iata": "CWL",
        "lon": "-3.339075",
        "iso": "GB",
        "status": 1,
        "name": "Cardiff International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.39877",
        "size": "large"
    },
    {
        "iata": "SWS",
        "lon": "-3.95",
        "iso": "GB",
        "status": 1,
        "name": "Swansea Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.63333",
        "size": "medium"
    },
    {
        "iata": "BRS",
        "lon": "-2.710659",
        "iso": "GB",
        "status": 1,
        "name": "Bristol International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.386757",
        "size": "large"
    },
    {
        "iata": "LPL",
        "lon": "-2.854905",
        "iso": "GB",
        "status": 1,
        "name": "Liverpool John Lennon Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.337616",
        "size": "large"
    },
    {
        "iata": "LTN",
        "lon": "-0.376232",
        "iso": "GB",
        "status": 1,
        "name": "London Luton Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.87977",
        "size": "large"
    },
    {
        "iata": "LEQ",
        "lon": "-5.733333",
        "iso": "GB",
        "status": 1,
        "name": "Land's End Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.05",
        "size": "medium"
    },
    {
        "iata": "PLH",
        "lon": "-4.110009",
        "iso": "GB",
        "status": 1,
        "name": "Plymouth City Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.422337",
        "size": "medium"
    },
    {
        "iata": "ISC",
        "lon": "-6.291667",
        "iso": "GB",
        "status": 1,
        "name": "St. Mary's Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.913334",
        "size": "medium"
    },
    {
        "iata": "BOH",
        "lon": "-1.832476",
        "iso": "GB",
        "status": 1,
        "name": "Bournemouth Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.77827",
        "size": "large"
    },
    {
        "iata": "SOU",
        "lon": "-1.361318",
        "iso": "GB",
        "status": 1,
        "name": "Southampton Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.950726",
        "size": "large"
    },
    {
        "iata": "BBP",
        "lon": "-1.083333",
        "iso": "GB",
        "status": 1,
        "name": "Bembridge Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.683334",
        "size": "small"
    },
    {
        "iata": "PZE",
        "iso": "GB",
        "status": 1,
        "name": "Penzance Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QLA",
        "lon": "-1.157375",
        "iso": "GB",
        "status": 1,
        "name": "Lasham Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.21111",
        "size": "medium"
    },
    {
        "iata": "NQY",
        "lon": "-4.997922",
        "iso": "GB",
        "status": 1,
        "name": "Newquay Cornwall Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.43745",
        "size": "medium"
    },
    {
        "iata": "QUG",
        "lon": "-0.842248",
        "iso": "GB",
        "status": 1,
        "name": "Chichester/Goodwood Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.941666",
        "size": "small"
    },
    {
        "iata": "ACI",
        "lon": "-2.215369",
        "iso": "GG",
        "status": 1,
        "name": "Alderney Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.709347",
        "size": "medium"
    },
    {
        "iata": "GCI",
        "lon": "-2.595044",
        "iso": "GG",
        "status": 1,
        "name": "Guernsey Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.432755",
        "size": "medium"
    },
    {
        "iata": "JER",
        "lon": "-2.194344",
        "iso": "JE",
        "status": 1,
        "name": "Jersey Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.205296",
        "size": "medium"
    },
    {
        "iata": "ESH",
        "lon": "-0.3",
        "iso": "GB",
        "status": 1,
        "name": "Shoreham Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.833332",
        "size": "medium"
    },
    {
        "iata": "BQH",
        "lon": "0.033333",
        "iso": "GB",
        "status": 1,
        "name": "London Biggin Hill Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.333332",
        "size": "medium"
    },
    {
        "iata": "LGW",
        "lon": "-0.161863",
        "iso": "GB",
        "status": 1,
        "name": "London Gatwick Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.156807",
        "size": "large"
    },
    {
        "iata": "KRH",
        "lon": "-0.186211",
        "iso": "GB",
        "status": 1,
        "name": "Redhill Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "51.33611",
        "size": "small"
    },
    {
        "iata": "LCY",
        "lon": "0.05",
        "iso": "GB",
        "status": 1,
        "name": "London City Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.5",
        "size": "large"
    },
    {
        "iata": "FAB",
        "lon": "-0.766667",
        "iso": "GB",
        "status": 1,
        "name": "Farnborough Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.283333",
        "size": "medium"
    },
    {
        "iata": "BBS",
        "lon": "-0.85",
        "iso": "GB",
        "status": 1,
        "name": "Blackbushe Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.333332",
        "size": "medium"
    },
    {
        "iata": "LHR",
        "lon": "-0.453566",
        "iso": "GB",
        "status": 1,
        "name": "London Heathrow Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.469604",
        "size": "large"
    },
    {
        "iata": "SEN",
        "lon": "0.701389",
        "iso": "GB",
        "status": 1,
        "name": "Southend Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.572777",
        "size": "medium"
    },
    {
        "iata": "LYX",
        "lon": "0.938414",
        "iso": "GB",
        "status": 1,
        "name": "Lydd Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.955334",
        "size": "medium"
    },
    {
        "iata": "MSE",
        "lon": "1.35",
        "iso": "GB",
        "status": 1,
        "name": "Kent International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.35",
        "size": "medium"
    },
    {
        "iata": "CAX",
        "lon": "-2.809444",
        "iso": "GB",
        "status": 1,
        "name": "Carlisle Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.93667",
        "size": "medium"
    },
    {
        "iata": "BLK",
        "lon": "-3.041985",
        "iso": "GB",
        "status": 1,
        "name": "Blackpool International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.778385",
        "size": "medium"
    },
    {
        "iata": "HUY",
        "lon": "-0.34851",
        "iso": "GB",
        "status": 1,
        "name": "Humberside Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.583378",
        "size": "medium"
    },
    {
        "iata": "BWF",
        "lon": "-3.233333",
        "iso": "GB",
        "status": 1,
        "name": "Barrow Walney Island Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.11667",
        "size": "medium"
    },
    {
        "iata": "LBA",
        "lon": "-1.659985",
        "iso": "GB",
        "status": 1,
        "name": "Leeds Bradford Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.86934",
        "size": "large"
    },
    {
        "iata": "CEG",
        "lon": "-2.970718",
        "iso": "GB",
        "status": 1,
        "name": "Hawarden Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.178604",
        "size": "medium"
    },
    {
        "iata": "IOM",
        "lon": "-4.63473",
        "iso": "IM",
        "status": 1,
        "name": "Isle of Man Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.08677",
        "size": "medium"
    },
    {
        "iata": "NCL",
        "lon": "-1.710629",
        "iso": "GB",
        "status": 1,
        "name": "Newcastle Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.037064",
        "size": "large"
    },
    {
        "iata": "MME",
        "lon": "-1.434013",
        "iso": "GB",
        "status": 1,
        "name": "Durham Tees Valley Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.512226",
        "size": "medium"
    },
    {
        "iata": "EMA",
        "lon": "-1.330595",
        "iso": "GB",
        "status": 1,
        "name": "East Midlands Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.82587",
        "size": "large"
    },
    {
        "iata": "HLY",
        "lon": "-4.633333",
        "iso": "GB",
        "status": 1,
        "name": "Anglesey Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.3",
        "size": "medium"
    },
    {
        "iata": "KOI",
        "lon": "-2.900556",
        "iso": "GB",
        "status": 1,
        "name": "Kirkwall Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.958057",
        "size": "medium"
    },
    {
        "iata": "LSI",
        "lon": "-1.287111",
        "iso": "GB",
        "status": 1,
        "name": "Sumburgh Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.877888",
        "size": "medium"
    },
    {
        "iata": "WIC",
        "lon": "-3.086667",
        "iso": "GB",
        "status": 1,
        "name": "Wick Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.456944",
        "size": "medium"
    },
    {
        "iata": "ABZ",
        "lon": "-2.204186",
        "iso": "GB",
        "status": 1,
        "name": "Aberdeen Dyce Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.200253",
        "size": "large"
    },
    {
        "iata": "INV",
        "lon": "-4.063738",
        "iso": "GB",
        "status": 1,
        "name": "Inverness Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.539345",
        "size": "medium"
    },
    {
        "iata": "GLA",
        "lon": "-4.431782",
        "iso": "GB",
        "status": 1,
        "name": "Glasgow International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.864212",
        "size": "large"
    },
    {
        "iata": "EDI",
        "lon": "-3.364177",
        "iso": "GB",
        "status": 1,
        "name": "Edinburgh Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.948143",
        "size": "large"
    },
    {
        "iata": "ILY",
        "lon": "-6.254167",
        "iso": "GB",
        "status": 1,
        "name": "Islay Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.681946",
        "size": "medium"
    },
    {
        "iata": "PIK",
        "lon": "-4.611286",
        "iso": "GB",
        "status": 1,
        "name": "Glasgow Prestwick Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.50843",
        "size": "medium"
    },
    {
        "iata": "BEB",
        "lon": "-7.376028",
        "iso": "GB",
        "status": 1,
        "name": "Benbecula Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.473415",
        "size": "medium"
    },
    {
        "iata": "SCS",
        "lon": "-1.294972",
        "iso": "GB",
        "status": 1,
        "name": "Scatsta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.436825",
        "size": "medium"
    },
    {
        "iata": "DND",
        "lon": "-3.014531",
        "iso": "GB",
        "status": 1,
        "name": "Dundee Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.45409",
        "size": "medium"
    },
    {
        "iata": "SYY",
        "lon": "-6.321995",
        "iso": "GB",
        "status": 1,
        "name": "Stornoway Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.213627",
        "size": "medium"
    },
    {
        "iata": "BRR",
        "lon": "-7.440278",
        "iso": "GB",
        "status": 1,
        "name": "Barra Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.023335",
        "size": "medium"
    },
    {
        "iata": "PSL",
        "lon": "-3.566667",
        "iso": "GB",
        "status": 1,
        "name": "Perth/Scone Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.233334",
        "size": "small"
    },
    {
        "iata": "TRE",
        "lon": "-6.871389",
        "iso": "GB",
        "status": 1,
        "name": "Tiree Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.500557",
        "size": "medium"
    },
    {
        "iata": "UNT",
        "lon": "-0.85",
        "iso": "GB",
        "status": 1,
        "name": "Unst Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.766666",
        "size": "small"
    },
    {
        "iata": "BOL",
        "lon": "-7.0",
        "iso": "GB",
        "status": 1,
        "name": "Ballykelly Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.0",
        "size": "small"
    },
    {
        "iata": "FSS",
        "lon": "-3.566667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Kinloss",
        "continent": "EU",
        "type": "airport",
        "lat": "57.65",
        "size": "medium"
    },
    {
        "iata": "ADX",
        "lon": "-2.866667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Leuchars",
        "continent": "EU",
        "type": "airport",
        "lat": "56.36667",
        "size": "medium"
    },
    {
        "iata": "LMO",
        "lon": "-3.333333",
        "iso": "GB",
        "status": 1,
        "name": "RAF Lossiemouth",
        "continent": "EU",
        "type": "airport",
        "lat": "57.716667",
        "size": "medium"
    },
    {
        "iata": "CBG",
        "lon": "0.177738",
        "iso": "GB",
        "status": 1,
        "name": "Cambridge Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.208042",
        "size": "medium"
    },
    {
        "iata": "NWI",
        "lon": "1.276782",
        "iso": "GB",
        "status": 1,
        "name": "Norwich International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.669533",
        "size": "medium"
    },
    {
        "iata": "STN",
        "lon": "0.262703",
        "iso": "GB",
        "status": 1,
        "name": "London Stansted Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.889267",
        "size": "large"
    },
    {
        "iata": "QFO",
        "iso": "GB",
        "status": 1,
        "name": "Duxford Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SZE",
        "iso": "GB",
        "status": 1,
        "name": "Sheffield City Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "EXT",
        "lon": "-3.410968",
        "iso": "GB",
        "status": 1,
        "name": "Exeter International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.73111",
        "size": "large"
    },
    {
        "iata": "FRK",
        "lon": "55.966667",
        "iso": "GB",
        "status": 1,
        "name": "Fairoaks Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "-4.583333",
        "size": "small"
    },
    {
        "iata": "FZO",
        "lon": "-2.583333",
        "iso": "GB",
        "status": 1,
        "name": "Bristol Filton Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.516666",
        "size": "medium"
    },
    {
        "iata": "OXF",
        "lon": "-1.32",
        "iso": "GB",
        "status": 1,
        "name": "Oxford (Kidlington) Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.836945",
        "size": "medium"
    },
    {
        "iata": "RCS",
        "lon": "0.5",
        "iso": "GB",
        "status": 1,
        "name": "Rochester Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.35",
        "size": "small"
    },
    {
        "iata": "BEX",
        "lon": "-1.083333",
        "iso": "GB",
        "status": 1,
        "name": "RAF Benson",
        "continent": "EU",
        "type": "airport",
        "lat": "51.63333",
        "size": "medium"
    },
    {
        "iata": "LKZ",
        "lon": "0.67609",
        "iso": "GB",
        "status": 1,
        "name": "RAF Lakenheath",
        "continent": "EU",
        "type": "airport",
        "lat": "52.49167",
        "size": "large"
    },
    {
        "iata": "MHZ",
        "lon": "0.483333",
        "iso": "GB",
        "status": 1,
        "name": "RAF Mildenhall",
        "continent": "EU",
        "type": "airport",
        "lat": "52.36667",
        "size": "large"
    },
    {
        "iata": "QUY",
        "iso": "GB",
        "status": 1,
        "name": "RAF Wyton",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "FFD",
        "lon": "-1.783333",
        "iso": "GB",
        "status": 1,
        "name": "RAF Fairford",
        "continent": "EU",
        "type": "airport",
        "lat": "51.733334",
        "size": "large"
    },
    {
        "iata": "BZZ",
        "lon": "-1.587093",
        "iso": "GB",
        "status": 1,
        "name": "RAF Brize Norton",
        "continent": "EU",
        "type": "airport",
        "lat": "51.75",
        "size": "large"
    },
    {
        "iata": "ODH",
        "lon": "-0.95",
        "iso": "GB",
        "status": 1,
        "name": "RAF Odiham",
        "continent": "EU",
        "type": "airport",
        "lat": "51.25",
        "size": "medium"
    },
    {
        "iata": "WXF",
        "lon": "0.566667",
        "iso": "GB",
        "status": 1,
        "name": "Wethersfield Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.88333",
        "size": "small"
    },
    {
        "iata": "ADV",
        "iso": "GB",
        "status": 1,
        "name": "Andover Airfield",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "NHT",
        "lon": "-0.366667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Northolt",
        "continent": "EU",
        "type": "airport",
        "lat": "51.533333",
        "size": "medium"
    },
    {
        "iata": "GSY",
        "iso": "GB",
        "status": 0,
        "name": "Binbrook Airfield",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "QCY",
        "iso": "GB",
        "status": 1,
        "name": "RAF Coningsby",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "BEQ",
        "lon": "0.766667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Honington",
        "continent": "EU",
        "type": "airport",
        "lat": "52.35",
        "size": "medium"
    },
    {
        "iata": "OKH",
        "lon": "-0.716667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Cottesmore",
        "continent": "EU",
        "type": "airport",
        "lat": "52.666668",
        "size": "medium"
    },
    {
        "iata": "SQZ",
        "lon": "-0.583333",
        "iso": "GB",
        "status": 1,
        "name": "RAF Scampton",
        "continent": "EU",
        "type": "airport",
        "lat": "53.3",
        "size": "medium"
    },
    {
        "iata": "HRT",
        "lon": "-1.55",
        "iso": "GB",
        "status": 1,
        "name": "RAF Linton-On-Ouse",
        "continent": "EU",
        "type": "airport",
        "lat": "54.0",
        "size": "medium"
    },
    {
        "iata": "WTN",
        "lon": "-0.516667",
        "iso": "GB",
        "status": 1,
        "name": "RAF Waddington",
        "continent": "EU",
        "type": "airport",
        "lat": "53.166668",
        "size": "medium"
    },
    {
        "iata": "MRH",
        "lon": "145.33333",
        "iso": "GB",
        "status": 1,
        "name": "RAF Marham",
        "continent": "EU",
        "type": "airport",
        "lat": "-8.466667",
        "size": "medium"
    },
    {
        "iata": "MPN",
        "lon": "-58.45",
        "iso": "FK",
        "status": 1,
        "name": "Mount Pleasant Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-51.816666",
        "size": "medium"
    },
    {
        "iata": "AMS",
        "lon": "4.763385",
        "iso": "NL",
        "status": 1,
        "name": "Amsterdam Airport Schiphol",
        "continent": "EU",
        "type": "airport",
        "lat": "52.30907",
        "size": "large"
    },
    {
        "iata": "MST",
        "lon": "5.768827",
        "iso": "NL",
        "status": 1,
        "name": "Maastricht Aachen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.91562",
        "size": "large"
    },
    {
        "iata": "QAR",
        "iso": "NL",
        "status": 1,
        "name": "Deelen Air Base",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "EIN",
        "lon": "5.391795",
        "iso": "NL",
        "status": 1,
        "name": "Eindhoven Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.457954",
        "size": "large"
    },
    {
        "iata": "GRQ",
        "lon": "6.575556",
        "iso": "NL",
        "status": 1,
        "name": "Eelde Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.120277",
        "size": "medium"
    },
    {
        "iata": "GLZ",
        "lon": "4.75",
        "iso": "NL",
        "status": 1,
        "name": "Gilze Rijen Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "51.583332",
        "size": "medium"
    },
    {
        "iata": "DHR",
        "lon": "4.75",
        "iso": "NL",
        "status": 1,
        "name": "De Kooy Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.95",
        "size": "medium"
    },
    {
        "iata": "LEY",
        "lon": "5.533333",
        "iso": "NL",
        "status": 1,
        "name": "Lelystad Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.466667",
        "size": "medium"
    },
    {
        "iata": "LWR",
        "lon": "5.8",
        "iso": "NL",
        "status": 1,
        "name": "Leeuwarden Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "53.25",
        "size": "medium"
    },
    {
        "iata": "RTM",
        "lon": "4.433606",
        "iso": "NL",
        "status": 1,
        "name": "Rotterdam Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.948948",
        "size": "medium"
    },
    {
        "iata": "UTC",
        "iso": "NL",
        "status": 0,
        "name": "Soesterberg Air Base",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "ENS",
        "lon": "6.878333",
        "iso": "NL",
        "status": 1,
        "name": "Twenthe Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.271667",
        "size": "medium"
    },
    {
        "iata": "LID",
        "iso": "NL",
        "status": 0,
        "name": "Valkenburg Naval Air Base",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "UDE",
        "lon": "5.616667",
        "iso": "NL",
        "status": 1,
        "name": "Volkel Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "51.666668",
        "size": "medium"
    },
    {
        "iata": "WOE",
        "lon": "4.335",
        "iso": "NL",
        "status": 1,
        "name": "Woensdrecht Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "51.435833",
        "size": "medium"
    },
    {
        "iata": "BYT",
        "lon": "-9.45",
        "iso": "IE",
        "status": 1,
        "name": "Bantry Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "51.683334",
        "size": "small"
    },
    {
        "iata": "BLY",
        "lon": "-10.0",
        "iso": "IE",
        "status": 1,
        "name": "Belmullet Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "54.233334",
        "size": "small"
    },
    {
        "iata": "NNR",
        "lon": "-9.466667",
        "iso": "IE",
        "status": 1,
        "name": "Connemara Regional Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.216667",
        "size": "small"
    },
    {
        "iata": "CLB",
        "lon": "-9.3",
        "iso": "IE",
        "status": 1,
        "name": "Castlebar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.86667",
        "size": "small"
    },
    {
        "iata": "WEX",
        "lon": "-6.466667",
        "iso": "IE",
        "status": 1,
        "name": "Castlebridge Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.333332",
        "size": "small"
    },
    {
        "iata": "ORK",
        "lon": "-8.48914",
        "iso": "IE",
        "status": 1,
        "name": "Cork Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.846645",
        "size": "large"
    },
    {
        "iata": "GWY",
        "lon": "-9.033333",
        "iso": "IE",
        "status": 1,
        "name": "Galway Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.283333",
        "size": "medium"
    },
    {
        "iata": "CFN",
        "lon": "-8.340278",
        "iso": "IE",
        "status": 1,
        "name": "Donegal Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.041668",
        "size": "medium"
    },
    {
        "iata": "DUB",
        "lon": "-6.24357",
        "iso": "IE",
        "status": 1,
        "name": "Dublin Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.42728",
        "size": "large"
    },
    {
        "iata": "IOR",
        "lon": "-9.75",
        "iso": "IE",
        "status": 1,
        "name": "Inishmore Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "53.11667",
        "size": "small"
    },
    {
        "iata": "INQ",
        "lon": "-9.433333",
        "iso": "IE",
        "status": 1,
        "name": "Inisheer Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "53.033333",
        "size": "small"
    },
    {
        "iata": "KKY",
        "lon": "-7.25",
        "iso": "IE",
        "status": 1,
        "name": "Kilkenny Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.65",
        "size": "small"
    },
    {
        "iata": "NOC",
        "lon": "-8.810468",
        "iso": "IE",
        "status": 1,
        "name": "Ireland West Knock Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.914",
        "size": "medium"
    },
    {
        "iata": "KIR",
        "lon": "-9.535666",
        "iso": "IE",
        "status": 1,
        "name": "Kerry Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.181477",
        "size": "medium"
    },
    {
        "iata": "LTR",
        "lon": "-7.733333",
        "iso": "IE",
        "status": 1,
        "name": "Letterkenny Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.95",
        "size": "small"
    },
    {
        "iata": "IIA",
        "lon": "-9.533333",
        "iso": "IE",
        "status": 1,
        "name": "Inishmaan Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "53.083332",
        "size": "small"
    },
    {
        "iata": "SNN",
        "lon": "-8.92039",
        "iso": "IE",
        "status": 1,
        "name": "Shannon Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.69248",
        "size": "large"
    },
    {
        "iata": "SXL",
        "lon": "-8.466667",
        "iso": "IE",
        "status": 1,
        "name": "Sligo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.283333",
        "size": "medium"
    },
    {
        "iata": "WAT",
        "lon": "-7.080082",
        "iso": "IE",
        "status": 1,
        "name": "Waterford Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.188866",
        "size": "medium"
    },
    {
        "iata": "AAR",
        "lon": "10.626351",
        "iso": "DK",
        "status": 1,
        "name": "Aarhus Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.30823",
        "size": "medium"
    },
    {
        "iata": "BLL",
        "lon": "9.147874",
        "iso": "DK",
        "status": 1,
        "name": "Billund Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.747383",
        "size": "large"
    },
    {
        "iata": "CPH",
        "lon": "12.647601",
        "iso": "DK",
        "status": 1,
        "name": "Copenhagen Kastrup Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.62905",
        "size": "large"
    },
    {
        "iata": "EBJ",
        "lon": "8.549062",
        "iso": "DK",
        "status": 1,
        "name": "Esbjerg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.52143",
        "size": "medium"
    },
    {
        "iata": "KRP",
        "lon": "9.116667",
        "iso": "DK",
        "status": 1,
        "name": "Karup Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.3",
        "size": "medium"
    },
    {
        "iata": "BYR",
        "lon": "11.005083",
        "iso": "DK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "57.27756",
        "size": "small"
    },
    {
        "iata": "MRW",
        "lon": "11.435",
        "iso": "DK",
        "status": 1,
        "name": "Lolland Falster Maribo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.685833",
        "size": "medium"
    },
    {
        "iata": "ODE",
        "lon": "10.328611",
        "iso": "DK",
        "status": 1,
        "name": "Odense Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.47639",
        "size": "medium"
    },
    {
        "iata": "RKE",
        "lon": "12.133333",
        "iso": "DK",
        "status": 1,
        "name": "Copenhagen Roskilde Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.583332",
        "size": "medium"
    },
    {
        "iata": "RNN",
        "lon": "14.757778",
        "iso": "DK",
        "status": 1,
        "name": "Bornholm Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.065556",
        "size": "medium"
    },
    {
        "iata": "SGD",
        "lon": "9.790798",
        "iso": "DK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "54.96476",
        "size": "medium"
    },
    {
        "iata": "CNL",
        "lon": "10.216667",
        "iso": "DK",
        "status": 1,
        "name": "Sindal Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.483334",
        "size": "medium"
    },
    {
        "iata": "SKS",
        "lon": "9.264722",
        "iso": "DK",
        "status": 1,
        "name": "Vojens Skrydstrup Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.221943",
        "size": "medium"
    },
    {
        "iata": "SQW",
        "lon": "9.033333",
        "iso": "DK",
        "status": 1,
        "name": "Skive Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.566666",
        "size": "medium"
    },
    {
        "iata": "TED",
        "lon": "8.703333",
        "iso": "DK",
        "status": 1,
        "name": "Thisted Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.072224",
        "size": "medium"
    },
    {
        "iata": "VDP",
        "lon": "-65.99111",
        "iso": "DK",
        "status": 1,
        "name": "Kolding Vamdrup Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "9.221389",
        "size": "medium"
    },
    {
        "iata": "FAE",
        "lon": "-7.266667",
        "iso": "FO",
        "status": 1,
        "name": "Vagar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.066666",
        "size": "medium"
    },
    {
        "iata": "STA",
        "lon": "8.355",
        "iso": "DK",
        "status": 1,
        "name": "Stauning Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.990276",
        "size": "medium"
    },
    {
        "iata": "AAL",
        "lon": "9.872241",
        "iso": "DK",
        "status": 1,
        "name": "Aalborg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.08655",
        "size": "large"
    },
    {
        "iata": "LUX",
        "lon": "6.209539",
        "iso": "LU",
        "status": 1,
        "name": "Luxembourg-Findel International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.63111",
        "size": "large"
    },
    {
        "iata": "AES",
        "lon": "6.116605",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "62.559643",
        "size": "medium"
    },
    {
        "iata": "ANX",
        "lon": "16.133326",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "69.30492",
        "size": "medium"
    },
    {
        "iata": "ALF",
        "lon": "23.355808",
        "iso": "NO",
        "status": 1,
        "name": "Alta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "69.977165",
        "size": "medium"
    },
    {
        "iata": "FDE",
        "lon": "5.762073",
        "iso": "NO",
        "status": 1,
        "name": "Bringeland Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.39178",
        "size": "small"
    },
    {
        "iata": "BNN",
        "lon": "12.215772",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.4617",
        "size": "medium"
    },
    {
        "iata": "BOO",
        "lon": "14.367839",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "67.27262",
        "size": "large"
    },
    {
        "iata": "BGO",
        "lon": "5.228169",
        "iso": "NO",
        "status": 1,
        "name": "Bergen Airport, Flesland",
        "continent": "EU",
        "type": "airport",
        "lat": "60.289062",
        "size": "large"
    },
    {
        "iata": "BJF",
        "lon": "29.666668",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "70.6",
        "size": "medium"
    },
    {
        "iata": "BVG",
        "lon": "29.0",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "70.86667",
        "size": "medium"
    },
    {
        "iata": "KRS",
        "lon": "8.073732",
        "iso": "NO",
        "status": 1,
        "name": "Kristiansand Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.20255",
        "size": "medium"
    },
    {
        "iata": "DLD",
        "lon": "8.483333",
        "iso": "NO",
        "status": 1,
        "name": "Geilo Airport Dagali",
        "continent": "EU",
        "type": "airport",
        "lat": "60.466667",
        "size": "small"
    },
    {
        "iata": "BDU",
        "lon": "18.54",
        "iso": "NO",
        "status": 1,
        "name": "Bardufoss Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "69.056114",
        "size": "medium"
    },
    {
        "iata": "EVE",
        "lon": "16.679722",
        "iso": "NO",
        "status": 1,
        "name": "Harstad/Narvik Airport, Evenes",
        "continent": "EU",
        "type": "airport",
        "lat": "68.496666",
        "size": "medium"
    },
    {
        "iata": "FBU",
        "iso": "NO",
        "status": 0,
        "name": "Oslo, Fornebu Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "VDB",
        "lon": "9.288056",
        "iso": "NO",
        "status": 1,
        "name": "Leirin Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.015556",
        "size": "medium"
    },
    {
        "iata": "FRO",
        "lon": "5.024577",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "61.58643",
        "size": "medium"
    },
    {
        "iata": "OSL",
        "lon": "11.100411",
        "iso": "NO",
        "status": 1,
        "name": "Oslo Gardermoen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.19419",
        "size": "large"
    },
    {
        "iata": "HMR",
        "lon": "11.116667",
        "iso": "NO",
        "status": 1,
        "name": "Stafsberg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.8",
        "size": "small"
    },
    {
        "iata": "HAU",
        "lon": "5.215903",
        "iso": "NO",
        "status": 1,
        "name": "Haugesund Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.344765",
        "size": "medium"
    },
    {
        "iata": "HFT",
        "lon": "23.675867",
        "iso": "NO",
        "status": 1,
        "name": "Hammerfest Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "70.67999",
        "size": "medium"
    },
    {
        "iata": "HAA",
        "lon": "22.15",
        "iso": "NO",
        "status": 1,
        "name": "Hasvik Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "70.46667",
        "size": "small"
    },
    {
        "iata": "HVG",
        "lon": "25.833332",
        "iso": "NO",
        "status": 1,
        "name": "Valan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "70.98333",
        "size": "medium"
    },
    {
        "iata": "QKX",
        "iso": "NO",
        "status": 1,
        "name": "Kautokeino Air Base",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "KSU",
        "lon": "7.844444",
        "iso": "NO",
        "status": 1,
        "name": "Kristiansund Airport, Kvernberget",
        "continent": "EU",
        "type": "airport",
        "lat": "63.114723",
        "size": "medium"
    },
    {
        "iata": "GLL",
        "lon": "8.95",
        "iso": "NO",
        "status": 1,
        "name": "Gol Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.7",
        "size": "small"
    },
    {
        "iata": "KKN",
        "lon": "29.891184",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "69.7235",
        "size": "medium"
    },
    {
        "iata": "FAN",
        "lon": "6.625556",
        "iso": "NO",
        "status": 1,
        "name": "Lista Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.101665",
        "size": "small"
    },
    {
        "iata": "LKN",
        "lon": "13.614864",
        "iso": "NO",
        "status": 1,
        "name": "Leknes Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "68.15421",
        "size": "medium"
    },
    {
        "iata": "MEH",
        "lon": "27.833332",
        "iso": "NO",
        "status": 1,
        "name": "Mehamn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "71.03333",
        "size": "medium"
    },
    {
        "iata": "MOL",
        "lon": "7.262118",
        "iso": "NO",
        "status": 1,
        "name": "Molde Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.747303",
        "size": "medium"
    },
    {
        "iata": "MJF",
        "lon": "13.218328",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.78439",
        "size": "medium"
    },
    {
        "iata": "LKL",
        "lon": "24.973612",
        "iso": "NO",
        "status": 1,
        "name": "Banak Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "70.06778",
        "size": "medium"
    },
    {
        "iata": "NVK",
        "lon": "17.425",
        "iso": "NO",
        "status": 1,
        "name": "Narvik Framnes Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "68.425",
        "size": "medium"
    },
    {
        "iata": "OSY",
        "lon": "11.570002",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.47273",
        "size": "small"
    },
    {
        "iata": "NTB",
        "lon": "9.215656",
        "iso": "NO",
        "status": 1,
        "name": "Notodden Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.56487",
        "size": "medium"
    },
    {
        "iata": "OLA",
        "lon": "9.616667",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.7",
        "size": "medium"
    },
    {
        "iata": "HOV",
        "lon": "6.078802",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "62.179787",
        "size": "medium"
    },
    {
        "iata": "MQN",
        "lon": "14.302748",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "66.36465",
        "size": "medium"
    },
    {
        "iata": "RVK",
        "lon": "11.233333",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.88333",
        "size": "medium"
    },
    {
        "iata": "RRS",
        "lon": "11.345556",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "62.579166",
        "size": "medium"
    },
    {
        "iata": "RET",
        "lon": "12.083333",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "67.48333",
        "size": "small"
    },
    {
        "iata": "RYG",
        "lon": "10.800161",
        "iso": "NO",
        "status": 1,
        "name": "Moss Airport, Rygge",
        "continent": "EU",
        "type": "airport",
        "lat": "59.37903",
        "size": "medium"
    },
    {
        "iata": "LYR",
        "lon": "15.8",
        "iso": "NO",
        "status": 1,
        "name": "Svalbard Airport, Longyear",
        "continent": "EU",
        "type": "airport",
        "lat": "78.208885",
        "size": "medium"
    },
    {
        "iata": "SDN",
        "lon": "6.216667",
        "iso": "NO",
        "status": 1,
        "name": "Sandane Airport, Anda",
        "continent": "EU",
        "type": "airport",
        "lat": "61.766666",
        "size": "small"
    },
    {
        "iata": "SOG",
        "lon": "7.136988",
        "iso": "NO",
        "status": 1,
        "name": "Sogndal Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.158127",
        "size": "small"
    },
    {
        "iata": "SVJ",
        "lon": "14.667774",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "68.24498",
        "size": "medium"
    },
    {
        "iata": "SKN",
        "lon": "15.032921",
        "iso": "NO",
        "status": 1,
        "name": "Stokmarknes Skagen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "68.57915",
        "size": "medium"
    },
    {
        "iata": "SKE",
        "lon": "9.5625",
        "iso": "NO",
        "status": 1,
        "name": "Skien Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.182777",
        "size": "medium"
    },
    {
        "iata": "SRP",
        "lon": "5.416667",
        "iso": "NO",
        "status": 1,
        "name": "Stord Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.833332",
        "size": "medium"
    },
    {
        "iata": "SOJ",
        "lon": "20.933332",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "69.78333",
        "size": "medium"
    },
    {
        "iata": "VAW",
        "lon": "31.045555",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "70.35472",
        "size": "medium"
    },
    {
        "iata": "SSJ",
        "lon": "12.476518",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.959946",
        "size": "medium"
    },
    {
        "iata": "TOS",
        "lon": "18.907343",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "69.67983",
        "size": "large"
    },
    {
        "iata": "TRF",
        "lon": "10.251807",
        "iso": "NO",
        "status": 1,
        "name": "Sandefjord Airport, Torp",
        "continent": "EU",
        "type": "airport",
        "lat": "59.178085",
        "size": "medium"
    },
    {
        "iata": "TRD",
        "lon": "10.917863",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.454285",
        "size": "large"
    },
    {
        "iata": "VDS",
        "lon": "29.845278",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "70.065",
        "size": "medium"
    },
    {
        "iata": "VRY",
        "iso": "NO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "SVG",
        "lon": "5.629197",
        "iso": "NO",
        "status": 1,
        "name": "Stavanger Airport, Sola",
        "continent": "EU",
        "type": "airport",
        "lat": "58.88215",
        "size": "large"
    },
    {
        "iata": "QYY",
        "iso": "PL",
        "status": 1,
        "name": "Bia?ystok-Krywlany Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BZG",
        "lon": "17.978611",
        "iso": "PL",
        "status": 1,
        "name": "Bydgoszcz Ignacy Jan Paderewski Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.09667",
        "size": "medium"
    },
    {
        "iata": "CZW",
        "lon": "19.1",
        "iso": "PL",
        "status": 1,
        "name": "Cz?stochowa-Rudniki",
        "continent": "EU",
        "type": "airport",
        "lat": "50.816666",
        "size": "small"
    },
    {
        "iata": "GDN",
        "lon": "18.468655",
        "iso": "PL",
        "status": 1,
        "name": "Gda?sk Lech Wa??sa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.380978",
        "size": "large"
    },
    {
        "iata": "QLC",
        "iso": "PL",
        "status": 1,
        "name": "Gliwice Glider Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "KRK",
        "lon": "19.793743",
        "iso": "PL",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "50.075493",
        "size": "large"
    },
    {
        "iata": "OSZ",
        "lon": "16.264444",
        "iso": "PL",
        "status": 1,
        "name": "Koszalin Zegrze Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.042778",
        "size": "small"
    },
    {
        "iata": "KTW",
        "lon": "19.07403",
        "iso": "PL",
        "status": 1,
        "name": "Katowice International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.470833",
        "size": "medium"
    },
    {
        "iata": "QEO",
        "iso": "PL",
        "status": 1,
        "name": "Bielsko-Bialo Kaniow Airfield",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LCJ",
        "lon": "19.398333",
        "iso": "PL",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "51.721943",
        "size": "medium"
    },
    {
        "iata": "QLU",
        "iso": "PL",
        "status": 1,
        "name": "Lublin Radwiec Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "QWS",
        "iso": "PL",
        "status": 1,
        "name": "Nowy Targ Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "QYD",
        "iso": "PL",
        "status": 1,
        "name": "Gdynia-Babie Do?y Air Base",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "QPM",
        "iso": "PL",
        "status": 1,
        "name": "Opole-Polska Nowa Wie? Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "POZ",
        "lon": "16.828844",
        "iso": "PL",
        "status": 1,
        "name": "Pozna?-?awica Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.414326",
        "size": "medium"
    },
    {
        "iata": "RZE",
        "lon": "22.03133",
        "iso": "PL",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "50.11525",
        "size": "medium"
    },
    {
        "iata": "SZZ",
        "lon": "14.894611",
        "iso": "PL",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "53.593525",
        "size": "medium"
    },
    {
        "iata": "OSP",
        "iso": "PL",
        "status": 0,
        "name": "Redzikowo Air Base",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "SZY",
        "iso": "PL",
        "status": 0,
        "name": "Szczytno-Szymany International Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "WAW",
        "lon": "20.97329",
        "iso": "PL",
        "status": 1,
        "name": "Warsaw Chopin Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.170906",
        "size": "large"
    },
    {
        "iata": "WRO",
        "lon": "16.899403",
        "iso": "PL",
        "status": 1,
        "name": "Copernicus Wroc?aw Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.10482",
        "size": "medium"
    },
    {
        "iata": "IEG",
        "lon": "15.516667",
        "iso": "PL",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "51.933334",
        "size": "medium"
    },
    {
        "iata": "RNB",
        "lon": "15.261111",
        "iso": "SE",
        "status": 1,
        "name": "Ronneby Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.25833",
        "size": "medium"
    },
    {
        "iata": "XWP",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "GOT",
        "lon": "12.294878",
        "iso": "SE",
        "status": 1,
        "name": "Gothenburg-Landvetter Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.66664",
        "size": "large"
    },
    {
        "iata": "JKG",
        "lon": "14.070497",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "57.7501",
        "size": "medium"
    },
    {
        "iata": "LDK",
        "lon": "13.166667",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.5",
        "size": "medium"
    },
    {
        "iata": "GSE",
        "lon": "11.864513",
        "iso": "SE",
        "status": 1,
        "name": "Gothenburg City Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.77775",
        "size": "medium"
    },
    {
        "iata": "KVB",
        "lon": "13.966667",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.45",
        "size": "medium"
    },
    {
        "iata": "THN",
        "lon": "12.3",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.266666",
        "size": "medium"
    },
    {
        "iata": "KSK",
        "lon": "14.55",
        "iso": "SE",
        "status": 1,
        "name": "Karlskoga Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.316666",
        "size": "medium"
    },
    {
        "iata": "MXX",
        "lon": "14.504529",
        "iso": "SE",
        "status": 1,
        "name": "Mora Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.95812",
        "size": "medium"
    },
    {
        "iata": "NYO",
        "lon": "16.922867",
        "iso": "SE",
        "status": 1,
        "name": "Stockholm Skavsta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.78425",
        "size": "medium"
    },
    {
        "iata": "KID",
        "lon": "14.088889",
        "iso": "SE",
        "status": 1,
        "name": "Kristianstad Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.919445",
        "size": "medium"
    },
    {
        "iata": "OSK",
        "lon": "16.433332",
        "iso": "SE",
        "status": 1,
        "name": "Oskarshamn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.266666",
        "size": "medium"
    },
    {
        "iata": "KLR",
        "lon": "16.287222",
        "iso": "SE",
        "status": 1,
        "name": "Kalmar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.685",
        "size": "medium"
    },
    {
        "iata": "MMX",
        "lon": "13.363727",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "55.538757",
        "size": "large"
    },
    {
        "iata": "HAD",
        "lon": "12.815005",
        "iso": "SE",
        "status": 1,
        "name": "Halmstad Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.680935",
        "size": "medium"
    },
    {
        "iata": "VXO",
        "lon": "14.732046",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "56.925095",
        "size": "medium"
    },
    {
        "iata": "EVG",
        "lon": "14.35",
        "iso": "SE",
        "status": 1,
        "name": "Sveg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "62.033333",
        "size": "medium"
    },
    {
        "iata": "GEV",
        "lon": "20.816668",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "67.134445",
        "size": "medium"
    },
    {
        "iata": "HUV",
        "iso": "SE",
        "status": 0,
        "name": "Hudiksvall Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "KRF",
        "lon": "17.772778",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.049442",
        "size": "medium"
    },
    {
        "iata": "LYC",
        "lon": "18.70967",
        "iso": "SE",
        "status": 1,
        "name": "Lycksele Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.55071",
        "size": "medium"
    },
    {
        "iata": "SDL",
        "lon": "17.438147",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "62.52165",
        "size": "medium"
    },
    {
        "iata": "OER",
        "lon": "18.992073",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.412582",
        "size": "medium"
    },
    {
        "iata": "KRN",
        "lon": "20.345833",
        "iso": "SE",
        "status": 1,
        "name": "Kiruna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "67.82222",
        "size": "medium"
    },
    {
        "iata": "SFT",
        "lon": "21.068548",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "64.62251",
        "size": "medium"
    },
    {
        "iata": "UME",
        "lon": "20.28954",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.79333",
        "size": "medium"
    },
    {
        "iata": "VHM",
        "lon": "16.65",
        "iso": "SE",
        "status": 1,
        "name": "Vilhelmina Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.61667",
        "size": "medium"
    },
    {
        "iata": "AJR",
        "lon": "19.285557",
        "iso": "SE",
        "status": 1,
        "name": "Arvidsjaur Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.59139",
        "size": "medium"
    },
    {
        "iata": "SOO",
        "lon": "17.05",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "61.3",
        "size": "medium"
    },
    {
        "iata": "OSD",
        "lon": "14.494444",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "63.198612",
        "size": "medium"
    },
    {
        "iata": "ORB",
        "lon": "15.047543",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "59.225758",
        "size": "medium"
    },
    {
        "iata": "HFS",
        "lon": "13.569167",
        "iso": "SE",
        "status": 1,
        "name": "Hagfors Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.0175",
        "size": "medium"
    },
    {
        "iata": "KSD",
        "lon": "13.472059",
        "iso": "SE",
        "status": 1,
        "name": "Karlstad Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.360283",
        "size": "medium"
    },
    {
        "iata": "VST",
        "lon": "16.630556",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "59.58917",
        "size": "medium"
    },
    {
        "iata": "LLA",
        "lon": "22.123587",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "65.54939",
        "size": "large"
    },
    {
        "iata": "ARN",
        "lon": "17.930365",
        "iso": "SE",
        "status": 1,
        "name": "Stockholm-Arlanda Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.64982",
        "size": "large"
    },
    {
        "iata": "BMA",
        "lon": "17.94608",
        "iso": "SE",
        "status": 1,
        "name": "Stockholm-Bromma Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.35566",
        "size": "medium"
    },
    {
        "iata": "BLE",
        "lon": "15.50826",
        "iso": "SE",
        "status": 1,
        "name": "Borlange Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.42973",
        "size": "medium"
    },
    {
        "iata": "HLF",
        "lon": "15.827778",
        "iso": "SE",
        "status": 1,
        "name": "Hultsfred Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.52639",
        "size": "medium"
    },
    {
        "iata": "GVX",
        "lon": "16.954721",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "60.593887",
        "size": "medium"
    },
    {
        "iata": "LPI",
        "lon": "15.656944",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.406944",
        "size": "medium"
    },
    {
        "iata": "NRK",
        "lon": "16.232393",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "58.583298",
        "size": "medium"
    },
    {
        "iata": "TYF",
        "lon": "12.99661",
        "iso": "SE",
        "status": 1,
        "name": "Torsby Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.154484",
        "size": "medium"
    },
    {
        "iata": "EKT",
        "lon": "16.533333",
        "iso": "SE",
        "status": 1,
        "name": "Eskilstuna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.36667",
        "size": "medium"
    },
    {
        "iata": "VBY",
        "lon": "18.338154",
        "iso": "SE",
        "status": 1,
        "name": "Visby Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.660446",
        "size": "medium"
    },
    {
        "iata": "VVK",
        "lon": "16.633333",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "57.75",
        "size": "small"
    },
    {
        "iata": "AGH",
        "lon": "12.8625",
        "iso": "SE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "56.293056",
        "size": "medium"
    },
    {
        "iata": "SQO",
        "lon": "17.8",
        "iso": "SE",
        "status": 1,
        "name": "Storuman Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.96222",
        "size": "medium"
    },
    {
        "iata": "IDB",
        "lon": "12.683333",
        "iso": "SE",
        "status": 1,
        "name": "Idre Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.86667",
        "size": "small"
    },
    {
        "iata": "PJA",
        "lon": "23.075",
        "iso": "SE",
        "status": 1,
        "name": "Pajala Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "67.246666",
        "size": "medium"
    },
    {
        "iata": "HMV",
        "lon": "15.273056",
        "iso": "SE",
        "status": 1,
        "name": "Hemavan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.727776",
        "size": "medium"
    },
    {
        "iata": "GLC",
        "lon": "46.416668",
        "iso": "ET",
        "status": 1,
        "name": "Geladi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.966667",
        "size": "small"
    },
    {
        "iata": "SHC",
        "lon": "39.13333",
        "iso": "ET",
        "status": 1,
        "name": "Shire Inda Selassie Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.983333",
        "size": "small"
    },
    {
        "iata": "SPM",
        "lon": "6.835917",
        "iso": "DE",
        "status": 1,
        "name": "Spangdahlem Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "50.06547",
        "size": "medium"
    },
    {
        "iata": "RMS",
        "lon": "7.599187",
        "iso": "DE",
        "status": 1,
        "name": "Ramstein Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "49.439938",
        "size": "large"
    },
    {
        "iata": "ZCD",
        "iso": "DE",
        "status": 1,
        "name": "Bamberg-Breitenau Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ZCN",
        "iso": "DE",
        "status": 1,
        "name": "Celle Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZPQ",
        "iso": "DE",
        "status": 1,
        "name": "Rheine Bentlage Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "FRZ",
        "lon": "9.266667",
        "iso": "DE",
        "status": 1,
        "name": "Fritzlar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.11667",
        "size": "medium"
    },
    {
        "iata": "ZNF",
        "iso": "DE",
        "status": 1,
        "name": "Hanau Army Air Field",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "QHD",
        "iso": "DE",
        "status": 1,
        "name": "Heidelberg Army Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "NDZ",
        "lon": "8.644722",
        "iso": "DE",
        "status": 1,
        "name": "Nordholz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.768612",
        "size": "medium"
    },
    {
        "iata": "GKE",
        "lon": "6.133333",
        "iso": "DE",
        "status": 1,
        "name": "Geilenkirchen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.966667",
        "size": "medium"
    },
    {
        "iata": "RLG",
        "lon": "12.266667",
        "iso": "DE",
        "status": 1,
        "name": "Rostock-Laage Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.92",
        "size": "medium"
    },
    {
        "iata": "QOE",
        "iso": "DE",
        "status": 1,
        "name": "Norvenich Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "WBG",
        "lon": "9.516667",
        "iso": "DE",
        "status": 1,
        "name": "Schleswig Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.45",
        "size": "medium"
    },
    {
        "iata": "FNB",
        "lon": "13.307222",
        "iso": "DE",
        "status": 1,
        "name": "Neubrandenburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.60278",
        "size": "medium"
    },
    {
        "iata": "WIE",
        "lon": "8.233333",
        "iso": "DE",
        "status": 1,
        "name": "Wiesbaden Army Airfield",
        "continent": "EU",
        "type": "airport",
        "lat": "50.083332",
        "size": "medium"
    },
    {
        "iata": "FEL",
        "lon": "11.266667",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.2",
        "size": "medium"
    },
    {
        "iata": "IGS",
        "iso": "DE",
        "status": 1,
        "name": "Ingolstadt Manching Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "GUT",
        "lon": "8.383333",
        "iso": "DE",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "51.9",
        "size": "medium"
    },
    {
        "iata": "BGN",
        "lon": "146.22974",
        "iso": "DE",
        "status": 1,
        "name": "Brugge Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "68.55577",
        "size": "small"
    },
    {
        "iata": "LPX",
        "lon": "21.1",
        "iso": "LV",
        "status": 1,
        "name": "Liep?ja International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.516666",
        "size": "medium"
    },
    {
        "iata": "RIX",
        "lon": "23.979807",
        "iso": "LV",
        "status": 1,
        "name": "Riga International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.92208",
        "size": "large"
    },
    {
        "iata": "VNT",
        "lon": "87.2",
        "iso": "LV",
        "status": 1,
        "name": "Ventspils International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "27.3",
        "size": "medium"
    },
    {
        "iata": "EXI",
        "iso": "US",
        "status": 1,
        "name": "Excursion Inlet Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "KUN",
        "lon": "23.916668",
        "iso": "LT",
        "status": 1,
        "name": "Kaunas International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.9",
        "size": "medium"
    },
    {
        "iata": "KLJ",
        "lon": "21.216667",
        "iso": "LT",
        "status": 1,
        "name": "Klaip?da Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.7",
        "size": "small"
    },
    {
        "iata": "PLQ",
        "lon": "21.083332",
        "iso": "LT",
        "status": 1,
        "name": "Palanga International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.95",
        "size": "medium"
    },
    {
        "iata": "PNV",
        "lon": "24.383333",
        "iso": "LT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "55.733334",
        "size": "medium"
    },
    {
        "iata": "SQQ",
        "lon": "23.39311",
        "iso": "LT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "55.89529",
        "size": "medium"
    },
    {
        "iata": "HLJ",
        "iso": "LT",
        "status": 1,
        "name": "Barysiai Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "VNO",
        "lon": "25.279606",
        "iso": "LT",
        "status": 1,
        "name": "Vilnius International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.643078",
        "size": "large"
    },
    {
        "iata": "ALJ",
        "lon": "16.536388",
        "iso": "ZA",
        "status": 1,
        "name": "Alexander Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.567223",
        "size": "medium"
    },
    {
        "iata": "AGZ",
        "lon": "18.85",
        "iso": "ZA",
        "status": 1,
        "name": "Aggeneys Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.05",
        "size": "medium"
    },
    {
        "iata": "ADY",
        "lon": "29.05",
        "iso": "ZA",
        "status": 1,
        "name": "Alldays Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.666668",
        "size": "small"
    },
    {
        "iata": "BIY",
        "lon": "27.283333",
        "iso": "ZA",
        "status": 1,
        "name": "Bisho Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-35.88333",
        "size": "medium"
    },
    {
        "iata": "BFN",
        "lon": "26.297516",
        "iso": "ZA",
        "status": 1,
        "name": "J B M Hertzog International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.095854",
        "size": "medium"
    },
    {
        "iata": "UTE",
        "lon": "28.066668",
        "iso": "ZA",
        "status": 1,
        "name": "Butterworth Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-32.38333",
        "size": "small"
    },
    {
        "iata": "CDO",
        "lon": "25.6",
        "iso": "ZA",
        "status": 1,
        "name": "Gradock Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-32.13333",
        "size": "small"
    },
    {
        "iata": "CPT",
        "lon": "18.596489",
        "iso": "ZA",
        "status": 1,
        "name": "Cape Town International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.968906",
        "size": "large"
    },
    {
        "iata": "DUK",
        "lon": "32.234165",
        "iso": "ZA",
        "status": 1,
        "name": "Mubatuba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.366667",
        "size": "small"
    },
    {
        "iata": "PZL",
        "lon": "32.3",
        "iso": "ZA",
        "status": 1,
        "name": "Zulu Inyala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.866667",
        "size": "small"
    },
    {
        "iata": "ELS",
        "lon": "27.82892",
        "iso": "ZA",
        "status": 1,
        "name": "Ben Schoeman Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.038437",
        "size": "medium"
    },
    {
        "iata": "EMG",
        "lon": "31.9",
        "iso": "ZA",
        "status": 1,
        "name": "Empangeni Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.75",
        "size": "small"
    },
    {
        "iata": "ELL",
        "lon": "27.683332",
        "iso": "ZA",
        "status": 1,
        "name": "Ellisras Matimba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.716667",
        "size": "small"
    },
    {
        "iata": "FCB",
        "lon": "27.908333",
        "iso": "ZA",
        "status": 1,
        "name": "Ficksburg Sentraoes Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.825",
        "size": "medium"
    },
    {
        "iata": "GCJ",
        "lon": "28.143793",
        "iso": "ZA",
        "status": 1,
        "name": "Grand Central Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.987297",
        "size": "medium"
    },
    {
        "iata": "GRJ",
        "lon": "22.382235",
        "iso": "ZA",
        "status": 1,
        "name": "George Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-34.00148",
        "size": "large"
    },
    {
        "iata": "GIY",
        "lon": "30.666668",
        "iso": "ZA",
        "status": 1,
        "name": "Giyani Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.283333",
        "size": "small"
    },
    {
        "iata": "QRA",
        "lon": "28.151648",
        "iso": "ZA",
        "status": 1,
        "name": "Rand Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.24284",
        "size": "medium"
    },
    {
        "iata": "HLW",
        "lon": "32.25",
        "iso": "ZA",
        "status": 1,
        "name": "Hluhluwe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.016666",
        "size": "small"
    },
    {
        "iata": "HRS",
        "lon": "29.1",
        "iso": "ZA",
        "status": 1,
        "name": "Harrismith Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.233334",
        "size": "medium"
    },
    {
        "iata": "HDS",
        "lon": "30.95",
        "iso": "ZA",
        "status": 1,
        "name": "Hoedspruit Air Force Base Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.35",
        "size": "small"
    },
    {
        "iata": "JNB",
        "lon": "28.231314",
        "iso": "ZA",
        "status": 1,
        "name": "OR Tambo International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.132664",
        "size": "large"
    },
    {
        "iata": "KXE",
        "lon": "26.716667",
        "iso": "ZA",
        "status": 1,
        "name": "P C Pelser Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.866667",
        "size": "medium"
    },
    {
        "iata": "KIM",
        "lon": "24.76639",
        "iso": "ZA",
        "status": 1,
        "name": "Kimberley Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.805834",
        "size": "medium"
    },
    {
        "iata": "MQP",
        "lon": "31.098131",
        "iso": "ZA",
        "status": 1,
        "name": "Kruger Mpumalanga International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.384947",
        "size": "medium"
    },
    {
        "iata": "KOF",
        "lon": "31.933332",
        "iso": "ZA",
        "status": 1,
        "name": "Komatipoort Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.433332",
        "size": "small"
    },
    {
        "iata": "KMH",
        "lon": "23.466667",
        "iso": "ZA",
        "status": 1,
        "name": "Johan Pienaar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.466667",
        "size": "medium"
    },
    {
        "iata": "KLZ",
        "lon": "17.066668",
        "iso": "ZA",
        "status": 1,
        "name": "Kleinsee Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.683332",
        "size": "medium"
    },
    {
        "iata": "HLA",
        "lon": "27.926432",
        "iso": "ZA",
        "status": 1,
        "name": "Lanseria Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.937843",
        "size": "medium"
    },
    {
        "iata": "LMR",
        "lon": "23.45",
        "iso": "ZA",
        "status": 1,
        "name": "Lime Acres Finsch Mine Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.366667",
        "size": "medium"
    },
    {
        "iata": "LDZ",
        "lon": "31.5",
        "iso": "ZA",
        "status": 1,
        "name": "Londolozi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.8",
        "size": "small"
    },
    {
        "iata": "DUR",
        "lon": "31.116388",
        "iso": "ZA",
        "status": 1,
        "name": "King Shaka International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.614445",
        "size": "large"
    },
    {
        "iata": "LUJ",
        "lon": "29.583332",
        "iso": "ZA",
        "status": 1,
        "name": "Lusikisiki Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-31.366667",
        "size": "small"
    },
    {
        "iata": "LCD",
        "lon": "29.716667",
        "iso": "ZA",
        "status": 1,
        "name": "Louis Trichardt Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.016666",
        "size": "small"
    },
    {
        "iata": "LGE",
        "lon": "127.566666",
        "iso": "ZA",
        "status": 1,
        "name": "Langebaanweg Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.133333",
        "size": "medium"
    },
    {
        "iata": "LAY",
        "lon": "29.75",
        "iso": "ZA",
        "status": 1,
        "name": "Ladysmith Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.566668",
        "size": "medium"
    },
    {
        "iata": "AAM",
        "lon": "31.533333",
        "iso": "ZA",
        "status": 1,
        "name": "Malamala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.8",
        "size": "medium"
    },
    {
        "iata": "MGH",
        "lon": "30.25",
        "iso": "ZA",
        "status": 1,
        "name": "Margate Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-30.916668",
        "size": "medium"
    },
    {
        "iata": "MBD",
        "lon": "25.5",
        "iso": "ZA",
        "status": 1,
        "name": "Mmabatho International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.816668",
        "size": "medium"
    },
    {
        "iata": "LLE",
        "lon": "31.566668",
        "iso": "ZA",
        "status": 1,
        "name": "Riverside Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.466667",
        "size": "medium"
    },
    {
        "iata": "MZY",
        "lon": "22.083332",
        "iso": "ZA",
        "status": 1,
        "name": "Mossel Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-34.183334",
        "size": "small"
    },
    {
        "iata": "MEZ",
        "lon": "29.833332",
        "iso": "ZA",
        "status": 1,
        "name": "Morningside Farm Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.366667",
        "size": "medium"
    },
    {
        "iata": "MZQ",
        "lon": "32.045906",
        "iso": "ZA",
        "status": 1,
        "name": "Mkuze Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.623335",
        "size": "medium"
    },
    {
        "iata": "NCS",
        "lon": "29.99",
        "iso": "ZA",
        "status": 1,
        "name": "Newcastle Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.774166",
        "size": "medium"
    },
    {
        "iata": "NGL",
        "lon": "21.55",
        "iso": "ZA",
        "status": 1,
        "name": "Ngala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.45",
        "size": "small"
    },
    {
        "iata": "NLP",
        "lon": "30.913889",
        "iso": "ZA",
        "status": 1,
        "name": "Nelspruit Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.500834",
        "size": "small"
    },
    {
        "iata": "OVG",
        "lon": "20.235832",
        "iso": "ZA",
        "status": 1,
        "name": "Overberg Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-34.551666",
        "size": "small"
    },
    {
        "iata": "OUH",
        "lon": "22.188334",
        "iso": "ZA",
        "status": 1,
        "name": "Oudtshoorn Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.603333",
        "size": "medium"
    },
    {
        "iata": "AFD",
        "lon": "26.883333",
        "iso": "ZA",
        "status": 1,
        "name": "Port Alfred Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.583332",
        "size": "small"
    },
    {
        "iata": "PLZ",
        "lon": "25.611422",
        "iso": "ZA",
        "status": 1,
        "name": "Port Elizabeth Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.98371",
        "size": "medium"
    },
    {
        "iata": "PBZ",
        "lon": "23.329166",
        "iso": "ZA",
        "status": 1,
        "name": "Plettenberg Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-34.0875",
        "size": "medium"
    },
    {
        "iata": "PHW",
        "lon": "31.15611",
        "iso": "ZA",
        "status": 1,
        "name": "Hendrik Van Eck Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.93361",
        "size": "medium"
    },
    {
        "iata": "PTG",
        "lon": "29.966667",
        "iso": "ZA",
        "status": 1,
        "name": "Pietersburg Municipal Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.916668",
        "size": "medium"
    },
    {
        "iata": "JOH",
        "lon": "29.533333",
        "iso": "ZA",
        "status": 1,
        "name": "Port St Johns Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-31.616667",
        "size": "medium"
    },
    {
        "iata": "PRK",
        "lon": "22.7",
        "iso": "ZA",
        "status": 1,
        "name": "Prieska Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.666668",
        "size": "small"
    },
    {
        "iata": "PZB",
        "lon": "30.396738",
        "iso": "ZA",
        "status": 1,
        "name": "Pietermaritzburg Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.643047",
        "size": "medium"
    },
    {
        "iata": "NTY",
        "lon": "27.170698",
        "iso": "ZA",
        "status": 1,
        "name": "Pilanesberg International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.335514",
        "size": "small"
    },
    {
        "iata": "PTG",
        "lon": "29.966667",
        "iso": "ZA",
        "status": 1,
        "name": "Polokwane International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.916668",
        "size": "medium"
    },
    {
        "iata": "PCF",
        "iso": "ZA",
        "status": 1,
        "name": "Potchefstroom Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "UTW",
        "lon": "26.866667",
        "iso": "ZA",
        "status": 1,
        "name": "Queenstown Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-31.866667",
        "size": "medium"
    },
    {
        "iata": "RCB",
        "lon": "32.093056",
        "iso": "ZA",
        "status": 1,
        "name": "Richards Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.740557",
        "size": "medium"
    },
    {
        "iata": "RVO",
        "lon": "24.133333",
        "iso": "ZA",
        "status": 1,
        "name": "Reivilo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.6",
        "size": "small"
    },
    {
        "iata": "ROD",
        "lon": "19.9",
        "iso": "ZA",
        "status": 1,
        "name": "Robertson Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.816666",
        "size": "medium"
    },
    {
        "iata": "SBU",
        "lon": "17.939444",
        "iso": "ZA",
        "status": 1,
        "name": "Springbok Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.68861",
        "size": "medium"
    },
    {
        "iata": "ZEC",
        "lon": "28.0",
        "iso": "ZA",
        "status": 1,
        "name": "Secunda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.0",
        "size": "medium"
    },
    {
        "iata": "SDB",
        "lon": "18.0",
        "iso": "ZA",
        "status": 1,
        "name": "Saldanha /Vredenburg Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.066666",
        "size": "medium"
    },
    {
        "iata": "GSS",
        "lon": "30.8",
        "iso": "ZA",
        "status": 1,
        "name": "Sabi Sabi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.116667",
        "size": "small"
    },
    {
        "iata": "SIS",
        "lon": "22.983334",
        "iso": "ZA",
        "status": 1,
        "name": "Sishen Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.916668",
        "size": "medium"
    },
    {
        "iata": "SZK",
        "lon": "31.586111",
        "iso": "ZA",
        "status": 1,
        "name": "Skukuza Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.9625",
        "size": "medium"
    },
    {
        "iata": "THY",
        "lon": "30.55",
        "iso": "ZA",
        "status": 1,
        "name": "Thohoyandou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.883333",
        "size": "small"
    },
    {
        "iata": "TCU",
        "lon": "26.866667",
        "iso": "ZA",
        "status": 1,
        "name": "Thaba Nchu Tar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.283333",
        "size": "small"
    },
    {
        "iata": "LTA",
        "lon": "30.15",
        "iso": "ZA",
        "status": 1,
        "name": "Tzaneen Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.833332",
        "size": "medium"
    },
    {
        "iata": "ULD",
        "lon": "31.433332",
        "iso": "ZA",
        "status": 1,
        "name": "Prince Mangosuthu Buthelezi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.283333",
        "size": "medium"
    },
    {
        "iata": "UTN",
        "lon": "21.253332",
        "iso": "ZA",
        "status": 1,
        "name": "Pierre Van Ryneveld Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.400557",
        "size": "medium"
    },
    {
        "iata": "UTT",
        "lon": "28.783333",
        "iso": "ZA",
        "status": 1,
        "name": "K. D. Matanzima Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-31.583332",
        "size": "medium"
    },
    {
        "iata": "VRU",
        "lon": "24.75",
        "iso": "ZA",
        "status": 1,
        "name": "Vryburg Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.916668",
        "size": "medium"
    },
    {
        "iata": "VIR",
        "lon": "31.066668",
        "iso": "ZA",
        "status": 1,
        "name": "Virginia Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.766666",
        "size": "medium"
    },
    {
        "iata": "VRE",
        "lon": "18.5",
        "iso": "ZA",
        "status": 1,
        "name": "Vredendal Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-31.666668",
        "size": "medium"
    },
    {
        "iata": "VYD",
        "lon": "30.8",
        "iso": "ZA",
        "status": 1,
        "name": "Vryheid Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-27.783333",
        "size": "small"
    },
    {
        "iata": "PRY",
        "lon": "28.220833",
        "iso": "ZA",
        "status": 1,
        "name": "Wonderboom Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.654444",
        "size": "medium"
    },
    {
        "iata": "AFB",
        "iso": "ZA",
        "status": 1,
        "name": "Waterkloof Air Force Base Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "WEL",
        "lon": "26.75",
        "iso": "ZA",
        "status": 1,
        "name": "Welkom Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.0",
        "size": "medium"
    },
    {
        "iata": "FRW",
        "lon": "27.4825",
        "iso": "BW",
        "status": 1,
        "name": "Francistown Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.16",
        "size": "medium"
    },
    {
        "iata": "GNZ",
        "lon": "21.666668",
        "iso": "BW",
        "status": 1,
        "name": "Ghanzi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.7",
        "size": "small"
    },
    {
        "iata": "JWA",
        "lon": "24.666668",
        "iso": "BW",
        "status": 1,
        "name": "Jwaneng Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.6",
        "size": "medium"
    },
    {
        "iata": "BBK",
        "lon": "25.15",
        "iso": "BW",
        "status": 1,
        "name": "Kasane Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.816668",
        "size": "medium"
    },
    {
        "iata": "KHW",
        "lon": "23.5",
        "iso": "BW",
        "status": 1,
        "name": "Khwai River Lodge Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.0",
        "size": "small"
    },
    {
        "iata": "MUB",
        "lon": "23.430555",
        "iso": "BW",
        "status": 1,
        "name": "Maun Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.970833",
        "size": "medium"
    },
    {
        "iata": "ORP",
        "lon": "24.5",
        "iso": "BW",
        "status": 1,
        "name": "Orapa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.0",
        "size": "small"
    },
    {
        "iata": "QPH",
        "iso": "BW",
        "status": 1,
        "name": "Palapye Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "GBE",
        "lon": "25.92451",
        "iso": "BW",
        "status": 1,
        "name": "Sir Seretse Khama International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.557981",
        "size": "medium"
    },
    {
        "iata": "SXN",
        "lon": "26.116667",
        "iso": "BW",
        "status": 1,
        "name": "Sua Pan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.55",
        "size": "medium"
    },
    {
        "iata": "PKW",
        "lon": "27.816668",
        "iso": "BW",
        "status": 1,
        "name": "Selebi Phikwe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.05",
        "size": "medium"
    },
    {
        "iata": "SWX",
        "lon": "21.833332",
        "iso": "BW",
        "status": 1,
        "name": "Shakawe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.383333",
        "size": "small"
    },
    {
        "iata": "TLD",
        "lon": "29.116667",
        "iso": "BW",
        "status": 1,
        "name": "Limpopo Valley Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.183332",
        "size": "small"
    },
    {
        "iata": "TBY",
        "lon": "22.466667",
        "iso": "BW",
        "status": 1,
        "name": "Tshabong Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.066668",
        "size": "small"
    },
    {
        "iata": "BZV",
        "lon": "15.251139",
        "iso": "CG",
        "status": 1,
        "name": "Maya-Maya Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.258899",
        "size": "medium"
    },
    {
        "iata": "DJM",
        "lon": "14.75",
        "iso": "CG",
        "status": 1,
        "name": "Djambala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.533333",
        "size": "small"
    },
    {
        "iata": "KNJ",
        "lon": "14.516667",
        "iso": "CG",
        "status": 1,
        "name": "Kindamba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.95",
        "size": "small"
    },
    {
        "iata": "LCO",
        "lon": "14.533333",
        "iso": "CG",
        "status": 1,
        "name": "Lague Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.45",
        "size": "small"
    },
    {
        "iata": "MUY",
        "lon": "13.919444",
        "iso": "CG",
        "status": 1,
        "name": "Mouyondzi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.983333",
        "size": "small"
    },
    {
        "iata": "SIB",
        "lon": "13.4",
        "iso": "CG",
        "status": 1,
        "name": "Sibiti Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.733333",
        "size": "small"
    },
    {
        "iata": "NKY",
        "lon": "13.3",
        "iso": "CG",
        "status": 1,
        "name": "Yokangassi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.216667",
        "size": "small"
    },
    {
        "iata": "ANJ",
        "lon": "13.833333",
        "iso": "CG",
        "status": 1,
        "name": "Zanaga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.85",
        "size": "small"
    },
    {
        "iata": "MSX",
        "lon": "12.733333",
        "iso": "CG",
        "status": 1,
        "name": "Mossendjo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.95",
        "size": "small"
    },
    {
        "iata": "BOE",
        "lon": "15.383333",
        "iso": "CG",
        "status": 1,
        "name": "Boundji Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.033333",
        "size": "small"
    },
    {
        "iata": "EWO",
        "lon": "14.8",
        "iso": "CG",
        "status": 1,
        "name": "Ewo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.883333",
        "size": "small"
    },
    {
        "iata": "GMM",
        "lon": "15.866667",
        "iso": "CG",
        "status": 1,
        "name": "Gamboma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.933333",
        "size": "small"
    },
    {
        "iata": "ION",
        "lon": "18.061943",
        "iso": "CG",
        "status": 1,
        "name": "Impfondo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.613333",
        "size": "small"
    },
    {
        "iata": "KEE",
        "lon": "14.533333",
        "iso": "CG",
        "status": 1,
        "name": "Kelle Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.083333",
        "size": "small"
    },
    {
        "iata": "MKJ",
        "lon": "15.576667",
        "iso": "CG",
        "status": 1,
        "name": "Makoua Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.020278",
        "size": "small"
    },
    {
        "iata": "FTX",
        "lon": "16.008333",
        "iso": "CG",
        "status": 1,
        "name": "Owando Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.983333",
        "size": "medium"
    },
    {
        "iata": "SOE",
        "lon": "14.166667",
        "iso": "CG",
        "status": 1,
        "name": "Souanke Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.0",
        "size": "small"
    },
    {
        "iata": "BTB",
        "lon": "18.5",
        "iso": "CG",
        "status": 1,
        "name": "Betou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.05",
        "size": "small"
    },
    {
        "iata": "OUE",
        "lon": "16.04861",
        "iso": "CG",
        "status": 1,
        "name": "Ouesso Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.616667",
        "size": "medium"
    },
    {
        "iata": "KMK",
        "lon": "12.616667",
        "iso": "CG",
        "status": 1,
        "name": "Makabana Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.483333",
        "size": "small"
    },
    {
        "iata": "DIS",
        "lon": "12.661389",
        "iso": "CG",
        "status": 1,
        "name": "Loubomo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.205556",
        "size": "small"
    },
    {
        "iata": "PNR",
        "lon": "11.882377",
        "iso": "CG",
        "status": 1,
        "name": "Pointe Noire Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.809799",
        "size": "medium"
    },
    {
        "iata": "MTS",
        "lon": "31.314133",
        "iso": "SZ",
        "status": 1,
        "name": "Matsapha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.52033",
        "size": "medium"
    },
    {
        "iata": "FEA",
        "lon": "-0.966667",
        "iso": "GB",
        "status": 1,
        "name": "Fetlar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.583332",
        "size": "small"
    },
    {
        "iata": "CRF",
        "lon": "15.933333",
        "iso": "CF",
        "status": 1,
        "name": "Carnot Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.983333",
        "size": "small"
    },
    {
        "iata": "BGF",
        "lon": "18.520279",
        "iso": "CF",
        "status": 1,
        "name": "Bangui M'Poko International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.396111",
        "size": "medium"
    },
    {
        "iata": "BGU",
        "lon": "22.784445",
        "iso": "CF",
        "status": 1,
        "name": "Bangassou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.786667",
        "size": "small"
    },
    {
        "iata": "IRO",
        "lon": "22.713888",
        "iso": "CF",
        "status": 1,
        "name": "Birao Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.186389",
        "size": "small"
    },
    {
        "iata": "BEM",
        "lon": "17.65",
        "iso": "CF",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "5.266667",
        "size": "small"
    },
    {
        "iata": "BBY",
        "lon": "20.649721",
        "iso": "CF",
        "status": 1,
        "name": "Bambari Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.845833",
        "size": "small"
    },
    {
        "iata": "NDL",
        "lon": "20.6",
        "iso": "CF",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "8.416667",
        "size": "small"
    },
    {
        "iata": "BOP",
        "lon": "15.666667",
        "iso": "CF",
        "status": 1,
        "name": "Bouar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.0",
        "size": "small"
    },
    {
        "iata": "BIV",
        "lon": "21.983334",
        "iso": "CF",
        "status": 1,
        "name": "Bria Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.533333",
        "size": "small"
    },
    {
        "iata": "BSN",
        "lon": "17.5",
        "iso": "CF",
        "status": 1,
        "name": "Bossangoa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.583333",
        "size": "small"
    },
    {
        "iata": "BBT",
        "lon": "15.788056",
        "iso": "CF",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "4.219167",
        "size": "medium"
    },
    {
        "iata": "ODA",
        "lon": "22.401667",
        "iso": "CF",
        "status": 1,
        "name": "Ouadda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.003889",
        "size": "small"
    },
    {
        "iata": "AIG",
        "lon": "23.25",
        "iso": "CF",
        "status": 1,
        "name": "Yalinga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.516667",
        "size": "small"
    },
    {
        "iata": "IMO",
        "lon": "25.083332",
        "iso": "CF",
        "status": 1,
        "name": "Zemio Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.033333",
        "size": "small"
    },
    {
        "iata": "MKI",
        "lon": "25.95",
        "iso": "CF",
        "status": 1,
        "name": "M'Boki Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.316667",
        "size": "small"
    },
    {
        "iata": "BTG",
        "lon": "18.333332",
        "iso": "CF",
        "status": 1,
        "name": "Batangafo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.416667",
        "size": "small"
    },
    {
        "iata": "GDI",
        "lon": "21.583332",
        "iso": "CF",
        "status": 1,
        "name": "Gordil Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.733333",
        "size": "small"
    },
    {
        "iata": "BMF",
        "lon": "22.8",
        "iso": "CF",
        "status": 1,
        "name": "Bakouma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.733333",
        "size": "small"
    },
    {
        "iata": "ODJ",
        "lon": "22.883333",
        "iso": "CF",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "8.916667",
        "size": "small"
    },
    {
        "iata": "RFA",
        "lon": "23.966667",
        "iso": "CF",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "4.983333",
        "size": "small"
    },
    {
        "iata": "BCF",
        "lon": "18.416668",
        "iso": "CF",
        "status": 1,
        "name": "Bouca Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.75",
        "size": "small"
    },
    {
        "iata": "BOZ",
        "lon": "16.583332",
        "iso": "CF",
        "status": 1,
        "name": "Bozoum Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.416667",
        "size": "small"
    },
    {
        "iata": "FEW",
        "iso": "US",
        "status": 1,
        "name": "Francis E Warren Air Force Base",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "BSG",
        "lon": "9.802222",
        "iso": "GQ",
        "status": 1,
        "name": "Bata Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.906667",
        "size": "medium"
    },
    {
        "iata": "SSG",
        "lon": "8.716096",
        "iso": "GQ",
        "status": 1,
        "name": "Malabo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.757791",
        "size": "medium"
    },
    {
        "iata": "ASI",
        "lon": "-14.4",
        "iso": "SH",
        "status": 1,
        "name": "RAF Ascension Island",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.966667",
        "size": "medium"
    },
    {
        "iata": "MRU",
        "lon": "57.67663",
        "iso": "MU",
        "status": 1,
        "name": "Sir Seewoosagur Ramgoolam International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.431997",
        "size": "medium"
    },
    {
        "iata": "RRG",
        "lon": "63.35",
        "iso": "MU",
        "status": 1,
        "name": "Sir Charles Gaetan Duval Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.75",
        "size": "medium"
    },
    {
        "iata": "FIN",
        "lon": "147.86389",
        "iso": "PG",
        "status": 1,
        "name": "Finschhafen Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.6275",
        "size": "small"
    },
    {
        "iata": "NKW",
        "iso": "IO",
        "status": 1,
        "name": "Diego Garcia Naval Support Facility",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "NKS",
        "lon": "9.933333",
        "iso": "CM",
        "status": 1,
        "name": "Nkongsamba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.95",
        "size": "small"
    },
    {
        "iata": "KBI",
        "lon": "9.916667",
        "iso": "CM",
        "status": 1,
        "name": "Kribi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.95",
        "size": "small"
    },
    {
        "iata": "TKC",
        "lon": "9.333333",
        "iso": "CM",
        "status": 1,
        "name": "Tiko Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.116667",
        "size": "medium"
    },
    {
        "iata": "DLA",
        "lon": "9.717018",
        "iso": "CM",
        "status": 1,
        "name": "Douala International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.01346",
        "size": "medium"
    },
    {
        "iata": "MMF",
        "lon": "9.3",
        "iso": "CM",
        "status": 1,
        "name": "Mamfe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.716667",
        "size": "small"
    },
    {
        "iata": "KLE",
        "lon": "14.45",
        "iso": "CM",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "10.116667",
        "size": "small"
    },
    {
        "iata": "OUR",
        "lon": "14.363611",
        "iso": "CM",
        "status": 1,
        "name": "Batouri Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.473056",
        "size": "small"
    },
    {
        "iata": "GXX",
        "lon": "15.2375",
        "iso": "CM",
        "status": 1,
        "name": "Yagoua Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.358333",
        "size": "small"
    },
    {
        "iata": "MVR",
        "lon": "14.256389",
        "iso": "CM",
        "status": 1,
        "name": "Salak Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.452222",
        "size": "medium"
    },
    {
        "iata": "FOM",
        "lon": "10.833333",
        "iso": "CM",
        "status": 1,
        "name": "Foumban Nkounja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.75",
        "size": "medium"
    },
    {
        "iata": "NGE",
        "lon": "13.561389",
        "iso": "CM",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "7.356389",
        "size": "medium"
    },
    {
        "iata": "BTA",
        "lon": "13.7275",
        "iso": "CM",
        "status": 1,
        "name": "Bertoua Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.540833",
        "size": "small"
    },
    {
        "iata": "GOU",
        "lon": "13.375556",
        "iso": "CM",
        "status": 1,
        "name": "Garoua International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.336111",
        "size": "medium"
    },
    {
        "iata": "DSC",
        "lon": "10.091667",
        "iso": "CM",
        "status": 1,
        "name": "Dschang Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.833333",
        "size": "small"
    },
    {
        "iata": "BFX",
        "lon": "10.4",
        "iso": "CM",
        "status": 1,
        "name": "Bafoussam Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.483333",
        "size": "medium"
    },
    {
        "iata": "BPC",
        "lon": "10.15",
        "iso": "CM",
        "status": 1,
        "name": "Bamenda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.916667",
        "size": "medium"
    },
    {
        "iata": "EBW",
        "lon": "11.166667",
        "iso": "CM",
        "status": 1,
        "name": "Ebolowa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.916667",
        "size": "small"
    },
    {
        "iata": "YAO",
        "lon": "11.523611",
        "iso": "CM",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "3.833611",
        "size": "medium"
    },
    {
        "iata": "NSI",
        "lon": "11.550278",
        "iso": "CM",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "3.702222",
        "size": "medium"
    },
    {
        "iata": "MMQ",
        "lon": "31.336111",
        "iso": "ZM",
        "status": 1,
        "name": "Mbala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.857222",
        "size": "small"
    },
    {
        "iata": "CIP",
        "lon": "32.587223",
        "iso": "ZM",
        "status": 1,
        "name": "Chipata Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.556944",
        "size": "small"
    },
    {
        "iata": "ZKP",
        "lon": "150.70778",
        "iso": "ZM",
        "status": 1,
        "name": "Kasompe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "65.738335",
        "size": "medium"
    },
    {
        "iata": "KLB",
        "lon": "22.644444",
        "iso": "ZM",
        "status": 1,
        "name": "Kalabo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.975833",
        "size": "small"
    },
    {
        "iata": "KMZ",
        "lon": "24.803333",
        "iso": "ZM",
        "status": 1,
        "name": "Kaoma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.798889",
        "size": "small"
    },
    {
        "iata": "KAA",
        "lon": "31.13",
        "iso": "ZM",
        "status": 1,
        "name": "Kasama Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.215833",
        "size": "small"
    },
    {
        "iata": "ZKB",
        "lon": "30.659721",
        "iso": "ZM",
        "status": 1,
        "name": "Kasaba Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.522222",
        "size": "small"
    },
    {
        "iata": "LVI",
        "lon": "25.822222",
        "iso": "ZM",
        "status": 1,
        "name": "Livingstone Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.820557",
        "size": "medium"
    },
    {
        "iata": "LXU",
        "lon": "23.2",
        "iso": "ZM",
        "status": 1,
        "name": "Lukulu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.416667",
        "size": "small"
    },
    {
        "iata": "LUN",
        "lon": "28.445747",
        "iso": "ZM",
        "status": 1,
        "name": "Lusaka International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.325017",
        "size": "medium"
    },
    {
        "iata": "MNS",
        "lon": "28.866667",
        "iso": "ZM",
        "status": 1,
        "name": "Mansa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.125",
        "size": "small"
    },
    {
        "iata": "MFU",
        "lon": "31.933332",
        "iso": "ZM",
        "status": 1,
        "name": "Mfuwe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.266667",
        "size": "medium"
    },
    {
        "iata": "MNR",
        "lon": "23.157778",
        "iso": "ZM",
        "status": 1,
        "name": "Mongu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.225556",
        "size": "medium"
    },
    {
        "iata": "ZGM",
        "lon": "25.934444",
        "iso": "ZM",
        "status": 1,
        "name": "Ngoma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.958333",
        "size": "small"
    },
    {
        "iata": "NLA",
        "lon": "28.665024",
        "iso": "ZM",
        "status": 1,
        "name": "Ndola Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.993449",
        "size": "medium"
    },
    {
        "iata": "SXG",
        "lon": "23.281944",
        "iso": "ZM",
        "status": 1,
        "name": "Senanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.119444",
        "size": "small"
    },
    {
        "iata": "KIW",
        "lon": "28.230556",
        "iso": "ZM",
        "status": 1,
        "name": "Southdowns Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.839444",
        "size": "medium"
    },
    {
        "iata": "SJQ",
        "lon": "24.283333",
        "iso": "ZM",
        "status": 1,
        "name": "Sesheke Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.483334",
        "size": "small"
    },
    {
        "iata": "SLI",
        "lon": "26.366667",
        "iso": "ZM",
        "status": 1,
        "name": "Solwesi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.172222",
        "size": "small"
    },
    {
        "iata": "BBZ",
        "lon": "23.11",
        "iso": "ZM",
        "status": 1,
        "name": "Zambezi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.537222",
        "size": "small"
    },
    {
        "iata": "ULI",
        "lon": "139.8",
        "iso": "FM",
        "status": 1,
        "name": "Falalop Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "10.016667",
        "size": "small"
    },
    {
        "iata": "HAH",
        "lon": "43.27492",
        "iso": "KM",
        "status": 1,
        "name": "Prince Said Ibrahim International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.537275",
        "size": "medium"
    },
    {
        "iata": "NWA",
        "lon": "43.767155",
        "iso": "KM",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "-12.298664",
        "size": "medium"
    },
    {
        "iata": "YVA",
        "lon": "43.261112",
        "iso": "KM",
        "status": 1,
        "name": "Iconi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.531944",
        "size": "small"
    },
    {
        "iata": "AJN",
        "lon": "44.430473",
        "iso": "KM",
        "status": 1,
        "name": "Ouani Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.131109",
        "size": "medium"
    },
    {
        "iata": "DZA",
        "lon": "45.2821",
        "iso": "YT",
        "status": 1,
        "name": "Dzaoudzi Pamandzi International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.804901",
        "size": "medium"
    },
    {
        "iata": "RUN",
        "lon": "55.511875",
        "iso": "RE",
        "status": 1,
        "name": "Roland Garros Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.892",
        "size": "medium"
    },
    {
        "iata": "ZSE",
        "lon": "55.42688",
        "iso": "RE",
        "status": 1,
        "name": "Pierrefonds Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.320944",
        "size": "medium"
    },
    {
        "iata": "WML",
        "lon": "45.55",
        "iso": "MG",
        "status": 1,
        "name": "Malaimbandy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.35",
        "size": "small"
    },
    {
        "iata": "ATJ",
        "lon": "47.016666",
        "iso": "MG",
        "status": 1,
        "name": "Antsirabe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.85",
        "size": "small"
    },
    {
        "iata": "WAQ",
        "lon": "44.61667",
        "iso": "MG",
        "status": 1,
        "name": "Antsalova Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.7",
        "size": "small"
    },
    {
        "iata": "VVB",
        "lon": "48.8",
        "iso": "MG",
        "status": 1,
        "name": "Mahanoro Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.833332",
        "size": "small"
    },
    {
        "iata": "TNR",
        "lon": "47.47503",
        "iso": "MG",
        "status": 1,
        "name": "Ivato Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.799631",
        "size": "medium"
    },
    {
        "iata": "JVA",
        "lon": "45.283333",
        "iso": "MG",
        "status": 1,
        "name": "Ankavandra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.8",
        "size": "small"
    },
    {
        "iata": "BMD",
        "lon": "44.05",
        "iso": "MG",
        "status": 1,
        "name": "Belo sur Tsiribihina Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.733334",
        "size": "small"
    },
    {
        "iata": "ZVA",
        "lon": "45.466667",
        "iso": "MG",
        "status": 1,
        "name": "Miandrivazo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.5",
        "size": "medium"
    },
    {
        "iata": "MXT",
        "lon": "44.03278",
        "iso": "MG",
        "status": 1,
        "name": "Maintirano Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.047777",
        "size": "small"
    },
    {
        "iata": "ILK",
        "lon": "47.166668",
        "iso": "MG",
        "status": 1,
        "name": "Atsinanana Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.333332",
        "size": "small"
    },
    {
        "iata": "TVA",
        "lon": "44.919167",
        "iso": "MG",
        "status": 1,
        "name": "Morafenobe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.849443",
        "size": "small"
    },
    {
        "iata": "SMS",
        "lon": "49.816666",
        "iso": "MG",
        "status": 1,
        "name": "Sainte Marie Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.083332",
        "size": "medium"
    },
    {
        "iata": "TMM",
        "lon": "49.392223",
        "iso": "MG",
        "status": 1,
        "name": "Toamasina Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.11",
        "size": "medium"
    },
    {
        "iata": "WTA",
        "lon": "43.966667",
        "iso": "MG",
        "status": 1,
        "name": "Tambohorano Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.483334",
        "size": "small"
    },
    {
        "iata": "MOQ",
        "lon": "44.318333",
        "iso": "MG",
        "status": 1,
        "name": "Morondava Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.283611",
        "size": "medium"
    },
    {
        "iata": "WTS",
        "lon": "46.054443",
        "iso": "MG",
        "status": 1,
        "name": "Tsiroanomandidy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.757221",
        "size": "small"
    },
    {
        "iata": "VAT",
        "lon": "48.833332",
        "iso": "MG",
        "status": 1,
        "name": "Vatomandry Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.283333",
        "size": "small"
    },
    {
        "iata": "WAM",
        "lon": "48.433334",
        "iso": "MG",
        "status": 1,
        "name": "Ambatondrazaka Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.8",
        "size": "small"
    },
    {
        "iata": "DIE",
        "lon": "49.2925",
        "iso": "MG",
        "status": 1,
        "name": "Arrachart Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.346111",
        "size": "medium"
    },
    {
        "iata": "WMR",
        "lon": "49.774967",
        "iso": "MG",
        "status": 1,
        "name": "Mananara Nord Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.164106",
        "size": "medium"
    },
    {
        "iata": "ZWA",
        "lon": "49.61667",
        "iso": "MG",
        "status": 1,
        "name": "Andapa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.65",
        "size": "medium"
    },
    {
        "iata": "AMB",
        "lon": "48.983334",
        "iso": "MG",
        "status": 1,
        "name": "Ambilobe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.183333",
        "size": "small"
    },
    {
        "iata": "WBD",
        "lon": "48.483334",
        "iso": "MG",
        "status": 1,
        "name": "Avaratra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.2",
        "size": "small"
    },
    {
        "iata": "WPB",
        "lon": "47.666668",
        "iso": "MG",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "-15.55",
        "size": "small"
    },
    {
        "iata": "ANM",
        "lon": "50.316666",
        "iso": "MG",
        "status": 1,
        "name": "Antsirabato Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.0",
        "size": "medium"
    },
    {
        "iata": "IVA",
        "lon": "48.45778",
        "iso": "MG",
        "status": 1,
        "name": "Ambanja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.642222",
        "size": "small"
    },
    {
        "iata": "HVA",
        "lon": "47.766666",
        "iso": "MG",
        "status": 1,
        "name": "Analalava Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.633333",
        "size": "medium"
    },
    {
        "iata": "MJN",
        "lon": "46.353058",
        "iso": "MG",
        "status": 1,
        "name": "Amborovy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.665833",
        "size": "medium"
    },
    {
        "iata": "NOS",
        "lon": "48.31389",
        "iso": "MG",
        "status": 1,
        "name": "Fascene Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.311111",
        "size": "medium"
    },
    {
        "iata": "DWB",
        "lon": "45.36667",
        "iso": "MG",
        "status": 1,
        "name": "Soalala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.1",
        "size": "small"
    },
    {
        "iata": "WMP",
        "lon": "47.59722",
        "iso": "MG",
        "status": 1,
        "name": "Mampikony Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.075",
        "size": "small"
    },
    {
        "iata": "BPY",
        "lon": "44.483334",
        "iso": "MG",
        "status": 1,
        "name": "Besalampy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.75",
        "size": "medium"
    },
    {
        "iata": "WMN",
        "lon": "49.683334",
        "iso": "MG",
        "status": 1,
        "name": "Maroantsetra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.433333",
        "size": "medium"
    },
    {
        "iata": "SVB",
        "lon": "50.175",
        "iso": "MG",
        "status": 1,
        "name": "Sambava Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.276944",
        "size": "medium"
    },
    {
        "iata": "TTS",
        "lon": "47.6",
        "iso": "MG",
        "status": 1,
        "name": "Tsaratanana Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.733334",
        "size": "small"
    },
    {
        "iata": "VOH",
        "lon": "50.0",
        "iso": "MG",
        "status": 1,
        "name": "Vohimarina Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.366667",
        "size": "medium"
    },
    {
        "iata": "WAI",
        "lon": "47.95",
        "iso": "MG",
        "status": 1,
        "name": "Ambalabe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.916667",
        "size": "medium"
    },
    {
        "iata": "WMA",
        "lon": "48.833332",
        "iso": "MG",
        "status": 1,
        "name": "Mandritsara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.833333",
        "size": "small"
    },
    {
        "iata": "WBO",
        "lon": "45.13333",
        "iso": "MG",
        "status": 1,
        "name": "Antsoa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.6",
        "size": "small"
    },
    {
        "iata": "WMD",
        "lon": "44.95",
        "iso": "MG",
        "status": 1,
        "name": "Mandabe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.033333",
        "size": "small"
    },
    {
        "iata": "FTU",
        "lon": "46.955555",
        "iso": "MG",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "-25.038055",
        "size": "medium"
    },
    {
        "iata": "WFI",
        "lon": "47.083332",
        "iso": "MG",
        "status": 1,
        "name": "Fianarantsoa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.466667",
        "size": "medium"
    },
    {
        "iata": "RVA",
        "lon": "47.819443",
        "iso": "MG",
        "status": 1,
        "name": "Farafangana Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.801945",
        "size": "small"
    },
    {
        "iata": "IHO",
        "lon": "46.11667",
        "iso": "MG",
        "status": 1,
        "name": "Ihosy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.416668",
        "size": "small"
    },
    {
        "iata": "MJA",
        "lon": "44.316666",
        "iso": "MG",
        "status": 1,
        "name": "Manja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.416668",
        "size": "small"
    },
    {
        "iata": "WVK",
        "lon": "48.016666",
        "iso": "MG",
        "status": 1,
        "name": "Manakara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.116667",
        "size": "medium"
    },
    {
        "iata": "OVA",
        "lon": "45.305",
        "iso": "MG",
        "status": 1,
        "name": "Bekily Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.2325",
        "size": "small"
    },
    {
        "iata": "MNJ",
        "lon": "48.36667",
        "iso": "MG",
        "status": 1,
        "name": "Mananjary Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.2",
        "size": "medium"
    },
    {
        "iata": "TDV",
        "lon": "43.733334",
        "iso": "MG",
        "status": 1,
        "name": "Samangoky Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.7",
        "size": "small"
    },
    {
        "iata": "MXM",
        "lon": "43.37574",
        "iso": "MG",
        "status": 1,
        "name": "Morombe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.751705",
        "size": "medium"
    },
    {
        "iata": "TLE",
        "lon": "43.724167",
        "iso": "MG",
        "status": 1,
        "name": "Toliara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.389723",
        "size": "medium"
    },
    {
        "iata": "BKU",
        "lon": "44.39111",
        "iso": "MG",
        "status": 1,
        "name": "Betioky Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.732222",
        "size": "small"
    },
    {
        "iata": "AMP",
        "lon": "44.733334",
        "iso": "MG",
        "status": 1,
        "name": "Ampanihy Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.7",
        "size": "small"
    },
    {
        "iata": "WAK",
        "lon": "44.533333",
        "iso": "MG",
        "status": 1,
        "name": "Ankazoabo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.3",
        "size": "small"
    },
    {
        "iata": "AZZ",
        "lon": "13.15",
        "iso": "AO",
        "status": 1,
        "name": "Ambriz Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.883333",
        "size": "small"
    },
    {
        "iata": "SSY",
        "lon": "14.244167",
        "iso": "AO",
        "status": 1,
        "name": "Mbanza Congo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.273889",
        "size": "medium"
    },
    {
        "iata": "BUG",
        "lon": "13.40194",
        "iso": "AO",
        "status": 1,
        "name": "Benguela Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.606427",
        "size": "medium"
    },
    {
        "iata": "CAB",
        "lon": "12.2",
        "iso": "AO",
        "status": 1,
        "name": "Cabinda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.583333",
        "size": "medium"
    },
    {
        "iata": "CFF",
        "lon": "18.0",
        "iso": "AO",
        "status": 1,
        "name": "Cafunfo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.766667",
        "size": "small"
    },
    {
        "iata": "PGI",
        "lon": "20.8",
        "iso": "AO",
        "status": 1,
        "name": "Chitato Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.333333",
        "size": "small"
    },
    {
        "iata": "CBT",
        "lon": "13.483333",
        "iso": "AO",
        "status": 1,
        "name": "Catumbela Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.483333",
        "size": "medium"
    },
    {
        "iata": "CTI",
        "lon": "19.166668",
        "iso": "AO",
        "status": 1,
        "name": "Cuito Cuanavale Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.166667",
        "size": "small"
    },
    {
        "iata": "CXM",
        "iso": "AO",
        "status": 1,
        "name": "Camaxilo Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CAV",
        "lon": "22.866667",
        "iso": "AO",
        "status": 1,
        "name": "Cazombo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.9",
        "size": "small"
    },
    {
        "iata": "DUE",
        "lon": "20.816668",
        "iso": "AO",
        "status": 1,
        "name": "Dundo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.4",
        "size": "medium"
    },
    {
        "iata": "VPE",
        "lon": "15.7",
        "iso": "AO",
        "status": 1,
        "name": "Ngjiva Pereira Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.05",
        "size": "medium"
    },
    {
        "iata": "NOV",
        "lon": "15.757222",
        "iso": "AO",
        "status": 1,
        "name": "Nova Lisboa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.805556",
        "size": "medium"
    },
    {
        "iata": "SVP",
        "lon": "16.956667",
        "iso": "AO",
        "status": 1,
        "name": "Kuito Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.404167",
        "size": "medium"
    },
    {
        "iata": "LAD",
        "lon": "13.234862",
        "iso": "AO",
        "status": 1,
        "name": "Quatro De Fevereiro Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.847951",
        "size": "medium"
    },
    {
        "iata": "MEG",
        "lon": "16.333332",
        "iso": "AO",
        "status": 1,
        "name": "Malanje Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.533333",
        "size": "medium"
    },
    {
        "iata": "SPP",
        "lon": "17.725555",
        "iso": "AO",
        "status": 1,
        "name": "Menongue Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.6375",
        "size": "medium"
    },
    {
        "iata": "MSZ",
        "lon": "12.150872",
        "iso": "AO",
        "status": 1,
        "name": "Namibe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.258293",
        "size": "medium"
    },
    {
        "iata": "GXG",
        "lon": "15.45",
        "iso": "AO",
        "status": 1,
        "name": "Negage Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.783333",
        "size": "medium"
    },
    {
        "iata": "PBN",
        "lon": "13.75",
        "iso": "AO",
        "status": 1,
        "name": "Porto Amboim Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.7",
        "size": "medium"
    },
    {
        "iata": "VHC",
        "lon": "20.55",
        "iso": "AO",
        "status": 1,
        "name": "Saurimo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.75",
        "size": "medium"
    },
    {
        "iata": "SZA",
        "lon": "12.375155",
        "iso": "AO",
        "status": 1,
        "name": "Soyo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.140637",
        "size": "medium"
    },
    {
        "iata": "NDD",
        "lon": "13.866667",
        "iso": "AO",
        "status": 1,
        "name": "Sumbe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.166667",
        "size": "medium"
    },
    {
        "iata": "UAL",
        "lon": "22.5",
        "iso": "AO",
        "status": 1,
        "name": "Luau Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.0",
        "size": "small"
    },
    {
        "iata": "SDD",
        "lon": "13.576667",
        "iso": "AO",
        "status": 1,
        "name": "Lubango Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.924444",
        "size": "medium"
    },
    {
        "iata": "LUO",
        "lon": "20.166668",
        "iso": "AO",
        "status": 1,
        "name": "Luena Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.5",
        "size": "medium"
    },
    {
        "iata": "UGO",
        "lon": "15.15",
        "iso": "AO",
        "status": 1,
        "name": "Uige Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.816667",
        "size": "medium"
    },
    {
        "iata": "CEO",
        "lon": "15.101389",
        "iso": "AO",
        "status": 1,
        "name": "Waco Kungo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.426389",
        "size": "small"
    },
    {
        "iata": "XGN",
        "lon": "14.972222",
        "iso": "AO",
        "status": 1,
        "name": "Xangongo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.75",
        "size": "medium"
    },
    {
        "iata": "ARZ",
        "lon": "13.5",
        "iso": "AO",
        "status": 1,
        "name": "N'zeto Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.5",
        "size": "small"
    },
    {
        "iata": "BGB",
        "lon": "11.938889",
        "iso": "GA",
        "status": 1,
        "name": "Booue Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.106667",
        "size": "small"
    },
    {
        "iata": "KDN",
        "lon": "11.166667",
        "iso": "GA",
        "status": 1,
        "name": "Ndende Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.5",
        "size": "small"
    },
    {
        "iata": "FOU",
        "lon": "10.783333",
        "iso": "GA",
        "status": 1,
        "name": "Fougamou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.2",
        "size": "small"
    },
    {
        "iata": "MBC",
        "lon": "12.0",
        "iso": "GA",
        "status": 1,
        "name": "M'Bigou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.0",
        "size": "small"
    },
    {
        "iata": "MGX",
        "lon": "11.0",
        "iso": "GA",
        "status": 1,
        "name": "Moabi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.25",
        "size": "small"
    },
    {
        "iata": "KDJ",
        "lon": "10.766667",
        "iso": "GA",
        "status": 1,
        "name": "Ville Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.15",
        "size": "small"
    },
    {
        "iata": "KOU",
        "lon": "12.508333",
        "iso": "GA",
        "status": 1,
        "name": "Koulamoutou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.116667",
        "size": "medium"
    },
    {
        "iata": "MJL",
        "lon": "10.924167",
        "iso": "GA",
        "status": 1,
        "name": "Mouilla Ville Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.817778",
        "size": "medium"
    },
    {
        "iata": "OYE",
        "lon": "11.583333",
        "iso": "GA",
        "status": 1,
        "name": "Oyem Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.566667",
        "size": "medium"
    },
    {
        "iata": "OKN",
        "lon": "14.008333",
        "iso": "GA",
        "status": 1,
        "name": "Okondja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.0",
        "size": "medium"
    },
    {
        "iata": "LBQ",
        "lon": "10.239444",
        "iso": "GA",
        "status": 1,
        "name": "Lambarene Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.714722",
        "size": "medium"
    },
    {
        "iata": "MVX",
        "lon": "12.133333",
        "iso": "GA",
        "status": 1,
        "name": "Minvoul Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.15",
        "size": "small"
    },
    {
        "iata": "BMM",
        "lon": "11.490833",
        "iso": "GA",
        "status": 1,
        "name": "Bitam Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.075833",
        "size": "medium"
    },
    {
        "iata": "MFF",
        "lon": "13.270833",
        "iso": "GA",
        "status": 1,
        "name": "Moanda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.536944",
        "size": "small"
    },
    {
        "iata": "MKB",
        "lon": "13.833333",
        "iso": "GA",
        "status": 1,
        "name": "Mekambo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.05",
        "size": "small"
    },
    {
        "iata": "POG",
        "lon": "8.753584",
        "iso": "GA",
        "status": 1,
        "name": "Port Gentil Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.719964",
        "size": "medium"
    },
    {
        "iata": "OMB",
        "lon": "9.266667",
        "iso": "GA",
        "status": 1,
        "name": "Omboue Hopital Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.6",
        "size": "medium"
    },
    {
        "iata": "MKU",
        "lon": "12.861111",
        "iso": "GA",
        "status": 1,
        "name": "Makokou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.559167",
        "size": "medium"
    },
    {
        "iata": "LBV",
        "lon": "9.409852",
        "iso": "GA",
        "status": 1,
        "name": "Leon M Ba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.456963",
        "size": "medium"
    },
    {
        "iata": "MZC",
        "lon": "11.566667",
        "iso": "GA",
        "status": 1,
        "name": "Mitzic Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.783333",
        "size": "small"
    },
    {
        "iata": "MVB",
        "lon": "13.433333",
        "iso": "GA",
        "status": 1,
        "name": "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.65",
        "size": "medium"
    },
    {
        "iata": "LTL",
        "lon": "12.716667",
        "iso": "GA",
        "status": 1,
        "name": "Lastourville Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.833333",
        "size": "small"
    },
    {
        "iata": "ZKM",
        "lon": "9.75",
        "iso": "GA",
        "status": 1,
        "name": "Sette Cama Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.533333",
        "size": "small"
    },
    {
        "iata": "TCH",
        "lon": "11.0",
        "iso": "GA",
        "status": 1,
        "name": "Tchibanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.816667",
        "size": "small"
    },
    {
        "iata": "MYB",
        "lon": "10.683333",
        "iso": "GA",
        "status": 1,
        "name": "Mayumba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.45",
        "size": "small"
    },
    {
        "iata": "PCP",
        "lon": "7.416667",
        "iso": "ST",
        "status": 1,
        "name": "Principe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.666667",
        "size": "medium"
    },
    {
        "iata": "TMS",
        "lon": "6.725",
        "iso": "ST",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "0.378333",
        "size": "medium"
    },
    {
        "iata": "ANO",
        "lon": "39.936943",
        "iso": "MZ",
        "status": 1,
        "name": "Angoche Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.177778",
        "size": "small"
    },
    {
        "iata": "BEW",
        "lon": "34.90192",
        "iso": "MZ",
        "status": 1,
        "name": "Beira Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.798805",
        "size": "medium"
    },
    {
        "iata": "FXO",
        "lon": "36.526943",
        "iso": "MZ",
        "status": 1,
        "name": "Cuamba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.805278",
        "size": "small"
    },
    {
        "iata": "VPY",
        "lon": "33.483334",
        "iso": "MZ",
        "status": 1,
        "name": "Chimoio Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.133333",
        "size": "medium"
    },
    {
        "iata": "IHC",
        "lon": "32.933334",
        "iso": "MZ",
        "status": 1,
        "name": "Inhaca Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.0",
        "size": "small"
    },
    {
        "iata": "INH",
        "lon": "35.408333",
        "iso": "MZ",
        "status": 1,
        "name": "Inhambane Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-23.874443",
        "size": "medium"
    },
    {
        "iata": "VXC",
        "lon": "35.25",
        "iso": "MZ",
        "status": 1,
        "name": "Lichinga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.283333",
        "size": "medium"
    },
    {
        "iata": "MPM",
        "lon": "32.57429",
        "iso": "MZ",
        "status": 1,
        "name": "Maputo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.924389",
        "size": "medium"
    },
    {
        "iata": "MUD",
        "lon": "39.516666",
        "iso": "MZ",
        "status": 1,
        "name": "Mueda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.666667",
        "size": "medium"
    },
    {
        "iata": "MZB",
        "lon": "40.356075",
        "iso": "MZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "-11.360261",
        "size": "medium"
    },
    {
        "iata": "MNC",
        "lon": "40.70861",
        "iso": "MZ",
        "status": 1,
        "name": "Nacala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.48",
        "size": "small"
    },
    {
        "iata": "APL",
        "lon": "39.287224",
        "iso": "MZ",
        "status": 1,
        "name": "Nampula Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.101667",
        "size": "medium"
    },
    {
        "iata": "POL",
        "lon": "40.5225",
        "iso": "MZ",
        "status": 1,
        "name": "Pemba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.988333",
        "size": "medium"
    },
    {
        "iata": "UEL",
        "lon": "36.865555",
        "iso": "MZ",
        "status": 1,
        "name": "Quelimane Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.847221",
        "size": "medium"
    },
    {
        "iata": "TET",
        "lon": "33.63889",
        "iso": "MZ",
        "status": 1,
        "name": "Chingozi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.103333",
        "size": "medium"
    },
    {
        "iata": "VNX",
        "lon": "35.283333",
        "iso": "MZ",
        "status": 1,
        "name": "Vilankulo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.016666",
        "size": "medium"
    },
    {
        "iata": "VJB",
        "lon": "33.61667",
        "iso": "MZ",
        "status": 1,
        "name": "Xai-Xai Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.033333",
        "size": "small"
    },
    {
        "iata": "JCA",
        "iso": "FR",
        "status": 1,
        "name": "Croisette Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "DES",
        "lon": "53.683334",
        "iso": "SC",
        "status": 1,
        "name": "Desroches Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.683333",
        "size": "small"
    },
    {
        "iata": "SEZ",
        "lon": "55.51128",
        "iso": "SC",
        "status": 1,
        "name": "Seychelles International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.671275",
        "size": "medium"
    },
    {
        "iata": "PRI",
        "lon": "55.692314",
        "iso": "SC",
        "status": 1,
        "name": "Praslin Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.323883",
        "size": "medium"
    },
    {
        "iata": "BDI",
        "lon": "55.216667",
        "iso": "SC",
        "status": 1,
        "name": "Bird Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.716667",
        "size": "small"
    },
    {
        "iata": "DEI",
        "lon": "55.666668",
        "iso": "SC",
        "status": 1,
        "name": "Denis Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.8",
        "size": "small"
    },
    {
        "iata": "FRK",
        "lon": "55.966667",
        "iso": "SC",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "-4.583333",
        "size": "small"
    },
    {
        "iata": "SRH",
        "lon": "18.383333",
        "iso": "TD",
        "status": 1,
        "name": "Sarh Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.083333",
        "size": "small"
    },
    {
        "iata": "OGR",
        "lon": "15.383333",
        "iso": "TD",
        "status": 1,
        "name": "Bongor Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.289444",
        "size": "small"
    },
    {
        "iata": "AEH",
        "lon": "20.850834",
        "iso": "TD",
        "status": 1,
        "name": "Abeche Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.851389",
        "size": "medium"
    },
    {
        "iata": "MQQ",
        "lon": "16.073334",
        "iso": "TD",
        "status": 1,
        "name": "Moundou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.625",
        "size": "medium"
    },
    {
        "iata": "LTC",
        "lon": "16.3",
        "iso": "TD",
        "status": 1,
        "name": "Lai Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.416667",
        "size": "small"
    },
    {
        "iata": "ATV",
        "lon": "18.3075",
        "iso": "TD",
        "status": 1,
        "name": "Ati Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.242222",
        "size": "small"
    },
    {
        "iata": "NDJ",
        "lon": "15.034215",
        "iso": "TD",
        "status": 1,
        "name": "N'Djamena International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.129056",
        "size": "medium"
    },
    {
        "iata": "BKR",
        "lon": "17.233334",
        "iso": "TD",
        "status": 1,
        "name": "Bokoro Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.416667",
        "size": "small"
    },
    {
        "iata": "OTC",
        "lon": "14.733333",
        "iso": "TD",
        "status": 1,
        "name": "Bol Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.433333",
        "size": "small"
    },
    {
        "iata": "MVO",
        "lon": "18.783333",
        "iso": "TD",
        "status": 1,
        "name": "Mongo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.2",
        "size": "small"
    },
    {
        "iata": "AMC",
        "lon": "20.283333",
        "iso": "TD",
        "status": 1,
        "name": "Am Timan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.033333",
        "size": "small"
    },
    {
        "iata": "PLF",
        "lon": "14.933333",
        "iso": "TD",
        "status": 1,
        "name": "Pala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.383333",
        "size": "small"
    },
    {
        "iata": "OUT",
        "lon": "16.716667",
        "iso": "TD",
        "status": 1,
        "name": "Bousso Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.483333",
        "size": "small"
    },
    {
        "iata": "AMO",
        "lon": "15.316667",
        "iso": "TD",
        "status": 1,
        "name": "Mao Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.116667",
        "size": "small"
    },
    {
        "iata": "FYT",
        "lon": "19.116667",
        "iso": "TD",
        "status": 1,
        "name": "Faya Largeau Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "17.916668",
        "size": "medium"
    },
    {
        "iata": "BUQ",
        "lon": "28.622616",
        "iso": "ZW",
        "status": 1,
        "name": "Joshua Mqabuko Nkomo International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.014933",
        "size": "medium"
    },
    {
        "iata": "CHJ",
        "lon": "32.65",
        "iso": "ZW",
        "status": 1,
        "name": "Chipinge Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.2",
        "size": "small"
    },
    {
        "iata": "BFO",
        "lon": "31.579166",
        "iso": "ZW",
        "status": 1,
        "name": "Buffalo Range Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-21.009167",
        "size": "medium"
    },
    {
        "iata": "VFA",
        "lon": "25.840279",
        "iso": "ZW",
        "status": 1,
        "name": "Victoria Falls International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.093056",
        "size": "medium"
    },
    {
        "iata": "HRE",
        "lon": "31.099249",
        "iso": "ZW",
        "status": 1,
        "name": "Harare International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.91863",
        "size": "medium"
    },
    {
        "iata": "KAB",
        "lon": "28.885279",
        "iso": "ZW",
        "status": 1,
        "name": "Kariba International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-16.518333",
        "size": "medium"
    },
    {
        "iata": "UTA",
        "lon": "32.666668",
        "iso": "ZW",
        "status": 1,
        "name": "Mutare Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.966667",
        "size": "small"
    },
    {
        "iata": "MVZ",
        "lon": "30.860556",
        "iso": "ZW",
        "status": 1,
        "name": "Masvingo International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-20.054167",
        "size": "medium"
    },
    {
        "iata": "GWE",
        "lon": "29.86111",
        "iso": "ZW",
        "status": 1,
        "name": "Thornhill Air Base",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.435833",
        "size": "medium"
    },
    {
        "iata": "HWN",
        "lon": "26.518333",
        "iso": "ZW",
        "status": 1,
        "name": "Hwange National Park Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.3625",
        "size": "medium"
    },
    {
        "iata": "WKI",
        "lon": "26.518333",
        "iso": "ZW",
        "status": 1,
        "name": "Hwange Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.3625",
        "size": "small"
    },
    {
        "iata": "CEH",
        "lon": "33.8",
        "iso": "MW",
        "status": 1,
        "name": "Chelinda Malawi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.55",
        "size": "small"
    },
    {
        "iata": "BLZ",
        "lon": "34.970833",
        "iso": "MW",
        "status": 1,
        "name": "Chileka International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.674722",
        "size": "medium"
    },
    {
        "iata": "CMK",
        "lon": "35.05",
        "iso": "MW",
        "status": 1,
        "name": "Club Makokola Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.3",
        "size": "small"
    },
    {
        "iata": "DWA",
        "lon": "34.13333",
        "iso": "MW",
        "status": 1,
        "name": "Dwangwa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.0",
        "size": "medium"
    },
    {
        "iata": "KGJ",
        "lon": "33.892223",
        "iso": "MW",
        "status": 1,
        "name": "Karonga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.957778",
        "size": "medium"
    },
    {
        "iata": "KBQ",
        "lon": "13.016667",
        "iso": "MW",
        "status": 1,
        "name": "Kasungu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-33.466667",
        "size": "small"
    },
    {
        "iata": "LLW",
        "lon": "33.780197",
        "iso": "MW",
        "status": 1,
        "name": "Lilongwe International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.780039",
        "size": "medium"
    },
    {
        "iata": "LIX",
        "lon": "34.733334",
        "iso": "MW",
        "status": 1,
        "name": "Likoma Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.083333",
        "size": "small"
    },
    {
        "iata": "MAI",
        "lon": "35.25",
        "iso": "MW",
        "status": 1,
        "name": "Mangochi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.5",
        "size": "medium"
    },
    {
        "iata": "MYZ",
        "lon": "34.533333",
        "iso": "MW",
        "status": 1,
        "name": "Monkey Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.1",
        "size": "small"
    },
    {
        "iata": "LMB",
        "lon": "34.583332",
        "iso": "MW",
        "status": 1,
        "name": "Salima Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.75",
        "size": "small"
    },
    {
        "iata": "ZZU",
        "lon": "34.01278",
        "iso": "MW",
        "status": 1,
        "name": "Mzuzu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.442778",
        "size": "medium"
    },
    {
        "iata": "LEF",
        "lon": "28.583332",
        "iso": "LS",
        "status": 1,
        "name": "Lebakeng Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.783333",
        "size": "small"
    },
    {
        "iata": "LRB",
        "lon": "28.0",
        "iso": "LS",
        "status": 1,
        "name": "Leribe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.966667",
        "size": "small"
    },
    {
        "iata": "LES",
        "lon": "27.666668",
        "iso": "LS",
        "status": 1,
        "name": "Lesobeng Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.666668",
        "size": "small"
    },
    {
        "iata": "MFC",
        "lon": "27.3",
        "iso": "LS",
        "status": 1,
        "name": "Mafeteng Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.75",
        "size": "small"
    },
    {
        "iata": "MKH",
        "lon": "29.166668",
        "iso": "LS",
        "status": 1,
        "name": "Mokhotlong Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.166668",
        "size": "small"
    },
    {
        "iata": "MSU",
        "lon": "27.505556",
        "iso": "LS",
        "status": 1,
        "name": "Moshoeshoe I International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.30139",
        "size": "medium"
    },
    {
        "iata": "NKU",
        "lon": "28.0",
        "iso": "LS",
        "status": 1,
        "name": "Nkaus Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.5",
        "size": "small"
    },
    {
        "iata": "PEL",
        "lon": "27.916668",
        "iso": "LS",
        "status": 1,
        "name": "Pelaneng Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-30.55",
        "size": "small"
    },
    {
        "iata": "UTG",
        "lon": "27.6",
        "iso": "LS",
        "status": 1,
        "name": "Quthing Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-30.5",
        "size": "small"
    },
    {
        "iata": "UNE",
        "lon": "27.166668",
        "iso": "LS",
        "status": 1,
        "name": "Qacha's Nek Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-30.166668",
        "size": "small"
    },
    {
        "iata": "SHK",
        "lon": "27.833332",
        "iso": "LS",
        "status": 1,
        "name": "Sehonghong Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.475",
        "size": "small"
    },
    {
        "iata": "SKQ",
        "lon": "28.166668",
        "iso": "LS",
        "status": 1,
        "name": "Sekakes Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.65",
        "size": "small"
    },
    {
        "iata": "SOK",
        "lon": "28.5",
        "iso": "LS",
        "status": 1,
        "name": "Semonkong Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.833332",
        "size": "small"
    },
    {
        "iata": "SHZ",
        "lon": "28.0",
        "iso": "LS",
        "status": 1,
        "name": "Seshutes Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.166668",
        "size": "small"
    },
    {
        "iata": "THB",
        "lon": "29.5",
        "iso": "LS",
        "status": 1,
        "name": "Thaba-Tseka Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.833332",
        "size": "small"
    },
    {
        "iata": "TKO",
        "lon": "28.883333",
        "iso": "LS",
        "status": 1,
        "name": "Tlokoeng Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-29.233334",
        "size": "small"
    },
    {
        "iata": "ADI",
        "lon": "15.0",
        "iso": "NA",
        "status": 1,
        "name": "Arandis Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.4",
        "size": "medium"
    },
    {
        "iata": "GOG",
        "lon": "18.974722",
        "iso": "NA",
        "status": 1,
        "name": "Gobabis Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.504723",
        "size": "small"
    },
    {
        "iata": "GFY",
        "lon": "18.133333",
        "iso": "NA",
        "status": 1,
        "name": "Grootfontein Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.6",
        "size": "medium"
    },
    {
        "iata": "MPA",
        "lon": "24.266666",
        "iso": "NA",
        "status": 1,
        "name": "Katima Mulilo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.5",
        "size": "small"
    },
    {
        "iata": "KMP",
        "lon": "18.1",
        "iso": "NA",
        "status": 1,
        "name": "Keetmanshoop Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.533611",
        "size": "medium"
    },
    {
        "iata": "LUD",
        "lon": "15.243889",
        "iso": "NA",
        "status": 1,
        "name": "Luderitz Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-26.68639",
        "size": "medium"
    },
    {
        "iata": "OKU",
        "lon": "17.05",
        "iso": "NA",
        "status": 1,
        "name": "Mokuti Lodge Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.808332",
        "size": "small"
    },
    {
        "iata": "NNI",
        "lon": "16.916668",
        "iso": "NA",
        "status": 1,
        "name": "Namutoni Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.816668",
        "size": "small"
    },
    {
        "iata": "OND",
        "lon": "15.942233",
        "iso": "NA",
        "status": 1,
        "name": "Ondangwa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.885496",
        "size": "medium"
    },
    {
        "iata": "OMG",
        "lon": "22.066668",
        "iso": "NA",
        "status": 1,
        "name": "Omega Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-18.0",
        "size": "small"
    },
    {
        "iata": "OMD",
        "lon": "16.45",
        "iso": "NA",
        "status": 1,
        "name": "Oranjemund Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-28.583332",
        "size": "medium"
    },
    {
        "iata": "OKF",
        "lon": "16.0",
        "iso": "NA",
        "status": 1,
        "name": "Okaukuejo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.166668",
        "size": "small"
    },
    {
        "iata": "NDU",
        "lon": "19.723513",
        "iso": "NA",
        "status": 1,
        "name": "Rundu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-17.955778",
        "size": "medium"
    },
    {
        "iata": "SWP",
        "lon": "14.566667",
        "iso": "NA",
        "status": 1,
        "name": "Swakopmund Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.683332",
        "size": "small"
    },
    {
        "iata": "TSB",
        "lon": "17.733334",
        "iso": "NA",
        "status": 1,
        "name": "Tsumeb Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-19.266666",
        "size": "medium"
    },
    {
        "iata": "WVB",
        "lon": "14.517222",
        "iso": "NA",
        "status": 1,
        "name": "Walvis Bay Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.978611",
        "size": "medium"
    },
    {
        "iata": "ERS",
        "lon": "17.083332",
        "iso": "NA",
        "status": 1,
        "name": "Eros Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.616667",
        "size": "medium"
    },
    {
        "iata": "WDH",
        "lon": "17.463202",
        "iso": "NA",
        "status": 1,
        "name": "Hosea Kutako International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.487345",
        "size": "medium"
    },
    {
        "iata": "FIH",
        "lon": "15.447338",
        "iso": "CD",
        "status": 1,
        "name": "Ndjili International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.389588",
        "size": "medium"
    },
    {
        "iata": "NLO",
        "lon": "15.326389",
        "iso": "CD",
        "status": 1,
        "name": "Ndolo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.325",
        "size": "medium"
    },
    {
        "iata": "MNB",
        "lon": "12.352778",
        "iso": "CD",
        "status": 1,
        "name": "Muanda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.926944",
        "size": "small"
    },
    {
        "iata": "BOA",
        "lon": "13.066667",
        "iso": "CD",
        "status": 1,
        "name": "Boma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.866667",
        "size": "small"
    },
    {
        "iata": "LZI",
        "lon": "14.0",
        "iso": "CD",
        "status": 1,
        "name": "Luozi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.9",
        "size": "small"
    },
    {
        "iata": "MAT",
        "lon": "13.441667",
        "iso": "CD",
        "status": 1,
        "name": "Tshimpi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.798333",
        "size": "small"
    },
    {
        "iata": "NKL",
        "lon": "16.466667",
        "iso": "CD",
        "status": 1,
        "name": "Nkolo Fuma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.983333",
        "size": "small"
    },
    {
        "iata": "INO",
        "lon": "18.279167",
        "iso": "CD",
        "status": 1,
        "name": "Inongo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.95",
        "size": "small"
    },
    {
        "iata": "NIO",
        "lon": "17.7",
        "iso": "CD",
        "status": 1,
        "name": "Nioki Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.75",
        "size": "small"
    },
    {
        "iata": "FDU",
        "lon": "17.383333",
        "iso": "CD",
        "status": 1,
        "name": "Bandundu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.305556",
        "size": "medium"
    },
    {
        "iata": "KRZ",
        "lon": "19.0",
        "iso": "CD",
        "status": 1,
        "name": "Basango Mboliasa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.483333",
        "size": "small"
    },
    {
        "iata": "KKW",
        "lon": "18.780277",
        "iso": "CD",
        "status": 1,
        "name": "Kikwit Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.036111",
        "size": "medium"
    },
    {
        "iata": "IDF",
        "lon": "19.6",
        "iso": "CD",
        "status": 1,
        "name": "Idiofa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.033333",
        "size": "small"
    },
    {
        "iata": "LUS",
        "lon": "18.718885",
        "iso": "CD",
        "status": 1,
        "name": "Lusanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.804654",
        "size": "small"
    },
    {
        "iata": "MSM",
        "lon": "17.916668",
        "iso": "CD",
        "status": 1,
        "name": "Masi Manimba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.766667",
        "size": "small"
    },
    {
        "iata": "MDK",
        "lon": "18.28861",
        "iso": "CD",
        "status": 1,
        "name": "Mbandaka Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.0225",
        "size": "medium"
    },
    {
        "iata": "BSU",
        "lon": "19.788889",
        "iso": "CD",
        "status": 1,
        "name": "Basankusu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.221667",
        "size": "small"
    },
    {
        "iata": "LIE",
        "lon": "18.63889",
        "iso": "CD",
        "status": 1,
        "name": "Libenge Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.623611",
        "size": "small"
    },
    {
        "iata": "BDT",
        "lon": "22.45",
        "iso": "CD",
        "status": 1,
        "name": "Gbadolite Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.083333",
        "size": "medium"
    },
    {
        "iata": "GMA",
        "lon": "19.77111",
        "iso": "CD",
        "status": 1,
        "name": "Gemena Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.235278",
        "size": "medium"
    },
    {
        "iata": "KLI",
        "lon": "21.75",
        "iso": "CD",
        "status": 1,
        "name": "Kotakoli Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.166667",
        "size": "small"
    },
    {
        "iata": "BMB",
        "lon": "22.481667",
        "iso": "CD",
        "status": 1,
        "name": "Bumbar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.182778",
        "size": "small"
    },
    {
        "iata": "LIQ",
        "lon": "21.496668",
        "iso": "CD",
        "status": 1,
        "name": "Lisala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.170556",
        "size": "medium"
    },
    {
        "iata": "BNB",
        "lon": "20.883972",
        "iso": "CD",
        "status": 1,
        "name": "Boende Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.286845",
        "size": "small"
    },
    {
        "iata": "IKL",
        "lon": "23.666668",
        "iso": "CD",
        "status": 1,
        "name": "Ikela Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.666667",
        "size": "small"
    },
    {
        "iata": "FKI",
        "lon": "25.338057",
        "iso": "CD",
        "status": 1,
        "name": "Bangoka International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.481667",
        "size": "medium"
    },
    {
        "iata": "YAN",
        "lon": "24.4",
        "iso": "CD",
        "status": 1,
        "name": "Yangambi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.783333",
        "size": "small"
    },
    {
        "iata": "IRP",
        "lon": "27.588333",
        "iso": "CD",
        "status": 1,
        "name": "Matari Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.8275",
        "size": "medium"
    },
    {
        "iata": "BUX",
        "lon": "30.220833",
        "iso": "CD",
        "status": 1,
        "name": "Bunia Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.565833",
        "size": "medium"
    },
    {
        "iata": "BZU",
        "lon": "24.733334",
        "iso": "CD",
        "status": 1,
        "name": "Buta Zega Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.8",
        "size": "medium"
    },
    {
        "iata": "BKY",
        "lon": "28.808804",
        "iso": "CD",
        "status": 1,
        "name": "Bukavu Kavumu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.308978",
        "size": "medium"
    },
    {
        "iata": "GOM",
        "lon": "29.23835",
        "iso": "CD",
        "status": 1,
        "name": "Goma International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.669921",
        "size": "medium"
    },
    {
        "iata": "BNC",
        "lon": "29.47018",
        "iso": "CD",
        "status": 1,
        "name": "Beni Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.577121",
        "size": "small"
    },
    {
        "iata": "KND",
        "lon": "25.915277",
        "iso": "CD",
        "status": 1,
        "name": "Kindu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.919167",
        "size": "medium"
    },
    {
        "iata": "KLY",
        "lon": "26.533333",
        "iso": "CD",
        "status": 1,
        "name": "Kinkungwa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.55",
        "size": "small"
    },
    {
        "iata": "PUN",
        "lon": "26.333332",
        "iso": "CD",
        "status": 1,
        "name": "Punia Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.366667",
        "size": "small"
    },
    {
        "iata": "FBM",
        "lon": "27.529167",
        "iso": "CD",
        "status": 1,
        "name": "Lubumbashi International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-11.590833",
        "size": "medium"
    },
    {
        "iata": "PWO",
        "lon": "28.9",
        "iso": "CD",
        "status": 1,
        "name": "Pweto Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.466667",
        "size": "small"
    },
    {
        "iata": "KEC",
        "lon": "28.75",
        "iso": "CD",
        "status": 1,
        "name": "Kasenga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.333333",
        "size": "small"
    },
    {
        "iata": "KWZ",
        "lon": "25.506945",
        "iso": "CD",
        "status": 1,
        "name": "Kolwezi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.766667",
        "size": "medium"
    },
    {
        "iata": "MNO",
        "lon": "27.393888",
        "iso": "CD",
        "status": 1,
        "name": "Manono Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.288056",
        "size": "small"
    },
    {
        "iata": "BDV",
        "lon": "29.8",
        "iso": "CD",
        "status": 1,
        "name": "Moba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.0",
        "size": "small"
    },
    {
        "iata": "FMI",
        "lon": "29.25",
        "iso": "CD",
        "status": 1,
        "name": "Kalemie Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.875556",
        "size": "medium"
    },
    {
        "iata": "KBO",
        "lon": "26.916668",
        "iso": "CD",
        "status": 1,
        "name": "Kabalo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.088056",
        "size": "small"
    },
    {
        "iata": "KOO",
        "lon": "26.990873",
        "iso": "CD",
        "status": 1,
        "name": "Kongolo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.394122",
        "size": "small"
    },
    {
        "iata": "KMN",
        "lon": "25.25",
        "iso": "CD",
        "status": 1,
        "name": "Kamina Base Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.641111",
        "size": "medium"
    },
    {
        "iata": "KAP",
        "lon": "22.666668",
        "iso": "CD",
        "status": 1,
        "name": "Kapanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.5",
        "size": "small"
    },
    {
        "iata": "KNM",
        "lon": "24.183332",
        "iso": "CD",
        "status": 1,
        "name": "Kaniama Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.516667",
        "size": "small"
    },
    {
        "iata": "KGA",
        "lon": "22.469168",
        "iso": "CD",
        "status": 1,
        "name": "Kananga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.922",
        "size": "medium"
    },
    {
        "iata": "LZA",
        "lon": "22.5",
        "iso": "CD",
        "status": 1,
        "name": "Luiza Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.666667",
        "size": "small"
    },
    {
        "iata": "TSH",
        "lon": "20.794327",
        "iso": "CD",
        "status": 1,
        "name": "Tshikapa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.437853",
        "size": "small"
    },
    {
        "iata": "LJA",
        "lon": "23.61884",
        "iso": "CD",
        "status": 1,
        "name": "Lodja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.46296",
        "size": "small"
    },
    {
        "iata": "LBO",
        "lon": "23.45",
        "iso": "CD",
        "status": 1,
        "name": "Lusambo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.966667",
        "size": "small"
    },
    {
        "iata": "MEW",
        "lon": "21.566668",
        "iso": "CD",
        "status": 1,
        "name": "Mweka Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.85",
        "size": "small"
    },
    {
        "iata": "BAN",
        "lon": "20.428057",
        "iso": "CD",
        "status": 1,
        "name": "Basongo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.313333",
        "size": "small"
    },
    {
        "iata": "PFR",
        "lon": "20.583332",
        "iso": "CD",
        "status": 1,
        "name": "Ilebo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.316667",
        "size": "small"
    },
    {
        "iata": "MJM",
        "lon": "23.633333",
        "iso": "CD",
        "status": 1,
        "name": "Mbuji Mayi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.15",
        "size": "medium"
    },
    {
        "iata": "GDJ",
        "lon": "23.966667",
        "iso": "CD",
        "status": 1,
        "name": "Gandajika Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.766667",
        "size": "small"
    },
    {
        "iata": "KBN",
        "lon": "24.533333",
        "iso": "CD",
        "status": 1,
        "name": "Tunta Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.116667",
        "size": "small"
    },
    {
        "iata": "AKE",
        "lon": "13.916667",
        "iso": "GA",
        "status": 1,
        "name": "Akieni Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.172222",
        "size": "small"
    },
    {
        "iata": "GAX",
        "lon": "9.983333",
        "iso": "GA",
        "status": 1,
        "name": "Gamba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.766667",
        "size": "small"
    },
    {
        "iata": "BKO",
        "lon": "-7.947951",
        "iso": "ML",
        "status": 1,
        "name": "Senou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.540926",
        "size": "medium"
    },
    {
        "iata": "GUD",
        "lon": "-3.609722",
        "iso": "ML",
        "status": 1,
        "name": "Goundam Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.3575",
        "size": "small"
    },
    {
        "iata": "GAQ",
        "lon": "-0.001389",
        "iso": "ML",
        "status": 1,
        "name": "Gao Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.248611",
        "size": "medium"
    },
    {
        "iata": "KNZ",
        "lon": "-11.2525",
        "iso": "ML",
        "status": 1,
        "name": "Kenieba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.843333",
        "size": "small"
    },
    {
        "iata": "KTX",
        "lon": "-5.383333",
        "iso": "ML",
        "status": 1,
        "name": "Koutiala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.416667",
        "size": "small"
    },
    {
        "iata": "KYS",
        "lon": "-11.439444",
        "iso": "ML",
        "status": 1,
        "name": "Kayes Dag Dag Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.431944",
        "size": "medium"
    },
    {
        "iata": "MZI",
        "lon": "-4.085",
        "iso": "ML",
        "status": 1,
        "name": "Ambodedjo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.508333",
        "size": "medium"
    },
    {
        "iata": "NRM",
        "lon": "-7.283333",
        "iso": "ML",
        "status": 1,
        "name": "Nara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.25",
        "size": "small"
    },
    {
        "iata": "NIX",
        "lon": "-9.577222",
        "iso": "ML",
        "status": 1,
        "name": "Nioro du Sahel Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.239167",
        "size": "small"
    },
    {
        "iata": "KSS",
        "lon": "-5.583333",
        "iso": "ML",
        "status": 1,
        "name": "Sikasso Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.3",
        "size": "small"
    },
    {
        "iata": "TOM",
        "lon": "-3.005278",
        "iso": "ML",
        "status": 1,
        "name": "Timbuktu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.732222",
        "size": "medium"
    },
    {
        "iata": "EYL",
        "lon": "-10.566667",
        "iso": "ML",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "15.133333",
        "size": "small"
    },
    {
        "iata": "DOC",
        "lon": "-4.033333",
        "iso": "GB",
        "status": 1,
        "name": "Dornoch Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.86667",
        "size": "small"
    },
    {
        "iata": "FLH",
        "lon": "-3.0",
        "iso": "GB",
        "status": 1,
        "name": "Flotta Isle Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.5",
        "size": "small"
    },
    {
        "iata": "FOA",
        "lon": "-2.083333",
        "iso": "GB",
        "status": 1,
        "name": "Foula Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.166668",
        "size": "small"
    },
    {
        "iata": "OUK",
        "lon": "-0.75",
        "iso": "GB",
        "status": 1,
        "name": "Outer Skerries Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.416668",
        "size": "medium"
    },
    {
        "iata": "PSV",
        "lon": "-1.7",
        "iso": "GB",
        "status": 1,
        "name": "Papa Stour Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.316666",
        "size": "small"
    },
    {
        "iata": "ULL",
        "lon": "-6.0",
        "iso": "GB",
        "status": 1,
        "name": "Glenforsa Airfield",
        "continent": "EU",
        "type": "airport",
        "lat": "56.45",
        "size": "small"
    },
    {
        "iata": "BJL",
        "lon": "-16.6522",
        "iso": "GM",
        "status": 1,
        "name": "Banjul International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.34406",
        "size": "medium"
    },
    {
        "iata": "FUE",
        "lon": "-13.869893",
        "iso": "ES",
        "status": 1,
        "name": "Fuerteventura Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "28.450605",
        "size": "medium"
    },
    {
        "iata": "GMZ",
        "lon": "-17.2",
        "iso": "ES",
        "status": 1,
        "name": "La Gomera Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "28.016666",
        "size": "small"
    },
    {
        "iata": "VDE",
        "lon": "-17.884937",
        "iso": "ES",
        "status": 1,
        "name": "Hierro Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "27.813969",
        "size": "medium"
    },
    {
        "iata": "SPC",
        "lon": "-17.755556",
        "iso": "ES",
        "status": 1,
        "name": "La Palma Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "28.626389",
        "size": "medium"
    },
    {
        "iata": "LPA",
        "lon": "-15.389351",
        "iso": "ES",
        "status": 1,
        "name": "Gran Canaria Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "27.938944",
        "size": "large"
    },
    {
        "iata": "ACE",
        "lon": "-13.609059",
        "iso": "ES",
        "status": 1,
        "name": "Lanzarote Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "28.950668",
        "size": "medium"
    },
    {
        "iata": "TFS",
        "lon": "-16.5725",
        "iso": "ES",
        "status": 1,
        "name": "Tenerife South Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "28.044443",
        "size": "large"
    },
    {
        "iata": "TFN",
        "lon": "-16.345982",
        "iso": "ES",
        "status": 1,
        "name": "Tenerife Norte Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "28.488056",
        "size": "large"
    },
    {
        "iata": "JCU",
        "iso": "ES",
        "status": 1,
        "name": "Ceuta Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "MLN",
        "lon": "-2.957473",
        "iso": "ES",
        "status": 1,
        "name": "Melilla Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.277077",
        "size": "medium"
    },
    {
        "iata": "BTE",
        "lon": "-12.008333",
        "iso": "SL",
        "status": 1,
        "name": "Sherbro International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.0",
        "size": "medium"
    },
    {
        "iata": "KBS",
        "lon": "-11.761944",
        "iso": "SL",
        "status": 1,
        "name": "Bo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.943889",
        "size": "medium"
    },
    {
        "iata": "GBK",
        "lon": "-12.366667",
        "iso": "SL",
        "status": 1,
        "name": "Gbangbatok Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.8",
        "size": "small"
    },
    {
        "iata": "HGS",
        "lon": "-13.13",
        "iso": "SL",
        "status": 1,
        "name": "Hastings Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.393056",
        "size": "small"
    },
    {
        "iata": "KBA",
        "lon": "-12.0",
        "iso": "SL",
        "status": 1,
        "name": "Kabala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.333333",
        "size": "small"
    },
    {
        "iata": "KEN",
        "lon": "-11.183333",
        "iso": "SL",
        "status": 1,
        "name": "Kenema Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.883333",
        "size": "medium"
    },
    {
        "iata": "FNA",
        "lon": "-13.196944",
        "iso": "SL",
        "status": 1,
        "name": "Lungi International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.6175",
        "size": "medium"
    },
    {
        "iata": "WYE",
        "lon": "-11.058889",
        "iso": "SL",
        "status": 1,
        "name": "Yengema Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.616667",
        "size": "medium"
    },
    {
        "iata": "BQE",
        "lon": "-15.85",
        "iso": "GW",
        "status": 1,
        "name": "Bubaque Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.3",
        "size": "small"
    },
    {
        "iata": "OXB",
        "lon": "-15.657222",
        "iso": "GW",
        "status": 1,
        "name": "Osvaldo Vieira International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.888889",
        "size": "medium"
    },
    {
        "iata": "JGR",
        "iso": "GL",
        "status": 1,
        "name": "Groennedal Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "QCU",
        "iso": "GL",
        "status": 1,
        "name": "Akunaq Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "UCN",
        "lon": "-10.033333",
        "iso": "LR",
        "status": 1,
        "name": "Buchanan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.95",
        "size": "small"
    },
    {
        "iata": "CPA",
        "lon": "-7.695556",
        "iso": "LR",
        "status": 1,
        "name": "Cape Palmas Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.376667",
        "size": "small"
    },
    {
        "iata": "SNI",
        "lon": "-9.063611",
        "iso": "LR",
        "status": 1,
        "name": "Greenville Sinoe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.033056",
        "size": "small"
    },
    {
        "iata": "UCN",
        "lon": "-10.033333",
        "iso": "LR",
        "status": 1,
        "name": "Lamco Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.95",
        "size": "small"
    },
    {
        "iata": "MLW",
        "lon": "-10.758333",
        "iso": "LR",
        "status": 1,
        "name": "Spriggs Payne Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.289444",
        "size": "medium"
    },
    {
        "iata": "NIA",
        "lon": "-8.591667",
        "iso": "LR",
        "status": 1,
        "name": "Nimba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.491111",
        "size": "small"
    },
    {
        "iata": "ROB",
        "lon": "-10.358889",
        "iso": "LR",
        "status": 1,
        "name": "Roberts International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.239722",
        "size": "medium"
    },
    {
        "iata": "SAZ",
        "lon": "-8.433333",
        "iso": "LR",
        "status": 1,
        "name": "Sasstown Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.666667",
        "size": "small"
    },
    {
        "iata": "THC",
        "lon": "-8.133333",
        "iso": "LR",
        "status": 1,
        "name": "Tchien Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.066667",
        "size": "small"
    },
    {
        "iata": "VOI",
        "lon": "-9.75",
        "iso": "LR",
        "status": 1,
        "name": "Voinjama Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.416667",
        "size": "small"
    },
    {
        "iata": "AGA",
        "lon": "-9.4131",
        "iso": "MA",
        "status": 1,
        "name": "Al Massira Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.325",
        "size": "medium"
    },
    {
        "iata": "TTA",
        "lon": "-11.083333",
        "iso": "MA",
        "status": 1,
        "name": "Tan Tan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "28.45",
        "size": "medium"
    },
    {
        "iata": "OZG",
        "lon": "-5.852456",
        "iso": "MA",
        "status": 1,
        "name": "Zagora Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.267143",
        "size": "small"
    },
    {
        "iata": "UAR",
        "lon": "51.538563",
        "iso": "MA",
        "status": 1,
        "name": "Bouarfa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "51.15371",
        "size": "medium"
    },
    {
        "iata": "FEZ",
        "lon": "-4.982138",
        "iso": "MA",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "33.93079",
        "size": "medium"
    },
    {
        "iata": "ERH",
        "lon": "-4.4",
        "iso": "MA",
        "status": 1,
        "name": "Moulay Ali Cherif Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.95",
        "size": "medium"
    },
    {
        "iata": "MEK",
        "lon": "-5.533333",
        "iso": "MA",
        "status": 1,
        "name": "Bassatine Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.88333",
        "size": "medium"
    },
    {
        "iata": "OUD",
        "lon": "-1.933333",
        "iso": "MA",
        "status": 1,
        "name": "Angads Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.783333",
        "size": "medium"
    },
    {
        "iata": "SMW",
        "lon": "-11.683333",
        "iso": "EH",
        "status": 1,
        "name": "Smara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "26.733334",
        "size": "medium"
    },
    {
        "iata": "GMD",
        "iso": "MA",
        "status": 1,
        "name": "Ben Slimane Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CAS",
        "lon": "-7.663056",
        "iso": "MA",
        "status": 1,
        "name": "Anfa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.559723",
        "size": "small"
    },
    {
        "iata": "RBA",
        "lon": "-6.748619",
        "iso": "MA",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "34.036137",
        "size": "medium"
    },
    {
        "iata": "SII",
        "lon": "-10.2",
        "iso": "MA",
        "status": 1,
        "name": "Sidi Ifni Xx Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "29.4",
        "size": "small"
    },
    {
        "iata": "VIL",
        "lon": "-15.935",
        "iso": "EH",
        "status": 1,
        "name": "Dakhla Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "23.713333",
        "size": "medium"
    },
    {
        "iata": "ESU",
        "lon": "-9.681667",
        "iso": "MA",
        "status": 1,
        "name": "Mogador Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.3975",
        "size": "small"
    },
    {
        "iata": "EUN",
        "lon": "-13.216667",
        "iso": "EH",
        "status": 1,
        "name": "Hassan I Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.133333",
        "size": "medium"
    },
    {
        "iata": "CMN",
        "lon": "-7.586667",
        "iso": "MA",
        "status": 1,
        "name": "Mohammed V International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "33.36667",
        "size": "large"
    },
    {
        "iata": "SFI",
        "lon": "-9.333333",
        "iso": "MA",
        "status": 1,
        "name": "Safi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.3",
        "size": "small"
    },
    {
        "iata": "NDR",
        "lon": "-3.028333",
        "iso": "MA",
        "status": 1,
        "name": "Nador International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.988888",
        "size": "medium"
    },
    {
        "iata": "RAK",
        "lon": "-8.026902",
        "iso": "MA",
        "status": 1,
        "name": "Menara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.601875",
        "size": "medium"
    },
    {
        "iata": "NNA",
        "lon": "-6.666667",
        "iso": "MA",
        "status": 1,
        "name": "Kenitra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "34.266666",
        "size": "medium"
    },
    {
        "iata": "OZZ",
        "lon": "-6.916667",
        "iso": "MA",
        "status": 1,
        "name": "Ouarzazate Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.916668",
        "size": "medium"
    },
    {
        "iata": "AHU",
        "lon": "-3.836944",
        "iso": "MA",
        "status": 1,
        "name": "Cherif Al Idrissi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.17972",
        "size": "medium"
    },
    {
        "iata": "TTU",
        "lon": "-5.316667",
        "iso": "MA",
        "status": 1,
        "name": "Saniat Rmel Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.583332",
        "size": "medium"
    },
    {
        "iata": "TNG",
        "lon": "-5.912898",
        "iso": "MA",
        "status": 1,
        "name": "Ibn Batouta Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "35.726288",
        "size": "medium"
    },
    {
        "iata": "GNU",
        "lon": "-161.58055",
        "iso": "US",
        "status": 1,
        "name": "Goodnews Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.1125",
        "size": "small"
    },
    {
        "iata": "KDA",
        "lon": "-14.966667",
        "iso": "SN",
        "status": 1,
        "name": "Kolda North Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.883333",
        "size": "small"
    },
    {
        "iata": "ZIG",
        "lon": "-16.275833",
        "iso": "SN",
        "status": 1,
        "name": "Ziguinchor Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.556111",
        "size": "medium"
    },
    {
        "iata": "CSK",
        "lon": "-16.744122",
        "iso": "SN",
        "status": 1,
        "name": "Cap Skirring Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.392154",
        "size": "medium"
    },
    {
        "iata": "KLC",
        "lon": "-16.5",
        "iso": "SN",
        "status": 1,
        "name": "Kaolack Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.083333",
        "size": "medium"
    },
    {
        "iata": "DKR",
        "lon": "-17.490194",
        "iso": "SN",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "14.744975",
        "size": "large"
    },
    {
        "iata": "MAX",
        "lon": "-13.323611",
        "iso": "SN",
        "status": 1,
        "name": "Ouro Sogui Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.593056",
        "size": "medium"
    },
    {
        "iata": "POD",
        "lon": "-14.966667",
        "iso": "SN",
        "status": 1,
        "name": "Podor Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.666668",
        "size": "small"
    },
    {
        "iata": "RDT",
        "lon": "-15.657222",
        "iso": "SN",
        "status": 1,
        "name": "Richard Toll Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.437222",
        "size": "small"
    },
    {
        "iata": "XLS",
        "lon": "-16.460278",
        "iso": "SN",
        "status": 1,
        "name": "Saint Louis Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.050278",
        "size": "medium"
    },
    {
        "iata": "BXE",
        "lon": "-12.467778",
        "iso": "SN",
        "status": 1,
        "name": "Bakel Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.841667",
        "size": "medium"
    },
    {
        "iata": "KGG",
        "lon": "-12.217222",
        "iso": "SN",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "12.570833",
        "size": "medium"
    },
    {
        "iata": "SMY",
        "lon": "-13.166667",
        "iso": "SN",
        "status": 1,
        "name": "Simenti Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.166667",
        "size": "small"
    },
    {
        "iata": "TUD",
        "lon": "-13.658333",
        "iso": "SN",
        "status": 1,
        "name": "Tambacounda Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.736111",
        "size": "medium"
    },
    {
        "iata": "AEO",
        "lon": "-9.635556",
        "iso": "MR",
        "status": 1,
        "name": "Aioun el Atrouss Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.709167",
        "size": "small"
    },
    {
        "iata": "OTL",
        "lon": "-14.666667",
        "iso": "MR",
        "status": 1,
        "name": "Boutilimit Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "17.75",
        "size": "small"
    },
    {
        "iata": "THI",
        "lon": "-9.5",
        "iso": "MR",
        "status": 1,
        "name": "Tichitt Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "18.45",
        "size": "small"
    },
    {
        "iata": "TIY",
        "lon": "-11.416667",
        "iso": "MR",
        "status": 1,
        "name": "Tidjikja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "18.566668",
        "size": "small"
    },
    {
        "iata": "BGH",
        "lon": "-14.2",
        "iso": "MR",
        "status": 1,
        "name": "Abbaye Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.633333",
        "size": "small"
    },
    {
        "iata": "KFA",
        "lon": "-11.405278",
        "iso": "MR",
        "status": 1,
        "name": "Kiffa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.588888",
        "size": "small"
    },
    {
        "iata": "TMD",
        "lon": "-8.154167",
        "iso": "MR",
        "status": 1,
        "name": "Timbedra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.236668",
        "size": "small"
    },
    {
        "iata": "EMN",
        "lon": "-7.283333",
        "iso": "MR",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "16.6",
        "size": "small"
    },
    {
        "iata": "AJJ",
        "lon": "-14.374444",
        "iso": "MR",
        "status": 1,
        "name": "Akjoujt Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "19.730556",
        "size": "small"
    },
    {
        "iata": "KED",
        "lon": "-13.507778",
        "iso": "MR",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "16.161388",
        "size": "small"
    },
    {
        "iata": "MOM",
        "lon": "-12.5",
        "iso": "MR",
        "status": 1,
        "name": "Letfotar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "17.75",
        "size": "small"
    },
    {
        "iata": "NKC",
        "lon": "-15.952041",
        "iso": "MR",
        "status": 1,
        "name": "Nouakchott International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "18.098148",
        "size": "medium"
    },
    {
        "iata": "SEY",
        "lon": "-12.206111",
        "iso": "MR",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "15.182222",
        "size": "small"
    },
    {
        "iata": "THT",
        "lon": "-10.816667",
        "iso": "MR",
        "status": 1,
        "name": "Tamchakett Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "17.233334",
        "size": "small"
    },
    {
        "iata": "ATR",
        "lon": "-13.046389",
        "iso": "MR",
        "status": 1,
        "name": "Atar International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "20.499443",
        "size": "medium"
    },
    {
        "iata": "FGD",
        "lon": "-12.731944",
        "iso": "MR",
        "status": 1,
        "name": "Fderik Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "22.675",
        "size": "small"
    },
    {
        "iata": "NDB",
        "lon": "-17.028334",
        "iso": "MR",
        "status": 1,
        "name": "Nouadhibou International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "20.934168",
        "size": "medium"
    },
    {
        "iata": "OUZ",
        "lon": "-12.35",
        "iso": "MR",
        "status": 1,
        "name": "Tazadit Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "22.733334",
        "size": "small"
    },
    {
        "iata": "JSS",
        "lon": "23.166668",
        "iso": "GR",
        "status": 1,
        "name": "Spetsai Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.25",
        "size": "small"
    },
    {
        "iata": "CIQ",
        "lon": "-89.61667",
        "iso": "GT",
        "status": 1,
        "name": "Chiquimula Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.85",
        "size": "small"
    },
    {
        "iata": "DON",
        "lon": "-89.666664",
        "iso": "GT",
        "status": 1,
        "name": "Dos Lagunas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.633333",
        "size": "small"
    },
    {
        "iata": "ENJ",
        "lon": "-90.166664",
        "iso": "GT",
        "status": 1,
        "name": "El Naranjo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.333332",
        "size": "small"
    },
    {
        "iata": "PCG",
        "lon": "-90.24722",
        "iso": "GT",
        "status": 1,
        "name": "Paso Caballos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.26",
        "size": "small"
    },
    {
        "iata": "LCF",
        "lon": "-88.94778",
        "iso": "GT",
        "status": 1,
        "name": "Las Vegas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.667778",
        "size": "small"
    },
    {
        "iata": "TKM",
        "lon": "-89.605",
        "iso": "GT",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "17.226389",
        "size": "small"
    },
    {
        "iata": "UAX",
        "lon": "-89.632774",
        "iso": "GT",
        "status": 1,
        "name": "Uaxactun Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.393888",
        "size": "small"
    },
    {
        "iata": "PKJ",
        "lon": "-90.76195",
        "iso": "GT",
        "status": 1,
        "name": "Playa Grande Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.6425",
        "size": "small"
    },
    {
        "iata": "GTZ",
        "lon": "34.225555",
        "iso": "TZ",
        "status": 1,
        "name": "Kirawira B Aerodrome",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.160833",
        "size": "small"
    },
    {
        "iata": "CKY",
        "lon": "-13.62017",
        "iso": "GN",
        "status": 1,
        "name": "Conakry Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.575655",
        "size": "medium"
    },
    {
        "iata": "FIG",
        "lon": "-13.566667",
        "iso": "GN",
        "status": 1,
        "name": "Fria Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.35",
        "size": "medium"
    },
    {
        "iata": "FAA",
        "lon": "-10.75",
        "iso": "GN",
        "status": 1,
        "name": "Faranah Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.033333",
        "size": "medium"
    },
    {
        "iata": "KSI",
        "lon": "-10.1",
        "iso": "GN",
        "status": 1,
        "name": "Kissidougou Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.183333",
        "size": "small"
    },
    {
        "iata": "LEK",
        "lon": "-12.297222",
        "iso": "GN",
        "status": 1,
        "name": "Labe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.333333",
        "size": "medium"
    },
    {
        "iata": "MCA",
        "lon": "-9.466667",
        "iso": "GN",
        "status": 1,
        "name": "Macenta Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.55",
        "size": "small"
    },
    {
        "iata": "NZE",
        "lon": "-8.702778",
        "iso": "GN",
        "status": 1,
        "name": "Nzerekore Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.808333",
        "size": "small"
    },
    {
        "iata": "BKJ",
        "lon": "-14.283333",
        "iso": "GN",
        "status": 1,
        "name": "Boke Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.966667",
        "size": "small"
    },
    {
        "iata": "SBI",
        "lon": "-13.3",
        "iso": "GN",
        "status": 1,
        "name": "Sambailo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.483333",
        "size": "small"
    },
    {
        "iata": "GII",
        "lon": "-9.166667",
        "iso": "GN",
        "status": 1,
        "name": "Siguiri Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.516667",
        "size": "small"
    },
    {
        "iata": "KNN",
        "lon": "-9.305556",
        "iso": "GN",
        "status": 1,
        "name": "Kankan Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.3975",
        "size": "small"
    },
    {
        "iata": "SID",
        "lon": "-22.943613",
        "iso": "CV",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "16.734695",
        "size": "medium"
    },
    {
        "iata": "NTO",
        "lon": "-25.083332",
        "iso": "CV",
        "status": 1,
        "name": "Agostinho Neto Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "17.066668",
        "size": "small"
    },
    {
        "iata": "BVC",
        "lon": "-22.833332",
        "iso": "CV",
        "status": 1,
        "name": "Rabil Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "16.083332",
        "size": "medium"
    },
    {
        "iata": "MMO",
        "lon": "-23.166668",
        "iso": "CV",
        "status": 1,
        "name": "Maio Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.25",
        "size": "medium"
    },
    {
        "iata": "MTI",
        "lon": "-24.433332",
        "iso": "CV",
        "status": 1,
        "name": "Mosteiros Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.0",
        "size": "small"
    },
    {
        "iata": "RAI",
        "lon": "-23.486567",
        "iso": "CV",
        "status": 1,
        "name": "Praia International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.945321",
        "size": "medium"
    },
    {
        "iata": "SFL",
        "lon": "-24.516666",
        "iso": "CV",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "14.9",
        "size": "small"
    },
    {
        "iata": "SNE",
        "lon": "-24.28861",
        "iso": "CV",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "16.586945",
        "size": "medium"
    },
    {
        "iata": "VXE",
        "lon": "-25.057571",
        "iso": "CV",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "16.835613",
        "size": "medium"
    },
    {
        "iata": "EDG",
        "iso": "DE",
        "status": 0,
        "name": "Gatow Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "BCG",
        "lon": "-58.55",
        "iso": "GY",
        "status": 1,
        "name": "Bemichi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.55",
        "size": "small"
    },
    {
        "iata": "BTO",
        "lon": "-55.45",
        "iso": "SR",
        "status": 1,
        "name": "Botopasi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.25",
        "size": "small"
    },
    {
        "iata": "DOE",
        "lon": "-55.483334",
        "iso": "SR",
        "status": 1,
        "name": "Djumu-Djomoe Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.016667",
        "size": "small"
    },
    {
        "iata": "LDO",
        "lon": "-55.233334",
        "iso": "SR",
        "status": 1,
        "name": "Ladouanie Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.216667",
        "size": "small"
    },
    {
        "iata": "WSO",
        "lon": "-57.183334",
        "iso": "SR",
        "status": 1,
        "name": "Washabo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.216667",
        "size": "small"
    },
    {
        "iata": "ADD",
        "lon": "38.7959",
        "iso": "ET",
        "status": 1,
        "name": "Bole International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.983759",
        "size": "medium"
    },
    {
        "iata": "AMH",
        "lon": "37.576942",
        "iso": "ET",
        "status": 1,
        "name": "Arba Minch Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.036111",
        "size": "medium"
    },
    {
        "iata": "AXU",
        "lon": "38.716667",
        "iso": "ET",
        "status": 1,
        "name": "Axum Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.120833",
        "size": "medium"
    },
    {
        "iata": "BCO",
        "lon": "36.55",
        "iso": "ET",
        "status": 1,
        "name": "Baco Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.783333",
        "size": "small"
    },
    {
        "iata": "BJR",
        "lon": "37.32324",
        "iso": "ET",
        "status": 1,
        "name": "Bahir Dar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.603292",
        "size": "medium"
    },
    {
        "iata": "BEI",
        "lon": "34.533333",
        "iso": "ET",
        "status": 1,
        "name": "Beica Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.391667",
        "size": "small"
    },
    {
        "iata": "DSE",
        "lon": "39.71528",
        "iso": "ET",
        "status": 1,
        "name": "Combolcha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.075",
        "size": "small"
    },
    {
        "iata": "DEM",
        "lon": "34.88333",
        "iso": "ET",
        "status": 1,
        "name": "Dembidollo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.571111",
        "size": "small"
    },
    {
        "iata": "DBM",
        "lon": "37.743057",
        "iso": "ET",
        "status": 1,
        "name": "Debra Marcos Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.319444",
        "size": "small"
    },
    {
        "iata": "DIR",
        "lon": "41.857994",
        "iso": "ET",
        "status": 1,
        "name": "Aba Tenna Dejazmach Yilma International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.61338",
        "size": "medium"
    },
    {
        "iata": "DBT",
        "lon": "38.025276",
        "iso": "ET",
        "status": 1,
        "name": "Debre Tabor Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.968056",
        "size": "small"
    },
    {
        "iata": "FNH",
        "lon": "37.433334",
        "iso": "ET",
        "status": 1,
        "name": "Fincha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.583333",
        "size": "small"
    },
    {
        "iata": "GOB",
        "lon": "39.980556",
        "iso": "ET",
        "status": 1,
        "name": "Robe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.013889",
        "size": "small"
    },
    {
        "iata": "GNN",
        "lon": "40.716667",
        "iso": "ET",
        "status": 1,
        "name": "Ghinnir Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.15",
        "size": "small"
    },
    {
        "iata": "GMB",
        "lon": "34.583332",
        "iso": "ET",
        "status": 1,
        "name": "Gambella Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.283333",
        "size": "medium"
    },
    {
        "iata": "GDQ",
        "lon": "37.445557",
        "iso": "ET",
        "status": 1,
        "name": "Gonder Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.514444",
        "size": "medium"
    },
    {
        "iata": "GDE",
        "lon": "43.628334",
        "iso": "ET",
        "status": 1,
        "name": "Gode Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.902222",
        "size": "medium"
    },
    {
        "iata": "GOR",
        "lon": "35.538055",
        "iso": "ET",
        "status": 1,
        "name": "Gore Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "8.155556",
        "size": "small"
    },
    {
        "iata": "QHR",
        "iso": "ET",
        "status": 1,
        "name": "Harar Meda Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "HUE",
        "lon": "36.583332",
        "iso": "ET",
        "status": 1,
        "name": "Humera Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.25",
        "size": "small"
    },
    {
        "iata": "JIM",
        "lon": "36.82111",
        "iso": "ET",
        "status": 1,
        "name": "Jimma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.654444",
        "size": "medium"
    },
    {
        "iata": "ABK",
        "lon": "44.26611",
        "iso": "ET",
        "status": 1,
        "name": "Kabri Dehar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.733889",
        "size": "small"
    },
    {
        "iata": "LFO",
        "lon": "44.333332",
        "iso": "ET",
        "status": 1,
        "name": "Kelafo East Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.666667",
        "size": "small"
    },
    {
        "iata": "AWA",
        "lon": "38.4875",
        "iso": "ET",
        "status": 1,
        "name": "Awassa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.061111",
        "size": "small"
    },
    {
        "iata": "LLI",
        "lon": "39.066666",
        "iso": "ET",
        "status": 1,
        "name": "Lalibella Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.016667",
        "size": "small"
    },
    {
        "iata": "MQX",
        "lon": "39.52639",
        "iso": "ET",
        "status": 1,
        "name": "Mekele Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.468333",
        "size": "medium"
    },
    {
        "iata": "NDM",
        "lon": "35.083332",
        "iso": "ET",
        "status": 1,
        "name": "Mendi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.816667",
        "size": "small"
    },
    {
        "iata": "MTF",
        "lon": "35.533333",
        "iso": "ET",
        "status": 1,
        "name": "Mizan Teferi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.966667",
        "size": "small"
    },
    {
        "iata": "NEJ",
        "lon": "35.466667",
        "iso": "ET",
        "status": 1,
        "name": "Nejjo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.5",
        "size": "small"
    },
    {
        "iata": "NEK",
        "lon": "36.5",
        "iso": "ET",
        "status": 1,
        "name": "Nekemte Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.066667",
        "size": "small"
    },
    {
        "iata": "SXU",
        "lon": "37.775",
        "iso": "ET",
        "status": 1,
        "name": "Soddu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.836111",
        "size": "small"
    },
    {
        "iata": "ASO",
        "lon": "34.536667",
        "iso": "ET",
        "status": 1,
        "name": "Asosa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.047222",
        "size": "medium"
    },
    {
        "iata": "TIE",
        "lon": "35.41528",
        "iso": "ET",
        "status": 1,
        "name": "Tippi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.201111",
        "size": "small"
    },
    {
        "iata": "WAC",
        "lon": "37.166668",
        "iso": "ET",
        "status": 1,
        "name": "Waca Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.166667",
        "size": "small"
    },
    {
        "iata": "BJM",
        "lon": "29.366667",
        "iso": "BI",
        "status": 1,
        "name": "Bujumbura International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.383333",
        "size": "medium"
    },
    {
        "iata": "GID",
        "lon": "29.916668",
        "iso": "BI",
        "status": 1,
        "name": "Gitega Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.416667",
        "size": "small"
    },
    {
        "iata": "ALU",
        "lon": "50.8",
        "iso": "SO",
        "status": 1,
        "name": "Alula Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.966667",
        "size": "small"
    },
    {
        "iata": "BIB",
        "lon": "43.63333",
        "iso": "SO",
        "status": 1,
        "name": "Baidoa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.066667",
        "size": "small"
    },
    {
        "iata": "CXN",
        "lon": "49.916668",
        "iso": "SO",
        "status": 1,
        "name": "Candala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.5",
        "size": "small"
    },
    {
        "iata": "HCM",
        "lon": "49.8",
        "iso": "SO",
        "status": 1,
        "name": "Eil Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.916667",
        "size": "small"
    },
    {
        "iata": "BSA",
        "lon": "49.15",
        "iso": "SO",
        "status": 1,
        "name": "Bosaso Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.283333",
        "size": "small"
    },
    {
        "iata": "GSR",
        "lon": "49.05",
        "iso": "SO",
        "status": 1,
        "name": "Gardo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.5",
        "size": "small"
    },
    {
        "iata": "HGA",
        "lon": "44.091667",
        "iso": "SO",
        "status": 1,
        "name": "Egal International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.515833",
        "size": "medium"
    },
    {
        "iata": "BBO",
        "lon": "45.00639",
        "iso": "SO",
        "status": 1,
        "name": "Berbera Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.419444",
        "size": "medium"
    },
    {
        "iata": "KMU",
        "lon": "42.461792",
        "iso": "SO",
        "status": 1,
        "name": "Kisimayu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.377185",
        "size": "medium"
    },
    {
        "iata": "MGQ",
        "lon": "45.31333",
        "iso": "SO",
        "status": 1,
        "name": "Aden Adde International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.013333",
        "size": "medium"
    },
    {
        "iata": "CMO",
        "lon": "48.516666",
        "iso": "SO",
        "status": 1,
        "name": "Obbia Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.366667",
        "size": "small"
    },
    {
        "iata": "GLK",
        "lon": "47.433334",
        "iso": "SO",
        "status": 1,
        "name": "Galcaio Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.766667",
        "size": "small"
    },
    {
        "iata": "CMS",
        "lon": "50.233334",
        "iso": "SO",
        "status": 1,
        "name": "Scusciuban Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.3",
        "size": "small"
    },
    {
        "iata": "ERA",
        "lon": "47.4",
        "iso": "SO",
        "status": 1,
        "name": "Erigavo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.616667",
        "size": "small"
    },
    {
        "iata": "BUO",
        "lon": "45.55417",
        "iso": "SO",
        "status": 1,
        "name": "Burao Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.5225",
        "size": "small"
    },
    {
        "iata": "JIB",
        "lon": "43.149677",
        "iso": "DJ",
        "status": 1,
        "name": "Djibouti-Ambouli Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.552361",
        "size": "medium"
    },
    {
        "iata": "AII",
        "lon": "42.716667",
        "iso": "DJ",
        "status": 1,
        "name": "Ali-Sabieh Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.15",
        "size": "small"
    },
    {
        "iata": "MHI",
        "lon": "43.0",
        "iso": "DJ",
        "status": 1,
        "name": "Moucha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.0",
        "size": "small"
    },
    {
        "iata": "OBC",
        "lon": "43.266666",
        "iso": "DJ",
        "status": 1,
        "name": "Obock Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.983333",
        "size": "small"
    },
    {
        "iata": "TDJ",
        "lon": "42.9",
        "iso": "DJ",
        "status": 1,
        "name": "Tadjoura Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "11.783333",
        "size": "small"
    },
    {
        "iata": "SEW",
        "lon": "25.50645",
        "iso": "EG",
        "status": 1,
        "name": "Siwa Oasis North Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "29.33371",
        "size": "small"
    },
    {
        "iata": "DBB",
        "lon": "28.46139",
        "iso": "EG",
        "status": 1,
        "name": "El Alamein International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.924444",
        "size": "medium"
    },
    {
        "iata": "AAC",
        "lon": "33.832287",
        "iso": "EG",
        "status": 1,
        "name": "El Arish International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.076414",
        "size": "medium"
    },
    {
        "iata": "ATZ",
        "lon": "31.0",
        "iso": "EG",
        "status": 1,
        "name": "Assiut International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.033333",
        "size": "medium"
    },
    {
        "iata": "ALY",
        "lon": "29.953058",
        "iso": "EG",
        "status": 1,
        "name": "El Nouzha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.192545",
        "size": "medium"
    },
    {
        "iata": "HBE",
        "lon": "29.686504",
        "iso": "EG",
        "status": 1,
        "name": "Borg El Arab International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.922234",
        "size": "medium"
    },
    {
        "iata": "ABS",
        "lon": "31.609774",
        "iso": "EG",
        "status": 1,
        "name": "Abu Simbel Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "22.367567",
        "size": "medium"
    },
    {
        "iata": "CAI",
        "lon": "31.40647",
        "iso": "EG",
        "status": 1,
        "name": "Cairo International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.120106",
        "size": "large"
    },
    {
        "iata": "CWE",
        "iso": "EG",
        "status": 1,
        "name": "Cairo West Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "DAK",
        "lon": "28.999166",
        "iso": "EG",
        "status": 1,
        "name": "Dakhla Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "25.414722",
        "size": "small"
    },
    {
        "iata": "HRG",
        "lon": "33.8055",
        "iso": "EG",
        "status": 1,
        "name": "Hurghada International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.189156",
        "size": "large"
    },
    {
        "iata": "EGH",
        "iso": "EG",
        "status": 1,
        "name": "El Gora Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "UVL",
        "lon": "30.590834",
        "iso": "EG",
        "status": 1,
        "name": "El Kharga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "25.475",
        "size": "small"
    },
    {
        "iata": "LXR",
        "lon": "32.699684",
        "iso": "EG",
        "status": 1,
        "name": "Luxor International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "25.67492",
        "size": "medium"
    },
    {
        "iata": "RMF",
        "lon": "34.592777",
        "iso": "EG",
        "status": 1,
        "name": "Marsa Alam International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "25.555786",
        "size": "medium"
    },
    {
        "iata": "HMB",
        "lon": "31.737167",
        "iso": "EG",
        "status": 1,
        "name": "Sohag International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "26.338877",
        "size": "medium"
    },
    {
        "iata": "MUH",
        "lon": "27.25",
        "iso": "EG",
        "status": 1,
        "name": "Mersa Matruh Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.35",
        "size": "medium"
    },
    {
        "iata": "GSQ",
        "lon": "28.716667",
        "iso": "EG",
        "status": 1,
        "name": "Shark El Oweinat International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "22.583332",
        "size": "small"
    },
    {
        "iata": "PSD",
        "lon": "32.3",
        "iso": "EG",
        "status": 1,
        "name": "Port Said Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.266666",
        "size": "medium"
    },
    {
        "iata": "SKV",
        "lon": "34.0625",
        "iso": "EG",
        "status": 1,
        "name": "St Catherine International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.685278",
        "size": "medium"
    },
    {
        "iata": "SSH",
        "lon": "34.385254",
        "iso": "EG",
        "status": 1,
        "name": "Sharm El Sheikh International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.979357",
        "size": "medium"
    },
    {
        "iata": "ASW",
        "lon": "32.824818",
        "iso": "EG",
        "status": 1,
        "name": "Aswan International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "23.968021",
        "size": "medium"
    },
    {
        "iata": "TCP",
        "lon": "34.916668",
        "iso": "EG",
        "status": 1,
        "name": "Taba International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.55",
        "size": "medium"
    },
    {
        "iata": "ELT",
        "lon": "33.63333",
        "iso": "EG",
        "status": 1,
        "name": "El Tor Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.216667",
        "size": "small"
    },
    {
        "iata": "HGI",
        "iso": "SS",
        "status": 1,
        "name": "Palogue Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ASM",
        "lon": "38.91028",
        "iso": "ER",
        "status": 1,
        "name": "Asmara International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.291111",
        "size": "medium"
    },
    {
        "iata": "MSW",
        "lon": "39.441113",
        "iso": "ER",
        "status": 1,
        "name": "Massawa International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.603333",
        "size": "medium"
    },
    {
        "iata": "ASA",
        "lon": "42.638332",
        "iso": "ER",
        "status": 1,
        "name": "Assab International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.07",
        "size": "medium"
    },
    {
        "iata": "TES",
        "lon": "36.683334",
        "iso": "ER",
        "status": 1,
        "name": "Tessenei Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.116667",
        "size": "small"
    },
    {
        "iata": "HIA",
        "lon": "119.12778",
        "iso": "CN",
        "status": 1,
        "name": "Lianshui Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.7875",
        "size": "medium"
    },
    {
        "iata": "HIL",
        "lon": "44.766666",
        "iso": "ET",
        "status": 1,
        "name": "Shilavo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "6.083333",
        "size": "small"
    },
    {
        "iata": "ASV",
        "lon": "37.25",
        "iso": "KE",
        "status": 1,
        "name": "Amboseli Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.633333",
        "size": "medium"
    },
    {
        "iata": "EDL",
        "lon": "35.2236",
        "iso": "KE",
        "status": 1,
        "name": "Eldoret International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.4054",
        "size": "medium"
    },
    {
        "iata": "EYS",
        "lon": "35.966667",
        "iso": "KE",
        "status": 1,
        "name": "Eliye Springs Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.25",
        "size": "small"
    },
    {
        "iata": "KLK",
        "lon": "25.783333",
        "iso": "KE",
        "status": 1,
        "name": "Kalokol Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.783333",
        "size": "small"
    },
    {
        "iata": "GAS",
        "lon": "39.65",
        "iso": "KE",
        "status": 1,
        "name": "Garissa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.45",
        "size": "small"
    },
    {
        "iata": "HOA",
        "lon": "40.0",
        "iso": "KE",
        "status": 1,
        "name": "Hola Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.0",
        "size": "small"
    },
    {
        "iata": "NBO",
        "lon": "36.92578",
        "iso": "KE",
        "status": 1,
        "name": "Jomo Kenyatta International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.319167",
        "size": "large"
    },
    {
        "iata": "GGM",
        "lon": "34.783333",
        "iso": "KE",
        "status": 1,
        "name": "Kakamega Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.266425",
        "size": "small"
    },
    {
        "iata": "KIS",
        "lon": "34.7375",
        "iso": "KE",
        "status": 1,
        "name": "Kisumu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.084946",
        "size": "medium"
    },
    {
        "iata": "ILU",
        "lon": "38.05",
        "iso": "KE",
        "status": 1,
        "name": "Kilaguni Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.9",
        "size": "small"
    },
    {
        "iata": "KEY",
        "lon": "35.283333",
        "iso": "KE",
        "status": 1,
        "name": "Kericho Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.366667",
        "size": "small"
    },
    {
        "iata": "KTL",
        "lon": "34.959248",
        "iso": "KE",
        "status": 1,
        "name": "Kitale Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.974111",
        "size": "medium"
    },
    {
        "iata": "LKG",
        "lon": "34.35",
        "iso": "KE",
        "status": 1,
        "name": "Lokichoggio Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.233333",
        "size": "medium"
    },
    {
        "iata": "LOK",
        "lon": "35.6",
        "iso": "KE",
        "status": 1,
        "name": "Lodwar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.15",
        "size": "medium"
    },
    {
        "iata": "LAU",
        "lon": "40.911667",
        "iso": "KE",
        "status": 1,
        "name": "Manda Airstrip",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.25",
        "size": "medium"
    },
    {
        "iata": "LOY",
        "lon": "36.716667",
        "iso": "KE",
        "status": 1,
        "name": "Loyengalani Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.75",
        "size": "small"
    },
    {
        "iata": "NDE",
        "lon": "41.86667",
        "iso": "KE",
        "status": 1,
        "name": "Mandera Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.933333",
        "size": "small"
    },
    {
        "iata": "RBT",
        "lon": "37.983334",
        "iso": "KE",
        "status": 1,
        "name": "Segel Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.333333",
        "size": "small"
    },
    {
        "iata": "MYD",
        "lon": "40.10048",
        "iso": "KE",
        "status": 1,
        "name": "Malindi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.230755",
        "size": "medium"
    },
    {
        "iata": "MBA",
        "lon": "39.60325",
        "iso": "KE",
        "status": 1,
        "name": "Mombasa Moi International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.0327",
        "size": "large"
    },
    {
        "iata": "MRE",
        "lon": "35.05885",
        "iso": "KE",
        "status": 1,
        "name": "Mara Serena Lodge Airstrip",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.300368",
        "size": "medium"
    },
    {
        "iata": "OYL",
        "lon": "39.05",
        "iso": "KE",
        "status": 1,
        "name": "Moyale Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.533333",
        "size": "small"
    },
    {
        "iata": "NYE",
        "lon": "36.95",
        "iso": "KE",
        "status": 1,
        "name": "Nyeri Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.416667",
        "size": "small"
    },
    {
        "iata": "NUU",
        "lon": "36.15",
        "iso": "KE",
        "status": 1,
        "name": "Nakuru Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.3",
        "size": "small"
    },
    {
        "iata": "WIL",
        "lon": "36.813248",
        "iso": "KE",
        "status": 1,
        "name": "Nairobi Wilson Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.318018",
        "size": "medium"
    },
    {
        "iata": "NYK",
        "lon": "37.066666",
        "iso": "KE",
        "status": 1,
        "name": "Nanyuki Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.016667",
        "size": "medium"
    },
    {
        "iata": "UAS",
        "lon": "37.55",
        "iso": "KE",
        "status": 1,
        "name": "Samburu South Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.466667",
        "size": "small"
    },
    {
        "iata": "UKA",
        "lon": "39.566666",
        "iso": "KE",
        "status": 1,
        "name": "Ukunda Airstrip",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.3",
        "size": "small"
    },
    {
        "iata": "WJR",
        "lon": "40.6",
        "iso": "KE",
        "status": 1,
        "name": "Wajir Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.766667",
        "size": "medium"
    },
    {
        "iata": "SRX",
        "lon": "16.612036",
        "iso": "LY",
        "status": 1,
        "name": "Gardabya Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.060917",
        "size": "medium"
    },
    {
        "iata": "TOB",
        "lon": "23.917221",
        "iso": "LY",
        "status": 1,
        "name": "Gamal Abdel Nasser Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "31.854168",
        "size": "small"
    },
    {
        "iata": "GHT",
        "lon": "10.166667",
        "iso": "LY",
        "status": 1,
        "name": "Ghat Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "24.966667",
        "size": "medium"
    },
    {
        "iata": "AKF",
        "lon": "23.333332",
        "iso": "LY",
        "status": 1,
        "name": "Kufra Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "24.2",
        "size": "medium"
    },
    {
        "iata": "BEN",
        "lon": "20.26489",
        "iso": "LY",
        "status": 1,
        "name": "Benina International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.085423",
        "size": "medium"
    },
    {
        "iata": "MJI",
        "lon": "13.277778",
        "iso": "LY",
        "status": 1,
        "name": "Mitiga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.89722",
        "size": "medium"
    },
    {
        "iata": "LAQ",
        "lon": "21.964167",
        "iso": "LY",
        "status": 1,
        "name": "La Abraq Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.788612",
        "size": "medium"
    },
    {
        "iata": "SEB",
        "lon": "14.4625",
        "iso": "LY",
        "status": 1,
        "name": "Sabha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.016666",
        "size": "medium"
    },
    {
        "iata": "TIP",
        "lon": "13.144279",
        "iso": "LY",
        "status": 1,
        "name": "Tripoli International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.66989",
        "size": "large"
    },
    {
        "iata": "LMQ",
        "lon": "19.579445",
        "iso": "LY",
        "status": 1,
        "name": "Marsa Brega Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.379444",
        "size": "medium"
    },
    {
        "iata": "HUQ",
        "lon": "15.5",
        "iso": "LY",
        "status": 1,
        "name": "Hon Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "29.5",
        "size": "small"
    },
    {
        "iata": "LTD",
        "lon": "9.509722",
        "iso": "LY",
        "status": 1,
        "name": "Ghadames East Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "30.129168",
        "size": "medium"
    },
    {
        "iata": "WAX",
        "lon": "-55.38333",
        "iso": "LY",
        "status": 1,
        "name": "Zwara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "51.216667",
        "size": "small"
    },
    {
        "iata": "GYI",
        "lon": "29.25",
        "iso": "RW",
        "status": 1,
        "name": "Gisenyi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.683333",
        "size": "medium"
    },
    {
        "iata": "BTQ",
        "lon": "29.733334",
        "iso": "RW",
        "status": 1,
        "name": "Butare Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.6",
        "size": "small"
    },
    {
        "iata": "KGL",
        "lon": "30.135014",
        "iso": "RW",
        "status": 1,
        "name": "Kigali International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.963042",
        "size": "medium"
    },
    {
        "iata": "RHG",
        "lon": "29.6",
        "iso": "RW",
        "status": 1,
        "name": "Ruhengeri Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.5",
        "size": "small"
    },
    {
        "iata": "KME",
        "lon": "28.916668",
        "iso": "RW",
        "status": 1,
        "name": "Kamembe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.466667",
        "size": "medium"
    },
    {
        "iata": "ATB",
        "lon": "34.016666",
        "iso": "SD",
        "status": 1,
        "name": "Atbara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "17.716667",
        "size": "small"
    },
    {
        "iata": "EDB",
        "lon": "30.833332",
        "iso": "SD",
        "status": 1,
        "name": "El Debba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "18.283333",
        "size": "small"
    },
    {
        "iata": "DOG",
        "lon": "30.45",
        "iso": "SD",
        "status": 1,
        "name": "Dongola Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "19.183332",
        "size": "medium"
    },
    {
        "iata": "ELF",
        "lon": "25.316668",
        "iso": "SD",
        "status": 1,
        "name": "El Fasher Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.616667",
        "size": "medium"
    },
    {
        "iata": "GSU",
        "lon": "35.466667",
        "iso": "SD",
        "status": 1,
        "name": "Azaza Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.033333",
        "size": "small"
    },
    {
        "iata": "DNX",
        "lon": "33.066666",
        "iso": "SD",
        "status": 1,
        "name": "Galegu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.1",
        "size": "small"
    },
    {
        "iata": "EGN",
        "lon": "22.469444",
        "iso": "SD",
        "status": 1,
        "name": "Geneina Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.4875",
        "size": "small"
    },
    {
        "iata": "KSL",
        "lon": "36.34222",
        "iso": "SD",
        "status": 1,
        "name": "Kassala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.390278",
        "size": "medium"
    },
    {
        "iata": "GBU",
        "lon": "35.88889",
        "iso": "SD",
        "status": 1,
        "name": "Khashm El Girba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "14.926389",
        "size": "small"
    },
    {
        "iata": "KST",
        "lon": "32.716667",
        "iso": "SD",
        "status": 1,
        "name": "Kosti Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.133333",
        "size": "small"
    },
    {
        "iata": "KDX",
        "iso": "SD",
        "status": 1,
        "name": "Kadugli Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "MWE",
        "lon": "31.833332",
        "iso": "SD",
        "status": 1,
        "name": "Merowe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "18.45",
        "size": "small"
    },
    {
        "iata": "NUD",
        "lon": "28.416668",
        "iso": "SD",
        "status": 1,
        "name": "En Nahud Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.75",
        "size": "small"
    },
    {
        "iata": "UYL",
        "lon": "24.894722",
        "iso": "SD",
        "status": 1,
        "name": "Nyala Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "12.071667",
        "size": "small"
    },
    {
        "iata": "NHF",
        "lon": "35.733334",
        "iso": "SD",
        "status": 1,
        "name": "New Halfa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.35",
        "size": "small"
    },
    {
        "iata": "EBD",
        "lon": "30.234167",
        "iso": "SD",
        "status": 1,
        "name": "El Obeid Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "13.159722",
        "size": "medium"
    },
    {
        "iata": "PZU",
        "lon": "37.21389",
        "iso": "SD",
        "status": 1,
        "name": "Port Sudan New International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "19.577778",
        "size": "medium"
    },
    {
        "iata": "JUB",
        "lon": "31.601692",
        "iso": "SS",
        "status": 1,
        "name": "Juba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "4.866198",
        "size": "medium"
    },
    {
        "iata": "MAK",
        "lon": "31.644444",
        "iso": "SS",
        "status": 1,
        "name": "Malakal Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.555556",
        "size": "medium"
    },
    {
        "iata": "KRT",
        "lon": "32.549698",
        "iso": "SD",
        "status": 1,
        "name": "Khartoum International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "15.592217",
        "size": "large"
    },
    {
        "iata": "WHF",
        "lon": "31.5",
        "iso": "SD",
        "status": 1,
        "name": "Wadi Halfa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "21.75",
        "size": "small"
    },
    {
        "iata": "WUU",
        "lon": "27.985277",
        "iso": "SS",
        "status": 1,
        "name": "Wau Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "7.746389",
        "size": "medium"
    },
    {
        "iata": "ARK",
        "lon": "36.683334",
        "iso": "TZ",
        "status": 1,
        "name": "Arusha Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.366667",
        "size": "medium"
    },
    {
        "iata": "BKZ",
        "lon": "31.82253",
        "iso": "TZ",
        "status": 1,
        "name": "Bukoba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.331923",
        "size": "small"
    },
    {
        "iata": "DAR",
        "lon": "39.20211",
        "iso": "TZ",
        "status": 1,
        "name": "Mwalimu Julius K. Nyerere International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.873533",
        "size": "medium"
    },
    {
        "iata": "DOD",
        "lon": "35.75028",
        "iso": "TZ",
        "status": 1,
        "name": "Dodoma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.166667",
        "size": "medium"
    },
    {
        "iata": "IRI",
        "lon": "35.75028",
        "iso": "TZ",
        "status": 1,
        "name": "Iringa Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.670556",
        "size": "medium"
    },
    {
        "iata": "TKQ",
        "lon": "29.67",
        "iso": "TZ",
        "status": 1,
        "name": "Kigoma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.885",
        "size": "small"
    },
    {
        "iata": "KIY",
        "lon": "39.511665",
        "iso": "TZ",
        "status": 1,
        "name": "Kilwa Masoko Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.911944",
        "size": "small"
    },
    {
        "iata": "JRO",
        "lon": "37.065342",
        "iso": "TZ",
        "status": 1,
        "name": "Kilimanjaro International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.424123",
        "size": "medium"
    },
    {
        "iata": "LDI",
        "lon": "39.759445",
        "iso": "TZ",
        "status": 1,
        "name": "Kikwetu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.845833",
        "size": "small"
    },
    {
        "iata": "LKY",
        "lon": "35.82021",
        "iso": "TZ",
        "status": 1,
        "name": "Lake Manyara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.375889",
        "size": "medium"
    },
    {
        "iata": "MFA",
        "lon": "39.665",
        "iso": "TZ",
        "status": 1,
        "name": "Mafia Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.913889",
        "size": "small"
    },
    {
        "iata": "MBI",
        "lon": "33.45886",
        "iso": "TZ",
        "status": 1,
        "name": "Mbeya Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-8.916634",
        "size": "small"
    },
    {
        "iata": "MWN",
        "lon": "33.6",
        "iso": "TZ",
        "status": 1,
        "name": "Mwadui Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.55",
        "size": "small"
    },
    {
        "iata": "XMI",
        "lon": "38.8",
        "iso": "TZ",
        "status": 1,
        "name": "Masasi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.716667",
        "size": "small"
    },
    {
        "iata": "QSI",
        "iso": "TZ",
        "status": 1,
        "name": "Moshi Airport",
        "continent": "AF",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "MYW",
        "lon": "40.19361",
        "iso": "TZ",
        "status": 1,
        "name": "Mtwara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.338889",
        "size": "medium"
    },
    {
        "iata": "MUZ",
        "lon": "33.8",
        "iso": "TZ",
        "status": 1,
        "name": "Musoma Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.497222",
        "size": "small"
    },
    {
        "iata": "MWZ",
        "lon": "32.923225",
        "iso": "TZ",
        "status": 1,
        "name": "Mwanza Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.441221",
        "size": "medium"
    },
    {
        "iata": "NCH",
        "lon": "38.775",
        "iso": "TZ",
        "status": 1,
        "name": "Nachingwea Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.363889",
        "size": "small"
    },
    {
        "iata": "JOM",
        "lon": "34.77139",
        "iso": "TZ",
        "status": 1,
        "name": "Njombe Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-9.354444",
        "size": "small"
    },
    {
        "iata": "PMA",
        "lon": "39.81262",
        "iso": "TZ",
        "status": 1,
        "name": "Pemba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.258731",
        "size": "medium"
    },
    {
        "iata": "SEU",
        "lon": "34.821545",
        "iso": "TZ",
        "status": 1,
        "name": "Seronera Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.4567",
        "size": "small"
    },
    {
        "iata": "SGX",
        "lon": "35.5775",
        "iso": "TZ",
        "status": 1,
        "name": "Songea Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-10.686667",
        "size": "small"
    },
    {
        "iata": "SUT",
        "lon": "31.616667",
        "iso": "TZ",
        "status": 1,
        "name": "Sumbawanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-7.966667",
        "size": "small"
    },
    {
        "iata": "SHY",
        "lon": "33.504486",
        "iso": "TZ",
        "status": 1,
        "name": "Shinyanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.608185",
        "size": "small"
    },
    {
        "iata": "TBO",
        "lon": "32.8375",
        "iso": "TZ",
        "status": 1,
        "name": "Tabora Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.0725",
        "size": "small"
    },
    {
        "iata": "TGT",
        "lon": "39.07028",
        "iso": "TZ",
        "status": 1,
        "name": "Tanga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-5.089444",
        "size": "medium"
    },
    {
        "iata": "ZNZ",
        "lon": "39.221184",
        "iso": "TZ",
        "status": 1,
        "name": "Zanzibar Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-6.218466",
        "size": "medium"
    },
    {
        "iata": "RUA",
        "lon": "30.933332",
        "iso": "UG",
        "status": 1,
        "name": "Arua Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.033333",
        "size": "medium"
    },
    {
        "iata": "EBB",
        "lon": "32.443184",
        "iso": "UG",
        "status": 1,
        "name": "Entebbe International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.045111",
        "size": "medium"
    },
    {
        "iata": "ULU",
        "lon": "32.3",
        "iso": "UG",
        "status": 1,
        "name": "Gulu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.783333",
        "size": "medium"
    },
    {
        "iata": "JIN",
        "lon": "33.192223",
        "iso": "UG",
        "status": 1,
        "name": "Jinja Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.456389",
        "size": "small"
    },
    {
        "iata": "KBG",
        "lon": "31.500895",
        "iso": "UG",
        "status": 1,
        "name": "Kabalega Falls Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.329142",
        "size": "small"
    },
    {
        "iata": "KSE",
        "lon": "30.100834",
        "iso": "UG",
        "status": 1,
        "name": "Kasese Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.185556",
        "size": "small"
    },
    {
        "iata": "MBQ",
        "lon": "30.65",
        "iso": "UG",
        "status": 1,
        "name": "Mbarara Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-0.616667",
        "size": "small"
    },
    {
        "iata": "KCU",
        "lon": "31.716667",
        "iso": "UG",
        "status": 1,
        "name": "Masindi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.683333",
        "size": "small"
    },
    {
        "iata": "SRT",
        "lon": "33.61667",
        "iso": "UG",
        "status": 1,
        "name": "Soroti Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "1.7",
        "size": "medium"
    },
    {
        "iata": "TRY",
        "lon": "34.183334",
        "iso": "UG",
        "status": 1,
        "name": "Tororo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.7",
        "size": "small"
    },
    {
        "iata": "IBL",
        "iso": "MZ",
        "status": 1,
        "name": "Indigo Bay Lodge Airport",
        "continent": "AF",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "PPJ",
        "lon": "103.3",
        "iso": "ID",
        "status": 1,
        "name": "Pulau Panjang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.633333",
        "size": "small"
    },
    {
        "iata": "AAS",
        "lon": "139.25",
        "iso": "ID",
        "status": 1,
        "name": "Apalapsili Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.916667",
        "size": "small"
    },
    {
        "iata": "AGD",
        "lon": "133.86667",
        "iso": "ID",
        "status": 1,
        "name": "Anggi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.383333",
        "size": "small"
    },
    {
        "iata": "AKQ",
        "lon": "105.23333",
        "iso": "ID",
        "status": 1,
        "name": "Gunung Batin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.616667",
        "size": "small"
    },
    {
        "iata": "AYW",
        "lon": "132.5",
        "iso": "ID",
        "status": 1,
        "name": "Ayawasi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.2",
        "size": "small"
    },
    {
        "iata": "BJG",
        "lon": "122.1",
        "iso": "ID",
        "status": 1,
        "name": "Boalang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.95",
        "size": "small"
    },
    {
        "iata": "BXM",
        "lon": "140.85",
        "iso": "ID",
        "status": 1,
        "name": "Batom Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.116667",
        "size": "small"
    },
    {
        "iata": "DRH",
        "lon": "138.56667",
        "iso": "ID",
        "status": 1,
        "name": "Dabra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.25",
        "size": "small"
    },
    {
        "iata": "ELR",
        "lon": "140.06667",
        "iso": "ID",
        "status": 1,
        "name": "Elelim Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.816667",
        "size": "small"
    },
    {
        "iata": "EWE",
        "lon": "138.08333",
        "iso": "ID",
        "status": 1,
        "name": "Ewer Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.483333",
        "size": "small"
    },
    {
        "iata": "FOO",
        "lon": "134.9",
        "iso": "ID",
        "status": 1,
        "name": "Kornasoren Airfield",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.05",
        "size": "small"
    },
    {
        "iata": "GAV",
        "lon": "130.88333",
        "iso": "ID",
        "status": 1,
        "name": "Gag Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-26.0",
        "size": "small"
    },
    {
        "iata": "IUL",
        "lon": "138.16667",
        "iso": "ID",
        "status": 1,
        "name": "Ilu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.733333",
        "size": "small"
    },
    {
        "iata": "KBF",
        "lon": "138.45",
        "iso": "ID",
        "status": 1,
        "name": "Karubaga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.716667",
        "size": "small"
    },
    {
        "iata": "KBX",
        "lon": "132.25",
        "iso": "ID",
        "status": 1,
        "name": "Kambuaya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.0",
        "size": "small"
    },
    {
        "iata": "KCD",
        "lon": "138.71666",
        "iso": "ID",
        "status": 1,
        "name": "Kamur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.2",
        "size": "small"
    },
    {
        "iata": "KCI",
        "lon": "127.05",
        "iso": "TL",
        "status": 1,
        "name": "Kon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.35",
        "size": "small"
    },
    {
        "iata": "KEA",
        "lon": "140.03334",
        "iso": "ID",
        "status": 1,
        "name": "Keisah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.033333",
        "size": "small"
    },
    {
        "iata": "KMM",
        "lon": "136.16667",
        "iso": "ID",
        "status": 1,
        "name": "Kiman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.666667",
        "size": "small"
    },
    {
        "iata": "KOD",
        "lon": "116.583336",
        "iso": "ID",
        "status": 1,
        "name": "Kotabangun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.266667",
        "size": "small"
    },
    {
        "iata": "KRC",
        "lon": "101.25",
        "iso": "ID",
        "status": 1,
        "name": "Kerinici airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.716667",
        "size": "small"
    },
    {
        "iata": "KWB",
        "lon": "110.5",
        "iso": "ID",
        "status": 1,
        "name": "Karimunjawa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.833333",
        "size": "small"
    },
    {
        "iata": "LLN",
        "lon": "138.66667",
        "iso": "ID",
        "status": 1,
        "name": "Kelila Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.75",
        "size": "small"
    },
    {
        "iata": "LWE",
        "lon": "124.4387",
        "iso": "ID",
        "status": 1,
        "name": "Lewoleba Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.361607",
        "size": "small"
    },
    {
        "iata": "LYK",
        "lon": "117.26667",
        "iso": "ID",
        "status": 1,
        "name": "Lunyuk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-9.0",
        "size": "small"
    },
    {
        "iata": "MJY",
        "lon": "103.566666",
        "iso": "ID",
        "status": 1,
        "name": "Mangunjaya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.733333",
        "size": "small"
    },
    {
        "iata": "MPT",
        "lon": "125.25",
        "iso": "ID",
        "status": 1,
        "name": "Maliana airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.066667",
        "size": "small"
    },
    {
        "iata": "MSI",
        "lon": "114.433334",
        "iso": "ID",
        "status": 1,
        "name": "Masalembo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.583333",
        "size": "small"
    },
    {
        "iata": "MUF",
        "lon": "140.33333",
        "iso": "ID",
        "status": 1,
        "name": "Muting Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.5",
        "size": "small"
    },
    {
        "iata": "NAF",
        "lon": "117.05",
        "iso": "ID",
        "status": 1,
        "name": "Banaina Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.75",
        "size": "small"
    },
    {
        "iata": "NDA",
        "lon": "129.9",
        "iso": "ID",
        "status": 1,
        "name": "Bandanaira Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.533333",
        "size": "small"
    },
    {
        "iata": "OBD",
        "lon": "136.2",
        "iso": "ID",
        "status": 1,
        "name": "Obano Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.9",
        "size": "small"
    },
    {
        "iata": "PPJ",
        "lon": "103.3",
        "iso": "ID",
        "status": 1,
        "name": "Pulau Panjang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.633333",
        "size": "small"
    },
    {
        "iata": "PUM",
        "lon": "121.61667",
        "iso": "ID",
        "status": 1,
        "name": "Pomala Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.183333",
        "size": "small"
    },
    {
        "iata": "PWL",
        "lon": "109.15",
        "iso": "ID",
        "status": 1,
        "name": "Purwokerto Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.466667",
        "size": "small"
    },
    {
        "iata": "RAQ",
        "lon": "122.583336",
        "iso": "ID",
        "status": 1,
        "name": "Sugimanuru Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.916667",
        "size": "small"
    },
    {
        "iata": "RKI",
        "lon": "100.75",
        "iso": "ID",
        "status": 1,
        "name": "Rokot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.95",
        "size": "small"
    },
    {
        "iata": "RTI",
        "lon": "122.96667",
        "iso": "ID",
        "status": 1,
        "name": "Roti Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-10.883333",
        "size": "small"
    },
    {
        "iata": "RUF",
        "lon": "140.93333",
        "iso": "ID",
        "status": 1,
        "name": "Yuruf Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.633333",
        "size": "small"
    },
    {
        "iata": "RZS",
        "lon": "68.86667",
        "iso": "ID",
        "status": 1,
        "name": "Sawan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.966667",
        "size": "small"
    },
    {
        "iata": "SAE",
        "lon": "-51.632145",
        "iso": "ID",
        "status": 1,
        "name": "Sangir Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "70.811455",
        "size": "small"
    },
    {
        "iata": "TBM",
        "lon": "112.583336",
        "iso": "ID",
        "status": 1,
        "name": "Tumbang Samba Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.25",
        "size": "small"
    },
    {
        "iata": "TMY",
        "lon": "138.41667",
        "iso": "ID",
        "status": 1,
        "name": "Tiom Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.95",
        "size": "small"
    },
    {
        "iata": "ZEG",
        "lon": "139.36667",
        "iso": "ID",
        "status": 1,
        "name": "Senggo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.983333",
        "size": "small"
    },
    {
        "iata": "ZGP",
        "iso": "ID",
        "status": 1,
        "name": "Zugapa Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "IOR",
        "lon": "-9.75",
        "iso": "IE",
        "status": 1,
        "name": "Kilronan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.11667",
        "size": "small"
    },
    {
        "iata": "CHE",
        "lon": "-10.233333",
        "iso": "IE",
        "status": 1,
        "name": "Reeroe Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.933334",
        "size": "small"
    },
    {
        "iata": "VDY",
        "iso": "IN",
        "status": 1,
        "name": "Vijayanagar Aerodrome (JSW)",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "JGB",
        "lon": "82.03333",
        "iso": "IN",
        "status": 1,
        "name": "Jagdalpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.066668",
        "size": "small"
    },
    {
        "iata": "NVY",
        "lon": "79.433334",
        "iso": "IN",
        "status": 1,
        "name": "Neyveli Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.6",
        "size": "small"
    },
    {
        "iata": "RJI",
        "lon": "74.3025",
        "iso": "IN",
        "status": 1,
        "name": "Rajouri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.36861",
        "size": "small"
    },
    {
        "iata": "TEI",
        "lon": "96.13333",
        "iso": "IN",
        "status": 1,
        "name": "Tezu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.95",
        "size": "small"
    },
    {
        "iata": "KHA",
        "lon": "45.15",
        "iso": "IR",
        "status": 1,
        "name": "Khaneh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.733334",
        "size": "small"
    },
    {
        "iata": "GSM",
        "lon": "55.905277",
        "iso": "IR",
        "status": 1,
        "name": "Gheshm Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.757778",
        "size": "small"
    },
    {
        "iata": "TQR",
        "iso": "IT",
        "status": 1,
        "name": "San Domino Island Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JGD",
        "lon": "124.11667",
        "iso": "CN",
        "status": 1,
        "name": "Jiagedaqi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.370556",
        "size": "medium"
    },
    {
        "iata": "JIC",
        "lon": "102.34861",
        "iso": "CN",
        "status": 1,
        "name": "Jinchuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.541943",
        "size": "small"
    },
    {
        "iata": "JIQ",
        "lon": "108.829185",
        "iso": "CN",
        "status": 1,
        "name": "Qianjiang Wulingshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.517557",
        "size": "medium"
    },
    {
        "iata": "JMC",
        "iso": "US",
        "status": 1,
        "name": "Commodore Center Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "JRA",
        "iso": "US",
        "status": 1,
        "name": "West 30th St. Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "AMK",
        "lon": "-107.75",
        "iso": "US",
        "status": 1,
        "name": "Animas Air Park",
        "continent": "NA",
        "type": "airport",
        "lat": "37.15",
        "size": "small"
    },
    {
        "iata": "RLA",
        "lon": "-91.77805",
        "iso": "US",
        "status": 1,
        "name": "Rolla Downtown Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.127777",
        "size": "small"
    },
    {
        "iata": "FID",
        "lon": "-72.0",
        "iso": "US",
        "status": 1,
        "name": "Elizabeth Field",
        "continent": "NA",
        "type": "airport",
        "lat": "41.0",
        "size": "small"
    },
    {
        "iata": "AHD",
        "lon": "-97.13333",
        "iso": "US",
        "status": 1,
        "name": "Ardmore Downtown Executive Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.166668",
        "size": "small"
    },
    {
        "iata": "GCW",
        "lon": "-113.815674",
        "iso": "US",
        "status": 1,
        "name": "Grand Canyon West Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.988804",
        "size": "small"
    },
    {
        "iata": "RKC",
        "lon": "-122.63333",
        "iso": "US",
        "status": 1,
        "name": "Montague-Yreka Rohrer Field",
        "continent": "NA",
        "type": "airport",
        "lat": "41.733334",
        "size": "small"
    },
    {
        "iata": "GNF",
        "lon": "-89.93228",
        "iso": "US",
        "status": 1,
        "name": "Gansner Field",
        "continent": "NA",
        "type": "airport",
        "lat": "33.975307",
        "size": "small"
    },
    {
        "iata": "AHF",
        "lon": "-99.9",
        "iso": "US",
        "status": 1,
        "name": "Arapahoe Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.3",
        "size": "small"
    },
    {
        "iata": "GTP",
        "lon": "-123.388695",
        "iso": "US",
        "status": 1,
        "name": "Grants Pass Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.50926",
        "size": "small"
    },
    {
        "iata": "GCD",
        "iso": "US",
        "status": 1,
        "name": "Grand Coulee Dam Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "FPY",
        "lon": "-83.583336",
        "iso": "US",
        "status": 1,
        "name": "Perry Foley Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.116667",
        "size": "small"
    },
    {
        "iata": "FHB",
        "iso": "US",
        "status": 1,
        "name": "Fernandina Beach Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BLD",
        "lon": "-114.85936",
        "iso": "US",
        "status": 1,
        "name": "Boulder City Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.947018",
        "size": "small"
    },
    {
        "iata": "AAF",
        "lon": "-84.98333",
        "iso": "US",
        "status": 1,
        "name": "Apalachicola Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.733334",
        "size": "small"
    },
    {
        "iata": "ABE",
        "lon": "-75.434364",
        "iso": "US",
        "status": 1,
        "name": "Lehigh Valley International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.651573",
        "size": "medium"
    },
    {
        "iata": "ABI",
        "lon": "-99.679726",
        "iso": "US",
        "status": 1,
        "name": "Abilene Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.409443",
        "size": "medium"
    },
    {
        "iata": "ABQ",
        "lon": "-106.617195",
        "iso": "US",
        "status": 1,
        "name": "Albuquerque International Sunport Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.049625",
        "size": "large"
    },
    {
        "iata": "ABR",
        "lon": "-98.42611",
        "iso": "US",
        "status": 1,
        "name": "Aberdeen Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.4525",
        "size": "medium"
    },
    {
        "iata": "ABY",
        "lon": "-84.19611",
        "iso": "US",
        "status": 1,
        "name": "Southwest Georgia Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.532223",
        "size": "medium"
    },
    {
        "iata": "ACB",
        "lon": "-85.21667",
        "iso": "US",
        "status": 1,
        "name": "Antrim County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.983334",
        "size": "small"
    },
    {
        "iata": "ACK",
        "lon": "-70.05972",
        "iso": "US",
        "status": 1,
        "name": "Nantucket Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.256668",
        "size": "medium"
    },
    {
        "iata": "ACT",
        "lon": "-97.22321",
        "iso": "US",
        "status": 1,
        "name": "Waco Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.609133",
        "size": "medium"
    },
    {
        "iata": "ACV",
        "lon": "-124.106926",
        "iso": "US",
        "status": 1,
        "name": "Arcata Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.970913",
        "size": "medium"
    },
    {
        "iata": "ACY",
        "lon": "-74.572235",
        "iso": "US",
        "status": 1,
        "name": "Atlantic City International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.450703",
        "size": "medium"
    },
    {
        "iata": "ADG",
        "lon": "-84.03333",
        "iso": "US",
        "status": 1,
        "name": "Lenawee County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.9",
        "size": "small"
    },
    {
        "iata": "ADT",
        "lon": "-96.666664",
        "iso": "US",
        "status": 1,
        "name": "Ada Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.8",
        "size": "small"
    },
    {
        "iata": "ADM",
        "lon": "-97.01945",
        "iso": "US",
        "status": 1,
        "name": "Ardmore Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.303055",
        "size": "small"
    },
    {
        "iata": "ADS",
        "lon": "-97.83639",
        "iso": "US",
        "status": 1,
        "name": "Addison Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.968613",
        "size": "small"
    },
    {
        "iata": "ADW",
        "lon": "-76.86667",
        "iso": "US",
        "status": 1,
        "name": "Andrews Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "38.816666",
        "size": "large"
    },
    {
        "iata": "KAE",
        "iso": "US",
        "status": 1,
        "name": "Kake Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "AEL",
        "lon": "-93.36667",
        "iso": "US",
        "status": 1,
        "name": "Albert Lea Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.65",
        "size": "small"
    },
    {
        "iata": "AEX",
        "lon": "-92.53958",
        "iso": "US",
        "status": 1,
        "name": "Alexandria International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.321268",
        "size": "medium"
    },
    {
        "iata": "AFF",
        "lon": "-104.7",
        "iso": "US",
        "status": 1,
        "name": "USAF Academy Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "38.833332",
        "size": "small"
    },
    {
        "iata": "WSG",
        "lon": "-80.25",
        "iso": "US",
        "status": 1,
        "name": "Washington County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.166668",
        "size": "small"
    },
    {
        "iata": "AFN",
        "lon": "-72.066666",
        "iso": "US",
        "status": 1,
        "name": "Jaffrey Airport Silver Ranch Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.833332",
        "size": "small"
    },
    {
        "iata": "AFO",
        "lon": "-110.933334",
        "iso": "US",
        "status": 1,
        "name": "Afton Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.733334",
        "size": "small"
    },
    {
        "iata": "AFW",
        "lon": "-97.316666",
        "iso": "US",
        "status": 1,
        "name": "Fort Worth Alliance Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.986668",
        "size": "large"
    },
    {
        "iata": "AGC",
        "lon": "-79.93",
        "iso": "US",
        "status": 1,
        "name": "Allegheny County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.35472",
        "size": "medium"
    },
    {
        "iata": "AGO",
        "lon": "-93.23333",
        "iso": "US",
        "status": 1,
        "name": "Magnolia Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.266666",
        "size": "small"
    },
    {
        "iata": "AGS",
        "lon": "-81.973434",
        "iso": "US",
        "status": 1,
        "name": "Augusta Regional At Bush Field",
        "continent": "NA",
        "type": "airport",
        "lat": "33.373665",
        "size": "large"
    },
    {
        "iata": "AHC",
        "lon": "-120.13333",
        "iso": "US",
        "status": 1,
        "name": "Amedee Army Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "40.15",
        "size": "small"
    },
    {
        "iata": "AHH",
        "lon": "-92.35",
        "iso": "US",
        "status": 1,
        "name": "Amery Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.3",
        "size": "small"
    },
    {
        "iata": "AHN",
        "lon": "-83.32472",
        "iso": "US",
        "status": 1,
        "name": "Athens Ben Epps Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.95195",
        "size": "medium"
    },
    {
        "iata": "AIA",
        "lon": "-102.80666",
        "iso": "US",
        "status": 1,
        "name": "Alliance Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.051666",
        "size": "small"
    },
    {
        "iata": "AID",
        "lon": "-85.683334",
        "iso": "US",
        "status": 1,
        "name": "Anderson Municipal Darlington Field",
        "continent": "NA",
        "type": "airport",
        "lat": "40.166668",
        "size": "small"
    },
    {
        "iata": "AIK",
        "lon": "-81.68407",
        "iso": "US",
        "status": 1,
        "name": "Aiken Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.649014",
        "size": "small"
    },
    {
        "iata": "AIO",
        "lon": "-95.01667",
        "iso": "US",
        "status": 1,
        "name": "Atlantic Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.4",
        "size": "small"
    },
    {
        "iata": "AIV",
        "lon": "-88.15",
        "iso": "US",
        "status": 1,
        "name": "George Downer Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.13333",
        "size": "small"
    },
    {
        "iata": "AIY",
        "iso": "US",
        "status": 0,
        "name": "Atlantic City Municipal Bader Field",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "AIZ",
        "lon": "-92.54722",
        "iso": "US",
        "status": 1,
        "name": "Lee C Fine Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.09833",
        "size": "small"
    },
    {
        "iata": "AKO",
        "lon": "-103.21667",
        "iso": "US",
        "status": 1,
        "name": "Colorado Plains Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.166668",
        "size": "small"
    },
    {
        "iata": "AKC",
        "lon": "-81.46778",
        "iso": "US",
        "status": 1,
        "name": "Akron Fulton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.04",
        "size": "medium"
    },
    {
        "iata": "ALB",
        "lon": "-73.809555",
        "iso": "US",
        "status": 1,
        "name": "Albany International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.745277",
        "size": "medium"
    },
    {
        "iata": "ALI",
        "lon": "-98.066666",
        "iso": "US",
        "status": 1,
        "name": "Alice International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.75",
        "size": "medium"
    },
    {
        "iata": "ALM",
        "lon": "-105.985275",
        "iso": "US",
        "status": 1,
        "name": "Alamogordo White Sands Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.845833",
        "size": "medium"
    },
    {
        "iata": "ALN",
        "lon": "-90.04833",
        "iso": "US",
        "status": 1,
        "name": "St Louis Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.891666",
        "size": "medium"
    },
    {
        "iata": "ALO",
        "lon": "-92.39972",
        "iso": "US",
        "status": 1,
        "name": "Waterloo Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.55611",
        "size": "medium"
    },
    {
        "iata": "ALS",
        "lon": "-105.86667",
        "iso": "US",
        "status": 1,
        "name": "San Luis Valley Regional Bergman Field",
        "continent": "NA",
        "type": "airport",
        "lat": "37.43667",
        "size": "medium"
    },
    {
        "iata": "ALW",
        "lon": "-118.291115",
        "iso": "US",
        "status": 1,
        "name": "Walla Walla Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.094723",
        "size": "medium"
    },
    {
        "iata": "ALX",
        "lon": "-85.95",
        "iso": "US",
        "status": 1,
        "name": "Thomas C Russell Field",
        "continent": "NA",
        "type": "airport",
        "lat": "32.933334",
        "size": "small"
    },
    {
        "iata": "AMA",
        "lon": "-101.70513",
        "iso": "US",
        "status": 1,
        "name": "Rick Husband Amarillo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.218273",
        "size": "large"
    },
    {
        "iata": "AMN",
        "lon": "-84.65",
        "iso": "US",
        "status": 1,
        "name": "RPD Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.38333",
        "size": "small"
    },
    {
        "iata": "AMW",
        "lon": "-93.61833",
        "iso": "US",
        "status": 1,
        "name": "Ames Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.994167",
        "size": "small"
    },
    {
        "iata": "ANB",
        "lon": "-85.855835",
        "iso": "US",
        "status": 1,
        "name": "Anniston Metropolitan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.59028",
        "size": "medium"
    },
    {
        "iata": "AND",
        "lon": "-82.71",
        "iso": "US",
        "status": 1,
        "name": "Anderson Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.49361",
        "size": "medium"
    },
    {
        "iata": "ANP",
        "lon": "-76.5",
        "iso": "US",
        "status": 1,
        "name": "Lee Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.983334",
        "size": "small"
    },
    {
        "iata": "ANQ",
        "lon": "-85.0",
        "iso": "US",
        "status": 1,
        "name": "Tri State Steuben County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.63333",
        "size": "small"
    },
    {
        "iata": "ANW",
        "lon": "-99.86667",
        "iso": "US",
        "status": 1,
        "name": "Ainsworth Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.55",
        "size": "small"
    },
    {
        "iata": "ANY",
        "lon": "-98.03333",
        "iso": "US",
        "status": 1,
        "name": "Anthony Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.15",
        "size": "small"
    },
    {
        "iata": "AOH",
        "lon": "-84.1",
        "iso": "US",
        "status": 1,
        "name": "Lima Allen County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.766666",
        "size": "small"
    },
    {
        "iata": "AOO",
        "lon": "-78.32",
        "iso": "US",
        "status": 1,
        "name": "Altoona Blair County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.297222",
        "size": "medium"
    },
    {
        "iata": "APA",
        "lon": "-104.84872",
        "iso": "US",
        "status": 1,
        "name": "Centennial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.572124",
        "size": "medium"
    },
    {
        "iata": "APC",
        "lon": "-122.28",
        "iso": "US",
        "status": 1,
        "name": "Napa County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.212223",
        "size": "small"
    },
    {
        "iata": "APF",
        "lon": "-81.775276",
        "iso": "US",
        "status": 1,
        "name": "Naples Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.152779",
        "size": "medium"
    },
    {
        "iata": "APG",
        "lon": "-76.166664",
        "iso": "US",
        "status": 1,
        "name": "Phillips Army Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.5",
        "size": "medium"
    },
    {
        "iata": "APH",
        "lon": "-77.35",
        "iso": "US",
        "status": 1,
        "name": "A P Hill Aaf (Fort A P Hill) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.05",
        "size": "small"
    },
    {
        "iata": "APN",
        "lon": "-83.55583",
        "iso": "US",
        "status": 1,
        "name": "Alpena County Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.08167",
        "size": "medium"
    },
    {
        "iata": "APT",
        "lon": "-85.5",
        "iso": "US",
        "status": 1,
        "name": "Marion County Brown Field",
        "continent": "NA",
        "type": "airport",
        "lat": "35.066666",
        "size": "small"
    },
    {
        "iata": "APV",
        "lon": "-117.212776",
        "iso": "US",
        "status": 1,
        "name": "Apple Valley Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.52639",
        "size": "small"
    },
    {
        "iata": "ARA",
        "lon": "-91.88389",
        "iso": "US",
        "status": 1,
        "name": "Acadiana Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.037779",
        "size": "medium"
    },
    {
        "iata": "ARB",
        "lon": "-83.75",
        "iso": "US",
        "status": 1,
        "name": "Ann Arbor Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.3",
        "size": "small"
    },
    {
        "iata": "ARG",
        "lon": "-90.95",
        "iso": "US",
        "status": 1,
        "name": "Walnut Ridge Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.066666",
        "size": "small"
    },
    {
        "iata": "ART",
        "lon": "-76.021385",
        "iso": "US",
        "status": 1,
        "name": "Watertown International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.990833",
        "size": "medium"
    },
    {
        "iata": "ATL",
        "lon": "-84.44403",
        "iso": "US",
        "status": 1,
        "name": "Hartsfield Jackson Atlanta International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.640068",
        "size": "large"
    },
    {
        "iata": "ATW",
        "lon": "-88.50994",
        "iso": "US",
        "status": 1,
        "name": "Outagamie County Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.26011",
        "size": "small"
    },
    {
        "iata": "ATY",
        "lon": "-97.15417",
        "iso": "US",
        "status": 1,
        "name": "Watertown Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.90889",
        "size": "medium"
    },
    {
        "iata": "AUG",
        "lon": "-69.79667",
        "iso": "US",
        "status": 1,
        "name": "Augusta State Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.318054",
        "size": "medium"
    },
    {
        "iata": "AUS",
        "lon": "-97.66706",
        "iso": "US",
        "status": 1,
        "name": "Austin Bergstrom International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.202545",
        "size": "large"
    },
    {
        "iata": "AVL",
        "lon": "-82.537315",
        "iso": "US",
        "status": 1,
        "name": "Asheville Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.43508",
        "size": "large"
    },
    {
        "iata": "AVO",
        "lon": "-81.51667",
        "iso": "US",
        "status": 1,
        "name": "Avon Park Executive Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.6",
        "size": "small"
    },
    {
        "iata": "AVP",
        "lon": "-75.730644",
        "iso": "US",
        "status": 1,
        "name": "Wilkes Barre Scranton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.336697",
        "size": "medium"
    },
    {
        "iata": "AWM",
        "lon": "-90.183334",
        "iso": "US",
        "status": 1,
        "name": "West Memphis Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.13333",
        "size": "small"
    },
    {
        "iata": "AXN",
        "lon": "-95.394165",
        "iso": "US",
        "status": 1,
        "name": "Chandler Field",
        "continent": "NA",
        "type": "airport",
        "lat": "45.86722",
        "size": "medium"
    },
    {
        "iata": "AXS",
        "lon": "-99.33806",
        "iso": "US",
        "status": 1,
        "name": "Altus Quartz Mountain Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.696667",
        "size": "small"
    },
    {
        "iata": "AXV",
        "lon": "-84.2",
        "iso": "US",
        "status": 1,
        "name": "Neil Armstrong Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.566666",
        "size": "small"
    },
    {
        "iata": "AXX",
        "lon": "-105.28333",
        "iso": "US",
        "status": 1,
        "name": "Angel Fire Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.416668",
        "size": "small"
    },
    {
        "iata": "AYE",
        "iso": "US",
        "status": 0,
        "name": "Ft Devens Moore Army Air Field",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "AYS",
        "lon": "-82.39667",
        "iso": "US",
        "status": 1,
        "name": "Waycross Ware County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.248611",
        "size": "small"
    },
    {
        "iata": "AZO",
        "lon": "-85.55656",
        "iso": "US",
        "status": 1,
        "name": "Kalamazoo Battle Creek International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.239964",
        "size": "medium"
    },
    {
        "iata": "BAB",
        "lon": "-121.583336",
        "iso": "US",
        "status": 1,
        "name": "Beale Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "39.15",
        "size": "large"
    },
    {
        "iata": "BAD",
        "lon": "-93.666664",
        "iso": "US",
        "status": 1,
        "name": "Barksdale Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "32.5",
        "size": "large"
    },
    {
        "iata": "BAF",
        "lon": "-72.71562",
        "iso": "US",
        "status": 1,
        "name": "Barnes Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.157955",
        "size": "medium"
    },
    {
        "iata": "BDL",
        "lon": "-72.6847",
        "iso": "US",
        "status": 1,
        "name": "Bradley International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.92953",
        "size": "large"
    },
    {
        "iata": "BDR",
        "lon": "-73.12444",
        "iso": "US",
        "status": 1,
        "name": "Igor I Sikorsky Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.164165",
        "size": "medium"
    },
    {
        "iata": "BED",
        "lon": "-71.29",
        "iso": "US",
        "status": 1,
        "name": "Laurence G Hanscom Field",
        "continent": "NA",
        "type": "airport",
        "lat": "42.470833",
        "size": "medium"
    },
    {
        "iata": "BFD",
        "lon": "-78.63944",
        "iso": "US",
        "status": 1,
        "name": "Bradford Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.802223",
        "size": "medium"
    },
    {
        "iata": "BFF",
        "lon": "-103.60111",
        "iso": "US",
        "status": 1,
        "name": "Western Neb. Rgnl/William B. Heilig Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.87528",
        "size": "medium"
    },
    {
        "iata": "BFI",
        "lon": "-122.3037",
        "iso": "US",
        "status": 1,
        "name": "Boeing Field King County International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.5371",
        "size": "large"
    },
    {
        "iata": "BFL",
        "lon": "-119.04533",
        "iso": "US",
        "status": 1,
        "name": "Meadows Field",
        "continent": "NA",
        "type": "airport",
        "lat": "35.429626",
        "size": "medium"
    },
    {
        "iata": "BGM",
        "lon": "-75.98294",
        "iso": "US",
        "status": 1,
        "name": "Greater Binghamton/Edwin A Link field",
        "continent": "NA",
        "type": "airport",
        "lat": "42.208534",
        "size": "medium"
    },
    {
        "iata": "BGR",
        "lon": "-68.82102",
        "iso": "US",
        "status": 1,
        "name": "Bangor International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.812298",
        "size": "large"
    },
    {
        "iata": "BHB",
        "lon": "-68.361664",
        "iso": "US",
        "status": 1,
        "name": "Hancock County-Bar Harbor Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.44889",
        "size": "medium"
    },
    {
        "iata": "BHM",
        "lon": "-86.75219",
        "iso": "US",
        "status": 1,
        "name": "Birmingham-Shuttlesworth International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.560833",
        "size": "large"
    },
    {
        "iata": "BIL",
        "lon": "-108.53723",
        "iso": "US",
        "status": 1,
        "name": "Billings Logan International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.803417",
        "size": "large"
    },
    {
        "iata": "BIS",
        "lon": "-100.75821",
        "iso": "US",
        "status": 1,
        "name": "Bismarck Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.77448",
        "size": "medium"
    },
    {
        "iata": "BKL",
        "lon": "-81.683334",
        "iso": "US",
        "status": 1,
        "name": "Burke Lakefront Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.516945",
        "size": "medium"
    },
    {
        "iata": "BKW",
        "lon": "-81.12417",
        "iso": "US",
        "status": 1,
        "name": "Raleigh County Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.781944",
        "size": "medium"
    },
    {
        "iata": "BKX",
        "lon": "-96.81111",
        "iso": "US",
        "status": 1,
        "name": "Brookings Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.303333",
        "size": "small"
    },
    {
        "iata": "BLF",
        "lon": "-81.20805",
        "iso": "US",
        "status": 1,
        "name": "Mercer County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.295834",
        "size": "medium"
    },
    {
        "iata": "BLI",
        "lon": "-122.53267",
        "iso": "US",
        "status": 1,
        "name": "Bellingham International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.795734",
        "size": "medium"
    },
    {
        "iata": "BMG",
        "lon": "-86.61472",
        "iso": "US",
        "status": 1,
        "name": "Monroe County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.14",
        "size": "medium"
    },
    {
        "iata": "BMI",
        "lon": "-88.91483",
        "iso": "US",
        "status": 1,
        "name": "Central Illinois Regional Airport at Bloomington-Normal",
        "continent": "NA",
        "type": "airport",
        "lat": "40.48401",
        "size": "large"
    },
    {
        "iata": "BMT",
        "lon": "-94.1",
        "iso": "US",
        "status": 1,
        "name": "Beaumont Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.083332",
        "size": "small"
    },
    {
        "iata": "BNA",
        "lon": "-86.668945",
        "iso": "US",
        "status": 1,
        "name": "Nashville International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.13174",
        "size": "large"
    },
    {
        "iata": "BOI",
        "lon": "-116.22193",
        "iso": "US",
        "status": 1,
        "name": "Boise Air Terminal/Gowen field",
        "continent": "NA",
        "type": "airport",
        "lat": "43.569263",
        "size": "large"
    },
    {
        "iata": "BOS",
        "lon": "-71.02018",
        "iso": "US",
        "status": 1,
        "name": "General Edward Lawrence Logan International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.36646",
        "size": "large"
    },
    {
        "iata": "BPT",
        "lon": "-94.02",
        "iso": "US",
        "status": 1,
        "name": "Southeast Texas Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.950832",
        "size": "medium"
    },
    {
        "iata": "BQK",
        "lon": "-81.48333",
        "iso": "US",
        "status": 1,
        "name": "Brunswick Golden Isles Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.166668",
        "size": "medium"
    },
    {
        "iata": "BRL",
        "lon": "-91.12334",
        "iso": "US",
        "status": 1,
        "name": "Southeast Iowa Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.78611",
        "size": "medium"
    },
    {
        "iata": "BRO",
        "lon": "-97.42333",
        "iso": "US",
        "status": 1,
        "name": "Brownsville South Padre Island International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.90889",
        "size": "medium"
    },
    {
        "iata": "BTM",
        "lon": "-112.49389",
        "iso": "US",
        "status": 1,
        "name": "Bert Mooney Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.95111",
        "size": "medium"
    },
    {
        "iata": "BTR",
        "lon": "-91.156906",
        "iso": "US",
        "status": 1,
        "name": "Baton Rouge Metropolitan, Ryan Field",
        "continent": "NA",
        "type": "airport",
        "lat": "30.532537",
        "size": "large"
    },
    {
        "iata": "BTV",
        "lon": "-73.15527",
        "iso": "US",
        "status": 1,
        "name": "Burlington International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.469013",
        "size": "medium"
    },
    {
        "iata": "BTY",
        "lon": "-116.76667",
        "iso": "US",
        "status": 1,
        "name": "Beatty Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.9",
        "size": "small"
    },
    {
        "iata": "BUB",
        "lon": "-99.13333",
        "iso": "US",
        "status": 1,
        "name": "Cram Field",
        "continent": "NA",
        "type": "airport",
        "lat": "41.783333",
        "size": "small"
    },
    {
        "iata": "BUF",
        "lon": "-78.731804",
        "iso": "US",
        "status": 1,
        "name": "Buffalo Niagara International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.933826",
        "size": "large"
    },
    {
        "iata": "BUM",
        "lon": "-94.333336",
        "iso": "US",
        "status": 1,
        "name": "Butler Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.266666",
        "size": "small"
    },
    {
        "iata": "BUR",
        "lon": "-118.35411",
        "iso": "US",
        "status": 1,
        "name": "Bob Hope Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.19619",
        "size": "medium"
    },
    {
        "iata": "BVO",
        "lon": "-96.01083",
        "iso": "US",
        "status": 1,
        "name": "Bartlesville Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.7625",
        "size": "small"
    },
    {
        "iata": "BVX",
        "lon": "-91.65",
        "iso": "US",
        "status": 1,
        "name": "Batesville Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.766666",
        "size": "small"
    },
    {
        "iata": "BVY",
        "lon": "-70.88333",
        "iso": "US",
        "status": 1,
        "name": "Beverly Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.55",
        "size": "medium"
    },
    {
        "iata": "BWC",
        "lon": "-115.51688",
        "iso": "US",
        "status": 1,
        "name": "Brawley Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.992867",
        "size": "small"
    },
    {
        "iata": "BWD",
        "lon": "-98.956665",
        "iso": "US",
        "status": 1,
        "name": "Brownwood Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.794722",
        "size": "small"
    },
    {
        "iata": "BWG",
        "lon": "-86.42222",
        "iso": "US",
        "status": 1,
        "name": "Bowling Green Warren County Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.962223",
        "size": "medium"
    },
    {
        "iata": "BWI",
        "lon": "-76.66894",
        "iso": "US",
        "status": 1,
        "name": "Baltimore/Washington International Thurgood Marshal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.179527",
        "size": "large"
    },
    {
        "iata": "BXA",
        "lon": "-89.88333",
        "iso": "US",
        "status": 1,
        "name": "George R Carr Memorial Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "30.933332",
        "size": "small"
    },
    {
        "iata": "BXK",
        "lon": "-112.583336",
        "iso": "US",
        "status": 1,
        "name": "Buckeye Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.36667",
        "size": "small"
    },
    {
        "iata": "BYS",
        "lon": "-116.566666",
        "iso": "US",
        "status": 1,
        "name": "Bicycle Lake Army Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "35.266666",
        "size": "medium"
    },
    {
        "iata": "BZN",
        "lon": "-111.16033",
        "iso": "US",
        "status": 1,
        "name": "Gallatin Field",
        "continent": "NA",
        "type": "airport",
        "lat": "45.777687",
        "size": "medium"
    },
    {
        "iata": "CLG",
        "lon": "-120.35",
        "iso": "US",
        "status": 1,
        "name": "New Coalinga Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.15",
        "size": "small"
    },
    {
        "iata": "CAE",
        "lon": "-81.12501",
        "iso": "US",
        "status": 1,
        "name": "Columbia Metropolitan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.946907",
        "size": "large"
    },
    {
        "iata": "CAK",
        "lon": "-81.43583",
        "iso": "US",
        "status": 1,
        "name": "Akron Canton Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.914963",
        "size": "medium"
    },
    {
        "iata": "CDC",
        "lon": "-113.09611",
        "iso": "US",
        "status": 1,
        "name": "Cedar City Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.7025",
        "size": "medium"
    },
    {
        "iata": "CEC",
        "lon": "-124.23583",
        "iso": "US",
        "status": 1,
        "name": "Jack Mc Namara Field Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.78",
        "size": "medium"
    },
    {
        "iata": "CEF",
        "lon": "-72.55383",
        "iso": "US",
        "status": 1,
        "name": "Westover ARB/Metropolitan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.183376",
        "size": "medium"
    },
    {
        "iata": "CGI",
        "lon": "-89.57167",
        "iso": "US",
        "status": 1,
        "name": "Cape Girardeau Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.22361",
        "size": "medium"
    },
    {
        "iata": "CGX",
        "iso": "US",
        "status": 0,
        "name": "Chicago Meigs Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "CHA",
        "lon": "-85.197784",
        "iso": "US",
        "status": 1,
        "name": "Lovell Field",
        "continent": "NA",
        "type": "airport",
        "lat": "35.036926",
        "size": "large"
    },
    {
        "iata": "CHO",
        "lon": "-78.44928",
        "iso": "US",
        "status": 1,
        "name": "Charlottesville Albemarle Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.139416",
        "size": "medium"
    },
    {
        "iata": "CHS",
        "lon": "-80.037155",
        "iso": "US",
        "status": 1,
        "name": "Charleston Air Force Base-International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.884354",
        "size": "large"
    },
    {
        "iata": "CID",
        "lon": "-91.7003",
        "iso": "US",
        "status": 1,
        "name": "The Eastern Iowa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.889423",
        "size": "large"
    },
    {
        "iata": "CIU",
        "lon": "-84.35",
        "iso": "US",
        "status": 1,
        "name": "Chippewa County International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.5",
        "size": "medium"
    },
    {
        "iata": "CKB",
        "lon": "-80.22945",
        "iso": "US",
        "status": 1,
        "name": "North Central West Virginia Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.295555",
        "size": "medium"
    },
    {
        "iata": "KCL",
        "lon": "-158.53194",
        "iso": "US",
        "status": 1,
        "name": "Chignik Lagoon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.308334",
        "size": "small"
    },
    {
        "iata": "CLE",
        "lon": "-81.83821",
        "iso": "US",
        "status": 1,
        "name": "Cleveland Hopkins International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.410854",
        "size": "large"
    },
    {
        "iata": "CLL",
        "lon": "-96.36682",
        "iso": "US",
        "status": 1,
        "name": "Easterwood Field",
        "continent": "NA",
        "type": "airport",
        "lat": "30.593842",
        "size": "medium"
    },
    {
        "iata": "CLM",
        "lon": "-123.49511",
        "iso": "US",
        "status": 1,
        "name": "William R Fairchild International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.121883",
        "size": "medium"
    },
    {
        "iata": "CLT",
        "lon": "-80.93584",
        "iso": "US",
        "status": 1,
        "name": "Charlotte Douglas International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.219166",
        "size": "large"
    },
    {
        "iata": "CMH",
        "lon": "-82.884964",
        "iso": "US",
        "status": 1,
        "name": "Port Columbus International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.99818",
        "size": "large"
    },
    {
        "iata": "CMI",
        "lon": "-88.26905",
        "iso": "US",
        "status": 1,
        "name": "University of Illinois Willard Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.039665",
        "size": "medium"
    },
    {
        "iata": "CMX",
        "lon": "-88.48639",
        "iso": "US",
        "status": 1,
        "name": "Houghton County Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.167778",
        "size": "medium"
    },
    {
        "iata": "CNM",
        "lon": "-104.26278",
        "iso": "US",
        "status": 1,
        "name": "Cavern City Air Terminal",
        "continent": "NA",
        "type": "airport",
        "lat": "32.337223",
        "size": "medium"
    },
    {
        "iata": "COS",
        "lon": "-104.70056",
        "iso": "US",
        "status": 1,
        "name": "City of Colorado Springs Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.79713",
        "size": "large"
    },
    {
        "iata": "COU",
        "lon": "-92.21917",
        "iso": "US",
        "status": 1,
        "name": "Columbia Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.81361",
        "size": "medium"
    },
    {
        "iata": "CPR",
        "lon": "-106.4625",
        "iso": "US",
        "status": 1,
        "name": "Casper-Natrona County International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.90861",
        "size": "large"
    },
    {
        "iata": "CRO",
        "lon": "-119.55",
        "iso": "US",
        "status": 1,
        "name": "Corcoran Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.1",
        "size": "small"
    },
    {
        "iata": "CRP",
        "lon": "-97.50249",
        "iso": "US",
        "status": 1,
        "name": "Corpus Christi International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.774813",
        "size": "large"
    },
    {
        "iata": "CRW",
        "lon": "-81.596504",
        "iso": "US",
        "status": 1,
        "name": "Yeager Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.370342",
        "size": "large"
    },
    {
        "iata": "CSG",
        "lon": "-84.94111",
        "iso": "US",
        "status": 1,
        "name": "Columbus Metropolitan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.513332",
        "size": "medium"
    },
    {
        "iata": "CVG",
        "lon": "-84.66145",
        "iso": "US",
        "status": 1,
        "name": "Cincinnati Northern Kentucky International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.0555",
        "size": "large"
    },
    {
        "iata": "KIP",
        "lon": "-98.5",
        "iso": "US",
        "status": 1,
        "name": "Kickapoo Downtown Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.9",
        "size": "small"
    },
    {
        "iata": "CWF",
        "lon": "-93.23189",
        "iso": "US",
        "status": 1,
        "name": "Chennault International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.305918",
        "size": "small"
    },
    {
        "iata": "CYS",
        "lon": "-104.816666",
        "iso": "US",
        "status": 1,
        "name": "Cheyenne Regional Jerry Olson Field",
        "continent": "NA",
        "type": "airport",
        "lat": "41.155834",
        "size": "medium"
    },
    {
        "iata": "DAB",
        "lon": "-81.06083",
        "iso": "US",
        "status": 1,
        "name": "Daytona Beach International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.185192",
        "size": "medium"
    },
    {
        "iata": "DAL",
        "lon": "-96.85",
        "iso": "US",
        "status": 1,
        "name": "Dallas Love Field",
        "continent": "NA",
        "type": "airport",
        "lat": "32.84391",
        "size": "large"
    },
    {
        "iata": "DAN",
        "lon": "-79.335",
        "iso": "US",
        "status": 1,
        "name": "Danville Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.573055",
        "size": "medium"
    },
    {
        "iata": "DAY",
        "lon": "-84.220764",
        "iso": "US",
        "status": 1,
        "name": "James M Cox Dayton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.898006",
        "size": "large"
    },
    {
        "iata": "DBQ",
        "lon": "-90.71083",
        "iso": "US",
        "status": 1,
        "name": "Dubuque Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.41",
        "size": "large"
    },
    {
        "iata": "DCA",
        "lon": "-77.04346",
        "iso": "US",
        "status": 1,
        "name": "Ronald Reagan Washington National Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.853436",
        "size": "large"
    },
    {
        "iata": "DDC",
        "lon": "-99.965",
        "iso": "US",
        "status": 1,
        "name": "Dodge City Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.761665",
        "size": "medium"
    },
    {
        "iata": "DEC",
        "lon": "-88.868614",
        "iso": "US",
        "status": 1,
        "name": "Decatur Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.834167",
        "size": "medium"
    },
    {
        "iata": "DEN",
        "lon": "-104.672844",
        "iso": "US",
        "status": 1,
        "name": "Denver International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.84939",
        "size": "large"
    },
    {
        "iata": "DET",
        "lon": "-83.005104",
        "iso": "US",
        "status": 1,
        "name": "Coleman A. Young Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.40932",
        "size": "medium"
    },
    {
        "iata": "DFW",
        "lon": "-97.036125",
        "iso": "US",
        "status": 1,
        "name": "Dallas Fort Worth International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.89746",
        "size": "large"
    },
    {
        "iata": "DHN",
        "lon": "-85.44833",
        "iso": "US",
        "status": 1,
        "name": "Dothan Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.32",
        "size": "medium"
    },
    {
        "iata": "DLH",
        "lon": "-92.18019",
        "iso": "US",
        "status": 1,
        "name": "Duluth International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.838974",
        "size": "large"
    },
    {
        "iata": "DNL",
        "lon": "-82.0392",
        "iso": "US",
        "status": 1,
        "name": "Daniel Field",
        "continent": "NA",
        "type": "airport",
        "lat": "33.468098",
        "size": "medium"
    },
    {
        "iata": "DSM",
        "lon": "-93.64809",
        "iso": "US",
        "status": 1,
        "name": "Des Moines International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.532433",
        "size": "large"
    },
    {
        "iata": "DTW",
        "lon": "-83.35605",
        "iso": "US",
        "status": 1,
        "name": "Detroit Metropolitan Wayne County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.20781",
        "size": "large"
    },
    {
        "iata": "DUJ",
        "lon": "-78.89889",
        "iso": "US",
        "status": 1,
        "name": "DuBois Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.178333",
        "size": "medium"
    },
    {
        "iata": "JJM",
        "lon": "38.183334",
        "iso": "KE",
        "status": 1,
        "name": "Mulika Lodge Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.233194",
        "size": "small"
    },
    {
        "iata": "VPG",
        "lon": "39.805",
        "iso": "KE",
        "status": 1,
        "name": "Vipingo Estate Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-3.842778",
        "size": "small"
    },
    {
        "iata": "KRV",
        "lon": "36.11667",
        "iso": "KE",
        "status": 1,
        "name": "Kerio Valley Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "2.983333",
        "size": "small"
    },
    {
        "iata": "KIU",
        "lon": "41.516666",
        "iso": "KE",
        "status": 1,
        "name": "Kiunga Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.733333",
        "size": "small"
    },
    {
        "iata": "LBK",
        "lon": "40.966667",
        "iso": "KE",
        "status": 1,
        "name": "Liboi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.383333",
        "size": "small"
    },
    {
        "iata": "LBN",
        "lon": "36.083332",
        "iso": "KE",
        "status": 1,
        "name": "Lake Baringo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.666667",
        "size": "small"
    },
    {
        "iata": "LKU",
        "lon": "35.88333",
        "iso": "KE",
        "status": 1,
        "name": "Lake Rudolf Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.416667",
        "size": "small"
    },
    {
        "iata": "MRE",
        "lon": "35.05885",
        "iso": "KE",
        "status": 1,
        "name": "Mara Lodges Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-1.300368",
        "size": "small"
    },
    {
        "iata": "MUM",
        "lon": "34.483334",
        "iso": "KE",
        "status": 1,
        "name": "Mumias Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "0.333333",
        "size": "small"
    },
    {
        "iata": "ALE",
        "lon": "-103.75",
        "iso": "US",
        "status": 1,
        "name": "Alpine Casparis Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.416668",
        "size": "small"
    },
    {
        "iata": "BGT",
        "lon": "-113.1687",
        "iso": "US",
        "status": 1,
        "name": "Bagdad Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.59398",
        "size": "small"
    },
    {
        "iata": "EAR",
        "lon": "-98.998886",
        "iso": "US",
        "status": 1,
        "name": "Kearney Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.728333",
        "size": "small"
    },
    {
        "iata": "EAU",
        "lon": "-91.48722",
        "iso": "US",
        "status": 1,
        "name": "Chippewa Valley Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.864445",
        "size": "medium"
    },
    {
        "iata": "ECP",
        "lon": "-85.79892",
        "iso": "US",
        "status": 1,
        "name": "Northwest Florida Beaches International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.357803",
        "size": "small"
    },
    {
        "iata": "EEN",
        "lon": "-72.270836",
        "iso": "US",
        "status": 1,
        "name": "Dillant Hopkins Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.900833",
        "size": "medium"
    },
    {
        "iata": "EGE",
        "lon": "-106.91347",
        "iso": "US",
        "status": 1,
        "name": "Eagle County Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.63988",
        "size": "medium"
    },
    {
        "iata": "KEK",
        "lon": "-157.4775",
        "iso": "US",
        "status": 1,
        "name": "Ekwok Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.355278",
        "size": "small"
    },
    {
        "iata": "EKO",
        "lon": "-115.78972",
        "iso": "US",
        "status": 1,
        "name": "Elko Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.82389",
        "size": "medium"
    },
    {
        "iata": "ELD",
        "lon": "-92.81472",
        "iso": "US",
        "status": 1,
        "name": "South Arkansas Regional At Goodwin Field",
        "continent": "NA",
        "type": "airport",
        "lat": "33.220554",
        "size": "medium"
    },
    {
        "iata": "ELM",
        "lon": "-76.89586",
        "iso": "US",
        "status": 1,
        "name": "Elmira Corning Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.16304",
        "size": "medium"
    },
    {
        "iata": "ELP",
        "lon": "-106.396",
        "iso": "US",
        "status": 1,
        "name": "El Paso International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.798948",
        "size": "medium"
    },
    {
        "iata": "ERI",
        "lon": "-80.18203",
        "iso": "US",
        "status": 1,
        "name": "Erie International Tom Ridge Field",
        "continent": "NA",
        "type": "airport",
        "lat": "42.08314",
        "size": "large"
    },
    {
        "iata": "EUG",
        "lon": "-123.21197",
        "iso": "US",
        "status": 1,
        "name": "Mahlon Sweet Field",
        "continent": "NA",
        "type": "airport",
        "lat": "44.119198",
        "size": "medium"
    },
    {
        "iata": "EVV",
        "lon": "-87.52796",
        "iso": "US",
        "status": 1,
        "name": "Evansville Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.046165",
        "size": "medium"
    },
    {
        "iata": "EWB",
        "lon": "-70.95917",
        "iso": "US",
        "status": 1,
        "name": "New Bedford Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.676945",
        "size": "medium"
    },
    {
        "iata": "EWN",
        "lon": "-77.03462",
        "iso": "US",
        "status": 1,
        "name": "Coastal Carolina Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.078342",
        "size": "medium"
    },
    {
        "iata": "EWR",
        "lon": "-74.17876",
        "iso": "US",
        "status": 1,
        "name": "Newark Liberty International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.68907",
        "size": "large"
    },
    {
        "iata": "EYW",
        "lon": "-81.75501",
        "iso": "US",
        "status": 1,
        "name": "Key West International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.553574",
        "size": "medium"
    },
    {
        "iata": "FAR",
        "lon": "-96.82551",
        "iso": "US",
        "status": 1,
        "name": "Hector International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.91953",
        "size": "medium"
    },
    {
        "iata": "FAT",
        "lon": "-119.72024",
        "iso": "US",
        "status": 1,
        "name": "Fresno Yosemite International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.769623",
        "size": "medium"
    },
    {
        "iata": "FAY",
        "lon": "-78.88722",
        "iso": "US",
        "status": 1,
        "name": "Fayetteville Regional Grannis Field",
        "continent": "NA",
        "type": "airport",
        "lat": "34.99132",
        "size": "medium"
    },
    {
        "iata": "FBG",
        "lon": "-78.936386",
        "iso": "US",
        "status": 1,
        "name": "Simmons Army Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "35.131943",
        "size": "medium"
    },
    {
        "iata": "FCS",
        "lon": "-104.816666",
        "iso": "US",
        "status": 1,
        "name": "Butts AAF (Fort Carson) Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "38.833332",
        "size": "medium"
    },
    {
        "iata": "KFE",
        "lon": "119.437225",
        "iso": "AU",
        "status": 1,
        "name": "Fortescue - Dave Forrest Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.291945",
        "size": "small"
    },
    {
        "iata": "FRD",
        "lon": "-123.0265",
        "iso": "US",
        "status": 1,
        "name": "Friday Harbor Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.524807",
        "size": "small"
    },
    {
        "iata": "FHU",
        "lon": "-110.343056",
        "iso": "US",
        "status": 1,
        "name": "Sierra Vista Municipal Libby Army Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "31.588888",
        "size": "medium"
    },
    {
        "iata": "FLG",
        "lon": "-111.67167",
        "iso": "US",
        "status": 1,
        "name": "Flagstaff Pulliam Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.13778",
        "size": "medium"
    },
    {
        "iata": "FLL",
        "lon": "-80.144905",
        "iso": "US",
        "status": 1,
        "name": "Fort Lauderdale Hollywood International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.071491",
        "size": "large"
    },
    {
        "iata": "FLO",
        "lon": "-79.72444",
        "iso": "US",
        "status": 1,
        "name": "Florence Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.18889",
        "size": "medium"
    },
    {
        "iata": "FLU",
        "iso": "US",
        "status": 0,
        "name": "Flushing Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "FNT",
        "lon": "-83.73891",
        "iso": "US",
        "status": 1,
        "name": "Bishop International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.973812",
        "size": "medium"
    },
    {
        "iata": "FOD",
        "lon": "-94.18972",
        "iso": "US",
        "status": 1,
        "name": "Fort Dodge Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.5525",
        "size": "medium"
    },
    {
        "iata": "FOE",
        "lon": "-95.6625",
        "iso": "US",
        "status": 1,
        "name": "Forbes Field",
        "continent": "NA",
        "type": "airport",
        "lat": "38.9525",
        "size": "medium"
    },
    {
        "iata": "FIL",
        "lon": "-112.3625",
        "iso": "US",
        "status": 1,
        "name": "Fillmore Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.958332",
        "size": "small"
    },
    {
        "iata": "FPR",
        "lon": "-80.333336",
        "iso": "US",
        "status": 1,
        "name": "St Lucie County International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.45",
        "size": "medium"
    },
    {
        "iata": "FRH",
        "lon": "-86.61667",
        "iso": "US",
        "status": 1,
        "name": "French Lick Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.55",
        "size": "small"
    },
    {
        "iata": "FRI",
        "lon": "-96.78333",
        "iso": "US",
        "status": 1,
        "name": "Marshall Army Air Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.083332",
        "size": "medium"
    },
    {
        "iata": "FRM",
        "lon": "-94.423615",
        "iso": "US",
        "status": 1,
        "name": "Fairmont Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.64722",
        "size": "small"
    },
    {
        "iata": "FSD",
        "lon": "-96.73115",
        "iso": "US",
        "status": 1,
        "name": "Joe Foss Field Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.580296",
        "size": "medium"
    },
    {
        "iata": "FSM",
        "lon": "-94.3589",
        "iso": "US",
        "status": 1,
        "name": "Fort Smith Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.34124",
        "size": "large"
    },
    {
        "iata": "FMS",
        "lon": "-91.3275",
        "iso": "US",
        "status": 1,
        "name": "Fort Madison Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.65889",
        "size": "small"
    },
    {
        "iata": "FTW",
        "lon": "-97.333336",
        "iso": "US",
        "status": 1,
        "name": "Fort Worth Meacham International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.75",
        "size": "large"
    },
    {
        "iata": "FWA",
        "lon": "-85.18771",
        "iso": "US",
        "status": 1,
        "name": "Fort Wayne International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.98666",
        "size": "large"
    },
    {
        "iata": "FYV",
        "lon": "-94.17",
        "iso": "US",
        "status": 1,
        "name": "Drake Field",
        "continent": "NA",
        "type": "airport",
        "lat": "36.003613",
        "size": "medium"
    },
    {
        "iata": "GCK",
        "lon": "-100.72972",
        "iso": "US",
        "status": 1,
        "name": "Garden City Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.92861",
        "size": "medium"
    },
    {
        "iata": "GCN",
        "lon": "-112.1475",
        "iso": "US",
        "status": 1,
        "name": "Grand Canyon National Park Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.951942",
        "size": "medium"
    },
    {
        "iata": "GEG",
        "lon": "-117.537636",
        "iso": "US",
        "status": 1,
        "name": "Spokane International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.62515",
        "size": "large"
    },
    {
        "iata": "GFK",
        "lon": "-97.17587",
        "iso": "US",
        "status": 1,
        "name": "Grand Forks International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.9488",
        "size": "medium"
    },
    {
        "iata": "GGG",
        "lon": "-94.71528",
        "iso": "US",
        "status": 1,
        "name": "East Texas Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.386665",
        "size": "medium"
    },
    {
        "iata": "GGW",
        "lon": "-106.615555",
        "iso": "US",
        "status": 1,
        "name": "Wokal Field Glasgow International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.212776",
        "size": "medium"
    },
    {
        "iata": "GJT",
        "lon": "-108.528336",
        "iso": "US",
        "status": 1,
        "name": "Grand Junction Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.122665",
        "size": "medium"
    },
    {
        "iata": "GLH",
        "lon": "-90.985",
        "iso": "US",
        "status": 1,
        "name": "Mid Delta Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.484444",
        "size": "medium"
    },
    {
        "iata": "GLS",
        "lon": "-94.860275",
        "iso": "US",
        "status": 1,
        "name": "Scholes International At Galveston Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.2675",
        "size": "medium"
    },
    {
        "iata": "GNV",
        "lon": "-82.276794",
        "iso": "US",
        "status": 1,
        "name": "Gainesville Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.686142",
        "size": "medium"
    },
    {
        "iata": "GON",
        "lon": "-72.04639",
        "iso": "US",
        "status": 1,
        "name": "Groton New London Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.328888",
        "size": "medium"
    },
    {
        "iata": "FCA",
        "lon": "-114.25315",
        "iso": "US",
        "status": 1,
        "name": "Glacier Park International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.307304",
        "size": "medium"
    },
    {
        "iata": "GPT",
        "lon": "-89.07203",
        "iso": "US",
        "status": 1,
        "name": "Gulfport Biloxi International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.413284",
        "size": "large"
    },
    {
        "iata": "GRB",
        "lon": "-88.121895",
        "iso": "US",
        "status": 1,
        "name": "Austin Straubel International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.492847",
        "size": "large"
    },
    {
        "iata": "GRI",
        "lon": "-98.30666",
        "iso": "US",
        "status": 1,
        "name": "Central Nebraska Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.96722",
        "size": "medium"
    },
    {
        "iata": "GRK",
        "lon": "-97.820915",
        "iso": "US",
        "status": 1,
        "name": "Robert Gray  Army Air Field Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.061821",
        "size": "medium"
    },
    {
        "iata": "GRR",
        "lon": "-85.52974",
        "iso": "US",
        "status": 1,
        "name": "Gerald R. Ford International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.88501",
        "size": "medium"
    },
    {
        "iata": "GSO",
        "lon": "-79.9373",
        "iso": "US",
        "status": 1,
        "name": "Piedmont Triad International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.105324",
        "size": "large"
    },
    {
        "iata": "GSP",
        "lon": "-82.21706",
        "iso": "US",
        "status": 1,
        "name": "Greenville Spartanburg International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.890568",
        "size": "large"
    },
    {
        "iata": "GTF",
        "lon": "-111.35636",
        "iso": "US",
        "status": 1,
        "name": "Great Falls International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.481888",
        "size": "medium"
    },
    {
        "iata": "GUP",
        "lon": "-108.78667",
        "iso": "US",
        "status": 1,
        "name": "Gallup Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.511112",
        "size": "medium"
    },
    {
        "iata": "GVT",
        "lon": "-96.06167",
        "iso": "US",
        "status": 1,
        "name": "Majors Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.066666",
        "size": "small"
    },
    {
        "iata": "GVW",
        "iso": "US",
        "status": 0,
        "name": "Richards-Gebaur Air Force Base",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "GYY",
        "lon": "-87.416664",
        "iso": "US",
        "status": 1,
        "name": "Gary Chicago International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.61667",
        "size": "medium"
    },
    {
        "iata": "HGR",
        "lon": "-77.73",
        "iso": "US",
        "status": 1,
        "name": "Hagerstown Regional Richard A Henson Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.70778",
        "size": "medium"
    },
    {
        "iata": "HIB",
        "lon": "-92.83861",
        "iso": "US",
        "status": 1,
        "name": "Chisholm Hibbing Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.38861",
        "size": "large"
    },
    {
        "iata": "HKY",
        "lon": "-81.39167",
        "iso": "US",
        "status": 1,
        "name": "Hickory Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.73861",
        "size": "medium"
    },
    {
        "iata": "HLN",
        "lon": "-111.99018",
        "iso": "US",
        "status": 1,
        "name": "Helena Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.61052",
        "size": "medium"
    },
    {
        "iata": "HON",
        "lon": "-98.22639",
        "iso": "US",
        "status": 1,
        "name": "Huron Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.38361",
        "size": "medium"
    },
    {
        "iata": "HOT",
        "lon": "-93.09611",
        "iso": "US",
        "status": 1,
        "name": "Memorial Field",
        "continent": "NA",
        "type": "airport",
        "lat": "34.47889",
        "size": "medium"
    },
    {
        "iata": "HOU",
        "lon": "-95.277016",
        "iso": "US",
        "status": 1,
        "name": "William P Hobby Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.654512",
        "size": "large"
    },
    {
        "iata": "HPN",
        "lon": "-73.70389",
        "iso": "US",
        "status": 1,
        "name": "Westchester County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.068672",
        "size": "medium"
    },
    {
        "iata": "HRL",
        "lon": "-97.662254",
        "iso": "US",
        "status": 1,
        "name": "Valley International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.223482",
        "size": "medium"
    },
    {
        "iata": "HRO",
        "lon": "-93.155556",
        "iso": "US",
        "status": 1,
        "name": "Boone County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.261665",
        "size": "medium"
    },
    {
        "iata": "HNC",
        "lon": "-75.7",
        "iso": "US",
        "status": 1,
        "name": "Billy Mitchell Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.216667",
        "size": "small"
    },
    {
        "iata": "HSV",
        "lon": "-86.77484",
        "iso": "US",
        "status": 1,
        "name": "Huntsville International Carl T Jones Field",
        "continent": "NA",
        "type": "airport",
        "lat": "34.64857",
        "size": "large"
    },
    {
        "iata": "HTS",
        "lon": "-82.556114",
        "iso": "US",
        "status": 1,
        "name": "Tri-State/Milton J. Ferguson Field",
        "continent": "NA",
        "type": "airport",
        "lat": "38.366943",
        "size": "large"
    },
    {
        "iata": "HUF",
        "lon": "-87.3075",
        "iso": "US",
        "status": 1,
        "name": "Terre Haute International Hulman Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.454445",
        "size": "medium"
    },
    {
        "iata": "HVN",
        "lon": "-72.888336",
        "iso": "US",
        "status": 1,
        "name": "Tweed New Haven Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.265278",
        "size": "medium"
    },
    {
        "iata": "HYA",
        "lon": "-70.280556",
        "iso": "US",
        "status": 1,
        "name": "Barnstable Municipal Boardman Polando Field",
        "continent": "NA",
        "type": "airport",
        "lat": "41.665833",
        "size": "medium"
    },
    {
        "iata": "IAB",
        "lon": "-97.27617",
        "iso": "US",
        "status": 1,
        "name": "Mc Connell Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "37.68114",
        "size": "medium"
    },
    {
        "iata": "IAD",
        "lon": "-77.44774",
        "iso": "US",
        "status": 1,
        "name": "Washington Dulles International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.95315",
        "size": "large"
    },
    {
        "iata": "IAG",
        "lon": "-78.95",
        "iso": "US",
        "status": 1,
        "name": "Niagara Falls International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.1",
        "size": "medium"
    },
    {
        "iata": "IAH",
        "lon": "-95.34",
        "iso": "US",
        "status": 1,
        "name": "George Bush Intercontinental Houston Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.983334",
        "size": "large"
    },
    {
        "iata": "ICT",
        "lon": "-97.428955",
        "iso": "US",
        "status": 1,
        "name": "Wichita Mid Continent Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.653046",
        "size": "large"
    },
    {
        "iata": "IDA",
        "lon": "-112.06751",
        "iso": "US",
        "status": 1,
        "name": "Idaho Falls Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.514854",
        "size": "medium"
    },
    {
        "iata": "IFP",
        "lon": "-114.55695",
        "iso": "US",
        "status": 1,
        "name": "Laughlin Bullhead International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.165634",
        "size": "small"
    },
    {
        "iata": "IGM",
        "lon": "-113.94",
        "iso": "US",
        "status": 1,
        "name": "Kingman Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.256668",
        "size": "small"
    },
    {
        "iata": "ILG",
        "lon": "-75.6075",
        "iso": "US",
        "status": 1,
        "name": "New Castle Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.678333",
        "size": "medium"
    },
    {
        "iata": "ILM",
        "lon": "-77.91063",
        "iso": "US",
        "status": 1,
        "name": "Wilmington International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.26705",
        "size": "medium"
    },
    {
        "iata": "IND",
        "lon": "-86.29805",
        "iso": "US",
        "status": 1,
        "name": "Indianapolis International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.714516",
        "size": "large"
    },
    {
        "iata": "INL",
        "lon": "-93.40417",
        "iso": "US",
        "status": 1,
        "name": "Falls International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.563057",
        "size": "medium"
    },
    {
        "iata": "INT",
        "lon": "-80.225555",
        "iso": "US",
        "status": 1,
        "name": "Smith Reynolds Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.135277",
        "size": "medium"
    },
    {
        "iata": "IPT",
        "lon": "-76.920555",
        "iso": "US",
        "status": 1,
        "name": "Williamsport Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.24222",
        "size": "medium"
    },
    {
        "iata": "IRK",
        "lon": "-92.54389",
        "iso": "US",
        "status": 1,
        "name": "Kirksville Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.0925",
        "size": "medium"
    },
    {
        "iata": "ISP",
        "lon": "-73.09755",
        "iso": "US",
        "status": 1,
        "name": "Long Island Mac Arthur Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.789314",
        "size": "medium"
    },
    {
        "iata": "ITH",
        "lon": "-76.46359",
        "iso": "US",
        "status": 1,
        "name": "Ithaca Tompkins Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.49034",
        "size": "medium"
    },
    {
        "iata": "AZA",
        "lon": "-111.655556",
        "iso": "US",
        "status": 1,
        "name": "Phoenix-Mesa-Gateway Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.307777",
        "size": "medium"
    },
    {
        "iata": "JAC",
        "lon": "-110.73613",
        "iso": "US",
        "status": 1,
        "name": "Jackson Hole Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.602806",
        "size": "medium"
    },
    {
        "iata": "JAN",
        "lon": "-90.07496",
        "iso": "US",
        "status": 1,
        "name": "Jackson Evers International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.309895",
        "size": "large"
    },
    {
        "iata": "JAX",
        "lon": "-81.68306",
        "iso": "US",
        "status": 1,
        "name": "Jacksonville International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.491657",
        "size": "large"
    },
    {
        "iata": "JBR",
        "lon": "-90.64833",
        "iso": "US",
        "status": 1,
        "name": "Jonesboro Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.83",
        "size": "medium"
    },
    {
        "iata": "JFK",
        "lon": "-73.78817",
        "iso": "US",
        "status": 1,
        "name": "John F Kennedy International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.642334",
        "size": "large"
    },
    {
        "iata": "JLN",
        "lon": "-94.49778",
        "iso": "US",
        "status": 1,
        "name": "Joplin Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.149723",
        "size": "large"
    },
    {
        "iata": "JMS",
        "lon": "-98.67833",
        "iso": "US",
        "status": 1,
        "name": "Jamestown Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.93",
        "size": "medium"
    },
    {
        "iata": "JST",
        "lon": "-78.83444",
        "iso": "US",
        "status": 1,
        "name": "John Murtha Johnstown Cambria County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.316666",
        "size": "medium"
    },
    {
        "iata": "LAF",
        "lon": "-86.93361",
        "iso": "US",
        "status": 1,
        "name": "Purdue University Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.411945",
        "size": "medium"
    },
    {
        "iata": "LAN",
        "lon": "-84.58958",
        "iso": "US",
        "status": 1,
        "name": "Capital City Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.774185",
        "size": "medium"
    },
    {
        "iata": "LAR",
        "lon": "-105.67306",
        "iso": "US",
        "status": 1,
        "name": "Laramie Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.31361",
        "size": "medium"
    },
    {
        "iata": "LAS",
        "lon": "-115.1486",
        "iso": "US",
        "status": 1,
        "name": "McCarran International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.086945",
        "size": "large"
    },
    {
        "iata": "LAX",
        "lon": "-118.40828",
        "iso": "US",
        "status": 1,
        "name": "Los Angeles International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.943398",
        "size": "large"
    },
    {
        "iata": "LBB",
        "lon": "-101.8223",
        "iso": "US",
        "status": 1,
        "name": "Lubbock Preston Smith International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.65622",
        "size": "large"
    },
    {
        "iata": "LBE",
        "lon": "-79.40855",
        "iso": "US",
        "status": 1,
        "name": "Arnold Palmer Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.272926",
        "size": "medium"
    },
    {
        "iata": "LBF",
        "lon": "-100.69667",
        "iso": "US",
        "status": 1,
        "name": "North Platte Regional Airport Lee Bird Field",
        "continent": "NA",
        "type": "airport",
        "lat": "41.128334",
        "size": "medium"
    },
    {
        "iata": "LBL",
        "lon": "-100.960556",
        "iso": "US",
        "status": 1,
        "name": "Liberal Mid-America Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.044445",
        "size": "medium"
    },
    {
        "iata": "LCH",
        "lon": "-93.225555",
        "iso": "US",
        "status": 1,
        "name": "Lake Charles Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.131111",
        "size": "medium"
    },
    {
        "iata": "LEB",
        "lon": "-72.310005",
        "iso": "US",
        "status": 1,
        "name": "Lebanon Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.62936",
        "size": "medium"
    },
    {
        "iata": "LEX",
        "lon": "-84.59792",
        "iso": "US",
        "status": 1,
        "name": "Blue Grass Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.03762",
        "size": "large"
    },
    {
        "iata": "LFT",
        "lon": "-91.99327",
        "iso": "US",
        "status": 1,
        "name": "Lafayette Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.20851",
        "size": "large"
    },
    {
        "iata": "LGA",
        "lon": "-73.87162",
        "iso": "US",
        "status": 1,
        "name": "La Guardia Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.774254",
        "size": "large"
    },
    {
        "iata": "LGB",
        "lon": "-118.14449",
        "iso": "US",
        "status": 1,
        "name": "Long Beach /Daugherty Field/ Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.818195",
        "size": "medium"
    },
    {
        "iata": "LHC",
        "iso": "US",
        "status": 0,
        "name": "Arlington Municipal Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "LIT",
        "lon": "-92.221375",
        "iso": "US",
        "status": 1,
        "name": "Adams Field",
        "continent": "NA",
        "type": "airport",
        "lat": "34.727432",
        "size": "large"
    },
    {
        "iata": "LMT",
        "lon": "-121.73583",
        "iso": "US",
        "status": 1,
        "name": "Klamath Falls Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.163055",
        "size": "medium"
    },
    {
        "iata": "LNK",
        "lon": "-96.75471",
        "iso": "US",
        "status": 1,
        "name": "Lincoln Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.846176",
        "size": "medium"
    },
    {
        "iata": "LNS",
        "lon": "-76.29972",
        "iso": "US",
        "status": 1,
        "name": "Lancaster Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.121113",
        "size": "medium"
    },
    {
        "iata": "LRD",
        "lon": "-99.455124",
        "iso": "US",
        "status": 1,
        "name": "Laredo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.543667",
        "size": "medium"
    },
    {
        "iata": "LRU",
        "lon": "-106.92167",
        "iso": "US",
        "status": 1,
        "name": "Las Cruces International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.290833",
        "size": "medium"
    },
    {
        "iata": "LSE",
        "lon": "-91.264336",
        "iso": "US",
        "status": 1,
        "name": "La Crosse Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.875244",
        "size": "medium"
    },
    {
        "iata": "LUK",
        "lon": "-84.666664",
        "iso": "US",
        "status": 1,
        "name": "Cincinnati Municipal Airport Lunken Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.033333",
        "size": "medium"
    },
    {
        "iata": "LWS",
        "lon": "-117.01444",
        "iso": "US",
        "status": 1,
        "name": "Lewiston Nez Perce County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.37472",
        "size": "medium"
    },
    {
        "iata": "LWT",
        "lon": "-109.47111",
        "iso": "US",
        "status": 1,
        "name": "Lewistown Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.05111",
        "size": "medium"
    },
    {
        "iata": "LYH",
        "lon": "-79.20111",
        "iso": "US",
        "status": 1,
        "name": "Lynchburg Regional Preston Glenn Field",
        "continent": "NA",
        "type": "airport",
        "lat": "37.326942",
        "size": "medium"
    },
    {
        "iata": "MAF",
        "lon": "-102.20817",
        "iso": "US",
        "status": 1,
        "name": "Midland International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.937145",
        "size": "medium"
    },
    {
        "iata": "MBS",
        "lon": "-84.090744",
        "iso": "US",
        "status": 1,
        "name": "MBS International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.53339",
        "size": "large"
    },
    {
        "iata": "MCE",
        "lon": "-120.51278",
        "iso": "US",
        "status": 1,
        "name": "Merced Regional Macready Field",
        "continent": "NA",
        "type": "airport",
        "lat": "37.28472",
        "size": "medium"
    },
    {
        "iata": "MCI",
        "lon": "-94.719925",
        "iso": "US",
        "status": 1,
        "name": "Kansas City International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.293808",
        "size": "large"
    },
    {
        "iata": "MCN",
        "lon": "-83.64865",
        "iso": "US",
        "status": 1,
        "name": "Middle Georgia Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.70199",
        "size": "medium"
    },
    {
        "iata": "MCO",
        "lon": "-81.308304",
        "iso": "US",
        "status": 1,
        "name": "Orlando International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.432177",
        "size": "large"
    },
    {
        "iata": "MDH",
        "lon": "-89.24694",
        "iso": "US",
        "status": 1,
        "name": "Southern Illinois Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.781387",
        "size": "medium"
    },
    {
        "iata": "MDT",
        "lon": "-76.75606",
        "iso": "US",
        "status": 1,
        "name": "Harrisburg International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.196007",
        "size": "medium"
    },
    {
        "iata": "MDW",
        "lon": "-87.74087",
        "iso": "US",
        "status": 1,
        "name": "Chicago Midway International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.788136",
        "size": "large"
    },
    {
        "iata": "MEI",
        "lon": "-88.74917",
        "iso": "US",
        "status": 1,
        "name": "Key Field",
        "continent": "NA",
        "type": "airport",
        "lat": "32.337223",
        "size": "medium"
    },
    {
        "iata": "MEM",
        "lon": "-89.98226",
        "iso": "US",
        "status": 1,
        "name": "Memphis International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.04458",
        "size": "large"
    },
    {
        "iata": "MFE",
        "lon": "-98.23978",
        "iso": "US",
        "status": 1,
        "name": "Mc Allen Miller International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.181295",
        "size": "medium"
    },
    {
        "iata": "MFR",
        "lon": "-122.87312",
        "iso": "US",
        "status": 1,
        "name": "Rogue Valley International Medford Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.369026",
        "size": "medium"
    },
    {
        "iata": "MGM",
        "lon": "-86.39029",
        "iso": "US",
        "status": 1,
        "name": "Montgomery Regional (Dannelly Field) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.305042",
        "size": "large"
    },
    {
        "iata": "MGW",
        "lon": "-79.91583",
        "iso": "US",
        "status": 1,
        "name": "Morgantown Municipal Walter L. Bill Hart Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.642776",
        "size": "medium"
    },
    {
        "iata": "MHK",
        "lon": "-96.66889",
        "iso": "US",
        "status": 1,
        "name": "Manhattan Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.142223",
        "size": "medium"
    },
    {
        "iata": "MHT",
        "lon": "-71.43844",
        "iso": "US",
        "status": 1,
        "name": "Manchester Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.92786",
        "size": "large"
    },
    {
        "iata": "MIA",
        "lon": "-80.27824",
        "iso": "US",
        "status": 1,
        "name": "Miami International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.796",
        "size": "large"
    },
    {
        "iata": "MIE",
        "lon": "-85.39445",
        "iso": "US",
        "status": 1,
        "name": "Delaware County Johnson Field",
        "continent": "NA",
        "type": "airport",
        "lat": "40.239723",
        "size": "medium"
    },
    {
        "iata": "MKC",
        "lon": "-94.59194",
        "iso": "US",
        "status": 1,
        "name": "Charles B. Wheeler Downtown Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.12417",
        "size": "medium"
    },
    {
        "iata": "MKE",
        "lon": "-87.90267",
        "iso": "US",
        "status": 1,
        "name": "General Mitchell International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.948093",
        "size": "large"
    },
    {
        "iata": "MKG",
        "lon": "-86.2375",
        "iso": "US",
        "status": 1,
        "name": "Muskegon County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.170555",
        "size": "small"
    },
    {
        "iata": "MKL",
        "lon": "-88.915276",
        "iso": "US",
        "status": 1,
        "name": "Mc Kellar Sipes Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.60139",
        "size": "medium"
    },
    {
        "iata": "MLB",
        "lon": "-80.63028",
        "iso": "US",
        "status": 1,
        "name": "Melbourne International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.1025",
        "size": "medium"
    },
    {
        "iata": "MLI",
        "lon": "-90.50611",
        "iso": "US",
        "status": 1,
        "name": "Quad City International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.453896",
        "size": "large"
    },
    {
        "iata": "MLS",
        "lon": "-105.880554",
        "iso": "US",
        "status": 1,
        "name": "Frank Wiley Field",
        "continent": "NA",
        "type": "airport",
        "lat": "46.430557",
        "size": "medium"
    },
    {
        "iata": "MLU",
        "lon": "-92.043655",
        "iso": "US",
        "status": 1,
        "name": "Monroe Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.51184",
        "size": "large"
    },
    {
        "iata": "KMM",
        "lon": "136.16667",
        "iso": "ID",
        "status": 1,
        "name": "Kimaam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.666667",
        "size": "small"
    },
    {
        "iata": "MMU",
        "lon": "-74.41583",
        "iso": "US",
        "status": 1,
        "name": "Morristown Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.799168",
        "size": "medium"
    },
    {
        "iata": "MOB",
        "lon": "-88.24475",
        "iso": "US",
        "status": 1,
        "name": "Mobile Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.681086",
        "size": "large"
    },
    {
        "iata": "MOD",
        "lon": "-120.95528",
        "iso": "US",
        "status": 1,
        "name": "Modesto City Co-Harry Sham Field",
        "continent": "NA",
        "type": "airport",
        "lat": "37.626945",
        "size": "medium"
    },
    {
        "iata": "MOT",
        "lon": "-101.28083",
        "iso": "US",
        "status": 1,
        "name": "Minot International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.259724",
        "size": "medium"
    },
    {
        "iata": "MRY",
        "lon": "-121.850815",
        "iso": "US",
        "status": 1,
        "name": "Monterey Peninsula Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.587296",
        "size": "medium"
    },
    {
        "iata": "MSL",
        "lon": "-87.61611",
        "iso": "US",
        "status": 1,
        "name": "Northwest Alabama Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.74861",
        "size": "medium"
    },
    {
        "iata": "MSN",
        "lon": "-89.3465",
        "iso": "US",
        "status": 1,
        "name": "Dane County Regional Truax Field",
        "continent": "NA",
        "type": "airport",
        "lat": "43.136375",
        "size": "large"
    },
    {
        "iata": "MSO",
        "lon": "-114.08321",
        "iso": "US",
        "status": 1,
        "name": "Missoula International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.918964",
        "size": "medium"
    },
    {
        "iata": "MSP",
        "lon": "-93.21092",
        "iso": "US",
        "status": 1,
        "name": "Minneapolis-St Paul International/Wold-Chamberlain Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.883015",
        "size": "large"
    },
    {
        "iata": "MSS",
        "lon": "-74.846664",
        "iso": "US",
        "status": 1,
        "name": "Massena International Richards Field",
        "continent": "NA",
        "type": "airport",
        "lat": "44.93778",
        "size": "medium"
    },
    {
        "iata": "MSY",
        "lon": "-90.25639",
        "iso": "US",
        "status": 1,
        "name": "Louis Armstrong New Orleans International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.984564",
        "size": "large"
    },
    {
        "iata": "MVY",
        "lon": "-70.61182",
        "iso": "US",
        "status": 1,
        "name": "Martha's Vineyard Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.389336",
        "size": "small"
    },
    {
        "iata": "MYR",
        "lon": "-78.92294",
        "iso": "US",
        "status": 1,
        "name": "Myrtle Beach International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.682674",
        "size": "large"
    },
    {
        "iata": "NEL",
        "lon": "-74.316666",
        "iso": "US",
        "status": 1,
        "name": "Lakehurst NAES /Maxfield Field",
        "continent": "NA",
        "type": "airport",
        "lat": "40.016666",
        "size": "medium"
    },
    {
        "iata": "NGZ",
        "iso": "US",
        "status": 0,
        "name": "Alameda Naval Air Station",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "NOP",
        "iso": "US",
        "status": 0,
        "name": "Floyd Bennett Field",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "YUM",
        "lon": "-114.599266",
        "iso": "US",
        "status": 1,
        "name": "Yuma MCAS/Yuma International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.668606",
        "size": "medium"
    },
    {
        "iata": "NVN",
        "iso": "US",
        "status": 1,
        "name": "Nervino Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ODC",
        "iso": "US",
        "status": 1,
        "name": "Oakdale Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OAK",
        "lon": "-122.21201",
        "iso": "US",
        "status": 1,
        "name": "Metropolitan Oakland International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.71188",
        "size": "large"
    },
    {
        "iata": "OAR",
        "lon": "-121.916664",
        "iso": "US",
        "status": 1,
        "name": "Marina Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.61667",
        "size": "small"
    },
    {
        "iata": "OKC",
        "lon": "-97.59609",
        "iso": "US",
        "status": 1,
        "name": "Will Rogers World Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.39563",
        "size": "large"
    },
    {
        "iata": "OLD",
        "lon": "-68.65",
        "iso": "US",
        "status": 1,
        "name": "Dewitt Field,Old Town Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.933334",
        "size": "small"
    },
    {
        "iata": "OLM",
        "lon": "-122.903336",
        "iso": "US",
        "status": 1,
        "name": "Olympia Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.97361",
        "size": "medium"
    },
    {
        "iata": "OMA",
        "lon": "-95.89972",
        "iso": "US",
        "status": 1,
        "name": "Eppley Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "41.29957",
        "size": "medium"
    },
    {
        "iata": "ONT",
        "lon": "-117.59765",
        "iso": "US",
        "status": 1,
        "name": "Ontario International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.06068",
        "size": "large"
    },
    {
        "iata": "ORD",
        "lon": "-87.90488",
        "iso": "US",
        "status": 1,
        "name": "Chicago O'Hare International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.976913",
        "size": "large"
    },
    {
        "iata": "ORF",
        "lon": "-76.20629",
        "iso": "US",
        "status": 1,
        "name": "Norfolk International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.898582",
        "size": "large"
    },
    {
        "iata": "ORH",
        "lon": "-71.87444",
        "iso": "US",
        "status": 1,
        "name": "Worcester Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.26917",
        "size": "medium"
    },
    {
        "iata": "OSH",
        "lon": "-88.556946",
        "iso": "US",
        "status": 1,
        "name": "Wittman Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.98389",
        "size": "medium"
    },
    {
        "iata": "OTH",
        "lon": "-124.24",
        "iso": "US",
        "status": 1,
        "name": "Southwest Oregon Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.418888",
        "size": "medium"
    },
    {
        "iata": "OTM",
        "lon": "-92.449165",
        "iso": "US",
        "status": 1,
        "name": "Ottumwa Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.106388",
        "size": "medium"
    },
    {
        "iata": "OWB",
        "lon": "-87.16583",
        "iso": "US",
        "status": 1,
        "name": "Owensboro Daviess County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.740833",
        "size": "medium"
    },
    {
        "iata": "DSO",
        "iso": "KP",
        "status": 1,
        "name": "Sondok Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "HBK",
        "iso": "US",
        "status": 1,
        "name": "Holbrook Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CWX",
        "iso": "US",
        "status": 1,
        "name": "Cochise County Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "PAE",
        "lon": "-122.28445",
        "iso": "US",
        "status": 1,
        "name": "Snohomish County (Paine Field) Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.90972",
        "size": "medium"
    },
    {
        "iata": "PAH",
        "lon": "-88.7725",
        "iso": "US",
        "status": 1,
        "name": "Barkley Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.06111",
        "size": "medium"
    },
    {
        "iata": "PBG",
        "lon": "-73.48333",
        "iso": "US",
        "status": 1,
        "name": "Plattsburgh International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.7",
        "size": "medium"
    },
    {
        "iata": "PBI",
        "lon": "-80.09044",
        "iso": "US",
        "status": 1,
        "name": "Palm Beach International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.688885",
        "size": "large"
    },
    {
        "iata": "PCA",
        "iso": "US",
        "status": 1,
        "name": "Picacho Stagefield Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "PDK",
        "lon": "-84.30194",
        "iso": "US",
        "status": 1,
        "name": "DeKalb Peachtree Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.875557",
        "size": "medium"
    },
    {
        "iata": "PDX",
        "lon": "-122.5929",
        "iso": "US",
        "status": 1,
        "name": "Portland International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.588997",
        "size": "large"
    },
    {
        "iata": "PFN",
        "lon": "-85.68309",
        "iso": "US",
        "status": 1,
        "name": "Panama City-Bay Co International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.207085",
        "size": "medium"
    },
    {
        "iata": "PHF",
        "lon": "-76.50282",
        "iso": "US",
        "status": 1,
        "name": "Newport News Williamsburg International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.13033",
        "size": "large"
    },
    {
        "iata": "ADR",
        "lon": "-79.566666",
        "iso": "US",
        "status": 1,
        "name": "Robert F Swinnie Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.45",
        "size": "small"
    },
    {
        "iata": "PHL",
        "lon": "-75.2433",
        "iso": "US",
        "status": 1,
        "name": "Philadelphia International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.87641",
        "size": "large"
    },
    {
        "iata": "PHX",
        "lon": "-112.00016",
        "iso": "US",
        "status": 1,
        "name": "Phoenix Sky Harbor International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.435036",
        "size": "large"
    },
    {
        "iata": "PIA",
        "lon": "-89.69012",
        "iso": "US",
        "status": 1,
        "name": "Greater Peoria Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.66643",
        "size": "large"
    },
    {
        "iata": "PIB",
        "lon": "-89.33667",
        "iso": "US",
        "status": 1,
        "name": "Hattiesburg Laurel Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.4675",
        "size": "medium"
    },
    {
        "iata": "PIE",
        "lon": "-82.69511",
        "iso": "US",
        "status": 1,
        "name": "St Petersburg Clearwater International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.912004",
        "size": "medium"
    },
    {
        "iata": "PIH",
        "lon": "-112.594444",
        "iso": "US",
        "status": 1,
        "name": "Pocatello Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.913334",
        "size": "medium"
    },
    {
        "iata": "PIR",
        "lon": "-100.29319",
        "iso": "US",
        "status": 1,
        "name": "Pierre Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.380363",
        "size": "medium"
    },
    {
        "iata": "PIT",
        "lon": "-80.25657",
        "iso": "US",
        "status": 1,
        "name": "Pittsburgh International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.49585",
        "size": "large"
    },
    {
        "iata": "PKB",
        "lon": "-81.43889",
        "iso": "US",
        "status": 1,
        "name": "Mid Ohio Valley Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.345554",
        "size": "medium"
    },
    {
        "iata": "PLB",
        "iso": "US",
        "status": 0,
        "name": "Clinton County Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "PLN",
        "lon": "-84.79278",
        "iso": "US",
        "status": 1,
        "name": "Pellston Regional Airport of Emmet County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.57083",
        "size": "medium"
    },
    {
        "iata": "PMD",
        "lon": "-118.083336",
        "iso": "US",
        "status": 1,
        "name": "Palmdale Regional/USAF Plant 42 Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.6275",
        "size": "medium"
    },
    {
        "iata": "PNS",
        "lon": "-87.19416",
        "iso": "US",
        "status": 1,
        "name": "Pensacola Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.475943",
        "size": "medium"
    },
    {
        "iata": "POU",
        "lon": "-73.882774",
        "iso": "US",
        "status": 1,
        "name": "Dutchess County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.628056",
        "size": "medium"
    },
    {
        "iata": "PQI",
        "lon": "-68.0475",
        "iso": "US",
        "status": 1,
        "name": "Northern Maine Regional Airport at Presque Isle",
        "continent": "NA",
        "type": "airport",
        "lat": "46.685",
        "size": "medium"
    },
    {
        "iata": "PRC",
        "lon": "-112.424164",
        "iso": "US",
        "status": 1,
        "name": "Ernest A. Love Field",
        "continent": "NA",
        "type": "airport",
        "lat": "34.65",
        "size": "medium"
    },
    {
        "iata": "PSC",
        "lon": "-119.1152",
        "iso": "US",
        "status": 1,
        "name": "Tri Cities Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.259094",
        "size": "medium"
    },
    {
        "iata": "PSM",
        "lon": "-70.88272",
        "iso": "US",
        "status": 1,
        "name": "Portsmouth International at Pease Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.179585",
        "size": "medium"
    },
    {
        "iata": "PSP",
        "lon": "-116.508446",
        "iso": "US",
        "status": 1,
        "name": "Palm Springs International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.822975",
        "size": "medium"
    },
    {
        "iata": "PUB",
        "lon": "-104.49611",
        "iso": "US",
        "status": 1,
        "name": "Pueblo Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.292778",
        "size": "small"
    },
    {
        "iata": "PUW",
        "lon": "-117.105",
        "iso": "US",
        "status": 1,
        "name": "Pullman Moscow Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.74472",
        "size": "medium"
    },
    {
        "iata": "PVD",
        "lon": "-71.43632",
        "iso": "US",
        "status": 1,
        "name": "Theodore Francis Green State Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.72631",
        "size": "medium"
    },
    {
        "iata": "PWM",
        "lon": "-70.31031",
        "iso": "US",
        "status": 1,
        "name": "Portland International Jetport Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.64749",
        "size": "large"
    },
    {
        "iata": "PWT",
        "lon": "-122.763336",
        "iso": "US",
        "status": 1,
        "name": "Bremerton National Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.485",
        "size": "medium"
    },
    {
        "iata": "KQA",
        "iso": "US",
        "status": 1,
        "name": "Akutan Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "RAP",
        "lon": "-103.06118",
        "iso": "US",
        "status": 1,
        "name": "Rapid City Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.037582",
        "size": "medium"
    },
    {
        "iata": "RDD",
        "lon": "-122.29991",
        "iso": "US",
        "status": 1,
        "name": "Redding Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.505768",
        "size": "medium"
    },
    {
        "iata": "RDG",
        "lon": "-75.96389",
        "iso": "US",
        "status": 1,
        "name": "Reading Regional Carl A Spaatz Field",
        "continent": "NA",
        "type": "airport",
        "lat": "40.378613",
        "size": "medium"
    },
    {
        "iata": "RDM",
        "lon": "-121.162895",
        "iso": "US",
        "status": 1,
        "name": "Roberts Field",
        "continent": "NA",
        "type": "airport",
        "lat": "44.254272",
        "size": "medium"
    },
    {
        "iata": "RDU",
        "lon": "-78.79086",
        "iso": "US",
        "status": 1,
        "name": "Raleigh Durham International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.873592",
        "size": "large"
    },
    {
        "iata": "RFD",
        "lon": "-89.222115",
        "iso": "US",
        "status": 1,
        "name": "Chicago Rockford International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.30364",
        "size": "large"
    },
    {
        "iata": "RHI",
        "lon": "-89.46253",
        "iso": "US",
        "status": 1,
        "name": "Rhinelander Oneida County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.625706",
        "size": "medium"
    },
    {
        "iata": "RIC",
        "lon": "-77.3225",
        "iso": "US",
        "status": 1,
        "name": "Richmond International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.50611",
        "size": "large"
    },
    {
        "iata": "KRJ",
        "lon": "143.51666",
        "iso": "PG",
        "status": 1,
        "name": "Karawari Airstrip",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.616667",
        "size": "small"
    },
    {
        "iata": "RKS",
        "lon": "-109.065834",
        "iso": "US",
        "status": 1,
        "name": "Rock Springs Sweetwater County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.59639",
        "size": "medium"
    },
    {
        "iata": "RNO",
        "lon": "-119.775696",
        "iso": "US",
        "status": 1,
        "name": "Reno Tahoe International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.505783",
        "size": "large"
    },
    {
        "iata": "ROA",
        "lon": "-79.97038",
        "iso": "US",
        "status": 1,
        "name": "Roanoke Regional Woodrum Field",
        "continent": "NA",
        "type": "airport",
        "lat": "37.32051",
        "size": "large"
    },
    {
        "iata": "ROC",
        "lon": "-77.66543",
        "iso": "US",
        "status": 1,
        "name": "Greater Rochester International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.127975",
        "size": "large"
    },
    {
        "iata": "ROW",
        "lon": "-104.53",
        "iso": "US",
        "status": 1,
        "name": "Roswell International Air Center Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.29972",
        "size": "medium"
    },
    {
        "iata": "RQO",
        "iso": "US",
        "status": 1,
        "name": "El Reno Regional Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "RST",
        "lon": "-92.48977",
        "iso": "US",
        "status": 1,
        "name": "Rochester International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.910793",
        "size": "large"
    },
    {
        "iata": "RSW",
        "lon": "-81.75433",
        "iso": "US",
        "status": 1,
        "name": "Southwest Florida International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.542835",
        "size": "large"
    },
    {
        "iata": "RUT",
        "lon": "-72.94833",
        "iso": "US",
        "status": 1,
        "name": "Rutland - Southern Vermont Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.529446",
        "size": "medium"
    },
    {
        "iata": "RWI",
        "lon": "-77.89056",
        "iso": "US",
        "status": 1,
        "name": "Rocky Mount Wilson Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.854443",
        "size": "medium"
    },
    {
        "iata": "AHM",
        "iso": "US",
        "status": 1,
        "name": "Ashland Municipal Sumner Parker Field",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "SUO",
        "lon": "-121.5",
        "iso": "US",
        "status": 1,
        "name": "Sunriver Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.916668",
        "size": "small"
    },
    {
        "iata": "IDH",
        "iso": "US",
        "status": 1,
        "name": "Idaho County Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "VSK",
        "iso": "US",
        "status": 1,
        "name": "Vista Field",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "SAN",
        "lon": "-117.19731",
        "iso": "US",
        "status": 1,
        "name": "San Diego International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.731937",
        "size": "large"
    },
    {
        "iata": "SAT",
        "lon": "-98.47264",
        "iso": "US",
        "status": 1,
        "name": "San Antonio International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.524937",
        "size": "large"
    },
    {
        "iata": "SAV",
        "lon": "-81.21059",
        "iso": "US",
        "status": 1,
        "name": "Savannah Hilton Head International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.1358",
        "size": "large"
    },
    {
        "iata": "SBA",
        "lon": "-119.83648",
        "iso": "US",
        "status": 1,
        "name": "Santa Barbara Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.432835",
        "size": "medium"
    },
    {
        "iata": "SBN",
        "lon": "-86.31335",
        "iso": "US",
        "status": 1,
        "name": "South Bend Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.700554",
        "size": "large"
    },
    {
        "iata": "SBP",
        "lon": "-120.640625",
        "iso": "US",
        "status": 1,
        "name": "San Luis County Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.239113",
        "size": "medium"
    },
    {
        "iata": "SBY",
        "lon": "-75.517265",
        "iso": "US",
        "status": 1,
        "name": "Salisbury Ocean City Wicomico Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.34312",
        "size": "medium"
    },
    {
        "iata": "SDF",
        "lon": "-85.74179",
        "iso": "US",
        "status": 1,
        "name": "Louisville International Standiford Field",
        "continent": "NA",
        "type": "airport",
        "lat": "38.186375",
        "size": "large"
    },
    {
        "iata": "SEA",
        "lon": "-122.301735",
        "iso": "US",
        "status": 1,
        "name": "Seattle Tacoma International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.44384",
        "size": "large"
    },
    {
        "iata": "SFB",
        "lon": "-81.2432",
        "iso": "US",
        "status": 1,
        "name": "Orlando Sanford International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.775118",
        "size": "large"
    },
    {
        "iata": "SFO",
        "lon": "-122.38988",
        "iso": "US",
        "status": 1,
        "name": "San Francisco International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.615215",
        "size": "large"
    },
    {
        "iata": "SGF",
        "lon": "-93.38226",
        "iso": "US",
        "status": 1,
        "name": "Springfield Branson National Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.24237",
        "size": "large"
    },
    {
        "iata": "SHD",
        "lon": "-78.89667",
        "iso": "US",
        "status": 1,
        "name": "Shenandoah Valley Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.26389",
        "size": "small"
    },
    {
        "iata": "SHR",
        "lon": "-106.97722",
        "iso": "US",
        "status": 1,
        "name": "Sheridan County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.774166",
        "size": "medium"
    },
    {
        "iata": "SHV",
        "lon": "-93.828384",
        "iso": "US",
        "status": 1,
        "name": "Shreveport Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.45471",
        "size": "large"
    },
    {
        "iata": "SJC",
        "lon": "-121.92638",
        "iso": "US",
        "status": 1,
        "name": "Norman Y. Mineta San Jose International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "37.366737",
        "size": "large"
    },
    {
        "iata": "SJT",
        "lon": "-100.494446",
        "iso": "US",
        "status": 1,
        "name": "San Angelo Regional Mathis Field",
        "continent": "NA",
        "type": "airport",
        "lat": "31.359722",
        "size": "medium"
    },
    {
        "iata": "SLC",
        "lon": "-111.980675",
        "iso": "US",
        "status": 1,
        "name": "Salt Lake City International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.785645",
        "size": "large"
    },
    {
        "iata": "SLK",
        "lon": "-74.206665",
        "iso": "US",
        "status": 1,
        "name": "Adirondack Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.38278",
        "size": "medium"
    },
    {
        "iata": "SLN",
        "lon": "-97.6525",
        "iso": "US",
        "status": 1,
        "name": "Salina Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.790833",
        "size": "medium"
    },
    {
        "iata": "SMF",
        "lon": "-121.5937",
        "iso": "US",
        "status": 1,
        "name": "Sacramento International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.692284",
        "size": "large"
    },
    {
        "iata": "SMX",
        "lon": "-120.45778",
        "iso": "US",
        "status": 1,
        "name": "Santa Maria Pub/Capt G Allan Hancock Field",
        "continent": "NA",
        "type": "airport",
        "lat": "34.905",
        "size": "medium"
    },
    {
        "iata": "SNA",
        "lon": "-117.860535",
        "iso": "US",
        "status": 1,
        "name": "John Wayne Airport-Orange County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.680202",
        "size": "large"
    },
    {
        "iata": "SPI",
        "lon": "-89.67889",
        "iso": "US",
        "status": 1,
        "name": "Abraham Lincoln Capital Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "39.844166",
        "size": "large"
    },
    {
        "iata": "SPS",
        "lon": "-98.49194",
        "iso": "US",
        "status": 1,
        "name": "Sheppard Air Force Base-Wichita Falls Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.988335",
        "size": "large"
    },
    {
        "iata": "SRQ",
        "lon": "-82.55328",
        "iso": "US",
        "status": 1,
        "name": "Sarasota Bradenton International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.38748",
        "size": "large"
    },
    {
        "iata": "STC",
        "lon": "-94.166664",
        "iso": "US",
        "status": 1,
        "name": "St Cloud Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "45.55",
        "size": "medium"
    },
    {
        "iata": "STL",
        "lon": "-90.36587",
        "iso": "US",
        "status": 1,
        "name": "Lambert St Louis International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.74228",
        "size": "large"
    },
    {
        "iata": "STP",
        "lon": "-93.066666",
        "iso": "US",
        "status": 1,
        "name": "St Paul Downtown Holman Field",
        "continent": "NA",
        "type": "airport",
        "lat": "44.933334",
        "size": "medium"
    },
    {
        "iata": "SUN",
        "lon": "-114.30082",
        "iso": "US",
        "status": 1,
        "name": "Friedman Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.50647",
        "size": "medium"
    },
    {
        "iata": "SUX",
        "lon": "-96.38417",
        "iso": "US",
        "status": 1,
        "name": "Sioux Gateway Col. Bud Day Field",
        "continent": "NA",
        "type": "airport",
        "lat": "42.401943",
        "size": "large"
    },
    {
        "iata": "SVC",
        "lon": "-108.15389",
        "iso": "US",
        "status": 1,
        "name": "Grant County Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.631943",
        "size": "small"
    },
    {
        "iata": "SWF",
        "lon": "-74.10104",
        "iso": "US",
        "status": 1,
        "name": "Stewart International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.49843",
        "size": "medium"
    },
    {
        "iata": "SYR",
        "lon": "-76.11223",
        "iso": "US",
        "status": 1,
        "name": "Syracuse Hancock International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.113983",
        "size": "large"
    },
    {
        "iata": "TBN",
        "lon": "-92.14056",
        "iso": "US",
        "status": 1,
        "name": "Waynesville-St. Robert Regional Forney field",
        "continent": "NA",
        "type": "airport",
        "lat": "37.74139",
        "size": "medium"
    },
    {
        "iata": "TCL",
        "lon": "-87.61083",
        "iso": "US",
        "status": 1,
        "name": "Tuscaloosa Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "33.22111",
        "size": "medium"
    },
    {
        "iata": "TLH",
        "lon": "-84.34444",
        "iso": "US",
        "status": 1,
        "name": "Tallahassee Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.395782",
        "size": "large"
    },
    {
        "iata": "OTK",
        "iso": "US",
        "status": 1,
        "name": "Tillamook Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "TOL",
        "lon": "-83.8069",
        "iso": "US",
        "status": 1,
        "name": "Toledo Express Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.5925",
        "size": "large"
    },
    {
        "iata": "TPA",
        "lon": "-82.535416",
        "iso": "US",
        "status": 1,
        "name": "Tampa International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.97987",
        "size": "large"
    },
    {
        "iata": "TPL",
        "lon": "-97.409164",
        "iso": "US",
        "status": 1,
        "name": "Draughon Miller Central Texas Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.150278",
        "size": "medium"
    },
    {
        "iata": "TRI",
        "lon": "-82.40785",
        "iso": "US",
        "status": 1,
        "name": "Tri Cities Regional Tn Va Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.48111",
        "size": "large"
    },
    {
        "iata": "TTN",
        "lon": "-74.8125",
        "iso": "US",
        "status": 1,
        "name": "Trenton Mercer Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.278057",
        "size": "medium"
    },
    {
        "iata": "TUL",
        "lon": "-95.8901",
        "iso": "US",
        "status": 1,
        "name": "Tulsa International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "36.189808",
        "size": "large"
    },
    {
        "iata": "TUP",
        "lon": "-88.76722",
        "iso": "US",
        "status": 1,
        "name": "Tupelo Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.25889",
        "size": "medium"
    },
    {
        "iata": "TUS",
        "lon": "-110.93737",
        "iso": "US",
        "status": 1,
        "name": "Tucson International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.12069",
        "size": "large"
    },
    {
        "iata": "TVC",
        "lon": "-85.58035",
        "iso": "US",
        "status": 1,
        "name": "Cherry Capital Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "44.744083",
        "size": "medium"
    },
    {
        "iata": "TVL",
        "lon": "-119.994446",
        "iso": "US",
        "status": 1,
        "name": "Lake Tahoe Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "38.89417",
        "size": "medium"
    },
    {
        "iata": "TWF",
        "lon": "-114.486664",
        "iso": "US",
        "status": 1,
        "name": "Joslin Field Magic Valley Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.481945",
        "size": "medium"
    },
    {
        "iata": "TXK",
        "lon": "-93.98917",
        "iso": "US",
        "status": 1,
        "name": "Texarkana Regional Webb Field",
        "continent": "NA",
        "type": "airport",
        "lat": "33.45639",
        "size": "medium"
    },
    {
        "iata": "TYR",
        "lon": "-95.41012",
        "iso": "US",
        "status": 1,
        "name": "Tyler Pounds Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.351597",
        "size": "medium"
    },
    {
        "iata": "TYS",
        "lon": "-83.98973",
        "iso": "US",
        "status": 1,
        "name": "McGhee Tyson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "35.80565",
        "size": "large"
    },
    {
        "iata": "ICS",
        "iso": "US",
        "status": 1,
        "name": "Cascade Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "UCA",
        "iso": "US",
        "status": 0,
        "name": "Oneida County Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "UIN",
        "lon": "-91.19722",
        "iso": "US",
        "status": 1,
        "name": "Quincy Regional Baldwin Field",
        "continent": "NA",
        "type": "airport",
        "lat": "39.944168",
        "size": "medium"
    },
    {
        "iata": "UIZ",
        "iso": "US",
        "status": 0,
        "name": "Berz-Macomb Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "SCE",
        "lon": "-77.84823",
        "iso": "US",
        "status": 1,
        "name": "University Park Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "40.85372",
        "size": "medium"
    },
    {
        "iata": "VCT",
        "lon": "-96.914444",
        "iso": "US",
        "status": 1,
        "name": "Victoria Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.85111",
        "size": "medium"
    },
    {
        "iata": "VLD",
        "lon": "-83.27722",
        "iso": "US",
        "status": 1,
        "name": "Valdosta Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.78389",
        "size": "medium"
    },
    {
        "iata": "VPS",
        "lon": "-86.54946",
        "iso": "US",
        "status": 1,
        "name": "Eglin Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "30.495913",
        "size": "large"
    },
    {
        "iata": "VRB",
        "lon": "-80.41361",
        "iso": "US",
        "status": 1,
        "name": "Vero Beach Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.6525",
        "size": "medium"
    },
    {
        "iata": "WRI",
        "lon": "-74.65017",
        "iso": "US",
        "status": 1,
        "name": "Mc Guire Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "40.155308",
        "size": "medium"
    },
    {
        "iata": "WRL",
        "lon": "-107.95306",
        "iso": "US",
        "status": 1,
        "name": "Worland Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "43.9675",
        "size": "medium"
    },
    {
        "iata": "KWY",
        "lon": "41.266666",
        "iso": "KE",
        "status": 1,
        "name": "Kiwayu Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.016667",
        "size": "medium"
    },
    {
        "iata": "YIP",
        "lon": "-83.53333",
        "iso": "US",
        "status": 1,
        "name": "Willow Run Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "42.233334",
        "size": "medium"
    },
    {
        "iata": "YKM",
        "lon": "-120.53778",
        "iso": "US",
        "status": 1,
        "name": "Yakima Air Terminal McAllister Field",
        "continent": "NA",
        "type": "airport",
        "lat": "46.566944",
        "size": "medium"
    },
    {
        "iata": "YNG",
        "lon": "-80.66972",
        "iso": "US",
        "status": 1,
        "name": "Youngstown Warren Regional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "41.25611",
        "size": "medium"
    },
    {
        "iata": "DZN",
        "lon": "67.73333",
        "iso": "KZ",
        "status": 1,
        "name": "Dzhezkazgan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "47.7",
        "size": "medium"
    },
    {
        "iata": "TDK",
        "lon": "78.433334",
        "iso": "KZ",
        "status": 1,
        "name": "Taldykorgan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.15",
        "size": "medium"
    },
    {
        "iata": "ATX",
        "lon": "68.4",
        "iso": "KZ",
        "status": 1,
        "name": "Atbasar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "51.88333",
        "size": "small"
    },
    {
        "iata": "AVX",
        "iso": "US",
        "status": 1,
        "name": "Pebbly Beach Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "LAC",
        "lon": "113.85",
        "iso": "CN",
        "status": 1,
        "name": "Swallow Reef Airstrip",
        "continent": "AS",
        "type": "airport",
        "lat": "7.4",
        "size": "small"
    },
    {
        "iata": "TIA",
        "lon": "19.71328",
        "iso": "AL",
        "status": 1,
        "name": "Tirana International Airport Mother Teresa",
        "continent": "EU",
        "type": "airport",
        "lat": "41.419132",
        "size": "large"
    },
    {
        "iata": "BOJ",
        "lon": "27.515545",
        "iso": "BG",
        "status": 1,
        "name": "Burgas Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.56745",
        "size": "large"
    },
    {
        "iata": "GOZ",
        "lon": "25.666668",
        "iso": "BG",
        "status": 1,
        "name": "Gorna Oryahovitsa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.11667",
        "size": "medium"
    },
    {
        "iata": "PDV",
        "lon": "24.716667",
        "iso": "BG",
        "status": 1,
        "name": "Plovdiv International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.3",
        "size": "large"
    },
    {
        "iata": "PVN",
        "lon": "24.616667",
        "iso": "BG",
        "status": 1,
        "name": "Dolna Mitropoliya Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "43.416668",
        "size": "medium"
    },
    {
        "iata": "SOF",
        "lon": "23.41443",
        "iso": "BG",
        "status": 1,
        "name": "Sofia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.688343",
        "size": "large"
    },
    {
        "iata": "SLS",
        "lon": "27.180555",
        "iso": "BG",
        "status": 1,
        "name": "Silistra Polkovnik Lambrinovo Airfield",
        "continent": "EU",
        "type": "airport",
        "lat": "44.05639",
        "size": "small"
    },
    {
        "iata": "SZR",
        "lon": "25.616667",
        "iso": "BG",
        "status": 1,
        "name": "Stara Zagora Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.433334",
        "size": "small"
    },
    {
        "iata": "VID",
        "lon": "22.816668",
        "iso": "BG",
        "status": 1,
        "name": "Vidin Smurdan Airfield",
        "continent": "EU",
        "type": "airport",
        "lat": "43.983334",
        "size": "small"
    },
    {
        "iata": "VAR",
        "lon": "27.829096",
        "iso": "BG",
        "status": 1,
        "name": "Varna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.23726",
        "size": "large"
    },
    {
        "iata": "ECN",
        "lon": "33.50307",
        "iso": "CY",
        "status": 1,
        "name": "Ercan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.15812",
        "size": "medium"
    },
    {
        "iata": "LCA",
        "lon": "33.62599",
        "iso": "CY",
        "status": 1,
        "name": "Larnaca International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.880867",
        "size": "large"
    },
    {
        "iata": "LCP",
        "lon": "-70.666664",
        "iso": "AR",
        "status": 1,
        "name": "Loncopue Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.083332",
        "size": "small"
    },
    {
        "iata": "PFO",
        "lon": "32.489105",
        "iso": "CY",
        "status": 1,
        "name": "Paphos International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.71155",
        "size": "large"
    },
    {
        "iata": "AKT",
        "lon": "32.983334",
        "iso": "CY",
        "status": 1,
        "name": "RAF Akrotiri",
        "continent": "AS",
        "type": "airport",
        "lat": "34.583332",
        "size": "medium"
    },
    {
        "iata": "DBV",
        "lon": "18.260616",
        "iso": "HR",
        "status": 1,
        "name": "Dubrovnik Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.56072",
        "size": "medium"
    },
    {
        "iata": "LSZ",
        "lon": "14.385833",
        "iso": "HR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "44.566944",
        "size": "small"
    },
    {
        "iata": "OSI",
        "lon": "18.683332",
        "iso": "HR",
        "status": 1,
        "name": "Osijek Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.55",
        "size": "medium"
    },
    {
        "iata": "PUY",
        "lon": "13.923611",
        "iso": "HR",
        "status": 1,
        "name": "Pula Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.891666",
        "size": "medium"
    },
    {
        "iata": "RJK",
        "lon": "14.568333",
        "iso": "HR",
        "status": 1,
        "name": "Rijeka Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.21583",
        "size": "medium"
    },
    {
        "iata": "BWK",
        "lon": "16.683332",
        "iso": "HR",
        "status": 1,
        "name": "Bol Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.283333",
        "size": "medium"
    },
    {
        "iata": "SPU",
        "lon": "16.29946",
        "iso": "HR",
        "status": 1,
        "name": "Split Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.536526",
        "size": "medium"
    },
    {
        "iata": "ZAG",
        "lon": "16.06152",
        "iso": "HR",
        "status": 1,
        "name": "Zagreb Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.733242",
        "size": "large"
    },
    {
        "iata": "ZAD",
        "lon": "15.356667",
        "iso": "HR",
        "status": 1,
        "name": "Zemunik Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.09778",
        "size": "medium"
    },
    {
        "iata": "ABC",
        "lon": "-1.863333",
        "iso": "ES",
        "status": 1,
        "name": "Albacete-Los Llanos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.948334",
        "size": "medium"
    },
    {
        "iata": "ALC",
        "lon": "-0.557381",
        "iso": "ES",
        "status": 1,
        "name": "Alicante International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.287098",
        "size": "large"
    },
    {
        "iata": "LEI",
        "lon": "-2.371873",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "36.847984",
        "size": "medium"
    },
    {
        "iata": "OVD",
        "lon": "-6.032094",
        "iso": "ES",
        "status": 1,
        "name": "Asturias Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.55891",
        "size": "medium"
    },
    {
        "iata": "ODB",
        "lon": "-4.847222",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "37.84111",
        "size": "medium"
    },
    {
        "iata": "BIO",
        "lon": "-2.905808",
        "iso": "ES",
        "status": 1,
        "name": "Bilbao Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.305534",
        "size": "medium"
    },
    {
        "iata": "RGS",
        "lon": "-3.62295",
        "iso": "ES",
        "status": 1,
        "name": "Burgos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.35494",
        "size": "medium"
    },
    {
        "iata": "BCN",
        "lon": "2.07593",
        "iso": "ES",
        "status": 1,
        "name": "Barcelona International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.30303",
        "size": "large"
    },
    {
        "iata": "BJZ",
        "lon": "-6.82",
        "iso": "ES",
        "status": 1,
        "name": "Badajoz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.891666",
        "size": "medium"
    },
    {
        "iata": "CDT",
        "iso": "ES",
        "status": 1,
        "name": "Castellon De La Plana Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LCG",
        "lon": "-8.381923",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.30236",
        "size": "medium"
    },
    {
        "iata": "GRO",
        "lon": "2.766383",
        "iso": "ES",
        "status": 1,
        "name": "Girona Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.89804",
        "size": "medium"
    },
    {
        "iata": "GRX",
        "lon": "-3.776954",
        "iso": "ES",
        "status": 1,
        "name": "Federico Garcia Lorca Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.184727",
        "size": "medium"
    },
    {
        "iata": "CCX",
        "lon": "-57.7",
        "iso": "ES",
        "status": 1,
        "name": "Guadalupe Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "-16.066668",
        "size": "small"
    },
    {
        "iata": "IBZ",
        "lon": "1.367803",
        "iso": "ES",
        "status": 1,
        "name": "Ibiza Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.876595",
        "size": "medium"
    },
    {
        "iata": "XRY",
        "lon": "-6.064535",
        "iso": "ES",
        "status": 1,
        "name": "Jerez Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.750614",
        "size": "medium"
    },
    {
        "iata": "MJV",
        "lon": "-0.81904",
        "iso": "ES",
        "status": 1,
        "name": "San Javier Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.77521",
        "size": "medium"
    },
    {
        "iata": "QSA",
        "iso": "ES",
        "status": 1,
        "name": "Sabadell Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LEN",
        "lon": "-5.64629",
        "iso": "ES",
        "status": 1,
        "name": "Leon Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.59057",
        "size": "medium"
    },
    {
        "iata": "RJL",
        "lon": "-2.323611",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "42.456944",
        "size": "small"
    },
    {
        "iata": "MAD",
        "lon": "-3.570209",
        "iso": "ES",
        "status": 1,
        "name": "Madrid Barajas International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.46515",
        "size": "large"
    },
    {
        "iata": "HEV",
        "lon": "-6.95",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "37.266666",
        "size": "small"
    },
    {
        "iata": "AGP",
        "lon": "-4.489616",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "36.675182",
        "size": "large"
    },
    {
        "iata": "MAH",
        "lon": "4.226256",
        "iso": "ES",
        "status": 1,
        "name": "Menorca Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.864746",
        "size": "medium"
    },
    {
        "iata": "OZP",
        "lon": "-5.759444",
        "iso": "ES",
        "status": 1,
        "name": "Moron Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "37.24917",
        "size": "medium"
    },
    {
        "iata": "PMI",
        "lon": "2.730388",
        "iso": "ES",
        "status": 1,
        "name": "Palma De Mallorca Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.547653",
        "size": "large"
    },
    {
        "iata": "PNA",
        "lon": "-1.639347",
        "iso": "ES",
        "status": 1,
        "name": "Pamplona Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.767498",
        "size": "medium"
    },
    {
        "iata": "CQM",
        "lon": "-3.974536",
        "iso": "ES",
        "status": 1,
        "name": "Ciudad Real Central Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.858685",
        "size": "small"
    },
    {
        "iata": "REU",
        "lon": "1.153319",
        "iso": "ES",
        "status": 1,
        "name": "Reus Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "41.146103",
        "size": "medium"
    },
    {
        "iata": "SLM",
        "lon": "-5.501944",
        "iso": "ES",
        "status": 1,
        "name": "Salamanca Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.951942",
        "size": "medium"
    },
    {
        "iata": "EAS",
        "lon": "-1.793538",
        "iso": "ES",
        "status": 1,
        "name": "San Sebastian Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.3564",
        "size": "medium"
    },
    {
        "iata": "SCQ",
        "lon": "-8.420327",
        "iso": "ES",
        "status": 1,
        "name": "Santiago de Compostela Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.897316",
        "size": "large"
    },
    {
        "iata": "LEU",
        "lon": "1.466667",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "42.416668",
        "size": "small"
    },
    {
        "iata": "TOJ",
        "lon": "-3.546667",
        "iso": "ES",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "40.455555",
        "size": "medium"
    },
    {
        "iata": "VLC",
        "lon": "-0.473475",
        "iso": "ES",
        "status": 1,
        "name": "Valencia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.49179",
        "size": "medium"
    },
    {
        "iata": "VLL",
        "lon": "-4.844626",
        "iso": "ES",
        "status": 1,
        "name": "Valladolid Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.70581",
        "size": "medium"
    },
    {
        "iata": "VIT",
        "lon": "-2.5",
        "iso": "ES",
        "status": 1,
        "name": "Vitoria/Foronda Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.833332",
        "size": "medium"
    },
    {
        "iata": "VGO",
        "lon": "-8.634025",
        "iso": "ES",
        "status": 1,
        "name": "Vigo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.224552",
        "size": "medium"
    },
    {
        "iata": "SDR",
        "lon": "-3.824453",
        "iso": "ES",
        "status": 1,
        "name": "Santander Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.423084",
        "size": "medium"
    },
    {
        "iata": "ZAZ",
        "lon": "-1.007466",
        "iso": "ES",
        "status": 1,
        "name": "Zaragoza Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "41.66386",
        "size": "medium"
    },
    {
        "iata": "SVQ",
        "lon": "-5.900136",
        "iso": "ES",
        "status": 1,
        "name": "Sevilla Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.423477",
        "size": "medium"
    },
    {
        "iata": "DPE",
        "lon": "1.241111",
        "iso": "FR",
        "status": 1,
        "name": "St Aubin Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.961388",
        "size": "small"
    },
    {
        "iata": "CQF",
        "lon": "1.933333",
        "iso": "FR",
        "status": 1,
        "name": "Calais-Dunkerque Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.95",
        "size": "medium"
    },
    {
        "iata": "XCP",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "XLN",
        "iso": "FR",
        "status": 1,
        "name": "Laon - Chambry Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "XSJ",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "XDK",
        "iso": "FR",
        "status": 1,
        "name": "Dunkerque les Moeres Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BYF",
        "lon": "2.699448",
        "iso": "FR",
        "status": 1,
        "name": "Albert-Bray Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.97039",
        "size": "small"
    },
    {
        "iata": "LTQ",
        "lon": "1.627071",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "50.514774",
        "size": "medium"
    },
    {
        "iata": "XVS",
        "iso": "FR",
        "status": 1,
        "name": "Valenciennes-Denain Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "QAM",
        "iso": "FR",
        "status": 1,
        "name": "Amiens-Glisy Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "AGF",
        "lon": "0.598611",
        "iso": "FR",
        "status": 1,
        "name": "Agen-La Garenne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.17361",
        "size": "medium"
    },
    {
        "iata": "BOD",
        "lon": "-0.70217",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "44.83102",
        "size": "large"
    },
    {
        "iata": "EGC",
        "lon": "0.483333",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "44.85",
        "size": "medium"
    },
    {
        "iata": "CNG",
        "lon": "-0.333333",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.683334",
        "size": "medium"
    },
    {
        "iata": "LRH",
        "lon": "-1.185833",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.180557",
        "size": "medium"
    },
    {
        "iata": "PIS",
        "lon": "0.306389",
        "iso": "FR",
        "status": 1,
        "name": "Poitiers-Biard Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.58611",
        "size": "medium"
    },
    {
        "iata": "MCU",
        "lon": "2.6",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.35",
        "size": "medium"
    },
    {
        "iata": "LIG",
        "lon": "1.176245",
        "iso": "FR",
        "status": 1,
        "name": "Limoges Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.86218",
        "size": "medium"
    },
    {
        "iata": "XMJ",
        "iso": "FR",
        "status": 1,
        "name": "Mont-de-Marsan (BA 118) Air Base",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "NIT",
        "lon": "-0.45",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.316666",
        "size": "medium"
    },
    {
        "iata": "TLS",
        "lon": "1.374321",
        "iso": "FR",
        "status": 1,
        "name": "Toulouse-Blagnac Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.63007",
        "size": "large"
    },
    {
        "iata": "PUF",
        "lon": "-0.413573",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.382347",
        "size": "medium"
    },
    {
        "iata": "LDE",
        "lon": "0.003368",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.18651",
        "size": "medium"
    },
    {
        "iata": "ANG",
        "lon": "0.216667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.733334",
        "size": "medium"
    },
    {
        "iata": "BVE",
        "lon": "1.533333",
        "iso": "FR",
        "status": 1,
        "name": "Brive-La Roche Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.166668",
        "size": "large"
    },
    {
        "iata": "PGX",
        "lon": "0.716667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.183334",
        "size": "medium"
    },
    {
        "iata": "XDA",
        "iso": "FR",
        "status": 1,
        "name": "Dax Seyresse Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BIQ",
        "lon": "-1.531242",
        "iso": "FR",
        "status": 1,
        "name": "Biarritz-Anglet-Bayonne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.472416",
        "size": "medium"
    },
    {
        "iata": "XCX",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ZAO",
        "lon": "1.45",
        "iso": "FR",
        "status": 1,
        "name": "Cahors-Lalbenque Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.45",
        "size": "medium"
    },
    {
        "iata": "XGT",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "XAC",
        "iso": "FR",
        "status": 1,
        "name": "Arcachon-La Teste-de-Buch Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "LBI",
        "lon": "2.15",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.933334",
        "size": "medium"
    },
    {
        "iata": "DCM",
        "lon": "2.284167",
        "iso": "FR",
        "status": 1,
        "name": "Castres-Mazamet Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.555832",
        "size": "medium"
    },
    {
        "iata": "RDZ",
        "lon": "2.483866",
        "iso": "FR",
        "status": 1,
        "name": "Rodez-Marcillac Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.410595",
        "size": "medium"
    },
    {
        "iata": "RYN",
        "lon": "-1.016667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.61667",
        "size": "medium"
    },
    {
        "iata": "XMW",
        "iso": "FR",
        "status": 1,
        "name": "Montauban Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "XLR",
        "iso": "FR",
        "status": 1,
        "name": "Libourne-Artigues-de-Lussac Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "RCO",
        "lon": "-0.980556",
        "iso": "FR",
        "status": 1,
        "name": "Rochefort-Saint-Agnant (BA 721) Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.890556",
        "size": "medium"
    },
    {
        "iata": "XSL",
        "iso": "FR",
        "status": 1,
        "name": "Sarlat Domme Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "XTB",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "IDY",
        "lon": "-2.388694",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.71787",
        "size": "medium"
    },
    {
        "iata": "CMR",
        "lon": "7.366667",
        "iso": "FR",
        "status": 1,
        "name": "Colmar-Houssen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.083332",
        "size": "medium"
    },
    {
        "iata": "XBV",
        "iso": "FR",
        "status": 1,
        "name": "Beaune-Challanges Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "DLE",
        "lon": "5.428719",
        "iso": "FR",
        "status": 1,
        "name": "Dole-Tavaux Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.038254",
        "size": "medium"
    },
    {
        "iata": "XVN",
        "iso": "FR",
        "status": 1,
        "name": "Verdun-Le Rozelier Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "MVV",
        "lon": "6.65",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.816666",
        "size": "small"
    },
    {
        "iata": "OBS",
        "lon": "4.7",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "44.666668",
        "size": "medium"
    },
    {
        "iata": "LPY",
        "lon": "3.766667",
        "iso": "FR",
        "status": 1,
        "name": "Le Puy-Loudes Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.083332",
        "size": "medium"
    },
    {
        "iata": "AHZ",
        "lon": "6.083333",
        "iso": "FR",
        "status": 1,
        "name": "L'alpe D'huez Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.083332",
        "size": "small"
    },
    {
        "iata": "ETZ",
        "lon": "6.243003",
        "iso": "FR",
        "status": 1,
        "name": "Metz-Nancy-Lorraine Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.98165",
        "size": "medium"
    },
    {
        "iata": "ANE",
        "lon": "-0.55",
        "iso": "FR",
        "status": 1,
        "name": "Angers-Loire Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.466667",
        "size": "medium"
    },
    {
        "iata": "BIA",
        "lon": "9.480124",
        "iso": "FR",
        "status": 1,
        "name": "Bastia-Poretta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.547615",
        "size": "medium"
    },
    {
        "iata": "CLY",
        "lon": "8.791667",
        "iso": "FR",
        "status": 1,
        "name": "Calvi-Sainte-Catherine Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.52778",
        "size": "medium"
    },
    {
        "iata": "FSC",
        "lon": "9.098223",
        "iso": "FR",
        "status": 1,
        "name": "Figari Sud-Corse Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.498634",
        "size": "medium"
    },
    {
        "iata": "AJA",
        "lon": "8.794013",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "41.91987",
        "size": "medium"
    },
    {
        "iata": "PRP",
        "lon": "8.9",
        "iso": "FR",
        "status": 1,
        "name": "Propriano Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.666668",
        "size": "small"
    },
    {
        "iata": "SOZ",
        "lon": "9.383333",
        "iso": "FR",
        "status": 1,
        "name": "Solenzara (BA 126) Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "41.88333",
        "size": "medium"
    },
    {
        "iata": "MFX",
        "lon": "6.566667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.416668",
        "size": "small"
    },
    {
        "iata": "AUF",
        "lon": "3.5",
        "iso": "FR",
        "status": 1,
        "name": "Auxerre-Branches Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.85",
        "size": "medium"
    },
    {
        "iata": "CMF",
        "lon": "5.88437",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.637993",
        "size": "medium"
    },
    {
        "iata": "CFE",
        "lon": "3.163893",
        "iso": "FR",
        "status": 1,
        "name": "Clermont-Ferrand Auvergne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.78032",
        "size": "medium"
    },
    {
        "iata": "BOU",
        "lon": "2.416667",
        "iso": "FR",
        "status": 1,
        "name": "Bourges Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.15",
        "size": "medium"
    },
    {
        "iata": "QNJ",
        "iso": "FR",
        "status": 1,
        "name": "Annemasse Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "CVF",
        "lon": "6.634722",
        "iso": "FR",
        "status": 1,
        "name": "Courchevel Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.396667",
        "size": "small"
    },
    {
        "iata": "LYS",
        "lon": "5.080334",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.721424",
        "size": "medium"
    },
    {
        "iata": "QNX",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SYT",
        "lon": "4.033333",
        "iso": "FR",
        "status": 1,
        "name": "Saint-Yan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.4",
        "size": "medium"
    },
    {
        "iata": "RNE",
        "lon": "4.000833",
        "iso": "FR",
        "status": 1,
        "name": "Roanne-Renaison Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.053333",
        "size": "medium"
    },
    {
        "iata": "NCY",
        "lon": "6.100833",
        "iso": "FR",
        "status": 1,
        "name": "Annecy-Haute-Savoie-Mont Blanc Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.930557",
        "size": "medium"
    },
    {
        "iata": "XMK",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "GNB",
        "lon": "5.332019",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.359833",
        "size": "medium"
    },
    {
        "iata": "MCU",
        "lon": "2.6",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.35",
        "size": "small"
    },
    {
        "iata": "VAF",
        "lon": "4.970278",
        "iso": "FR",
        "status": 1,
        "name": "Valence-Chabeuil Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.920277",
        "size": "medium"
    },
    {
        "iata": "VHY",
        "lon": "3.409722",
        "iso": "FR",
        "status": 1,
        "name": "Vichy-Charmeil Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.165558",
        "size": "medium"
    },
    {
        "iata": "AUR",
        "lon": "2.418056",
        "iso": "FR",
        "status": 1,
        "name": "Aurillac Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.8975",
        "size": "medium"
    },
    {
        "iata": "CHR",
        "lon": "1.728128",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.861954",
        "size": "medium"
    },
    {
        "iata": "LYN",
        "lon": "4.943333",
        "iso": "FR",
        "status": 1,
        "name": "Lyon-Bron Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.73083",
        "size": "medium"
    },
    {
        "iata": "CEQ",
        "lon": "6.954167",
        "iso": "FR",
        "status": 1,
        "name": "Cannes-Mandelieu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.54639",
        "size": "medium"
    },
    {
        "iata": "EBU",
        "lon": "4.296944",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.54139",
        "size": "medium"
    },
    {
        "iata": "QIE",
        "lon": "85.53333",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "38.13333",
        "size": "medium"
    },
    {
        "iata": "CCF",
        "lon": "2.316667",
        "iso": "FR",
        "status": 1,
        "name": "Carcassonne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.216667",
        "size": "medium"
    },
    {
        "iata": "MRS",
        "lon": "5.222137",
        "iso": "FR",
        "status": 1,
        "name": "Marseille Provence Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.44178",
        "size": "large"
    },
    {
        "iata": "NCE",
        "lon": "7.205232",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.66049",
        "size": "large"
    },
    {
        "iata": "XOG",
        "iso": "FR",
        "status": 1,
        "name": "Orange-Caritat (BA 115) Air Base",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "PGF",
        "lon": "2.868183",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "42.741016",
        "size": "medium"
    },
    {
        "iata": "CTT",
        "lon": "5.783333",
        "iso": "FR",
        "status": 1,
        "name": "Le Castellet Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.25",
        "size": "medium"
    },
    {
        "iata": "BAE",
        "lon": "6.666667",
        "iso": "FR",
        "status": 1,
        "name": "Barcelonnette - Saint-Pons Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.38333",
        "size": "small"
    },
    {
        "iata": "XAS",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "MPL",
        "lon": "3.959174",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.57843",
        "size": "medium"
    },
    {
        "iata": "BZR",
        "lon": "3.354444",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.324165",
        "size": "medium"
    },
    {
        "iata": "AVN",
        "lon": "4.9",
        "iso": "FR",
        "status": 1,
        "name": "Avignon-Caumont Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.9",
        "size": "medium"
    },
    {
        "iata": "GAT",
        "lon": "6.083333",
        "iso": "FR",
        "status": 1,
        "name": "Gap - Tallard Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.55",
        "size": "small"
    },
    {
        "iata": "MEN",
        "lon": "3.533333",
        "iso": "FR",
        "status": 1,
        "name": "Mende-Brenoux Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.5",
        "size": "medium"
    },
    {
        "iata": "SCP",
        "lon": "0.616667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "45.416668",
        "size": "small"
    },
    {
        "iata": "BVA",
        "lon": "2.110815",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.459488",
        "size": "medium"
    },
    {
        "iata": "XSU",
        "iso": "FR",
        "status": 1,
        "name": "Saumur-Saint-Florent Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "EVX",
        "lon": "1.15",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.016666",
        "size": "medium"
    },
    {
        "iata": "XAN",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LEH",
        "lon": "0.088611",
        "iso": "FR",
        "status": 1,
        "name": "Le Havre Octeville Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.53889",
        "size": "medium"
    },
    {
        "iata": "XAB",
        "iso": "FR",
        "status": 1,
        "name": "Abbeville-Buigny-Saint-Maclou Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ORE",
        "lon": "1.9",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "47.916668",
        "size": "medium"
    },
    {
        "iata": "XCR",
        "lon": "4.201111",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.767776",
        "size": "medium"
    },
    {
        "iata": "LSO",
        "lon": "-1.783333",
        "iso": "FR",
        "status": 1,
        "name": "Les Sables-d'Olonne Talmont Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.5",
        "size": "small"
    },
    {
        "iata": "URO",
        "lon": "1.1875",
        "iso": "FR",
        "status": 1,
        "name": "Rouen Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.38861",
        "size": "medium"
    },
    {
        "iata": "XBQ",
        "iso": "FR",
        "status": 1,
        "name": "Blois-Le Breuil Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "QTJ",
        "iso": "FR",
        "status": 1,
        "name": "Chartres Champhol Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "TUF",
        "lon": "0.726584",
        "iso": "FR",
        "status": 1,
        "name": "Tours-Val-de-Loire Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.432236",
        "size": "large"
    },
    {
        "iata": "CET",
        "lon": "-0.866667",
        "iso": "FR",
        "status": 1,
        "name": "Cholet Le Pontreau Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.066666",
        "size": "medium"
    },
    {
        "iata": "LVA",
        "lon": "-0.766667",
        "iso": "FR",
        "status": 1,
        "name": "Laval-Entrammes Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.066666",
        "size": "medium"
    },
    {
        "iata": "LBG",
        "lon": "2.4425",
        "iso": "FR",
        "status": 1,
        "name": "Paris-Le Bourget Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.971943",
        "size": "medium"
    },
    {
        "iata": "CSF",
        "lon": "2.483333",
        "iso": "FR",
        "status": 1,
        "name": "Creil Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "49.25",
        "size": "medium"
    },
    {
        "iata": "CDG",
        "lon": "2.567023",
        "iso": "FR",
        "status": 1,
        "name": "Charles de Gaulle International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.003197",
        "size": "large"
    },
    {
        "iata": "JDP",
        "iso": "FR",
        "status": 1,
        "name": "Paris Issy-les-Moulineaux Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "TNF",
        "lon": "2.113056",
        "iso": "FR",
        "status": 1,
        "name": "Toussus-le-Noble Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.75083",
        "size": "medium"
    },
    {
        "iata": "ORY",
        "lon": "2.3597",
        "iso": "FR",
        "status": 1,
        "name": "Paris-Orly Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.728283",
        "size": "large"
    },
    {
        "iata": "POX",
        "lon": "2.1",
        "iso": "FR",
        "status": 1,
        "name": "Pontoise - Cormeilles-en-Vexin Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.05",
        "size": "medium"
    },
    {
        "iata": "VIY",
        "lon": "2.191667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.774166",
        "size": "medium"
    },
    {
        "iata": "QYR",
        "iso": "FR",
        "status": 1,
        "name": "Troyes-Barberey Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "NVS",
        "lon": "3.15",
        "iso": "FR",
        "status": 1,
        "name": "Nevers-Fourchambault Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.0",
        "size": "medium"
    },
    {
        "iata": "XCB",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "XME",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "LIL",
        "lon": "3.106067",
        "iso": "FR",
        "status": 1,
        "name": "Lille-Lesquin Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.57205",
        "size": "medium"
    },
    {
        "iata": "HZB",
        "lon": "2.65",
        "iso": "FR",
        "status": 1,
        "name": "Merville-Calonne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.61667",
        "size": "medium"
    },
    {
        "iata": "XCZ",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "XVO",
        "iso": "FR",
        "status": 1,
        "name": "Vesoul-Frotey Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "BES",
        "lon": "-4.415827",
        "iso": "FR",
        "status": 1,
        "name": "Brest Bretagne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.445374",
        "size": "medium"
    },
    {
        "iata": "CER",
        "lon": "-1.465278",
        "iso": "FR",
        "status": 1,
        "name": "Cherbourg-Maupertus Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.65111",
        "size": "medium"
    },
    {
        "iata": "DNR",
        "lon": "-2.083611",
        "iso": "FR",
        "status": 1,
        "name": "Dinard-Pleurtuit-Saint-Malo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.587776",
        "size": "medium"
    },
    {
        "iata": "LBY",
        "lon": "-2.345833",
        "iso": "FR",
        "status": 1,
        "name": "La Baule-Escoublac Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.28889",
        "size": "medium"
    },
    {
        "iata": "GFR",
        "lon": "-1.566667",
        "iso": "FR",
        "status": 1,
        "name": "Granville Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.88333",
        "size": "medium"
    },
    {
        "iata": "DOL",
        "lon": "0.164167",
        "iso": "FR",
        "status": 1,
        "name": "Deauville-Saint-Gatien Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.362778",
        "size": "medium"
    },
    {
        "iata": "LRT",
        "lon": "-3.436394",
        "iso": "FR",
        "status": 1,
        "name": "Lorient South Brittany (Bretagne Sud) Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.75374",
        "size": "medium"
    },
    {
        "iata": "EDM",
        "lon": "-1.383333",
        "iso": "FR",
        "status": 1,
        "name": "La Roche-sur-Yon Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.7",
        "size": "medium"
    },
    {
        "iata": "LDV",
        "lon": "-4.1",
        "iso": "FR",
        "status": 1,
        "name": "Landivisiau Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "48.5",
        "size": "medium"
    },
    {
        "iata": "CFR",
        "lon": "-0.459276",
        "iso": "FR",
        "status": 1,
        "name": "Caen-Carpiquet Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.183437",
        "size": "medium"
    },
    {
        "iata": "LME",
        "lon": "1.25",
        "iso": "FR",
        "status": 1,
        "name": "Le Mans-Arnage Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.0",
        "size": "medium"
    },
    {
        "iata": "RNS",
        "lon": "-1.726249",
        "iso": "FR",
        "status": 1,
        "name": "Rennes-Saint-Jacques Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.068066",
        "size": "medium"
    },
    {
        "iata": "LAI",
        "lon": "-3.481944",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.754166",
        "size": "medium"
    },
    {
        "iata": "UIP",
        "lon": "-4.170833",
        "iso": "FR",
        "status": 1,
        "name": "Quimper-Cornouaille Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.974445",
        "size": "medium"
    },
    {
        "iata": "NTE",
        "lon": "-1.601402",
        "iso": "FR",
        "status": 1,
        "name": "Nantes Atlantique Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.157623",
        "size": "medium"
    },
    {
        "iata": "SBK",
        "lon": "-2.808889",
        "iso": "FR",
        "status": 1,
        "name": "Saint-Brieuc-Armor Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.513058",
        "size": "medium"
    },
    {
        "iata": "MXN",
        "lon": "-3.816667",
        "iso": "FR",
        "status": 1,
        "name": "Morlaix-Ploujean Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.6",
        "size": "medium"
    },
    {
        "iata": "VNE",
        "lon": "-2.766667",
        "iso": "FR",
        "status": 1,
        "name": "Vannes-Meucon Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.65",
        "size": "medium"
    },
    {
        "iata": "SNR",
        "lon": "-2.153864",
        "iso": "FR",
        "status": 1,
        "name": "Saint-Nazaire-Montoir Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.312046",
        "size": "medium"
    },
    {
        "iata": "QBQ",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BSL",
        "lon": "7.532604",
        "iso": "FR",
        "status": 1,
        "name": "EuroAirport Basel-Mulhouse-Freiburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.59961",
        "size": "medium"
    },
    {
        "iata": "DIJ",
        "lon": "5.088889",
        "iso": "FR",
        "status": 1,
        "name": "Dijon-Bourgogne Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.270832",
        "size": "medium"
    },
    {
        "iata": "MZM",
        "lon": "6.134444",
        "iso": "FR",
        "status": 1,
        "name": "Metz-Frescaty (BA 128) Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "49.0725",
        "size": "medium"
    },
    {
        "iata": "EPL",
        "lon": "6.069444",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.325832",
        "size": "medium"
    },
    {
        "iata": "XMF",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ENC",
        "lon": "6.231667",
        "iso": "FR",
        "status": 1,
        "name": "Nancy-Essey Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.691666",
        "size": "medium"
    },
    {
        "iata": "BOR",
        "lon": "7.011667",
        "iso": "FR",
        "status": 1,
        "name": "Fontaine Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.655556",
        "size": "small"
    },
    {
        "iata": "RHE",
        "lon": "4.050556",
        "iso": "FR",
        "status": 1,
        "name": "Reims-Champagne (BA 112) Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.310833",
        "size": "medium"
    },
    {
        "iata": "SXB",
        "lon": "7.627674",
        "iso": "FR",
        "status": 1,
        "name": "Strasbourg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.544876",
        "size": "medium"
    },
    {
        "iata": "VTL",
        "lon": "5.95",
        "iso": "FR",
        "status": 1,
        "name": "Vittel Champ De Course Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.2",
        "size": "small"
    },
    {
        "iata": "XHE",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "FNI",
        "lon": "4.416667",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.75",
        "size": "medium"
    },
    {
        "iata": "LTT",
        "lon": "6.633333",
        "iso": "FR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.283333",
        "size": "small"
    },
    {
        "iata": "MQC",
        "lon": "-56.333332",
        "iso": "PM",
        "status": 1,
        "name": "Miquelon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "47.05",
        "size": "medium"
    },
    {
        "iata": "FSP",
        "lon": "-56.166668",
        "iso": "PM",
        "status": 1,
        "name": "St Pierre Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "46.916668",
        "size": "medium"
    },
    {
        "iata": "PYR",
        "lon": "21.466667",
        "iso": "GR",
        "status": 1,
        "name": "Andravida Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.683334",
        "size": "medium"
    },
    {
        "iata": "AGQ",
        "lon": "21.351944",
        "iso": "GR",
        "status": 1,
        "name": "Agrinion Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.604168",
        "size": "small"
    },
    {
        "iata": "AXD",
        "lon": "25.944893",
        "iso": "GR",
        "status": 1,
        "name": "Dimokritos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.856785",
        "size": "medium"
    },
    {
        "iata": "HEW",
        "lon": "23.814444",
        "iso": "GR",
        "status": 1,
        "name": "Athen Helenikon Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.947224",
        "size": "small"
    },
    {
        "iata": "ATH",
        "lon": "23.946486",
        "iso": "GR",
        "status": 1,
        "name": "Eleftherios Venizelos International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.93635",
        "size": "large"
    },
    {
        "iata": "VOL",
        "lon": "22.93111",
        "iso": "GR",
        "status": 1,
        "name": "Nea Anchialos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.38333",
        "size": "medium"
    },
    {
        "iata": "JKH",
        "lon": "26.142336",
        "iso": "GR",
        "status": 1,
        "name": "Chios Island National Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.345795",
        "size": "medium"
    },
    {
        "iata": "PKH",
        "lon": "23.147223",
        "iso": "GR",
        "status": 1,
        "name": "Porto Cheli Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.301388",
        "size": "small"
    },
    {
        "iata": "JIK",
        "lon": "26.333332",
        "iso": "GR",
        "status": 1,
        "name": "Ikaria Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.666668",
        "size": "medium"
    },
    {
        "iata": "IOA",
        "lon": "20.825832",
        "iso": "GR",
        "status": 1,
        "name": "Ioannina Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.696667",
        "size": "medium"
    },
    {
        "iata": "HER",
        "lon": "25.174192",
        "iso": "GR",
        "status": 1,
        "name": "Heraklion International Nikos Kazantzakis Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.33663",
        "size": "large"
    },
    {
        "iata": "KSO",
        "lon": "21.273333",
        "iso": "GR",
        "status": 1,
        "name": "Kastoria National Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.450832",
        "size": "medium"
    },
    {
        "iata": "KIT",
        "lon": "23.025",
        "iso": "GR",
        "status": 1,
        "name": "Kithira Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.291668",
        "size": "medium"
    },
    {
        "iata": "EFL",
        "lon": "20.505556",
        "iso": "GR",
        "status": 1,
        "name": "Kefallinia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.118057",
        "size": "medium"
    },
    {
        "iata": "KZS",
        "lon": "29.576376",
        "iso": "GR",
        "status": 1,
        "name": "Kastelorizo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.14167",
        "size": "small"
    },
    {
        "iata": "KLX",
        "lon": "22.027779",
        "iso": "GR",
        "status": 1,
        "name": "Kalamata Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.069443",
        "size": "medium"
    },
    {
        "iata": "KGS",
        "lon": "27.095278",
        "iso": "GR",
        "status": 1,
        "name": "Kos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.793056",
        "size": "medium"
    },
    {
        "iata": "AOK",
        "lon": "27.146729",
        "iso": "GR",
        "status": 1,
        "name": "Karpathos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.420685",
        "size": "medium"
    },
    {
        "iata": "CFU",
        "lon": "19.914644",
        "iso": "GR",
        "status": 1,
        "name": "Ioannis Kapodistrias International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.60784",
        "size": "medium"
    },
    {
        "iata": "KSJ",
        "lon": "26.916668",
        "iso": "GR",
        "status": 1,
        "name": "Kasos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.42139",
        "size": "small"
    },
    {
        "iata": "KVA",
        "lon": "24.619675",
        "iso": "GR",
        "status": 1,
        "name": "Alexander the Great International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.912994",
        "size": "medium"
    },
    {
        "iata": "KZI",
        "lon": "21.841944",
        "iso": "GR",
        "status": 1,
        "name": "Filippos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.288612",
        "size": "medium"
    },
    {
        "iata": "LRS",
        "lon": "26.783333",
        "iso": "GR",
        "status": 1,
        "name": "Leros Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.516666",
        "size": "small"
    },
    {
        "iata": "LXS",
        "lon": "25.233446",
        "iso": "GR",
        "status": 1,
        "name": "Limnos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.923904",
        "size": "medium"
    },
    {
        "iata": "LRA",
        "lon": "22.463888",
        "iso": "GR",
        "status": 1,
        "name": "Larisa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.65111",
        "size": "medium"
    },
    {
        "iata": "JMK",
        "lon": "25.344444",
        "iso": "GR",
        "status": 1,
        "name": "Mikonos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.43611",
        "size": "medium"
    },
    {
        "iata": "MLO",
        "lon": "24.475",
        "iso": "GR",
        "status": 1,
        "name": "Milos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.69611",
        "size": "small"
    },
    {
        "iata": "MJT",
        "lon": "26.599443",
        "iso": "GR",
        "status": 1,
        "name": "Mytilene International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.05611",
        "size": "medium"
    },
    {
        "iata": "JNX",
        "lon": "25.383333",
        "iso": "GR",
        "status": 1,
        "name": "Naxos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.1",
        "size": "small"
    },
    {
        "iata": "PAS",
        "lon": "25.1",
        "iso": "GR",
        "status": 1,
        "name": "Paros Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.066666",
        "size": "small"
    },
    {
        "iata": "JTY",
        "lon": "26.366667",
        "iso": "GR",
        "status": 1,
        "name": "Astypalaia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.566666",
        "size": "medium"
    },
    {
        "iata": "PVK",
        "lon": "20.765833",
        "iso": "GR",
        "status": 1,
        "name": "Aktion National Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.926388",
        "size": "medium"
    },
    {
        "iata": "RHO",
        "lon": "28.090677",
        "iso": "GR",
        "status": 1,
        "name": "Diagoras Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.401867",
        "size": "medium"
    },
    {
        "iata": "GPA",
        "lon": "21.733334",
        "iso": "GR",
        "status": 1,
        "name": "Araxos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.25",
        "size": "medium"
    },
    {
        "iata": "CHQ",
        "lon": "24.140373",
        "iso": "GR",
        "status": 1,
        "name": "Chania International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.5402",
        "size": "medium"
    },
    {
        "iata": "JSI",
        "lon": "23.505556",
        "iso": "GR",
        "status": 1,
        "name": "Skiathos Island National Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.180557",
        "size": "medium"
    },
    {
        "iata": "SMI",
        "lon": "26.914417",
        "iso": "GR",
        "status": 1,
        "name": "Samos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.69145",
        "size": "medium"
    },
    {
        "iata": "JSY",
        "lon": "24.95",
        "iso": "GR",
        "status": 1,
        "name": "Syros Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.42361",
        "size": "medium"
    },
    {
        "iata": "SPJ",
        "lon": "22.533333",
        "iso": "GR",
        "status": 1,
        "name": "Sparti Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.983334",
        "size": "small"
    },
    {
        "iata": "JTR",
        "lon": "25.475983",
        "iso": "GR",
        "status": 1,
        "name": "Santorini Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.404263",
        "size": "medium"
    },
    {
        "iata": "JSH",
        "lon": "26.116667",
        "iso": "GR",
        "status": 1,
        "name": "Sitia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.216667",
        "size": "medium"
    },
    {
        "iata": "SKU",
        "lon": "24.566668",
        "iso": "GR",
        "status": 1,
        "name": "Skiros Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.916668",
        "size": "medium"
    },
    {
        "iata": "SKG",
        "lon": "22.972221",
        "iso": "GR",
        "status": 1,
        "name": "Thessaloniki Macedonia International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.520832",
        "size": "large"
    },
    {
        "iata": "ZTH",
        "lon": "20.88471",
        "iso": "GR",
        "status": 1,
        "name": "Dionysios Solomos Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.753506",
        "size": "medium"
    },
    {
        "iata": "BUD",
        "lon": "19.261621",
        "iso": "HU",
        "status": 1,
        "name": "Budapest Listz Ferenc international Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.433037",
        "size": "large"
    },
    {
        "iata": "DEB",
        "lon": "21.7",
        "iso": "HU",
        "status": 1,
        "name": "Debrecen International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.55",
        "size": "medium"
    },
    {
        "iata": "MCQ",
        "lon": "20.833332",
        "iso": "HU",
        "status": 1,
        "name": "Miskolc Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.11667",
        "size": "small"
    },
    {
        "iata": "PEV",
        "lon": "170.6",
        "iso": "HU",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "69.78333",
        "size": "medium"
    },
    {
        "iata": "QGY",
        "iso": "HU",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SOB",
        "lon": "17.240444",
        "iso": "HU",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.71347",
        "size": "medium"
    },
    {
        "iata": "TZR",
        "lon": "-83.17194",
        "iso": "HU",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "39.90817",
        "size": "medium"
    },
    {
        "iata": "QZD",
        "iso": "HU",
        "status": 1,
        "name": "Szeged Glider Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CRV",
        "lon": "17.080778",
        "iso": "IT",
        "status": 1,
        "name": "Crotone Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.996304",
        "size": "medium"
    },
    {
        "iata": "BRI",
        "lon": "16.76391",
        "iso": "IT",
        "status": 1,
        "name": "Bari / Palese International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.13388",
        "size": "large"
    },
    {
        "iata": "FOG",
        "lon": "15.544367",
        "iso": "IT",
        "status": 1,
        "name": "Foggia / Gino Lisa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.43399",
        "size": "medium"
    },
    {
        "iata": "TAR",
        "lon": "17.401943",
        "iso": "IT",
        "status": 1,
        "name": "Taranto / Grottaglie Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.517776",
        "size": "medium"
    },
    {
        "iata": "LCC",
        "lon": "18.133333",
        "iso": "IT",
        "status": 1,
        "name": "Lecce Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.24278",
        "size": "medium"
    },
    {
        "iata": "PSR",
        "lon": "14.187222",
        "iso": "IT",
        "status": 1,
        "name": "Pescara International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.43722",
        "size": "medium"
    },
    {
        "iata": "BDS",
        "lon": "17.939053",
        "iso": "IT",
        "status": 1,
        "name": "Brindisi / Casale Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.657993",
        "size": "medium"
    },
    {
        "iata": "SUF",
        "lon": "16.244972",
        "iso": "IT",
        "status": 1,
        "name": "Lamezia Terme Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.910015",
        "size": "medium"
    },
    {
        "iata": "CIY",
        "lon": "14.609152",
        "iso": "IT",
        "status": 1,
        "name": "Comiso Airport \"\"Vincenzo Magliocco\"\"",
        "continent": "EU",
        "type": "airport",
        "lat": "36.99621",
        "size": "medium"
    },
    {
        "iata": "CTA",
        "lon": "15.065877",
        "iso": "IT",
        "status": 1,
        "name": "Catania / Fontanarossa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.47066",
        "size": "large"
    },
    {
        "iata": "LMP",
        "lon": "12.616667",
        "iso": "IT",
        "status": 1,
        "name": "Lampedusa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.5",
        "size": "medium"
    },
    {
        "iata": "PNL",
        "lon": "11.966111",
        "iso": "IT",
        "status": 1,
        "name": "Pantelleria Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.81361",
        "size": "medium"
    },
    {
        "iata": "PMO",
        "lon": "13.104779",
        "iso": "IT",
        "status": 1,
        "name": "Palermo / Punta Raisi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.186523",
        "size": "large"
    },
    {
        "iata": "REG",
        "lon": "15.650833",
        "iso": "IT",
        "status": 1,
        "name": "Reggio Calabria Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.07528",
        "size": "medium"
    },
    {
        "iata": "TPS",
        "lon": "12.495865",
        "iso": "IT",
        "status": 1,
        "name": "Trapani / Birgi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.901386",
        "size": "medium"
    },
    {
        "iata": "NSY",
        "lon": "14.933333",
        "iso": "IT",
        "status": 1,
        "name": "Sigonella Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.4",
        "size": "medium"
    },
    {
        "iata": "BLX",
        "lon": "12.216667",
        "iso": "IT",
        "status": 1,
        "name": "Belluno Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.15",
        "size": "small"
    },
    {
        "iata": "RAN",
        "lon": "12.223333",
        "iso": "IT",
        "status": 1,
        "name": "Ravenna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.36667",
        "size": "medium"
    },
    {
        "iata": "ZIA",
        "iso": "IT",
        "status": 1,
        "name": "Trento / Mattarello Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "AHO",
        "lon": "8.295891",
        "iso": "IT",
        "status": 1,
        "name": "Alghero / Fertilia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.630405",
        "size": "medium"
    },
    {
        "iata": "DCI",
        "lon": "8.966667",
        "iso": "IT",
        "status": 1,
        "name": "Decimomannu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.35",
        "size": "medium"
    },
    {
        "iata": "CAG",
        "lon": "9.060673",
        "iso": "IT",
        "status": 1,
        "name": "Cagliari / Elmas Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.254333",
        "size": "large"
    },
    {
        "iata": "OLB",
        "lon": "9.514823",
        "iso": "IT",
        "status": 1,
        "name": "Olbia / Costa Smeralda Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.903145",
        "size": "medium"
    },
    {
        "iata": "TTB",
        "lon": "9.683333",
        "iso": "IT",
        "status": 1,
        "name": "Tortoli' / Arbatax Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.916668",
        "size": "medium"
    },
    {
        "iata": "QVA",
        "iso": "IT",
        "status": 1,
        "name": "Varese / Venegono Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "QMM",
        "lon": "10.146547",
        "iso": "IT",
        "status": 1,
        "name": "Massa Cinquale Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.987595",
        "size": "small"
    },
    {
        "iata": "MXP",
        "lon": "8.71237",
        "iso": "IT",
        "status": 1,
        "name": "Malpensa International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.627403",
        "size": "large"
    },
    {
        "iata": "BGY",
        "lon": "9.698713",
        "iso": "IT",
        "status": 1,
        "name": "Bergamo / Orio Al Serio Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.665314",
        "size": "large"
    },
    {
        "iata": "TRN",
        "lon": "7.643049",
        "iso": "IT",
        "status": 1,
        "name": "Torino / Caselle International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.191456",
        "size": "large"
    },
    {
        "iata": "ALL",
        "lon": "8.216667",
        "iso": "IT",
        "status": 1,
        "name": "Villanova D'Albenga International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.05",
        "size": "medium"
    },
    {
        "iata": "GOA",
        "lon": "8.85081",
        "iso": "IT",
        "status": 1,
        "name": "Genova / Sestri Cristoforo Colombo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.415066",
        "size": "large"
    },
    {
        "iata": "LIN",
        "lon": "9.279157",
        "iso": "IT",
        "status": 1,
        "name": "Linate Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.460957",
        "size": "large"
    },
    {
        "iata": "PMF",
        "lon": "10.295278",
        "iso": "IT",
        "status": 1,
        "name": "Parma Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.822224",
        "size": "medium"
    },
    {
        "iata": "QPZ",
        "iso": "IT",
        "status": 1,
        "name": "Piacenza Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "AOT",
        "lon": "7.3625",
        "iso": "IT",
        "status": 1,
        "name": "Aosta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.73861",
        "size": "medium"
    },
    {
        "iata": "CUF",
        "lon": "7.6175",
        "iso": "IT",
        "status": 1,
        "name": "Cuneo / Levaldigi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.53528",
        "size": "medium"
    },
    {
        "iata": "AVB",
        "lon": "12.6",
        "iso": "IT",
        "status": 1,
        "name": "Aviano Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "46.033333",
        "size": "medium"
    },
    {
        "iata": "BZO",
        "lon": "11.330511",
        "iso": "IT",
        "status": 1,
        "name": "Bolzano Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.462444",
        "size": "medium"
    },
    {
        "iata": "UDN",
        "lon": "13.183333",
        "iso": "IT",
        "status": 1,
        "name": "Udine / Campoformido Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "46.033333",
        "size": "small"
    },
    {
        "iata": "BLQ",
        "lon": "11.293289",
        "iso": "IT",
        "status": 1,
        "name": "Bologna / Borgo Panigale Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.529266",
        "size": "large"
    },
    {
        "iata": "TSF",
        "lon": "12.204444",
        "iso": "IT",
        "status": 1,
        "name": "Treviso / Sant'Angelo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.655113",
        "size": "large"
    },
    {
        "iata": "FRL",
        "lon": "12.07076",
        "iso": "IT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "44.19857",
        "size": "medium"
    },
    {
        "iata": "VBS",
        "lon": "10.326545",
        "iso": "IT",
        "status": 1,
        "name": "Brescia / Montichiari Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.425446",
        "size": "medium"
    },
    {
        "iata": "TRS",
        "lon": "13.485678",
        "iso": "IT",
        "status": 1,
        "name": "Trieste / Ronchi Dei Legionari",
        "continent": "EU",
        "type": "airport",
        "lat": "45.82078",
        "size": "medium"
    },
    {
        "iata": "RMI",
        "lon": "12.619594",
        "iso": "IT",
        "status": 1,
        "name": "Rimini / Miramare - Federico Fellini International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.022953",
        "size": "medium"
    },
    {
        "iata": "VIC",
        "lon": "11.55",
        "iso": "IT",
        "status": 1,
        "name": "Vicenza Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.55",
        "size": "medium"
    },
    {
        "iata": "QPA",
        "iso": "IT",
        "status": 1,
        "name": "Padova Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "VRN",
        "lon": "10.906796",
        "iso": "IT",
        "status": 1,
        "name": "Verona / Villafranca Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.40233",
        "size": "large"
    },
    {
        "iata": "AOI",
        "lon": "13.355723",
        "iso": "IT",
        "status": 1,
        "name": "Ancona / Falconara Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.60691",
        "size": "medium"
    },
    {
        "iata": "VCE",
        "lon": "12.337947",
        "iso": "IT",
        "status": 1,
        "name": "Venezia / Tessera -  Marco Polo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.502285",
        "size": "large"
    },
    {
        "iata": "QZO",
        "iso": "IT",
        "status": 1,
        "name": "Arezzo Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LCV",
        "lon": "10.583333",
        "iso": "IT",
        "status": 1,
        "name": "Lucca / Tassignano Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.833332",
        "size": "small"
    },
    {
        "iata": "QRT",
        "iso": "IT",
        "status": 1,
        "name": "Rieti Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "SAY",
        "lon": "11.25",
        "iso": "IT",
        "status": 1,
        "name": "Siena / Ampugnano Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.25",
        "size": "medium"
    },
    {
        "iata": "CIA",
        "lon": "12.590987",
        "iso": "IT",
        "status": 1,
        "name": "Ciampino Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.799065",
        "size": "large"
    },
    {
        "iata": "FCO",
        "lon": "12.250346",
        "iso": "IT",
        "status": 1,
        "name": "Leonardo Da Vinci (Fiumicino) International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.794594",
        "size": "large"
    },
    {
        "iata": "QFR",
        "iso": "IT",
        "status": 1,
        "name": "Frosinone Military Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "QSR",
        "lon": "14.92084",
        "iso": "IT",
        "status": 1,
        "name": "Salerno / Pontecagnano Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.620148",
        "size": "medium"
    },
    {
        "iata": "EBA",
        "lon": "10.240409",
        "iso": "IT",
        "status": 1,
        "name": "Marina Di Campo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.763103",
        "size": "medium"
    },
    {
        "iata": "QLT",
        "iso": "IT",
        "status": 1,
        "name": "Latina Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "NAP",
        "lon": "14.291667",
        "iso": "IT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "40.886112",
        "size": "large"
    },
    {
        "iata": "PSA",
        "lon": "10.399915",
        "iso": "IT",
        "status": 1,
        "name": "Pisa / San Giusto - Galileo Galilei International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.69871",
        "size": "large"
    },
    {
        "iata": "FLR",
        "lon": "11.201989",
        "iso": "IT",
        "status": 1,
        "name": "Firenze / Peretola Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.802128",
        "size": "medium"
    },
    {
        "iata": "GRS",
        "lon": "11.070556",
        "iso": "IT",
        "status": 1,
        "name": "Grosseto Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.761944",
        "size": "medium"
    },
    {
        "iata": "PEG",
        "lon": "12.366667",
        "iso": "IT",
        "status": 1,
        "name": "Perugia / San Egidio Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.13333",
        "size": "medium"
    },
    {
        "iata": "LJU",
        "lon": "14.454972",
        "iso": "SI",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.23102",
        "size": "large"
    },
    {
        "iata": "MBX",
        "lon": "15.686741",
        "iso": "SI",
        "status": 1,
        "name": "Maribor Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.4785",
        "size": "medium"
    },
    {
        "iata": "POW",
        "lon": "13.583333",
        "iso": "SI",
        "status": 1,
        "name": "Portoroz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.516666",
        "size": "medium"
    },
    {
        "iata": "GTW",
        "lon": "17.516666",
        "iso": "CZ",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.2",
        "size": "small"
    },
    {
        "iata": "UHE",
        "lon": "17.45",
        "iso": "CZ",
        "status": 1,
        "name": "Kunovice Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.066666",
        "size": "medium"
    },
    {
        "iata": "KLV",
        "lon": "12.916667",
        "iso": "CZ",
        "status": 1,
        "name": "Karlovy Vary International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.2",
        "size": "medium"
    },
    {
        "iata": "MKA",
        "lon": "12.716667",
        "iso": "CZ",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.983334",
        "size": "small"
    },
    {
        "iata": "OSR",
        "lon": "18.121284",
        "iso": "CZ",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.69552",
        "size": "medium"
    },
    {
        "iata": "PED",
        "lon": "15.738611",
        "iso": "CZ",
        "status": 1,
        "name": "Pardubice Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.013332",
        "size": "medium"
    },
    {
        "iata": "PRV",
        "lon": "17.208332",
        "iso": "CZ",
        "status": 1,
        "name": "P?erov Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "49.59028",
        "size": "medium"
    },
    {
        "iata": "PRG",
        "lon": "14.266638",
        "iso": "CZ",
        "status": 1,
        "name": "Ruzyn? International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.10619",
        "size": "large"
    },
    {
        "iata": "BRQ",
        "lon": "16.7",
        "iso": "CZ",
        "status": 1,
        "name": "Brno-Tu?any Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.15",
        "size": "medium"
    },
    {
        "iata": "ZBE",
        "lon": "16.866667",
        "iso": "CZ",
        "status": 1,
        "name": "Zabreh Ostrava Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.88333",
        "size": "small"
    },
    {
        "iata": "GHK",
        "iso": "PS",
        "status": 0,
        "name": "Gush Katif Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "TLV",
        "lon": "34.870743",
        "iso": "IL",
        "status": 1,
        "name": "Ben Gurion International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.000454",
        "size": "large"
    },
    {
        "iata": "BEV",
        "lon": "34.8",
        "iso": "IL",
        "status": 1,
        "name": "Be'er Sheva (Teyman) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.25",
        "size": "small"
    },
    {
        "iata": "ETH",
        "lon": "34.95357",
        "iso": "IL",
        "status": 1,
        "name": "Eilat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.555616",
        "size": "medium"
    },
    {
        "iata": "EIY",
        "lon": "35.183334",
        "iso": "IL",
        "status": 1,
        "name": "Ein Yahav Airfield",
        "continent": "AS",
        "type": "airport",
        "lat": "30.633333",
        "size": "small"
    },
    {
        "iata": "HFA",
        "lon": "35.044594",
        "iso": "IL",
        "status": 1,
        "name": "Haifa International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.811466",
        "size": "medium"
    },
    {
        "iata": "RPN",
        "lon": "35.57111",
        "iso": "IL",
        "status": 1,
        "name": "Ben Ya'akov Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.981667",
        "size": "medium"
    },
    {
        "iata": "JRS",
        "iso": "PS",
        "status": 0,
        "name": "Atarot Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "KSW",
        "lon": "35.566666",
        "iso": "IL",
        "status": 1,
        "name": "Kiryat Shmona Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.216667",
        "size": "small"
    },
    {
        "iata": "MTZ",
        "lon": "35.75",
        "iso": "IL",
        "status": 1,
        "name": "Bar Yehuda Airfield",
        "continent": "AS",
        "type": "airport",
        "lat": "33.233334",
        "size": "medium"
    },
    {
        "iata": "VTM",
        "iso": "IL",
        "status": 1,
        "name": "Nevatim Air Base",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "VDA",
        "lon": "34.9339",
        "iso": "IL",
        "status": 1,
        "name": "Ovda International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.952015",
        "size": "large"
    },
    {
        "iata": "MIP",
        "lon": "34.8",
        "iso": "IL",
        "status": 1,
        "name": "Ramon Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "30.6",
        "size": "medium"
    },
    {
        "iata": "SDV",
        "lon": "34.787857",
        "iso": "IL",
        "status": 1,
        "name": "Sde Dov Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.104885",
        "size": "medium"
    },
    {
        "iata": "YOT",
        "lon": "35.066666",
        "iso": "IL",
        "status": 1,
        "name": "Yotvata Airfield",
        "continent": "AS",
        "type": "airport",
        "lat": "29.9",
        "size": "small"
    },
    {
        "iata": "GZM",
        "iso": "MT",
        "status": 1,
        "name": "Xewkija Heliport",
        "continent": "EU",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "MLA",
        "lon": "14.495401",
        "iso": "MT",
        "status": 1,
        "name": "Luqa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "35.849777",
        "size": "large"
    },
    {
        "iata": "HOH",
        "lon": "9.7",
        "iso": "AT",
        "status": 1,
        "name": "Hohenems-Dornbirn Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.38333",
        "size": "small"
    },
    {
        "iata": "LOR",
        "iso": "US",
        "status": 1,
        "name": "Lowe AHP (Fort Rucker) Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "GRZ",
        "lon": "15.444928",
        "iso": "AT",
        "status": 1,
        "name": "Graz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.994125",
        "size": "medium"
    },
    {
        "iata": "INN",
        "lon": "11.351467",
        "iso": "AT",
        "status": 1,
        "name": "Innsbruck Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.25745",
        "size": "medium"
    },
    {
        "iata": "KLU",
        "lon": "14.324192",
        "iso": "AT",
        "status": 1,
        "name": "Klagenfurt Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.650124",
        "size": "medium"
    },
    {
        "iata": "LNZ",
        "lon": "14.193345",
        "iso": "AT",
        "status": 1,
        "name": "Linz Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.23987",
        "size": "medium"
    },
    {
        "iata": "SZG",
        "lon": "12.997331",
        "iso": "AT",
        "status": 1,
        "name": "Salzburg Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.791225",
        "size": "medium"
    },
    {
        "iata": "VIE",
        "lon": "16.563583",
        "iso": "AT",
        "status": 1,
        "name": "Vienna International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.11972",
        "size": "large"
    },
    {
        "iata": "SMA",
        "lon": "-25.1",
        "iso": "PT",
        "status": 1,
        "name": "Santa Maria Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.966667",
        "size": "medium"
    },
    {
        "iata": "BGC",
        "lon": "-6.75",
        "iso": "PT",
        "status": 1,
        "name": "Braganca Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.816666",
        "size": "medium"
    },
    {
        "iata": "BYJ",
        "iso": "PT",
        "status": 1,
        "name": "Beja International Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "BGZ",
        "lon": "-8.433333",
        "iso": "PT",
        "status": 1,
        "name": "Braga Municipal Aerodrome",
        "continent": "EU",
        "type": "airport",
        "lat": "41.55",
        "size": "medium"
    },
    {
        "iata": "CHV",
        "lon": "-7.466667",
        "iso": "PT",
        "status": 1,
        "name": "Chaves Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.733334",
        "size": "small"
    },
    {
        "iata": "CBP",
        "lon": "-8.416667",
        "iso": "PT",
        "status": 1,
        "name": "Coimbra Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.2",
        "size": "medium"
    },
    {
        "iata": "CVU",
        "lon": "-31.1",
        "iso": "PT",
        "status": 1,
        "name": "Corvo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.7",
        "size": "small"
    },
    {
        "iata": "COV",
        "lon": "-7.5",
        "iso": "PT",
        "status": 1,
        "name": "Covilha Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.283333",
        "size": "medium"
    },
    {
        "iata": "FLW",
        "lon": "-31.15",
        "iso": "PT",
        "status": 1,
        "name": "Flores Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.466667",
        "size": "medium"
    },
    {
        "iata": "FAO",
        "lon": "-7.968545",
        "iso": "PT",
        "status": 1,
        "name": "Faro Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.020645",
        "size": "large"
    },
    {
        "iata": "GRW",
        "lon": "-28.027958",
        "iso": "PT",
        "status": 1,
        "name": "Graciosa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "39.090954",
        "size": "medium"
    },
    {
        "iata": "HOR",
        "lon": "-28.717222",
        "iso": "PT",
        "status": 1,
        "name": "Horta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.52",
        "size": "medium"
    },
    {
        "iata": "TER",
        "lon": "-27.08757",
        "iso": "PT",
        "status": 1,
        "name": "Lajes Field",
        "continent": "EU",
        "type": "airport",
        "lat": "38.754074",
        "size": "large"
    },
    {
        "iata": "FNC",
        "lon": "-16.775635",
        "iso": "PT",
        "status": 1,
        "name": "Madeira Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "32.693123",
        "size": "medium"
    },
    {
        "iata": "PAF",
        "lon": "31.554146",
        "iso": "PT",
        "status": 1,
        "name": "Montijo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "2.203072",
        "size": "medium"
    },
    {
        "iata": "PDL",
        "lon": "-25.696468",
        "iso": "PT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "37.743847",
        "size": "large"
    },
    {
        "iata": "PIX",
        "lon": "-28.3",
        "iso": "PT",
        "status": 1,
        "name": "Pico Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.466667",
        "size": "medium"
    },
    {
        "iata": "PRM",
        "lon": "-8.533333",
        "iso": "PT",
        "status": 1,
        "name": "Portimao Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.13333",
        "size": "medium"
    },
    {
        "iata": "OPO",
        "lon": "-8.670272",
        "iso": "PT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "41.237774",
        "size": "large"
    },
    {
        "iata": "PXO",
        "lon": "-16.345015",
        "iso": "PT",
        "status": 1,
        "name": "Porto Santo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "33.070023",
        "size": "medium"
    },
    {
        "iata": "LIS",
        "lon": "-9.128165",
        "iso": "PT",
        "status": 1,
        "name": "Lisbon Portela Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "38.770042",
        "size": "large"
    },
    {
        "iata": "SIE",
        "lon": "-8.85",
        "iso": "PT",
        "status": 1,
        "name": "Sines Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "37.933334",
        "size": "small"
    },
    {
        "iata": "SJZ",
        "lon": "-28.16843",
        "iso": "PT",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "38.664616",
        "size": "medium"
    },
    {
        "iata": "VRL",
        "lon": "-7.75",
        "iso": "PT",
        "status": 1,
        "name": "Vila Real Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.3",
        "size": "medium"
    },
    {
        "iata": "VSE",
        "lon": "-7.916667",
        "iso": "PT",
        "status": 1,
        "name": "Viseu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "40.65",
        "size": "medium"
    },
    {
        "iata": "BNX",
        "lon": "17.3",
        "iso": "BA",
        "status": 1,
        "name": "Banja Luka International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.933334",
        "size": "medium"
    },
    {
        "iata": "OMO",
        "lon": "17.846666",
        "iso": "BA",
        "status": 1,
        "name": "Mostar International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.285557",
        "size": "medium"
    },
    {
        "iata": "SJJ",
        "lon": "18.336065",
        "iso": "BA",
        "status": 1,
        "name": "Sarajevo International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.826687",
        "size": "large"
    },
    {
        "iata": "TZL",
        "lon": "18.709167",
        "iso": "BA",
        "status": 1,
        "name": "Tuzla International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.40972",
        "size": "medium"
    },
    {
        "iata": "ARW",
        "lon": "21.266666",
        "iso": "RO",
        "status": 1,
        "name": "Arad International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.183334",
        "size": "medium"
    },
    {
        "iata": "BCM",
        "lon": "26.881945",
        "iso": "RO",
        "status": 1,
        "name": "Bac?u Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.59861",
        "size": "medium"
    },
    {
        "iata": "BAY",
        "lon": "23.466667",
        "iso": "RO",
        "status": 1,
        "name": "Tautii Magheraus Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.65",
        "size": "medium"
    },
    {
        "iata": "BBU",
        "lon": "26.080996",
        "iso": "RO",
        "status": 1,
        "name": "B?neasa International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.495934",
        "size": "medium"
    },
    {
        "iata": "CND",
        "lon": "28.483334",
        "iso": "RO",
        "status": 1,
        "name": "Mihail Kog?lniceanu International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.35",
        "size": "medium"
    },
    {
        "iata": "CLJ",
        "lon": "23.687014",
        "iso": "RO",
        "status": 1,
        "name": "Cluj-Napoca International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.782063",
        "size": "medium"
    },
    {
        "iata": "CSB",
        "lon": "22.216667",
        "iso": "RO",
        "status": 1,
        "name": "Caransebe? Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.416668",
        "size": "medium"
    },
    {
        "iata": "CRA",
        "lon": "23.886389",
        "iso": "RO",
        "status": 1,
        "name": "Craiova Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.31889",
        "size": "medium"
    },
    {
        "iata": "IAS",
        "lon": "27.616993",
        "iso": "RO",
        "status": 1,
        "name": "Ia?i Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.176987",
        "size": "medium"
    },
    {
        "iata": "LRO",
        "iso": "US",
        "status": 0,
        "name": "Sharpe AAF",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "OMR",
        "lon": "21.903055",
        "iso": "RO",
        "status": 1,
        "name": "Oradea International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.0275",
        "size": "medium"
    },
    {
        "iata": "OTP",
        "lon": "26.077063",
        "iso": "RO",
        "status": 1,
        "name": "Henri Coand? International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.571156",
        "size": "large"
    },
    {
        "iata": "SBZ",
        "lon": "24.093529",
        "iso": "RO",
        "status": 1,
        "name": "Sibiu International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.789757",
        "size": "medium"
    },
    {
        "iata": "SUJ",
        "lon": "22.883333",
        "iso": "RO",
        "status": 1,
        "name": "Satu Mare Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.7",
        "size": "medium"
    },
    {
        "iata": "SCV",
        "lon": "26.356112",
        "iso": "RO",
        "status": 1,
        "name": "Suceava Stefan cel Mare Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.689445",
        "size": "medium"
    },
    {
        "iata": "TCE",
        "lon": "28.816668",
        "iso": "RO",
        "status": 1,
        "name": "Tulcea Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.183334",
        "size": "medium"
    },
    {
        "iata": "TGM",
        "lon": "24.533333",
        "iso": "RO",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "46.533333",
        "size": "medium"
    },
    {
        "iata": "TSR",
        "lon": "21.32012",
        "iso": "RO",
        "status": 1,
        "name": "Timi?oara Traian Vuia Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.809925",
        "size": "medium"
    },
    {
        "iata": "GVA",
        "lon": "6.105774",
        "iso": "CH",
        "status": 1,
        "name": "Geneva Cointrin International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.229633",
        "size": "large"
    },
    {
        "iata": "QNC",
        "iso": "CH",
        "status": 1,
        "name": "Neuchatel Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "SIR",
        "lon": "7.333333",
        "iso": "CH",
        "status": 1,
        "name": "Sion Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.25",
        "size": "medium"
    },
    {
        "iata": "ZIN",
        "lon": "8.983056",
        "iso": "CH",
        "status": 1,
        "name": "Interlaken Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "13.779444",
        "size": "small"
    },
    {
        "iata": "LUG",
        "lon": "8.966667",
        "iso": "CH",
        "status": 1,
        "name": "Lugano Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.016666",
        "size": "medium"
    },
    {
        "iata": "BRN",
        "lon": "7.50356",
        "iso": "CH",
        "status": 1,
        "name": "Bern Belp Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.911728",
        "size": "medium"
    },
    {
        "iata": "BXO",
        "lon": "8.383333",
        "iso": "CH",
        "status": 1,
        "name": "Buochs Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.966667",
        "size": "small"
    },
    {
        "iata": "ACO",
        "iso": "CH",
        "status": 0,
        "name": "Ascona Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "ZHI",
        "iso": "CH",
        "status": 1,
        "name": "Grenchen Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ZRH",
        "lon": "8.561746",
        "iso": "CH",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "47.450603",
        "size": "large"
    },
    {
        "iata": "ZJI",
        "iso": "CH",
        "status": 1,
        "name": "Locarno Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ACH",
        "lon": "9.566667",
        "iso": "CH",
        "status": 1,
        "name": "St Gallen Altenrhein Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.483334",
        "size": "medium"
    },
    {
        "iata": "SMV",
        "lon": "9.885556",
        "iso": "CH",
        "status": 1,
        "name": "Samedan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.53472",
        "size": "medium"
    },
    {
        "iata": "GKD",
        "iso": "TR",
        "status": 1,
        "name": "Imroz Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ESB",
        "lon": "32.993145",
        "iso": "TR",
        "status": 1,
        "name": "Esenbo?a International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.11494",
        "size": "large"
    },
    {
        "iata": "ANK",
        "lon": "32.833332",
        "iso": "TR",
        "status": 1,
        "name": "Etimesgut Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "39.983334",
        "size": "medium"
    },
    {
        "iata": "ADA",
        "lon": "35.29736",
        "iso": "TR",
        "status": 1,
        "name": "Adana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.98503",
        "size": "large"
    },
    {
        "iata": "UAB",
        "lon": "35.41833",
        "iso": "TR",
        "status": 1,
        "name": "?ncirlik Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "37.00028",
        "size": "medium"
    },
    {
        "iata": "AFY",
        "lon": "30.6",
        "iso": "TR",
        "status": 1,
        "name": "Afyon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.733334",
        "size": "medium"
    },
    {
        "iata": "AYT",
        "lon": "30.80135",
        "iso": "TR",
        "status": 1,
        "name": "Antalya International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.89928",
        "size": "large"
    },
    {
        "iata": "GZT",
        "lon": "37.473747",
        "iso": "TR",
        "status": 1,
        "name": "Gaziantep International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.944935",
        "size": "large"
    },
    {
        "iata": "ISK",
        "lon": "73.811264",
        "iso": "TR",
        "status": 1,
        "name": "?skenderun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.966942",
        "size": "small"
    },
    {
        "iata": "KFS",
        "lon": "33.794952",
        "iso": "TR",
        "status": 1,
        "name": "Kastamonu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.306446",
        "size": "small"
    },
    {
        "iata": "KYA",
        "lon": "32.56222",
        "iso": "TR",
        "status": 1,
        "name": "Konya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.979168",
        "size": "medium"
    },
    {
        "iata": "MZH",
        "lon": "35.533333",
        "iso": "TR",
        "status": 1,
        "name": "Amasya Merzifon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.88333",
        "size": "medium"
    },
    {
        "iata": "SSX",
        "lon": "36.30361",
        "iso": "TR",
        "status": 1,
        "name": "Samsun Samair Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.27833",
        "size": "small"
    },
    {
        "iata": "VAS",
        "lon": "36.904167",
        "iso": "TR",
        "status": 1,
        "name": "Sivas Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.81389",
        "size": "medium"
    },
    {
        "iata": "ONQ",
        "lon": "32.1",
        "iso": "TR",
        "status": 1,
        "name": "Zonguldak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.516666",
        "size": "small"
    },
    {
        "iata": "MLX",
        "lon": "38.253613",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "38.354443",
        "size": "medium"
    },
    {
        "iata": "ASR",
        "lon": "35.490707",
        "iso": "TR",
        "status": 1,
        "name": "Kayseri Erkilet Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.7742",
        "size": "medium"
    },
    {
        "iata": "TJK",
        "lon": "36.36778",
        "iso": "TR",
        "status": 1,
        "name": "Tokat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.302223",
        "size": "medium"
    },
    {
        "iata": "DNZ",
        "lon": "29.703333",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "37.787224",
        "size": "medium"
    },
    {
        "iata": "NAV",
        "lon": "34.716667",
        "iso": "TR",
        "status": 1,
        "name": "Nev?ehir Kapadokya International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.63333",
        "size": "large"
    },
    {
        "iata": "LTB",
        "iso": "AU",
        "status": 0,
        "name": "Latrobe Airport",
        "continent": "OC",
        "type": "closed",
        "size": null
    },
    {
        "iata": "IST",
        "lon": "28.815277",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "40.976665",
        "size": "large"
    },
    {
        "iata": "BTZ",
        "lon": "29.009722",
        "iso": "TR",
        "status": 1,
        "name": "Bursa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.234444",
        "size": "small"
    },
    {
        "iata": "BZI",
        "lon": "27.927778",
        "iso": "TR",
        "status": 1,
        "name": "Bal?kesir Merkez Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.61722",
        "size": "medium"
    },
    {
        "iata": "BDM",
        "lon": "27.977222",
        "iso": "TR",
        "status": 1,
        "name": "Band?rma Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.318333",
        "size": "medium"
    },
    {
        "iata": "CKZ",
        "lon": "26.4",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "40.13333",
        "size": "medium"
    },
    {
        "iata": "ESK",
        "lon": "30.582111",
        "iso": "TR",
        "status": 1,
        "name": "Eski?ehir Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "39.784138",
        "size": "medium"
    },
    {
        "iata": "ADB",
        "lon": "27.147594",
        "iso": "TR",
        "status": 1,
        "name": "Adnan Menderes International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.294403",
        "size": "large"
    },
    {
        "iata": "IGL",
        "lon": "27.159445",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "38.31889",
        "size": "medium"
    },
    {
        "iata": "USQ",
        "lon": "29.471945",
        "iso": "TR",
        "status": 1,
        "name": "U?ak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.68222",
        "size": "small"
    },
    {
        "iata": "KCO",
        "lon": "30.083336",
        "iso": "TR",
        "status": 1,
        "name": "Cengiz Topel Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.735027",
        "size": "medium"
    },
    {
        "iata": "YEI",
        "lon": "29.55",
        "iso": "TR",
        "status": 1,
        "name": "Bursa Yeni?ehir Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.233334",
        "size": "medium"
    },
    {
        "iata": "DLM",
        "lon": "28.794546",
        "iso": "TR",
        "status": 1,
        "name": "Dalaman International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.717552",
        "size": "large"
    },
    {
        "iata": "TEQ",
        "lon": "27.5",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "40.966667",
        "size": "medium"
    },
    {
        "iata": "BXN",
        "lon": "27.667221",
        "iso": "TR",
        "status": 1,
        "name": "Ims?k Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.134724",
        "size": "small"
    },
    {
        "iata": "AOE",
        "lon": "30.519444",
        "iso": "TR",
        "status": 1,
        "name": "Anadolu University Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.81",
        "size": "medium"
    },
    {
        "iata": "EZS",
        "lon": "39.291668",
        "iso": "TR",
        "status": 1,
        "name": "Elaz?? Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.608334",
        "size": "medium"
    },
    {
        "iata": "DIY",
        "lon": "40.20458",
        "iso": "TR",
        "status": 1,
        "name": "Diyarbakir Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.903873",
        "size": "medium"
    },
    {
        "iata": "ERC",
        "lon": "39.516945",
        "iso": "TR",
        "status": 1,
        "name": "Erzincan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.711666",
        "size": "medium"
    },
    {
        "iata": "ERZ",
        "lon": "41.17361",
        "iso": "TR",
        "status": 1,
        "name": "Erzurum International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.955555",
        "size": "large"
    },
    {
        "iata": "KSY",
        "lon": "43.085835",
        "iso": "TR",
        "status": 1,
        "name": "Kars Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.551666",
        "size": "medium"
    },
    {
        "iata": "TZX",
        "lon": "39.78168",
        "iso": "TR",
        "status": 1,
        "name": "Trabzon International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.99419",
        "size": "large"
    },
    {
        "iata": "SFQ",
        "lon": "38.85",
        "iso": "TR",
        "status": 1,
        "name": "?anl?urfa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.085278",
        "size": "medium"
    },
    {
        "iata": "VAN",
        "lon": "43.332222",
        "iso": "TR",
        "status": 1,
        "name": "Van Ferit Melen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.45861",
        "size": "medium"
    },
    {
        "iata": "BAL",
        "lon": "41.083332",
        "iso": "TR",
        "status": 1,
        "name": "Batman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.916668",
        "size": "medium"
    },
    {
        "iata": "MSR",
        "lon": "41.625",
        "iso": "TR",
        "status": 1,
        "name": "Mu? Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.725",
        "size": "medium"
    },
    {
        "iata": "SXZ",
        "lon": "41.84",
        "iso": "TR",
        "status": 1,
        "name": "Siirt Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.98",
        "size": "medium"
    },
    {
        "iata": "SIC",
        "lon": "35.06889",
        "iso": "TR",
        "status": 1,
        "name": "Sinop Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.0175",
        "size": "small"
    },
    {
        "iata": "KCM",
        "lon": "36.950558",
        "iso": "TR",
        "status": 1,
        "name": "Kahramanmara? Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.534443",
        "size": "medium"
    },
    {
        "iata": "AJI",
        "lon": "43.025",
        "iso": "TR",
        "status": 1,
        "name": "A?r? Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.65",
        "size": "medium"
    },
    {
        "iata": "ADF",
        "lon": "38.266666",
        "iso": "TR",
        "status": 1,
        "name": "Ad?yaman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.75",
        "size": "medium"
    },
    {
        "iata": "MQM",
        "lon": "40.6317",
        "iso": "TR",
        "status": 1,
        "name": "Mardin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.2233",
        "size": "medium"
    },
    {
        "iata": "GNY",
        "lon": "38.846943",
        "iso": "TR",
        "status": 1,
        "name": "?anl?urfa GAP Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.094166",
        "size": "medium"
    },
    {
        "iata": "HTY",
        "lon": "36.28057",
        "iso": "TR",
        "status": 1,
        "name": "Hatay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.364544",
        "size": "medium"
    },
    {
        "iata": "ISE",
        "lon": "30.382221",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "37.86611",
        "size": "large"
    },
    {
        "iata": "EDO",
        "lon": "27.033333",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "39.583332",
        "size": "medium"
    },
    {
        "iata": "BJV",
        "lon": "27.672781",
        "iso": "TR",
        "status": 1,
        "name": "Milas Bodrum International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.243954",
        "size": "large"
    },
    {
        "iata": "SZF",
        "lon": "36.555058",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "41.25738",
        "size": "medium"
    },
    {
        "iata": "SAW",
        "lon": "29.309189",
        "iso": "TR",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "40.904675",
        "size": "large"
    },
    {
        "iata": "GZP",
        "lon": "32.3014",
        "iso": "TR",
        "status": 1,
        "name": "Gazipa?a Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.2993",
        "size": "medium"
    },
    {
        "iata": "BZY",
        "lon": "27.933332",
        "iso": "MD",
        "status": 1,
        "name": "Balti International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.75",
        "size": "medium"
    },
    {
        "iata": "KIV",
        "lon": "28.934967",
        "iso": "MD",
        "status": 1,
        "name": "Chi?in?u International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.935444",
        "size": "medium"
    },
    {
        "iata": "GZA",
        "iso": "PS",
        "status": 0,
        "name": "Yasser Arafat International Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "OHD",
        "lon": "20.743055",
        "iso": "MK",
        "status": 1,
        "name": "Ohrid St. Paul the Apostle Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.185",
        "size": "medium"
    },
    {
        "iata": "SKP",
        "lon": "21.62872",
        "iso": "MK",
        "status": 1,
        "name": "Skopje Alexander the Great Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "41.95649",
        "size": "medium"
    },
    {
        "iata": "GIB",
        "lon": "-5.349271",
        "iso": "GI",
        "status": 1,
        "name": "Gibraltar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "36.153763",
        "size": "medium"
    },
    {
        "iata": "BCQ",
        "lon": "14.216667",
        "iso": "LY",
        "status": 1,
        "name": "Brack Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "27.683332",
        "size": "medium"
    },
    {
        "iata": "DNF",
        "lon": "22.766666",
        "iso": "LY",
        "status": 1,
        "name": "Martubah Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.55",
        "size": "small"
    },
    {
        "iata": "MRA",
        "lon": "15.066667",
        "iso": "LY",
        "status": 1,
        "name": "Misratah Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "32.4",
        "size": "small"
    },
    {
        "iata": "QUB",
        "lon": "12.820452",
        "iso": "LY",
        "status": 1,
        "name": "Ubari Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "26.568268",
        "size": "small"
    },
    {
        "iata": "UZC",
        "lon": "19.697403",
        "iso": "RS",
        "status": 1,
        "name": "Ponikve Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.898834",
        "size": "small"
    },
    {
        "iata": "BEG",
        "lon": "20.306944",
        "iso": "RS",
        "status": 1,
        "name": "Belgrade Nikola Tesla Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.819443",
        "size": "large"
    },
    {
        "iata": "LJB",
        "iso": "RS",
        "status": 1,
        "name": "Airport Lisicji Jarak",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "IVG",
        "lon": "19.862028",
        "iso": "ME",
        "status": 1,
        "name": "Berane Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.839027",
        "size": "small"
    },
    {
        "iata": "BJY",
        "lon": "20.2575",
        "iso": "RS",
        "status": 1,
        "name": "Batajnica Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "44.93528",
        "size": "medium"
    },
    {
        "iata": "GJA",
        "lon": "-85.90583",
        "iso": "KS",
        "status": 1,
        "name": "?akovica Airfield",
        "continent": "EU",
        "type": "airport",
        "lat": "16.445",
        "size": "medium"
    },
    {
        "iata": "KID",
        "lon": "14.088889",
        "iso": "RS",
        "status": 1,
        "name": "Airport Drakslerov salas Kikinda",
        "continent": "EU",
        "type": "airport",
        "lat": "55.919445",
        "size": "small"
    },
    {
        "iata": "INI",
        "lon": "21.851667",
        "iso": "RS",
        "status": 1,
        "name": "Nis Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.33389",
        "size": "medium"
    },
    {
        "iata": "QND",
        "iso": "RS",
        "status": 1,
        "name": "Cenej Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "TGD",
        "lon": "19.246023",
        "iso": "ME",
        "status": 1,
        "name": "Podgorica Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.368023",
        "size": "large"
    },
    {
        "iata": "TIV",
        "lon": "18.725555",
        "iso": "ME",
        "status": 1,
        "name": "Tivat Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.40361",
        "size": "medium"
    },
    {
        "iata": "BTS",
        "lon": "17.199799",
        "iso": "SK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.170017",
        "size": "large"
    },
    {
        "iata": "KSC",
        "lon": "21.25",
        "iso": "SK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.666668",
        "size": "medium"
    },
    {
        "iata": "LUE",
        "lon": "19.666668",
        "iso": "SK",
        "status": 1,
        "name": "Lu?enec Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.333332",
        "size": "small"
    },
    {
        "iata": "PZY",
        "lon": "17.833332",
        "iso": "SK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "48.63333",
        "size": "medium"
    },
    {
        "iata": "POV",
        "lon": "21.25",
        "iso": "SK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.0",
        "size": "small"
    },
    {
        "iata": "SLD",
        "lon": "19.133333",
        "iso": "SK",
        "status": 1,
        "name": "Slia? Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.63333",
        "size": "medium"
    },
    {
        "iata": "ILZ",
        "lon": "18.766666",
        "iso": "SK",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "49.233334",
        "size": "medium"
    },
    {
        "iata": "GLN",
        "lon": "-10.066667",
        "iso": "MA",
        "status": 1,
        "name": "Goulimime Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "29.016666",
        "size": "small"
    },
    {
        "iata": "GDT",
        "lon": "-71.13333",
        "iso": "TC",
        "status": 1,
        "name": "JAGS McCartney International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.466667",
        "size": "medium"
    },
    {
        "iata": "MDS",
        "lon": "-71.71667",
        "iso": "TC",
        "status": 1,
        "name": "Middle Caicos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.783333",
        "size": "small"
    },
    {
        "iata": "NCA",
        "lon": "-71.98333",
        "iso": "TC",
        "status": 1,
        "name": "North Caicos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.933332",
        "size": "medium"
    },
    {
        "iata": "PIC",
        "lon": "-72.1",
        "iso": "TC",
        "status": 1,
        "name": "Pine Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.883333",
        "size": "small"
    },
    {
        "iata": "PLS",
        "lon": "-72.26833",
        "iso": "TC",
        "status": 1,
        "name": "Providenciales Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.769167",
        "size": "medium"
    },
    {
        "iata": "XSC",
        "lon": "-71.529724",
        "iso": "TC",
        "status": 1,
        "name": "South Caicos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.515833",
        "size": "medium"
    },
    {
        "iata": "SLX",
        "lon": "-71.20167",
        "iso": "TC",
        "status": 1,
        "name": "Salt Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.333332",
        "size": "small"
    },
    {
        "iata": "EPS",
        "lon": "-69.5",
        "iso": "DO",
        "status": 1,
        "name": "Arroyo Barril International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.25",
        "size": "medium"
    },
    {
        "iata": "BRX",
        "lon": "-71.11667",
        "iso": "DO",
        "status": 1,
        "name": "Maria Montez International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.216667",
        "size": "medium"
    },
    {
        "iata": "CBJ",
        "lon": "-71.65",
        "iso": "DO",
        "status": 1,
        "name": "Cabo Rojo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.933332",
        "size": "medium"
    },
    {
        "iata": "AZS",
        "lon": "-69.736946",
        "iso": "DO",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "19.270555",
        "size": "small"
    },
    {
        "iata": "COZ",
        "lon": "-70.71667",
        "iso": "DO",
        "status": 1,
        "name": "Constanza Dom Re Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.9",
        "size": "small"
    },
    {
        "iata": "HEX",
        "iso": "DO",
        "status": 0,
        "name": "Herrera Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "JBQ",
        "lon": "-69.98144",
        "iso": "DO",
        "status": 1,
        "name": "La Isabela International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.575731",
        "size": "medium"
    },
    {
        "iata": "LRM",
        "lon": "-68.9",
        "iso": "DO",
        "status": 1,
        "name": "Casa De Campo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.416668",
        "size": "medium"
    },
    {
        "iata": "PUJ",
        "lon": "-68.364",
        "iso": "DO",
        "status": 1,
        "name": "Punta Cana International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.562477",
        "size": "medium"
    },
    {
        "iata": "POP",
        "lon": "-70.56315",
        "iso": "DO",
        "status": 1,
        "name": "Gregorio Luperon International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.754942",
        "size": "medium"
    },
    {
        "iata": "MDR",
        "lon": "-154.71666",
        "iso": "US",
        "status": 1,
        "name": "Medfra Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.106945",
        "size": "small"
    },
    {
        "iata": "SNX",
        "lon": "-69.4",
        "iso": "DO",
        "status": 1,
        "name": "Sabana de Mar Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.116667",
        "size": "small"
    },
    {
        "iata": "SDQ",
        "lon": "-69.67674",
        "iso": "DO",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "18.430124",
        "size": "large"
    },
    {
        "iata": "SJM",
        "iso": "DO",
        "status": 0,
        "name": "San Juan Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "STI",
        "lon": "-70.603355",
        "iso": "DO",
        "status": 1,
        "name": "Cibao International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.404486",
        "size": "medium"
    },
    {
        "iata": "DOA",
        "lon": "49.5",
        "iso": "MG",
        "status": 1,
        "name": "Doany Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-14.366667",
        "size": "small"
    },
    {
        "iata": "CBV",
        "lon": "-90.409164",
        "iso": "GT",
        "status": 1,
        "name": "Coban Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.471667",
        "size": "medium"
    },
    {
        "iata": "CMM",
        "lon": "-90.05333",
        "iso": "GT",
        "status": 1,
        "name": "Carmelita Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.458332",
        "size": "small"
    },
    {
        "iata": "CTF",
        "lon": "-91.916664",
        "iso": "GT",
        "status": 1,
        "name": "Coatepeque Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.766667",
        "size": "small"
    },
    {
        "iata": "GUA",
        "lon": "-90.53068",
        "iso": "GT",
        "status": 1,
        "name": "La Aurora Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.588071",
        "size": "medium"
    },
    {
        "iata": "HUG",
        "lon": "-91.46667",
        "iso": "GT",
        "status": 1,
        "name": "Huehuetenango Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.333333",
        "size": "small"
    },
    {
        "iata": "MCR",
        "lon": "-92.00833",
        "iso": "GT",
        "status": 1,
        "name": "Melchor de Mencos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.0",
        "size": "small"
    },
    {
        "iata": "PBR",
        "lon": "-88.60611",
        "iso": "GT",
        "status": 1,
        "name": "Puerto Barrios Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.723056",
        "size": "medium"
    },
    {
        "iata": "PON",
        "lon": "-89.433334",
        "iso": "GT",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "16.35",
        "size": "small"
    },
    {
        "iata": "AQB",
        "iso": "GT",
        "status": 1,
        "name": "Santa Cruz del Quiche Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "AAZ",
        "iso": "GT",
        "status": 1,
        "name": "Quezaltenango Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "RUV",
        "lon": "-90.333336",
        "iso": "GT",
        "status": 1,
        "name": "Rubelsanto Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.833333",
        "size": "medium"
    },
    {
        "iata": "RER",
        "lon": "-91.696945",
        "iso": "GT",
        "status": 1,
        "name": "Retalhuleu Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.520278",
        "size": "medium"
    },
    {
        "iata": "GSJ",
        "iso": "GT",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "FRS",
        "lon": "-89.88333",
        "iso": "GT",
        "status": 1,
        "name": "Mundo Maya International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.916668",
        "size": "medium"
    },
    {
        "iata": "AIM",
        "lon": "169.98334",
        "iso": "MH",
        "status": 1,
        "name": "Ailuk Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "10.2",
        "size": "small"
    },
    {
        "iata": "AUL",
        "lon": "171.16667",
        "iso": "MH",
        "status": 1,
        "name": "Aur Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "8.133333",
        "size": "small"
    },
    {
        "iata": "BII",
        "lon": "165.56512",
        "iso": "MH",
        "status": 1,
        "name": "Enyu Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "11.525081",
        "size": "small"
    },
    {
        "iata": "EBN",
        "lon": "166.8",
        "iso": "MH",
        "status": 1,
        "name": "Ebadon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.333333",
        "size": "small"
    },
    {
        "iata": "JAT",
        "lon": "168.96666",
        "iso": "MH",
        "status": 1,
        "name": "Jabot Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.75",
        "size": "small"
    },
    {
        "iata": "JEJ",
        "lon": "168.96513",
        "iso": "MH",
        "status": 1,
        "name": "Jeh Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.569916",
        "size": "small"
    },
    {
        "iata": "KBT",
        "lon": "170.91667",
        "iso": "MH",
        "status": 1,
        "name": "Kaben Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.166667",
        "size": "small"
    },
    {
        "iata": "LIK",
        "lon": "169.31667",
        "iso": "MH",
        "status": 1,
        "name": "Likiep Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.816667",
        "size": "small"
    },
    {
        "iata": "LML",
        "lon": "166.25",
        "iso": "MH",
        "status": 1,
        "name": "Lae Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "8.916667",
        "size": "small"
    },
    {
        "iata": "MAV",
        "lon": "171.1",
        "iso": "MH",
        "status": 1,
        "name": "Maloelap Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "8.75",
        "size": "small"
    },
    {
        "iata": "MJB",
        "lon": "170.88333",
        "iso": "MH",
        "status": 1,
        "name": "Mejit Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "10.283333",
        "size": "small"
    },
    {
        "iata": "MJE",
        "lon": "170.0",
        "iso": "MH",
        "status": 1,
        "name": "Majkin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.0",
        "size": "small"
    },
    {
        "iata": "NDK",
        "lon": "168.11667",
        "iso": "MH",
        "status": 1,
        "name": "Namorik Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "5.633333",
        "size": "small"
    },
    {
        "iata": "RNP",
        "lon": "166.88333",
        "iso": "MH",
        "status": 1,
        "name": "Rongelap Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "11.15",
        "size": "small"
    },
    {
        "iata": "TIC",
        "lon": "171.78334",
        "iso": "MH",
        "status": 1,
        "name": "Tinak Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.05",
        "size": "small"
    },
    {
        "iata": "UIT",
        "lon": "169.61667",
        "iso": "MH",
        "status": 1,
        "name": "Jaluit Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "5.833333",
        "size": "small"
    },
    {
        "iata": "WJA",
        "lon": "168.55296",
        "iso": "MH",
        "status": 1,
        "name": "Woja Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.453653",
        "size": "small"
    },
    {
        "iata": "WTE",
        "lon": "170.26666",
        "iso": "MH",
        "status": 1,
        "name": "Wotje Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.45",
        "size": "small"
    },
    {
        "iata": "WTO",
        "lon": "166.03334",
        "iso": "MH",
        "status": 1,
        "name": "Wotho Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "10.166667",
        "size": "small"
    },
    {
        "iata": "AHS",
        "lon": "-84.425",
        "iso": "HN",
        "status": 1,
        "name": "Ahuas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.441667",
        "size": "small"
    },
    {
        "iata": "BHG",
        "lon": "-84.543335",
        "iso": "HN",
        "status": 1,
        "name": "Brus Laguna Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.734722",
        "size": "small"
    },
    {
        "iata": "CAA",
        "lon": "-85.89445",
        "iso": "HN",
        "status": 1,
        "name": "Catacamas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.830556",
        "size": "small"
    },
    {
        "iata": "CDD",
        "lon": "-83.602776",
        "iso": "HN",
        "status": 1,
        "name": "Cauquira Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.322222",
        "size": "small"
    },
    {
        "iata": "JUT",
        "lon": "-86.29305",
        "iso": "HN",
        "status": 1,
        "name": "Jutigalpa airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.673333",
        "size": "small"
    },
    {
        "iata": "LCE",
        "lon": "-86.85722",
        "iso": "HN",
        "status": 1,
        "name": "Goloson International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.740556",
        "size": "medium"
    },
    {
        "iata": "LEZ",
        "lon": "-88.166664",
        "iso": "HN",
        "status": 1,
        "name": "La Esperanza Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.25",
        "size": "small"
    },
    {
        "iata": "SAP",
        "lon": "-87.927795",
        "iso": "HN",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "15.456245",
        "size": "medium"
    },
    {
        "iata": "GJA",
        "lon": "-85.90583",
        "iso": "HN",
        "status": 1,
        "name": "La Laguna Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.445",
        "size": "medium"
    },
    {
        "iata": "PEU",
        "lon": "-83.76667",
        "iso": "HN",
        "status": 1,
        "name": "Puerto Lempira Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.216667",
        "size": "small"
    },
    {
        "iata": "RTB",
        "lon": "-86.52722",
        "iso": "HN",
        "status": 1,
        "name": "Juan Manuel Galvez International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.318209",
        "size": "medium"
    },
    {
        "iata": "SDH",
        "lon": "-88.77639",
        "iso": "HN",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "14.777222",
        "size": "small"
    },
    {
        "iata": "RUY",
        "lon": "-88.0",
        "iso": "HN",
        "status": 1,
        "name": "Copan Ruinas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.0",
        "size": "small"
    },
    {
        "iata": "XPL",
        "lon": "-87.61667",
        "iso": "HN",
        "status": 1,
        "name": "Coronel Enrique Soto Cano Air Base",
        "continent": "NA",
        "type": "airport",
        "lat": "14.416667",
        "size": "medium"
    },
    {
        "iata": "TEA",
        "lon": "-87.48778",
        "iso": "HN",
        "status": 1,
        "name": "Tela Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.771667",
        "size": "medium"
    },
    {
        "iata": "TGU",
        "lon": "-87.21972",
        "iso": "HN",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "14.060123",
        "size": "medium"
    },
    {
        "iata": "TJI",
        "lon": "-85.939445",
        "iso": "HN",
        "status": 1,
        "name": "Trujillo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.925833",
        "size": "medium"
    },
    {
        "iata": "SCD",
        "lon": "-87.26667",
        "iso": "HN",
        "status": 1,
        "name": "Sulaco Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.916111",
        "size": "small"
    },
    {
        "iata": "UII",
        "lon": "-86.8875",
        "iso": "HN",
        "status": 1,
        "name": "Utila Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.091667",
        "size": "small"
    },
    {
        "iata": "MJG",
        "lon": "-79.0",
        "iso": "PR",
        "status": 1,
        "name": "Areopuerto Internacional Michael Gonzalez",
        "continent": "NA",
        "type": "airport",
        "lat": "21.758333",
        "size": "small"
    },
    {
        "iata": "OCJ",
        "lon": "-76.96972",
        "iso": "JM",
        "status": 1,
        "name": "Boscobel Aerodrome",
        "continent": "NA",
        "type": "airport",
        "lat": "18.400557",
        "size": "medium"
    },
    {
        "iata": "KIN",
        "lon": "-76.77816",
        "iso": "JM",
        "status": 1,
        "name": "Norman Manley International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.93775",
        "size": "large"
    },
    {
        "iata": "MBJ",
        "lon": "-77.91663",
        "iso": "JM",
        "status": 1,
        "name": "Sangster International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.498465",
        "size": "medium"
    },
    {
        "iata": "POT",
        "lon": "-76.53555",
        "iso": "JM",
        "status": 1,
        "name": "Ken Jones Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.198334",
        "size": "medium"
    },
    {
        "iata": "NEG",
        "lon": "-78.333336",
        "iso": "JM",
        "status": 1,
        "name": "Negril Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.308332",
        "size": "small"
    },
    {
        "iata": "KTP",
        "lon": "-76.82333",
        "iso": "JM",
        "status": 1,
        "name": "Tinson Pen Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.9875",
        "size": "medium"
    },
    {
        "iata": "MIJ",
        "lon": "171.75",
        "iso": "MH",
        "status": 1,
        "name": "Mili Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "6.083333",
        "size": "small"
    },
    {
        "iata": "HEB",
        "lon": "95.46667",
        "iso": "MM",
        "status": 1,
        "name": "Henzada Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.633333",
        "size": "small"
    },
    {
        "iata": "ACA",
        "lon": "-99.75459",
        "iso": "MX",
        "status": 1,
        "name": "General Juan N Alvarez International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.762403",
        "size": "large"
    },
    {
        "iata": "NTR",
        "lon": "-100.316666",
        "iso": "MX",
        "status": 1,
        "name": "Del Norte International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.666668",
        "size": "medium"
    },
    {
        "iata": "AGU",
        "lon": "-102.31374",
        "iso": "MX",
        "status": 1,
        "name": "Jesus Teran International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.701416",
        "size": "medium"
    },
    {
        "iata": "HUX",
        "lon": "-96.23556",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "15.768333",
        "size": "medium"
    },
    {
        "iata": "CNA",
        "lon": "-110.333336",
        "iso": "MX",
        "status": 1,
        "name": "Cananea Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.0",
        "size": "small"
    },
    {
        "iata": "CVJ",
        "lon": "-99.262276",
        "iso": "MX",
        "status": 1,
        "name": "General Mariano Matamoros Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.834608",
        "size": "medium"
    },
    {
        "iata": "ACN",
        "lon": "-100.916664",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "29.3",
        "size": "small"
    },
    {
        "iata": "CME",
        "lon": "-91.802734",
        "iso": "MX",
        "status": 1,
        "name": "Ciudad del Carmen International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.65103",
        "size": "medium"
    },
    {
        "iata": "NCG",
        "lon": "-107.916664",
        "iso": "MX",
        "status": 1,
        "name": "Nuevo Casas Grandes Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.416668",
        "size": "small"
    },
    {
        "iata": "CUL",
        "lon": "-107.46958",
        "iso": "MX",
        "status": 1,
        "name": "Federal de Bachigualato International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.76643",
        "size": "medium"
    },
    {
        "iata": "CTM",
        "lon": "-88.333336",
        "iso": "MX",
        "status": 1,
        "name": "Chetumal International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.481943",
        "size": "medium"
    },
    {
        "iata": "CEN",
        "lon": "-109.839165",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "27.391945",
        "size": "medium"
    },
    {
        "iata": "CJT",
        "lon": "-92.05",
        "iso": "MX",
        "status": 1,
        "name": "Comitan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.166668",
        "size": "small"
    },
    {
        "iata": "CPE",
        "lon": "-90.509445",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "19.835",
        "size": "medium"
    },
    {
        "iata": "CJS",
        "lon": "-106.4361",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "31.63593",
        "size": "medium"
    },
    {
        "iata": "CZA",
        "lon": "-88.566666",
        "iso": "MX",
        "status": 1,
        "name": "Chichen Itza International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.666668",
        "size": "medium"
    },
    {
        "iata": "CUU",
        "lon": "-105.96952",
        "iso": "MX",
        "status": 1,
        "name": "General Roberto Fierro Villalobos International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.704048",
        "size": "medium"
    },
    {
        "iata": "CVM",
        "lon": "-98.96528",
        "iso": "MX",
        "status": 1,
        "name": "General Pedro Jose Mendez International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.713888",
        "size": "medium"
    },
    {
        "iata": "CYW",
        "lon": "-100.9",
        "iso": "MX",
        "status": 1,
        "name": "Captain Rogelio Castillo National Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.55",
        "size": "medium"
    },
    {
        "iata": "CZM",
        "lon": "-86.930466",
        "iso": "MX",
        "status": 1,
        "name": "Cozumel International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.5112",
        "size": "medium"
    },
    {
        "iata": "CUA",
        "lon": "-111.664444",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "25.036943",
        "size": "medium"
    },
    {
        "iata": "MMC",
        "lon": "-99.0",
        "iso": "MX",
        "status": 1,
        "name": "Ciudad Mante National Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.833332",
        "size": "small"
    },
    {
        "iata": "DGO",
        "lon": "-104.525",
        "iso": "MX",
        "status": 1,
        "name": "General Guadalupe Victoria International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.125",
        "size": "medium"
    },
    {
        "iata": "TPQ",
        "lon": "-104.9",
        "iso": "MX",
        "status": 1,
        "name": "Amado Nervo National Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.5",
        "size": "medium"
    },
    {
        "iata": "ESE",
        "lon": "-116.61667",
        "iso": "MX",
        "status": 1,
        "name": "Ensenada Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.866667",
        "size": "small"
    },
    {
        "iata": "GDL",
        "lon": "-103.29921",
        "iso": "MX",
        "status": 1,
        "name": "Don Miguel Hidalgo Y Costilla International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.525198",
        "size": "large"
    },
    {
        "iata": "GYM",
        "lon": "-110.93056",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "27.955557",
        "size": "medium"
    },
    {
        "iata": "GUB",
        "lon": "-114.066666",
        "iso": "MX",
        "status": 1,
        "name": "Guerrero Negro Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.033333",
        "size": "small"
    },
    {
        "iata": "TCN",
        "lon": "-97.41778",
        "iso": "MX",
        "status": 1,
        "name": "Tehuacan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.499722",
        "size": "small"
    },
    {
        "iata": "HMO",
        "lon": "-111.051704",
        "iso": "MX",
        "status": 1,
        "name": "General Ignacio P. Garcia International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "29.089905",
        "size": "large"
    },
    {
        "iata": "CLQ",
        "lon": "-103.65",
        "iso": "MX",
        "status": 1,
        "name": "Lic. Miguel de la Madrid Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.3",
        "size": "medium"
    },
    {
        "iata": "ISJ",
        "lon": "-86.75",
        "iso": "MX",
        "status": 1,
        "name": "Isla Mujeres Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.266666",
        "size": "small"
    },
    {
        "iata": "SLW",
        "lon": "-100.916664",
        "iso": "MX",
        "status": 1,
        "name": "Plan De Guadalupe International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.55",
        "size": "medium"
    },
    {
        "iata": "IZT",
        "lon": "-95.10833",
        "iso": "MX",
        "status": 1,
        "name": "Ixtepec Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.569166",
        "size": "medium"
    },
    {
        "iata": "JAL",
        "lon": "-96.781944",
        "iso": "MX",
        "status": 1,
        "name": "El Lencero Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.472221",
        "size": "medium"
    },
    {
        "iata": "AZP",
        "lon": "-99.1",
        "iso": "MX",
        "status": 1,
        "name": "Atizapan De Zaragoza Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.416668",
        "size": "small"
    },
    {
        "iata": "LZC",
        "lon": "-102.21667",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "18.0",
        "size": "medium"
    },
    {
        "iata": "LMM",
        "lon": "-108.98333",
        "iso": "MX",
        "status": 1,
        "name": "Valle del Fuerte International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.816668",
        "size": "medium"
    },
    {
        "iata": "BJX",
        "lon": "-101.479",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "20.985699",
        "size": "medium"
    },
    {
        "iata": "LAP",
        "lon": "-110.367836",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "24.076088",
        "size": "medium"
    },
    {
        "iata": "LTO",
        "lon": "-111.35",
        "iso": "MX",
        "status": 1,
        "name": "Loreto International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.983334",
        "size": "medium"
    },
    {
        "iata": "MAM",
        "lon": "-97.52361",
        "iso": "MX",
        "status": 1,
        "name": "General Servando Canales International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.770279",
        "size": "medium"
    },
    {
        "iata": "MID",
        "lon": "-89.66375",
        "iso": "MX",
        "status": 1,
        "name": "Licenciado Manuel Crescencio Rejon Int Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.933823",
        "size": "medium"
    },
    {
        "iata": "MUG",
        "lon": "-111.98333",
        "iso": "MX",
        "status": 1,
        "name": "Mulege Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.916668",
        "size": "small"
    },
    {
        "iata": "MXL",
        "lon": "-115.248215",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "32.6282",
        "size": "medium"
    },
    {
        "iata": "MLM",
        "lon": "-101.02693",
        "iso": "MX",
        "status": 1,
        "name": "General Francisco J. Mujica International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.84351",
        "size": "medium"
    },
    {
        "iata": "MTT",
        "lon": "-94.51667",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "17.983334",
        "size": "medium"
    },
    {
        "iata": "LOV",
        "lon": "-101.416664",
        "iso": "MX",
        "status": 1,
        "name": "Monclova International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.9",
        "size": "medium"
    },
    {
        "iata": "MEX",
        "lon": "-99.07278",
        "iso": "MX",
        "status": 1,
        "name": "Licenciado Benito Juarez International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.435278",
        "size": "large"
    },
    {
        "iata": "MTY",
        "lon": "-100.114395",
        "iso": "MX",
        "status": 1,
        "name": "General Mariano Escobedo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.77657",
        "size": "large"
    },
    {
        "iata": "MZT",
        "lon": "-106.27015",
        "iso": "MX",
        "status": 1,
        "name": "General Rafael Buelna International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.167315",
        "size": "medium"
    },
    {
        "iata": "NOG",
        "lon": "-110.933334",
        "iso": "MX",
        "status": 1,
        "name": "Nogales International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.333332",
        "size": "small"
    },
    {
        "iata": "NLD",
        "lon": "-99.566666",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "27.434723",
        "size": "medium"
    },
    {
        "iata": "OAX",
        "lon": "-96.721634",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "17.000883",
        "size": "medium"
    },
    {
        "iata": "PAZ",
        "lon": "-97.467224",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "20.516666",
        "size": "medium"
    },
    {
        "iata": "PBC",
        "lon": "-98.3675",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "19.135",
        "size": "medium"
    },
    {
        "iata": "PPE",
        "iso": "MX",
        "status": 0,
        "name": "Puerto Penasco Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "PDS",
        "lon": "-100.53604",
        "iso": "MX",
        "status": 1,
        "name": "Piedras Negras International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "28.628948",
        "size": "medium"
    },
    {
        "iata": "UPN",
        "lon": "-102.04722",
        "iso": "MX",
        "status": 1,
        "name": "Licenciado y General Ignacio Lopez Rayon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.411112",
        "size": "medium"
    },
    {
        "iata": "PQM",
        "lon": "-92.00833",
        "iso": "MX",
        "status": 1,
        "name": "Palenque International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.5",
        "size": "small"
    },
    {
        "iata": "PVR",
        "lon": "-105.24898",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "20.678297",
        "size": "large"
    },
    {
        "iata": "PXM",
        "lon": "-97.083336",
        "iso": "MX",
        "status": 1,
        "name": "Puerto Escondido International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.85",
        "size": "medium"
    },
    {
        "iata": "QRO",
        "lon": "-100.38333",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "20.6",
        "size": "medium"
    },
    {
        "iata": "REX",
        "lon": "-98.23",
        "iso": "MX",
        "status": 1,
        "name": "General Lucio Blanco International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.0125",
        "size": "medium"
    },
    {
        "iata": "SZT",
        "lon": "-92.54417",
        "iso": "MX",
        "status": 1,
        "name": "San Cristobal De Las Casas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.690556",
        "size": "small"
    },
    {
        "iata": "SJD",
        "lon": "-109.717285",
        "iso": "MX",
        "status": 1,
        "name": "Los Cabos International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.162354",
        "size": "large"
    },
    {
        "iata": "SFH",
        "lon": "-114.86667",
        "iso": "MX",
        "status": 1,
        "name": "San Felipe International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "31.0",
        "size": "small"
    },
    {
        "iata": "SLP",
        "lon": "-100.936806",
        "iso": "MX",
        "status": 1,
        "name": "Ponciano Arriaga International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.255466",
        "size": "medium"
    },
    {
        "iata": "TGM",
        "lon": "24.533333",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "46.533333",
        "size": "medium"
    },
    {
        "iata": "TRC",
        "lon": "-103.39874",
        "iso": "MX",
        "status": 1,
        "name": "Francisco Sarabia International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.563066",
        "size": "medium"
    },
    {
        "iata": "TIJ",
        "lon": "-116.97466",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "32.546284",
        "size": "large"
    },
    {
        "iata": "TAM",
        "lon": "-97.87016",
        "iso": "MX",
        "status": 1,
        "name": "General Francisco Javier Mina International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.289082",
        "size": "medium"
    },
    {
        "iata": "TSL",
        "lon": "-98.75",
        "iso": "MX",
        "status": 1,
        "name": "Tamuin Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.983334",
        "size": "small"
    },
    {
        "iata": "TLC",
        "lon": "-99.56049",
        "iso": "MX",
        "status": 1,
        "name": "Licenciado Adolfo Lopez Mateos International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.333355",
        "size": "medium"
    },
    {
        "iata": "TAP",
        "lon": "-92.25306",
        "iso": "MX",
        "status": 1,
        "name": "Tapachula International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.909722",
        "size": "medium"
    },
    {
        "iata": "WIX",
        "iso": "MX",
        "status": 1,
        "name": "Tuxpan Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CUN",
        "lon": "-86.874435",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "21.040457",
        "size": "large"
    },
    {
        "iata": "VSA",
        "lon": "-92.818985",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "17.99243",
        "size": "medium"
    },
    {
        "iata": "VER",
        "lon": "-96.18321",
        "iso": "MX",
        "status": 1,
        "name": "General Heriberto Jara International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.142275",
        "size": "medium"
    },
    {
        "iata": "ZCL",
        "lon": "-102.55",
        "iso": "MX",
        "status": 1,
        "name": "General Leobardo C. Ruiz International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.8",
        "size": "medium"
    },
    {
        "iata": "ZIH",
        "lon": "-101.464066",
        "iso": "MX",
        "status": 1,
        "name": "Ixtapa Zihuatanejo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.606783",
        "size": "medium"
    },
    {
        "iata": "ZMM",
        "lon": "-102.26667",
        "iso": "MX",
        "status": 1,
        "name": "Zamora Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.983334",
        "size": "small"
    },
    {
        "iata": "ZLO",
        "lon": "-104.350555",
        "iso": "MX",
        "status": 1,
        "name": "Playa De Oro International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.113333",
        "size": "medium"
    },
    {
        "iata": "MXW",
        "lon": "106.28333",
        "iso": "MN",
        "status": 1,
        "name": "Mandalgobi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.766666",
        "size": "small"
    },
    {
        "iata": "ULG",
        "lon": "89.96667",
        "iso": "MN",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "48.966667",
        "size": "small"
    },
    {
        "iata": "ULZ",
        "lon": "96.85",
        "iso": "MN",
        "status": 1,
        "name": "Jibhalanta Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "47.75",
        "size": "small"
    },
    {
        "iata": "BEF",
        "lon": "-83.76861",
        "iso": "NI",
        "status": 1,
        "name": "Bluefields Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "11.996944",
        "size": "medium"
    },
    {
        "iata": "BZA",
        "lon": "-84.6",
        "iso": "NI",
        "status": 1,
        "name": "San Pedro Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.95",
        "size": "small"
    },
    {
        "iata": "RNI",
        "lon": "-83.066666",
        "iso": "NI",
        "status": 1,
        "name": "Corn Island",
        "continent": "NA",
        "type": "airport",
        "lat": "12.15",
        "size": "small"
    },
    {
        "iata": "MGA",
        "lon": "-86.171265",
        "iso": "NI",
        "status": 1,
        "name": "Augusto C. Sandino (Managua) International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.144838",
        "size": "medium"
    },
    {
        "iata": "NVG",
        "lon": "-84.45",
        "iso": "NI",
        "status": 1,
        "name": "Nueva Guinea Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "11.666667",
        "size": "small"
    },
    {
        "iata": "PUZ",
        "lon": "-83.38361",
        "iso": "NI",
        "status": 1,
        "name": "Puerto Cabezas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.045278",
        "size": "medium"
    },
    {
        "iata": "RFS",
        "lon": "-84.4",
        "iso": "NI",
        "status": 1,
        "name": "Rosita Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.883333",
        "size": "small"
    },
    {
        "iata": "NCR",
        "lon": "-84.833336",
        "iso": "NI",
        "status": 1,
        "name": "San Carlos",
        "continent": "NA",
        "type": "airport",
        "lat": "11.25",
        "size": "small"
    },
    {
        "iata": "SIU",
        "lon": "-84.77778",
        "iso": "NI",
        "status": 1,
        "name": "Siuna",
        "continent": "NA",
        "type": "airport",
        "lat": "13.727222",
        "size": "small"
    },
    {
        "iata": "WSP",
        "lon": "-84.01667",
        "iso": "NI",
        "status": 1,
        "name": "Waspam Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.683333",
        "size": "small"
    },
    {
        "iata": "BOC",
        "lon": "-82.251945",
        "iso": "PA",
        "status": 1,
        "name": "Bocas Del Toro International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.34",
        "size": "medium"
    },
    {
        "iata": "CTD",
        "lon": "-80.433334",
        "iso": "PA",
        "status": 1,
        "name": "Alonso Valderrama Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "7.966667",
        "size": "medium"
    },
    {
        "iata": "CHX",
        "lon": "-82.45",
        "iso": "PA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "9.45",
        "size": "medium"
    },
    {
        "iata": "DAV",
        "lon": "-82.433334",
        "iso": "PA",
        "status": 1,
        "name": "Enrique Malek International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.383333",
        "size": "medium"
    },
    {
        "iata": "ONX",
        "lon": "-79.9",
        "iso": "PA",
        "status": 1,
        "name": "Enrique Adolfo Jimenez Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.35",
        "size": "medium"
    },
    {
        "iata": "BLB",
        "lon": "-79.59809",
        "iso": "PA",
        "status": 1,
        "name": "Howard Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.914011",
        "size": "small"
    },
    {
        "iata": "JQE",
        "lon": "-78.166664",
        "iso": "PA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "7.5",
        "size": "small"
    },
    {
        "iata": "PLP",
        "lon": "-78.13333",
        "iso": "PA",
        "status": 1,
        "name": "Captain Ramon Xatruch Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.333333",
        "size": "small"
    },
    {
        "iata": "PAC",
        "lon": "-79.55561",
        "iso": "PA",
        "status": 1,
        "name": "Marcos A. Gelabert International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.971004",
        "size": "medium"
    },
    {
        "iata": "SYP",
        "lon": "-80.98333",
        "iso": "PA",
        "status": 1,
        "name": "Ruben Cantu Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.1",
        "size": "medium"
    },
    {
        "iata": "PTY",
        "lon": "-79.38764",
        "iso": "PA",
        "status": 1,
        "name": "Tocumen International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.066897",
        "size": "large"
    },
    {
        "iata": "NBL",
        "lon": "-79.0",
        "iso": "PA",
        "status": 1,
        "name": "San Blas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.3",
        "size": "small"
    },
    {
        "iata": "FON",
        "lon": "-84.48333",
        "iso": "CR",
        "status": 1,
        "name": "Arenal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.4",
        "size": "small"
    },
    {
        "iata": "TTQ",
        "lon": "-83.51667",
        "iso": "CR",
        "status": 1,
        "name": "Aerotortuguero Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.566667",
        "size": "medium"
    },
    {
        "iata": "BAI",
        "lon": "-83.333336",
        "iso": "CR",
        "status": 1,
        "name": "Buenos Aires Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.166667",
        "size": "medium"
    },
    {
        "iata": "BCL",
        "lon": "-82.96667",
        "iso": "CR",
        "status": 1,
        "name": "Barra del Colorado Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.833333",
        "size": "medium"
    },
    {
        "iata": "CSC",
        "lon": "-85.48333",
        "iso": "CR",
        "status": 1,
        "name": "Codela Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.766667",
        "size": "small"
    },
    {
        "iata": "OTR",
        "lon": "-82.933334",
        "iso": "CR",
        "status": 1,
        "name": "Coto 47 Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.566667",
        "size": "medium"
    },
    {
        "iata": "RIK",
        "lon": "-85.48333",
        "iso": "CR",
        "status": 1,
        "name": "Carrillo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.866667",
        "size": "small"
    },
    {
        "iata": "TNO",
        "lon": "-85.815475",
        "iso": "CR",
        "status": 1,
        "name": "Cabo Velas Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.314033",
        "size": "small"
    },
    {
        "iata": "FMG",
        "lon": "-85.78333",
        "iso": "CR",
        "status": 1,
        "name": "Flamingo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.416667",
        "size": "small"
    },
    {
        "iata": "GLF",
        "lon": "-83.18083",
        "iso": "CR",
        "status": 1,
        "name": "Golfito Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.651389",
        "size": "medium"
    },
    {
        "iata": "GPL",
        "lon": "-83.8",
        "iso": "CR",
        "status": 1,
        "name": "Guapiles Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.2",
        "size": "medium"
    },
    {
        "iata": "PBP",
        "lon": "-85.166664",
        "iso": "CR",
        "status": 1,
        "name": "Islita Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.416667",
        "size": "small"
    },
    {
        "iata": "LIR",
        "lon": "-85.53839",
        "iso": "CR",
        "status": 1,
        "name": "Daniel Oduber Quiros International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.600005",
        "size": "medium"
    },
    {
        "iata": "LSL",
        "lon": "-84.7",
        "iso": "CR",
        "status": 1,
        "name": "Los Chiles Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "11.033333",
        "size": "medium"
    },
    {
        "iata": "LIO",
        "lon": "-83.02111",
        "iso": "CR",
        "status": 1,
        "name": "Limon International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.955833",
        "size": "medium"
    },
    {
        "iata": "NCT",
        "lon": "-85.445",
        "iso": "CR",
        "status": 1,
        "name": "Guanacaste Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.138889",
        "size": "small"
    },
    {
        "iata": "NOM",
        "lon": "142.21666",
        "iso": "CR",
        "status": 1,
        "name": "Nosara Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "-6.3",
        "size": "medium"
    },
    {
        "iata": "SJO",
        "lon": "-84.20408",
        "iso": "CR",
        "status": 1,
        "name": "Juan Santamaria International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.998238",
        "size": "medium"
    },
    {
        "iata": "PJM",
        "lon": "-83.333336",
        "iso": "CR",
        "status": 1,
        "name": "Puerto Jimenez Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.583333",
        "size": "medium"
    },
    {
        "iata": "PMZ",
        "lon": "-83.46598",
        "iso": "CR",
        "status": 1,
        "name": "Palmar Sur Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.950836",
        "size": "medium"
    },
    {
        "iata": "SYQ",
        "lon": "-84.13333",
        "iso": "CR",
        "status": 1,
        "name": "Tobias Bolanos International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.95",
        "size": "medium"
    },
    {
        "iata": "XQP",
        "lon": "-84.13335",
        "iso": "CR",
        "status": 1,
        "name": "Quepos Managua Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.439146",
        "size": "medium"
    },
    {
        "iata": "RFR",
        "lon": "-83.88333",
        "iso": "CR",
        "status": 1,
        "name": "Rio Frio / Progreso Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.333333",
        "size": "small"
    },
    {
        "iata": "PLD",
        "lon": "-85.416664",
        "iso": "CR",
        "status": 1,
        "name": "Playa Samara Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.25",
        "size": "small"
    },
    {
        "iata": "TOO",
        "lon": "-82.96667",
        "iso": "CR",
        "status": 1,
        "name": "San Vito De Java Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.833333",
        "size": "medium"
    },
    {
        "iata": "TNO",
        "lon": "-85.815475",
        "iso": "CR",
        "status": 1,
        "name": "Tamarindo De Santa Cruz Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.314033",
        "size": "small"
    },
    {
        "iata": "TMU",
        "lon": "-85.01667",
        "iso": "CR",
        "status": 1,
        "name": "Tambor Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.733333",
        "size": "small"
    },
    {
        "iata": "UPL",
        "lon": "-85.03333",
        "iso": "CR",
        "status": 1,
        "name": "Upala Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.783333",
        "size": "medium"
    },
    {
        "iata": "SAL",
        "lon": "-89.05723",
        "iso": "SV",
        "status": 1,
        "name": "El Salvador International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.445126",
        "size": "medium"
    },
    {
        "iata": "CYA",
        "lon": "-73.78833",
        "iso": "HT",
        "status": 1,
        "name": "Les Cayes Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.269722",
        "size": "medium"
    },
    {
        "iata": "CAP",
        "lon": "-72.195",
        "iso": "HT",
        "status": 1,
        "name": "Cap Haitien International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.732779",
        "size": "medium"
    },
    {
        "iata": "JAK",
        "lon": "-72.51861",
        "iso": "HT",
        "status": 1,
        "name": "Jacmel Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.240557",
        "size": "medium"
    },
    {
        "iata": "JEE",
        "lon": "-74.16972",
        "iso": "HT",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "18.662222",
        "size": "medium"
    },
    {
        "iata": "PAP",
        "lon": "-72.29471",
        "iso": "HT",
        "status": 1,
        "name": "Toussaint Louverture International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.575394",
        "size": "medium"
    },
    {
        "iata": "PAX",
        "lon": "-72.84167",
        "iso": "HT",
        "status": 1,
        "name": "Port-de-Paix Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.933332",
        "size": "medium"
    },
    {
        "iata": "MTU",
        "lon": "38.983334",
        "iso": "MZ",
        "status": 1,
        "name": "Montepuez Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-13.133333",
        "size": "small"
    },
    {
        "iata": "BCA",
        "lon": "-74.5",
        "iso": "CU",
        "status": 1,
        "name": "Gustavo Rizo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.35",
        "size": "medium"
    },
    {
        "iata": "BWW",
        "iso": "CU",
        "status": 1,
        "name": "Las Brujas Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BYM",
        "lon": "-76.65",
        "iso": "CU",
        "status": 1,
        "name": "Carlos Manuel de Cespedes Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.383333",
        "size": "medium"
    },
    {
        "iata": "AVI",
        "lon": "-78.79139",
        "iso": "CU",
        "status": 1,
        "name": "Maximo Gomez Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.025",
        "size": "medium"
    },
    {
        "iata": "CCC",
        "lon": "-78.32861",
        "iso": "CU",
        "status": 1,
        "name": "Jardines Del Rey Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.461111",
        "size": "medium"
    },
    {
        "iata": "CFG",
        "lon": "-80.41417",
        "iso": "CU",
        "status": 1,
        "name": "Jaime Gonzalez Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.15",
        "size": "medium"
    },
    {
        "iata": "CYO",
        "lon": "-81.51667",
        "iso": "CU",
        "status": 1,
        "name": "Vilo Acuna International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.616667",
        "size": "medium"
    },
    {
        "iata": "CMW",
        "lon": "-77.849724",
        "iso": "CU",
        "status": 1,
        "name": "Ignacio Agramonte International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.418612",
        "size": "medium"
    },
    {
        "iata": "QCO",
        "iso": "CU",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "SCU",
        "lon": "-75.83583",
        "iso": "CU",
        "status": 1,
        "name": "Antonio Maceo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.969168",
        "size": "medium"
    },
    {
        "iata": "NBW",
        "lon": "-75.23333",
        "iso": "CU",
        "status": 1,
        "name": "Leeward Point Field",
        "continent": "NA",
        "type": "airport",
        "lat": "20.166668",
        "size": "medium"
    },
    {
        "iata": "GAO",
        "lon": "-75.15861",
        "iso": "CU",
        "status": 1,
        "name": "Mariana Grajales Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.084723",
        "size": "medium"
    },
    {
        "iata": "HAV",
        "lon": "-82.40818",
        "iso": "CU",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "22.99845",
        "size": "large"
    },
    {
        "iata": "HOG",
        "lon": "-76.315",
        "iso": "CU",
        "status": 1,
        "name": "Frank Pais International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.785278",
        "size": "medium"
    },
    {
        "iata": "VRO",
        "lon": "-81.3",
        "iso": "CU",
        "status": 1,
        "name": "Kawama Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.133333",
        "size": "medium"
    },
    {
        "iata": "LCL",
        "lon": "-83.61667",
        "iso": "CU",
        "status": 1,
        "name": "La Coloma Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.283333",
        "size": "medium"
    },
    {
        "iata": "UMA",
        "iso": "CU",
        "status": 1,
        "name": "Punta de Maisi Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "MJG",
        "lon": "-79.0",
        "iso": "CU",
        "status": 1,
        "name": "Mayajigua Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.758333",
        "size": "small"
    },
    {
        "iata": "MOA",
        "lon": "-74.88333",
        "iso": "CU",
        "status": 1,
        "name": "Orestes Acosta Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.5",
        "size": "medium"
    },
    {
        "iata": "MZO",
        "lon": "-77.12889",
        "iso": "CU",
        "status": 1,
        "name": "Sierra Maestra Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.325",
        "size": "medium"
    },
    {
        "iata": "QSN",
        "iso": "CU",
        "status": 1,
        "name": "San Nicolas De Bari Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ICR",
        "lon": "-75.975",
        "iso": "CU",
        "status": 1,
        "name": "Nicaro Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.983334",
        "size": "small"
    },
    {
        "iata": "GER",
        "lon": "-82.78",
        "iso": "CU",
        "status": 1,
        "name": "Rafael Cabrera Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.8375",
        "size": "medium"
    },
    {
        "iata": "UPB",
        "lon": "-82.579445",
        "iso": "CU",
        "status": 1,
        "name": "Playa Baracoa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.032778",
        "size": "medium"
    },
    {
        "iata": "QPD",
        "iso": "CU",
        "status": 1,
        "name": "Pinar Del Rio Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SNU",
        "lon": "-79.94194",
        "iso": "CU",
        "status": 1,
        "name": "Abel Santamaria Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.491943",
        "size": "medium"
    },
    {
        "iata": "SNJ",
        "lon": "-84.15",
        "iso": "CU",
        "status": 1,
        "name": "San Julian Air Base",
        "continent": "NA",
        "type": "airport",
        "lat": "22.083332",
        "size": "medium"
    },
    {
        "iata": "SZJ",
        "lon": "-82.954445",
        "iso": "CU",
        "status": 1,
        "name": "Siguanea Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.643059",
        "size": "medium"
    },
    {
        "iata": "USS",
        "lon": "-79.55",
        "iso": "CU",
        "status": 1,
        "name": "Sancti Spiritus Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.866667",
        "size": "small"
    },
    {
        "iata": "TND",
        "lon": "-80.0",
        "iso": "CU",
        "status": 1,
        "name": "Alberto Delgado Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.8",
        "size": "medium"
    },
    {
        "iata": "VRA",
        "lon": "-81.43694",
        "iso": "CU",
        "status": 1,
        "name": "Juan Gualberto Gomez International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.039896",
        "size": "large"
    },
    {
        "iata": "VTU",
        "lon": "-76.95",
        "iso": "CU",
        "status": 1,
        "name": "Hermanos Ameijeiras Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.966667",
        "size": "medium"
    },
    {
        "iata": "CYB",
        "lon": "-79.87941",
        "iso": "KY",
        "status": 1,
        "name": "Gerrard Smith International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.690191",
        "size": "medium"
    },
    {
        "iata": "LYB",
        "lon": "-80.08472",
        "iso": "KY",
        "status": 1,
        "name": "Edward Bodden Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "19.6592",
        "size": "medium"
    },
    {
        "iata": "GCM",
        "lon": "-81.35779",
        "iso": "KY",
        "status": 1,
        "name": "Owen Roberts International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.29637",
        "size": "large"
    },
    {
        "iata": "AJS",
        "lon": "-113.666664",
        "iso": "MX",
        "status": 1,
        "name": "Abreojos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.833332",
        "size": "small"
    },
    {
        "iata": "AZG",
        "lon": "-102.352776",
        "iso": "MX",
        "status": 1,
        "name": "Pablo L. Sidar Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.068056",
        "size": "small"
    },
    {
        "iata": "CEO",
        "lon": "15.101389",
        "iso": "MX",
        "status": 1,
        "name": "El Crucero Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "-11.426389",
        "size": "small"
    },
    {
        "iata": "NVJ",
        "iso": "MX",
        "status": 1,
        "name": "Navojoa Airport",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "PCM",
        "lon": "-87.1",
        "iso": "MX",
        "status": 1,
        "name": "Playa del Carmen Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.6",
        "size": "small"
    },
    {
        "iata": "PCV",
        "lon": "-112.0",
        "iso": "MX",
        "status": 1,
        "name": "Punta Chivato Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.083332",
        "size": "small"
    },
    {
        "iata": "PNO",
        "lon": "-98.06944",
        "iso": "MX",
        "status": 1,
        "name": "Pinotepa Nacional Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.3425",
        "size": "small"
    },
    {
        "iata": "SCX",
        "lon": "-95.2",
        "iso": "MX",
        "status": 1,
        "name": "Salina Cruz Naval Air Station",
        "continent": "NA",
        "type": "airport",
        "lat": "16.166668",
        "size": "small"
    },
    {
        "iata": "SGM",
        "lon": "-113.0",
        "iso": "MX",
        "status": 1,
        "name": "San Ignacio Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.45",
        "size": "small"
    },
    {
        "iata": "TUY",
        "lon": "-87.46667",
        "iso": "MX",
        "status": 1,
        "name": "Tulum Naval Air Station",
        "continent": "NA",
        "type": "airport",
        "lat": "20.216667",
        "size": "small"
    },
    {
        "iata": "UAC",
        "lon": "-114.8",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "32.45",
        "size": "small"
    },
    {
        "iata": "XAL",
        "lon": "-108.933334",
        "iso": "MX",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "27.016666",
        "size": "small"
    },
    {
        "iata": "GTB",
        "lon": "111.7",
        "iso": "MY",
        "status": 1,
        "name": "Genting Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.116667",
        "size": "small"
    },
    {
        "iata": "GTK",
        "lon": "102.916664",
        "iso": "MY",
        "status": 1,
        "name": "Sungei Tekai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.6",
        "size": "small"
    },
    {
        "iata": "LBP",
        "lon": "115.4",
        "iso": "MY",
        "status": 1,
        "name": "Long Banga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.2",
        "size": "small"
    },
    {
        "iata": "LLM",
        "lon": "114.46667",
        "iso": "MY",
        "status": 1,
        "name": "Long Lama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.766667",
        "size": "small"
    },
    {
        "iata": "MZS",
        "lon": "118.15",
        "iso": "MY",
        "status": 1,
        "name": "Mostyn Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.616667",
        "size": "small"
    },
    {
        "iata": "SPT",
        "lon": "115.55",
        "iso": "MY",
        "status": 1,
        "name": "Sipitang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.083333",
        "size": "small"
    },
    {
        "iata": "MAY",
        "lon": "-77.680275",
        "iso": "BS",
        "status": 1,
        "name": "Clarence A. Bain Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.289722",
        "size": "medium"
    },
    {
        "iata": "ASD",
        "lon": "-77.79611",
        "iso": "BS",
        "status": 1,
        "name": "Andros Town Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.697779",
        "size": "medium"
    },
    {
        "iata": "COX",
        "lon": "-77.583336",
        "iso": "BS",
        "status": 1,
        "name": "Congo Town Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.15",
        "size": "medium"
    },
    {
        "iata": "MHH",
        "lon": "-77.07712",
        "iso": "BS",
        "status": 1,
        "name": "Marsh Harbour International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.513428",
        "size": "medium"
    },
    {
        "iata": "SAQ",
        "lon": "-78.05",
        "iso": "BS",
        "status": 1,
        "name": "San Andros Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.05",
        "size": "medium"
    },
    {
        "iata": "AXP",
        "lon": "-73.96667",
        "iso": "BS",
        "status": 1,
        "name": "Spring Point Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.45",
        "size": "medium"
    },
    {
        "iata": "TCB",
        "lon": "-77.37222",
        "iso": "BS",
        "status": 1,
        "name": "Treasure Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.735",
        "size": "medium"
    },
    {
        "iata": "WKR",
        "lon": "-78.4",
        "iso": "BS",
        "status": 1,
        "name": "Abaco I Walker C Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "27.266666",
        "size": "small"
    },
    {
        "iata": "CCZ",
        "lon": "-77.881386",
        "iso": "BS",
        "status": 1,
        "name": "Chub Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.4175",
        "size": "medium"
    },
    {
        "iata": "GHC",
        "lon": "-77.84028",
        "iso": "BS",
        "status": 1,
        "name": "Great Harbour Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.738056",
        "size": "medium"
    },
    {
        "iata": "BIM",
        "lon": "-79.26399",
        "iso": "BS",
        "status": 1,
        "name": "South Bimini Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.700066",
        "size": "medium"
    },
    {
        "iata": "ATC",
        "lon": "-75.67139",
        "iso": "BS",
        "status": 1,
        "name": "Arthur's Town Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.62861",
        "size": "medium"
    },
    {
        "iata": "CAT",
        "lon": "-75.45361",
        "iso": "BS",
        "status": 1,
        "name": "New Bight Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.315",
        "size": "medium"
    },
    {
        "iata": "CXY",
        "lon": "-77.816666",
        "iso": "BS",
        "status": 1,
        "name": "Cat Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.416668",
        "size": "small"
    },
    {
        "iata": "CRI",
        "lon": "-74.15",
        "iso": "BS",
        "status": 1,
        "name": "Colonel Hill Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.75",
        "size": "medium"
    },
    {
        "iata": "PWN",
        "lon": "-74.35",
        "iso": "BS",
        "status": 1,
        "name": "Pitts Town Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.833332",
        "size": "small"
    },
    {
        "iata": "GGT",
        "lon": "-75.872475",
        "iso": "BS",
        "status": 1,
        "name": "Exuma International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.564018",
        "size": "medium"
    },
    {
        "iata": "ELH",
        "lon": "-76.68192",
        "iso": "BS",
        "status": 1,
        "name": "North Eleuthera Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.476917",
        "size": "medium"
    },
    {
        "iata": "GHB",
        "lon": "-76.32488",
        "iso": "BS",
        "status": 1,
        "name": "Governor's Harbour Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.280489",
        "size": "medium"
    },
    {
        "iata": "NMC",
        "lon": "-76.82",
        "iso": "BS",
        "status": 1,
        "name": "Normans Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.59361",
        "size": "medium"
    },
    {
        "iata": "RSD",
        "lon": "-76.178055",
        "iso": "BS",
        "status": 1,
        "name": "Rock Sound Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.891666",
        "size": "medium"
    },
    {
        "iata": "TYM",
        "lon": "-76.433334",
        "iso": "BS",
        "status": 1,
        "name": "Staniel Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.15",
        "size": "medium"
    },
    {
        "iata": "FPO",
        "lon": "-78.7052",
        "iso": "BS",
        "status": 1,
        "name": "Grand Bahama International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.547293",
        "size": "medium"
    },
    {
        "iata": "WTD",
        "lon": "-78.97583",
        "iso": "BS",
        "status": 1,
        "name": "West End Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "26.686111",
        "size": "small"
    },
    {
        "iata": "IGA",
        "lon": "-73.666664",
        "iso": "BS",
        "status": 1,
        "name": "Inagua Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.983334",
        "size": "medium"
    },
    {
        "iata": "LGI",
        "lon": "-75.09055",
        "iso": "BS",
        "status": 1,
        "name": "Deadman's Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.179443",
        "size": "medium"
    },
    {
        "iata": "SML",
        "lon": "-75.26806",
        "iso": "BS",
        "status": 1,
        "name": "Stella Maris Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.583332",
        "size": "medium"
    },
    {
        "iata": "MYG",
        "lon": "-73.01833",
        "iso": "BS",
        "status": 1,
        "name": "Mayaguana Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.376667",
        "size": "medium"
    },
    {
        "iata": "NAS",
        "lon": "-77.463776",
        "iso": "BS",
        "status": 1,
        "name": "Lynden Pindling International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.048223",
        "size": "large"
    },
    {
        "iata": "PID",
        "lon": "-77.3",
        "iso": "BS",
        "status": 1,
        "name": "Nassau Paradise Island Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "25.083332",
        "size": "small"
    },
    {
        "iata": "DCT",
        "lon": "-75.75",
        "iso": "BS",
        "status": 1,
        "name": "Duncan Town Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "22.25",
        "size": "medium"
    },
    {
        "iata": "RCY",
        "lon": "-74.83502",
        "iso": "BS",
        "status": 1,
        "name": "Rum Cay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "23.684307",
        "size": "small"
    },
    {
        "iata": "ZSA",
        "lon": "-74.53306",
        "iso": "BS",
        "status": 1,
        "name": "San Salvador Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "24.06",
        "size": "medium"
    },
    {
        "iata": "MFW",
        "iso": "MZ",
        "status": 0,
        "name": "Magaruque Airport",
        "continent": "AF",
        "type": "closed",
        "size": null
    },
    {
        "iata": "TGS",
        "lon": "32.416668",
        "iso": "MZ",
        "status": 1,
        "name": null,
        "continent": "AF",
        "type": "airport",
        "lat": "-24.616667",
        "size": "small"
    },
    {
        "iata": "BZE",
        "lon": "-88.308334",
        "iso": "BZ",
        "status": 1,
        "name": "Philip S. W. Goldson International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.539167",
        "size": "large"
    },
    {
        "iata": "NBS",
        "lon": "127.6",
        "iso": "CN",
        "status": 1,
        "name": "Changbaishan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.083332",
        "size": "medium"
    },
    {
        "iata": "AIT",
        "lon": "-159.767",
        "iso": "CK",
        "status": 1,
        "name": "Aitutaki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.829443",
        "size": "small"
    },
    {
        "iata": "AIU",
        "lon": "-158.1",
        "iso": "CK",
        "status": 1,
        "name": "Enua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.033333",
        "size": "small"
    },
    {
        "iata": "MGS",
        "lon": "-157.93333",
        "iso": "CK",
        "status": 1,
        "name": "Mangaia Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.933332",
        "size": "small"
    },
    {
        "iata": "MHX",
        "lon": "-161.0",
        "iso": "CK",
        "status": 1,
        "name": "Manihiki Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.366667",
        "size": "small"
    },
    {
        "iata": "MUK",
        "lon": "-158.5",
        "iso": "CK",
        "status": 1,
        "name": "Mauke Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.333332",
        "size": "small"
    },
    {
        "iata": "MOI",
        "lon": "-157.71121",
        "iso": "CK",
        "status": 1,
        "name": "Mitiaro Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.84166",
        "size": "small"
    },
    {
        "iata": "PYE",
        "lon": "-158.03334",
        "iso": "CK",
        "status": 1,
        "name": "Tongareva Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.016667",
        "size": "small"
    },
    {
        "iata": "RAR",
        "lon": "-159.79852",
        "iso": "CK",
        "status": 1,
        "name": "Rarotonga International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.19992",
        "size": "medium"
    },
    {
        "iata": "ICI",
        "lon": "-179.33333",
        "iso": "FJ",
        "status": 1,
        "name": "Cicia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.75",
        "size": "small"
    },
    {
        "iata": "NAN",
        "lon": "177.45161",
        "iso": "FJ",
        "status": 1,
        "name": "Nadi International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.75327",
        "size": "medium"
    },
    {
        "iata": "PTF",
        "lon": "177.21666",
        "iso": "FJ",
        "status": 1,
        "name": "Malolo Lailai Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.783333",
        "size": "small"
    },
    {
        "iata": "KDV",
        "lon": "178.15607",
        "iso": "FJ",
        "status": 1,
        "name": "Vunisea Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.053968",
        "size": "small"
    },
    {
        "iata": "MNF",
        "lon": "177.11667",
        "iso": "FJ",
        "status": 1,
        "name": "Mana Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.633333",
        "size": "small"
    },
    {
        "iata": "MFJ",
        "lon": "179.93333",
        "iso": "FJ",
        "status": 1,
        "name": "Moala Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.566668",
        "size": "small"
    },
    {
        "iata": "SUV",
        "lon": "178.55981",
        "iso": "FJ",
        "status": 1,
        "name": "Nausori International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.045885",
        "size": "medium"
    },
    {
        "iata": "LEV",
        "lon": "178.83333",
        "iso": "FJ",
        "status": 1,
        "name": "Levuka Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.683332",
        "size": "small"
    },
    {
        "iata": "NGI",
        "lon": "179.33333",
        "iso": "FJ",
        "status": 1,
        "name": "Ngau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.0",
        "size": "small"
    },
    {
        "iata": "LUC",
        "lon": "179.66695",
        "iso": "FJ",
        "status": 1,
        "name": "Laucala Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.748056",
        "size": "small"
    },
    {
        "iata": "LKB",
        "lon": "179.2",
        "iso": "FJ",
        "status": 1,
        "name": "Lakeba Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.2",
        "size": "small"
    },
    {
        "iata": "LBS",
        "lon": "179.33766",
        "iso": "FJ",
        "status": 1,
        "name": "Labasa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.466215",
        "size": "medium"
    },
    {
        "iata": "TVU",
        "lon": "179.87416",
        "iso": "FJ",
        "status": 1,
        "name": "Matei Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.693056",
        "size": "small"
    },
    {
        "iata": "KXF",
        "lon": "179.83333",
        "iso": "FJ",
        "status": 1,
        "name": "Koro Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.333332",
        "size": "small"
    },
    {
        "iata": "RTA",
        "lon": "177.0711",
        "iso": "FJ",
        "status": 1,
        "name": "Rotuma Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.4825",
        "size": "small"
    },
    {
        "iata": "SVU",
        "lon": "179.33913",
        "iso": "FJ",
        "status": 1,
        "name": "Savusavu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.798828",
        "size": "small"
    },
    {
        "iata": "VAU",
        "lon": "177.86667",
        "iso": "FJ",
        "status": 1,
        "name": "Vatukoula Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.5",
        "size": "small"
    },
    {
        "iata": "KAY",
        "lon": "178.56612",
        "iso": "FJ",
        "status": 1,
        "name": "Wakaya Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.041111",
        "size": "small"
    },
    {
        "iata": "ONU",
        "lon": "179.0",
        "iso": "FJ",
        "status": 1,
        "name": "Ono-I-Lau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.333332",
        "size": "small"
    },
    {
        "iata": "YAS",
        "lon": "177.54515",
        "iso": "FJ",
        "status": 1,
        "name": "Yasawa Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.759281",
        "size": "small"
    },
    {
        "iata": "EUA",
        "lon": "-174.95699",
        "iso": "TO",
        "status": 1,
        "name": "Kaufana Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.377012",
        "size": "small"
    },
    {
        "iata": "TBU",
        "lon": "-175.14131",
        "iso": "TO",
        "status": 1,
        "name": "Fua'amotu International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.24117",
        "size": "medium"
    },
    {
        "iata": "HPA",
        "lon": "-174.34088",
        "iso": "TO",
        "status": 1,
        "name": "Lifuka Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.778538",
        "size": "medium"
    },
    {
        "iata": "NFO",
        "lon": "-175.65",
        "iso": "TO",
        "status": 1,
        "name": "Mata'aho Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.6",
        "size": "small"
    },
    {
        "iata": "NTT",
        "lon": "-174.0",
        "iso": "TO",
        "status": 1,
        "name": "Kuini Lavenia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.5",
        "size": "small"
    },
    {
        "iata": "VAV",
        "lon": "-173.96436",
        "iso": "TO",
        "status": 1,
        "name": "Vava'u International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.585537",
        "size": "medium"
    },
    {
        "iata": "VBV",
        "lon": "-178.95",
        "iso": "FJ",
        "status": 1,
        "name": "Vanua Balavu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.233334",
        "size": "small"
    },
    {
        "iata": "VTF",
        "lon": "177.61667",
        "iso": "FJ",
        "status": 1,
        "name": "Vatulele Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.55",
        "size": "small"
    },
    {
        "iata": "GMO",
        "lon": "10.898889",
        "iso": "NG",
        "status": 1,
        "name": "Gombe Lawanti International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.298056",
        "size": "medium"
    },
    {
        "iata": "PHG",
        "lon": "6.833333",
        "iso": "NG",
        "status": 1,
        "name": "Port Harcourt City Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.033333",
        "size": "small"
    },
    {
        "iata": "BCU",
        "lon": "9.8",
        "iso": "NG",
        "status": 1,
        "name": "Bauchi Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "10.366667",
        "size": "small"
    },
    {
        "iata": "QRW",
        "lon": "5.819282",
        "iso": "NG",
        "status": 1,
        "name": "Warri Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.594487",
        "size": "medium"
    },
    {
        "iata": "ABF",
        "lon": "173.30417",
        "iso": "KI",
        "status": 1,
        "name": "Abaiang Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "1.670833",
        "size": "small"
    },
    {
        "iata": "BEZ",
        "lon": "176.0",
        "iso": "KI",
        "status": 1,
        "name": "Beru Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-1.333333",
        "size": "small"
    },
    {
        "iata": "FUN",
        "lon": "179.20833",
        "iso": "TV",
        "status": 1,
        "name": "Funafuti International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.516667",
        "size": "medium"
    },
    {
        "iata": "KUC",
        "lon": "173.38333",
        "iso": "KI",
        "status": 1,
        "name": "Kuria Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "0.233333",
        "size": "small"
    },
    {
        "iata": "MNK",
        "lon": "173.0",
        "iso": "KI",
        "status": 1,
        "name": "Maiana Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "1.0",
        "size": "small"
    },
    {
        "iata": "MZK",
        "lon": "173.66667",
        "iso": "KI",
        "status": 1,
        "name": "Marakei Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "1.983333",
        "size": "small"
    },
    {
        "iata": "MTK",
        "lon": "174.0",
        "iso": "KI",
        "status": 1,
        "name": "Makin Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "3.0",
        "size": "small"
    },
    {
        "iata": "NIG",
        "lon": "176.41667",
        "iso": "KI",
        "status": 1,
        "name": "Nikunau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-1.333333",
        "size": "small"
    },
    {
        "iata": "OOT",
        "lon": "175.56667",
        "iso": "KI",
        "status": 1,
        "name": "Onotoa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-1.916667",
        "size": "small"
    },
    {
        "iata": "TRW",
        "lon": "172.94167",
        "iso": "KI",
        "status": 1,
        "name": "Bonriki International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "1.358333",
        "size": "medium"
    },
    {
        "iata": "AEA",
        "lon": "173.85",
        "iso": "KI",
        "status": 1,
        "name": "Abemama Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "0.483333",
        "size": "small"
    },
    {
        "iata": "TBF",
        "lon": "174.78",
        "iso": "KI",
        "status": 1,
        "name": "Tabiteuea North Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-1.231944",
        "size": "medium"
    },
    {
        "iata": "TMN",
        "lon": "175.98334",
        "iso": "KI",
        "status": 1,
        "name": "Tamana Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-2.483333",
        "size": "small"
    },
    {
        "iata": "NON",
        "lon": "174.35",
        "iso": "KI",
        "status": 1,
        "name": "Nonouti Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-0.680556",
        "size": "small"
    },
    {
        "iata": "AIS",
        "lon": "176.83333",
        "iso": "KI",
        "status": 1,
        "name": "Arorae Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-2.65",
        "size": "small"
    },
    {
        "iata": "TSU",
        "lon": "174.83333",
        "iso": "KI",
        "status": 1,
        "name": "Tabiteuea South Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-1.416667",
        "size": "small"
    },
    {
        "iata": "BBG",
        "lon": "172.75",
        "iso": "KI",
        "status": 1,
        "name": "Butaritari Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "3.166667",
        "size": "small"
    },
    {
        "iata": "AAK",
        "lon": "173.58333",
        "iso": "KI",
        "status": 1,
        "name": "Buariki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "0.166667",
        "size": "small"
    },
    {
        "iata": "IUE",
        "lon": "-169.93333",
        "iso": "NU",
        "status": 1,
        "name": "Niue International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.083332",
        "size": "medium"
    },
    {
        "iata": "FUT",
        "lon": "-178.15",
        "iso": "WF",
        "status": 1,
        "name": "Pointe Vele Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.25",
        "size": "medium"
    },
    {
        "iata": "WLS",
        "lon": "-176.16667",
        "iso": "WF",
        "status": 1,
        "name": "Hihifo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.233333",
        "size": "medium"
    },
    {
        "iata": "MWP",
        "lon": "85.333336",
        "iso": "NP",
        "status": 1,
        "name": "Mountain Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.0",
        "size": "small"
    },
    {
        "iata": "TPJ",
        "lon": "87.683334",
        "iso": "NP",
        "status": 1,
        "name": "Taplejung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.35",
        "size": "small"
    },
    {
        "iata": "OFU",
        "lon": "-169.7",
        "iso": "AS",
        "status": 1,
        "name": "Ofu Village Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.166667",
        "size": "small"
    },
    {
        "iata": "AAU",
        "lon": "-172.6",
        "iso": "WS",
        "status": 1,
        "name": "Asau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.458333",
        "size": "small"
    },
    {
        "iata": "APW",
        "lon": "-171.99724",
        "iso": "WS",
        "status": 1,
        "name": "Faleolo International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.832793",
        "size": "medium"
    },
    {
        "iata": "FGI",
        "lon": "-171.73334",
        "iso": "WS",
        "status": 1,
        "name": "Fagali'i Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.833333",
        "size": "small"
    },
    {
        "iata": "FAQ",
        "lon": "141.95833",
        "iso": "AS",
        "status": 1,
        "name": "Fitiuta Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.616667",
        "size": "medium"
    },
    {
        "iata": "MXS",
        "lon": "-172.23334",
        "iso": "WS",
        "status": 1,
        "name": "Maota Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.716667",
        "size": "small"
    },
    {
        "iata": "PPG",
        "lon": "-170.6964",
        "iso": "AS",
        "status": 1,
        "name": "Pago Pago International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.326389",
        "size": "medium"
    },
    {
        "iata": "PPT",
        "lon": "-149.60938",
        "iso": "PF",
        "status": 1,
        "name": "Faa'a International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.55963",
        "size": "medium"
    },
    {
        "iata": "RUR",
        "lon": "-151.33333",
        "iso": "PF",
        "status": 1,
        "name": "Rurutu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.433332",
        "size": "medium"
    },
    {
        "iata": "TUB",
        "lon": "-149.46666",
        "iso": "PF",
        "status": 1,
        "name": "Tubuai Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.35",
        "size": "medium"
    },
    {
        "iata": "AAA",
        "lon": "-145.41667",
        "iso": "PF",
        "status": 1,
        "name": "Anaa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.05",
        "size": "medium"
    },
    {
        "iata": "FGU",
        "lon": "-141.83333",
        "iso": "PF",
        "status": 1,
        "name": "Fangatau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.05",
        "size": "medium"
    },
    {
        "iata": "TIH",
        "lon": "-148.2338",
        "iso": "PF",
        "status": 1,
        "name": "Tikehau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.118639",
        "size": "medium"
    },
    {
        "iata": "APK",
        "lon": "-146.08333",
        "iso": "PF",
        "status": 1,
        "name": "Apataki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.416667",
        "size": "small"
    },
    {
        "iata": "REA",
        "lon": "-136.4",
        "iso": "PF",
        "status": 1,
        "name": "Reao Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.5",
        "size": "medium"
    },
    {
        "iata": "FAV",
        "lon": "-145.65225",
        "iso": "PF",
        "status": 1,
        "name": "Fakarava Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.053486",
        "size": "medium"
    },
    {
        "iata": "HHZ",
        "lon": "-142.53334",
        "iso": "PF",
        "status": 1,
        "name": "Hikueru Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.533333",
        "size": "small"
    },
    {
        "iata": "XMH",
        "lon": "-146.07051",
        "iso": "PF",
        "status": 1,
        "name": "Manihi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.43795",
        "size": "medium"
    },
    {
        "iata": "GMR",
        "lon": "-134.88757",
        "iso": "PF",
        "status": 1,
        "name": "Totegegie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.084",
        "size": "medium"
    },
    {
        "iata": "KKR",
        "lon": "-146.66667",
        "iso": "PF",
        "status": 1,
        "name": "Kaukura Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.783333",
        "size": "medium"
    },
    {
        "iata": "MKP",
        "lon": "-143.65436",
        "iso": "PF",
        "status": 1,
        "name": "Makemo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.587921",
        "size": "medium"
    },
    {
        "iata": "NAU",
        "lon": "-141.58333",
        "iso": "PF",
        "status": 1,
        "name": "Napuka Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.166667",
        "size": "small"
    },
    {
        "iata": "TKV",
        "lon": "-138.33333",
        "iso": "PF",
        "status": 1,
        "name": "Tatakoto Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.283333",
        "size": "small"
    },
    {
        "iata": "PKP",
        "lon": "-138.96666",
        "iso": "PF",
        "status": 1,
        "name": "Puka Puka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.75",
        "size": "medium"
    },
    {
        "iata": "PUK",
        "lon": "-137.01784",
        "iso": "PF",
        "status": 1,
        "name": "Pukarua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.296125",
        "size": "small"
    },
    {
        "iata": "TKP",
        "lon": "-145.14166",
        "iso": "PF",
        "status": 1,
        "name": "Takapoto Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.45",
        "size": "medium"
    },
    {
        "iata": "AXR",
        "lon": "-146.75",
        "iso": "PF",
        "status": 1,
        "name": "Arutua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.25",
        "size": "medium"
    },
    {
        "iata": "MVT",
        "lon": "-148.41667",
        "iso": "PF",
        "status": 1,
        "name": "Mataiva Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.833333",
        "size": "medium"
    },
    {
        "iata": "NUK",
        "lon": "-138.7",
        "iso": "PF",
        "status": 1,
        "name": "Nukutavake Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.183332",
        "size": "small"
    },
    {
        "iata": "ZTA",
        "lon": "-138.5",
        "iso": "PF",
        "status": 1,
        "name": "Tureia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.816668",
        "size": "small"
    },
    {
        "iata": "AHE",
        "lon": "-146.25694",
        "iso": "PF",
        "status": 1,
        "name": "Ahe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.428056",
        "size": "medium"
    },
    {
        "iata": "KHZ",
        "lon": "-145.11667",
        "iso": "PF",
        "status": 1,
        "name": "Kauehi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.766667",
        "size": "small"
    },
    {
        "iata": "FAC",
        "lon": "-145.33333",
        "iso": "PF",
        "status": 1,
        "name": "Faaite Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.683332",
        "size": "small"
    },
    {
        "iata": "FHZ",
        "lon": "-140.16446",
        "iso": "PF",
        "status": 1,
        "name": "Fakahina Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.99095",
        "size": "small"
    },
    {
        "iata": "TKX",
        "lon": "-145.0264",
        "iso": "PF",
        "status": 1,
        "name": "Takaroa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.457749",
        "size": "medium"
    },
    {
        "iata": "NHV",
        "lon": "-140.22223",
        "iso": "PF",
        "status": 1,
        "name": "Nuku Hiva Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.795214",
        "size": "medium"
    },
    {
        "iata": "AUQ",
        "lon": "-139.00978",
        "iso": "PF",
        "status": 1,
        "name": "Hiva Oa-Atuona Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.769324",
        "size": "medium"
    },
    {
        "iata": "UAP",
        "lon": "-140.08125",
        "iso": "PF",
        "status": 1,
        "name": "Ua Pou Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.343477",
        "size": "small"
    },
    {
        "iata": "UAH",
        "lon": "-139.55",
        "iso": "PF",
        "status": 1,
        "name": "Ua Huka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.933333",
        "size": "small"
    },
    {
        "iata": "BOB",
        "lon": "-151.75447",
        "iso": "PF",
        "status": 1,
        "name": "Bora Bora Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.446207",
        "size": "medium"
    },
    {
        "iata": "TTI",
        "lon": "-149.5",
        "iso": "PF",
        "status": 1,
        "name": "Tetiaroa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.083332",
        "size": "small"
    },
    {
        "iata": "RGI",
        "lon": "-147.65915",
        "iso": "PF",
        "status": 1,
        "name": "Rangiroa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.956334",
        "size": "medium"
    },
    {
        "iata": "HUH",
        "lon": "-151.02826",
        "iso": "PF",
        "status": 1,
        "name": "Huahine-Fare Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.689682",
        "size": "medium"
    },
    {
        "iata": "MOZ",
        "lon": "-149.76414",
        "iso": "PF",
        "status": 1,
        "name": "Moorea Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.490433",
        "size": "medium"
    },
    {
        "iata": "HOI",
        "lon": "-140.96529",
        "iso": "PF",
        "status": 1,
        "name": "Hao Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.06248",
        "size": "medium"
    },
    {
        "iata": "MAU",
        "lon": "-152.24236",
        "iso": "PF",
        "status": 1,
        "name": "Maupiti Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.427385",
        "size": "medium"
    },
    {
        "iata": "RFP",
        "lon": "-151.46666",
        "iso": "PF",
        "status": 1,
        "name": "Raiatea Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.725004",
        "size": "medium"
    },
    {
        "iata": "VHZ",
        "lon": "-138.83333",
        "iso": "PF",
        "status": 1,
        "name": "Vahitahi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.583332",
        "size": "small"
    },
    {
        "iata": "MTV",
        "lon": "167.66667",
        "iso": "VU",
        "status": 1,
        "name": "Mota Lava Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.666667",
        "size": "small"
    },
    {
        "iata": "SLH",
        "lon": "167.55",
        "iso": "VU",
        "status": 1,
        "name": "Sola Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.883333",
        "size": "small"
    },
    {
        "iata": "TOH",
        "lon": "166.75",
        "iso": "VU",
        "status": 1,
        "name": "Torres Airstrip",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.166667",
        "size": "small"
    },
    {
        "iata": "EAE",
        "lon": "168.41667",
        "iso": "VU",
        "status": 1,
        "name": "Sangafa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.166668",
        "size": "small"
    },
    {
        "iata": "CCV",
        "lon": "167.5",
        "iso": "VU",
        "status": 1,
        "name": "Craig Cove Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.2",
        "size": "small"
    },
    {
        "iata": "LOD",
        "lon": "167.96666",
        "iso": "VU",
        "status": 1,
        "name": "Longana Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.316667",
        "size": "small"
    },
    {
        "iata": "SSR",
        "lon": "168.3",
        "iso": "VU",
        "status": 1,
        "name": "Sara Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.5",
        "size": "small"
    },
    {
        "iata": "PBJ",
        "lon": "168.21666",
        "iso": "VU",
        "status": 1,
        "name": "Tavie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.433332",
        "size": "small"
    },
    {
        "iata": "LPM",
        "lon": "167.81667",
        "iso": "VU",
        "status": 1,
        "name": "Lamap Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.466667",
        "size": "small"
    },
    {
        "iata": "LNB",
        "lon": "168.18333",
        "iso": "VU",
        "status": 1,
        "name": "Lamen Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.583332",
        "size": "small"
    },
    {
        "iata": "MWF",
        "lon": "168.16667",
        "iso": "VU",
        "status": 1,
        "name": "Naone Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.166667",
        "size": "small"
    },
    {
        "iata": "LNE",
        "lon": "168.17007",
        "iso": "VU",
        "status": 1,
        "name": "Lonorore Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.860106",
        "size": "small"
    },
    {
        "iata": "NUS",
        "lon": "167.4",
        "iso": "VU",
        "status": 1,
        "name": "Norsup Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.058332",
        "size": "small"
    },
    {
        "iata": "ZGU",
        "lon": "167.58827",
        "iso": "VU",
        "status": 1,
        "name": "Gaua Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.220086",
        "size": "medium"
    },
    {
        "iata": "RCL",
        "lon": "153.07333",
        "iso": "VU",
        "status": 1,
        "name": "Redcliffe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.207035",
        "size": "small"
    },
    {
        "iata": "SON",
        "lon": "167.21873",
        "iso": "VU",
        "status": 1,
        "name": "Santo Pekoa International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.508251",
        "size": "medium"
    },
    {
        "iata": "TGH",
        "lon": "168.54666",
        "iso": "VU",
        "status": 1,
        "name": "Tongoa Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.90361",
        "size": "small"
    },
    {
        "iata": "ULB",
        "lon": "168.33333",
        "iso": "VU",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-16.416668",
        "size": "small"
    },
    {
        "iata": "VLS",
        "lon": "168.2",
        "iso": "VU",
        "status": 1,
        "name": "Valesdir Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.8",
        "size": "small"
    },
    {
        "iata": "WLH",
        "lon": "167.7",
        "iso": "VU",
        "status": 1,
        "name": "Walaha Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.416667",
        "size": "small"
    },
    {
        "iata": "SWJ",
        "lon": "167.16667",
        "iso": "VU",
        "status": 1,
        "name": "Southwest Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.25",
        "size": "small"
    },
    {
        "iata": "OLJ",
        "lon": "166.56003",
        "iso": "VU",
        "status": 1,
        "name": "North West Santo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.884605",
        "size": "small"
    },
    {
        "iata": "AUY",
        "lon": "169.66667",
        "iso": "VU",
        "status": 1,
        "name": "Anelghowhat Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.333332",
        "size": "small"
    },
    {
        "iata": "AWD",
        "lon": "169.5",
        "iso": "VU",
        "status": 1,
        "name": "Aniwa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.25",
        "size": "small"
    },
    {
        "iata": "DLY",
        "lon": "169.15",
        "iso": "VU",
        "status": 1,
        "name": "Dillon's Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.7",
        "size": "small"
    },
    {
        "iata": "FTA",
        "lon": "170.25",
        "iso": "VU",
        "status": 1,
        "name": "Futuna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.416668",
        "size": "small"
    },
    {
        "iata": "IPA",
        "lon": "169.18333",
        "iso": "VU",
        "status": 1,
        "name": "Ipota Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.75",
        "size": "small"
    },
    {
        "iata": "UIQ",
        "lon": "168.16667",
        "iso": "VU",
        "status": 1,
        "name": "Quion Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.0",
        "size": "small"
    },
    {
        "iata": "VLI",
        "lon": "168.3195",
        "iso": "VU",
        "status": 1,
        "name": "Port Vila Bauerfield Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.701853",
        "size": "medium"
    },
    {
        "iata": "TAH",
        "lon": "169.22821",
        "iso": "VU",
        "status": 1,
        "name": "Tanna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.458445",
        "size": "medium"
    },
    {
        "iata": "TGJ",
        "lon": "167.80293",
        "iso": "NC",
        "status": 1,
        "name": "Tiga Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.098358",
        "size": "medium"
    },
    {
        "iata": "BMY",
        "lon": "163.66667",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-19.75",
        "size": "medium"
    },
    {
        "iata": "KNQ",
        "lon": "164.83778",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-21.053333",
        "size": "medium"
    },
    {
        "iata": "ILP",
        "lon": "167.4475",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-22.589722",
        "size": "medium"
    },
    {
        "iata": "HLU",
        "lon": "166.06667",
        "iso": "NC",
        "status": 1,
        "name": "Nesson Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.033333",
        "size": "small"
    },
    {
        "iata": "KOC",
        "lon": "164.41667",
        "iso": "NC",
        "status": 1,
        "name": "Koumac Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.5",
        "size": "medium"
    },
    {
        "iata": "LIF",
        "lon": "167.25",
        "iso": "NC",
        "status": 1,
        "name": "Lifou Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.766666",
        "size": "medium"
    },
    {
        "iata": "GEA",
        "lon": "166.47078",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-22.260536",
        "size": "medium"
    },
    {
        "iata": "IOU",
        "lon": "166.78305",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-22.463888",
        "size": "small"
    },
    {
        "iata": "PUV",
        "lon": "164.1014",
        "iso": "NC",
        "status": 1,
        "name": "Poum Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.294443",
        "size": "small"
    },
    {
        "iata": "PDC",
        "lon": "165.0",
        "iso": "NC",
        "status": 1,
        "name": "Mueo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.316668",
        "size": "small"
    },
    {
        "iata": "MEE",
        "lon": "168.03609",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-21.482311",
        "size": "medium"
    },
    {
        "iata": "TOU",
        "lon": "165.25",
        "iso": "NC",
        "status": 1,
        "name": "Touho Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.8",
        "size": "medium"
    },
    {
        "iata": "UVE",
        "lon": "166.57198",
        "iso": "NC",
        "status": 1,
        "name": null,
        "continent": "OC",
        "type": "airport",
        "lat": "-20.64242",
        "size": "medium"
    },
    {
        "iata": "NOU",
        "lon": "166.21611",
        "iso": "NC",
        "status": 1,
        "name": "La Tontouta International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.01639",
        "size": "medium"
    },
    {
        "iata": "AKL",
        "lon": "174.78352",
        "iso": "NZ",
        "status": 1,
        "name": "Auckland International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.004787",
        "size": "large"
    },
    {
        "iata": "TUO",
        "lon": "176.08235",
        "iso": "NZ",
        "status": 1,
        "name": "Taupo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.741432",
        "size": "medium"
    },
    {
        "iata": "AMZ",
        "lon": "174.96666",
        "iso": "NZ",
        "status": 1,
        "name": "Ardmore Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.033333",
        "size": "medium"
    },
    {
        "iata": "ASG",
        "lon": "171.78334",
        "iso": "NZ",
        "status": 1,
        "name": "Ashburton Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-43.9",
        "size": "small"
    },
    {
        "iata": "CHC",
        "lon": "172.5389",
        "iso": "NZ",
        "status": 1,
        "name": "Christchurch International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-43.488655",
        "size": "large"
    },
    {
        "iata": "CHT",
        "lon": "-176.35",
        "iso": "NZ",
        "status": 1,
        "name": "Chatham Islands-Tuuta Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-43.8",
        "size": "medium"
    },
    {
        "iata": "CMV",
        "lon": "175.66667",
        "iso": "NZ",
        "status": 1,
        "name": "Coromandel Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.0",
        "size": "small"
    },
    {
        "iata": "DGR",
        "lon": "173.88333",
        "iso": "NZ",
        "status": 1,
        "name": "Dargaville Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.933334",
        "size": "small"
    },
    {
        "iata": "DUD",
        "lon": "170.19905",
        "iso": "NZ",
        "status": 1,
        "name": "Dunedin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-45.92387",
        "size": "medium"
    },
    {
        "iata": "FGL",
        "iso": "NZ",
        "status": 1,
        "name": "Fox Heliport",
        "continent": "OC",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "WHO",
        "lon": "170.18333",
        "iso": "NZ",
        "status": 1,
        "name": "Franz Josef Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-43.38333",
        "size": "small"
    },
    {
        "iata": "GBZ",
        "lon": "175.41667",
        "iso": "NZ",
        "status": 1,
        "name": "Great Barrier Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.25",
        "size": "small"
    },
    {
        "iata": "GMN",
        "lon": "171.2",
        "iso": "NZ",
        "status": 1,
        "name": "Greymouth Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-42.45",
        "size": "small"
    },
    {
        "iata": "GIS",
        "lon": "177.98228",
        "iso": "NZ",
        "status": 1,
        "name": "Gisborne Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.661934",
        "size": "medium"
    },
    {
        "iata": "GTN",
        "lon": "170.13611",
        "iso": "NZ",
        "status": 1,
        "name": "Glentanner Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-43.766666",
        "size": "medium"
    },
    {
        "iata": "HKK",
        "lon": "170.98346",
        "iso": "NZ",
        "status": 1,
        "name": "Hokitika Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "-42.714912",
        "size": "medium"
    },
    {
        "iata": "HLZ",
        "lon": "175.33603",
        "iso": "NZ",
        "status": 1,
        "name": "Hamilton International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.86622",
        "size": "medium"
    },
    {
        "iata": "WIK",
        "lon": "175.08333",
        "iso": "NZ",
        "status": 1,
        "name": "Waiheke Reeve Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.833332",
        "size": "small"
    },
    {
        "iata": "KBZ",
        "lon": "173.68333",
        "iso": "NZ",
        "status": 1,
        "name": "Kaikoura Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-42.416668",
        "size": "small"
    },
    {
        "iata": "KKE",
        "lon": "173.91237",
        "iso": "NZ",
        "status": 1,
        "name": "Kerikeri Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.263283",
        "size": "medium"
    },
    {
        "iata": "KKO",
        "lon": "173.81667",
        "iso": "NZ",
        "status": 1,
        "name": "Kaikohe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.452778",
        "size": "small"
    },
    {
        "iata": "KAT",
        "lon": "173.28542",
        "iso": "NZ",
        "status": 1,
        "name": "Kaitaia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.066284",
        "size": "medium"
    },
    {
        "iata": "ALR",
        "lon": "169.36945",
        "iso": "NZ",
        "status": 1,
        "name": "Alexandra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-45.21389",
        "size": "medium"
    },
    {
        "iata": "MTA",
        "lon": "175.76666",
        "iso": "NZ",
        "status": 1,
        "name": "Matamata Glider Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.816666",
        "size": "small"
    },
    {
        "iata": "MON",
        "lon": "170.13611",
        "iso": "NZ",
        "status": 1,
        "name": "Mount Cook Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-43.766666",
        "size": "medium"
    },
    {
        "iata": "MFN",
        "lon": "167.91112",
        "iso": "NZ",
        "status": 1,
        "name": "Milford Sound Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-44.669445",
        "size": "small"
    },
    {
        "iata": "MZP",
        "lon": "173.0",
        "iso": "NZ",
        "status": 1,
        "name": "Motueka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.11667",
        "size": "small"
    },
    {
        "iata": "TEU",
        "lon": "167.7264",
        "iso": "NZ",
        "status": 1,
        "name": "Manapouri Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-45.419445",
        "size": "medium"
    },
    {
        "iata": "MRO",
        "lon": "175.63333",
        "iso": "NZ",
        "status": 1,
        "name": "Hood Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.975",
        "size": "medium"
    },
    {
        "iata": "NPL",
        "lon": "174.17932",
        "iso": "NZ",
        "status": 1,
        "name": "New Plymouth Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-39.01013",
        "size": "medium"
    },
    {
        "iata": "NPE",
        "lon": "176.87225",
        "iso": "NZ",
        "status": 1,
        "name": "Napier Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-39.46667",
        "size": "medium"
    },
    {
        "iata": "NSN",
        "lon": "173.22498",
        "iso": "NZ",
        "status": 1,
        "name": "Nelson Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.299976",
        "size": "medium"
    },
    {
        "iata": "IVC",
        "lon": "168.32175",
        "iso": "NZ",
        "status": 1,
        "name": "Invercargill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-46.415432",
        "size": "medium"
    },
    {
        "iata": "OHA",
        "lon": "175.38667",
        "iso": "NZ",
        "status": 1,
        "name": "RNZAF Base Ohakea",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.208332",
        "size": "medium"
    },
    {
        "iata": "OAM",
        "lon": "171.0819",
        "iso": "NZ",
        "status": 1,
        "name": "Oamaru Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-44.97028",
        "size": "medium"
    },
    {
        "iata": "PMR",
        "lon": "175.62126",
        "iso": "NZ",
        "status": 1,
        "name": "Palmerston North Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.323532",
        "size": "medium"
    },
    {
        "iata": "PCN",
        "lon": "174.03334",
        "iso": "NZ",
        "status": 1,
        "name": "Picton Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.35",
        "size": "small"
    },
    {
        "iata": "PPQ",
        "lon": "174.9876",
        "iso": "NZ",
        "status": 1,
        "name": "Paraparaumu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.901638",
        "size": "medium"
    },
    {
        "iata": "ZQN",
        "lon": "168.7391",
        "iso": "NZ",
        "status": 1,
        "name": "Queenstown International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-45.022038",
        "size": "medium"
    },
    {
        "iata": "RAG",
        "lon": "174.86667",
        "iso": "NZ",
        "status": 1,
        "name": "Raglan Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.833332",
        "size": "small"
    },
    {
        "iata": "SZS",
        "lon": "167.86667",
        "iso": "NZ",
        "status": 1,
        "name": "Ryans Creek Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-47.0",
        "size": "small"
    },
    {
        "iata": "ROT",
        "lon": "176.31746",
        "iso": "NZ",
        "status": 1,
        "name": "Rotorua Regional Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.10982",
        "size": "medium"
    },
    {
        "iata": "TRG",
        "lon": "176.19772",
        "iso": "NZ",
        "status": 1,
        "name": "Tauranga Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.67093",
        "size": "medium"
    },
    {
        "iata": "TMZ",
        "lon": "175.56667",
        "iso": "NZ",
        "status": 1,
        "name": "Thames Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.11667",
        "size": "small"
    },
    {
        "iata": "KTF",
        "lon": "172.8",
        "iso": "NZ",
        "status": 1,
        "name": "Takaka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.85",
        "size": "small"
    },
    {
        "iata": "TKZ",
        "lon": "175.83333",
        "iso": "NZ",
        "status": 1,
        "name": "Tokoroa Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.216667",
        "size": "small"
    },
    {
        "iata": "THH",
        "lon": "174.71666",
        "iso": "NZ",
        "status": 1,
        "name": "Taharoa Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.2",
        "size": "small"
    },
    {
        "iata": "TIU",
        "lon": "171.22632",
        "iso": "NZ",
        "status": 1,
        "name": "Timaru Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-44.303448",
        "size": "medium"
    },
    {
        "iata": "TWZ",
        "lon": "170.11722",
        "iso": "NZ",
        "status": 1,
        "name": "Pukaki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-44.234165",
        "size": "medium"
    },
    {
        "iata": "BHE",
        "lon": "173.86765",
        "iso": "NZ",
        "status": 1,
        "name": "Woodbourne Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.513588",
        "size": "medium"
    },
    {
        "iata": "WKA",
        "lon": "169.24425",
        "iso": "NZ",
        "status": 1,
        "name": "Wanaka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-44.72336",
        "size": "medium"
    },
    {
        "iata": "WHK",
        "lon": "176.91748",
        "iso": "NZ",
        "status": 1,
        "name": "Whakatane Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.924675",
        "size": "medium"
    },
    {
        "iata": "WLG",
        "lon": "174.81216",
        "iso": "NZ",
        "status": 1,
        "name": "Wellington International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.329037",
        "size": "large"
    },
    {
        "iata": "WIR",
        "lon": "177.41667",
        "iso": "NZ",
        "status": 1,
        "name": "Wairoa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-39.033333",
        "size": "medium"
    },
    {
        "iata": "WRE",
        "lon": "174.36403",
        "iso": "NZ",
        "status": 1,
        "name": "Whangarei Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.76742",
        "size": "medium"
    },
    {
        "iata": "WSZ",
        "lon": "171.57858",
        "iso": "NZ",
        "status": 1,
        "name": "Westport Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.74002",
        "size": "medium"
    },
    {
        "iata": "WTZ",
        "lon": "175.678",
        "iso": "NZ",
        "status": 1,
        "name": "Whitianga Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.8313",
        "size": "small"
    },
    {
        "iata": "WAG",
        "lon": "175.02444",
        "iso": "NZ",
        "status": 1,
        "name": "Wanganui Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-39.960922",
        "size": "medium"
    },
    {
        "iata": "O62",
        "iso": "US",
        "status": 0,
        "name": "Carmel Valley",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "BZF",
        "iso": "US",
        "status": 1,
        "name": "Benton Field",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OAA",
        "iso": "AF",
        "status": 1,
        "name": "Shank Air Base",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "BIN",
        "lon": "67.82361",
        "iso": "AF",
        "status": 1,
        "name": "Bamiyan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.80417",
        "size": "small"
    },
    {
        "iata": "BST",
        "lon": "64.36667",
        "iso": "AF",
        "status": 1,
        "name": "Bost Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.55",
        "size": "small"
    },
    {
        "iata": "CCN",
        "lon": "65.26667",
        "iso": "AF",
        "status": 1,
        "name": "Chakcharan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.533333",
        "size": "small"
    },
    {
        "iata": "DAZ",
        "lon": "70.88333",
        "iso": "AF",
        "status": 1,
        "name": "Darwaz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.466667",
        "size": "small"
    },
    {
        "iata": "FAH",
        "lon": "62.11667",
        "iso": "AF",
        "status": 1,
        "name": "Farah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.36667",
        "size": "small"
    },
    {
        "iata": "FBD",
        "lon": "70.566666",
        "iso": "AF",
        "status": 1,
        "name": "Faizabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.1",
        "size": "small"
    },
    {
        "iata": "KWH",
        "lon": "66.333336",
        "iso": "AF",
        "status": 1,
        "name": "Khwahan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.916668",
        "size": "small"
    },
    {
        "iata": "HEA",
        "lon": "62.22599",
        "iso": "AF",
        "status": 1,
        "name": "Herat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.212696",
        "size": "medium"
    },
    {
        "iata": "OAI",
        "lon": "69.27051",
        "iso": "AF",
        "status": 1,
        "name": "Bagram Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "34.959614",
        "size": "medium"
    },
    {
        "iata": "JAA",
        "lon": "70.46667",
        "iso": "AF",
        "status": 1,
        "name": "Jalalabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.433334",
        "size": "medium"
    },
    {
        "iata": "KBL",
        "lon": "69.21152",
        "iso": "AF",
        "status": 1,
        "name": "Kabul International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.56069",
        "size": "medium"
    },
    {
        "iata": "KDH",
        "lon": "65.8475",
        "iso": "AF",
        "status": 1,
        "name": "Kandahar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.506945",
        "size": "medium"
    },
    {
        "iata": "KHT",
        "lon": "72.0",
        "iso": "AF",
        "status": 1,
        "name": "Khost Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.0",
        "size": "small"
    },
    {
        "iata": "MMZ",
        "lon": "64.73333",
        "iso": "AF",
        "status": 1,
        "name": "Maimana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.916668",
        "size": "medium"
    },
    {
        "iata": "MZR",
        "lon": "67.20849",
        "iso": "AF",
        "status": 1,
        "name": "Mazar I Sharif Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.70919",
        "size": "medium"
    },
    {
        "iata": "LQN",
        "lon": "63.666668",
        "iso": "AF",
        "status": 1,
        "name": "Qala-I-Naw Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.95",
        "size": "small"
    },
    {
        "iata": "OAS",
        "iso": "AF",
        "status": 1,
        "name": "Sharana Airstrip",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OAH",
        "iso": "AF",
        "status": 1,
        "name": "Shindand Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "SGA",
        "lon": "71.71667",
        "iso": "AF",
        "status": 1,
        "name": "Sheghnan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.033333",
        "size": "small"
    },
    {
        "iata": "TII",
        "lon": "65.63333",
        "iso": "AF",
        "status": 1,
        "name": "Tereen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.86667",
        "size": "small"
    },
    {
        "iata": "UND",
        "lon": "68.909164",
        "iso": "AF",
        "status": 1,
        "name": "Konduz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.660557",
        "size": "medium"
    },
    {
        "iata": "OAZ",
        "lon": "64.224724",
        "iso": "AF",
        "status": 1,
        "name": "Camp Bastion Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.86389",
        "size": "small"
    },
    {
        "iata": "ZAJ",
        "lon": "61.983334",
        "iso": "AF",
        "status": 1,
        "name": "Zaranj Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.1",
        "size": "small"
    },
    {
        "iata": "BAH",
        "lon": "50.62605",
        "iso": "BH",
        "status": 1,
        "name": "Bahrain International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.26918",
        "size": "large"
    },
    {
        "iata": "OBK",
        "iso": "US",
        "status": 0,
        "name": "Sky Harbor Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "AHB",
        "lon": "42.657574",
        "iso": "SA",
        "status": 1,
        "name": "Abha Regional Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.23429",
        "size": "medium"
    },
    {
        "iata": "HOF",
        "lon": "49.48746",
        "iso": "SA",
        "status": 1,
        "name": "Al Ahsa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.294783",
        "size": "medium"
    },
    {
        "iata": "ABT",
        "lon": "41.64002",
        "iso": "SA",
        "status": 1,
        "name": "Al Baha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.29401",
        "size": "medium"
    },
    {
        "iata": "BHH",
        "lon": "42.618484",
        "iso": "SA",
        "status": 1,
        "name": "Bisha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.994509",
        "size": "medium"
    },
    {
        "iata": "DMM",
        "lon": "49.79778",
        "iso": "SA",
        "status": 1,
        "name": "King Fahd International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.471111",
        "size": "large"
    },
    {
        "iata": "DHA",
        "lon": "50.17475",
        "iso": "SA",
        "status": 1,
        "name": "King Abdulaziz Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "26.39286",
        "size": "large"
    },
    {
        "iata": "DWD",
        "lon": "44.1287",
        "iso": "SA",
        "status": 1,
        "name": "Dawadmi Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.445545",
        "size": "medium"
    },
    {
        "iata": "GIZ",
        "lon": "42.582344",
        "iso": "SA",
        "status": 1,
        "name": "Jizan Regional Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.898949",
        "size": "medium"
    },
    {
        "iata": "ELQ",
        "lon": "43.768177",
        "iso": "SA",
        "status": 1,
        "name": "Gassim Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.305029",
        "size": "medium"
    },
    {
        "iata": "URY",
        "lon": "37.275005",
        "iso": "SA",
        "status": 1,
        "name": "Guriat Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.409622",
        "size": "medium"
    },
    {
        "iata": "HAS",
        "lon": "41.690483",
        "iso": "SA",
        "status": 1,
        "name": "Hail Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.438158",
        "size": "medium"
    },
    {
        "iata": "QJB",
        "iso": "SA",
        "status": 1,
        "name": "Jubail Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "JED",
        "lon": "39.150578",
        "iso": "SA",
        "status": 1,
        "name": "King Abdulaziz International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.670233",
        "size": "large"
    },
    {
        "iata": "HBT",
        "lon": "45.522972",
        "iso": "SA",
        "status": 1,
        "name": "King Khaled Military City Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.911552",
        "size": "medium"
    },
    {
        "iata": "KMX",
        "lon": "42.716667",
        "iso": "SA",
        "status": 1,
        "name": "King Khaled Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "18.3",
        "size": "medium"
    },
    {
        "iata": "MED",
        "lon": "39.698967",
        "iso": "SA",
        "status": 1,
        "name": "Prince Mohammad Bin Abdulaziz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.544369",
        "size": "large"
    },
    {
        "iata": "EAM",
        "lon": "44.414646",
        "iso": "SA",
        "status": 1,
        "name": "Nejran Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.613281",
        "size": "medium"
    },
    {
        "iata": "AQI",
        "lon": "46.121315",
        "iso": "SA",
        "status": 1,
        "name": "Hafr Al Batin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.334408",
        "size": "medium"
    },
    {
        "iata": "AKH",
        "lon": "47.580555",
        "iso": "SA",
        "status": 1,
        "name": "Prince Sultan Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "24.063334",
        "size": "medium"
    },
    {
        "iata": "RAH",
        "lon": "43.488785",
        "iso": "SA",
        "status": 1,
        "name": "Rafha Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.623463",
        "size": "medium"
    },
    {
        "iata": "RUH",
        "lon": "46.702877",
        "iso": "SA",
        "status": 1,
        "name": "King Khaled International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.95929",
        "size": "large"
    },
    {
        "iata": "RAE",
        "lon": "41.137115",
        "iso": "SA",
        "status": 1,
        "name": "Arar Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.903028",
        "size": "medium"
    },
    {
        "iata": "XXN",
        "iso": "SA",
        "status": 1,
        "name": "Riyadh Air Base",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SHW",
        "lon": "47.111984",
        "iso": "SA",
        "status": 1,
        "name": "Sharurah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.46946",
        "size": "medium"
    },
    {
        "iata": "AJF",
        "lon": "40.101486",
        "iso": "SA",
        "status": 1,
        "name": "Al-Jawf Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.788668",
        "size": "medium"
    },
    {
        "iata": "SLF",
        "lon": "45.61667",
        "iso": "SA",
        "status": 1,
        "name": "Sulayel Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.466667",
        "size": "small"
    },
    {
        "iata": "TUU",
        "lon": "36.608288",
        "iso": "SA",
        "status": 1,
        "name": "Tabuk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.375784",
        "size": "medium"
    },
    {
        "iata": "TIF",
        "lon": "40.552685",
        "iso": "SA",
        "status": 1,
        "name": "Taif Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.480364",
        "size": "medium"
    },
    {
        "iata": "TUI",
        "lon": "38.73383",
        "iso": "SA",
        "status": 1,
        "name": "Turaif Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.68873",
        "size": "medium"
    },
    {
        "iata": "EWD",
        "lon": "-159.76222",
        "iso": "SA",
        "status": 1,
        "name": "Wadi Al Dawasir Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.45639",
        "size": "medium"
    },
    {
        "iata": "EJH",
        "lon": "36.47481",
        "iso": "SA",
        "status": 1,
        "name": "Al Wajh Domestic Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.207716",
        "size": "medium"
    },
    {
        "iata": "YNB",
        "lon": "38.06302",
        "iso": "SA",
        "status": 1,
        "name": "Yenbo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.140734",
        "size": "medium"
    },
    {
        "iata": "ZUL",
        "lon": "44.833332",
        "iso": "SA",
        "status": 1,
        "name": "Zilfi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.25",
        "size": "small"
    },
    {
        "iata": "OHE",
        "lon": "122.42059",
        "iso": "CN",
        "status": 1,
        "name": "Gu-Lian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "52.92113",
        "size": "medium"
    },
    {
        "iata": "ABD",
        "lon": "48.226597",
        "iso": "IR",
        "status": 1,
        "name": "Abadan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.363905",
        "size": "medium"
    },
    {
        "iata": "OMI",
        "lon": "49.533333",
        "iso": "IR",
        "status": 1,
        "name": "Omidiyeh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.833332",
        "size": "small"
    },
    {
        "iata": "MRX",
        "lon": "49.16722",
        "iso": "IR",
        "status": 1,
        "name": "Mahshahr Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.535557",
        "size": "medium"
    },
    {
        "iata": "AWZ",
        "lon": "48.74668",
        "iso": "IR",
        "status": 1,
        "name": "Ahwaz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.34292",
        "size": "medium"
    },
    {
        "iata": "AEU",
        "lon": "55.033913",
        "iso": "IR",
        "status": 1,
        "name": "Abumusa Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.875475",
        "size": "medium"
    },
    {
        "iata": "BUZ",
        "lon": "50.825424",
        "iso": "IR",
        "status": 1,
        "name": "Bushehr Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.958284",
        "size": "medium"
    },
    {
        "iata": "AOY",
        "lon": "52.61667",
        "iso": "IR",
        "status": 1,
        "name": "Asaloyeh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.466667",
        "size": "medium"
    },
    {
        "iata": "KNR",
        "lon": "52.350834",
        "iso": "IR",
        "status": 1,
        "name": "Jam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.818056",
        "size": "medium"
    },
    {
        "iata": "KIH",
        "lon": "53.972084",
        "iso": "IR",
        "status": 1,
        "name": "Kish International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.532276",
        "size": "medium"
    },
    {
        "iata": "BDH",
        "lon": "54.829147",
        "iso": "IR",
        "status": 1,
        "name": "Bandar Lengeh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.529486",
        "size": "medium"
    },
    {
        "iata": "YEH",
        "iso": "IR",
        "status": 1,
        "name": "Persian Gulf International Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "KHK",
        "lon": "50.323612",
        "iso": "IR",
        "status": 1,
        "name": "Khark Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.261667",
        "size": "medium"
    },
    {
        "iata": "SXI",
        "lon": "54.533333",
        "iso": "IR",
        "status": 1,
        "name": "Sirri Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.916668",
        "size": "medium"
    },
    {
        "iata": "LVP",
        "lon": "53.35611",
        "iso": "IR",
        "status": 1,
        "name": "Lavan Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.810278",
        "size": "medium"
    },
    {
        "iata": "KSH",
        "lon": "47.145126",
        "iso": "IR",
        "status": 1,
        "name": "Shahid Ashrafi Esfahani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.355915",
        "size": "medium"
    },
    {
        "iata": "IIL",
        "lon": "46.399235",
        "iso": "IR",
        "status": 1,
        "name": "Ilam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.58637",
        "size": "medium"
    },
    {
        "iata": "KHD",
        "lon": "48.280834",
        "iso": "IR",
        "status": 1,
        "name": "Khoram Abad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.43861",
        "size": "medium"
    },
    {
        "iata": "SDG",
        "lon": "47.013767",
        "iso": "IR",
        "status": 1,
        "name": "Sanandaj Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.251472",
        "size": "medium"
    },
    {
        "iata": "IFH",
        "lon": "51.55",
        "iso": "IR",
        "status": 1,
        "name": "Hesa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.916668",
        "size": "small"
    },
    {
        "iata": "IFN",
        "lon": "51.87638",
        "iso": "IR",
        "status": 1,
        "name": "Esfahan Shahid Beheshti International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.745686",
        "size": "medium"
    },
    {
        "iata": "CQD",
        "lon": "50.837536",
        "iso": "IR",
        "status": 1,
        "name": "Shahrekord Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.294453",
        "size": "medium"
    },
    {
        "iata": "RAS",
        "lon": "49.619587",
        "iso": "IR",
        "status": 1,
        "name": "Rasht Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.321815",
        "size": "medium"
    },
    {
        "iata": "HDM",
        "lon": "48.53732",
        "iso": "IR",
        "status": 1,
        "name": "Hamadan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.867146",
        "size": "medium"
    },
    {
        "iata": "AJK",
        "lon": "49.833332",
        "iso": "IR",
        "status": 1,
        "name": "Arak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.13333",
        "size": "small"
    },
    {
        "iata": "IKA",
        "lon": "51.1548",
        "iso": "IR",
        "status": 1,
        "name": "Imam Khomeini International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.40863",
        "size": "large"
    },
    {
        "iata": "THR",
        "lon": "51.32187",
        "iso": "IR",
        "status": 1,
        "name": "Mehrabad International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.691532",
        "size": "large"
    },
    {
        "iata": "BND",
        "lon": "56.368385",
        "iso": "IR",
        "status": 1,
        "name": "Bandar Abbas International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.21059",
        "size": "medium"
    },
    {
        "iata": "KER",
        "lon": "56.96192",
        "iso": "IR",
        "status": 1,
        "name": "Kerman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.258778",
        "size": "medium"
    },
    {
        "iata": "BXR",
        "lon": "58.449356",
        "iso": "IR",
        "status": 1,
        "name": "Bam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.080082",
        "size": "medium"
    },
    {
        "iata": "HDR",
        "lon": "56.166668",
        "iso": "IR",
        "status": 1,
        "name": "Havadarya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.05",
        "size": "small"
    },
    {
        "iata": "RJN",
        "lon": "56.056667",
        "iso": "IR",
        "status": 1,
        "name": "Rafsanjan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.296944",
        "size": "medium"
    },
    {
        "iata": "SYJ",
        "lon": "55.664917",
        "iso": "IR",
        "status": 1,
        "name": "Sirjan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.550123",
        "size": "medium"
    },
    {
        "iata": "XBJ",
        "lon": "59.25499",
        "iso": "IR",
        "status": 1,
        "name": "Birjand Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.89905",
        "size": "medium"
    },
    {
        "iata": "CKT",
        "lon": "61.07",
        "iso": "IR",
        "status": 1,
        "name": "Sarakhs Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.488056",
        "size": "medium"
    },
    {
        "iata": "MHD",
        "lon": "59.64165",
        "iso": "IR",
        "status": 1,
        "name": "Mashhad International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.227173",
        "size": "large"
    },
    {
        "iata": "BJB",
        "lon": "57.30885",
        "iso": "IR",
        "status": 1,
        "name": "Bojnord Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.48972",
        "size": "medium"
    },
    {
        "iata": "AFZ",
        "lon": "57.60332",
        "iso": "IR",
        "status": 1,
        "name": "Sabzevar National Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.171513",
        "size": "medium"
    },
    {
        "iata": "TCX",
        "lon": "56.895557",
        "iso": "IR",
        "status": 1,
        "name": "Tabas Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.667168",
        "size": "medium"
    },
    {
        "iata": "BBL",
        "lon": "52.666668",
        "iso": "IR",
        "status": 1,
        "name": "Babolsar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.65",
        "size": "small"
    },
    {
        "iata": "GBT",
        "lon": "54.410515",
        "iso": "IR",
        "status": 1,
        "name": "Gorgan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.90851",
        "size": "medium"
    },
    {
        "iata": "BSM",
        "lon": "52.350464",
        "iso": "IR",
        "status": 1,
        "name": "Bishe Kola Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "36.65551",
        "size": "small"
    },
    {
        "iata": "NSH",
        "lon": "51.5",
        "iso": "IR",
        "status": 1,
        "name": "Noshahr Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.666668",
        "size": "medium"
    },
    {
        "iata": "RZR",
        "lon": "50.68244",
        "iso": "IR",
        "status": 1,
        "name": "Ramsar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.904625",
        "size": "medium"
    },
    {
        "iata": "SRY",
        "lon": "53.197277",
        "iso": "IR",
        "status": 1,
        "name": "Dasht-e Naz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.635727",
        "size": "medium"
    },
    {
        "iata": "FAZ",
        "lon": "53.7",
        "iso": "IR",
        "status": 1,
        "name": "Fasa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.933332",
        "size": "medium"
    },
    {
        "iata": "LRR",
        "lon": "54.38276",
        "iso": "IR",
        "status": 1,
        "name": "Lar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.66969",
        "size": "medium"
    },
    {
        "iata": "SYZ",
        "lon": "52.58997",
        "iso": "IR",
        "status": 1,
        "name": "Shiraz Shahid Dastghaib International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.54613",
        "size": "large"
    },
    {
        "iata": "KHY",
        "lon": "44.97139",
        "iso": "IR",
        "status": 1,
        "name": "Khoy Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.42917",
        "size": "small"
    },
    {
        "iata": "ADU",
        "lon": "48.42125",
        "iso": "IR",
        "status": 1,
        "name": "Ardabil Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.323826",
        "size": "medium"
    },
    {
        "iata": "ACP",
        "lon": "46.145115",
        "iso": "IR",
        "status": 1,
        "name": "Sahand Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.347244",
        "size": "small"
    },
    {
        "iata": "PFQ",
        "lon": "47.87752",
        "iso": "IR",
        "status": 1,
        "name": "Parsabade Moghan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.60689",
        "size": "small"
    },
    {
        "iata": "OMH",
        "lon": "45.05877",
        "iso": "IR",
        "status": 1,
        "name": "Urmia Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.662525",
        "size": "medium"
    },
    {
        "iata": "TBZ",
        "lon": "46.244274",
        "iso": "IR",
        "status": 1,
        "name": "Tabriz International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.12285",
        "size": "large"
    },
    {
        "iata": "AZD",
        "lon": "54.283264",
        "iso": "IR",
        "status": 1,
        "name": "Shahid Sadooghi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.903603",
        "size": "medium"
    },
    {
        "iata": "ACZ",
        "lon": "61.542244",
        "iso": "IR",
        "status": 1,
        "name": "Zabol Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.087694",
        "size": "medium"
    },
    {
        "iata": "ZBR",
        "lon": "60.379562",
        "iso": "IR",
        "status": 1,
        "name": "Konarak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.436478",
        "size": "medium"
    },
    {
        "iata": "ZAH",
        "lon": "60.90033",
        "iso": "IR",
        "status": 1,
        "name": "Zahedan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.475756",
        "size": "medium"
    },
    {
        "iata": "AMM",
        "lon": "35.98932",
        "iso": "JO",
        "status": 1,
        "name": "Queen Alia International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.722534",
        "size": "large"
    },
    {
        "iata": "ADJ",
        "lon": "35.98277",
        "iso": "JO",
        "status": 1,
        "name": "Amman-Marka International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.97533",
        "size": "medium"
    },
    {
        "iata": "AQJ",
        "lon": "35.02151",
        "iso": "JO",
        "status": 1,
        "name": "Aqaba King Hussein International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.610073",
        "size": "medium"
    },
    {
        "iata": "JRS",
        "iso": "PS",
        "status": 0,
        "name": "Jerusalem Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "OMF",
        "lon": "36.25",
        "iso": "JO",
        "status": 1,
        "name": "King Hussein Air College",
        "continent": "AS",
        "type": "airport",
        "lat": "32.35",
        "size": "small"
    },
    {
        "iata": "KWI",
        "lon": "47.971252",
        "iso": "KW",
        "status": 1,
        "name": "Kuwait International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.240116",
        "size": "large"
    },
    {
        "iata": "BEY",
        "lon": "35.49308",
        "iso": "LB",
        "status": 1,
        "name": "Beirut Rafic Hariri International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.826073",
        "size": "large"
    },
    {
        "iata": "KYE",
        "lon": "36.002777",
        "iso": "LB",
        "status": 1,
        "name": "Rene Mouawad Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "34.58611",
        "size": "medium"
    },
    {
        "iata": "BYB",
        "lon": "56.25",
        "iso": "OM",
        "status": 1,
        "name": "Dibba Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.583332",
        "size": "small"
    },
    {
        "iata": "AOM",
        "lon": "140.68922",
        "iso": "OM",
        "status": 1,
        "name": "Adam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.738758",
        "size": "small"
    },
    {
        "iata": "AUH",
        "lon": "54.645973",
        "iso": "AE",
        "status": 1,
        "name": "Abu Dhabi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.426912",
        "size": "large"
    },
    {
        "iata": "AZI",
        "lon": "54.456833",
        "iso": "AE",
        "status": 1,
        "name": "Bateen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.4291",
        "size": "medium"
    },
    {
        "iata": "AAN",
        "lon": "55.616627",
        "iso": "AE",
        "status": 1,
        "name": "Al Ain International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.260231",
        "size": "medium"
    },
    {
        "iata": "DXB",
        "lon": "55.352917",
        "iso": "AE",
        "status": 1,
        "name": "Dubai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.248665",
        "size": "large"
    },
    {
        "iata": "NHD",
        "lon": "55.373653",
        "iso": "AE",
        "status": 1,
        "name": "Al Minhad Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "25.023756",
        "size": "medium"
    },
    {
        "iata": "DWC",
        "lon": "55.175278",
        "iso": "AE",
        "status": 1,
        "name": "Al Maktoum International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.918056",
        "size": "large"
    },
    {
        "iata": "FJR",
        "lon": "56.330555",
        "iso": "AE",
        "status": 1,
        "name": "Fujairah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.109444",
        "size": "medium"
    },
    {
        "iata": "RKT",
        "lon": "55.943695",
        "iso": "AE",
        "status": 1,
        "name": "Ras Al Khaimah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.616198",
        "size": "medium"
    },
    {
        "iata": "SHJ",
        "lon": "55.52029",
        "iso": "AE",
        "status": 1,
        "name": "Sharjah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.320873",
        "size": "large"
    },
    {
        "iata": "RMB",
        "lon": "55.783333",
        "iso": "OM",
        "status": 1,
        "name": "Buraimi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.25",
        "size": "small"
    },
    {
        "iata": "KHS",
        "lon": "56.23506",
        "iso": "OM",
        "status": 1,
        "name": "Khasab Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "26.163939",
        "size": "medium"
    },
    {
        "iata": "MSH",
        "lon": "58.891666",
        "iso": "OM",
        "status": 1,
        "name": "Masirah Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "20.680555",
        "size": "medium"
    },
    {
        "iata": "MCT",
        "lon": "58.29022",
        "iso": "OM",
        "status": 1,
        "name": "Muscat International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.588078",
        "size": "large"
    },
    {
        "iata": "OMM",
        "lon": "55.266666",
        "iso": "OM",
        "status": 1,
        "name": "Marmul Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.133333",
        "size": "small"
    },
    {
        "iata": "SLL",
        "lon": "54.106937",
        "iso": "OM",
        "status": 1,
        "name": "Salalah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.038124",
        "size": "medium"
    },
    {
        "iata": "SUH",
        "lon": "59.483334",
        "iso": "OM",
        "status": 1,
        "name": "Sur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.533333",
        "size": "small"
    },
    {
        "iata": "TTH",
        "lon": "54.0",
        "iso": "OM",
        "status": 1,
        "name": "Thumrait Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "17.633333",
        "size": "medium"
    },
    {
        "iata": "DDU",
        "lon": "67.6711",
        "iso": "PK",
        "status": 1,
        "name": "Dadu West Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.735804",
        "size": "small"
    },
    {
        "iata": "AAW",
        "lon": "73.25",
        "iso": "PK",
        "status": 1,
        "name": "Abbottabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.2",
        "size": "small"
    },
    {
        "iata": "BNP",
        "lon": "70.666664",
        "iso": "PK",
        "status": 1,
        "name": "Bannu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.0",
        "size": "small"
    },
    {
        "iata": "WGB",
        "lon": "73.25",
        "iso": "PK",
        "status": 1,
        "name": "Bahawalnagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.0",
        "size": "small"
    },
    {
        "iata": "BHV",
        "lon": "71.683334",
        "iso": "PK",
        "status": 1,
        "name": "Bahawalpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.4",
        "size": "medium"
    },
    {
        "iata": "CJL",
        "lon": "71.79444",
        "iso": "PK",
        "status": 1,
        "name": "Chitral Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.886112",
        "size": "medium"
    },
    {
        "iata": "CHB",
        "lon": "74.083336",
        "iso": "PK",
        "status": 1,
        "name": "Chilas Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.416668",
        "size": "small"
    },
    {
        "iata": "DBA",
        "lon": "64.416664",
        "iso": "PK",
        "status": 1,
        "name": "Dalbandin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.883333",
        "size": "small"
    },
    {
        "iata": "DDU",
        "lon": "67.6711",
        "iso": "PK",
        "status": 1,
        "name": "Dadu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.735804",
        "size": "small"
    },
    {
        "iata": "DEA",
        "lon": "70.485275",
        "iso": "PK",
        "status": 1,
        "name": "Dera Ghazi Khan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.960556",
        "size": "medium"
    },
    {
        "iata": "DSK",
        "lon": "70.9",
        "iso": "PK",
        "status": 1,
        "name": "Dera Ismael Khan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.916668",
        "size": "medium"
    },
    {
        "iata": "LYP",
        "lon": "72.98757",
        "iso": "PK",
        "status": 1,
        "name": "Faisalabad International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.363043",
        "size": "medium"
    },
    {
        "iata": "GWD",
        "lon": "62.33889",
        "iso": "PK",
        "status": 1,
        "name": "Gwadar International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.230556",
        "size": "medium"
    },
    {
        "iata": "GIL",
        "lon": "74.33224",
        "iso": "PK",
        "status": 1,
        "name": "Gilgit Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.91972",
        "size": "medium"
    },
    {
        "iata": "JAG",
        "lon": "68.45",
        "iso": "PK",
        "status": 1,
        "name": "Shahbaz Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "28.3",
        "size": "medium"
    },
    {
        "iata": "JIW",
        "lon": "61.816666",
        "iso": "PK",
        "status": 1,
        "name": "Jiwani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.083332",
        "size": "small"
    },
    {
        "iata": "KHI",
        "lon": "67.16828",
        "iso": "PK",
        "status": 1,
        "name": "Jinnah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.899986",
        "size": "medium"
    },
    {
        "iata": "HDD",
        "lon": "68.36389",
        "iso": "PK",
        "status": 1,
        "name": "Hyderabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.322222",
        "size": "small"
    },
    {
        "iata": "KDD",
        "lon": "66.630554",
        "iso": "PK",
        "status": 1,
        "name": "Khuzdar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.780556",
        "size": "small"
    },
    {
        "iata": "KBH",
        "lon": "66.51667",
        "iso": "PK",
        "status": 1,
        "name": "Kalat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.133333",
        "size": "small"
    },
    {
        "iata": "OHT",
        "lon": "71.433334",
        "iso": "PK",
        "status": 1,
        "name": "Kohat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.583332",
        "size": "small"
    },
    {
        "iata": "LHE",
        "lon": "74.40278",
        "iso": "PK",
        "status": 1,
        "name": "Alama Iqbal International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.520832",
        "size": "medium"
    },
    {
        "iata": "LRG",
        "lon": "65.083336",
        "iso": "PK",
        "status": 1,
        "name": "Loralai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.85",
        "size": "small"
    },
    {
        "iata": "XJM",
        "iso": "PK",
        "status": 1,
        "name": "Mangla Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "MFG",
        "lon": "73.46667",
        "iso": "PK",
        "status": 1,
        "name": "Muzaffarabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.36667",
        "size": "medium"
    },
    {
        "iata": "MWD",
        "lon": "71.55",
        "iso": "PK",
        "status": 1,
        "name": "Mianwali Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "32.55",
        "size": "medium"
    },
    {
        "iata": "MJD",
        "lon": "68.14167",
        "iso": "PK",
        "status": 1,
        "name": "Moenjodaro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.33639",
        "size": "medium"
    },
    {
        "iata": "MPD",
        "lon": "69.03333",
        "iso": "PK",
        "status": 1,
        "name": "Mirpur Khas Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "25.65",
        "size": "medium"
    },
    {
        "iata": "MPD",
        "lon": "69.03333",
        "iso": "PK",
        "status": 1,
        "name": "Sindhri Tharparkar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.65",
        "size": "small"
    },
    {
        "iata": "MUX",
        "lon": "71.41498",
        "iso": "PK",
        "status": 1,
        "name": "Multan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.199507",
        "size": "medium"
    },
    {
        "iata": "WNS",
        "lon": "68.39222",
        "iso": "PK",
        "status": 1,
        "name": "Nawabshah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.219444",
        "size": "medium"
    },
    {
        "iata": "NHS",
        "lon": "66.0",
        "iso": "PK",
        "status": 1,
        "name": "Nushki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.583332",
        "size": "small"
    },
    {
        "iata": "ORW",
        "lon": "64.583336",
        "iso": "PK",
        "status": 1,
        "name": "Ormara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.3",
        "size": "small"
    },
    {
        "iata": "PAJ",
        "lon": "70.1",
        "iso": "PK",
        "status": 1,
        "name": "Parachinar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.9",
        "size": "small"
    },
    {
        "iata": "PJG",
        "lon": "64.1",
        "iso": "PK",
        "status": 1,
        "name": "Panjgur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.966667",
        "size": "medium"
    },
    {
        "iata": "PSI",
        "lon": "63.36111",
        "iso": "PK",
        "status": 1,
        "name": "Pasni Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.3",
        "size": "medium"
    },
    {
        "iata": "PEW",
        "lon": "71.51925",
        "iso": "PK",
        "status": 1,
        "name": "Peshawar International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.989082",
        "size": "medium"
    },
    {
        "iata": "UET",
        "lon": "66.949036",
        "iso": "PK",
        "status": 1,
        "name": "Quetta International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.249266",
        "size": "medium"
    },
    {
        "iata": "RYK",
        "lon": "70.28662",
        "iso": "PK",
        "status": 1,
        "name": "Shaikh Zaid Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.39178",
        "size": "medium"
    },
    {
        "iata": "ISB",
        "lon": "73.1051",
        "iso": "PK",
        "status": 1,
        "name": "Benazir Bhutto International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.609707",
        "size": "large"
    },
    {
        "iata": "RAZ",
        "lon": "73.833336",
        "iso": "PK",
        "status": 1,
        "name": "Rawalakot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.841667",
        "size": "medium"
    },
    {
        "iata": "SBQ",
        "lon": "67.88333",
        "iso": "PK",
        "status": 1,
        "name": "Sibi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.55",
        "size": "small"
    },
    {
        "iata": "KDU",
        "lon": "75.53862",
        "iso": "PK",
        "status": 1,
        "name": "Skardu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.333008",
        "size": "medium"
    },
    {
        "iata": "SKZ",
        "lon": "68.7958",
        "iso": "PK",
        "status": 1,
        "name": "Sukkur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.72336",
        "size": "medium"
    },
    {
        "iata": "SYW",
        "lon": "67.85",
        "iso": "PK",
        "status": 1,
        "name": "Sehwan Sharif Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.416668",
        "size": "medium"
    },
    {
        "iata": "SGI",
        "lon": "72.67117",
        "iso": "PK",
        "status": 1,
        "name": "Mushaf Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "32.046844",
        "size": "medium"
    },
    {
        "iata": "SDT",
        "lon": "72.35",
        "iso": "PK",
        "status": 1,
        "name": "Saidu Sharif Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.75",
        "size": "medium"
    },
    {
        "iata": "SKT",
        "lon": "74.5",
        "iso": "PK",
        "status": 1,
        "name": "Sialkot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.533333",
        "size": "small"
    },
    {
        "iata": "SUL",
        "lon": "69.1725",
        "iso": "PK",
        "status": 1,
        "name": "Sui Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.64889",
        "size": "medium"
    },
    {
        "iata": "SWN",
        "lon": "73.13333",
        "iso": "PK",
        "status": 1,
        "name": "Sahiwal Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.75",
        "size": "small"
    },
    {
        "iata": "TLB",
        "lon": "72.816666",
        "iso": "PK",
        "status": 1,
        "name": "Tarbela Dam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.13333",
        "size": "small"
    },
    {
        "iata": "BDN",
        "lon": "68.9",
        "iso": "PK",
        "status": 1,
        "name": "Talhar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.633333",
        "size": "small"
    },
    {
        "iata": "TFT",
        "lon": "61.36667",
        "iso": "PK",
        "status": 1,
        "name": "Taftan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.583332",
        "size": "small"
    },
    {
        "iata": "TUK",
        "lon": "63.066666",
        "iso": "PK",
        "status": 1,
        "name": "Turbat International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.983334",
        "size": "medium"
    },
    {
        "iata": "WAF",
        "lon": "69.53333",
        "iso": "PK",
        "status": 1,
        "name": "Wana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.333332",
        "size": "small"
    },
    {
        "iata": "PZH",
        "lon": "69.5",
        "iso": "PK",
        "status": 1,
        "name": "Zhob Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.333332",
        "size": "medium"
    },
    {
        "iata": "IQA",
        "lon": "-68.53658",
        "iso": "IQ",
        "status": 1,
        "name": "Al Asad Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "63.75175",
        "size": "medium"
    },
    {
        "iata": "BMN",
        "lon": "43.266666",
        "iso": "IQ",
        "status": 1,
        "name": "Bamarni Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.1",
        "size": "small"
    },
    {
        "iata": "SDA",
        "lon": "44.230137",
        "iso": "IQ",
        "status": 1,
        "name": "Baghdad International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.26846",
        "size": "large"
    },
    {
        "iata": "OSB",
        "lon": "-92.68217",
        "iso": "IQ",
        "status": 1,
        "name": "Mosul International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.11437",
        "size": "medium"
    },
    {
        "iata": "EBL",
        "lon": "43.963055",
        "iso": "IQ",
        "status": 1,
        "name": "Erbil International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.2375",
        "size": "medium"
    },
    {
        "iata": "KIK",
        "lon": "44.283333",
        "iso": "IQ",
        "status": 1,
        "name": "Kirkuk Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "35.516666",
        "size": "medium"
    },
    {
        "iata": "BSR",
        "lon": "47.79139",
        "iso": "IQ",
        "status": 1,
        "name": "Basrah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.555555",
        "size": "large"
    },
    {
        "iata": "OSO",
        "iso": "IQ",
        "status": 1,
        "name": "Sulaymaniyah International Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ALP",
        "lon": "37.227074",
        "iso": "SY",
        "status": 1,
        "name": "Aleppo International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.185352",
        "size": "large"
    },
    {
        "iata": "DAM",
        "lon": "36.51249",
        "iso": "SY",
        "status": 1,
        "name": "Damascus International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.41117",
        "size": "large"
    },
    {
        "iata": "DEZ",
        "lon": "40.186806",
        "iso": "SY",
        "status": 1,
        "name": "Deir ez-Zor Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.28755",
        "size": "medium"
    },
    {
        "iata": "KAC",
        "lon": "41.20556",
        "iso": "SY",
        "status": 1,
        "name": "Kamishly Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.031025",
        "size": "medium"
    },
    {
        "iata": "LTK",
        "lon": "35.943993",
        "iso": "SY",
        "status": 1,
        "name": "Bassel Al-Assad International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.407352",
        "size": "large"
    },
    {
        "iata": "PMS",
        "lon": "38.25",
        "iso": "SY",
        "status": 1,
        "name": "Palmyra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.6",
        "size": "medium"
    },
    {
        "iata": "DOH",
        "lon": "51.558067",
        "iso": "QA",
        "status": 1,
        "name": "Doha International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.267569",
        "size": "large"
    },
    {
        "iata": "IUD",
        "iso": "QA",
        "status": 1,
        "name": "Al Udeid Air Base",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "OTT",
        "lon": "-58.581287",
        "iso": "BR",
        "status": 1,
        "name": "Andre Maggi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.902215",
        "size": "small"
    },
    {
        "iata": "ADE",
        "lon": "45.037537",
        "iso": "YE",
        "status": 1,
        "name": "Aden International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.826116",
        "size": "medium"
    },
    {
        "iata": "EAB",
        "lon": "43.5",
        "iso": "YE",
        "status": 1,
        "name": "Abbse Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.583333",
        "size": "small"
    },
    {
        "iata": "AXK",
        "lon": "46.3",
        "iso": "YE",
        "status": 1,
        "name": "Ataq Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.866667",
        "size": "medium"
    },
    {
        "iata": "BHN",
        "lon": "45.733334",
        "iso": "YE",
        "status": 1,
        "name": "Beihan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.783333",
        "size": "medium"
    },
    {
        "iata": "AAY",
        "lon": "52.173256",
        "iso": "YE",
        "status": 1,
        "name": "Al Ghaidah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.19596",
        "size": "medium"
    },
    {
        "iata": "HOD",
        "lon": "42.970478",
        "iso": "YE",
        "status": 1,
        "name": "Hodeidah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.755638",
        "size": "medium"
    },
    {
        "iata": "MYN",
        "lon": "45.333332",
        "iso": "YE",
        "status": 1,
        "name": "Mareb Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.483333",
        "size": "small"
    },
    {
        "iata": "IHN",
        "lon": "50.05",
        "iso": "YE",
        "status": 1,
        "name": "Qishn Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.05",
        "size": "small"
    },
    {
        "iata": "RIY",
        "lon": "49.373684",
        "iso": "YE",
        "status": 1,
        "name": "Mukalla International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.666999",
        "size": "medium"
    },
    {
        "iata": "SYE",
        "lon": "43.61667",
        "iso": "YE",
        "status": 1,
        "name": "Sadah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.866667",
        "size": "small"
    },
    {
        "iata": "SAH",
        "lon": "44.22511",
        "iso": "YE",
        "status": 1,
        "name": "Sana'a International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.473598",
        "size": "medium"
    },
    {
        "iata": "SCT",
        "lon": "53.909245",
        "iso": "YE",
        "status": 1,
        "name": "Socotra International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.627546",
        "size": "medium"
    },
    {
        "iata": "GXF",
        "lon": "48.787086",
        "iso": "YE",
        "status": 1,
        "name": "Sayun International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.961794",
        "size": "medium"
    },
    {
        "iata": "TAI",
        "lon": "44.134346",
        "iso": "YE",
        "status": 1,
        "name": "Ta'izz International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.685553",
        "size": "medium"
    },
    {
        "iata": "ACU",
        "lon": "-79.72444",
        "iso": "PA",
        "status": 1,
        "name": "Achutupo Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.576389",
        "size": "small"
    },
    {
        "iata": "AIL",
        "lon": "-78.083336",
        "iso": "PA",
        "status": 1,
        "name": "Alligandi Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "9.25",
        "size": "small"
    },
    {
        "iata": "AML",
        "lon": "-82.86667",
        "iso": "PA",
        "status": 1,
        "name": "Puerto Armuelles Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.266667",
        "size": "small"
    },
    {
        "iata": "BFQ",
        "lon": "-78.166664",
        "iso": "PA",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "7.583333",
        "size": "small"
    },
    {
        "iata": "ELE",
        "lon": "-77.833336",
        "iso": "PA",
        "status": 1,
        "name": "EL Real Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.166667",
        "size": "small"
    },
    {
        "iata": "OTD",
        "lon": "-79.566666",
        "iso": "PA",
        "status": 1,
        "name": "Contadora Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.666667",
        "size": "small"
    },
    {
        "iata": "SAX",
        "lon": "-78.083336",
        "iso": "PA",
        "status": 1,
        "name": "Sambu Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "8.033333",
        "size": "small"
    },
    {
        "iata": "AKB",
        "lon": "-174.20084",
        "iso": "US",
        "status": 1,
        "name": "Atka Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "52.21722",
        "size": "small"
    },
    {
        "iata": "PTD",
        "iso": "US",
        "status": 1,
        "name": "Port Alexander Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "PAQ",
        "lon": "-149.11667",
        "iso": "US",
        "status": 1,
        "name": "Palmer Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.6",
        "size": "medium"
    },
    {
        "iata": "BTI",
        "lon": "-143.57834",
        "iso": "US",
        "status": 1,
        "name": "Barter Island Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.13472",
        "size": "medium"
    },
    {
        "iata": "BET",
        "lon": "-161.83139",
        "iso": "US",
        "status": 1,
        "name": "Bethel Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.784443",
        "size": "medium"
    },
    {
        "iata": "BVU",
        "lon": "-151.03612",
        "iso": "US",
        "status": 1,
        "name": "Beluga Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.169445",
        "size": "small"
    },
    {
        "iata": "BIG",
        "lon": "-145.719",
        "iso": "US",
        "status": 1,
        "name": "Allen Army Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "63.99458",
        "size": "medium"
    },
    {
        "iata": "BKC",
        "lon": "-161.12666",
        "iso": "US",
        "status": 1,
        "name": "Buckland Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "65.97889",
        "size": "medium"
    },
    {
        "iata": "BRW",
        "lon": "-156.77579",
        "iso": "US",
        "status": 1,
        "name": "Wiley Post Will Rogers Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "71.288574",
        "size": "medium"
    },
    {
        "iata": "BTT",
        "lon": "-151.52638",
        "iso": "US",
        "status": 1,
        "name": "Bettles Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.915276",
        "size": "medium"
    },
    {
        "iata": "CDB",
        "lon": "-162.71774",
        "iso": "US",
        "status": 1,
        "name": "Cold Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.20457",
        "size": "medium"
    },
    {
        "iata": "CLF",
        "lon": "-149.2125",
        "iso": "US",
        "status": 1,
        "name": "Clear Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.320335",
        "size": "medium"
    },
    {
        "iata": "CDV",
        "lon": "-145.47",
        "iso": "US",
        "status": 1,
        "name": "Merle K (Mudhole) Smith Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.493057",
        "size": "medium"
    },
    {
        "iata": "CZF",
        "lon": "-166.03639",
        "iso": "US",
        "status": 1,
        "name": "Cape Romanzof Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.781113",
        "size": "medium"
    },
    {
        "iata": "DRG",
        "lon": "-162.75989",
        "iso": "US",
        "status": 1,
        "name": "Deering Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.0712",
        "size": "medium"
    },
    {
        "iata": "ADK",
        "lon": "-176.64473",
        "iso": "US",
        "status": 1,
        "name": "Adak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "51.88278",
        "size": "medium"
    },
    {
        "iata": "DLG",
        "lon": "-158.51472",
        "iso": "US",
        "status": 1,
        "name": "Dillingham Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.04246",
        "size": "medium"
    },
    {
        "iata": "ADQ",
        "lon": "-152.51297",
        "iso": "US",
        "status": 1,
        "name": "Kodiak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.755276",
        "size": "medium"
    },
    {
        "iata": "DUT",
        "lon": "-166.54224",
        "iso": "US",
        "status": 1,
        "name": "Unalaska Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.89446",
        "size": "medium"
    },
    {
        "iata": "EDF",
        "lon": "-149.78473",
        "iso": "US",
        "status": 1,
        "name": "Elmendorf Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "61.250557",
        "size": "medium"
    },
    {
        "iata": "EEK",
        "lon": "-162.01666",
        "iso": "US",
        "status": 1,
        "name": "Eek Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.216667",
        "size": "small"
    },
    {
        "iata": "EHM",
        "lon": "-162.06056",
        "iso": "US",
        "status": 1,
        "name": "Cape Newenham Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.64722",
        "size": "medium"
    },
    {
        "iata": "EIL",
        "lon": "-147.71666",
        "iso": "US",
        "status": 1,
        "name": "Eielson Air Force Base",
        "continent": "NA",
        "type": "airport",
        "lat": "64.85",
        "size": "medium"
    },
    {
        "iata": "ENM",
        "iso": "US",
        "status": 1,
        "name": "Emmonak Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ENA",
        "lon": "-151.24644",
        "iso": "US",
        "status": 1,
        "name": "Kenai Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.56521",
        "size": "medium"
    },
    {
        "iata": "FAI",
        "lon": "-147.8668",
        "iso": "US",
        "status": 1,
        "name": "Fairbanks International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.818214",
        "size": "large"
    },
    {
        "iata": "FBK",
        "lon": "-147.7",
        "iso": "US",
        "status": 1,
        "name": "Ladd AAF Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "64.833336",
        "size": "medium"
    },
    {
        "iata": "AFE",
        "iso": "US",
        "status": 1,
        "name": "Kake Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "ABL",
        "lon": "-157.84584",
        "iso": "US",
        "status": 1,
        "name": "Ambler Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.0875",
        "size": "medium"
    },
    {
        "iata": "FRN",
        "lon": "-149.68333",
        "iso": "US",
        "status": 1,
        "name": "Bryant Army Air Force Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.25",
        "size": "small"
    },
    {
        "iata": "FMC",
        "lon": "-149.84166",
        "iso": "US",
        "status": 1,
        "name": "Five Mile Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.916664",
        "size": "small"
    },
    {
        "iata": "GAL",
        "lon": "-156.94186",
        "iso": "US",
        "status": 1,
        "name": "Edward G. Pitka Sr Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.73798",
        "size": "medium"
    },
    {
        "iata": "GKN",
        "lon": "-145.45222",
        "iso": "US",
        "status": 1,
        "name": "Gulkana Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.155277",
        "size": "medium"
    },
    {
        "iata": "GAM",
        "lon": "-171.71355",
        "iso": "US",
        "status": 1,
        "name": "Gambell Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.776596",
        "size": "medium"
    },
    {
        "iata": "AGN",
        "iso": "US",
        "status": 1,
        "name": "Angoon Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "GST",
        "lon": "-135.705",
        "iso": "US",
        "status": 1,
        "name": "Gustavus Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.4275",
        "size": "medium"
    },
    {
        "iata": "SGY",
        "lon": "-135.31555",
        "iso": "US",
        "status": 1,
        "name": "Skagway Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.460556",
        "size": "small"
    },
    {
        "iata": "HCR",
        "lon": "-159.77167",
        "iso": "US",
        "status": 1,
        "name": "Holy Cross Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.18889",
        "size": "medium"
    },
    {
        "iata": "HNS",
        "lon": "-135.52924",
        "iso": "US",
        "status": 1,
        "name": "Haines Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.246197",
        "size": "medium"
    },
    {
        "iata": "HOM",
        "lon": "-151.49316",
        "iso": "US",
        "status": 1,
        "name": "Homer Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.64324",
        "size": "medium"
    },
    {
        "iata": "HPB",
        "lon": "-166.14586",
        "iso": "US",
        "status": 1,
        "name": "Hooper Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.525078",
        "size": "medium"
    },
    {
        "iata": "HYG",
        "iso": "US",
        "status": 1,
        "name": "Hydaburg Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "IGG",
        "lon": "-155.9",
        "iso": "US",
        "status": 1,
        "name": "Igiugig Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.316666",
        "size": "small"
    },
    {
        "iata": "EII",
        "lon": "-9.433333",
        "iso": "US",
        "status": 1,
        "name": "Egegik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "53.033333",
        "size": "medium"
    },
    {
        "iata": "IAN",
        "lon": "-160.43056",
        "iso": "US",
        "status": 1,
        "name": "Bob Baker Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.975555",
        "size": "medium"
    },
    {
        "iata": "ILI",
        "lon": "-154.90834",
        "iso": "US",
        "status": 1,
        "name": "Iliamna Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.752777",
        "size": "medium"
    },
    {
        "iata": "UTO",
        "lon": "-153.70166",
        "iso": "US",
        "status": 1,
        "name": "Indian Mountain Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "65.99333",
        "size": "medium"
    },
    {
        "iata": "JNU",
        "lon": "-134.58339",
        "iso": "US",
        "status": 1,
        "name": "Juneau International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.35932",
        "size": "medium"
    },
    {
        "iata": "KFP",
        "lon": "-163.40916",
        "iso": "US",
        "status": 1,
        "name": "False Pass Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "54.849167",
        "size": "small"
    },
    {
        "iata": "AKK",
        "lon": "-154.16667",
        "iso": "US",
        "status": 1,
        "name": "Akhiok Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.944443",
        "size": "small"
    },
    {
        "iata": "AKN",
        "lon": "-156.66902",
        "iso": "US",
        "status": 1,
        "name": "King Salmon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.68274",
        "size": "medium"
    },
    {
        "iata": "IKO",
        "lon": "-168.85",
        "iso": "US",
        "status": 1,
        "name": "Nikolski Air Station",
        "continent": "NA",
        "type": "airport",
        "lat": "52.941666",
        "size": "small"
    },
    {
        "iata": "AKP",
        "lon": "-151.74",
        "iso": "US",
        "status": 1,
        "name": "Anaktuvuk Pass Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.1375",
        "size": "medium"
    },
    {
        "iata": "KTN",
        "lon": "-131.7087",
        "iso": "US",
        "status": 1,
        "name": "Ketchikan International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.35655",
        "size": "medium"
    },
    {
        "iata": "KAL",
        "lon": "-158.73611",
        "iso": "US",
        "status": 1,
        "name": "Kaltag Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.321945",
        "size": "small"
    },
    {
        "iata": "AKW",
        "lon": "49.683334",
        "iso": "US",
        "status": 1,
        "name": "Klawock Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "30.75",
        "size": "medium"
    },
    {
        "iata": "KLG",
        "lon": "-160.3465",
        "iso": "US",
        "status": 1,
        "name": "Kalskag Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.53258",
        "size": "small"
    },
    {
        "iata": "LUR",
        "lon": "-166.10695",
        "iso": "US",
        "status": 1,
        "name": "Cape Lisburne Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.87583",
        "size": "medium"
    },
    {
        "iata": "MCG",
        "lon": "-155.65764",
        "iso": "US",
        "status": 1,
        "name": "McGrath Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.97875",
        "size": "medium"
    },
    {
        "iata": "MOU",
        "lon": "-163.71666",
        "iso": "US",
        "status": 1,
        "name": "Mountain Village Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.089443",
        "size": "small"
    },
    {
        "iata": "MRI",
        "lon": "-149.9",
        "iso": "US",
        "status": 1,
        "name": "Merrill Field",
        "continent": "NA",
        "type": "airport",
        "lat": "61.233334",
        "size": "medium"
    },
    {
        "iata": "MYU",
        "lon": "-166.26805",
        "iso": "US",
        "status": 1,
        "name": "Mekoryuk Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.37278",
        "size": "medium"
    },
    {
        "iata": "WNA",
        "lon": "-162.11667",
        "iso": "US",
        "status": 1,
        "name": "Napakiak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.7",
        "size": "small"
    },
    {
        "iata": "ANC",
        "lon": "-149.99638",
        "iso": "US",
        "status": 1,
        "name": "Ted Stevens Anchorage International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.174442",
        "size": "large"
    },
    {
        "iata": "ANI",
        "lon": "-159.53697",
        "iso": "US",
        "status": 1,
        "name": "Aniak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.574398",
        "size": "medium"
    },
    {
        "iata": "ENN",
        "lon": "-149.11667",
        "iso": "US",
        "status": 1,
        "name": "Nenana Municipal Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.566666",
        "size": "medium"
    },
    {
        "iata": "ANN",
        "lon": "-131.57056",
        "iso": "US",
        "status": 1,
        "name": "Annette Island Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.036945",
        "size": "medium"
    },
    {
        "iata": "ANV",
        "lon": "-160.18889",
        "iso": "US",
        "status": 1,
        "name": "Anvik Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.647778",
        "size": "medium"
    },
    {
        "iata": "KNW",
        "lon": "-157.32973",
        "iso": "US",
        "status": 1,
        "name": "New Stuyahok Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.45222",
        "size": "small"
    },
    {
        "iata": "PCA",
        "lon": "-157.70195",
        "iso": "US",
        "status": 1,
        "name": "Portage Creek Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.90139",
        "size": "small"
    },
    {
        "iata": "HNH",
        "lon": "-135.40334",
        "iso": "US",
        "status": 1,
        "name": "Hoonah Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "58.098057",
        "size": "small"
    },
    {
        "iata": "OME",
        "lon": "-165.44167",
        "iso": "US",
        "status": 1,
        "name": "Nome Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.51139",
        "size": "medium"
    },
    {
        "iata": "ORT",
        "lon": "-141.92389",
        "iso": "US",
        "status": 1,
        "name": "Northway Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.961945",
        "size": "medium"
    },
    {
        "iata": "OTZ",
        "lon": "-162.60583",
        "iso": "US",
        "status": 1,
        "name": "Ralph Wien Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.888054",
        "size": "medium"
    },
    {
        "iata": "PBV",
        "lon": "-57.333332",
        "iso": "US",
        "status": 1,
        "name": "St George Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "-11.516667",
        "size": "medium"
    },
    {
        "iata": "KPC",
        "lon": "-166.85583",
        "iso": "US",
        "status": 1,
        "name": "Port Clarence Coast Guard Station",
        "continent": "NA",
        "type": "airport",
        "lat": "65.25444",
        "size": "medium"
    },
    {
        "iata": "PSG",
        "lon": "-132.9425",
        "iso": "US",
        "status": 1,
        "name": "Petersburg James A Johnson Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.80417",
        "size": "medium"
    },
    {
        "iata": "PTH",
        "lon": "-158.6375",
        "iso": "US",
        "status": 1,
        "name": "Port Heiden Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.95667",
        "size": "medium"
    },
    {
        "iata": "PTU",
        "lon": "-161.81694",
        "iso": "US",
        "status": 1,
        "name": "Platinum Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.01139",
        "size": "medium"
    },
    {
        "iata": "PHO",
        "lon": "-166.8",
        "iso": "US",
        "status": 1,
        "name": "Point Hope Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.35",
        "size": "small"
    },
    {
        "iata": "NUI",
        "lon": "-151.00555",
        "iso": "US",
        "status": 1,
        "name": "Nuiqsut Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.209724",
        "size": "medium"
    },
    {
        "iata": "ARC",
        "lon": "-145.52444",
        "iso": "US",
        "status": 1,
        "name": "Arctic Village Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "68.1375",
        "size": "medium"
    },
    {
        "iata": "RBY",
        "lon": "-155.46083",
        "iso": "US",
        "status": 1,
        "name": "Ruby Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "64.73",
        "size": "medium"
    },
    {
        "iata": "SVA",
        "lon": "-170.48679",
        "iso": "US",
        "status": 1,
        "name": "Savoonga Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.69093",
        "size": "medium"
    },
    {
        "iata": "SCC",
        "lon": "-148.46011",
        "iso": "US",
        "status": 1,
        "name": "Deadhorse Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.203636",
        "size": "medium"
    },
    {
        "iata": "SDP",
        "lon": "-160.51389",
        "iso": "US",
        "status": 1,
        "name": "Sand Point Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.316666",
        "size": "medium"
    },
    {
        "iata": "SHH",
        "lon": "-166.05833",
        "iso": "US",
        "status": 1,
        "name": "Shishmaref Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.25694",
        "size": "medium"
    },
    {
        "iata": "SIT",
        "lon": "-135.23334",
        "iso": "US",
        "status": 1,
        "name": "Sitka Rocky Gutierrez Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.04861",
        "size": "medium"
    },
    {
        "iata": "KSM",
        "lon": "-163.29489",
        "iso": "US",
        "status": 1,
        "name": "St Mary's Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.058666",
        "size": "medium"
    },
    {
        "iata": "SNP",
        "lon": "-170.21722",
        "iso": "US",
        "status": 1,
        "name": "St Paul Island Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.15222",
        "size": "medium"
    },
    {
        "iata": "SVW",
        "lon": "-155.57222",
        "iso": "US",
        "status": 1,
        "name": "Sparrevohn Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.0975",
        "size": "medium"
    },
    {
        "iata": "SXQ",
        "lon": "-151.03639",
        "iso": "US",
        "status": 1,
        "name": "Soldotna Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.475555",
        "size": "medium"
    },
    {
        "iata": "SYA",
        "lon": "174.08945",
        "iso": "US",
        "status": 1,
        "name": "Eareckson Air Station",
        "continent": "NA",
        "type": "airport",
        "lat": "52.7175",
        "size": "medium"
    },
    {
        "iata": "TOG",
        "lon": "-160.38222",
        "iso": "US",
        "status": 1,
        "name": "Togiak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "59.056667",
        "size": "medium"
    },
    {
        "iata": "TKJ",
        "lon": "-143.0",
        "iso": "US",
        "status": 1,
        "name": "Tok Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "63.3",
        "size": "small"
    },
    {
        "iata": "TLJ",
        "lon": "-155.96806",
        "iso": "US",
        "status": 1,
        "name": "Tatalina LRRS Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "62.885277",
        "size": "medium"
    },
    {
        "iata": "ATK",
        "lon": "-157.33333",
        "iso": "US",
        "status": 1,
        "name": "Atqasuk Edward Burnell Sr Memorial Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.5",
        "size": "medium"
    },
    {
        "iata": "VAK",
        "lon": "-165.59027",
        "iso": "US",
        "status": 1,
        "name": "Chevak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "61.52861",
        "size": "small"
    },
    {
        "iata": "KVC",
        "lon": "-162.31334",
        "iso": "US",
        "status": 1,
        "name": "King Cove Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "55.055",
        "size": "small"
    },
    {
        "iata": "VDZ",
        "lon": "-146.23889",
        "iso": "US",
        "status": 1,
        "name": "Valdez Pioneer Field",
        "continent": "NA",
        "type": "airport",
        "lat": "61.1325",
        "size": "medium"
    },
    {
        "iata": "KVL",
        "lon": "-164.54723",
        "iso": "US",
        "status": 1,
        "name": "Kivalina Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.73167",
        "size": "medium"
    },
    {
        "iata": "SWD",
        "lon": "-149.41806",
        "iso": "US",
        "status": 1,
        "name": "Seward Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.13333",
        "size": "medium"
    },
    {
        "iata": "WRG",
        "lon": "-132.36667",
        "iso": "US",
        "status": 1,
        "name": "Wrangell Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "56.484444",
        "size": "medium"
    },
    {
        "iata": "AIN",
        "lon": "-160.03334",
        "iso": "US",
        "status": 1,
        "name": "Wainwright Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "70.63333",
        "size": "medium"
    },
    {
        "iata": "WTK",
        "lon": "-162.98416",
        "iso": "US",
        "status": 1,
        "name": "Noatak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "67.56306",
        "size": "medium"
    },
    {
        "iata": "IYS",
        "iso": "US",
        "status": 1,
        "name": "Wasilla Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "CIS",
        "lon": "-171.66667",
        "iso": "KI",
        "status": 1,
        "name": "Canton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-2.833333",
        "size": "medium"
    },
    {
        "iata": "PCQ",
        "lon": "101.90583",
        "iso": "LA",
        "status": 1,
        "name": "Bounneau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.645832",
        "size": "small"
    },
    {
        "iata": "MFT",
        "lon": "-72.566666",
        "iso": "PE",
        "status": 1,
        "name": "Machu Pichu Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.116667",
        "size": "small"
    },
    {
        "iata": "AKI",
        "lon": "-161.22333",
        "iso": "US",
        "status": 1,
        "name": "Akiak Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "60.905556",
        "size": "small"
    },
    {
        "iata": "AET",
        "lon": "-152.65834",
        "iso": "US",
        "status": 1,
        "name": "Allakaket Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.56167",
        "size": "small"
    },
    {
        "iata": "FYU",
        "lon": "-145.2482",
        "iso": "US",
        "status": 1,
        "name": "Fort Yukon Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "66.56981",
        "size": "medium"
    },
    {
        "iata": "AKG",
        "lon": "142.01666",
        "iso": "PG",
        "status": 1,
        "name": "Anguganak Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.633333",
        "size": "small"
    },
    {
        "iata": "AWB",
        "lon": "142.75",
        "iso": "PG",
        "status": 1,
        "name": "Awaba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.016667",
        "size": "small"
    },
    {
        "iata": "BAA",
        "lon": "151.03334",
        "iso": "PG",
        "status": 1,
        "name": "Bialla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.316667",
        "size": "small"
    },
    {
        "iata": "CVB",
        "lon": "144.7",
        "iso": "PG",
        "status": 1,
        "name": "Chungribu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.8",
        "size": "small"
    },
    {
        "iata": "GMI",
        "lon": "150.33333",
        "iso": "PG",
        "status": 1,
        "name": "Gasmata Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.3",
        "size": "small"
    },
    {
        "iata": "GVI",
        "lon": "141.15",
        "iso": "PG",
        "status": 1,
        "name": "Green River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.966667",
        "size": "small"
    },
    {
        "iata": "HYF",
        "lon": "143.05",
        "iso": "PG",
        "status": 1,
        "name": "Hayfields Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.633333",
        "size": "small"
    },
    {
        "iata": "IHU",
        "lon": "145.38333",
        "iso": "PG",
        "status": 1,
        "name": "Ihu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.9",
        "size": "small"
    },
    {
        "iata": "IIS",
        "lon": "154.34166",
        "iso": "PG",
        "status": 1,
        "name": "Nissan Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.333333",
        "size": "small"
    },
    {
        "iata": "JAQ",
        "lon": "151.51666",
        "iso": "PG",
        "status": 1,
        "name": "Jacquinot Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.6",
        "size": "small"
    },
    {
        "iata": "KDR",
        "lon": "25.333332",
        "iso": "PG",
        "status": 1,
        "name": "Kandrian Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "41.61667",
        "size": "small"
    },
    {
        "iata": "KKD",
        "lon": "147.73122",
        "iso": "PG",
        "status": 1,
        "name": "Kokoda Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.886153",
        "size": "small"
    },
    {
        "iata": "KUY",
        "lon": "143.12389",
        "iso": "PG",
        "status": 1,
        "name": "Kamusi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.4275",
        "size": "small"
    },
    {
        "iata": "KWO",
        "lon": "142.81667",
        "iso": "PG",
        "status": 1,
        "name": "Kawito Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.983333",
        "size": "small"
    },
    {
        "iata": "KXR",
        "lon": "154.58333",
        "iso": "PG",
        "status": 1,
        "name": "Karoola Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.15",
        "size": "small"
    },
    {
        "iata": "LMI",
        "lon": "142.03334",
        "iso": "PG",
        "status": 1,
        "name": "Lumi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.483333",
        "size": "small"
    },
    {
        "iata": "LMY",
        "lon": "141.36667",
        "iso": "PG",
        "status": 1,
        "name": "Lake Murray Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.833333",
        "size": "small"
    },
    {
        "iata": "OBX",
        "lon": "141.31667",
        "iso": "PG",
        "status": 1,
        "name": "Obo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.583333",
        "size": "small"
    },
    {
        "iata": "OPU",
        "lon": "142.93333",
        "iso": "PG",
        "status": 1,
        "name": "Balimo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.033333",
        "size": "medium"
    },
    {
        "iata": "SKC",
        "lon": "141.8",
        "iso": "PG",
        "status": 1,
        "name": "Suki Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.083333",
        "size": "small"
    },
    {
        "iata": "TFI",
        "lon": "149.33333",
        "iso": "PG",
        "status": 1,
        "name": "Tufi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.05",
        "size": "small"
    },
    {
        "iata": "TFM",
        "lon": "141.63333",
        "iso": "PG",
        "status": 1,
        "name": "Telefomin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-5.183333",
        "size": "small"
    },
    {
        "iata": "TLO",
        "lon": "152.03334",
        "iso": "PG",
        "status": 1,
        "name": "Tol Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.983333",
        "size": "small"
    },
    {
        "iata": "UKU",
        "lon": "142.46666",
        "iso": "PG",
        "status": 1,
        "name": "Nuku Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-3.683333",
        "size": "small"
    },
    {
        "iata": "ULE",
        "lon": "151.26666",
        "iso": "PG",
        "status": 1,
        "name": "Sule Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-4.933333",
        "size": "small"
    },
    {
        "iata": "VMU",
        "lon": "144.8",
        "iso": "PG",
        "status": 1,
        "name": "Baimuru Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-7.5",
        "size": "medium"
    },
    {
        "iata": "WPM",
        "lon": "143.03334",
        "iso": "PG",
        "status": 1,
        "name": "Wipim Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.816667",
        "size": "small"
    },
    {
        "iata": "ROP",
        "lon": "145.24408",
        "iso": "MP",
        "status": 1,
        "name": "Rota International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "14.171568",
        "size": "medium"
    },
    {
        "iata": "SPN",
        "lon": "145.72325",
        "iso": "MP",
        "status": 1,
        "name": "Francisco C. Ada Saipan International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "15.122178",
        "size": "medium"
    },
    {
        "iata": "UAM",
        "lon": "144.92953",
        "iso": "GU",
        "status": 1,
        "name": "Andersen Air Force Base",
        "continent": "OC",
        "type": "airport",
        "lat": "13.580373",
        "size": "medium"
    },
    {
        "iata": "GUM",
        "lon": "144.80486",
        "iso": "GU",
        "status": 1,
        "name": "Antonio B. Won Pat International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "13.492787",
        "size": "large"
    },
    {
        "iata": "TIQ",
        "lon": "145.62634",
        "iso": "MP",
        "status": 1,
        "name": "Tinian International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "14.994319",
        "size": "medium"
    },
    {
        "iata": "ENI",
        "lon": "119.41278",
        "iso": "PH",
        "status": 1,
        "name": "El Nido Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.205556",
        "size": "small"
    },
    {
        "iata": "BKH",
        "lon": "-159.71666",
        "iso": "US",
        "status": 1,
        "name": "Barking Sands Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.966667",
        "size": "medium"
    },
    {
        "iata": "HDH",
        "lon": "-157.96666",
        "iso": "US",
        "status": 1,
        "name": "Dillingham Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "21.466667",
        "size": "medium"
    },
    {
        "iata": "PHG",
        "lon": "6.833333",
        "iso": "NG",
        "status": 1,
        "name": "Port Harcourt City Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "5.033333",
        "size": "small"
    },
    {
        "iata": "HHI",
        "lon": "-158.03334",
        "iso": "US",
        "status": 1,
        "name": "Wheeler Army Airfield",
        "continent": "NA",
        "type": "airport",
        "lat": "21.5",
        "size": "medium"
    },
    {
        "iata": "HNM",
        "lon": "-156.01889",
        "iso": "US",
        "status": 1,
        "name": "Hana Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.795834",
        "size": "medium"
    },
    {
        "iata": "JHM",
        "lon": "-156.67569",
        "iso": "US",
        "status": 1,
        "name": "Kapalua Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.962194",
        "size": "medium"
    },
    {
        "iata": "JRF",
        "iso": "US",
        "status": 1,
        "name": "Kalaeloa (John Rodgers Field) Airport",
        "continent": "NA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "KOA",
        "lon": "-156.04108",
        "iso": "US",
        "status": 1,
        "name": "Kona International At Keahole Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.736174",
        "size": "medium"
    },
    {
        "iata": "LIH",
        "lon": "-159.34944",
        "iso": "US",
        "status": 1,
        "name": "Lihue Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.978205",
        "size": "medium"
    },
    {
        "iata": "LUP",
        "lon": "-156.97444",
        "iso": "US",
        "status": 1,
        "name": "Kalaupapa Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.211111",
        "size": "small"
    },
    {
        "iata": "MKK",
        "lon": "-157.1",
        "iso": "US",
        "status": 1,
        "name": "Molokai Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.152779",
        "size": "medium"
    },
    {
        "iata": "MUE",
        "lon": "-155.67334",
        "iso": "US",
        "status": 1,
        "name": "Waimea Kohala Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.0125",
        "size": "medium"
    },
    {
        "iata": "HNL",
        "lon": "-157.92166",
        "iso": "US",
        "status": 1,
        "name": "Honolulu International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "21.325832",
        "size": "large"
    },
    {
        "iata": "LNY",
        "lon": "-156.95049",
        "iso": "US",
        "status": 1,
        "name": "Lanai Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.790085",
        "size": "medium"
    },
    {
        "iata": "OGG",
        "lon": "-156.43863",
        "iso": "US",
        "status": 1,
        "name": "Kahului Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.892883",
        "size": "medium"
    },
    {
        "iata": "ITO",
        "lon": "-155.03963",
        "iso": "US",
        "status": 1,
        "name": "Hilo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "19.714565",
        "size": "medium"
    },
    {
        "iata": "UPP",
        "lon": "-155.8625",
        "iso": "US",
        "status": 1,
        "name": "Upolu Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "20.268057",
        "size": "medium"
    },
    {
        "iata": "JON",
        "lon": "-169.53334",
        "iso": "UM",
        "status": 1,
        "name": "Johnston Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "16.733334",
        "size": "medium"
    },
    {
        "iata": "BHC",
        "lon": "73.433334",
        "iso": "PK",
        "status": 1,
        "name": "Bhurban Heliport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.925",
        "size": "small"
    },
    {
        "iata": "CWP",
        "lon": "72.433334",
        "iso": "PK",
        "status": 1,
        "name": "Campbellpore Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.766666",
        "size": "small"
    },
    {
        "iata": "GRT",
        "lon": "74.03333",
        "iso": "PK",
        "status": 1,
        "name": "Gujrat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.666668",
        "size": "small"
    },
    {
        "iata": "HRA",
        "lon": "73.2",
        "iso": "PK",
        "status": 1,
        "name": "Mansehra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.333332",
        "size": "small"
    },
    {
        "iata": "KCF",
        "lon": "69.15",
        "iso": "PK",
        "status": 1,
        "name": "Kadanwari Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.2",
        "size": "small"
    },
    {
        "iata": "REQ",
        "lon": "-73.86667",
        "iso": "PK",
        "status": 1,
        "name": "Reko Diq Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.064947",
        "size": "small"
    },
    {
        "iata": "RZS",
        "lon": "68.86667",
        "iso": "PK",
        "status": 1,
        "name": "Sawan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.966667",
        "size": "small"
    },
    {
        "iata": "SWV",
        "lon": "159.23",
        "iso": "PK",
        "status": 1,
        "name": "Shikapur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "61.921665",
        "size": "small"
    },
    {
        "iata": "ENT",
        "lon": "162.25",
        "iso": "MH",
        "status": 1,
        "name": "Eniwetok Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "11.5",
        "size": "small"
    },
    {
        "iata": "MAJ",
        "lon": "171.2825",
        "iso": "MH",
        "status": 1,
        "name": "Marshall Islands International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.068717",
        "size": "medium"
    },
    {
        "iata": "KIA",
        "lon": "146.25",
        "iso": "GY",
        "status": 1,
        "name": "Kaieteur International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.266667",
        "size": "medium"
    },
    {
        "iata": "KWA",
        "lon": "167.72195",
        "iso": "MH",
        "status": 1,
        "name": "Bucholz Army Air Field",
        "continent": "OC",
        "type": "airport",
        "lat": "8.720843",
        "size": "medium"
    },
    {
        "iata": "CXI",
        "lon": "-157.45",
        "iso": "KI",
        "status": 1,
        "name": "Cassidy International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "1.966667",
        "size": "medium"
    },
    {
        "iata": "TNV",
        "lon": "-159.35",
        "iso": "KI",
        "status": 1,
        "name": "Tabuaeran Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "3.833333",
        "size": "small"
    },
    {
        "iata": "MDY",
        "lon": "-177.38333",
        "iso": "UM",
        "status": 1,
        "name": "Henderson Field",
        "continent": "OC",
        "type": "airport",
        "lat": "28.2",
        "size": "medium"
    },
    {
        "iata": "PIZ",
        "lon": "-163.16667",
        "iso": "US",
        "status": 1,
        "name": "Point Lay Lrrs Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "69.75",
        "size": "medium"
    },
    {
        "iata": "PQD",
        "iso": "LK",
        "status": 1,
        "name": "Passikudah Helipad",
        "continent": "AS",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "DDP",
        "lon": "-66.15",
        "iso": "PR",
        "status": 1,
        "name": "Dorado Beach Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.483334",
        "size": "small"
    },
    {
        "iata": "HUC",
        "lon": "-65.80111",
        "iso": "PR",
        "status": 1,
        "name": "Humacao Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.14",
        "size": "small"
    },
    {
        "iata": "PPD",
        "lon": "-65.833336",
        "iso": "PR",
        "status": 1,
        "name": "Palmas del Mar Airstrip",
        "continent": "NA",
        "type": "airport",
        "lat": "18.15",
        "size": "small"
    },
    {
        "iata": "TKK",
        "lon": "151.84201",
        "iso": "FM",
        "status": 1,
        "name": "Chuuk International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.457446",
        "size": "medium"
    },
    {
        "iata": "PNI",
        "lon": "158.20334",
        "iso": "FM",
        "status": 1,
        "name": "Pohnpei International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "6.980947",
        "size": "medium"
    },
    {
        "iata": "ROR",
        "lon": "134.5329",
        "iso": "PW",
        "status": 1,
        "name": "Babelthuap Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "7.364122",
        "size": "medium"
    },
    {
        "iata": "KSA",
        "lon": "162.9566",
        "iso": "FM",
        "status": 1,
        "name": "Kosrae International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "5.352478",
        "size": "medium"
    },
    {
        "iata": "YAP",
        "lon": "138.08673",
        "iso": "FM",
        "status": 1,
        "name": "Yap International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.497715",
        "size": "medium"
    },
    {
        "iata": "AWK",
        "lon": "166.63333",
        "iso": "UM",
        "status": 1,
        "name": "Wake Island Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "19.283333",
        "size": "medium"
    },
    {
        "iata": "BFA",
        "lon": "-58.170277",
        "iso": "PY",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-20.232779",
        "size": "small"
    },
    {
        "iata": "OLK",
        "lon": "-57.883057",
        "iso": "PY",
        "status": 1,
        "name": "Fuerte Olimpo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.996944",
        "size": "small"
    },
    {
        "iata": "PBT",
        "lon": "-58.026943",
        "iso": "PY",
        "status": 1,
        "name": "Puerto Leda Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.616667",
        "size": "small"
    },
    {
        "iata": "PCJ",
        "lon": "-57.92861",
        "iso": "PY",
        "status": 1,
        "name": "Puerto La Victoria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.268888",
        "size": "small"
    },
    {
        "iata": "KIO",
        "lon": "169.2",
        "iso": "MH",
        "status": 1,
        "name": "Kili Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "5.65",
        "size": "small"
    },
    {
        "iata": "QFX",
        "iso": "GL",
        "status": 1,
        "name": "Igaliku Heliport",
        "continent": "NA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "RAW",
        "lon": "155.58333",
        "iso": "PG",
        "status": 1,
        "name": "Arawa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.25",
        "size": "small"
    },
    {
        "iata": "KNH",
        "lon": "118.366615",
        "iso": "TW",
        "status": 1,
        "name": "Kinmen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.432386",
        "size": "large"
    },
    {
        "iata": "LZN",
        "lon": "119.958336",
        "iso": "TW",
        "status": 1,
        "name": "Matsu Nangan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.159445",
        "size": "medium"
    },
    {
        "iata": "TTT",
        "lon": "121.1",
        "iso": "TW",
        "status": 1,
        "name": "Taitung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.75",
        "size": "medium"
    },
    {
        "iata": "GNI",
        "lon": "121.46667",
        "iso": "TW",
        "status": 1,
        "name": "Lyudao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.1",
        "size": "small"
    },
    {
        "iata": "KHH",
        "lon": "120.345276",
        "iso": "TW",
        "status": 1,
        "name": "Kaohsiung International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.5725",
        "size": "large"
    },
    {
        "iata": "CYI",
        "lon": "120.38333",
        "iso": "TW",
        "status": 1,
        "name": "Chiayi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.466667",
        "size": "medium"
    },
    {
        "iata": "HCN",
        "lon": "120.833336",
        "iso": "TW",
        "status": 1,
        "name": "Hengchun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.933332",
        "size": "medium"
    },
    {
        "iata": "TXG",
        "lon": "120.64611",
        "iso": "TW",
        "status": 1,
        "name": "Taichung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.15",
        "size": "small"
    },
    {
        "iata": "KYD",
        "lon": "121.5",
        "iso": "TW",
        "status": 1,
        "name": "Lanyu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.083332",
        "size": "medium"
    },
    {
        "iata": "RMQ",
        "lon": "120.605576",
        "iso": "TW",
        "status": 1,
        "name": "Taichung Ching Chuang Kang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.255674",
        "size": "medium"
    },
    {
        "iata": "MFK",
        "lon": "119.916664",
        "iso": "TW",
        "status": 1,
        "name": "Matsu Beigan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.166668",
        "size": "medium"
    },
    {
        "iata": "TNN",
        "lon": "120.21571",
        "iso": "TW",
        "status": 1,
        "name": "Tainan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.948706",
        "size": "medium"
    },
    {
        "iata": "MZG",
        "lon": "119.618614",
        "iso": "TW",
        "status": 1,
        "name": "Makung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.569166",
        "size": "medium"
    },
    {
        "iata": "PIF",
        "lon": "120.490234",
        "iso": "TW",
        "status": 1,
        "name": "Pingtung North Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.705004",
        "size": "medium"
    },
    {
        "iata": "TSA",
        "lon": "121.551926",
        "iso": "TW",
        "status": 1,
        "name": "Taipei Songshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.06279",
        "size": "medium"
    },
    {
        "iata": "TPE",
        "lon": "121.22389",
        "iso": "TW",
        "status": 1,
        "name": "Taiwan Taoyuan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.07639",
        "size": "large"
    },
    {
        "iata": "WOT",
        "lon": "119.5",
        "iso": "TW",
        "status": 1,
        "name": "Wang-an Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.416668",
        "size": "small"
    },
    {
        "iata": "HUN",
        "lon": "121.613434",
        "iso": "TW",
        "status": 1,
        "name": "Hualien Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.025764",
        "size": "medium"
    },
    {
        "iata": "NRT",
        "lon": "140.38744",
        "iso": "JP",
        "status": 1,
        "name": "Narita International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.773212",
        "size": "large"
    },
    {
        "iata": "MMJ",
        "lon": "137.96666",
        "iso": "JP",
        "status": 1,
        "name": "Matsumoto Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.233334",
        "size": "medium"
    },
    {
        "iata": "IBR",
        "lon": "140.41472",
        "iso": "JP",
        "status": 1,
        "name": "Hyakuri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.181667",
        "size": "medium"
    },
    {
        "iata": "MUS",
        "iso": "JP",
        "status": 1,
        "name": "Minami Torishima Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "IWO",
        "lon": "141.31667",
        "iso": "JP",
        "status": 1,
        "name": "Iwo Jima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.783333",
        "size": "medium"
    },
    {
        "iata": "KIX",
        "lon": "135.24397",
        "iso": "JP",
        "status": 1,
        "name": "Kansai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.43533",
        "size": "large"
    },
    {
        "iata": "SHM",
        "lon": "135.35806",
        "iso": "JP",
        "status": 1,
        "name": "Nanki Shirahama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.66139",
        "size": "medium"
    },
    {
        "iata": "UKB",
        "lon": "135.2284",
        "iso": "JP",
        "status": 1,
        "name": "Kobe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.6373",
        "size": "medium"
    },
    {
        "iata": "HIW",
        "lon": "132.41638",
        "iso": "JP",
        "status": 1,
        "name": "Hiroshimanishi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.36361",
        "size": "medium"
    },
    {
        "iata": "TJH",
        "lon": "134.78767",
        "iso": "JP",
        "status": 1,
        "name": "Tajima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.51366",
        "size": "medium"
    },
    {
        "iata": "OBO",
        "lon": "143.21243",
        "iso": "JP",
        "status": 1,
        "name": "Tokachi-Obihiro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.732002",
        "size": "medium"
    },
    {
        "iata": "CTS",
        "lon": "141.68134",
        "iso": "JP",
        "status": 1,
        "name": "New Chitose Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.78728",
        "size": "large"
    },
    {
        "iata": "HKD",
        "lon": "140.81581",
        "iso": "JP",
        "status": 1,
        "name": "Hakodate Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.776127",
        "size": "medium"
    },
    {
        "iata": "KUH",
        "lon": "144.19682",
        "iso": "JP",
        "status": 1,
        "name": "Kushiro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.04565",
        "size": "medium"
    },
    {
        "iata": "MMB",
        "lon": "144.16722",
        "iso": "JP",
        "status": 1,
        "name": "Memanbetsu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.9",
        "size": "medium"
    },
    {
        "iata": "SHB",
        "lon": "144.95613",
        "iso": "JP",
        "status": 1,
        "name": "Nakashibetsu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.572197",
        "size": "medium"
    },
    {
        "iata": "OKD",
        "lon": "141.382",
        "iso": "JP",
        "status": 1,
        "name": "Okadama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.110374",
        "size": "medium"
    },
    {
        "iata": "RBJ",
        "lon": "141.03334",
        "iso": "JP",
        "status": 1,
        "name": "Rebun Airport Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.38333",
        "size": "small"
    },
    {
        "iata": "WKJ",
        "lon": "141.7974",
        "iso": "JP",
        "status": 1,
        "name": "Wakkanai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.39943",
        "size": "medium"
    },
    {
        "iata": "IKI",
        "lon": "129.78833",
        "iso": "JP",
        "status": 1,
        "name": "Iki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.745556",
        "size": "medium"
    },
    {
        "iata": "UBJ",
        "lon": "131.27489",
        "iso": "JP",
        "status": 1,
        "name": "Yamaguchi Ube Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.933395",
        "size": "medium"
    },
    {
        "iata": "TSJ",
        "lon": "129.32637",
        "iso": "JP",
        "status": 1,
        "name": "Tsushima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.28615",
        "size": "medium"
    },
    {
        "iata": "MBE",
        "lon": "143.38333",
        "iso": "JP",
        "status": 1,
        "name": "Monbetsu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "44.266666",
        "size": "medium"
    },
    {
        "iata": "AKJ",
        "lon": "142.45454",
        "iso": "JP",
        "status": 1,
        "name": "Asahikawa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.67109",
        "size": "medium"
    },
    {
        "iata": "OIR",
        "lon": "139.51666",
        "iso": "JP",
        "status": 1,
        "name": "Okushiri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.166668",
        "size": "medium"
    },
    {
        "iata": "RIS",
        "lon": "141.25",
        "iso": "JP",
        "status": 1,
        "name": "Rishiri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.183334",
        "size": "medium"
    },
    {
        "iata": "KUM",
        "lon": "130.65916",
        "iso": "JP",
        "status": 1,
        "name": "Yakushima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.385555",
        "size": "medium"
    },
    {
        "iata": "FUJ",
        "lon": "128.83762",
        "iso": "JP",
        "status": 1,
        "name": "Fukue Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.667828",
        "size": "medium"
    },
    {
        "iata": "FUK",
        "lon": "130.4439",
        "iso": "JP",
        "status": 1,
        "name": "Fukuoka Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.584286",
        "size": "large"
    },
    {
        "iata": "TNE",
        "lon": "130.9929",
        "iso": "JP",
        "status": 1,
        "name": "New Tanegashima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.604837",
        "size": "medium"
    },
    {
        "iata": "KOJ",
        "lon": "130.71562",
        "iso": "JP",
        "status": 1,
        "name": "Kagoshima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.801224",
        "size": "large"
    },
    {
        "iata": "KMI",
        "lon": "131.44147",
        "iso": "JP",
        "status": 1,
        "name": "Miyazaki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.872498",
        "size": "medium"
    },
    {
        "iata": "OIT",
        "lon": "131.73236",
        "iso": "JP",
        "status": 1,
        "name": "Oita Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.477238",
        "size": "medium"
    },
    {
        "iata": "KKJ",
        "lon": "131.03375",
        "iso": "JP",
        "status": 1,
        "name": "Kitaky?sh? Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.842793",
        "size": "medium"
    },
    {
        "iata": "HSG",
        "lon": "130.30278",
        "iso": "JP",
        "status": 1,
        "name": "Saga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.153828",
        "size": "medium"
    },
    {
        "iata": "KMJ",
        "lon": "130.85799",
        "iso": "JP",
        "status": 1,
        "name": "Kumamoto Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.834133",
        "size": "medium"
    },
    {
        "iata": "NGS",
        "lon": "129.92258",
        "iso": "JP",
        "status": 1,
        "name": "Nagasaki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.9144",
        "size": "medium"
    },
    {
        "iata": "NGO",
        "lon": "136.80528",
        "iso": "JP",
        "status": 1,
        "name": "Chubu Centrair International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.858334",
        "size": "large"
    },
    {
        "iata": "ASJ",
        "lon": "129.70793",
        "iso": "JP",
        "status": 1,
        "name": "Amami Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.431522",
        "size": "medium"
    },
    {
        "iata": "OKE",
        "lon": "128.70555",
        "iso": "JP",
        "status": 1,
        "name": "Okierabu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.431667",
        "size": "medium"
    },
    {
        "iata": "KKX",
        "lon": "129.92805",
        "iso": "JP",
        "status": 1,
        "name": "Kikai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.321388",
        "size": "medium"
    },
    {
        "iata": "TKN",
        "lon": "128.88333",
        "iso": "JP",
        "status": 1,
        "name": "Tokunoshima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.833332",
        "size": "medium"
    },
    {
        "iata": "NKM",
        "lon": "136.91957",
        "iso": "JP",
        "status": 1,
        "name": "Nagoya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.25389",
        "size": "medium"
    },
    {
        "iata": "FKJ",
        "lon": "136.22667",
        "iso": "JP",
        "status": 1,
        "name": "Fukui Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.13972",
        "size": "medium"
    },
    {
        "iata": "QGU",
        "lon": "136.89522",
        "iso": "JP",
        "status": 1,
        "name": "Gifu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.493137",
        "size": "medium"
    },
    {
        "iata": "KMQ",
        "lon": "136.41342",
        "iso": "JP",
        "status": 1,
        "name": "Komatsu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.40237",
        "size": "medium"
    },
    {
        "iata": "OKI",
        "lon": "133.31667",
        "iso": "JP",
        "status": 1,
        "name": "Oki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.166668",
        "size": "medium"
    },
    {
        "iata": "TOY",
        "lon": "137.18945",
        "iso": "JP",
        "status": 1,
        "name": "Toyama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.64245",
        "size": "medium"
    },
    {
        "iata": "NTQ",
        "lon": "136.9568",
        "iso": "JP",
        "status": 1,
        "name": "Noto Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.294678",
        "size": "medium"
    },
    {
        "iata": "HIJ",
        "lon": "132.91945",
        "iso": "JP",
        "status": 1,
        "name": "Hiroshima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.43611",
        "size": "medium"
    },
    {
        "iata": "OKJ",
        "lon": "133.85277",
        "iso": "JP",
        "status": 1,
        "name": "Okayama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.76022",
        "size": "medium"
    },
    {
        "iata": "IZO",
        "lon": "132.88576",
        "iso": "JP",
        "status": 1,
        "name": "Izumo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.41485",
        "size": "medium"
    },
    {
        "iata": "YGJ",
        "lon": "133.24448",
        "iso": "JP",
        "status": 1,
        "name": "Miho Yonago Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.500652",
        "size": "medium"
    },
    {
        "iata": "KCZ",
        "lon": "133.67485",
        "iso": "JP",
        "status": 1,
        "name": "K?chi Ry?ma Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.547688",
        "size": "medium"
    },
    {
        "iata": "MYJ",
        "lon": "132.70416",
        "iso": "JP",
        "status": 1,
        "name": "Matsuyama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.822224",
        "size": "medium"
    },
    {
        "iata": "ITM",
        "lon": "135.44171",
        "iso": "JP",
        "status": 1,
        "name": "Osaka International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.790974",
        "size": "large"
    },
    {
        "iata": "TTJ",
        "lon": "134.16756",
        "iso": "JP",
        "status": 1,
        "name": "Tottori Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.525913",
        "size": "medium"
    },
    {
        "iata": "TKS",
        "lon": "134.59483",
        "iso": "JP",
        "status": 1,
        "name": "Tokushima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.139023",
        "size": "medium"
    },
    {
        "iata": "TAK",
        "lon": "134.01825",
        "iso": "JP",
        "status": 1,
        "name": "Takamatsu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.219017",
        "size": "medium"
    },
    {
        "iata": "IWJ",
        "lon": "131.79028",
        "iso": "JP",
        "status": 1,
        "name": "Iwami Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.676388",
        "size": "medium"
    },
    {
        "iata": "AOJ",
        "lon": "140.68922",
        "iso": "JP",
        "status": 1,
        "name": "Aomori Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.738758",
        "size": "medium"
    },
    {
        "iata": "GAJ",
        "lon": "140.36583",
        "iso": "JP",
        "status": 1,
        "name": "Yamagata Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.410645",
        "size": "medium"
    },
    {
        "iata": "SDS",
        "lon": "138.41667",
        "iso": "JP",
        "status": 1,
        "name": "Sado Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.0",
        "size": "medium"
    },
    {
        "iata": "FKS",
        "lon": "140.43279",
        "iso": "JP",
        "status": 1,
        "name": "Fukushima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.226665",
        "size": "medium"
    },
    {
        "iata": "HHE",
        "lon": "141.47084",
        "iso": "JP",
        "status": 1,
        "name": "Hachinohe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.549168",
        "size": "medium"
    },
    {
        "iata": "HNA",
        "lon": "141.13077",
        "iso": "JP",
        "status": 1,
        "name": "Hanamaki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.426926",
        "size": "medium"
    },
    {
        "iata": "AXT",
        "lon": "140.22015",
        "iso": "JP",
        "status": 1,
        "name": "Akita Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.61177",
        "size": "medium"
    },
    {
        "iata": "MSJ",
        "lon": "141.3869",
        "iso": "JP",
        "status": 1,
        "name": "Misawa Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "40.696316",
        "size": "medium"
    },
    {
        "iata": "KIJ",
        "lon": "139.11325",
        "iso": "JP",
        "status": 1,
        "name": "Niigata Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.951992",
        "size": "medium"
    },
    {
        "iata": "ONJ",
        "lon": "140.37383",
        "iso": "JP",
        "status": 1,
        "name": "Odate Noshiro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.196415",
        "size": "medium"
    },
    {
        "iata": "SDJ",
        "lon": "140.92389",
        "iso": "JP",
        "status": 1,
        "name": "Sendai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.135555",
        "size": "medium"
    },
    {
        "iata": "SYO",
        "lon": "139.79056",
        "iso": "JP",
        "status": 1,
        "name": "Shonai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.809444",
        "size": "medium"
    },
    {
        "iata": "NJA",
        "lon": "139.35",
        "iso": "JP",
        "status": 1,
        "name": "Atsugi Naval Air Facility",
        "continent": "AS",
        "type": "airport",
        "lat": "35.416668",
        "size": "medium"
    },
    {
        "iata": "HAC",
        "lon": "139.78122",
        "iso": "JP",
        "status": 1,
        "name": "Hachijojima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.117947",
        "size": "medium"
    },
    {
        "iata": "OIM",
        "lon": "139.365",
        "iso": "JP",
        "status": 1,
        "name": "Oshima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.779167",
        "size": "medium"
    },
    {
        "iata": "MYE",
        "lon": "139.5625",
        "iso": "JP",
        "status": 1,
        "name": "Miyakejima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.069443",
        "size": "medium"
    },
    {
        "iata": "HND",
        "lon": "139.78453",
        "iso": "JP",
        "status": 1,
        "name": "Tokyo International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.54907",
        "size": "large"
    },
    {
        "iata": "QUT",
        "lon": "140.00609",
        "iso": "JP",
        "status": 1,
        "name": "Utsunomiya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.631527",
        "size": "medium"
    },
    {
        "iata": "OKO",
        "lon": "139.35",
        "iso": "JP",
        "status": 1,
        "name": "Yokota Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "35.75",
        "size": "large"
    },
    {
        "iata": "KWJ",
        "lon": "126.81021",
        "iso": "KR",
        "status": 1,
        "name": "Gwangju Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.140175",
        "size": "medium"
    },
    {
        "iata": "KUV",
        "lon": "126.75",
        "iso": "KR",
        "status": 1,
        "name": "Kunsan Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "35.983334",
        "size": "large"
    },
    {
        "iata": "MPK",
        "lon": "126.385",
        "iso": "KR",
        "status": 1,
        "name": "Mokpo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.756668",
        "size": "medium"
    },
    {
        "iata": "CHN",
        "lon": "127.933334",
        "iso": "KR",
        "status": 1,
        "name": "Jeon Ju Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.016945",
        "size": "small"
    },
    {
        "iata": "RSU",
        "lon": "127.61267",
        "iso": "KR",
        "status": 1,
        "name": "Yeosu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.8419",
        "size": "medium"
    },
    {
        "iata": "QUN",
        "lon": "127.85",
        "iso": "KR",
        "status": 1,
        "name": "A-306 Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.9",
        "size": "small"
    },
    {
        "iata": "SHO",
        "lon": "128.6",
        "iso": "KR",
        "status": 1,
        "name": "Sokcho Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.13333",
        "size": "small"
    },
    {
        "iata": "KAG",
        "lon": "128.94972",
        "iso": "KR",
        "status": 1,
        "name": "Gangneung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.75222",
        "size": "medium"
    },
    {
        "iata": "WJU",
        "lon": "127.95167",
        "iso": "KR",
        "status": 1,
        "name": "Wonju Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.434166",
        "size": "medium"
    },
    {
        "iata": "YNY",
        "lon": "128.6",
        "iso": "KR",
        "status": 1,
        "name": "Yangyang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.066666",
        "size": "medium"
    },
    {
        "iata": "CJU",
        "lon": "126.49312",
        "iso": "KR",
        "status": 1,
        "name": "Jeju International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.5067",
        "size": "large"
    },
    {
        "iata": "CHF",
        "lon": "128.69778",
        "iso": "KR",
        "status": 1,
        "name": "Jinhae Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.1375",
        "size": "small"
    },
    {
        "iata": "PUS",
        "lon": "128.94873",
        "iso": "KR",
        "status": 1,
        "name": "Gimhae International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.179317",
        "size": "large"
    },
    {
        "iata": "HIN",
        "lon": "128.08676",
        "iso": "KR",
        "status": 1,
        "name": "Sacheon Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "35.09263",
        "size": "medium"
    },
    {
        "iata": "USN",
        "lon": "129.35597",
        "iso": "KR",
        "status": 1,
        "name": "Ulsan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.59367",
        "size": "medium"
    },
    {
        "iata": "ICN",
        "lon": "126.45123",
        "iso": "KR",
        "status": 1,
        "name": "Incheon International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.448524",
        "size": "large"
    },
    {
        "iata": "SSN",
        "lon": "127.11261",
        "iso": "KR",
        "status": 1,
        "name": "Seoul Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "37.447243",
        "size": "medium"
    },
    {
        "iata": "OSN",
        "lon": "127.03333",
        "iso": "KR",
        "status": 1,
        "name": "Osan Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "37.083332",
        "size": "large"
    },
    {
        "iata": "GMP",
        "lon": "126.80351",
        "iso": "KR",
        "status": 1,
        "name": "Gimpo International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.559288",
        "size": "large"
    },
    {
        "iata": "SWU",
        "lon": "127.01667",
        "iso": "KR",
        "status": 1,
        "name": "Suwon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.283333",
        "size": "medium"
    },
    {
        "iata": "KPO",
        "lon": "129.43375",
        "iso": "KR",
        "status": 1,
        "name": "Pohang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.98371",
        "size": "medium"
    },
    {
        "iata": "TAE",
        "lon": "128.63788",
        "iso": "KR",
        "status": 1,
        "name": "Daegu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.899254",
        "size": "medium"
    },
    {
        "iata": "CJJ",
        "lon": "127.49509",
        "iso": "KR",
        "status": 1,
        "name": "Cheongju International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.7224",
        "size": "large"
    },
    {
        "iata": "YEC",
        "lon": "128.355",
        "iso": "KR",
        "status": 1,
        "name": "Yecheon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.625",
        "size": "medium"
    },
    {
        "iata": "OKA",
        "lon": "127.645836",
        "iso": "JP",
        "status": 1,
        "name": "Naha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.195833",
        "size": "large"
    },
    {
        "iata": "DNA",
        "lon": "127.76667",
        "iso": "JP",
        "status": 1,
        "name": "Kadena Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "26.35",
        "size": "large"
    },
    {
        "iata": "ISG",
        "lon": "124.16889",
        "iso": "JP",
        "status": 1,
        "name": "Ishigaki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.336945",
        "size": "medium"
    },
    {
        "iata": "UEO",
        "lon": "126.719444",
        "iso": "JP",
        "status": 1,
        "name": "Kumejima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.365278",
        "size": "medium"
    },
    {
        "iata": "KJP",
        "lon": "127.333336",
        "iso": "JP",
        "status": 1,
        "name": "Kerama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.083332",
        "size": "small"
    },
    {
        "iata": "MMD",
        "lon": "131.24722",
        "iso": "JP",
        "status": 1,
        "name": "Minami Daito Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.841389",
        "size": "medium"
    },
    {
        "iata": "MMY",
        "lon": "125.29778",
        "iso": "JP",
        "status": 1,
        "name": "Miyako Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.779198",
        "size": "medium"
    },
    {
        "iata": "AGJ",
        "lon": "127.23805",
        "iso": "JP",
        "status": 1,
        "name": "Aguni Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.589722",
        "size": "small"
    },
    {
        "iata": "IEJ",
        "lon": "127.8",
        "iso": "JP",
        "status": 1,
        "name": "Ie Jima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.733334",
        "size": "medium"
    },
    {
        "iata": "HTR",
        "lon": "123.8",
        "iso": "JP",
        "status": 1,
        "name": "Hateruma Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.05",
        "size": "small"
    },
    {
        "iata": "KTD",
        "lon": "131.3",
        "iso": "JP",
        "status": 1,
        "name": "Kitadaito Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.95",
        "size": "medium"
    },
    {
        "iata": "SHI",
        "lon": "125.15",
        "iso": "JP",
        "status": 1,
        "name": "Shimojishima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.816668",
        "size": "medium"
    },
    {
        "iata": "TRA",
        "lon": "124.7",
        "iso": "JP",
        "status": 1,
        "name": "Tarama Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.65",
        "size": "medium"
    },
    {
        "iata": "RNJ",
        "lon": "128.40266",
        "iso": "JP",
        "status": 1,
        "name": "Yoron Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.043634",
        "size": "medium"
    },
    {
        "iata": "OGN",
        "lon": "122.97639",
        "iso": "JP",
        "status": 1,
        "name": "Yonaguni Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.461111",
        "size": "medium"
    },
    {
        "iata": "SFS",
        "lon": "120.26722",
        "iso": "PH",
        "status": 1,
        "name": "Subic Bay International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.785556",
        "size": "medium"
    },
    {
        "iata": "CRK",
        "lon": "120.546486",
        "iso": "PH",
        "status": 1,
        "name": "Diosdado Macapagal International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.182571",
        "size": "large"
    },
    {
        "iata": "LAO",
        "lon": "120.53413",
        "iso": "PH",
        "status": 1,
        "name": "Laoag International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.182407",
        "size": "medium"
    },
    {
        "iata": "MNL",
        "lon": "121.01251",
        "iso": "PH",
        "status": 1,
        "name": "Ninoy Aquino International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.509602",
        "size": "large"
    },
    {
        "iata": "CYU",
        "lon": "121.0",
        "iso": "PH",
        "status": 1,
        "name": "Cuyo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.883333",
        "size": "small"
    },
    {
        "iata": "LGP",
        "lon": "123.7302",
        "iso": "PH",
        "status": 1,
        "name": "Legazpi City International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.15213",
        "size": "medium"
    },
    {
        "iata": "NSP",
        "iso": "PH",
        "status": 1,
        "name": "Sangley Point Air Base",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "LBX",
        "lon": "120.105",
        "iso": "PH",
        "status": 1,
        "name": "Lubang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.865556",
        "size": "medium"
    },
    {
        "iata": "AAV",
        "lon": "124.76667",
        "iso": "PH",
        "status": 1,
        "name": "Allah Valley Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.333333",
        "size": "medium"
    },
    {
        "iata": "GES",
        "lon": "125.09583",
        "iso": "PH",
        "status": 1,
        "name": "General Santos International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.057778",
        "size": "medium"
    },
    {
        "iata": "CBO",
        "lon": "124.21464",
        "iso": "PH",
        "status": 1,
        "name": "Awang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.161412",
        "size": "medium"
    },
    {
        "iata": "DVO",
        "lon": "125.6447",
        "iso": "PH",
        "status": 1,
        "name": "Francisco Bangoy International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.130696",
        "size": "large"
    },
    {
        "iata": "BXU",
        "lon": "125.48123",
        "iso": "PH",
        "status": 1,
        "name": "Bancasi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.947999",
        "size": "medium"
    },
    {
        "iata": "BPH",
        "lon": "126.32111",
        "iso": "PH",
        "status": 1,
        "name": "Bislig Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.196667",
        "size": "medium"
    },
    {
        "iata": "DPL",
        "lon": "123.34418",
        "iso": "PH",
        "status": 1,
        "name": "Dipolog Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.599978",
        "size": "medium"
    },
    {
        "iata": "CGM",
        "lon": "124.70695",
        "iso": "PH",
        "status": 1,
        "name": "Camiguin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.253611",
        "size": "medium"
    },
    {
        "iata": "IGN",
        "lon": "124.214165",
        "iso": "PH",
        "status": 1,
        "name": "Iligan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.131111",
        "size": "medium"
    },
    {
        "iata": "JOL",
        "lon": "121.00889",
        "iso": "PH",
        "status": 1,
        "name": "Jolo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.055278",
        "size": "medium"
    },
    {
        "iata": "CGY",
        "lon": "124.45722",
        "iso": "PH",
        "status": 1,
        "name": "Cagayan De Oro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.6125",
        "size": "medium"
    },
    {
        "iata": "MLP",
        "lon": "124.0575",
        "iso": "PH",
        "status": 1,
        "name": "Malabang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.618333",
        "size": "small"
    },
    {
        "iata": "SGS",
        "lon": "119.74433",
        "iso": "PH",
        "status": 1,
        "name": "Sanga Sanga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.04455",
        "size": "medium"
    },
    {
        "iata": "OZC",
        "lon": "123.84541",
        "iso": "PH",
        "status": 1,
        "name": "Labo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.181967",
        "size": "medium"
    },
    {
        "iata": "PAG",
        "lon": "123.4575",
        "iso": "PH",
        "status": 1,
        "name": "Pagadian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.826667",
        "size": "medium"
    },
    {
        "iata": "MXI",
        "lon": "126.25",
        "iso": "PH",
        "status": 1,
        "name": "Mati National Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.916667",
        "size": "medium"
    },
    {
        "iata": "SUG",
        "lon": "125.479164",
        "iso": "PH",
        "status": 1,
        "name": "Surigao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.758889",
        "size": "medium"
    },
    {
        "iata": "CDY",
        "lon": "118.495",
        "iso": "PH",
        "status": 1,
        "name": "Cagayan de Sulu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.013611",
        "size": "small"
    },
    {
        "iata": "IPE",
        "lon": "122.583336",
        "iso": "PH",
        "status": 1,
        "name": "Ipil Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.783333",
        "size": "small"
    },
    {
        "iata": "TDG",
        "lon": "126.00833",
        "iso": "PH",
        "status": 1,
        "name": "Tandag Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.5",
        "size": "medium"
    },
    {
        "iata": "ZAM",
        "lon": "122.062256",
        "iso": "PH",
        "status": 1,
        "name": "Zamboanga International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.919294",
        "size": "medium"
    },
    {
        "iata": "IAO",
        "lon": "126.013885",
        "iso": "PH",
        "status": 1,
        "name": "Siargao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.858889",
        "size": "small"
    },
    {
        "iata": "BAG",
        "lon": "120.617775",
        "iso": "PH",
        "status": 1,
        "name": "Loakan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.376667",
        "size": "medium"
    },
    {
        "iata": "DTE",
        "lon": "122.98333",
        "iso": "PH",
        "status": 1,
        "name": "Daet Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.133333",
        "size": "medium"
    },
    {
        "iata": "SJI",
        "lon": "121.04569",
        "iso": "PH",
        "status": 1,
        "name": "San Jose Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.359764",
        "size": "medium"
    },
    {
        "iata": "MBO",
        "lon": "120.60333",
        "iso": "PH",
        "status": 1,
        "name": "Mamburao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.211389",
        "size": "medium"
    },
    {
        "iata": "WNP",
        "lon": "123.27",
        "iso": "PH",
        "status": 1,
        "name": "Naga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.587222",
        "size": "medium"
    },
    {
        "iata": "BSO",
        "lon": "121.977776",
        "iso": "PH",
        "status": 1,
        "name": "Basco Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.453056",
        "size": "medium"
    },
    {
        "iata": "BQA",
        "lon": "121.083336",
        "iso": "PH",
        "status": 1,
        "name": "Dr.Juan C. Angara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.0",
        "size": "medium"
    },
    {
        "iata": "SFE",
        "lon": "120.3",
        "iso": "PH",
        "status": 1,
        "name": "San Fernando Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.6",
        "size": "medium"
    },
    {
        "iata": "TUG",
        "lon": "121.73167",
        "iso": "PH",
        "status": 1,
        "name": "Tuguegarao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.641111",
        "size": "medium"
    },
    {
        "iata": "VRC",
        "lon": "124.2",
        "iso": "PH",
        "status": 1,
        "name": "Virac Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.583333",
        "size": "medium"
    },
    {
        "iata": "MRQ",
        "lon": "121.82361",
        "iso": "PH",
        "status": 1,
        "name": "Marinduque Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.363056",
        "size": "medium"
    },
    {
        "iata": "CYZ",
        "lon": "121.75584",
        "iso": "PH",
        "status": 1,
        "name": "Cauayan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.93",
        "size": "medium"
    },
    {
        "iata": "TAC",
        "lon": "125.02592",
        "iso": "PH",
        "status": 1,
        "name": "Daniel Z. Romualdez Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.22684",
        "size": "medium"
    },
    {
        "iata": "BCD",
        "lon": "122.93359",
        "iso": "PH",
        "status": 1,
        "name": "Bacolod-Silay City International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.644815",
        "size": "medium"
    },
    {
        "iata": "CYP",
        "lon": "124.54305",
        "iso": "PH",
        "status": 1,
        "name": "Calbayog Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.075833",
        "size": "medium"
    },
    {
        "iata": "DGT",
        "lon": "123.29601",
        "iso": "PH",
        "status": 1,
        "name": "Sibulan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.332543",
        "size": "medium"
    },
    {
        "iata": "MPH",
        "lon": "121.9521",
        "iso": "PH",
        "status": 1,
        "name": "Godofredo P. Ramos Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.925263",
        "size": "medium"
    },
    {
        "iata": "CRM",
        "lon": "124.583336",
        "iso": "PH",
        "status": 1,
        "name": "Catarman National Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.483333",
        "size": "medium"
    },
    {
        "iata": "GUI",
        "lon": "-62.3",
        "iso": "PH",
        "status": 1,
        "name": "Guiuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.566667",
        "size": "small"
    },
    {
        "iata": "ILO",
        "lon": "122.543884",
        "iso": "PH",
        "status": 1,
        "name": "Iloilo International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.714312",
        "size": "medium"
    },
    {
        "iata": "MBT",
        "lon": "123.066666",
        "iso": "PH",
        "status": 1,
        "name": "Moises R. Espinosa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.366667",
        "size": "medium"
    },
    {
        "iata": "KLO",
        "lon": "122.38183",
        "iso": "PH",
        "status": 1,
        "name": "Kalibo International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.687152",
        "size": "medium"
    },
    {
        "iata": "CEB",
        "lon": "123.98278",
        "iso": "PH",
        "status": 1,
        "name": "Mactan Cebu International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.313333",
        "size": "large"
    },
    {
        "iata": "OMC",
        "lon": "124.566666",
        "iso": "PH",
        "status": 1,
        "name": "Ormoc Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.05",
        "size": "medium"
    },
    {
        "iata": "PPS",
        "lon": "118.75674",
        "iso": "PH",
        "status": 1,
        "name": "Puerto Princesa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.740198",
        "size": "medium"
    },
    {
        "iata": "RXS",
        "lon": "122.74882",
        "iso": "PH",
        "status": 1,
        "name": "Roxas Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.60066",
        "size": "medium"
    },
    {
        "iata": "EUQ",
        "lon": "121.941666",
        "iso": "PH",
        "status": 1,
        "name": "Evelio Javier Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.775",
        "size": "medium"
    },
    {
        "iata": "TAG",
        "lon": "123.853615",
        "iso": "PH",
        "status": 1,
        "name": "Tagbilaran Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.657587",
        "size": "medium"
    },
    {
        "iata": "TBH",
        "lon": "122.08056",
        "iso": "PH",
        "status": 1,
        "name": "Romblon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.31612",
        "size": "medium"
    },
    {
        "iata": "USU",
        "lon": "120.166664",
        "iso": "PH",
        "status": 1,
        "name": "Francisco B. Reyes Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.1",
        "size": "medium"
    },
    {
        "iata": "BPR",
        "iso": "PH",
        "status": 1,
        "name": "Borongan Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "NGK",
        "lon": "143.16667",
        "iso": "RU",
        "status": 1,
        "name": "Nogliki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "51.8",
        "size": "small"
    },
    {
        "iata": "GRV",
        "lon": "45.75",
        "iso": "RU",
        "status": 1,
        "name": "Grozny North Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.333332",
        "size": "large"
    },
    {
        "iata": "LNX",
        "lon": "32.05",
        "iso": "RU",
        "status": 1,
        "name": "Smolensk South Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.8",
        "size": "small"
    },
    {
        "iata": "VUS",
        "lon": "46.316666",
        "iso": "RU",
        "status": 1,
        "name": "Velikiy Ustyug Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "60.766666",
        "size": "small"
    },
    {
        "iata": "LPS",
        "lon": "-122.935555",
        "iso": "US",
        "status": 1,
        "name": "Lopez Island Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "48.482777",
        "size": "small"
    },
    {
        "iata": "MJR",
        "lon": "-57.833332",
        "iso": "AR",
        "status": 1,
        "name": "Miramar Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.25",
        "size": "small"
    },
    {
        "iata": "CCT",
        "lon": "-68.916664",
        "iso": "AR",
        "status": 1,
        "name": "Colonia Catriel Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-39.5",
        "size": "small"
    },
    {
        "iata": "COC",
        "lon": "-57.996387",
        "iso": "AR",
        "status": 1,
        "name": "Comodoro Pierrestegui Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.297222",
        "size": "medium"
    },
    {
        "iata": "GHU",
        "lon": "-58.61222",
        "iso": "AR",
        "status": 1,
        "name": "Gualeguaychu Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.004166",
        "size": "medium"
    },
    {
        "iata": "JNI",
        "lon": "-60.966667",
        "iso": "AR",
        "status": 1,
        "name": "Junin Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.583332",
        "size": "medium"
    },
    {
        "iata": "PRA",
        "lon": "-60.480278",
        "iso": "AR",
        "status": 1,
        "name": "General Urquiza Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.794722",
        "size": "medium"
    },
    {
        "iata": "ROS",
        "lon": "-60.783333",
        "iso": "AR",
        "status": 1,
        "name": "Islas Malvinas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-32.933334",
        "size": "medium"
    },
    {
        "iata": "SFN",
        "lon": "-60.816666",
        "iso": "AR",
        "status": 1,
        "name": "Sauce Viejo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.716667",
        "size": "medium"
    },
    {
        "iata": "AEP",
        "lon": "-58.41667",
        "iso": "AR",
        "status": 1,
        "name": "Jorge Newbery Airpark",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.55622",
        "size": "medium"
    },
    {
        "iata": "LCM",
        "lon": "-64.5",
        "iso": "AR",
        "status": 1,
        "name": "La Cumbre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.966667",
        "size": "small"
    },
    {
        "iata": "COR",
        "lon": "-64.21377",
        "iso": "AR",
        "status": 1,
        "name": "Ingeniero Ambrosio Taravella Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.31548",
        "size": "medium"
    },
    {
        "iata": "FDO",
        "iso": "AR",
        "status": 1,
        "name": "San Fernando Airport",
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "LPG",
        "lon": "-57.892334",
        "iso": "AR",
        "status": 1,
        "name": "La Plata Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.974953",
        "size": "medium"
    },
    {
        "iata": "EPA",
        "lon": "-58.6125",
        "iso": "AR",
        "status": 1,
        "name": "El Palomar Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.608334",
        "size": "medium"
    },
    {
        "iata": "EZE",
        "lon": "-58.539833",
        "iso": "AR",
        "status": 1,
        "name": "Ministro Pistarini International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.81273",
        "size": "large"
    },
    {
        "iata": "HOS",
        "lon": "-70.21667",
        "iso": "AR",
        "status": 1,
        "name": "Chos Malal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-37.416668",
        "size": "small"
    },
    {
        "iata": "CVH",
        "lon": "-71.0",
        "iso": "AR",
        "status": 1,
        "name": "Caviahue Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-37.916668",
        "size": "small"
    },
    {
        "iata": "GNR",
        "lon": "-67.616066",
        "iso": "AR",
        "status": 1,
        "name": "Dr. Arturo H. Illia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-39.00166",
        "size": "small"
    },
    {
        "iata": "RDS",
        "lon": "-68.9",
        "iso": "AR",
        "status": 1,
        "name": "Rincon De Los Sauces Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-37.38333",
        "size": "medium"
    },
    {
        "iata": "APZ",
        "lon": "-70.083336",
        "iso": "AR",
        "status": 1,
        "name": "Zapala Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.916668",
        "size": "medium"
    },
    {
        "iata": "MDZ",
        "lon": "-68.79891",
        "iso": "AR",
        "status": 1,
        "name": "El Plumerillo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-32.82789",
        "size": "medium"
    },
    {
        "iata": "LGS",
        "lon": "-69.57457",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-35.49324",
        "size": "medium"
    },
    {
        "iata": "AFA",
        "lon": "-68.40056",
        "iso": "AR",
        "status": 1,
        "name": "Suboficial Ay Santiago Germano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.58917",
        "size": "medium"
    },
    {
        "iata": "CTC",
        "lon": "-65.779724",
        "iso": "AR",
        "status": 1,
        "name": "Catamarca Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-28.448334",
        "size": "medium"
    },
    {
        "iata": "SDE",
        "lon": "-64.32222",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-27.765278",
        "size": "medium"
    },
    {
        "iata": "RHD",
        "lon": "-64.95",
        "iso": "AR",
        "status": 1,
        "name": "Las Termas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.533333",
        "size": "small"
    },
    {
        "iata": "IRJ",
        "lon": "-66.78333",
        "iso": "AR",
        "status": 1,
        "name": "Capitan V A Almonacid Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.383333",
        "size": "medium"
    },
    {
        "iata": "TUC",
        "lon": "-65.2",
        "iso": "AR",
        "status": 1,
        "name": "Teniente Benjamin Matienzo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.833332",
        "size": "medium"
    },
    {
        "iata": "UAQ",
        "lon": "-68.51667",
        "iso": "AR",
        "status": 1,
        "name": "Domingo Faustino Sarmiento Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.55",
        "size": "medium"
    },
    {
        "iata": "CRR",
        "lon": "-61.833332",
        "iso": "AR",
        "status": 1,
        "name": "Ceres Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.916668",
        "size": "small"
    },
    {
        "iata": "RCU",
        "lon": "-64.331665",
        "iso": "AR",
        "status": 1,
        "name": "Area De Material Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.165558",
        "size": "medium"
    },
    {
        "iata": "VDR",
        "lon": "-65.14222",
        "iso": "AR",
        "status": 1,
        "name": "Villa Dolores Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.94111",
        "size": "medium"
    },
    {
        "iata": "VME",
        "lon": "-65.416664",
        "iso": "AR",
        "status": 1,
        "name": "Villa Reynolds Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.63333",
        "size": "medium"
    },
    {
        "iata": "RLO",
        "lon": "-65.5525",
        "iso": "AR",
        "status": 1,
        "name": "Valle Del Conlara International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-32.384445",
        "size": "small"
    },
    {
        "iata": "LUQ",
        "lon": "-66.35861",
        "iso": "AR",
        "status": 1,
        "name": "Brigadier Mayor D Cesar Raul Ojeda Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.274445",
        "size": "medium"
    },
    {
        "iata": "CNQ",
        "lon": "-58.762222",
        "iso": "AR",
        "status": 1,
        "name": "Corrientes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.449722",
        "size": "medium"
    },
    {
        "iata": "RES",
        "lon": "-59.050835",
        "iso": "AR",
        "status": 1,
        "name": "Resistencia International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.45139",
        "size": "medium"
    },
    {
        "iata": "FMA",
        "lon": "-58.235",
        "iso": "AR",
        "status": 1,
        "name": "Formosa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.212778",
        "size": "medium"
    },
    {
        "iata": "IGR",
        "lon": "-54.47635",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-25.731504",
        "size": "medium"
    },
    {
        "iata": "AOL",
        "lon": "-57.15",
        "iso": "AR",
        "status": 1,
        "name": "Paso De Los Libres Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.683332",
        "size": "medium"
    },
    {
        "iata": "MCS",
        "lon": "-57.64111",
        "iso": "AR",
        "status": 1,
        "name": "Monte Caseros Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.270832",
        "size": "medium"
    },
    {
        "iata": "PSS",
        "lon": "-55.9675",
        "iso": "AR",
        "status": 1,
        "name": "Libertador Gral D Jose De San Martin Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.383888",
        "size": "medium"
    },
    {
        "iata": "PSV",
        "lon": "-1.7",
        "iso": "AR",
        "status": 1,
        "name": "Rovere Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "60.316666",
        "size": "small"
    },
    {
        "iata": "SLA",
        "lon": "-65.47849",
        "iso": "AR",
        "status": 1,
        "name": "Martin Miguel De Guemes International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.844217",
        "size": "medium"
    },
    {
        "iata": "JUJ",
        "lon": "-65.083336",
        "iso": "AR",
        "status": 1,
        "name": "Gobernador Horacio Guzman International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.4",
        "size": "medium"
    },
    {
        "iata": "ORA",
        "lon": "-64.166664",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-23.333332",
        "size": "medium"
    },
    {
        "iata": "TTG",
        "lon": "-63.833332",
        "iso": "AR",
        "status": 1,
        "name": "General Enrique Mosconi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.533333",
        "size": "medium"
    },
    {
        "iata": "CLX",
        "lon": "-57.666668",
        "iso": "AR",
        "status": 1,
        "name": "Clorinda Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.333332",
        "size": "small"
    },
    {
        "iata": "ELO",
        "lon": "-54.733334",
        "iso": "AR",
        "status": 1,
        "name": "El Dorado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.5",
        "size": "small"
    },
    {
        "iata": "OYA",
        "lon": "-59.216667",
        "iso": "AR",
        "status": 1,
        "name": "Goya Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.103333",
        "size": "medium"
    },
    {
        "iata": "LLS",
        "lon": "-60.583332",
        "iso": "AR",
        "status": 1,
        "name": "Alferez Armando Rodriguez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.716667",
        "size": "small"
    },
    {
        "iata": "MDX",
        "lon": "-58.075558",
        "iso": "AR",
        "status": 1,
        "name": "Mercedes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.167221",
        "size": "small"
    },
    {
        "iata": "RCQ",
        "lon": "-59.7",
        "iso": "AR",
        "status": 1,
        "name": "Reconquista Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.183332",
        "size": "medium"
    },
    {
        "iata": "UZU",
        "lon": "-58.095554",
        "iso": "AR",
        "status": 1,
        "name": "Curuzu Cuatia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.778889",
        "size": "medium"
    },
    {
        "iata": "EHL",
        "lon": "-71.75",
        "iso": "AR",
        "status": 1,
        "name": "El Bolson Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-42.0",
        "size": "medium"
    },
    {
        "iata": "CRD",
        "lon": "-67.462776",
        "iso": "AR",
        "status": 1,
        "name": "General E. Mosconi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-45.787224",
        "size": "medium"
    },
    {
        "iata": "EMX",
        "lon": "-71.15",
        "iso": "AR",
        "status": 1,
        "name": "El Maiten Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-42.033333",
        "size": "small"
    },
    {
        "iata": "EQS",
        "lon": "-71.1425",
        "iso": "AR",
        "status": 1,
        "name": "Brigadier Antonio Parodi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-42.90972",
        "size": "medium"
    },
    {
        "iata": "LHS",
        "lon": "-68.816666",
        "iso": "AR",
        "status": 1,
        "name": "Las Heras Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-32.85",
        "size": "medium"
    },
    {
        "iata": "IGB",
        "lon": "-69.583336",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-41.3",
        "size": "small"
    },
    {
        "iata": "OES",
        "lon": "-64.95",
        "iso": "AR",
        "status": 1,
        "name": "Antoine De St Exupery Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.733334",
        "size": "small"
    },
    {
        "iata": "MQD",
        "lon": "-68.73333",
        "iso": "AR",
        "status": 1,
        "name": "Maquinchao Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-41.25",
        "size": "small"
    },
    {
        "iata": "ARR",
        "lon": "-70.833336",
        "iso": "AR",
        "status": 1,
        "name": "D. Casimiro Szlapelis Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-45.033333",
        "size": "small"
    },
    {
        "iata": "SGV",
        "lon": "-65.35",
        "iso": "AR",
        "status": 1,
        "name": "Sierra Grande Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-41.566666",
        "size": "small"
    },
    {
        "iata": "REL",
        "lon": "-65.32333",
        "iso": "AR",
        "status": 1,
        "name": "Almirante Marco Andres Zar Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-43.233334",
        "size": "medium"
    },
    {
        "iata": "VDM",
        "lon": "-63.00084",
        "iso": "AR",
        "status": 1,
        "name": "Gobernador Castello Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.86429",
        "size": "medium"
    },
    {
        "iata": "PMY",
        "lon": "-65.066666",
        "iso": "AR",
        "status": 1,
        "name": "El Tehuelche Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-42.733334",
        "size": "medium"
    },
    {
        "iata": "ING",
        "lon": "-72.666664",
        "iso": "AR",
        "status": 1,
        "name": "Lago Argentino Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-50.583332",
        "size": "small"
    },
    {
        "iata": "FTE",
        "lon": "-72.053696",
        "iso": "AR",
        "status": 1,
        "name": "El Calafate Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-50.284225",
        "size": "medium"
    },
    {
        "iata": "PUD",
        "lon": "-65.916664",
        "iso": "AR",
        "status": 1,
        "name": "Puerto Deseado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-47.75",
        "size": "medium"
    },
    {
        "iata": "RGA",
        "lon": "-67.75",
        "iso": "AR",
        "status": 1,
        "name": "Hermes Quijada International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-53.779167",
        "size": "medium"
    },
    {
        "iata": "RGL",
        "lon": "-69.28333",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-51.61667",
        "size": "medium"
    },
    {
        "iata": "USH",
        "lon": "-68.31245",
        "iso": "AR",
        "status": 1,
        "name": "Malvinas Argentinas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-54.839348",
        "size": "medium"
    },
    {
        "iata": "ULA",
        "lon": "-67.666664",
        "iso": "AR",
        "status": 1,
        "name": "Capitan D Daniel Vazquez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-49.316666",
        "size": "medium"
    },
    {
        "iata": "ROY",
        "lon": "-70.25",
        "iso": "AR",
        "status": 1,
        "name": "Rio Mayo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-45.666668",
        "size": "small"
    },
    {
        "iata": "PMQ",
        "lon": "-70.98361",
        "iso": "AR",
        "status": 1,
        "name": "Perito Moreno Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-46.53639",
        "size": "medium"
    },
    {
        "iata": "GGS",
        "lon": "-70.25",
        "iso": "AR",
        "status": 1,
        "name": "Gobernador Gregores Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-48.766666",
        "size": "small"
    },
    {
        "iata": "JSM",
        "lon": "-70.433334",
        "iso": "AR",
        "status": 1,
        "name": "Jose De San Martin Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-44.066666",
        "size": "small"
    },
    {
        "iata": "RYO",
        "lon": "-72.2196",
        "iso": "AR",
        "status": 1,
        "name": "28 De Noviembre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-51.604477",
        "size": "small"
    },
    {
        "iata": "RZA",
        "lon": "-68.53333",
        "iso": "AR",
        "status": 1,
        "name": "Santa Cruz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-50.0",
        "size": "medium"
    },
    {
        "iata": "BHI",
        "lon": "-62.150555",
        "iso": "AR",
        "status": 1,
        "name": "Comandante Espora Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.730556",
        "size": "medium"
    },
    {
        "iata": "CSZ",
        "lon": "-61.88333",
        "iso": "AR",
        "status": 1,
        "name": "Brigadier D.H.E. Ruiz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-37.433334",
        "size": "small"
    },
    {
        "iata": "OVR",
        "lon": "-60.22778",
        "iso": "AR",
        "status": 1,
        "name": "Olavarria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-36.88889",
        "size": "small"
    },
    {
        "iata": "GPO",
        "lon": "-63.766666",
        "iso": "AR",
        "status": 1,
        "name": "General Pico Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-35.63333",
        "size": "medium"
    },
    {
        "iata": "OYO",
        "lon": "-60.25",
        "iso": "AR",
        "status": 1,
        "name": "Tres Arroyos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.36667",
        "size": "medium"
    },
    {
        "iata": "SST",
        "lon": "-60.783333",
        "iso": "AR",
        "status": 1,
        "name": "Santa Teresita Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.416668",
        "size": "medium"
    },
    {
        "iata": "MDQ",
        "lon": "-57.572224",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-37.934723",
        "size": "medium"
    },
    {
        "iata": "NQN",
        "lon": "-68.15222",
        "iso": "AR",
        "status": 1,
        "name": "Presidente Peron Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.95",
        "size": "medium"
    },
    {
        "iata": "NEC",
        "lon": "-58.75",
        "iso": "AR",
        "status": 1,
        "name": "Necochea Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.566666",
        "size": "medium"
    },
    {
        "iata": "PEH",
        "lon": "-61.86667",
        "iso": "AR",
        "status": 1,
        "name": "Comodoro Pedro Zanni Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-35.85",
        "size": "medium"
    },
    {
        "iata": "RSA",
        "lon": "-64.26667",
        "iso": "AR",
        "status": 1,
        "name": "Santa Rosa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-36.566666",
        "size": "medium"
    },
    {
        "iata": "BRC",
        "lon": "-71.16109",
        "iso": "AR",
        "status": 1,
        "name": "San Carlos De Bariloche Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-41.145966",
        "size": "medium"
    },
    {
        "iata": "TDL",
        "lon": "-59.083332",
        "iso": "AR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-37.333332",
        "size": "medium"
    },
    {
        "iata": "VLG",
        "lon": "-57.583332",
        "iso": "AR",
        "status": 1,
        "name": "Villa Gesell Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.016666",
        "size": "medium"
    },
    {
        "iata": "CUT",
        "lon": "-69.0",
        "iso": "AR",
        "status": 1,
        "name": "Cutral-Co Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.9",
        "size": "medium"
    },
    {
        "iata": "CPC",
        "lon": "-71.13333",
        "iso": "AR",
        "status": 1,
        "name": "Aviador C. Campos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.066666",
        "size": "medium"
    },
    {
        "iata": "VIU",
        "lon": "157.66667",
        "iso": "SB",
        "status": 1,
        "name": "Viru Harbour Airstrip",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.5",
        "size": "small"
    },
    {
        "iata": "SB0",
        "iso": "BR",
        "status": 0,
        "name": "Pirenopolis Centeral Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "SB2",
        "iso": "BR",
        "status": 0,
        "name": "Fazenda Bela Vista Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "CDJ",
        "lon": "-49.283333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-8.233333",
        "size": "medium"
    },
    {
        "iata": "APS",
        "lon": "-48.966667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.333332",
        "size": "medium"
    },
    {
        "iata": "AQA",
        "lon": "-48.13691",
        "iso": "BR",
        "status": 1,
        "name": "Araraquara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.806643",
        "size": "medium"
    },
    {
        "iata": "AJU",
        "lon": "-37.072792",
        "iso": "BR",
        "status": 1,
        "name": "Santa Maria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.987206",
        "size": "medium"
    },
    {
        "iata": "AFL",
        "lon": "-56.104767",
        "iso": "BR",
        "status": 1,
        "name": "Alta Floresta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.872456",
        "size": "medium"
    },
    {
        "iata": "ARU",
        "lon": "-50.42611",
        "iso": "BR",
        "status": 1,
        "name": "Dario Guarita Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.143612",
        "size": "medium"
    },
    {
        "iata": "AAX",
        "lon": "-46.92917",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-19.568056",
        "size": "small"
    },
    {
        "iata": "BEL",
        "lon": "-48.480003",
        "iso": "BR",
        "status": 1,
        "name": "Val de Cans International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.389865",
        "size": "large"
    },
    {
        "iata": "BGX",
        "lon": "-54.1125",
        "iso": "BR",
        "status": 1,
        "name": "Comandante Gustavo Kraemer Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.398611",
        "size": "medium"
    },
    {
        "iata": "PLU",
        "lon": "-43.75",
        "iso": "BR",
        "status": 1,
        "name": "Pampulha - Carlos Drummond de Andrade Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.75",
        "size": "medium"
    },
    {
        "iata": "QAK",
        "lon": "-43.88333",
        "iso": "BR",
        "status": 1,
        "name": "Major Brigadeiro Doorgal Borges Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.397223",
        "size": "medium"
    },
    {
        "iata": "BSB",
        "lon": "-47.921486",
        "iso": "BR",
        "status": 1,
        "name": "Presidente Juscelino Kubistschek International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.869807",
        "size": "large"
    },
    {
        "iata": "BAT",
        "lon": "-48.55",
        "iso": "BR",
        "status": 1,
        "name": "Chafei Amsei Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.55",
        "size": "medium"
    },
    {
        "iata": "BAU",
        "lon": "-49.0525",
        "iso": "BR",
        "status": 1,
        "name": "Bauru Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.343056",
        "size": "medium"
    },
    {
        "iata": "BVB",
        "lon": "-60.666668",
        "iso": "BR",
        "status": 1,
        "name": "Atlas Brasil Cantanhede Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.833333",
        "size": "medium"
    },
    {
        "iata": "BPG",
        "lon": "-52.38333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-15.85",
        "size": "medium"
    },
    {
        "iata": "BZC",
        "lon": "-41.964443",
        "iso": "BR",
        "status": 1,
        "name": "Umberto Modiano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.769444",
        "size": "medium"
    },
    {
        "iata": "CAC",
        "lon": "-53.466667",
        "iso": "BR",
        "status": 1,
        "name": "Cascavel Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.95",
        "size": "medium"
    },
    {
        "iata": "CFB",
        "lon": "-42.07861",
        "iso": "BR",
        "status": 1,
        "name": "Cabo Frio Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.925",
        "size": "small"
    },
    {
        "iata": "ITB",
        "lon": "-55.983334",
        "iso": "BR",
        "status": 1,
        "name": "Cachimbo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.283333",
        "size": "medium"
    },
    {
        "iata": "CNF",
        "lon": "-43.963215",
        "iso": "BR",
        "status": 1,
        "name": "Tancredo Neves International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.632418",
        "size": "large"
    },
    {
        "iata": "CGR",
        "lon": "-54.668873",
        "iso": "BR",
        "status": 1,
        "name": "Campo Grande Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.456991",
        "size": "medium"
    },
    {
        "iata": "XAP",
        "lon": "-52.629723",
        "iso": "BR",
        "status": 1,
        "name": "Serafin Enoss Bertaso Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.088612",
        "size": "medium"
    },
    {
        "iata": "CLN",
        "lon": "-47.45",
        "iso": "BR",
        "status": 1,
        "name": "Brig. Lysias Augusto Rodrigues Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.333333",
        "size": "medium"
    },
    {
        "iata": "CKS",
        "lon": "-50.001945",
        "iso": "BR",
        "status": 1,
        "name": "Carajas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.114749",
        "size": "medium"
    },
    {
        "iata": "CCM",
        "lon": "-49.36667",
        "iso": "BR",
        "status": 1,
        "name": "Diomicio Freitas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-28.7",
        "size": "medium"
    },
    {
        "iata": "CLV",
        "lon": "-48.63333",
        "iso": "BR",
        "status": 1,
        "name": "Caldas Novas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.75",
        "size": "small"
    },
    {
        "iata": "QNS",
        "lon": "-51.28889",
        "iso": "BR",
        "status": 1,
        "name": "Canoas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.016666",
        "size": "medium"
    },
    {
        "iata": "CAW",
        "lon": "-41.303482",
        "iso": "BR",
        "status": 1,
        "name": "Bartolomeu Lisandro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.70016",
        "size": "medium"
    },
    {
        "iata": "CMG",
        "lon": "-57.672222",
        "iso": "BR",
        "status": 1,
        "name": "Corumbá International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.01111",
        "size": "medium"
    },
    {
        "iata": "CWB",
        "lon": "-49.176544",
        "iso": "BR",
        "status": 1,
        "name": "Afonso Pena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.5322",
        "size": "medium"
    },
    {
        "iata": "CRQ",
        "lon": "-39.25",
        "iso": "BR",
        "status": 1,
        "name": "Caravelas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.75",
        "size": "medium"
    },
    {
        "iata": "CXJ",
        "lon": "-51.183334",
        "iso": "BR",
        "status": 1,
        "name": "Campo dos Bugres Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.166668",
        "size": "medium"
    },
    {
        "iata": "CGB",
        "lon": "-56.120266",
        "iso": "BR",
        "status": 1,
        "name": "Marechal Rondon Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.651725",
        "size": "medium"
    },
    {
        "iata": "CZS",
        "lon": "-72.78333",
        "iso": "BR",
        "status": 1,
        "name": "Cruzeiro do Sul Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.583333",
        "size": "medium"
    },
    {
        "iata": "PPB",
        "lon": "-51.45",
        "iso": "BR",
        "status": 1,
        "name": "Presidente Prudente Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.1",
        "size": "medium"
    },
    {
        "iata": "MAO",
        "lon": "-60.046093",
        "iso": "BR",
        "status": 1,
        "name": "Eduardo Gomes International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.031327",
        "size": "medium"
    },
    {
        "iata": "JCR",
        "lon": "-57.533333",
        "iso": "BR",
        "status": 1,
        "name": "Jacareacanga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.983333",
        "size": "medium"
    },
    {
        "iata": "IGU",
        "lon": "-54.488823",
        "iso": "BR",
        "status": 1,
        "name": "Cataratas International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.597937",
        "size": "medium"
    },
    {
        "iata": "FLN",
        "lon": "-48.5452",
        "iso": "BR",
        "status": 1,
        "name": "Hercílio Luz International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.664446",
        "size": "large"
    },
    {
        "iata": "FEN",
        "lon": "-32.416668",
        "iso": "BR",
        "status": 1,
        "name": "Fernando de Noronha Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.85",
        "size": "medium"
    },
    {
        "iata": "FOR",
        "lon": "-38.540836",
        "iso": "BR",
        "status": 1,
        "name": "Pinto Martins International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.779073",
        "size": "medium"
    },
    {
        "iata": "GIG",
        "lon": "-43.24651",
        "iso": "BR",
        "status": 1,
        "name": "Tom Jobim International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.814653",
        "size": "large"
    },
    {
        "iata": "GJM",
        "lon": "-65.36667",
        "iso": "BR",
        "status": 1,
        "name": "Guajara-Mirim Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.783333",
        "size": "medium"
    },
    {
        "iata": "GYN",
        "lon": "-49.226624",
        "iso": "BR",
        "status": 1,
        "name": "Santa Genoveva Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-16.632631",
        "size": "medium"
    },
    {
        "iata": "GRU",
        "lon": "-46.481926",
        "iso": "BR",
        "status": 1,
        "name": "São Paulo International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.425669",
        "size": "large"
    },
    {
        "iata": "GPB",
        "lon": "-51.5",
        "iso": "BR",
        "status": 1,
        "name": "Tancredo Thomas de Faria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.333332",
        "size": "small"
    },
    {
        "iata": "GVR",
        "lon": "-41.933334",
        "iso": "BR",
        "status": 1,
        "name": "Governador Valadares Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-18.850279",
        "size": "small"
    },
    {
        "iata": "GUJ",
        "lon": "-45.216667",
        "iso": "BR",
        "status": 1,
        "name": "Guaratingueta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.816668",
        "size": "medium"
    },
    {
        "iata": "ATM",
        "lon": "-52.216667",
        "iso": "BR",
        "status": 1,
        "name": "Altamira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.2",
        "size": "medium"
    },
    {
        "iata": "ITA",
        "lon": "-58.416668",
        "iso": "BR",
        "status": 1,
        "name": "Itacoatiara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.133333",
        "size": "medium"
    },
    {
        "iata": "ITB",
        "lon": "-55.983334",
        "iso": "BR",
        "status": 1,
        "name": "Itaituba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.283333",
        "size": "medium"
    },
    {
        "iata": "IOS",
        "lon": "-39.033268",
        "iso": "BR",
        "status": 1,
        "name": "Bahia - Jorge Amado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.815859",
        "size": "medium"
    },
    {
        "iata": "IPN",
        "lon": "-42.533333",
        "iso": "BR",
        "status": 1,
        "name": "Usiminas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.5",
        "size": "medium"
    },
    {
        "iata": "IMP",
        "lon": "-47.480556",
        "iso": "BR",
        "status": 1,
        "name": "Prefeito Renato Moreira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.531944",
        "size": "medium"
    },
    {
        "iata": "JDF",
        "lon": "-43.333332",
        "iso": "BR",
        "status": 1,
        "name": "Francisco de Assis Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.75",
        "size": "medium"
    },
    {
        "iata": "JPA",
        "lon": "-34.94816",
        "iso": "BR",
        "status": 1,
        "name": "Presidente Castro Pinto Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.145357",
        "size": "medium"
    },
    {
        "iata": "JDO",
        "lon": "-39.316666",
        "iso": "BR",
        "status": 1,
        "name": "Orlando Bezerra de Menezes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.2",
        "size": "small"
    },
    {
        "iata": "JOI",
        "lon": "-48.783333",
        "iso": "BR",
        "status": 1,
        "name": "Lauro Carneiro de Loyola Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.233334",
        "size": "medium"
    },
    {
        "iata": "CPV",
        "lon": "-35.9",
        "iso": "BR",
        "status": 1,
        "name": "Campina Grande Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.266667",
        "size": "medium"
    },
    {
        "iata": "VCP",
        "lon": "-47.141674",
        "iso": "BR",
        "status": 1,
        "name": "Viracopos International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.009892",
        "size": "medium"
    },
    {
        "iata": "LEC",
        "lon": "-41.281113",
        "iso": "BR",
        "status": 1,
        "name": "Chapada Diamantina Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.48",
        "size": "small"
    },
    {
        "iata": "LAJ",
        "lon": "-50.316666",
        "iso": "BR",
        "status": 1,
        "name": "Lages Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.8",
        "size": "medium"
    },
    {
        "iata": "LIP",
        "lon": "-49.75",
        "iso": "BR",
        "status": 1,
        "name": "Lins Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.666668",
        "size": "medium"
    },
    {
        "iata": "LDB",
        "lon": "-51.137714",
        "iso": "BR",
        "status": 1,
        "name": "Governador José Richa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.328457",
        "size": "medium"
    },
    {
        "iata": "LAZ",
        "lon": "-43.4125",
        "iso": "BR",
        "status": 1,
        "name": "Bom Jesus da Lapa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.256944",
        "size": "medium"
    },
    {
        "iata": "MAB",
        "lon": "-49.166668",
        "iso": "BR",
        "status": 1,
        "name": "João Correa da Rocha Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.366667",
        "size": "medium"
    },
    {
        "iata": "MQH",
        "lon": "-48.233334",
        "iso": "BR",
        "status": 1,
        "name": "Minaçu Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.533333",
        "size": "medium"
    },
    {
        "iata": "MEU",
        "lon": "-52.583332",
        "iso": "BR",
        "status": 1,
        "name": "Monte Dourado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.883333",
        "size": "medium"
    },
    {
        "iata": "MEA",
        "lon": "-41.76405",
        "iso": "BR",
        "status": 1,
        "name": "Benedito Lacerda Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.341843",
        "size": "medium"
    },
    {
        "iata": "MGF",
        "lon": "-51.933334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-23.383612",
        "size": "medium"
    },
    {
        "iata": "MOC",
        "lon": "-43.817223",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.707779",
        "size": "medium"
    },
    {
        "iata": "MII",
        "lon": "-49.933334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-22.2",
        "size": "medium"
    },
    {
        "iata": "PLL",
        "lon": "-59.984444",
        "iso": "BR",
        "status": 1,
        "name": "Ponta Pelada Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.145556",
        "size": "medium"
    },
    {
        "iata": "MCZ",
        "lon": "-35.800446",
        "iso": "BR",
        "status": 1,
        "name": "Zumbi dos Palmares Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.512521",
        "size": "medium"
    },
    {
        "iata": "MCP",
        "lon": "-51.068382",
        "iso": "BR",
        "status": 1,
        "name": "Alberto Alcolumbre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.049895",
        "size": "medium"
    },
    {
        "iata": "MVF",
        "lon": "-37.25",
        "iso": "BR",
        "status": 1,
        "name": "Dix-Sept Rosado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.166667",
        "size": "medium"
    },
    {
        "iata": "SAO",
        "iso": "BR",
        "status": 1,
        "name": "Campo de Marte Airport",
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "MNX",
        "lon": "-61.283333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-5.816667",
        "size": "medium"
    },
    {
        "iata": "NVT",
        "lon": "-48.63333",
        "iso": "BR",
        "status": 1,
        "name": "Ministro Victor Konder International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.866667",
        "size": "medium"
    },
    {
        "iata": "GEL",
        "lon": "-54.168056",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-28.28111",
        "size": "medium"
    },
    {
        "iata": "NAT",
        "lon": "-35.250244",
        "iso": "BR",
        "status": 1,
        "name": "Augusto Severo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.916787",
        "size": "medium"
    },
    {
        "iata": "OYK",
        "lon": "-51.783333",
        "iso": "BR",
        "status": 1,
        "name": "Oiapoque Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.883333",
        "size": "medium"
    },
    {
        "iata": "POA",
        "lon": "-51.17709",
        "iso": "BR",
        "status": 1,
        "name": "Salgado Filho Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.98961",
        "size": "medium"
    },
    {
        "iata": "PBB",
        "lon": "-51.183334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-19.666668",
        "size": "small"
    },
    {
        "iata": "POO",
        "lon": "-46.5675",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-21.841389",
        "size": "medium"
    },
    {
        "iata": "PFB",
        "lon": "-52.333332",
        "iso": "BR",
        "status": 1,
        "name": "Lauro Kurtz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-28.25",
        "size": "medium"
    },
    {
        "iata": "PMW",
        "lon": "-48.35278",
        "iso": "BR",
        "status": 1,
        "name": "Brigadeiro Lysias Rodrigues Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.241667",
        "size": "medium"
    },
    {
        "iata": "PET",
        "lon": "-52.324444",
        "iso": "BR",
        "status": 1,
        "name": "Pelotas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.718056",
        "size": "medium"
    },
    {
        "iata": "PNZ",
        "lon": "-40.490555",
        "iso": "BR",
        "status": 1,
        "name": "Senador Nilo Coelho Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.393333",
        "size": "medium"
    },
    {
        "iata": "PNB",
        "lon": "-48.416668",
        "iso": "BR",
        "status": 1,
        "name": "Porto Nacional Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.7",
        "size": "medium"
    },
    {
        "iata": "PMG",
        "lon": "-55.7",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-22.55",
        "size": "medium"
    },
    {
        "iata": "BPS",
        "lon": "-39.081097",
        "iso": "BR",
        "status": 1,
        "name": "Porto Seguro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-16.441158",
        "size": "medium"
    },
    {
        "iata": "PVH",
        "lon": "-63.898308",
        "iso": "BR",
        "status": 1,
        "name": "Governador Jorge Teixeira de Oliveira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.714311",
        "size": "medium"
    },
    {
        "iata": "VDC",
        "lon": "-40.86667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-14.883333",
        "size": "medium"
    },
    {
        "iata": "RBR",
        "lon": "-67.8",
        "iso": "BR",
        "status": 1,
        "name": "Plácido de Castro International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.966667",
        "size": "medium"
    },
    {
        "iata": "REC",
        "lon": "-34.91792",
        "iso": "BR",
        "status": 1,
        "name": "Guararapes - Gilberto Freyre International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.131507",
        "size": "medium"
    },
    {
        "iata": "SDU",
        "lon": "-43.167095",
        "iso": "BR",
        "status": 1,
        "name": "Santos Dumont Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.911541",
        "size": "medium"
    },
    {
        "iata": "RAO",
        "lon": "-47.773335",
        "iso": "BR",
        "status": 1,
        "name": "Leite Lopes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.135834",
        "size": "medium"
    },
    {
        "iata": "BRB",
        "lon": "-57.11111",
        "iso": "BR",
        "status": 1,
        "name": "Barreirinhas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.910626",
        "size": "small"
    },
    {
        "iata": "SNZ",
        "lon": "-36.016666",
        "iso": "BR",
        "status": 1,
        "name": "Santa Cruz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.216667",
        "size": "medium"
    },
    {
        "iata": "SJK",
        "lon": "-46.11667",
        "iso": "BR",
        "status": 1,
        "name": "Professor Urbano Ernesto Stumpf Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.183332",
        "size": "medium"
    },
    {
        "iata": "SLZ",
        "lon": "-44.236683",
        "iso": "BR",
        "status": 1,
        "name": "Marechal Cunha Machado International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.583316",
        "size": "medium"
    },
    {
        "iata": "RIA",
        "lon": "-53.6875",
        "iso": "BR",
        "status": 1,
        "name": "Santa Maria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.710556",
        "size": "medium"
    },
    {
        "iata": "STM",
        "lon": "-54.78639",
        "iso": "BR",
        "status": 1,
        "name": "Maestro Wilson Fonseca Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.424886",
        "size": "medium"
    },
    {
        "iata": "CGH",
        "lon": "-46.659557",
        "iso": "BR",
        "status": 1,
        "name": "Congonhas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.626902",
        "size": "large"
    },
    {
        "iata": "SJP",
        "lon": "-49.416668",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-20.816668",
        "size": "medium"
    },
    {
        "iata": "SSZ",
        "lon": "-46.36667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-23.933332",
        "size": "medium"
    },
    {
        "iata": "SSA",
        "lon": "-38.335197",
        "iso": "BR",
        "status": 1,
        "name": "Deputado Luís Eduardo Magalhães International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.913988",
        "size": "large"
    },
    {
        "iata": "QHP",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "TMT",
        "lon": "-55.583332",
        "iso": "BR",
        "status": 1,
        "name": "Trombetas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.916667",
        "size": "medium"
    },
    {
        "iata": "UNA",
        "lon": "-38.997223",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-15.353333",
        "size": "small"
    },
    {
        "iata": "TOW",
        "lon": "-53.69611",
        "iso": "BR",
        "status": 1,
        "name": "Toledo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.69861",
        "size": "small"
    },
    {
        "iata": "THE",
        "lon": "-42.821087",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-5.06335",
        "size": "medium"
    },
    {
        "iata": "TFF",
        "lon": "-64.7",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-3.366667",
        "size": "medium"
    },
    {
        "iata": "TRQ",
        "lon": "-70.75",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-8.1",
        "size": "medium"
    },
    {
        "iata": "TEC",
        "lon": "-50.65167",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-24.315832",
        "size": "medium"
    },
    {
        "iata": "OBI",
        "lon": "-55.516666",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-1.916667",
        "size": "medium"
    },
    {
        "iata": "TBT",
        "lon": "-69.933334",
        "iso": "BR",
        "status": 1,
        "name": "Tabatinga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.233333",
        "size": "medium"
    },
    {
        "iata": "TUR",
        "lon": "-49.733334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-3.7",
        "size": "medium"
    },
    {
        "iata": "SJL",
        "lon": "-66.98556",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-0.148333",
        "size": "medium"
    },
    {
        "iata": "PAV",
        "lon": "-38.249916",
        "iso": "BR",
        "status": 1,
        "name": "Paulo Afonso Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.40077",
        "size": "medium"
    },
    {
        "iata": "URG",
        "lon": "-57.038334",
        "iso": "BR",
        "status": 1,
        "name": "Rubem Berta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.781668",
        "size": "medium"
    },
    {
        "iata": "UDI",
        "lon": "-48.225822",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.884567",
        "size": "medium"
    },
    {
        "iata": "UBA",
        "lon": "-47.958332",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-19.776388",
        "size": "medium"
    },
    {
        "iata": "VAG",
        "lon": "-45.47395",
        "iso": "BR",
        "status": 1,
        "name": "Major Brigadeiro Trompowsky Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.588886",
        "size": "medium"
    },
    {
        "iata": "BVH",
        "lon": "-60.11667",
        "iso": "BR",
        "status": 1,
        "name": "Vilhena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.716667",
        "size": "medium"
    },
    {
        "iata": "VIX",
        "lon": "-40.289566",
        "iso": "BR",
        "status": 1,
        "name": "Eurico de Aguiar Salles Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.25681",
        "size": "medium"
    },
    {
        "iata": "QPS",
        "iso": "BR",
        "status": 1,
        "name": "Campo Fontenelle Airport",
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "PRI",
        "lon": "55.692314",
        "iso": "SC",
        "status": 1,
        "name": "Praslin Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.323883",
        "size": "small"
    },
    {
        "iata": "PRI",
        "lon": "55.692314",
        "iso": "SC",
        "status": 1,
        "name": "Praslin Island Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-4.323883",
        "size": "small"
    },
    {
        "iata": "ZUD",
        "lon": "-73.833336",
        "iso": "CL",
        "status": 1,
        "name": "Pupelde Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-42.0",
        "size": "small"
    },
    {
        "iata": "LOB",
        "lon": "-71.53333",
        "iso": "CL",
        "status": 1,
        "name": "San Rafael Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.966667",
        "size": "small"
    },
    {
        "iata": "WAP",
        "lon": "-71.8",
        "iso": "CL",
        "status": 1,
        "name": "Alto Palena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-43.61667",
        "size": "small"
    },
    {
        "iata": "ARI",
        "lon": "-70.335556",
        "iso": "CL",
        "status": 1,
        "name": "Chacalluta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-18.349766",
        "size": "medium"
    },
    {
        "iata": "WPA",
        "lon": "-72.7",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-45.4",
        "size": "small"
    },
    {
        "iata": "DAT",
        "lon": "113.48136",
        "iso": "CL",
        "status": 1,
        "name": "Desierto de Atacama Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "40.05554",
        "size": "medium"
    },
    {
        "iata": "BBA",
        "lon": "-71.695",
        "iso": "CL",
        "status": 1,
        "name": "Balmaceda Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-45.916668",
        "size": "medium"
    },
    {
        "iata": "TOQ",
        "lon": "-70.061386",
        "iso": "CL",
        "status": 1,
        "name": "Barriles Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.13611",
        "size": "medium"
    },
    {
        "iata": "CCH",
        "lon": "-71.7",
        "iso": "CL",
        "status": 1,
        "name": "Chile Chico Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-46.55",
        "size": "medium"
    },
    {
        "iata": "CJC",
        "lon": "-68.90843",
        "iso": "CL",
        "status": 1,
        "name": "El Loa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.495085",
        "size": "medium"
    },
    {
        "iata": "YAI",
        "lon": "-72.11667",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-36.6",
        "size": "medium"
    },
    {
        "iata": "PUQ",
        "lon": "-70.84307",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-53.005356",
        "size": "medium"
    },
    {
        "iata": "GXQ",
        "lon": "-72.09861",
        "iso": "CL",
        "status": 1,
        "name": "Teniente Vidal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-45.58889",
        "size": "medium"
    },
    {
        "iata": "IQQ",
        "lon": "-70.17848",
        "iso": "CL",
        "status": 1,
        "name": "Diego Aracena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.547937",
        "size": "medium"
    },
    {
        "iata": "SCL",
        "lon": "-70.79382",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-33.397175",
        "size": "large"
    },
    {
        "iata": "ESR",
        "lon": "-69.76528",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-26.315277",
        "size": "medium"
    },
    {
        "iata": "FRT",
        "lon": "-73.06528",
        "iso": "CL",
        "status": 1,
        "name": "El Avellano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-41.125",
        "size": "small"
    },
    {
        "iata": "ANF",
        "lon": "-70.44079",
        "iso": "CL",
        "status": 1,
        "name": "Cerro Moreno Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.449",
        "size": "medium"
    },
    {
        "iata": "WPR",
        "lon": "-70.333336",
        "iso": "CL",
        "status": 1,
        "name": "Capitan Fuentes Martinez Airport Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-53.25",
        "size": "medium"
    },
    {
        "iata": "FFU",
        "lon": "-71.816666",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-43.2",
        "size": "small"
    },
    {
        "iata": "LSQ",
        "lon": "-72.422775",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-37.3975",
        "size": "small"
    },
    {
        "iata": "WPU",
        "lon": "-68.25",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-54.95",
        "size": "medium"
    },
    {
        "iata": "CPO",
        "lon": "-70.414406",
        "iso": "CL",
        "status": 1,
        "name": "Chamonate Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.29892",
        "size": "small"
    },
    {
        "iata": "LGR",
        "lon": "-72.0",
        "iso": "CL",
        "status": 1,
        "name": "Cochrane Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-47.166668",
        "size": "medium"
    },
    {
        "iata": "CCP",
        "lon": "-73.05944",
        "iso": "CL",
        "status": 1,
        "name": "Carriel Sur Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-36.777122",
        "size": "medium"
    },
    {
        "iata": "IPC",
        "lon": "-109.36667",
        "iso": "CL",
        "status": 1,
        "name": "Mataveri Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.116667",
        "size": "medium"
    },
    {
        "iata": "ZOS",
        "lon": "-73.05556",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-40.607777",
        "size": "medium"
    },
    {
        "iata": "VLR",
        "lon": "-70.761665",
        "iso": "CL",
        "status": 1,
        "name": "Vallenar Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-28.591667",
        "size": "medium"
    },
    {
        "iata": "ZLR",
        "lon": "-71.68611",
        "iso": "CL",
        "status": 1,
        "name": "Municipal de Linares Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-35.966667",
        "size": "small"
    },
    {
        "iata": "PNT",
        "lon": "-72.51667",
        "iso": "CL",
        "status": 1,
        "name": "Tte. Julio Gallardo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-51.683334",
        "size": "medium"
    },
    {
        "iata": "OVL",
        "lon": "-71.0",
        "iso": "CL",
        "status": 1,
        "name": "El Tuqui Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.566668",
        "size": "small"
    },
    {
        "iata": "ZPC",
        "lon": "-71.63333",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-39.76389",
        "size": "small"
    },
    {
        "iata": "PUX",
        "lon": "-72.94444",
        "iso": "CL",
        "status": 1,
        "name": "El Mirador Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-40.34028",
        "size": "small"
    },
    {
        "iata": "CNR",
        "lon": "-70.604164",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-26.329166",
        "size": "medium"
    },
    {
        "iata": "VAP",
        "lon": "-71.666664",
        "iso": "CL",
        "status": 1,
        "name": "Rodelillo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.033333",
        "size": "small"
    },
    {
        "iata": "QRC",
        "lon": "-70.85555",
        "iso": "CL",
        "status": 1,
        "name": "De La Independencia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.244446",
        "size": "medium"
    },
    {
        "iata": "SMB",
        "lon": "-69.34278",
        "iso": "CL",
        "status": 1,
        "name": "Franco Bianco Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-52.738335",
        "size": "small"
    },
    {
        "iata": "LSC",
        "lon": "-71.20369",
        "iso": "CL",
        "status": 1,
        "name": "La Florida Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.917297",
        "size": "medium"
    },
    {
        "iata": "SSD",
        "lon": "-67.1",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "1.916667",
        "size": "small"
    },
    {
        "iata": "WCA",
        "lon": "-73.833336",
        "iso": "CL",
        "status": 1,
        "name": "Gamboa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-42.5",
        "size": "small"
    },
    {
        "iata": "ZCO",
        "lon": "-72.63845",
        "iso": "CL",
        "status": 1,
        "name": "Maquehue Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.770355",
        "size": "medium"
    },
    {
        "iata": "PMC",
        "lon": "-73.09831",
        "iso": "CL",
        "status": 1,
        "name": "El Tepual Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-41.433727",
        "size": "medium"
    },
    {
        "iata": "ULC",
        "iso": "CL",
        "status": 0,
        "name": "Los Cerrillos Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "TLX",
        "lon": "-71.666664",
        "iso": "CL",
        "status": 1,
        "name": "Panguilemo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-35.466667",
        "size": "medium"
    },
    {
        "iata": "WCH",
        "lon": "-72.71667",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-42.916668",
        "size": "medium"
    },
    {
        "iata": "ZIC",
        "lon": "-72.333336",
        "iso": "CL",
        "status": 1,
        "name": "Victoria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-38.216667",
        "size": "medium"
    },
    {
        "iata": "TTC",
        "lon": "-70.42222",
        "iso": "CL",
        "status": 1,
        "name": "Las Breas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.517778",
        "size": "medium"
    },
    {
        "iata": "ZAL",
        "lon": "-73.23944",
        "iso": "CL",
        "status": 1,
        "name": "Pichoy Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-39.791668",
        "size": "medium"
    },
    {
        "iata": "KNA",
        "lon": "-71.566666",
        "iso": "CL",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-33.033333",
        "size": "medium"
    },
    {
        "iata": "CPQ",
        "lon": "-47.111595",
        "iso": "BR",
        "status": 1,
        "name": "Amarais Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.856462",
        "size": "small"
    },
    {
        "iata": "QCJ",
        "lon": "-48.475",
        "iso": "BR",
        "status": 1,
        "name": "Botucatu Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.966667",
        "size": "small"
    },
    {
        "iata": "OLC",
        "lon": "-68.95853",
        "iso": "BR",
        "status": 1,
        "name": "Senadora Eunice Micheles Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.470869",
        "size": "small"
    },
    {
        "iata": "SOD",
        "lon": "-47.45",
        "iso": "BR",
        "status": 1,
        "name": "Sorocaba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.483334",
        "size": "small"
    },
    {
        "iata": "QDC",
        "lon": "-51.666668",
        "iso": "BR",
        "status": 1,
        "name": "Dracena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.561111",
        "size": "small"
    },
    {
        "iata": "JLS",
        "lon": "-50.55",
        "iso": "BR",
        "status": 1,
        "name": "Jales Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.166668",
        "size": "small"
    },
    {
        "iata": "QOA",
        "lon": "-47.044445",
        "iso": "BR",
        "status": 1,
        "name": "Mococa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.527779",
        "size": "small"
    },
    {
        "iata": "QGC",
        "lon": "-48.85278",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-22.672222",
        "size": "small"
    },
    {
        "iata": "QNV",
        "lon": "-43.59722",
        "iso": "BR",
        "status": 1,
        "name": "Aeroclube Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.866667",
        "size": "small"
    },
    {
        "iata": "OUS",
        "lon": "-49.916668",
        "iso": "BR",
        "status": 1,
        "name": "Ourinhos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.966667",
        "size": "small"
    },
    {
        "iata": "QHB",
        "lon": "-47.644444",
        "iso": "BR",
        "status": 1,
        "name": "Piracicaba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.805555",
        "size": "small"
    },
    {
        "iata": "QIQ",
        "lon": "-47.691666",
        "iso": "BR",
        "status": 1,
        "name": "Rio Claro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.561111",
        "size": "small"
    },
    {
        "iata": "QVP",
        "lon": "-48.99167",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-23.180555",
        "size": "small"
    },
    {
        "iata": "QRZ",
        "lon": "-44.61667",
        "iso": "BR",
        "status": 1,
        "name": "Resende Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.586111",
        "size": "small"
    },
    {
        "iata": "QSC",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "UBT",
        "lon": "-45.083332",
        "iso": "BR",
        "status": 1,
        "name": "Ubatuba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.433332",
        "size": "small"
    },
    {
        "iata": "QGS",
        "iso": "BR",
        "status": 1,
        "name": "Alagoinhas Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "VOT",
        "lon": "-49.88333",
        "iso": "BR",
        "status": 1,
        "name": "Votuporanga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.433332",
        "size": "small"
    },
    {
        "iata": "QGB",
        "iso": "BR",
        "status": 1,
        "name": "Limeira Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "IZA",
        "lon": "-43.173058",
        "iso": "BR",
        "status": 1,
        "name": "Zona da Mata Regional Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.513056",
        "size": "medium"
    },
    {
        "iata": "ATF",
        "lon": "-78.7",
        "iso": "EC",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-1.083333",
        "size": "medium"
    },
    {
        "iata": "OCC",
        "lon": "-76.98596",
        "iso": "EC",
        "status": 1,
        "name": "Francisco De Orellana Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.462171",
        "size": "medium"
    },
    {
        "iata": "CUE",
        "lon": "-78.98689",
        "iso": "EC",
        "status": 1,
        "name": "Mariscal Lamar Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.889343",
        "size": "medium"
    },
    {
        "iata": "GPS",
        "lon": "-90.282776",
        "iso": "EC",
        "status": 1,
        "name": "Seymour Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.434722",
        "size": "small"
    },
    {
        "iata": "GYE",
        "lon": "-79.88032",
        "iso": "EC",
        "status": 1,
        "name": "Simon Bolivar International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.142654",
        "size": "medium"
    },
    {
        "iata": "IBB",
        "iso": "EC",
        "status": 1,
        "name": "General Villamil Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "JIP",
        "lon": "-80.666664",
        "iso": "EC",
        "status": 1,
        "name": "Jipijapa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.0",
        "size": "small"
    },
    {
        "iata": "LTX",
        "lon": "-78.61609",
        "iso": "EC",
        "status": 1,
        "name": "Cotopaxi International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.918468",
        "size": "medium"
    },
    {
        "iata": "MRR",
        "lon": "-79.933334",
        "iso": "EC",
        "status": 1,
        "name": "Jose Maria Velasco Ibarra Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.383333",
        "size": "medium"
    },
    {
        "iata": "XMS",
        "lon": "-78.13333",
        "iso": "EC",
        "status": 1,
        "name": "Coronel E Carvajal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.316667",
        "size": "medium"
    },
    {
        "iata": "MCH",
        "lon": "-79.98333",
        "iso": "EC",
        "status": 1,
        "name": "General Manuel Serrano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.266667",
        "size": "medium"
    },
    {
        "iata": "MEC",
        "lon": "-80.684",
        "iso": "EC",
        "status": 1,
        "name": "Eloy Alfaro International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.953035",
        "size": "medium"
    },
    {
        "iata": "LGQ",
        "lon": "-76.88333",
        "iso": "EC",
        "status": 1,
        "name": "Nueva Loja Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.1",
        "size": "small"
    },
    {
        "iata": "PYO",
        "lon": "-75.9",
        "iso": "EC",
        "status": 1,
        "name": "Putumayo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.083333",
        "size": "small"
    },
    {
        "iata": "PVO",
        "lon": "-80.46667",
        "iso": "EC",
        "status": 1,
        "name": "Reales Tamarindos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.033333",
        "size": "medium"
    },
    {
        "iata": "UIO",
        "lon": "-78.35597",
        "iso": "EC",
        "status": 1,
        "name": "Mariscal Sucre International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.123811",
        "size": "medium"
    },
    {
        "iata": "ETR",
        "lon": "-79.96167",
        "iso": "EC",
        "status": 1,
        "name": "Coronel Artilleria Victor Larrea Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.452222",
        "size": "small"
    },
    {
        "iata": "SNC",
        "lon": "-80.98333",
        "iso": "EC",
        "status": 1,
        "name": "General Ulpiano Paez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.2",
        "size": "medium"
    },
    {
        "iata": "SUQ",
        "lon": "-78.166664",
        "iso": "EC",
        "status": 1,
        "name": "Sucua Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.466667",
        "size": "small"
    },
    {
        "iata": "PTZ",
        "lon": "-77.0",
        "iso": "EC",
        "status": 1,
        "name": "Rio Amazonas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.0",
        "size": "small"
    },
    {
        "iata": "SCY",
        "lon": "-89.433334",
        "iso": "EC",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-0.833333",
        "size": "small"
    },
    {
        "iata": "BHA",
        "lon": "-80.416664",
        "iso": "EC",
        "status": 1,
        "name": "Los Perales Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.6",
        "size": "small"
    },
    {
        "iata": "TSC",
        "lon": "-77.5",
        "iso": "EC",
        "status": 1,
        "name": "Taisha Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.383333",
        "size": "small"
    },
    {
        "iata": "TPN",
        "lon": "-75.53333",
        "iso": "EC",
        "status": 1,
        "name": "Tiputini Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.766667",
        "size": "small"
    },
    {
        "iata": "LOH",
        "lon": "-79.37361",
        "iso": "EC",
        "status": 1,
        "name": "Camilo Ponce Enriquez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.993611",
        "size": "small"
    },
    {
        "iata": "ESM",
        "lon": "-79.625",
        "iso": "EC",
        "status": 1,
        "name": "General Rivadeneira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.966667",
        "size": "small"
    },
    {
        "iata": "TPC",
        "lon": "-76.78333",
        "iso": "EC",
        "status": 1,
        "name": "Tarapoa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.416667",
        "size": "medium"
    },
    {
        "iata": "TUA",
        "lon": "-77.71667",
        "iso": "EC",
        "status": 1,
        "name": "Teniente Coronel Luis a Mantilla Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.8",
        "size": "medium"
    },
    {
        "iata": "PSY",
        "lon": "-57.85",
        "iso": "FK",
        "status": 1,
        "name": "Stanley Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-51.7",
        "size": "medium"
    },
    {
        "iata": "ASU",
        "lon": "-57.513794",
        "iso": "PY",
        "status": 1,
        "name": "Silvio Pettirossi International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.241795",
        "size": "medium"
    },
    {
        "iata": "AYO",
        "lon": "-56.85",
        "iso": "PY",
        "status": 1,
        "name": "Juan De Ayolas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.366667",
        "size": "medium"
    },
    {
        "iata": "CIO",
        "lon": "-57.41833",
        "iso": "PY",
        "status": 1,
        "name": "Teniente Col Carmelo Peralta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.434444",
        "size": "small"
    },
    {
        "iata": "ENO",
        "lon": "-55.833332",
        "iso": "PY",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-27.25",
        "size": "small"
    },
    {
        "iata": "AGT",
        "lon": "-54.833332",
        "iso": "PY",
        "status": 1,
        "name": "Guarani International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.5",
        "size": "medium"
    },
    {
        "iata": "FLM",
        "lon": "-60.033333",
        "iso": "PY",
        "status": 1,
        "name": "Filadelfia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.35",
        "size": "small"
    },
    {
        "iata": "ESG",
        "lon": "-60.61667",
        "iso": "PY",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-22.033333",
        "size": "medium"
    },
    {
        "iata": "PIL",
        "lon": "-58.333332",
        "iso": "PY",
        "status": 1,
        "name": "Carlos Miguel Gimenez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.866667",
        "size": "medium"
    },
    {
        "iata": "PJC",
        "lon": "-55.666668",
        "iso": "PY",
        "status": 1,
        "name": "Dr Augusto Roberto Fuster International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.5",
        "size": "small"
    },
    {
        "iata": "LVR",
        "iso": "BR",
        "status": 1,
        "name": "Municipal Bom Futuro Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "FRC",
        "lon": "-47.4375",
        "iso": "BR",
        "status": 1,
        "name": "Franca Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.551945",
        "size": "small"
    },
    {
        "iata": "CFO",
        "lon": "-51.566666",
        "iso": "BR",
        "status": 1,
        "name": "Confresa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.633333",
        "size": "small"
    },
    {
        "iata": "JTC",
        "lon": "-49.07229",
        "iso": "BR",
        "status": 1,
        "name": "Bauru - Arealva Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.165958",
        "size": "medium"
    },
    {
        "iata": "ARS",
        "lon": "-52.233334",
        "iso": "BR",
        "status": 1,
        "name": "Usina Santa Cruz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.9",
        "size": "small"
    },
    {
        "iata": "ACM",
        "lon": "-71.833336",
        "iso": "CO",
        "status": 1,
        "name": "Arica Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.0",
        "size": "small"
    },
    {
        "iata": "ACL",
        "lon": "-73.0",
        "iso": "CO",
        "status": 1,
        "name": "Aguaclara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.75",
        "size": "small"
    },
    {
        "iata": "NBB",
        "lon": "-70.333336",
        "iso": "CO",
        "status": 1,
        "name": "Barranco Minas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.333333",
        "size": "small"
    },
    {
        "iata": "AZT",
        "lon": "-73.25",
        "iso": "CO",
        "status": 1,
        "name": "Zapatoca Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.866667",
        "size": "small"
    },
    {
        "iata": "SQB",
        "lon": "-74.583336",
        "iso": "CO",
        "status": 1,
        "name": "Santa Ana Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.316667",
        "size": "small"
    },
    {
        "iata": "ARF",
        "lon": "-70.13333",
        "iso": "CO",
        "status": 1,
        "name": "Acaricuara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.533333",
        "size": "small"
    },
    {
        "iata": "ACR",
        "lon": "-72.3",
        "iso": "CO",
        "status": 1,
        "name": "Araracuara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.383333",
        "size": "small"
    },
    {
        "iata": "ACD",
        "lon": "-77.3",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "8.516667",
        "size": "small"
    },
    {
        "iata": "AFI",
        "lon": "-75.066666",
        "iso": "CO",
        "status": 1,
        "name": "Amalfi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.916667",
        "size": "small"
    },
    {
        "iata": "ADN",
        "lon": "-75.933334",
        "iso": "CO",
        "status": 1,
        "name": "Andes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.666667",
        "size": "small"
    },
    {
        "iata": "APY",
        "lon": "-45.933334",
        "iso": "CO",
        "status": 1,
        "name": "Gomez Nino Apiay Air Base",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.133333",
        "size": "medium"
    },
    {
        "iata": "AXM",
        "lon": "-75.76804",
        "iso": "CO",
        "status": 1,
        "name": "El Eden Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.452869",
        "size": "medium"
    },
    {
        "iata": "PUU",
        "lon": "-76.53333",
        "iso": "CO",
        "status": 1,
        "name": "Tres De Mayo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.533333",
        "size": "medium"
    },
    {
        "iata": "ELB",
        "lon": "-73.97417",
        "iso": "CO",
        "status": 1,
        "name": "Las Flores Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.048889",
        "size": "medium"
    },
    {
        "iata": "BGA",
        "lon": "-73.1814",
        "iso": "CO",
        "status": 1,
        "name": "Palonegro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.128045",
        "size": "medium"
    },
    {
        "iata": "BOG",
        "lon": "-74.143135",
        "iso": "CO",
        "status": 1,
        "name": "El Dorado International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.698602",
        "size": "large"
    },
    {
        "iata": "BAQ",
        "lon": "-74.77618",
        "iso": "CO",
        "status": 1,
        "name": "Ernesto Cortissoz International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.886398",
        "size": "medium"
    },
    {
        "iata": "BSC",
        "lon": "-77.4",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "6.183333",
        "size": "medium"
    },
    {
        "iata": "BUN",
        "lon": "-76.995834",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "3.825",
        "size": "medium"
    },
    {
        "iata": "CPB",
        "lon": "-77.333336",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "8.633333",
        "size": "small"
    },
    {
        "iata": "CUC",
        "lon": "-72.50815",
        "iso": "CO",
        "status": 1,
        "name": "Camilo Daza International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.927108",
        "size": "medium"
    },
    {
        "iata": "COG",
        "lon": "-76.66333",
        "iso": "CO",
        "status": 1,
        "name": "Mandinga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.079167",
        "size": "small"
    },
    {
        "iata": "CTG",
        "lon": "-75.51659",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "10.445704",
        "size": "medium"
    },
    {
        "iata": "CCO",
        "lon": "-71.333336",
        "iso": "CO",
        "status": 1,
        "name": "Carimagua Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.566667",
        "size": "small"
    },
    {
        "iata": "CLO",
        "lon": "-76.381386",
        "iso": "CO",
        "status": 1,
        "name": "Alfonso Bonilla Aragon International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.543056",
        "size": "medium"
    },
    {
        "iata": "CIM",
        "lon": "-74.11667",
        "iso": "CO",
        "status": 1,
        "name": "Cimitarra Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.483333",
        "size": "small"
    },
    {
        "iata": "RAV",
        "lon": "-70.183334",
        "iso": "CO",
        "status": 1,
        "name": "Cravo Norte Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.316667",
        "size": "small"
    },
    {
        "iata": "TCO",
        "lon": "-78.76667",
        "iso": "CO",
        "status": 1,
        "name": "La Florida Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "1.816667",
        "size": "medium"
    },
    {
        "iata": "CUO",
        "lon": "-71.23333",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "1.033333",
        "size": "small"
    },
    {
        "iata": "CAQ",
        "lon": "-75.19718",
        "iso": "CO",
        "status": 1,
        "name": "Juan H White Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.967905",
        "size": "medium"
    },
    {
        "iata": "CVE",
        "lon": "-75.73333",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "9.4",
        "size": "medium"
    },
    {
        "iata": "CZU",
        "lon": "-75.282776",
        "iso": "CO",
        "status": 1,
        "name": "Las Brujas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.3375",
        "size": "medium"
    },
    {
        "iata": "EBG",
        "lon": "-74.816666",
        "iso": "CO",
        "status": 1,
        "name": "El Bagre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.616667",
        "size": "medium"
    },
    {
        "iata": "EJA",
        "lon": "-73.799164",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "7.015833",
        "size": "medium"
    },
    {
        "iata": "FLA",
        "lon": "-75.55889",
        "iso": "CO",
        "status": 1,
        "name": "Gustavo Artunduaga Paredes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "1.588889",
        "size": "medium"
    },
    {
        "iata": "FDA",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "GIR",
        "lon": "-74.8",
        "iso": "CO",
        "status": 1,
        "name": "Santiago Vila Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.3",
        "size": "medium"
    },
    {
        "iata": "CRC",
        "lon": "-75.933334",
        "iso": "CO",
        "status": 1,
        "name": "Santa Ana Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.766667",
        "size": "medium"
    },
    {
        "iata": "GPI",
        "lon": "-78.34167",
        "iso": "CO",
        "status": 1,
        "name": "Juan Casiano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.5",
        "size": "medium"
    },
    {
        "iata": "GLJ",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "CPL",
        "lon": "-75.46667",
        "iso": "CO",
        "status": 1,
        "name": "Chaparral Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.716667",
        "size": "small"
    },
    {
        "iata": "HTZ",
        "lon": "-72.63333",
        "iso": "CO",
        "status": 1,
        "name": "Hato Corozal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.033333",
        "size": "small"
    },
    {
        "iata": "IBE",
        "lon": "-75.13858",
        "iso": "CO",
        "status": 1,
        "name": "Perales Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.423948",
        "size": "medium"
    },
    {
        "iata": "IGO",
        "lon": "-76.68667",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "7.681667",
        "size": "small"
    },
    {
        "iata": "MMP",
        "lon": "-74.43611",
        "iso": "CO",
        "status": 1,
        "name": "Cicuco Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.261667",
        "size": "small"
    },
    {
        "iata": "IPI",
        "lon": "-77.71167",
        "iso": "CO",
        "status": 1,
        "name": "San Luis Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "0.866389",
        "size": "medium"
    },
    {
        "iata": "LQM",
        "lon": "-74.583336",
        "iso": "CO",
        "status": 1,
        "name": "Caucaya Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.3",
        "size": "small"
    },
    {
        "iata": "MCJ",
        "lon": "-72.24167",
        "iso": "CO",
        "status": 1,
        "name": "Jorge Isaac Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.391667",
        "size": "medium"
    },
    {
        "iata": "LPD",
        "lon": "-69.71667",
        "iso": "CO",
        "status": 1,
        "name": "La Pedrera Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.3",
        "size": "small"
    },
    {
        "iata": "LET",
        "lon": "-69.939445",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-4.196389",
        "size": "medium"
    },
    {
        "iata": "EOH",
        "lon": "-75.5864",
        "iso": "CO",
        "status": 1,
        "name": "Enrique Olaya Herrera Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.218666",
        "size": "medium"
    },
    {
        "iata": "MFS",
        "lon": "-73.25",
        "iso": "CO",
        "status": 1,
        "name": "Miraflores Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.166667",
        "size": "small"
    },
    {
        "iata": "MGN",
        "lon": "-74.845",
        "iso": "CO",
        "status": 1,
        "name": "Baracoa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.288056",
        "size": "medium"
    },
    {
        "iata": "MCJ",
        "lon": "-72.24167",
        "iso": "CO",
        "status": 1,
        "name": "Maicao Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.391667",
        "size": "small"
    },
    {
        "iata": "MTB",
        "lon": "-75.46667",
        "iso": "CO",
        "status": 1,
        "name": "Montelibano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.066667",
        "size": "small"
    },
    {
        "iata": "MTR",
        "lon": "-75.82392",
        "iso": "CO",
        "status": 1,
        "name": "Los Garzones Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.825035",
        "size": "medium"
    },
    {
        "iata": "MVP",
        "lon": "-70.05",
        "iso": "CO",
        "status": 1,
        "name": "Fabio Alberto Leon Bentley Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "1.133333",
        "size": "medium"
    },
    {
        "iata": "MZL",
        "lon": "-75.46878",
        "iso": "CO",
        "status": 1,
        "name": "La Nubia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.029005",
        "size": "medium"
    },
    {
        "iata": "NCI",
        "lon": "-76.75",
        "iso": "CO",
        "status": 1,
        "name": "Necocli Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.483333",
        "size": "small"
    },
    {
        "iata": "NQU",
        "lon": "-77.28333",
        "iso": "CO",
        "status": 1,
        "name": "Reyes Murillo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.7",
        "size": "small"
    },
    {
        "iata": "NVA",
        "lon": "-75.295525",
        "iso": "CO",
        "status": 1,
        "name": "Benito Salas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.946824",
        "size": "medium"
    },
    {
        "iata": "OCV",
        "lon": "-73.333336",
        "iso": "CO",
        "status": 1,
        "name": "Aguas Claras Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.25",
        "size": "medium"
    },
    {
        "iata": "ORC",
        "lon": "-71.333336",
        "iso": "CO",
        "status": 1,
        "name": "Orocue Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.9",
        "size": "small"
    },
    {
        "iata": "PCR",
        "lon": "-67.63333",
        "iso": "CO",
        "status": 1,
        "name": "German Olano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.183333",
        "size": "medium"
    },
    {
        "iata": "PDA",
        "lon": "-67.88333",
        "iso": "CO",
        "status": 1,
        "name": "Obando Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.883333",
        "size": "medium"
    },
    {
        "iata": "PEI",
        "lon": "-75.736534",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "4.815945",
        "size": "medium"
    },
    {
        "iata": "PTX",
        "lon": "-76.03333",
        "iso": "CO",
        "status": 1,
        "name": "Pitalito Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "1.85",
        "size": "medium"
    },
    {
        "iata": "PLT",
        "lon": "-74.78333",
        "iso": "CO",
        "status": 1,
        "name": "Plato Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.783333",
        "size": "small"
    },
    {
        "iata": "NAR",
        "lon": "-74.583336",
        "iso": "CO",
        "status": 1,
        "name": "Puerto Nare Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.2",
        "size": "small"
    },
    {
        "iata": "PPN",
        "lon": "-76.60889",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "2.45",
        "size": "medium"
    },
    {
        "iata": "PQE",
        "iso": "CO",
        "status": 1,
        "name": "German Olano Air Base",
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "PBE",
        "lon": "-74.48333",
        "iso": "CO",
        "status": 1,
        "name": "Puerto Berrio Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.483333",
        "size": "small"
    },
    {
        "iata": "PSO",
        "lon": "-77.25",
        "iso": "CO",
        "status": 1,
        "name": "Antonio Narino Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "1.466667",
        "size": "medium"
    },
    {
        "iata": "PVA",
        "lon": "-81.35",
        "iso": "CO",
        "status": 1,
        "name": "El Embrujo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "13.35",
        "size": "medium"
    },
    {
        "iata": "PZA",
        "lon": "-71.885",
        "iso": "CO",
        "status": 1,
        "name": "Paz De Ariporo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.877778",
        "size": "medium"
    },
    {
        "iata": "MQU",
        "lon": "-74.88333",
        "iso": "CO",
        "status": 1,
        "name": "Jose Celestino Mutis Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.216667",
        "size": "medium"
    },
    {
        "iata": "MDE",
        "lon": "-75.42821",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "6.171382",
        "size": "medium"
    },
    {
        "iata": "RCH",
        "lon": "-72.924446",
        "iso": "CO",
        "status": 1,
        "name": "Almirante Padilla Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.529167",
        "size": "medium"
    },
    {
        "iata": "SJE",
        "lon": "-72.666664",
        "iso": "CO",
        "status": 1,
        "name": "Jorge E. Gonzalez Torres Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.583333",
        "size": "medium"
    },
    {
        "iata": "SMR",
        "lon": "-74.232704",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "11.117132",
        "size": "medium"
    },
    {
        "iata": "SOX",
        "lon": "-72.933334",
        "iso": "CO",
        "status": 1,
        "name": "Alberto Lleras Camargo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.716667",
        "size": "small"
    },
    {
        "iata": "ADZ",
        "lon": "-81.70221",
        "iso": "CO",
        "status": 1,
        "name": "Gustavo Rojas Pinilla International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "12.586047",
        "size": "medium"
    },
    {
        "iata": "SVI",
        "lon": "-74.76667",
        "iso": "CO",
        "status": 1,
        "name": "Eduardo Falla Solano Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.15",
        "size": "medium"
    },
    {
        "iata": "TBU",
        "lon": "-175.14131",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-21.24117",
        "size": "small"
    },
    {
        "iata": "TDA",
        "lon": "-71.72444",
        "iso": "CO",
        "status": 1,
        "name": "Trinidad Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.423611",
        "size": "small"
    },
    {
        "iata": "TLU",
        "lon": "-75.583336",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "9.516667",
        "size": "small"
    },
    {
        "iata": "TME",
        "lon": "-71.76667",
        "iso": "CO",
        "status": 1,
        "name": "Gustavo Vargas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.5",
        "size": "medium"
    },
    {
        "iata": "TQS",
        "lon": "-75.23333",
        "iso": "CO",
        "status": 1,
        "name": "Tres Esquinas Air Base",
        "continent": "SA",
        "type": "airport",
        "lat": "0.733333",
        "size": "medium"
    },
    {
        "iata": "TRB",
        "lon": "-76.748055",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "8.078333",
        "size": "small"
    },
    {
        "iata": "MQZ",
        "lon": "115.1",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-33.933334",
        "size": "medium"
    },
    {
        "iata": "AUC",
        "lon": "-70.7425",
        "iso": "CO",
        "status": 1,
        "name": "Santiago Perez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.071667",
        "size": "medium"
    },
    {
        "iata": "UIB",
        "lon": "-76.641945",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "5.690556",
        "size": "medium"
    },
    {
        "iata": "ULQ",
        "lon": "-76.229164",
        "iso": "CO",
        "status": 1,
        "name": "Farfan Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.097222",
        "size": "medium"
    },
    {
        "iata": "URR",
        "lon": "-76.183334",
        "iso": "CO",
        "status": 1,
        "name": "Urrao Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.333333",
        "size": "small"
    },
    {
        "iata": "VUP",
        "lon": "-73.25278",
        "iso": "CO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "10.436667",
        "size": "medium"
    },
    {
        "iata": "VVC",
        "lon": "-73.566666",
        "iso": "CO",
        "status": 1,
        "name": "Vanguardia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.078889",
        "size": "medium"
    },
    {
        "iata": "AYG",
        "lon": "-73.933334",
        "iso": "CO",
        "status": 1,
        "name": "Yaguara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "1.533333",
        "size": "small"
    },
    {
        "iata": "EYP",
        "lon": "-72.386284",
        "iso": "CO",
        "status": 1,
        "name": "El Yopal Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.320729",
        "size": "medium"
    },
    {
        "iata": "MHW",
        "lon": "-63.966667",
        "iso": "BO",
        "status": 1,
        "name": "Monteagudo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.8325",
        "size": "small"
    },
    {
        "iata": "APB",
        "lon": "-68.51667",
        "iso": "BO",
        "status": 1,
        "name": "Apolo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.716667",
        "size": "small"
    },
    {
        "iata": "ASC",
        "lon": "-63.15",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-15.916667",
        "size": "small"
    },
    {
        "iata": "BJO",
        "lon": "-64.333336",
        "iso": "BO",
        "status": 1,
        "name": "Bermejo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.866667",
        "size": "medium"
    },
    {
        "iata": "CAM",
        "lon": "-63.56111",
        "iso": "BO",
        "status": 1,
        "name": "Camiri Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.01111",
        "size": "small"
    },
    {
        "iata": "CBB",
        "lon": "-66.178894",
        "iso": "BO",
        "status": 1,
        "name": "Jorge Wilsterman International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.413954",
        "size": "medium"
    },
    {
        "iata": "CIJ",
        "lon": "-68.75278",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.026389",
        "size": "medium"
    },
    {
        "iata": "CEP",
        "lon": "-62.083332",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.233334",
        "size": "small"
    },
    {
        "iata": "SRZ",
        "lon": "-63.1725",
        "iso": "BO",
        "status": 1,
        "name": "El Trompillo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.804167",
        "size": "medium"
    },
    {
        "iata": "GYA",
        "lon": "-65.361664",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-10.83",
        "size": "small"
    },
    {
        "iata": "BVK",
        "lon": "-63.75",
        "iso": "BO",
        "status": 1,
        "name": "Huacaraje Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.55",
        "size": "small"
    },
    {
        "iata": "SJS",
        "lon": "-60.738335",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-17.827223",
        "size": "small"
    },
    {
        "iata": "SJB",
        "lon": "-64.8",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-13.083333",
        "size": "small"
    },
    {
        "iata": "SJV",
        "lon": "-62.416668",
        "iso": "BO",
        "status": 1,
        "name": "San Javier Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-16.233334",
        "size": "small"
    },
    {
        "iata": "LPB",
        "lon": "-68.1769",
        "iso": "BO",
        "status": 1,
        "name": "El Alto International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-16.50889",
        "size": "medium"
    },
    {
        "iata": "MGD",
        "lon": "-64.124725",
        "iso": "BO",
        "status": 1,
        "name": "Magdalena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.325278",
        "size": "small"
    },
    {
        "iata": "ORU",
        "lon": "-67.07546",
        "iso": "BO",
        "status": 1,
        "name": "Juan Mendoza Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.963202",
        "size": "medium"
    },
    {
        "iata": "POI",
        "lon": "-65.72361",
        "iso": "BO",
        "status": 1,
        "name": "Capitan Nicolas Rojas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.543056",
        "size": "medium"
    },
    {
        "iata": "PUR",
        "lon": "-67.52",
        "iso": "BO",
        "status": 1,
        "name": "Puerto Rico Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.1125",
        "size": "small"
    },
    {
        "iata": "PSZ",
        "lon": "-57.8",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.966667",
        "size": "medium"
    },
    {
        "iata": "SRD",
        "lon": "-64.71667",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-13.283333",
        "size": "small"
    },
    {
        "iata": "RBO",
        "lon": "-59.75528",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.344444",
        "size": "small"
    },
    {
        "iata": "RIB",
        "lon": "-66.093056",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.006944",
        "size": "small"
    },
    {
        "iata": "REY",
        "lon": "-67.26667",
        "iso": "BO",
        "status": 1,
        "name": "Reyes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.316667",
        "size": "small"
    },
    {
        "iata": "SBL",
        "lon": "-65.45",
        "iso": "BO",
        "status": 1,
        "name": "Santa Ana Del Yacuma Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.716667",
        "size": "medium"
    },
    {
        "iata": "SRJ",
        "lon": "-66.833336",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-14.816667",
        "size": "small"
    },
    {
        "iata": "SNG",
        "lon": "-60.97639",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.383057",
        "size": "small"
    },
    {
        "iata": "SNM",
        "lon": "-65.63556",
        "iso": "BO",
        "status": 1,
        "name": "San Ignacio de Moxos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.966389",
        "size": "small"
    },
    {
        "iata": "SRB",
        "lon": "-67.333336",
        "iso": "BO",
        "status": 1,
        "name": "Santa Rosa De Yacuma Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.6",
        "size": "small"
    },
    {
        "iata": "SRE",
        "lon": "-65.30167",
        "iso": "BO",
        "status": 1,
        "name": "Juana Azurduy De Padilla Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-19.007778",
        "size": "medium"
    },
    {
        "iata": "MQK",
        "lon": "-58.385555",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.334444",
        "size": "small"
    },
    {
        "iata": "TJA",
        "lon": "-64.745834",
        "iso": "BO",
        "status": 1,
        "name": "Capitan Oriel Lea Plaza Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.554167",
        "size": "medium"
    },
    {
        "iata": "TDD",
        "lon": "-64.91931",
        "iso": "BO",
        "status": 1,
        "name": "Teniente Av. Jorge Henrich Arauz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.822613",
        "size": "medium"
    },
    {
        "iata": "UYU",
        "lon": "-66.8484",
        "iso": "BO",
        "status": 1,
        "name": "Uyuni Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.4463",
        "size": "small"
    },
    {
        "iata": "VAH",
        "lon": "-64.1",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.483334",
        "size": "small"
    },
    {
        "iata": "VLM",
        "lon": "-63.5",
        "iso": "BO",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-21.25",
        "size": "medium"
    },
    {
        "iata": "VVI",
        "lon": "-63.140488",
        "iso": "BO",
        "status": 1,
        "name": "Viru Viru International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.648233",
        "size": "medium"
    },
    {
        "iata": "BYC",
        "lon": "-63.65",
        "iso": "BO",
        "status": 1,
        "name": "Yacuiba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.95",
        "size": "medium"
    },
    {
        "iata": "ABN",
        "lon": "-54.083332",
        "iso": "SR",
        "status": 1,
        "name": "Albina Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.5",
        "size": "small"
    },
    {
        "iata": "TOT",
        "lon": "-56.316666",
        "iso": "SR",
        "status": 1,
        "name": "Totness Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.883333",
        "size": "small"
    },
    {
        "iata": "DRJ",
        "lon": "-54.666668",
        "iso": "SR",
        "status": 1,
        "name": "Drietabbetje Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.116667",
        "size": "small"
    },
    {
        "iata": "PBM",
        "lon": "-55.191113",
        "iso": "SR",
        "status": 1,
        "name": "Johan Adolf Pengel International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.451389",
        "size": "medium"
    },
    {
        "iata": "ICK",
        "lon": "-56.983334",
        "iso": "SR",
        "status": 1,
        "name": "Nieuw Nickerie Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.933333",
        "size": "small"
    },
    {
        "iata": "OEM",
        "lon": "-55.45",
        "iso": "SR",
        "status": 1,
        "name": "Vincent Fayks Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.35",
        "size": "small"
    },
    {
        "iata": "SMZ",
        "lon": "-54.4",
        "iso": "SR",
        "status": 1,
        "name": "Stoelmanseiland Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.343056",
        "size": "small"
    },
    {
        "iata": "AGI",
        "lon": "-56.833332",
        "iso": "SR",
        "status": 1,
        "name": "Wageningen Airport Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.833333",
        "size": "small"
    },
    {
        "iata": "ORG",
        "lon": "-55.191113",
        "iso": "SR",
        "status": 1,
        "name": "Zorg en Hoop Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.809444",
        "size": "small"
    },
    {
        "iata": "APY",
        "lon": "-45.933334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-9.133333",
        "size": "small"
    },
    {
        "iata": "APQ",
        "lon": "-36.65",
        "iso": "BR",
        "status": 1,
        "name": "Arapiraca Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.75",
        "size": "small"
    },
    {
        "iata": "AMJ",
        "lon": "-40.683334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.183332",
        "size": "small"
    },
    {
        "iata": "AIF",
        "lon": "-50.416668",
        "iso": "BR",
        "status": 1,
        "name": "Marcelo Pires Halzhausen Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.666668",
        "size": "small"
    },
    {
        "iata": "BDC",
        "lon": "-45.266666",
        "iso": "BR",
        "status": 1,
        "name": "Barra do Corda Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.466667",
        "size": "small"
    },
    {
        "iata": "BVM",
        "lon": "-38.916668",
        "iso": "BR",
        "status": 1,
        "name": "Belmonte Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.833333",
        "size": "small"
    },
    {
        "iata": "BRA",
        "lon": "-45.00833",
        "iso": "BR",
        "status": 1,
        "name": "Barreiras Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.073056",
        "size": "small"
    },
    {
        "iata": "BSS",
        "lon": "-46.05",
        "iso": "BR",
        "status": 1,
        "name": "Balsas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.516667",
        "size": "small"
    },
    {
        "iata": "BMS",
        "lon": "-41.63333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-14.233333",
        "size": "small"
    },
    {
        "iata": "BQQ",
        "lon": "-43.13333",
        "iso": "BR",
        "status": 1,
        "name": "Barra Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.083333",
        "size": "small"
    },
    {
        "iata": "CTP",
        "lon": "-46.016666",
        "iso": "BR",
        "status": 1,
        "name": "Carutapera Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.25",
        "size": "small"
    },
    {
        "iata": "CPU",
        "lon": "-44.86667",
        "iso": "BR",
        "status": 1,
        "name": "Cururupu Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.8",
        "size": "small"
    },
    {
        "iata": "QCH",
        "iso": "BR",
        "status": 1,
        "name": "Colatina Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "RDC",
        "lon": "-49.98",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-8.030278",
        "size": "small"
    },
    {
        "iata": "LEP",
        "lon": "-42.666668",
        "iso": "BR",
        "status": 1,
        "name": "Leopoldina Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-21.466667",
        "size": "small"
    },
    {
        "iata": "DIQ",
        "lon": "-44.869446",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-20.181389",
        "size": "small"
    },
    {
        "iata": "CNV",
        "lon": "-38.983334",
        "iso": "BR",
        "status": 1,
        "name": "Canavieiras Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.666667",
        "size": "small"
    },
    {
        "iata": "SXX",
        "lon": "-51.983334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-6.633333",
        "size": "small"
    },
    {
        "iata": "ODL",
        "lon": "140.63333",
        "iso": "BR",
        "status": 1,
        "name": "Guarapari Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.716667",
        "size": "small"
    },
    {
        "iata": "GDP",
        "lon": "-43.58167",
        "iso": "BR",
        "status": 1,
        "name": "Guadalupe Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.781944",
        "size": "small"
    },
    {
        "iata": "GNM",
        "lon": "-42.783333",
        "iso": "BR",
        "status": 1,
        "name": "Guanambi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.3",
        "size": "small"
    },
    {
        "iata": "GMS",
        "lon": "-44.7",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-2.15",
        "size": "small"
    },
    {
        "iata": "QGP",
        "lon": "-36.65",
        "iso": "BR",
        "status": 1,
        "name": "Garanhuns Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.911111",
        "size": "small"
    },
    {
        "iata": "ITN",
        "iso": "BR",
        "status": 0,
        "name": "Itabuna Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "IRE",
        "lon": "-41.86667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.3",
        "size": "small"
    },
    {
        "iata": "QIG",
        "lon": "-39.43611",
        "iso": "BR",
        "status": 1,
        "name": "Iguatu Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.477778",
        "size": "small"
    },
    {
        "iata": "QIT",
        "lon": "-40.36111",
        "iso": "BR",
        "status": 1,
        "name": "Itapetinga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.338889",
        "size": "small"
    },
    {
        "iata": "IPU",
        "lon": "-39.733334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-14.133333",
        "size": "small"
    },
    {
        "iata": "JCM",
        "lon": "-40.516666",
        "iso": "BR",
        "status": 1,
        "name": "Jacobina Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.183333",
        "size": "small"
    },
    {
        "iata": "FEC",
        "lon": "-38.906113",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-12.2025",
        "size": "small"
    },
    {
        "iata": "JEQ",
        "lon": "-40.083332",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-13.85",
        "size": "small"
    },
    {
        "iata": "JNA",
        "lon": "-44.385834",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-15.474167",
        "size": "small"
    },
    {
        "iata": "JDR",
        "lon": "-44.2301",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-21.087957",
        "size": "small"
    },
    {
        "iata": "CMP",
        "lon": "-36.35",
        "iso": "BR",
        "status": 1,
        "name": "Santana do Araguaia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.8",
        "size": "small"
    },
    {
        "iata": "QDF",
        "lon": "-43.930557",
        "iso": "BR",
        "status": 1,
        "name": "Conselheiro Lafaiete Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.786112",
        "size": "small"
    },
    {
        "iata": "QXD",
        "lon": "-41.225",
        "iso": "BR",
        "status": 1,
        "name": "Cachoeiro do Itapemirim Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-20.866667",
        "size": "small"
    },
    {
        "iata": "QCP",
        "lon": "-36.60278",
        "iso": "BR",
        "status": 1,
        "name": "Currais Novos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.408333",
        "size": "small"
    },
    {
        "iata": "LVB",
        "lon": "-55.61667",
        "iso": "BR",
        "status": 1,
        "name": "Livramento do Brumado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.833332",
        "size": "small"
    },
    {
        "iata": "SSO",
        "lon": "-45.05",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-22.116667",
        "size": "small"
    },
    {
        "iata": "MTE",
        "lon": "-54.066666",
        "iso": "BR",
        "status": 1,
        "name": "Monte Alegre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.983333",
        "size": "small"
    },
    {
        "iata": "MVS",
        "lon": "-39.566666",
        "iso": "BR",
        "status": 1,
        "name": "Mucuri Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-18.083332",
        "size": "small"
    },
    {
        "iata": "SBJ",
        "lon": "-39.733334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.583332",
        "size": "small"
    },
    {
        "iata": "PTQ",
        "lon": "-52.244446",
        "iso": "BR",
        "status": 1,
        "name": "Porto de Moz Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.738889",
        "size": "small"
    },
    {
        "iata": "NNU",
        "lon": "-40.333332",
        "iso": "BR",
        "status": 1,
        "name": "Nanuque Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.816668",
        "size": "small"
    },
    {
        "iata": "QBX",
        "lon": "-40.36667",
        "iso": "BR",
        "status": 1,
        "name": "Sobral Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.781521",
        "size": "small"
    },
    {
        "iata": "PSW",
        "lon": "-46.61667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-20.716667",
        "size": "small"
    },
    {
        "iata": "ORX",
        "lon": "-55.86667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-1.75",
        "size": "small"
    },
    {
        "iata": "PCS",
        "lon": "-41.466667",
        "iso": "BR",
        "status": 1,
        "name": "Picos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.083333",
        "size": "small"
    },
    {
        "iata": "POJ",
        "lon": "-46.490833",
        "iso": "BR",
        "status": 1,
        "name": "Patos de Minas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-18.671667",
        "size": "small"
    },
    {
        "iata": "PIV",
        "lon": "-44.93222",
        "iso": "BR",
        "status": 1,
        "name": "Pirapora Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.338612",
        "size": "small"
    },
    {
        "iata": "FLB",
        "lon": "-43.033333",
        "iso": "BR",
        "status": 1,
        "name": "Cangapara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.8",
        "size": "small"
    },
    {
        "iata": "JDO",
        "iso": "BR",
        "status": 1,
        "name": "Colina Verde Heliport",
        "continent": "SA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "PIV",
        "lon": "-44.93222",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-17.338612",
        "size": "small"
    },
    {
        "iata": "PDF",
        "lon": "-39.216667",
        "iso": "BR",
        "status": 1,
        "name": "Prado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.35",
        "size": "small"
    },
    {
        "iata": "CAU",
        "lon": "-35.916668",
        "iso": "BR",
        "status": 1,
        "name": "Caruaru Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.25",
        "size": "small"
    },
    {
        "iata": "SFK",
        "lon": "-48.52222",
        "iso": "BR",
        "status": 1,
        "name": "Soure Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.7",
        "size": "small"
    },
    {
        "iata": "OBI",
        "lon": "-55.516666",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-1.916667",
        "size": "small"
    },
    {
        "iata": "TFL",
        "lon": "-41.5",
        "iso": "BR",
        "status": 1,
        "name": "Juscelino Kubitscheck Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.85",
        "size": "small"
    },
    {
        "iata": "VAL",
        "lon": "-38.988876",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-13.295259",
        "size": "small"
    },
    {
        "iata": "QID",
        "lon": "-45.291668",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-21.855556",
        "size": "small"
    },
    {
        "iata": "BVS",
        "lon": "-50.466667",
        "iso": "BR",
        "status": 1,
        "name": "Breves Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-1.666667",
        "size": "small"
    },
    {
        "iata": "CMC",
        "lon": "-40.833332",
        "iso": "BR",
        "status": 1,
        "name": "Camocim Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.916667",
        "size": "small"
    },
    {
        "iata": "QXC",
        "lon": "-43.355556",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-4.870141",
        "size": "small"
    },
    {
        "iata": "PHI",
        "lon": "-45.083332",
        "iso": "BR",
        "status": 1,
        "name": "Pinheiro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.516667",
        "size": "small"
    },
    {
        "iata": "ITI",
        "lon": "-41.5",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.0",
        "size": "small"
    },
    {
        "iata": "PPY",
        "lon": "-45.918888",
        "iso": "BR",
        "status": 1,
        "name": "Pouso Alegre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.288334",
        "size": "small"
    },
    {
        "iata": "BXX",
        "lon": "43.11667",
        "iso": "SO",
        "status": 1,
        "name": "Boorama Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "9.966667",
        "size": "small"
    },
    {
        "iata": "GTA",
        "lon": "158.18333",
        "iso": "SB",
        "status": 1,
        "name": "Gatokae Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-8.766667",
        "size": "small"
    },
    {
        "iata": "CAY",
        "lon": "-52.36667",
        "iso": "GF",
        "status": 1,
        "name": "Cayenne-Rochambeau Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.816667",
        "size": "medium"
    },
    {
        "iata": "MPY",
        "lon": "-54.033333",
        "iso": "GF",
        "status": 1,
        "name": "Maripasoula Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.666667",
        "size": "small"
    },
    {
        "iata": "OXP",
        "iso": "GF",
        "status": 1,
        "name": "Saint-Georges-de-l'Oyapock Airport",
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "LDX",
        "lon": "-54.05",
        "iso": "GF",
        "status": 1,
        "name": "Saint-Laurent-du-Maroni Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.483333",
        "size": "small"
    },
    {
        "iata": "REI",
        "lon": "-52.13333",
        "iso": "GF",
        "status": 1,
        "name": "Regina Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.316667",
        "size": "small"
    },
    {
        "iata": "XAU",
        "lon": "-53.2",
        "iso": "GF",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "3.616667",
        "size": "small"
    },
    {
        "iata": "APE",
        "lon": "-69.083336",
        "iso": "PE",
        "status": 1,
        "name": "San Juan Aposento Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.933333",
        "size": "small"
    },
    {
        "iata": "ALD",
        "lon": "-69.333336",
        "iso": "PE",
        "status": 1,
        "name": "Alerta Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.683333",
        "size": "small"
    },
    {
        "iata": "AOP",
        "iso": "PE",
        "status": 1,
        "name": "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ATG",
        "lon": "72.25",
        "iso": "PE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "33.9",
        "size": "medium"
    },
    {
        "iata": "MBP",
        "lon": "-77.166664",
        "iso": "PE",
        "status": 1,
        "name": "Moyobamba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.2",
        "size": "small"
    },
    {
        "iata": "LHC",
        "iso": "PE",
        "status": 1,
        "name": "Caballococha Airport",
        "continent": "SA",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "BLP",
        "lon": "-69.416664",
        "iso": "PE",
        "status": 1,
        "name": "Huallaga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-16.583332",
        "size": "small"
    },
    {
        "iata": "IBP",
        "lon": "-69.583336",
        "iso": "PE",
        "status": 1,
        "name": "Iberia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.366667",
        "size": "medium"
    },
    {
        "iata": "TCG",
        "lon": "83.333336",
        "iso": "PE",
        "status": 1,
        "name": "Tocache Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "46.666668",
        "size": "small"
    },
    {
        "iata": "PCL",
        "lon": "-74.63333",
        "iso": "PE",
        "status": 1,
        "name": "Cap FAP David Abenzur Rengifo International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.333333",
        "size": "medium"
    },
    {
        "iata": "CTF",
        "lon": "-91.916664",
        "iso": "PE",
        "status": 1,
        "name": "Contamana Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "14.766667",
        "size": "small"
    },
    {
        "iata": "CHM",
        "lon": "-78.53111",
        "iso": "PE",
        "status": 1,
        "name": "Teniente FAP Jaime A De Montreuil Morales Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.150556",
        "size": "medium"
    },
    {
        "iata": "TGI",
        "lon": "-76.00552",
        "iso": "PE",
        "status": 1,
        "name": "Tingo Maria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.292779",
        "size": "small"
    },
    {
        "iata": "CIX",
        "lon": "-79.83222",
        "iso": "PE",
        "status": 1,
        "name": "Capitan FAP Jose A Quinones Gonzales International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.789722",
        "size": "medium"
    },
    {
        "iata": "AYP",
        "lon": "-74.24722",
        "iso": "PE",
        "status": 1,
        "name": "Coronel FAP Alfredo Mendivil Duarte Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.197222",
        "size": "medium"
    },
    {
        "iata": "ANS",
        "lon": "-73.355835",
        "iso": "PE",
        "status": 1,
        "name": "Andahuaylas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.716667",
        "size": "small"
    },
    {
        "iata": "ATA",
        "lon": "-77.6",
        "iso": "PE",
        "status": 1,
        "name": "Comandante FAP German Arias Graziani Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.347222",
        "size": "medium"
    },
    {
        "iata": "UMI",
        "lon": "-70.666664",
        "iso": "PE",
        "status": 1,
        "name": "Quince Air Base",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.25",
        "size": "small"
    },
    {
        "iata": "LIM",
        "lon": "-77.107666",
        "iso": "PE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-12.019421",
        "size": "large"
    },
    {
        "iata": "SFK",
        "lon": "-48.52222",
        "iso": "PE",
        "status": 1,
        "name": "Satipo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.7",
        "size": "small"
    },
    {
        "iata": "UCZ",
        "iso": "PE",
        "status": 1,
        "name": "Uchiza Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "RIJ",
        "lon": "-77.15",
        "iso": "PE",
        "status": 1,
        "name": "Juan Simons Vela Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.05",
        "size": "medium"
    },
    {
        "iata": "JJI",
        "lon": "-76.71667",
        "iso": "PE",
        "status": 1,
        "name": "Juanjui Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.15",
        "size": "medium"
    },
    {
        "iata": "JAU",
        "lon": "-75.25",
        "iso": "PE",
        "status": 1,
        "name": "Francisco Carle Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.75",
        "size": "medium"
    },
    {
        "iata": "JUL",
        "lon": "-70.15444",
        "iso": "PE",
        "status": 1,
        "name": "Inca Manco Capac International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.464167",
        "size": "medium"
    },
    {
        "iata": "SJA",
        "lon": "-75.15",
        "iso": "PE",
        "status": 1,
        "name": "San Juan de Marcona Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-15.35",
        "size": "small"
    },
    {
        "iata": "CJA",
        "lon": "-78.5",
        "iso": "PE",
        "status": 1,
        "name": "Mayor General FAP Armando Revoredo Iglesias Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.133333",
        "size": "medium"
    },
    {
        "iata": "RIM",
        "lon": "-78.0",
        "iso": "PE",
        "status": 1,
        "name": "San Nicolas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.0",
        "size": "small"
    },
    {
        "iata": "ILQ",
        "lon": "-71.333336",
        "iso": "PE",
        "status": 1,
        "name": "Ilo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.666668",
        "size": "medium"
    },
    {
        "iata": "LIM",
        "lon": "-77.107666",
        "iso": "PE",
        "status": 1,
        "name": "Las Palmas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.019421",
        "size": "small"
    },
    {
        "iata": "TBP",
        "lon": "-80.416664",
        "iso": "PE",
        "status": 1,
        "name": "Capitan FAP Pedro Canga Rodriguez Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.566667",
        "size": "medium"
    },
    {
        "iata": "CHM",
        "lon": "-78.53111",
        "iso": "PE",
        "status": 1,
        "name": "Manuel Prado Ugarteche Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.150556",
        "size": "small"
    },
    {
        "iata": "SMG",
        "lon": "-72.0",
        "iso": "PE",
        "status": 1,
        "name": "Santa Maria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.5",
        "size": "small"
    },
    {
        "iata": "YMS",
        "lon": "-76.1",
        "iso": "PE",
        "status": 1,
        "name": "Moises Benzaquen Rengifo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.9",
        "size": "medium"
    },
    {
        "iata": "HUU",
        "lon": "-76.21667",
        "iso": "PE",
        "status": 1,
        "name": "Alferez Fap David Figueroa Fernandini Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.866667",
        "size": "medium"
    },
    {
        "iata": "SQU",
        "lon": "-76.75",
        "iso": "PE",
        "status": 1,
        "name": "Saposoa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.95",
        "size": "small"
    },
    {
        "iata": "SYC",
        "lon": "-69.166664",
        "iso": "PE",
        "status": 1,
        "name": "Shiringayoc/Hacienda Hda Mejia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.9",
        "size": "small"
    },
    {
        "iata": "CHH",
        "lon": "-77.85",
        "iso": "PE",
        "status": 1,
        "name": "Chachapoyas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.216667",
        "size": "medium"
    },
    {
        "iata": "REQ",
        "lon": "-73.86667",
        "iso": "PE",
        "status": 1,
        "name": "Requena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-5.064947",
        "size": "small"
    },
    {
        "iata": "IQT",
        "lon": "-73.30284",
        "iso": "PE",
        "status": 1,
        "name": "Coronel FAP Francisco Secada Vignetta International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.785098",
        "size": "medium"
    },
    {
        "iata": "AQP",
        "lon": "-71.56799",
        "iso": "PE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.344812",
        "size": "medium"
    },
    {
        "iata": "TRU",
        "lon": "-79.115",
        "iso": "PE",
        "status": 1,
        "name": "Capitan FAP Carlos Martinez De Pinillos International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.09",
        "size": "medium"
    },
    {
        "iata": "SQD",
        "iso": "PE",
        "status": 1,
        "name": "San Francisco Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "PIO",
        "lon": "-76.21667",
        "iso": "PE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-13.75",
        "size": "medium"
    },
    {
        "iata": "TPP",
        "lon": "-76.39861",
        "iso": "PE",
        "status": 1,
        "name": "Cadete FAP Guillermo Del Castillo Paredes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-6.511111",
        "size": "medium"
    },
    {
        "iata": "SYC",
        "lon": "-69.166664",
        "iso": "PE",
        "status": 1,
        "name": "Shiringayoc Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.9",
        "size": "small"
    },
    {
        "iata": "TCQ",
        "lon": "-70.27889",
        "iso": "PE",
        "status": 1,
        "name": "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-18.063334",
        "size": "medium"
    },
    {
        "iata": "PEM",
        "lon": "-69.23333",
        "iso": "PE",
        "status": 1,
        "name": "Padre Aldamiz International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.583333",
        "size": "medium"
    },
    {
        "iata": "PIU",
        "lon": "-80.613335",
        "iso": "PE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-5.201667",
        "size": "medium"
    },
    {
        "iata": "TYL",
        "lon": "-81.25278",
        "iso": "PE",
        "status": 1,
        "name": "Capitan Montes Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.564722",
        "size": "medium"
    },
    {
        "iata": "NZA",
        "lon": "21.35786",
        "iso": "PE",
        "status": 1,
        "name": "Maria Reiche Neuman Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-7.716528",
        "size": "medium"
    },
    {
        "iata": "CUZ",
        "lon": "-71.94371",
        "iso": "PE",
        "status": 1,
        "name": "Alejandro Velasco Astete International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.538429",
        "size": "large"
    },
    {
        "iata": "AAJ",
        "lon": "-55.36667",
        "iso": "SR",
        "status": 1,
        "name": "Cayana Airstrip",
        "continent": "SA",
        "type": "airport",
        "lat": "3.9",
        "size": "small"
    },
    {
        "iata": "KCB",
        "lon": "-55.716667",
        "iso": "SR",
        "status": 1,
        "name": "Tepoe Airstrip",
        "continent": "SA",
        "type": "airport",
        "lat": "3.15",
        "size": "small"
    },
    {
        "iata": "APU",
        "lon": "-51.483334",
        "iso": "BR",
        "status": 1,
        "name": "Apucarana Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.55",
        "size": "small"
    },
    {
        "iata": "BNU",
        "lon": "-49.0",
        "iso": "BR",
        "status": 1,
        "name": "Blumenau Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.0",
        "size": "small"
    },
    {
        "iata": "CCI",
        "lon": "-52.050556",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-27.181389",
        "size": "small"
    },
    {
        "iata": "QCN",
        "lon": "-50.922222",
        "iso": "BR",
        "status": 1,
        "name": "Canela Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.497223",
        "size": "small"
    },
    {
        "iata": "CKO",
        "lon": "-50.666668",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-23.116667",
        "size": "small"
    },
    {
        "iata": "DOU",
        "lon": "-54.925556",
        "iso": "BR",
        "status": 1,
        "name": "Dourados Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.202778",
        "size": "small"
    },
    {
        "iata": "ERM",
        "lon": "-52.275555",
        "iso": "BR",
        "status": 1,
        "name": "Erechim Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.641666",
        "size": "small"
    },
    {
        "iata": "FBE",
        "lon": "-53.066666",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-26.083332",
        "size": "small"
    },
    {
        "iata": "QGA",
        "lon": "-54.230556",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-24.197222",
        "size": "small"
    },
    {
        "iata": "IJU",
        "lon": "-53.916668",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-28.416668",
        "size": "small"
    },
    {
        "iata": "ITQ",
        "lon": "-56.55",
        "iso": "BR",
        "status": 1,
        "name": "Itaqui Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.133333",
        "size": "small"
    },
    {
        "iata": "JCB",
        "lon": "-51.516666",
        "iso": "BR",
        "status": 1,
        "name": "Santa Terezinha Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.166668",
        "size": "small"
    },
    {
        "iata": "QDB",
        "lon": "-53.002777",
        "iso": "BR",
        "status": 1,
        "name": "Cachoeira do Sul Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.022223",
        "size": "small"
    },
    {
        "iata": "QCR",
        "lon": "-50.71389",
        "iso": "BR",
        "status": 1,
        "name": "Curitibanos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.425",
        "size": "small"
    },
    {
        "iata": "QRE",
        "lon": "-52.955555",
        "iso": "BR",
        "status": 1,
        "name": "Carazinho Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-28.36389",
        "size": "small"
    },
    {
        "iata": "ALQ",
        "lon": "-55.763332",
        "iso": "BR",
        "status": 1,
        "name": "Alegrete Novo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.799723",
        "size": "small"
    },
    {
        "iata": "QMF",
        "lon": "-49.97222",
        "iso": "BR",
        "status": 1,
        "name": "Mafra Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.23889",
        "size": "small"
    },
    {
        "iata": "QGF",
        "lon": "-51.544445",
        "iso": "BR",
        "status": 1,
        "name": "Montenegro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.744444",
        "size": "small"
    },
    {
        "iata": "QHV",
        "lon": "-51.216667",
        "iso": "BR",
        "status": 1,
        "name": "Novo Hamburgo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.811111",
        "size": "small"
    },
    {
        "iata": "SQX",
        "lon": "-53.502777",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-26.780556",
        "size": "small"
    },
    {
        "iata": "APX",
        "lon": "-51.466667",
        "iso": "BR",
        "status": 1,
        "name": "Arapongas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.483334",
        "size": "small"
    },
    {
        "iata": "PTO",
        "lon": "-52.666668",
        "iso": "BR",
        "status": 1,
        "name": "Pato Branco Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-26.216667",
        "size": "small"
    },
    {
        "iata": "PNG",
        "lon": "-48.5",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-25.516666",
        "size": "small"
    },
    {
        "iata": "PVI",
        "lon": "-52.466667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-23.066668",
        "size": "small"
    },
    {
        "iata": "PBB",
        "lon": "-51.183334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-19.666668",
        "size": "small"
    },
    {
        "iata": "QAC",
        "lon": "-50.052776",
        "iso": "BR",
        "status": 1,
        "name": "Castro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.875",
        "size": "small"
    },
    {
        "iata": "SQY",
        "lon": "-52.032223",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-31.382778",
        "size": "small"
    },
    {
        "iata": "QOJ",
        "lon": "-56.177776",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-28.680555",
        "size": "small"
    },
    {
        "iata": "CSU",
        "lon": "-52.416668",
        "iso": "BR",
        "status": 1,
        "name": "Santa Cruz do Sul Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-29.683332",
        "size": "small"
    },
    {
        "iata": "UMU",
        "lon": "-53.31333",
        "iso": "BR",
        "status": 1,
        "name": "Umuarama Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-23.798332",
        "size": "small"
    },
    {
        "iata": "QVB",
        "lon": "-51.08889",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-26.366667",
        "size": "small"
    },
    {
        "iata": "VIA",
        "lon": "-51.136112",
        "iso": "BR",
        "status": 1,
        "name": "Videira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.008333",
        "size": "small"
    },
    {
        "iata": "CTQ",
        "lon": "-50.1",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.85",
        "size": "small"
    },
    {
        "iata": "AXE",
        "lon": "-52.38333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-26.883333",
        "size": "small"
    },
    {
        "iata": "AAG",
        "lon": "-49.79",
        "iso": "BR",
        "status": 1,
        "name": "Arapoti Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-24.103611",
        "size": "small"
    },
    {
        "iata": "SRA",
        "lon": "-54.483334",
        "iso": "BR",
        "status": 1,
        "name": "Santa Rosa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-27.866667",
        "size": "small"
    },
    {
        "iata": "PGZ",
        "lon": "-50.15",
        "iso": "BR",
        "status": 1,
        "name": "Ponta Grossa Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-25.083332",
        "size": "small"
    },
    {
        "iata": "ATI",
        "lon": "-56.50833",
        "iso": "UY",
        "status": 1,
        "name": "Artigas International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.4",
        "size": "small"
    },
    {
        "iata": "BUV",
        "lon": "-57.083332",
        "iso": "UY",
        "status": 1,
        "name": "Bella Union Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.333332",
        "size": "small"
    },
    {
        "iata": "CYR",
        "lon": "-57.76777",
        "iso": "UY",
        "status": 1,
        "name": "Laguna de Los Patos International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.455326",
        "size": "small"
    },
    {
        "iata": "CAR",
        "lon": "-68.01667",
        "iso": "UY",
        "status": 1,
        "name": "Carmelo International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "46.86667",
        "size": "small"
    },
    {
        "iata": "DZO",
        "lon": "-56.516666",
        "iso": "UY",
        "status": 1,
        "name": "Santa Bernardina International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.416668",
        "size": "medium"
    },
    {
        "iata": "PDP",
        "lon": "-55.093163",
        "iso": "UY",
        "status": 1,
        "name": "Capitan Corbeta CA Curbelo International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.857212",
        "size": "medium"
    },
    {
        "iata": "MER",
        "lon": "-120.48333",
        "iso": "UY",
        "status": 1,
        "name": "Ricardo de Tomasi International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "37.3",
        "size": "small"
    },
    {
        "iata": "MLZ",
        "lon": "-54.2",
        "iso": "UY",
        "status": 1,
        "name": "Cerro Largo International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-32.333332",
        "size": "small"
    },
    {
        "iata": "MVD",
        "lon": "-56.026466",
        "iso": "UY",
        "status": 1,
        "name": "Carrasco International /General C L Berisso Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-34.841152",
        "size": "large"
    },
    {
        "iata": "MDO",
        "lon": "-146.3",
        "iso": "UY",
        "status": 1,
        "name": "El Jaguel / Punta del Este Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "59.453056",
        "size": "small"
    },
    {
        "iata": "PDU",
        "lon": "-58.064167",
        "iso": "UY",
        "status": 1,
        "name": "Tydeo Larre Borges Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-32.365833",
        "size": "small"
    },
    {
        "iata": "RVY",
        "lon": "-55.47703",
        "iso": "UY",
        "status": 1,
        "name": "Presidente General Don Oscar D. Gestido International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-30.973482",
        "size": "small"
    },
    {
        "iata": "STY",
        "lon": "-57.988335",
        "iso": "UY",
        "status": 1,
        "name": "Nueva Hesperides International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.436666",
        "size": "medium"
    },
    {
        "iata": "TAW",
        "lon": "-55.916668",
        "iso": "UY",
        "status": 1,
        "name": "Tacuarembo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.75",
        "size": "small"
    },
    {
        "iata": "TYT",
        "lon": "-54.283333",
        "iso": "UY",
        "status": 1,
        "name": "Treinta y Tres Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-33.266666",
        "size": "small"
    },
    {
        "iata": "VCH",
        "lon": "-54.25",
        "iso": "UY",
        "status": 1,
        "name": "Vichadero Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-31.733334",
        "size": "small"
    },
    {
        "iata": "AGV",
        "lon": "-69.23333",
        "iso": "VE",
        "status": 1,
        "name": "Oswaldo Guevara Mujica Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.552222",
        "size": "medium"
    },
    {
        "iata": "AAO",
        "lon": "-64.46389",
        "iso": "VE",
        "status": 1,
        "name": "Anaco Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.431944",
        "size": "medium"
    },
    {
        "iata": "LPJ",
        "lon": "-67.666664",
        "iso": "VE",
        "status": 1,
        "name": "Armando Schwarck Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.0",
        "size": "small"
    },
    {
        "iata": "BLA",
        "lon": "-64.68589",
        "iso": "VE",
        "status": 1,
        "name": "General Jose Antonio Anzoategui International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.107936",
        "size": "medium"
    },
    {
        "iata": "BNS",
        "lon": "-70.22111",
        "iso": "VE",
        "status": 1,
        "name": "Barinas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.618056",
        "size": "medium"
    },
    {
        "iata": "ELR",
        "lon": "140.06667",
        "iso": "VE",
        "status": 1,
        "name": "El Libertador Airbase",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.816667",
        "size": "medium"
    },
    {
        "iata": "BRM",
        "lon": "-69.35984",
        "iso": "VE",
        "status": 1,
        "name": "Barquisimeto International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.046267",
        "size": "medium"
    },
    {
        "iata": "MYC",
        "lon": "-68.166664",
        "iso": "VE",
        "status": 1,
        "name": "Escuela Mariscal Sucre Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.5",
        "size": "medium"
    },
    {
        "iata": "CBL",
        "lon": "-63.534657",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "8.122381",
        "size": "medium"
    },
    {
        "iata": "CXA",
        "lon": "-66.15",
        "iso": "VE",
        "status": 1,
        "name": "Caicara del Orinoco Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.533333",
        "size": "medium"
    },
    {
        "iata": "CUV",
        "lon": "-70.95",
        "iso": "VE",
        "status": 1,
        "name": "Casigua El Cubo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.066667",
        "size": "small"
    },
    {
        "iata": "CLZ",
        "lon": "-67.433334",
        "iso": "VE",
        "status": 1,
        "name": "Calabozo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.933333",
        "size": "medium"
    },
    {
        "iata": "CAJ",
        "lon": "-62.85351",
        "iso": "VE",
        "status": 1,
        "name": "Canaima Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.240634",
        "size": "medium"
    },
    {
        "iata": "VCR",
        "lon": "-70.083336",
        "iso": "VE",
        "status": 1,
        "name": "Carora Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.183333",
        "size": "medium"
    },
    {
        "iata": "CUP",
        "lon": "-63.2625",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "10.658889",
        "size": "medium"
    },
    {
        "iata": "CZE",
        "lon": "-69.681946",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "11.415833",
        "size": "medium"
    },
    {
        "iata": "CUM",
        "lon": "-64.131744",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "10.449879",
        "size": "medium"
    },
    {
        "iata": "isl",
        "lon": "-145.03334",
        "iso": "VE",
        "status": 1,
        "name": "La Tortuga Punta Delgada Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "63.5",
        "size": "small"
    },
    {
        "iata": "EOR",
        "lon": "-61.88333",
        "iso": "VE",
        "status": 1,
        "name": "El Dorado Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.733333",
        "size": "medium"
    },
    {
        "iata": "EOZ",
        "lon": "-69.53333",
        "iso": "VE",
        "status": 1,
        "name": "Elorza Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.166667",
        "size": "medium"
    },
    {
        "iata": "GDO",
        "lon": "-70.8",
        "iso": "VE",
        "status": 1,
        "name": "Guasdalito Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.233333",
        "size": "medium"
    },
    {
        "iata": "GUI",
        "lon": "-62.3",
        "iso": "VE",
        "status": 1,
        "name": "Guiria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.566667",
        "size": "medium"
    },
    {
        "iata": "GUQ",
        "lon": "-69.75",
        "iso": "VE",
        "status": 1,
        "name": "Guanare Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.025278",
        "size": "medium"
    },
    {
        "iata": "ICA",
        "lon": "-61.733334",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "4.333333",
        "size": "small"
    },
    {
        "iata": "LSP",
        "lon": "-70.14961",
        "iso": "VE",
        "status": 1,
        "name": "Josefa Camejo International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.777155",
        "size": "medium"
    },
    {
        "iata": "KAV",
        "lon": "-61.766666",
        "iso": "VE",
        "status": 1,
        "name": "Kavanayen Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.666667",
        "size": "small"
    },
    {
        "iata": "LFR",
        "lon": "-72.272224",
        "iso": "VE",
        "status": 1,
        "name": "La Fria Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.240556",
        "size": "medium"
    },
    {
        "iata": "MAR",
        "lon": "-71.72351",
        "iso": "VE",
        "status": 1,
        "name": "La Chinita International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.555564",
        "size": "medium"
    },
    {
        "iata": "MRD",
        "lon": "-71.16051",
        "iso": "VE",
        "status": 1,
        "name": "Alberto Carnevalli Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.582471",
        "size": "medium"
    },
    {
        "iata": "PMV",
        "lon": "-63.96899",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "10.917189",
        "size": "medium"
    },
    {
        "iata": "CCS",
        "lon": "-67.00551",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "10.596942",
        "size": "medium"
    },
    {
        "iata": "MUN",
        "lon": "-63.156933",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "9.749384",
        "size": "medium"
    },
    {
        "iata": "CBS",
        "lon": "-71.416664",
        "iso": "VE",
        "status": 1,
        "name": "Oro Negro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.383333",
        "size": "small"
    },
    {
        "iata": "PYH",
        "lon": "-67.5",
        "iso": "VE",
        "status": 1,
        "name": "Cacique Aramare Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.6",
        "size": "medium"
    },
    {
        "iata": "PBL",
        "lon": "-68.07361",
        "iso": "VE",
        "status": 1,
        "name": "General Bartolome Salom International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.479167",
        "size": "medium"
    },
    {
        "iata": "PDZ",
        "lon": "-62.233334",
        "iso": "VE",
        "status": 1,
        "name": "Pedernales Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.966667",
        "size": "small"
    },
    {
        "iata": "PPH",
        "lon": "-61.516666",
        "iso": "VE",
        "status": 1,
        "name": "Perai Tepuy Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.583333",
        "size": "small"
    },
    {
        "iata": "SCI",
        "lon": "-72.1999",
        "iso": "VE",
        "status": 1,
        "name": "Paramillo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.800072",
        "size": "medium"
    },
    {
        "iata": "PZO",
        "lon": "-62.759136",
        "iso": "VE",
        "status": 1,
        "name": "General Manuel Carlos Piar International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.286533",
        "size": "medium"
    },
    {
        "iata": "PTM",
        "lon": "-70.183334",
        "iso": "VE",
        "status": 1,
        "name": "Palmarito Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.566667",
        "size": "medium"
    },
    {
        "iata": "LRV",
        "lon": "-66.750114",
        "iso": "VE",
        "status": 1,
        "name": "Gran Roque Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.857591",
        "size": "small"
    },
    {
        "iata": "SVZ",
        "lon": "-72.43865",
        "iso": "VE",
        "status": 1,
        "name": "San Antonio Del Tachira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.839419",
        "size": "medium"
    },
    {
        "iata": "SBB",
        "lon": "-71.166664",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "7.783333",
        "size": "small"
    },
    {
        "iata": "SNV",
        "lon": "-61.11667",
        "iso": "VE",
        "status": 1,
        "name": "Santa Elena de Uairen Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.55",
        "size": "medium"
    },
    {
        "iata": "STD",
        "lon": "-72.066666",
        "iso": "VE",
        "status": 1,
        "name": "Mayor Buenaventura Vivas International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.583333",
        "size": "medium"
    },
    {
        "iata": "SNF",
        "lon": "-68.75389",
        "iso": "VE",
        "status": 1,
        "name": "Sub Teniente Nestor Arias Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.280278",
        "size": "medium"
    },
    {
        "iata": "SFD",
        "lon": "-67.43916",
        "iso": "VE",
        "status": 1,
        "name": "San Fernando De Apure Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.885278",
        "size": "medium"
    },
    {
        "iata": "SOM",
        "lon": "-64.14981",
        "iso": "VE",
        "status": 1,
        "name": "San Tome Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.94536",
        "size": "medium"
    },
    {
        "iata": "STB",
        "lon": "-71.95",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "9.033333",
        "size": "medium"
    },
    {
        "iata": "TUV",
        "lon": "-62.05",
        "iso": "VE",
        "status": 1,
        "name": "Tucupita Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.091667",
        "size": "medium"
    },
    {
        "iata": "TMO",
        "lon": "-61.5",
        "iso": "VE",
        "status": 1,
        "name": "Tumeremo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.3",
        "size": "medium"
    },
    {
        "iata": "URM",
        "lon": "-62.75833",
        "iso": "VE",
        "status": 1,
        "name": "Uriman Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.341667",
        "size": "small"
    },
    {
        "iata": "VLN",
        "lon": "-67.92248",
        "iso": "VE",
        "status": 1,
        "name": "Arturo Michelena International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.15429",
        "size": "medium"
    },
    {
        "iata": "VIG",
        "lon": "-71.65",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "8.633333",
        "size": "medium"
    },
    {
        "iata": "VLV",
        "lon": "-70.58583",
        "iso": "VE",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "9.339167",
        "size": "medium"
    },
    {
        "iata": "VDP",
        "lon": "-65.99111",
        "iso": "VE",
        "status": 1,
        "name": "Valle de La Pascua Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "9.221389",
        "size": "medium"
    },
    {
        "iata": "BAZ",
        "lon": "-62.933613",
        "iso": "BR",
        "status": 1,
        "name": "Barcelos Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.966944",
        "size": "small"
    },
    {
        "iata": "RBB",
        "lon": "-59.583332",
        "iso": "BR",
        "status": 1,
        "name": "Borba Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.4",
        "size": "small"
    },
    {
        "iata": "CAF",
        "lon": "-66.916664",
        "iso": "BR",
        "status": 1,
        "name": "Carauari Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.9",
        "size": "small"
    },
    {
        "iata": "CQS",
        "lon": "-64.25",
        "iso": "BR",
        "status": 1,
        "name": "Costa Marques Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.416667",
        "size": "small"
    },
    {
        "iata": "DMT",
        "lon": "-56.5",
        "iso": "BR",
        "status": 1,
        "name": "Diamantino Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.5",
        "size": "small"
    },
    {
        "iata": "DNO",
        "lon": "-46.85",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.616667",
        "size": "small"
    },
    {
        "iata": "ARS",
        "lon": "-52.233334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-15.9",
        "size": "small"
    },
    {
        "iata": "ERN",
        "lon": "-69.88333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-6.583333",
        "size": "small"
    },
    {
        "iata": "CQA",
        "lon": "-52.27028",
        "iso": "BR",
        "status": 1,
        "name": "Canarana Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-13.574167",
        "size": "small"
    },
    {
        "iata": "FEJ",
        "lon": "-70.35",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-8.15",
        "size": "small"
    },
    {
        "iata": "SXO",
        "lon": "-50.65",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.6",
        "size": "small"
    },
    {
        "iata": "GRP",
        "lon": "-49.216667",
        "iso": "BR",
        "status": 1,
        "name": "Gurupi Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.666667",
        "size": "small"
    },
    {
        "iata": "AUX",
        "lon": "-48.2",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-7.2",
        "size": "small"
    },
    {
        "iata": "IPG",
        "lon": "-65.95",
        "iso": "BR",
        "status": 1,
        "name": "Ipiranga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-3.216667",
        "size": "small"
    },
    {
        "iata": "IDO",
        "lon": "-50.666668",
        "iso": "BR",
        "status": 1,
        "name": "Santa Izabel do Morro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.566667",
        "size": "small"
    },
    {
        "iata": "JPR",
        "lon": "-61.84667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-10.870556",
        "size": "small"
    },
    {
        "iata": "JIA",
        "lon": "-58.88333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.5",
        "size": "small"
    },
    {
        "iata": "JRN",
        "lon": "-58.488888",
        "iso": "BR",
        "status": 1,
        "name": "Juruena Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.305556",
        "size": "small"
    },
    {
        "iata": "CCX",
        "lon": "-57.7",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.066668",
        "size": "small"
    },
    {
        "iata": "CIZ",
        "lon": "-63.13333",
        "iso": "BR",
        "status": 1,
        "name": "Coari Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-4.083333",
        "size": "small"
    },
    {
        "iata": "TLZ",
        "lon": "-47.95",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-18.166668",
        "size": "small"
    },
    {
        "iata": "LBR",
        "lon": "-64.85",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-7.25",
        "size": "small"
    },
    {
        "iata": "RVD",
        "lon": "-50.933334",
        "iso": "BR",
        "status": 1,
        "name": "General Leite de Castro Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-17.716667",
        "size": "small"
    },
    {
        "iata": "MBZ",
        "lon": "-57.7",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-3.4",
        "size": "small"
    },
    {
        "iata": "NVP",
        "lon": "-60.36667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-5.133333",
        "size": "small"
    },
    {
        "iata": "AQM",
        "lon": "-63.066666",
        "iso": "BR",
        "status": 1,
        "name": "Nova Vida Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-9.933333",
        "size": "small"
    },
    {
        "iata": "BCR",
        "lon": "-67.38333",
        "iso": "BR",
        "status": 1,
        "name": "Novo Campo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-8.75",
        "size": "small"
    },
    {
        "iata": "NQL",
        "lon": "-48.983334",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-14.0",
        "size": "small"
    },
    {
        "iata": "APS",
        "lon": "-48.966667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.333332",
        "size": "small"
    },
    {
        "iata": "PIN",
        "lon": "-56.770027",
        "iso": "BR",
        "status": 1,
        "name": "Parintins Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-2.669208",
        "size": "small"
    },
    {
        "iata": "PMW",
        "lon": "-48.35278",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-10.241667",
        "size": "small"
    },
    {
        "iata": "PBQ",
        "lon": "-61.2",
        "iso": "BR",
        "status": 1,
        "name": "Pimenta Bueno Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-11.7",
        "size": "small"
    },
    {
        "iata": "AAI",
        "lon": "-46.933334",
        "iso": "BR",
        "status": 1,
        "name": "Arraias Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.916667",
        "size": "small"
    },
    {
        "iata": "ROO",
        "lon": "-54.716667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-16.433332",
        "size": "small"
    },
    {
        "iata": "AIR",
        "lon": "-59.38333",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-10.25",
        "size": "small"
    },
    {
        "iata": "OPS",
        "lon": "-55.58156",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-11.878573",
        "size": "small"
    },
    {
        "iata": "STZ",
        "lon": "-50.45",
        "iso": "BR",
        "status": 1,
        "name": "Santa Terezinha Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.300278",
        "size": "small"
    },
    {
        "iata": "IRZ",
        "lon": "-64.996025",
        "iso": "BR",
        "status": 1,
        "name": "Tapuruquara Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-0.380296",
        "size": "small"
    },
    {
        "iata": "AZL",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "QHN",
        "lon": "-46.405556",
        "iso": "BR",
        "status": 1,
        "name": "Taguatinga Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-12.438889",
        "size": "small"
    },
    {
        "iata": "SQM",
        "lon": "-50.216667",
        "iso": "BR",
        "status": 1,
        "name": null,
        "continent": "SA",
        "type": "airport",
        "lat": "-13.316667",
        "size": "small"
    },
    {
        "iata": "VLP",
        "lon": "-51.122223",
        "iso": "BR",
        "status": 1,
        "name": "Vila Rica Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.015556",
        "size": "small"
    },
    {
        "iata": "MBK",
        "lon": "-54.932777",
        "iso": "BR",
        "status": 1,
        "name": "Regional Orlando Villas Boas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-10.0575",
        "size": "small"
    },
    {
        "iata": "NOK",
        "lon": "-52.34861",
        "iso": "BR",
        "status": 1,
        "name": "Xavantina Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-14.690278",
        "size": "small"
    },
    {
        "iata": "AHL",
        "lon": "-59.316666",
        "iso": "GY",
        "status": 1,
        "name": "Aishalton Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.483333",
        "size": "small"
    },
    {
        "iata": "NAI",
        "lon": "-59.0",
        "iso": "GY",
        "status": 1,
        "name": "Annai Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.75",
        "size": "small"
    },
    {
        "iata": "BMJ",
        "lon": "-60.0",
        "iso": "GY",
        "status": 1,
        "name": "Baramita Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.75",
        "size": "small"
    },
    {
        "iata": "GFO",
        "lon": "-58.583332",
        "iso": "GY",
        "status": 1,
        "name": "Bartica A Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.416667",
        "size": "small"
    },
    {
        "iata": "GEO",
        "lon": "-58.25308",
        "iso": "GY",
        "status": 1,
        "name": "Cheddi Jagan International Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.503833",
        "size": "medium"
    },
    {
        "iata": "OGL",
        "lon": "-58.10521",
        "iso": "GY",
        "status": 1,
        "name": "Ogle Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "6.807733",
        "size": "small"
    },
    {
        "iata": "IMB",
        "lon": "-60.283333",
        "iso": "GY",
        "status": 1,
        "name": "Imbaimadai Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.716667",
        "size": "small"
    },
    {
        "iata": "KAR",
        "lon": "-60.61667",
        "iso": "GY",
        "status": 1,
        "name": "Kamarang Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.883333",
        "size": "small"
    },
    {
        "iata": "KRM",
        "lon": "-59.316666",
        "iso": "GY",
        "status": 1,
        "name": "Karanambo Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.75",
        "size": "small"
    },
    {
        "iata": "KRG",
        "lon": "-59.516666",
        "iso": "GY",
        "status": 1,
        "name": "Karasabai Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.016667",
        "size": "small"
    },
    {
        "iata": "KTO",
        "lon": "-59.816666",
        "iso": "GY",
        "status": 1,
        "name": "Kato Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.65",
        "size": "small"
    },
    {
        "iata": "LUB",
        "lon": "-59.433334",
        "iso": "GY",
        "status": 1,
        "name": "Lumid Pau Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "2.4",
        "size": "small"
    },
    {
        "iata": "LTM",
        "lon": "-59.79",
        "iso": "GY",
        "status": 1,
        "name": "Lethem Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "3.371667",
        "size": "medium"
    },
    {
        "iata": "USI",
        "lon": "-59.833332",
        "iso": "GY",
        "status": 1,
        "name": "Mabaruma Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.166667",
        "size": "small"
    },
    {
        "iata": "MHA",
        "lon": "-59.15",
        "iso": "GY",
        "status": 1,
        "name": "Mahdia Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.25",
        "size": "small"
    },
    {
        "iata": "MYM",
        "lon": "-59.63333",
        "iso": "GY",
        "status": 1,
        "name": "Monkey Mountain Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.6",
        "size": "small"
    },
    {
        "iata": "QSX",
        "iso": "GY",
        "status": 1,
        "name": "New Amsterdam Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ORJ",
        "lon": "-60.033333",
        "iso": "GY",
        "status": 1,
        "name": "Orinduik Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "4.716667",
        "size": "small"
    },
    {
        "iata": "PRR",
        "lon": "-61.05",
        "iso": "GY",
        "status": 1,
        "name": "Paruma Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.8",
        "size": "small"
    },
    {
        "iata": "SZN",
        "lon": "-119.915",
        "iso": "US",
        "status": 1,
        "name": "Santa Cruz Island Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "34.060555",
        "size": "small"
    },
    {
        "iata": "ANU",
        "lon": "-61.76389",
        "iso": "AG",
        "status": 1,
        "name": "V.C. Bird International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.108334",
        "size": "medium"
    },
    {
        "iata": "BBQ",
        "lon": "-61.765",
        "iso": "AG",
        "status": 1,
        "name": "Codrington Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.555555",
        "size": "small"
    },
    {
        "iata": "BGI",
        "lon": "-59.487835",
        "iso": "BB",
        "status": 1,
        "name": "Sir Grantley Adams International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.080732",
        "size": "medium"
    },
    {
        "iata": "DCF",
        "lon": "-61.333332",
        "iso": "DM",
        "status": 1,
        "name": "Canefield Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.5",
        "size": "medium"
    },
    {
        "iata": "DOM",
        "lon": "-61.309166",
        "iso": "DM",
        "status": 1,
        "name": "Melville Hall Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.543056",
        "size": "medium"
    },
    {
        "iata": "DSD",
        "lon": "-61.016666",
        "iso": "GP",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "16.333332",
        "size": "small"
    },
    {
        "iata": "BBR",
        "lon": "-61.739445",
        "iso": "GP",
        "status": 1,
        "name": "Baillif Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.01639",
        "size": "small"
    },
    {
        "iata": "SFC",
        "lon": "-61.283333",
        "iso": "GP",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "16.25",
        "size": "small"
    },
    {
        "iata": "FDF",
        "lon": "-60.999947",
        "iso": "MQ",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "14.596061",
        "size": "medium"
    },
    {
        "iata": "SFG",
        "lon": "-63.04889",
        "iso": "MF",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "18.100555",
        "size": "medium"
    },
    {
        "iata": "SBH",
        "lon": "-62.85",
        "iso": "BL",
        "status": 1,
        "name": "Gustaf III Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.9",
        "size": "medium"
    },
    {
        "iata": "GBJ",
        "lon": "-61.266666",
        "iso": "GP",
        "status": 1,
        "name": "Les Bases Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.866667",
        "size": "medium"
    },
    {
        "iata": "PTP",
        "lon": "-61.52639",
        "iso": "GP",
        "status": 1,
        "name": null,
        "continent": "NA",
        "type": "airport",
        "lat": "16.2675",
        "size": "medium"
    },
    {
        "iata": "LSS",
        "lon": "-61.576668",
        "iso": "GP",
        "status": 1,
        "name": "Terre-de-Haut Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "15.866944",
        "size": "small"
    },
    {
        "iata": "GND",
        "lon": "-61.78611",
        "iso": "GD",
        "status": 1,
        "name": "Point Salines International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.004167",
        "size": "medium"
    },
    {
        "iata": "CRU",
        "lon": "-61.47222",
        "iso": "GD",
        "status": 1,
        "name": "Lauriston Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.475",
        "size": "small"
    },
    {
        "iata": "STT",
        "lon": "-64.969444",
        "iso": "VI",
        "status": 1,
        "name": "Cyril E. King Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.3375",
        "size": "medium"
    },
    {
        "iata": "STX",
        "lon": "-64.798615",
        "iso": "VI",
        "status": 1,
        "name": "Henry E Rohlsen Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.701944",
        "size": "medium"
    },
    {
        "iata": "ARE",
        "lon": "-66.675835",
        "iso": "PR",
        "status": 1,
        "name": "Antonio Nery Juarbe Pol Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.45",
        "size": "medium"
    },
    {
        "iata": "BQN",
        "lon": "-67.13",
        "iso": "PR",
        "status": 1,
        "name": "Rafael Hernandez Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.495832",
        "size": "medium"
    },
    {
        "iata": "VQS",
        "lon": "-65.48803",
        "iso": "PR",
        "status": 1,
        "name": "Vieques Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.134089",
        "size": "small"
    },
    {
        "iata": "CPX",
        "lon": "-65.30368",
        "iso": "PR",
        "status": 1,
        "name": "Benjamin Rivera Noriega Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.31255",
        "size": "small"
    },
    {
        "iata": "FAJ",
        "lon": "-65.50222",
        "iso": "PR",
        "status": 1,
        "name": "Diego Jimenez Torres Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.338888",
        "size": "medium"
    },
    {
        "iata": "SIG",
        "lon": "-66.09971",
        "iso": "PR",
        "status": 1,
        "name": "Fernando Luis Ribas Dominicci Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.456001",
        "size": "medium"
    },
    {
        "iata": "MAZ",
        "lon": "-67.14889",
        "iso": "PR",
        "status": 1,
        "name": "Eugenio Maria De Hostos Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.257778",
        "size": "medium"
    },
    {
        "iata": "PSE",
        "lon": "-66.563545",
        "iso": "PR",
        "status": 1,
        "name": "Mercedita Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.010702",
        "size": "medium"
    },
    {
        "iata": "SJU",
        "lon": "-66.004684",
        "iso": "PR",
        "status": 1,
        "name": "Luis Munoz Marin International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.437403",
        "size": "large"
    },
    {
        "iata": "SKB",
        "lon": "-62.71397",
        "iso": "KN",
        "status": 1,
        "name": "Robert L. Bradshaw International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.310843",
        "size": "medium"
    },
    {
        "iata": "NEV",
        "lon": "-62.5925",
        "iso": "KN",
        "status": 1,
        "name": "Vance W. Amory International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.205",
        "size": "medium"
    },
    {
        "iata": "SLU",
        "lon": "-60.99441",
        "iso": "LC",
        "status": 1,
        "name": "George F. L. Charles Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "14.020417",
        "size": "medium"
    },
    {
        "iata": "UVF",
        "lon": "-60.95222",
        "iso": "LC",
        "status": 1,
        "name": "Hewanorra International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.735556",
        "size": "medium"
    },
    {
        "iata": "NBE",
        "lon": "10.438611",
        "iso": "TN",
        "status": 1,
        "name": "Enfidha Zine El Abidine Ben Ali International Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "36.075832",
        "size": "large"
    },
    {
        "iata": "AUA",
        "lon": "-70.013885",
        "iso": "AW",
        "status": 1,
        "name": "Queen Beatrix International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.502222",
        "size": "medium"
    },
    {
        "iata": "BON",
        "lon": "-68.27687",
        "iso": "AN",
        "status": 1,
        "name": "Flamingo International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.133403",
        "size": "medium"
    },
    {
        "iata": "CUR",
        "lon": "-68.95706",
        "iso": "AN",
        "status": 1,
        "name": "Hato International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.184615",
        "size": "medium"
    },
    {
        "iata": "EUX",
        "lon": "-62.97778",
        "iso": "AN",
        "status": 1,
        "name": "F. D. Roosevelt Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.493055",
        "size": "medium"
    },
    {
        "iata": "SXM",
        "lon": "-63.11406",
        "iso": "AN",
        "status": 1,
        "name": "Princess Juliana International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.044722",
        "size": "large"
    },
    {
        "iata": "SAB",
        "lon": "-63.216667",
        "iso": "AN",
        "status": 1,
        "name": "Juancho E. Yrausquin Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "17.65",
        "size": "small"
    },
    {
        "iata": "AXA",
        "lon": "-63.066666",
        "iso": "AI",
        "status": 1,
        "name": "Wallblake Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.216667",
        "size": "medium"
    },
    {
        "iata": "MNI",
        "lon": "-62.233334",
        "iso": "MS",
        "status": 1,
        "name": "John A. Osborne Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "16.75",
        "size": "medium"
    },
    {
        "iata": "TAB",
        "lon": "-60.839684",
        "iso": "TT",
        "status": 1,
        "name": "Tobago-Crown Point Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "11.152541",
        "size": "medium"
    },
    {
        "iata": "POS",
        "lon": "-61.339413",
        "iso": "TT",
        "status": 1,
        "name": "Piarco International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "10.602089",
        "size": "medium"
    },
    {
        "iata": "NGD",
        "lon": "-64.3",
        "iso": "VG",
        "status": 1,
        "name": "Captain Auguste George Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.716667",
        "size": "small"
    },
    {
        "iata": "EIS",
        "lon": "-64.54278",
        "iso": "VG",
        "status": 1,
        "name": "Terrance B. Lettsome International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.44389",
        "size": "medium"
    },
    {
        "iata": "VIJ",
        "lon": "-64.427734",
        "iso": "VG",
        "status": 1,
        "name": "Virgin Gorda Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "18.45027",
        "size": "small"
    },
    {
        "iata": "BR-",
        "iso": "BR",
        "status": 0,
        "name": "Tucuma Airport",
        "continent": "SA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "BQU",
        "lon": "-61.264168",
        "iso": "VC",
        "status": 1,
        "name": "J F Mitchell Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.986667",
        "size": "medium"
    },
    {
        "iata": "CIW",
        "lon": "-61.316666",
        "iso": "VC",
        "status": 1,
        "name": "Canouan Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.7",
        "size": "medium"
    },
    {
        "iata": "MQS",
        "lon": "-61.181946",
        "iso": "VC",
        "status": 1,
        "name": "Mustique Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "12.888333",
        "size": "medium"
    },
    {
        "iata": "UNI",
        "lon": "-61.0",
        "iso": "VC",
        "status": 1,
        "name": "Union Island International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.5",
        "size": "medium"
    },
    {
        "iata": "SVD",
        "lon": "-61.21038",
        "iso": "VC",
        "status": 1,
        "name": "E. T. Joshua Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "13.14604",
        "size": "medium"
    },
    {
        "iata": "CMJ",
        "lon": "119.416664",
        "iso": "TW",
        "status": 1,
        "name": "Chi Mei Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.25",
        "size": "small"
    },
    {
        "iata": "TWH",
        "iso": "US",
        "status": 1,
        "name": "Two Harbors Amphibious Terminal",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "BDA",
        "lon": "-64.70115",
        "iso": "BM",
        "status": 1,
        "name": "L.F. Wade International International Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "32.35994",
        "size": "medium"
    },
    {
        "iata": "GIT",
        "lon": "32.2",
        "iso": "TZ",
        "status": 1,
        "name": "Geita Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-2.8",
        "size": "small"
    },
    {
        "iata": "LUY",
        "lon": "-7.551944",
        "iso": "TZ",
        "status": 1,
        "name": "Lushoto Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "43.015",
        "size": "small"
    },
    {
        "iata": "ALA",
        "lon": "77.01145",
        "iso": "KZ",
        "status": 1,
        "name": "Almaty Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.346653",
        "size": "large"
    },
    {
        "iata": "BXH",
        "lon": "75.01667",
        "iso": "KZ",
        "status": 1,
        "name": "Balkhash Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.88333",
        "size": "medium"
    },
    {
        "iata": "BXJ",
        "lon": "76.95",
        "iso": "KZ",
        "status": 1,
        "name": "Boralday Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.5",
        "size": "small"
    },
    {
        "iata": "TSE",
        "lon": "71.4612",
        "iso": "KZ",
        "status": 1,
        "name": "Astana International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "51.02781",
        "size": "large"
    },
    {
        "iata": "KOV",
        "lon": "69.4",
        "iso": "KZ",
        "status": 1,
        "name": "Kokshetau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.316666",
        "size": "medium"
    },
    {
        "iata": "PPK",
        "lon": "69.183334",
        "iso": "KZ",
        "status": 1,
        "name": "Petropavlosk South Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "54.783333",
        "size": "medium"
    },
    {
        "iata": "DMB",
        "lon": "71.304596",
        "iso": "KZ",
        "status": 1,
        "name": "Taraz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.855373",
        "size": "medium"
    },
    {
        "iata": "UAE",
        "lon": "144.65",
        "iso": "PG",
        "status": 1,
        "name": "Mount Aue Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-6.233333",
        "size": "small"
    },
    {
        "iata": "FRU",
        "lon": "74.46945",
        "iso": "KG",
        "status": 1,
        "name": "Manas International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.05358",
        "size": "large"
    },
    {
        "iata": "OSS",
        "lon": "72.78648",
        "iso": "KG",
        "status": 1,
        "name": "Osh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.60769",
        "size": "medium"
    },
    {
        "iata": "CIT",
        "lon": "69.6",
        "iso": "KZ",
        "status": 1,
        "name": "Shymkent Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.3",
        "size": "medium"
    },
    {
        "iata": "DZN",
        "lon": "67.73333",
        "iso": "KZ",
        "status": 1,
        "name": "Zhezkazgan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "47.7",
        "size": "medium"
    },
    {
        "iata": "KGF",
        "lon": "73.32836",
        "iso": "KZ",
        "status": 1,
        "name": "Sary-Arka Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "49.67526",
        "size": "large"
    },
    {
        "iata": "KZO",
        "lon": "65.59288",
        "iso": "KZ",
        "status": 1,
        "name": "Kzyl-Orda Southwest Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "44.712223",
        "size": "small"
    },
    {
        "iata": "URA",
        "lon": "51.538563",
        "iso": "KZ",
        "status": 1,
        "name": "Uralsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "51.15371",
        "size": "medium"
    },
    {
        "iata": "EKB",
        "lon": "75.21667",
        "iso": "KZ",
        "status": 1,
        "name": "Ekibastuz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "51.583332",
        "size": "medium"
    },
    {
        "iata": "UKK",
        "lon": "82.5056",
        "iso": "KZ",
        "status": 1,
        "name": "Ust-Kamennogorsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.025723",
        "size": "medium"
    },
    {
        "iata": "PWQ",
        "lon": "77.066666",
        "iso": "KZ",
        "status": 1,
        "name": "Pavlodar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "52.183334",
        "size": "medium"
    },
    {
        "iata": "DLX",
        "iso": "KZ",
        "status": 1,
        "name": "Semipalatinsk Airport",
        "continent": "AS",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "SCO",
        "lon": "51.1",
        "iso": "KZ",
        "status": 1,
        "name": "Aktau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.86667",
        "size": "medium"
    },
    {
        "iata": "GUW",
        "lon": "51.829556",
        "iso": "KZ",
        "status": 1,
        "name": "Atyrau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "47.122814",
        "size": "medium"
    },
    {
        "iata": "AKX",
        "lon": "57.211403",
        "iso": "KZ",
        "status": 1,
        "name": "Aktobe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.249336",
        "size": "medium"
    },
    {
        "iata": "AYK",
        "lon": "66.96667",
        "iso": "KZ",
        "status": 1,
        "name": "Arkalyk North Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.316666",
        "size": "small"
    },
    {
        "iata": "KSN",
        "lon": "63.55",
        "iso": "KZ",
        "status": 1,
        "name": "Kostanay West Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.2",
        "size": "medium"
    },
    {
        "iata": "GYD",
        "lon": "50.05039",
        "iso": "AZ",
        "status": 1,
        "name": "Heydar Aliyev International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.462486",
        "size": "large"
    },
    {
        "iata": "KVD",
        "lon": "46.316666",
        "iso": "AZ",
        "status": 1,
        "name": "Ganja Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.733334",
        "size": "medium"
    },
    {
        "iata": "NAJ",
        "lon": "45.45889",
        "iso": "AZ",
        "status": 1,
        "name": "Nakhchivan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.190277",
        "size": "medium"
    },
    {
        "iata": "GBB",
        "lon": "47.719048",
        "iso": "AZ",
        "status": 1,
        "name": "Gabala International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.82116",
        "size": "medium"
    },
    {
        "iata": "ZTU",
        "lon": "46.664787",
        "iso": "AZ",
        "status": 1,
        "name": "Zaqatala International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.56166",
        "size": "small"
    },
    {
        "iata": "LWN",
        "lon": "43.833332",
        "iso": "AM",
        "status": 1,
        "name": "Gyumri Shirak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.716667",
        "size": "medium"
    },
    {
        "iata": "EVN",
        "lon": "44.39805",
        "iso": "AM",
        "status": 1,
        "name": "Zvartnots International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.15272",
        "size": "large"
    },
    {
        "iata": "ADH",
        "lon": "125.4061",
        "iso": "RU",
        "status": 1,
        "name": "Aldan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "58.601215",
        "size": "small"
    },
    {
        "iata": "YKS",
        "lon": "129.75006",
        "iso": "RU",
        "status": 1,
        "name": "Yakutsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "62.085606",
        "size": "medium"
    },
    {
        "iata": "CNN",
        "lon": "124.88333",
        "iso": "RU",
        "status": 1,
        "name": "Chulman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.9",
        "size": "medium"
    },
    {
        "iata": "ULK",
        "lon": "114.83152",
        "iso": "RU",
        "status": 1,
        "name": "Lensk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "60.72248",
        "size": "medium"
    },
    {
        "iata": "PYJ",
        "lon": "112.05",
        "iso": "RU",
        "status": 1,
        "name": "Polyarny Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "66.416664",
        "size": "medium"
    },
    {
        "iata": "MJZ",
        "lon": "114.03333",
        "iso": "RU",
        "status": 1,
        "name": "Mirny Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "62.533333",
        "size": "medium"
    },
    {
        "iata": "CKH",
        "lon": "147.88333",
        "iso": "RU",
        "status": 1,
        "name": "Chokurdakh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "70.63333",
        "size": "medium"
    },
    {
        "iata": "CYX",
        "lon": "161.35",
        "iso": "RU",
        "status": 1,
        "name": "Cherskiy Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "68.75",
        "size": "medium"
    },
    {
        "iata": "IKS",
        "lon": "128.9",
        "iso": "RU",
        "status": 1,
        "name": "Tiksi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "71.7",
        "size": "medium"
    },
    {
        "iata": "ENK",
        "lon": "-7.633333",
        "iso": "RU",
        "status": 1,
        "name": "Zyryanka Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "54.35",
        "size": "medium"
    },
    {
        "iata": "OYG",
        "lon": "31.716667",
        "iso": "UG",
        "status": 1,
        "name": "Moyo Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "3.65",
        "size": "small"
    },
    {
        "iata": "UGB",
        "lon": "-157.74422",
        "iso": "US",
        "status": 1,
        "name": "Ugashik Bay Airport",
        "continent": "NA",
        "type": "airport",
        "lat": "57.42529",
        "size": "small"
    },
    {
        "iata": "KUT",
        "lon": "42.7",
        "iso": "GE",
        "status": 1,
        "name": "Kopitnari Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.25",
        "size": "medium"
    },
    {
        "iata": "BUS",
        "lon": "41.600555",
        "iso": "GE",
        "status": 1,
        "name": "Batumi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.610832",
        "size": "medium"
    },
    {
        "iata": "SUI",
        "lon": "41.11667",
        "iso": "GE",
        "status": 1,
        "name": "Sukhumi Dranda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.86667",
        "size": "medium"
    },
    {
        "iata": "TBS",
        "lon": "44.958958",
        "iso": "GE",
        "status": 1,
        "name": "Tbilisi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.674065",
        "size": "large"
    },
    {
        "iata": "BQS",
        "lon": "127.4",
        "iso": "RU",
        "status": 1,
        "name": "Ignatyevo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.416668",
        "size": "medium"
    },
    {
        "iata": "GDG",
        "lon": "125.8",
        "iso": "RU",
        "status": 1,
        "name": "Magdagachi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.466667",
        "size": "small"
    },
    {
        "iata": "TYD",
        "lon": "124.73333",
        "iso": "RU",
        "status": 1,
        "name": "Tynda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "55.283333",
        "size": "small"
    },
    {
        "iata": "KHV",
        "lon": "135.16861",
        "iso": "RU",
        "status": 1,
        "name": "Khabarovsk-Novy Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "48.524563",
        "size": "medium"
    },
    {
        "iata": "KXK",
        "lon": "136.95",
        "iso": "RU",
        "status": 1,
        "name": "Komsomolsk-on-Amur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.4",
        "size": "medium"
    },
    {
        "iata": "DYR",
        "lon": "177.75",
        "iso": "RU",
        "status": 1,
        "name": "Ugolny Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "64.73333",
        "size": "medium"
    },
    {
        "iata": "PVS",
        "lon": "-173.24333",
        "iso": "RU",
        "status": 1,
        "name": "Provideniya Bay Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.38333",
        "size": "medium"
    },
    {
        "iata": "GDX",
        "lon": "150.71666",
        "iso": "RU",
        "status": 1,
        "name": "Sokol Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "59.916668",
        "size": "medium"
    },
    {
        "iata": "PWE",
        "lon": "170.6",
        "iso": "RU",
        "status": 1,
        "name": "Pevek Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "69.78333",
        "size": "medium"
    },
    {
        "iata": "BQG",
        "lon": "140.44833",
        "iso": "RU",
        "status": 1,
        "name": "Bogorodskoye Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.378334",
        "size": "small"
    },
    {
        "iata": "OHO",
        "lon": "143.05",
        "iso": "RU",
        "status": 1,
        "name": "Okhotsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "59.416668",
        "size": "medium"
    },
    {
        "iata": "PKC",
        "lon": "158.65",
        "iso": "RU",
        "status": 1,
        "name": "Yelizovo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.016666",
        "size": "medium"
    },
    {
        "iata": "OHH",
        "lon": "142.88333",
        "iso": "RU",
        "status": 1,
        "name": "Okha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.516666",
        "size": "small"
    },
    {
        "iata": "EKS",
        "lon": "-111.65034",
        "iso": "RU",
        "status": 1,
        "name": "Shakhtyorsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.267853",
        "size": "small"
    },
    {
        "iata": "DEE",
        "lon": "145.683",
        "iso": "RU",
        "status": 1,
        "name": "Mendeleyevo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.9584",
        "size": "small"
    },
    {
        "iata": "ZZO",
        "lon": "142.75",
        "iso": "RU",
        "status": 1,
        "name": "Zonalnoye Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.666668",
        "size": "small"
    },
    {
        "iata": "UUS",
        "lon": "142.75",
        "iso": "RU",
        "status": 1,
        "name": "Yuzhno-Sakhalinsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.966667",
        "size": "medium"
    },
    {
        "iata": "VVO",
        "lon": "132.14075",
        "iso": "RU",
        "status": 1,
        "name": "Vladivostok International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.378574",
        "size": "medium"
    },
    {
        "iata": "HTA",
        "lon": "113.3",
        "iso": "RU",
        "status": 1,
        "name": "Chita-Kadala Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "52.033333",
        "size": "medium"
    },
    {
        "iata": "BTK",
        "lon": "101.816666",
        "iso": "RU",
        "status": 1,
        "name": "Bratsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.36667",
        "size": "medium"
    },
    {
        "iata": "UIK",
        "lon": "102.55",
        "iso": "RU",
        "status": 1,
        "name": "Ust-Ilimsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "58.13333",
        "size": "small"
    },
    {
        "iata": "IKT",
        "lon": "104.35607",
        "iso": "RU",
        "status": 1,
        "name": "Irkutsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "52.273308",
        "size": "medium"
    },
    {
        "iata": "ODO",
        "lon": "114.2425",
        "iso": "RU",
        "status": 1,
        "name": "Bodaybo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "57.86639",
        "size": "small"
    },
    {
        "iata": "ERG",
        "lon": "108.03",
        "iso": "RU",
        "status": 1,
        "name": "Yerbogachen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "61.275",
        "size": "small"
    },
    {
        "iata": "UKX",
        "lon": "105.73333",
        "iso": "RU",
        "status": 1,
        "name": "Ust-Kut Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.85",
        "size": "medium"
    },
    {
        "iata": "UUD",
        "lon": "107.5",
        "iso": "RU",
        "status": 1,
        "name": "Ulan-Ude Airport (Mukhino)",
        "continent": "AS",
        "type": "airport",
        "lat": "51.833332",
        "size": "medium"
    },
    {
        "iata": "UJE",
        "lon": "165.66667",
        "iso": "MH",
        "status": 1,
        "name": "Ujae Atoll Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "9.083333",
        "size": "small"
    },
    {
        "iata": "UJN",
        "iso": "KR",
        "status": 1,
        "name": "Uljin Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "KBP",
        "lon": "30.895206",
        "iso": "UA",
        "status": 1,
        "name": "Boryspil International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.341244",
        "size": "large"
    },
    {
        "iata": "DOK",
        "lon": "37.75",
        "iso": "UA",
        "status": 1,
        "name": "Donetsk International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.083332",
        "size": "large"
    },
    {
        "iata": "KRQ",
        "lon": "37.61667",
        "iso": "UA",
        "status": 1,
        "name": "Kramatorsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.716667",
        "size": "small"
    },
    {
        "iata": "MPW",
        "lon": "37.451187",
        "iso": "UA",
        "status": 1,
        "name": "Mariupol International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.078846",
        "size": "medium"
    },
    {
        "iata": "SEV",
        "lon": "38.55",
        "iso": "UA",
        "status": 1,
        "name": "Sievierodonetsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.9",
        "size": "small"
    },
    {
        "iata": "VSG",
        "lon": "39.333332",
        "iso": "UA",
        "status": 1,
        "name": "Luhansk International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.566666",
        "size": "medium"
    },
    {
        "iata": "ERD",
        "lon": "36.783333",
        "iso": "UA",
        "status": 1,
        "name": "Berdyansk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.733334",
        "size": "small"
    },
    {
        "iata": "DNK",
        "lon": "35.09446",
        "iso": "UA",
        "status": 1,
        "name": "Dnipropetrovsk International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.36874",
        "size": "medium"
    },
    {
        "iata": "OZH",
        "lon": "35.315834",
        "iso": "UA",
        "status": 1,
        "name": "Zaporizhzhia International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.8675",
        "size": "medium"
    },
    {
        "iata": "KWG",
        "lon": "33.216667",
        "iso": "UA",
        "status": 1,
        "name": "Kryvyi Rih International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.05",
        "size": "medium"
    },
    {
        "iata": "UKS",
        "lon": "33.576668",
        "iso": "UA",
        "status": 1,
        "name": "Belbek Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.691666",
        "size": "medium"
    },
    {
        "iata": "SIP",
        "lon": "33.99819",
        "iso": "UA",
        "status": 1,
        "name": "Simferopol International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.020657",
        "size": "large"
    },
    {
        "iata": "KHC",
        "lon": "36.45",
        "iso": "UA",
        "status": 1,
        "name": "Kerch Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.36667",
        "size": "small"
    },
    {
        "iata": "UKH",
        "iso": "OM",
        "status": 1,
        "name": "Mukhaizna Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "HRK",
        "lon": "36.281185",
        "iso": "UA",
        "status": 1,
        "name": "Kharkiv International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.92078",
        "size": "large"
    },
    {
        "iata": "PLV",
        "lon": "34.533333",
        "iso": "UA",
        "status": 1,
        "name": "Suprunovka Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.583332",
        "size": "small"
    },
    {
        "iata": "UMY",
        "lon": "34.783333",
        "iso": "UA",
        "status": 1,
        "name": "Sumy Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.933334",
        "size": "small"
    },
    {
        "iata": "CKC",
        "lon": "32.0",
        "iso": "UA",
        "status": 1,
        "name": "Cherkasy International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.416668",
        "size": "medium"
    },
    {
        "iata": "KGO",
        "lon": "32.3",
        "iso": "UA",
        "status": 1,
        "name": "Kirovograd Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.55",
        "size": "small"
    },
    {
        "iata": "IEV",
        "lon": "30.450832",
        "iso": "UA",
        "status": 1,
        "name": "Kiev Zhuliany International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.401943",
        "size": "medium"
    },
    {
        "iata": "GML",
        "iso": "UA",
        "status": 1,
        "name": "Gostomel Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "UCK",
        "lon": "25.333332",
        "iso": "UA",
        "status": 1,
        "name": "Lutsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.75",
        "size": "small"
    },
    {
        "iata": "HMJ",
        "lon": "27.0",
        "iso": "UA",
        "status": 1,
        "name": "Khmelnytskyi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.416668",
        "size": "medium"
    },
    {
        "iata": "IFO",
        "lon": "24.707565",
        "iso": "UA",
        "status": 1,
        "name": "Ivano-Frankivsk International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.887535",
        "size": "medium"
    },
    {
        "iata": "LWO",
        "lon": "23.955318",
        "iso": "UA",
        "status": 1,
        "name": "Lviv International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.816418",
        "size": "medium"
    },
    {
        "iata": "CWC",
        "lon": "25.966667",
        "iso": "UA",
        "status": 1,
        "name": "Chernivtsi International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.266666",
        "size": "medium"
    },
    {
        "iata": "RWN",
        "lon": "26.15",
        "iso": "UA",
        "status": 1,
        "name": "Rivne International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.6",
        "size": "medium"
    },
    {
        "iata": "TNL",
        "lon": "25.6",
        "iso": "UA",
        "status": 1,
        "name": "Ternopil International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.566666",
        "size": "small"
    },
    {
        "iata": "UDJ",
        "lon": "22.273214",
        "iso": "UA",
        "status": 1,
        "name": "Uzhhorod International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.63762",
        "size": "medium"
    },
    {
        "iata": "KHE",
        "lon": "32.51268",
        "iso": "UA",
        "status": 1,
        "name": "Chernobayevka Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.67822",
        "size": "small"
    },
    {
        "iata": "NLV",
        "lon": "31.916668",
        "iso": "UA",
        "status": 1,
        "name": "Mykolaiv International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.05",
        "size": "medium"
    },
    {
        "iata": "ODS",
        "lon": "30.676718",
        "iso": "UA",
        "status": 1,
        "name": "Odessa International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.44101",
        "size": "large"
    },
    {
        "iata": "VIN",
        "lon": "28.6112",
        "iso": "UA",
        "status": 1,
        "name": "Vinnytsia/Gavyryshivka Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "49.243427",
        "size": "small"
    },
    {
        "iata": "ARH",
        "lon": "40.711903",
        "iso": "RU",
        "status": 1,
        "name": "Talagi Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "64.594795",
        "size": "medium"
    },
    {
        "iata": "NNM",
        "lon": "53.15",
        "iso": "RU",
        "status": 1,
        "name": "Naryan Mar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "67.61667",
        "size": "medium"
    },
    {
        "iata": "CSH",
        "lon": "35.733334",
        "iso": "RU",
        "status": 1,
        "name": "Solovki Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.02944",
        "size": "small"
    },
    {
        "iata": "CEE",
        "lon": "38.066666",
        "iso": "RU",
        "status": 1,
        "name": "Cherepovets Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.283333",
        "size": "medium"
    },
    {
        "iata": "AMV",
        "lon": "61.55",
        "iso": "RU",
        "status": 1,
        "name": "Amderma Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "69.76667",
        "size": "medium"
    },
    {
        "iata": "ULH",
        "lon": "38.116653",
        "iso": "SA",
        "status": 1,
        "name": "Majeed Bin Abdulaziz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.480862",
        "size": "medium"
    },
    {
        "iata": "KSZ",
        "lon": "46.7",
        "iso": "RU",
        "status": 1,
        "name": "Kotlas Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.233334",
        "size": "medium"
    },
    {
        "iata": "LED",
        "lon": "30.3083",
        "iso": "RU",
        "status": 1,
        "name": "Pulkovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.806084",
        "size": "large"
    },
    {
        "iata": "KVK",
        "lon": "33.583332",
        "iso": "RU",
        "status": 1,
        "name": "Kirovsk-Apatity Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "67.583336",
        "size": "small"
    },
    {
        "iata": "MMK",
        "lon": "32.759155",
        "iso": "RU",
        "status": 1,
        "name": "Murmansk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "68.785095",
        "size": "medium"
    },
    {
        "iata": "NVR",
        "iso": "RU",
        "status": 0,
        "name": "Novgorod Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "VLU",
        "lon": "30.616667",
        "iso": "RU",
        "status": 1,
        "name": "Velikiye Luki Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.38333",
        "size": "medium"
    },
    {
        "iata": "PKV",
        "lon": "28.3",
        "iso": "RU",
        "status": 1,
        "name": "Pskov Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.816666",
        "size": "medium"
    },
    {
        "iata": "PES",
        "lon": "34.333332",
        "iso": "RU",
        "status": 1,
        "name": "Petrozavodsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.683334",
        "size": "medium"
    },
    {
        "iata": "RVH",
        "iso": "RU",
        "status": 0,
        "name": "Rzhevka Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "VGD",
        "lon": "39.95",
        "iso": "RU",
        "status": 1,
        "name": "Vologda Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "59.283333",
        "size": "medium"
    },
    {
        "iata": "BQT",
        "lon": "23.883333",
        "iso": "BY",
        "status": 1,
        "name": "Brest Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.11667",
        "size": "medium"
    },
    {
        "iata": "GME",
        "lon": "31.017895",
        "iso": "BY",
        "status": 1,
        "name": "Gomel Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.526073",
        "size": "medium"
    },
    {
        "iata": "VTB",
        "lon": "30.366667",
        "iso": "BY",
        "status": 1,
        "name": "Vitebsk East Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.13333",
        "size": "medium"
    },
    {
        "iata": "KGD",
        "lon": "20.586645",
        "iso": "RU",
        "status": 1,
        "name": "Khrabrovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.882656",
        "size": "medium"
    },
    {
        "iata": "GNA",
        "lon": "24.053905",
        "iso": "BY",
        "status": 1,
        "name": "Hrodna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.6058",
        "size": "medium"
    },
    {
        "iata": "MHP",
        "lon": "27.55",
        "iso": "BY",
        "status": 1,
        "name": "Minsk 1 Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.85",
        "size": "medium"
    },
    {
        "iata": "MSQ",
        "lon": "28.032442",
        "iso": "BY",
        "status": 1,
        "name": "Minsk International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.889725",
        "size": "large"
    },
    {
        "iata": "MVQ",
        "lon": "30.133333",
        "iso": "BY",
        "status": 1,
        "name": "Mogilev Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.95",
        "size": "medium"
    },
    {
        "iata": "ABA",
        "lon": "91.5",
        "iso": "RU",
        "status": 1,
        "name": "Abakan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.716667",
        "size": "medium"
    },
    {
        "iata": "BAX",
        "lon": "83.547646",
        "iso": "RU",
        "status": 1,
        "name": "Barnaul Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.361088",
        "size": "medium"
    },
    {
        "iata": "KEJ",
        "lon": "86.11623",
        "iso": "RU",
        "status": 1,
        "name": "Kemerovo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "55.280567",
        "size": "medium"
    },
    {
        "iata": "EIE",
        "lon": "92.11667",
        "iso": "RU",
        "status": 1,
        "name": "Yeniseysk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "58.466667",
        "size": "medium"
    },
    {
        "iata": "KJA",
        "lon": "92.48286",
        "iso": "RU",
        "status": 1,
        "name": "Yemelyanovo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.18113",
        "size": "large"
    },
    {
        "iata": "ACS",
        "lon": "90.566666",
        "iso": "RU",
        "status": 1,
        "name": "Achinsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.266666",
        "size": "medium"
    },
    {
        "iata": "KYZ",
        "lon": "94.46667",
        "iso": "RU",
        "status": 1,
        "name": "Kyzyl Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "51.7",
        "size": "medium"
    },
    {
        "iata": "OVB",
        "lon": "82.667",
        "iso": "RU",
        "status": 1,
        "name": "Tolmachevo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "55.00901",
        "size": "medium"
    },
    {
        "iata": "OMS",
        "lon": "73.3167",
        "iso": "RU",
        "status": 1,
        "name": "Omsk Central Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "54.957455",
        "size": "medium"
    },
    {
        "iata": "TOF",
        "lon": "84.96667",
        "iso": "RU",
        "status": 1,
        "name": "Bogashevo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.5",
        "size": "medium"
    },
    {
        "iata": "NOZ",
        "lon": "87.166664",
        "iso": "RU",
        "status": 1,
        "name": "Spichenkovo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "53.75",
        "size": "medium"
    },
    {
        "iata": "DKS",
        "lon": "80.36667",
        "iso": "RU",
        "status": 1,
        "name": "Dikson Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "73.51667",
        "size": "medium"
    },
    {
        "iata": "HTG",
        "lon": "102.5",
        "iso": "RU",
        "status": 1,
        "name": "Khatanga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "71.96667",
        "size": "medium"
    },
    {
        "iata": "IAA",
        "lon": "86.63333",
        "iso": "RU",
        "status": 1,
        "name": "Igarka Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "67.433334",
        "size": "medium"
    },
    {
        "iata": "NSK",
        "lon": "87.339806",
        "iso": "RU",
        "status": 1,
        "name": "Norilsk-Alykel Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "69.32312",
        "size": "medium"
    },
    {
        "iata": "AAQ",
        "lon": "37.3466",
        "iso": "RU",
        "status": 1,
        "name": "Anapa Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.00166",
        "size": "medium"
    },
    {
        "iata": "EIK",
        "iso": "RU",
        "status": 1,
        "name": "Yeysk Airport",
        "continent": "EU",
        "type": "airport",
        "size": "medium"
    },
    {
        "iata": "GDZ",
        "lon": "38.016666",
        "iso": "RU",
        "status": 1,
        "name": "Gelendzhik Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.566666",
        "size": "medium"
    },
    {
        "iata": "KRR",
        "lon": "39.139004",
        "iso": "RU",
        "status": 1,
        "name": "Krasnodar International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.034138",
        "size": "medium"
    },
    {
        "iata": "MCX",
        "lon": "47.65611",
        "iso": "RU",
        "status": 1,
        "name": "Uytash Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "42.82095",
        "size": "medium"
    },
    {
        "iata": "MRV",
        "lon": "43.085815",
        "iso": "RU",
        "status": 1,
        "name": "Mineralnyye Vody Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "44.218105",
        "size": "medium"
    },
    {
        "iata": "NAL",
        "lon": "43.7",
        "iso": "RU",
        "status": 1,
        "name": "Nalchik Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.533333",
        "size": "medium"
    },
    {
        "iata": "OGZ",
        "lon": "44.6",
        "iso": "RU",
        "status": 1,
        "name": "Beslan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.2",
        "size": "medium"
    },
    {
        "iata": "IGT",
        "lon": "45.01261",
        "iso": "RU",
        "status": 1,
        "name": "Magas Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.322124",
        "size": "medium"
    },
    {
        "iata": "STW",
        "lon": "42.00833",
        "iso": "RU",
        "status": 1,
        "name": "Stavropol Shpakovskoye Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "45.333332",
        "size": "medium"
    },
    {
        "iata": "ROV",
        "lon": "39.75",
        "iso": "RU",
        "status": 1,
        "name": "Rostov-na-Donu Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.25",
        "size": "medium"
    },
    {
        "iata": "VLK",
        "lon": "42.083332",
        "iso": "RU",
        "status": 1,
        "name": "Volgodonsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "47.683334",
        "size": "medium"
    },
    {
        "iata": "AER",
        "lon": "39.941105",
        "iso": "RU",
        "status": 1,
        "name": "Sochi International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "43.44884",
        "size": "large"
    },
    {
        "iata": "ASF",
        "lon": "47.999977",
        "iso": "RU",
        "status": 1,
        "name": "Astrakhan Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.2877",
        "size": "medium"
    },
    {
        "iata": "ESL",
        "lon": "44.332966",
        "iso": "RU",
        "status": 1,
        "name": "Elista Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "46.370594",
        "size": "medium"
    },
    {
        "iata": "VOG",
        "lon": "44.354805",
        "iso": "RU",
        "status": 1,
        "name": "Volgograd International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "48.792",
        "size": "medium"
    },
    {
        "iata": "AHT",
        "iso": "US",
        "status": 0,
        "name": "Amchitka Army Airfield",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "CEK",
        "lon": "61.51235",
        "iso": "RU",
        "status": 1,
        "name": "Chelyabinsk Balandino Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "55.297504",
        "size": "medium"
    },
    {
        "iata": "MQF",
        "lon": "59.066666",
        "iso": "RU",
        "status": 1,
        "name": "Magnitogorsk International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.45",
        "size": "medium"
    },
    {
        "iata": "SLY",
        "lon": "66.6",
        "iso": "RU",
        "status": 1,
        "name": "Salekhard Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "66.583336",
        "size": "medium"
    },
    {
        "iata": "YMK",
        "lon": "73.59573",
        "iso": "RU",
        "status": 1,
        "name": "Mys Kamenny Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "68.46843",
        "size": "small"
    },
    {
        "iata": "UEN",
        "iso": "RU",
        "status": 1,
        "name": "Urengoy Airport",
        "continent": "EU",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "EZV",
        "lon": "65.04485",
        "iso": "RU",
        "status": 1,
        "name": "Berezovo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "63.924583",
        "size": "medium"
    },
    {
        "iata": "HMA",
        "lon": "69.09714",
        "iso": "RU",
        "status": 1,
        "name": "Khanty Mansiysk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "61.02613",
        "size": "medium"
    },
    {
        "iata": "IRM",
        "lon": "64.433945",
        "iso": "RU",
        "status": 1,
        "name": "Igrim Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "63.200756",
        "size": "small"
    },
    {
        "iata": "NYA",
        "lon": "65.60563",
        "iso": "RU",
        "status": 1,
        "name": "Nyagan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "62.101074",
        "size": "medium"
    },
    {
        "iata": "OVS",
        "lon": "63.601887",
        "iso": "RU",
        "status": 1,
        "name": "Sovetskiy Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "61.33209",
        "size": "medium"
    },
    {
        "iata": "URJ",
        "lon": "64.833336",
        "iso": "RU",
        "status": 1,
        "name": "Uray Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "60.11667",
        "size": "medium"
    },
    {
        "iata": "EYK",
        "lon": "66.70121",
        "iso": "RU",
        "status": 1,
        "name": "Beloyarskiy Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "63.696682",
        "size": "medium"
    },
    {
        "iata": "IJK",
        "lon": "53.456528",
        "iso": "RU",
        "status": 1,
        "name": "Izhevsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.83045",
        "size": "medium"
    },
    {
        "iata": "KVX",
        "lon": "49.35",
        "iso": "RU",
        "status": 1,
        "name": "Pobedilovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.5",
        "size": "medium"
    },
    {
        "iata": "NYM",
        "lon": "72.71667",
        "iso": "RU",
        "status": 1,
        "name": "Nadym Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "65.48333",
        "size": "medium"
    },
    {
        "iata": "NUX",
        "lon": "76.522835",
        "iso": "RU",
        "status": 1,
        "name": "Novy Urengoy Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "66.07335",
        "size": "medium"
    },
    {
        "iata": "NJC",
        "lon": "76.49145",
        "iso": "RU",
        "status": 1,
        "name": "Nizhnevartovsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "60.9476",
        "size": "medium"
    },
    {
        "iata": "RAT",
        "iso": "RU",
        "status": 0,
        "name": "Raduzhny Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "PEE",
        "lon": "56.01918",
        "iso": "RU",
        "status": 1,
        "name": "Bolshoye Savino Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.920025",
        "size": "medium"
    },
    {
        "iata": "KGP",
        "lon": "74.53361",
        "iso": "RU",
        "status": 1,
        "name": "Kogalym International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "62.19583",
        "size": "medium"
    },
    {
        "iata": "NFG",
        "lon": "73.05",
        "iso": "RU",
        "status": 1,
        "name": "Nefteyugansk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "61.13333",
        "size": "medium"
    },
    {
        "iata": "NOJ",
        "lon": "75.29459",
        "iso": "RU",
        "status": 1,
        "name": "Noyabrsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "63.17673",
        "size": "medium"
    },
    {
        "iata": "SGC",
        "lon": "73.40953",
        "iso": "RU",
        "status": 1,
        "name": "Surgut Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "61.339916",
        "size": "medium"
    },
    {
        "iata": "SVX",
        "lon": "60.804314",
        "iso": "RU",
        "status": 1,
        "name": "Koltsovo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "56.750336",
        "size": "large"
    },
    {
        "iata": "TOX",
        "lon": "68.23333",
        "iso": "RU",
        "status": 1,
        "name": "Tobolsk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "58.13333",
        "size": "small"
    },
    {
        "iata": "TJM",
        "lon": "65.35024",
        "iso": "RU",
        "status": 1,
        "name": "Roshchino International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "57.181828",
        "size": "medium"
    },
    {
        "iata": "KRO",
        "lon": "65.413185",
        "iso": "RU",
        "status": 1,
        "name": "Kurgan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "55.474228",
        "size": "medium"
    },
    {
        "iata": "ASB",
        "lon": "58.366978",
        "iso": "TM",
        "status": 1,
        "name": "Ashgabat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.984184",
        "size": "medium"
    },
    {
        "iata": "KRW",
        "lon": "53.012672",
        "iso": "TM",
        "status": 1,
        "name": "Turkmenbashi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.056156",
        "size": "medium"
    },
    {
        "iata": "MYP",
        "lon": "61.833332",
        "iso": "TM",
        "status": 1,
        "name": "Mary Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.666668",
        "size": "medium"
    },
    {
        "iata": "TAZ",
        "lon": "59.966667",
        "iso": "TM",
        "status": 1,
        "name": "Da?oguz Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.833332",
        "size": "medium"
    },
    {
        "iata": "CRZ",
        "lon": "63.61667",
        "iso": "TM",
        "status": 1,
        "name": "Turkmenabat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.083332",
        "size": "medium"
    },
    {
        "iata": "DYU",
        "lon": "68.81728",
        "iso": "TJ",
        "status": 1,
        "name": "Dushanbe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.54894",
        "size": "medium"
    },
    {
        "iata": "TJU",
        "lon": "69.80666",
        "iso": "TJ",
        "status": 1,
        "name": "Kulob Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.988335",
        "size": "medium"
    },
    {
        "iata": "LBD",
        "lon": "69.6965",
        "iso": "TJ",
        "status": 1,
        "name": "Khudzhand Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.219887",
        "size": "medium"
    },
    {
        "iata": "KQT",
        "lon": "68.86513",
        "iso": "TJ",
        "status": 1,
        "name": "Qurghonteppa International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.865623",
        "size": "medium"
    },
    {
        "iata": "AZN",
        "lon": "72.3",
        "iso": "UZ",
        "status": 1,
        "name": "Andizhan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.733334",
        "size": "small"
    },
    {
        "iata": "FEG",
        "lon": "71.73361",
        "iso": "UZ",
        "status": 1,
        "name": "Fergana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.350277",
        "size": "small"
    },
    {
        "iata": "NMA",
        "lon": "71.6",
        "iso": "UZ",
        "status": 1,
        "name": "Namangan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.983334",
        "size": "small"
    },
    {
        "iata": "NCU",
        "lon": "59.63333",
        "iso": "UZ",
        "status": 1,
        "name": "Nukus Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.483334",
        "size": "small"
    },
    {
        "iata": "UGC",
        "lon": "60.63303",
        "iso": "UZ",
        "status": 1,
        "name": "Urgench Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.584835",
        "size": "medium"
    },
    {
        "iata": "NVI",
        "lon": "65.159164",
        "iso": "UZ",
        "status": 1,
        "name": "Navoi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.115",
        "size": "small"
    },
    {
        "iata": "BHK",
        "lon": "64.47479",
        "iso": "UZ",
        "status": 1,
        "name": "Bukhara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.76037",
        "size": "medium"
    },
    {
        "iata": "KSQ",
        "lon": "65.773056",
        "iso": "UZ",
        "status": 1,
        "name": "Karshi Khanabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.8025",
        "size": "small"
    },
    {
        "iata": "AFS",
        "lon": "64.233055",
        "iso": "UZ",
        "status": 1,
        "name": "Sugraly Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.61361",
        "size": "small"
    },
    {
        "iata": "SKD",
        "lon": "66.99085",
        "iso": "UZ",
        "status": 1,
        "name": "Samarkand Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.696217",
        "size": "medium"
    },
    {
        "iata": "TMJ",
        "lon": "67.318954",
        "iso": "UZ",
        "status": 1,
        "name": "Termez Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.280502",
        "size": "medium"
    },
    {
        "iata": "TAS",
        "lon": "69.26619",
        "iso": "UZ",
        "status": 1,
        "name": "Tashkent International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.262714",
        "size": "large"
    },
    {
        "iata": "KMW",
        "lon": "40.933334",
        "iso": "RU",
        "status": 1,
        "name": "Kostroma Sokerkino Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.75",
        "size": "medium"
    },
    {
        "iata": "BKA",
        "lon": "37.966667",
        "iso": "RU",
        "status": 1,
        "name": "Bykovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.433334",
        "size": "medium"
    },
    {
        "iata": "IWA",
        "lon": "40.944546",
        "iso": "RU",
        "status": 1,
        "name": "Ivanovo South Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.942955",
        "size": "medium"
    },
    {
        "iata": "RYB",
        "lon": "38.86667",
        "iso": "RU",
        "status": 1,
        "name": "Staroselye Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "58.13333",
        "size": "medium"
    },
    {
        "iata": "BZK",
        "lon": "34.333332",
        "iso": "RU",
        "status": 1,
        "name": "Bryansk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.266666",
        "size": "medium"
    },
    {
        "iata": "DME",
        "lon": "37.899494",
        "iso": "RU",
        "status": 1,
        "name": "Domodedovo International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.414566",
        "size": "large"
    },
    {
        "iata": "IAR",
        "lon": "40.160892",
        "iso": "RU",
        "status": 1,
        "name": "Tunoshna Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.559753",
        "size": "small"
    },
    {
        "iata": "SVO",
        "lon": "37.416573",
        "iso": "RU",
        "status": 1,
        "name": "Sheremetyevo International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.966324",
        "size": "large"
    },
    {
        "iata": "KLD",
        "lon": "35.916668",
        "iso": "RU",
        "status": 1,
        "name": "Migalovo Air Base",
        "continent": "EU",
        "type": "airport",
        "lat": "56.916668",
        "size": "medium"
    },
    {
        "iata": "EGO",
        "lon": "36.65",
        "iso": "RU",
        "status": 1,
        "name": "Belgorod International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "50.63333",
        "size": "medium"
    },
    {
        "iata": "URS",
        "lon": "36.266666",
        "iso": "RU",
        "status": 1,
        "name": "Kursk East Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.75",
        "size": "medium"
    },
    {
        "iata": "LPK",
        "lon": "39.53822",
        "iso": "RU",
        "status": 1,
        "name": "Lipetsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.70341",
        "size": "medium"
    },
    {
        "iata": "VOZ",
        "lon": "39.226997",
        "iso": "RU",
        "status": 1,
        "name": "Voronezh International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.812355",
        "size": "medium"
    },
    {
        "iata": "OEL",
        "lon": "36.1",
        "iso": "RU",
        "status": 1,
        "name": "Oryol Yuzhny Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.983334",
        "size": "medium"
    },
    {
        "iata": "TBW",
        "lon": "41.433334",
        "iso": "RU",
        "status": 1,
        "name": "Donskoye Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "52.716667",
        "size": "medium"
    },
    {
        "iata": "RZN",
        "lon": "39.583332",
        "iso": "RU",
        "status": 1,
        "name": "Turlatovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.63333",
        "size": "small"
    },
    {
        "iata": "VKO",
        "lon": "37.2921",
        "iso": "RU",
        "status": 1,
        "name": "Vnukovo International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.60315",
        "size": "medium"
    },
    {
        "iata": "UCT",
        "lon": "53.8",
        "iso": "RU",
        "status": 1,
        "name": "Ukhta Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "63.566666",
        "size": "medium"
    },
    {
        "iata": "INA",
        "lon": "60.1",
        "iso": "RU",
        "status": 1,
        "name": "Inta Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "66.066666",
        "size": "medium"
    },
    {
        "iata": "PEX",
        "lon": "57.13333",
        "iso": "RU",
        "status": 1,
        "name": "Pechora Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.11667",
        "size": "medium"
    },
    {
        "iata": "USK",
        "lon": "57.4",
        "iso": "RU",
        "status": 1,
        "name": "Usinsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.95",
        "size": "medium"
    },
    {
        "iata": "VKT",
        "lon": "63.9952",
        "iso": "RU",
        "status": 1,
        "name": "Vorkuta Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "67.48624",
        "size": "medium"
    },
    {
        "iata": "UTS",
        "lon": "52.2",
        "iso": "RU",
        "status": 1,
        "name": "Ust-Tsylma Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "65.433334",
        "size": "medium"
    },
    {
        "iata": "SCW",
        "lon": "50.766666",
        "iso": "RU",
        "status": 1,
        "name": "Syktyvkar Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "61.666668",
        "size": "medium"
    },
    {
        "iata": "GOJ",
        "lon": "43.789764",
        "iso": "RU",
        "status": 1,
        "name": "Nizhny Novgorod International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.218613",
        "size": "medium"
    },
    {
        "iata": "UUA",
        "lon": "52.816666",
        "iso": "RU",
        "status": 1,
        "name": "Bugulma Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.61667",
        "size": "medium"
    },
    {
        "iata": "KZN",
        "lon": "49.29824",
        "iso": "RU",
        "status": 1,
        "name": "Kazan International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.60844",
        "size": "medium"
    },
    {
        "iata": "NBC",
        "lon": "52.092438",
        "iso": "RU",
        "status": 1,
        "name": "Begishevo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "55.56379",
        "size": "medium"
    },
    {
        "iata": "JOK",
        "lon": "47.9",
        "iso": "RU",
        "status": 1,
        "name": "Yoshkar-Ola Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.716667",
        "size": "medium"
    },
    {
        "iata": "CSY",
        "lon": "47.266666",
        "iso": "RU",
        "status": 1,
        "name": "Cheboksary Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.13333",
        "size": "medium"
    },
    {
        "iata": "ULV",
        "lon": "48.226665",
        "iso": "RU",
        "status": 1,
        "name": "Ulyanovsk Baratayevka Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.268333",
        "size": "medium"
    },
    {
        "iata": "ULY",
        "lon": "48.216667",
        "iso": "RU",
        "status": 1,
        "name": "Ulyanovsk East Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.266666",
        "size": "medium"
    },
    {
        "iata": "REN",
        "lon": "55.4567",
        "iso": "RU",
        "status": 1,
        "name": "Orenburg Central Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.79105",
        "size": "medium"
    },
    {
        "iata": "OSW",
        "lon": "58.566666",
        "iso": "RU",
        "status": 1,
        "name": "Orsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.2",
        "size": "medium"
    },
    {
        "iata": "PEZ",
        "lon": "45.02305",
        "iso": "RU",
        "status": 1,
        "name": "Penza Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.11852",
        "size": "medium"
    },
    {
        "iata": "SKX",
        "lon": "45.2161",
        "iso": "RU",
        "status": 1,
        "name": "Saransk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.127632",
        "size": "medium"
    },
    {
        "iata": "BWO",
        "lon": "47.75",
        "iso": "RU",
        "status": 1,
        "name": "Balakovo Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.86667",
        "size": "medium"
    },
    {
        "iata": "RTW",
        "lon": "46.066666",
        "iso": "RU",
        "status": 1,
        "name": "Saratov Central Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "51.566666",
        "size": "medium"
    },
    {
        "iata": "BCX",
        "lon": "58.333332",
        "iso": "RU",
        "status": 1,
        "name": "Beloretsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.933334",
        "size": "small"
    },
    {
        "iata": "NEF",
        "lon": "54.35",
        "iso": "RU",
        "status": 1,
        "name": "Neftekamsk Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "56.1",
        "size": "small"
    },
    {
        "iata": "OKT",
        "lon": "53.38333",
        "iso": "RU",
        "status": 1,
        "name": "Oktyabrskiy Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.433334",
        "size": "small"
    },
    {
        "iata": "UFA",
        "lon": "55.884544",
        "iso": "RU",
        "status": 1,
        "name": "Ufa International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "54.565403",
        "size": "large"
    },
    {
        "iata": "KUF",
        "lon": "50.14742",
        "iso": "RU",
        "status": 1,
        "name": "Kurumoch International Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "53.50782",
        "size": "large"
    },
    {
        "iata": "FZB",
        "iso": "UY",
        "status": 1,
        "name": "Fray Bentos Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "RCH",
        "lon": "-72.924446",
        "iso": "UY",
        "status": 1,
        "name": "Rocha Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "11.529167",
        "size": "small"
    },
    {
        "iata": "DIU",
        "lon": "70.916664",
        "iso": "IN",
        "status": 1,
        "name": "Diu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.716667",
        "size": "small"
    },
    {
        "iata": "AMD",
        "lon": "72.62417",
        "iso": "IN",
        "status": 1,
        "name": "Sardar Vallabhbhai Patel International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.06639",
        "size": "medium"
    },
    {
        "iata": "AKD",
        "lon": "77.083336",
        "iso": "IN",
        "status": 1,
        "name": "Akola Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.666668",
        "size": "medium"
    },
    {
        "iata": "IXU",
        "lon": "75.39721",
        "iso": "IN",
        "status": 1,
        "name": "Aurangabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.866465",
        "size": "medium"
    },
    {
        "iata": "BOM",
        "lon": "72.87497",
        "iso": "IN",
        "status": 1,
        "name": "Chhatrapati Shivaji International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.095509",
        "size": "large"
    },
    {
        "iata": "PAB",
        "lon": "76.833336",
        "iso": "IN",
        "status": 1,
        "name": "Bilaspur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.316668",
        "size": "medium"
    },
    {
        "iata": "BHJ",
        "lon": "69.670555",
        "iso": "IN",
        "status": 1,
        "name": "Bhuj Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.2875",
        "size": "medium"
    },
    {
        "iata": "IXG",
        "lon": "74.61833",
        "iso": "IN",
        "status": 1,
        "name": "Belgaum Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.858611",
        "size": "medium"
    },
    {
        "iata": "BDQ",
        "lon": "73.2157",
        "iso": "IN",
        "status": 1,
        "name": "Vadodara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.329077",
        "size": "medium"
    },
    {
        "iata": "BHO",
        "lon": "77.34177",
        "iso": "IN",
        "status": 1,
        "name": "Bhopal Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.284761",
        "size": "medium"
    },
    {
        "iata": "BHU",
        "lon": "72.18444",
        "iso": "IN",
        "status": 1,
        "name": "Bhavnagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.755",
        "size": "medium"
    },
    {
        "iata": "NMB",
        "lon": "72.95",
        "iso": "IN",
        "status": 1,
        "name": "Daman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.416668",
        "size": "medium"
    },
    {
        "iata": "GUX",
        "lon": "77.316666",
        "iso": "IN",
        "status": 1,
        "name": "Guna Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.666668",
        "size": "medium"
    },
    {
        "iata": "GOI",
        "lon": "73.83983",
        "iso": "IN",
        "status": 1,
        "name": "Dabolim Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.384534",
        "size": "large"
    },
    {
        "iata": "HBX",
        "lon": "75.08608",
        "iso": "IN",
        "status": 1,
        "name": "Hubli Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.358738",
        "size": "medium"
    },
    {
        "iata": "IDR",
        "lon": "75.80944",
        "iso": "IN",
        "status": 1,
        "name": "Devi Ahilyabai Holkar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.728651",
        "size": "medium"
    },
    {
        "iata": "JLR",
        "lon": "79.925",
        "iso": "IN",
        "status": 1,
        "name": "Jabalpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.666668",
        "size": "medium"
    },
    {
        "iata": "JGA",
        "lon": "70.01195",
        "iso": "IN",
        "status": 1,
        "name": "Jamnagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.463333",
        "size": "medium"
    },
    {
        "iata": "IXY",
        "lon": "70.104164",
        "iso": "IN",
        "status": 1,
        "name": "Kandla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.11111",
        "size": "medium"
    },
    {
        "iata": "HJR",
        "lon": "79.91642",
        "iso": "IN",
        "status": 1,
        "name": "Khajuraho Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.818747",
        "size": "medium"
    },
    {
        "iata": "KLH",
        "lon": "74.333336",
        "iso": "IN",
        "status": 1,
        "name": "Kolhapur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.666668",
        "size": "medium"
    },
    {
        "iata": "IXK",
        "lon": "70.270836",
        "iso": "IN",
        "status": 1,
        "name": "Keshod Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.319445",
        "size": "medium"
    },
    {
        "iata": "NDC",
        "lon": "77.316666",
        "iso": "IN",
        "status": 1,
        "name": "Nanded Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.183332",
        "size": "medium"
    },
    {
        "iata": "NAG",
        "lon": "79.05636",
        "iso": "IN",
        "status": 1,
        "name": "Dr. Babasaheb Ambedkar International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.090036",
        "size": "medium"
    },
    {
        "iata": "ISK",
        "lon": "73.811264",
        "iso": "IN",
        "status": 1,
        "name": "Gandhinagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.966942",
        "size": "medium"
    },
    {
        "iata": "PNQ",
        "lon": "73.920555",
        "iso": "IN",
        "status": 1,
        "name": "Pune Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.581388",
        "size": "medium"
    },
    {
        "iata": "PBD",
        "lon": "69.656944",
        "iso": "IN",
        "status": 1,
        "name": "Porbandar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.647223",
        "size": "medium"
    },
    {
        "iata": "RTC",
        "lon": "73.316666",
        "iso": "IN",
        "status": 1,
        "name": "Ratnagiri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.0",
        "size": "medium"
    },
    {
        "iata": "RAJ",
        "lon": "70.78235",
        "iso": "IN",
        "status": 1,
        "name": "Rajkot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.308674",
        "size": "medium"
    },
    {
        "iata": "RPR",
        "lon": "81.73975",
        "iso": "IN",
        "status": 1,
        "name": "Raipur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.18456",
        "size": "medium"
    },
    {
        "iata": "SSE",
        "lon": "75.933334",
        "iso": "IN",
        "status": 1,
        "name": "Solapur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.633333",
        "size": "medium"
    },
    {
        "iata": "STV",
        "lon": "72.745255",
        "iso": "IN",
        "status": 1,
        "name": "Surat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.117659",
        "size": "medium"
    },
    {
        "iata": "UDR",
        "lon": "73.891266",
        "iso": "IN",
        "status": 1,
        "name": "Maharana Pratap Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.61919",
        "size": "medium"
    },
    {
        "iata": "CMB",
        "lon": "79.8865",
        "iso": "LK",
        "status": 1,
        "name": "Bandaranaike International Colombo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.174112",
        "size": "large"
    },
    {
        "iata": "ACJ",
        "lon": "80.38333",
        "iso": "LK",
        "status": 1,
        "name": "Anuradhapura Air Force Base",
        "continent": "AS",
        "type": "airport",
        "lat": "8.35",
        "size": "medium"
    },
    {
        "iata": "BTC",
        "lon": "81.67854",
        "iso": "LK",
        "status": 1,
        "name": "Batticaloa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.704878",
        "size": "medium"
    },
    {
        "iata": "RML",
        "lon": "79.8875",
        "iso": "LK",
        "status": 1,
        "name": "Colombo Ratmalana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.819444",
        "size": "medium"
    },
    {
        "iata": "GOY",
        "lon": "81.61667",
        "iso": "LK",
        "status": 1,
        "name": "Amparai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.333333",
        "size": "medium"
    },
    {
        "iata": "MNH",
        "lon": "57.48639",
        "iso": "LK",
        "status": 1,
        "name": "Hingurakgoda Air Force Base",
        "continent": "AS",
        "type": "airport",
        "lat": "23.64111",
        "size": "medium"
    },
    {
        "iata": "JAF",
        "lon": "80.075",
        "iso": "LK",
        "status": 1,
        "name": "Kankesanturai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.791667",
        "size": "medium"
    },
    {
        "iata": "KCT",
        "lon": "80.31974",
        "iso": "LK",
        "status": 1,
        "name": "Koggala Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.993832",
        "size": "medium"
    },
    {
        "iata": "GIU",
        "lon": "80.72891",
        "iso": "LK",
        "status": 1,
        "name": "Sigiriya Air Force Base",
        "continent": "AS",
        "type": "airport",
        "lat": "7.955185",
        "size": "small"
    },
    {
        "iata": "TRR",
        "lon": "81.18189",
        "iso": "LK",
        "status": 1,
        "name": "China Bay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.538984",
        "size": "medium"
    },
    {
        "iata": "WRZ",
        "lon": "81.238335",
        "iso": "LK",
        "status": 1,
        "name": "Weerawila International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.251667",
        "size": "small"
    },
    {
        "iata": "BBM",
        "lon": "103.2",
        "iso": "KH",
        "status": 1,
        "name": "Battambang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.116667",
        "size": "medium"
    },
    {
        "iata": "KZC",
        "lon": "104.583336",
        "iso": "KH",
        "status": 1,
        "name": "Kampong Chhnang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.333333",
        "size": "medium"
    },
    {
        "iata": "KKZ",
        "lon": "102.96667",
        "iso": "KH",
        "status": 1,
        "name": "Kaoh Kong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.633333",
        "size": "small"
    },
    {
        "iata": "KTI",
        "lon": "106.05",
        "iso": "KH",
        "status": 1,
        "name": "Kratie Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.5",
        "size": "small"
    },
    {
        "iata": "PNH",
        "lon": "104.84778",
        "iso": "KH",
        "status": 1,
        "name": "Phnom Penh International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.546111",
        "size": "large"
    },
    {
        "iata": "RBE",
        "lon": "106.98333",
        "iso": "KH",
        "status": 1,
        "name": "Ratanakiri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.666667",
        "size": "medium"
    },
    {
        "iata": "REP",
        "lon": "103.815926",
        "iso": "KH",
        "status": 1,
        "name": "Angkor International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.408436",
        "size": "large"
    },
    {
        "iata": "TNX",
        "lon": "106.03333",
        "iso": "KH",
        "status": 1,
        "name": "Stung Treng Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.533333",
        "size": "medium"
    },
    {
        "iata": "KOS",
        "lon": "103.63908",
        "iso": "KH",
        "status": 1,
        "name": "Sihanoukville International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.580739",
        "size": "small"
    },
    {
        "iata": "ELX",
        "iso": "VE",
        "status": 1,
        "name": "Isla de Tigre  Heliport",
        "continent": "SA",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "LGY",
        "lon": "-71.4",
        "iso": "VE",
        "status": 1,
        "name": "Lagunillas Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.516667",
        "size": "small"
    },
    {
        "iata": "KTV",
        "lon": "-62.416668",
        "iso": "VE",
        "status": 1,
        "name": "Kamarata Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.75",
        "size": "small"
    },
    {
        "iata": "LAG",
        "lon": "-66.933334",
        "iso": "VE",
        "status": 1,
        "name": "La Guaira Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "10.6",
        "size": "small"
    },
    {
        "iata": "SFX",
        "lon": "-62.38333",
        "iso": "VE",
        "status": 1,
        "name": "San Felix Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "8.216667",
        "size": "small"
    },
    {
        "iata": "SVV",
        "lon": "-62.0",
        "iso": "VE",
        "status": 1,
        "name": "San Salvador de Paul Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "7.0",
        "size": "small"
    },
    {
        "iata": "WOK",
        "lon": "-61.733334",
        "iso": "VE",
        "status": 1,
        "name": "Wonken Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "5.25",
        "size": "small"
    },
    {
        "iata": "IXV",
        "lon": "94.816666",
        "iso": "IN",
        "status": 1,
        "name": "Along Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.2",
        "size": "medium"
    },
    {
        "iata": "IXA",
        "lon": "91.244514",
        "iso": "IN",
        "status": 1,
        "name": "Agartala Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.8926",
        "size": "medium"
    },
    {
        "iata": "AJL",
        "iso": "IN",
        "status": 0,
        "name": "Tuirial Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "IXB",
        "lon": "88.324814",
        "iso": "IN",
        "status": 1,
        "name": "Bagdogra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.68488",
        "size": "medium"
    },
    {
        "iata": "RGH",
        "lon": "88.73333",
        "iso": "IN",
        "status": 1,
        "name": "Balurghat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.25",
        "size": "small"
    },
    {
        "iata": "SHL",
        "lon": "91.88333",
        "iso": "IN",
        "status": 1,
        "name": "Shillong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.566668",
        "size": "medium"
    },
    {
        "iata": "BBI",
        "lon": "85.81738",
        "iso": "IN",
        "status": 1,
        "name": "Biju Patnaik Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.252853",
        "size": "medium"
    },
    {
        "iata": "CCU",
        "lon": "88.43931",
        "iso": "IN",
        "status": 1,
        "name": "Netaji Subhash Chandra Bose International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.64531",
        "size": "large"
    },
    {
        "iata": "COH",
        "lon": "89.46667",
        "iso": "IN",
        "status": 1,
        "name": "Cooch Behar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.333332",
        "size": "small"
    },
    {
        "iata": "DBD",
        "lon": "86.45",
        "iso": "IN",
        "status": 1,
        "name": "Dhanbad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.783333",
        "size": "medium"
    },
    {
        "iata": "DAE",
        "lon": "94.333336",
        "iso": "IN",
        "status": 1,
        "name": "Daporijo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.5",
        "size": "small"
    },
    {
        "iata": "GOP",
        "lon": "83.44376",
        "iso": "IN",
        "status": 1,
        "name": "Gorakhpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.746862",
        "size": "medium"
    },
    {
        "iata": "GAU",
        "lon": "91.58881",
        "iso": "IN",
        "status": 1,
        "name": "Lokpriya Gopinath Bordoloi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.105982",
        "size": "medium"
    },
    {
        "iata": "GAY",
        "lon": "84.94583",
        "iso": "IN",
        "status": 1,
        "name": "Gaya Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.746668",
        "size": "medium"
    },
    {
        "iata": "IMF",
        "lon": "93.897064",
        "iso": "IN",
        "status": 1,
        "name": "Imphal Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.76457",
        "size": "medium"
    },
    {
        "iata": "PYB",
        "lon": "82.63333",
        "iso": "IN",
        "status": 1,
        "name": "Jeypore Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.833332",
        "size": "small"
    },
    {
        "iata": "IXW",
        "lon": "86.16889",
        "iso": "IN",
        "status": 1,
        "name": "Jamshedpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.813057",
        "size": "medium"
    },
    {
        "iata": "JRH",
        "lon": "94.1849",
        "iso": "IN",
        "status": 1,
        "name": "Jorhat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.734484",
        "size": "medium"
    },
    {
        "iata": "IXQ",
        "lon": "91.81556",
        "iso": "IN",
        "status": 1,
        "name": "Kamalpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.1325",
        "size": "small"
    },
    {
        "iata": "IXH",
        "lon": "92.00889",
        "iso": "IN",
        "status": 1,
        "name": "Kailashahar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.307222",
        "size": "medium"
    },
    {
        "iata": "IXS",
        "lon": "92.979256",
        "iso": "IN",
        "status": 1,
        "name": "Silchar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.916018",
        "size": "medium"
    },
    {
        "iata": "IXN",
        "lon": "91.60333",
        "iso": "IN",
        "status": 1,
        "name": "Khowai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.063889",
        "size": "small"
    },
    {
        "iata": "AJL",
        "lon": "92.62482",
        "iso": "IN",
        "status": 1,
        "name": "Lengpui Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.838985",
        "size": "medium"
    },
    {
        "iata": "IXI",
        "lon": "94.08889",
        "iso": "IN",
        "status": 1,
        "name": "North Lakhimpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.290277",
        "size": "medium"
    },
    {
        "iata": "LDA",
        "lon": "88.15",
        "iso": "IN",
        "status": 1,
        "name": "Malda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.033333",
        "size": "small"
    },
    {
        "iata": "DIB",
        "lon": "95.021065",
        "iso": "IN",
        "status": 1,
        "name": "Dibrugarh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.482908",
        "size": "medium"
    },
    {
        "iata": "DMU",
        "lon": "93.772865",
        "iso": "IN",
        "status": 1,
        "name": "Dimapur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.879816",
        "size": "medium"
    },
    {
        "iata": "MZU",
        "lon": "85.38333",
        "iso": "IN",
        "status": 1,
        "name": "Muzaffarpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.116667",
        "size": "medium"
    },
    {
        "iata": "IXT",
        "lon": "95.333336",
        "iso": "IN",
        "status": 1,
        "name": "Pasighat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.016666",
        "size": "small"
    },
    {
        "iata": "PAT",
        "lon": "85.09067",
        "iso": "IN",
        "status": 1,
        "name": "Lok Nayak Jayaprakash Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.59489",
        "size": "medium"
    },
    {
        "iata": "IXR",
        "lon": "85.32285",
        "iso": "IN",
        "status": 1,
        "name": "Birsa Munda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.318192",
        "size": "medium"
    },
    {
        "iata": "RRK",
        "lon": "84.81472",
        "iso": "IN",
        "status": 1,
        "name": "Rourkela Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.256666",
        "size": "medium"
    },
    {
        "iata": "RUP",
        "lon": "89.916664",
        "iso": "IN",
        "status": 1,
        "name": "Rupsi India Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.15",
        "size": "small"
    },
    {
        "iata": "TEZ",
        "lon": "92.79611",
        "iso": "IN",
        "status": 1,
        "name": "Tezpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.710833",
        "size": "medium"
    },
    {
        "iata": "VTZ",
        "lon": "83.22675",
        "iso": "IN",
        "status": 1,
        "name": "Vishakhapatnam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.728151",
        "size": "small"
    },
    {
        "iata": "ZER",
        "lon": "93.833336",
        "iso": "IN",
        "status": 1,
        "name": "Zero Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.633333",
        "size": "medium"
    },
    {
        "iata": "BZL",
        "lon": "90.301445",
        "iso": "BD",
        "status": 1,
        "name": "Barisal Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.798943",
        "size": "medium"
    },
    {
        "iata": "CXB",
        "lon": "91.96667",
        "iso": "BD",
        "status": 1,
        "name": "Cox's Bazar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.45",
        "size": "medium"
    },
    {
        "iata": "CLA",
        "lon": "91.19222",
        "iso": "BD",
        "status": 1,
        "name": "Comilla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.4375",
        "size": "small"
    },
    {
        "iata": "CGP",
        "lon": "91.81516",
        "iso": "BD",
        "status": 1,
        "name": "Shah Amanat International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.245201",
        "size": "medium"
    },
    {
        "iata": "IRD",
        "lon": "89.05278",
        "iso": "BD",
        "status": 1,
        "name": "Ishurdi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.133333",
        "size": "medium"
    },
    {
        "iata": "JSR",
        "lon": "89.160645",
        "iso": "BD",
        "status": 1,
        "name": "Jessore Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.17696",
        "size": "medium"
    },
    {
        "iata": "LLJ",
        "iso": "BD",
        "status": 1,
        "name": "Lalmonirhat Airport",
        "continent": "AS",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "RJH",
        "lon": "88.61667",
        "iso": "BD",
        "status": 1,
        "name": "Shah Mokhdum Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.433332",
        "size": "medium"
    },
    {
        "iata": "SPD",
        "lon": "88.9",
        "iso": "BD",
        "status": 1,
        "name": "Saidpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.783333",
        "size": "medium"
    },
    {
        "iata": "TKR",
        "lon": "88.4",
        "iso": "BD",
        "status": 1,
        "name": "Thakurgaon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.016666",
        "size": "small"
    },
    {
        "iata": "ZHM",
        "lon": "91.92111",
        "iso": "BD",
        "status": 1,
        "name": "Shamshernagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.398611",
        "size": "small"
    },
    {
        "iata": "ZYL",
        "lon": "91.870476",
        "iso": "BD",
        "status": 1,
        "name": "Osmany International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.957598",
        "size": "medium"
    },
    {
        "iata": "DAC",
        "lon": "90.405876",
        "iso": "BD",
        "status": 1,
        "name": "Dhaka / Hazrat Shahjalal International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.848648",
        "size": "medium"
    },
    {
        "iata": "HKG",
        "lon": "113.93649",
        "iso": "HK",
        "status": 1,
        "name": "Chek Lap Kok International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.315248",
        "size": "large"
    },
    {
        "iata": "HKG",
        "iso": "HK",
        "status": 0,
        "name": "Hong Kong International Airport Kai Tak",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "HHP",
        "iso": "HK",
        "status": 1,
        "name": "Shun Tak Heliport",
        "continent": "AS",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "SPB",
        "iso": "VI",
        "status": 1,
        "name": "Charlotte Amalie Harbor Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "SSB",
        "iso": "VI",
        "status": 1,
        "name": "Christiansted Harbor Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "AGR",
        "lon": "77.962776",
        "iso": "IN",
        "status": 1,
        "name": "Agra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.158333",
        "size": "medium"
    },
    {
        "iata": "IXD",
        "lon": "81.736115",
        "iso": "IN",
        "status": 1,
        "name": "Allahabad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.439722",
        "size": "medium"
    },
    {
        "iata": "ATQ",
        "lon": "74.8073",
        "iso": "IN",
        "status": 1,
        "name": "Sri Guru Ram Dass Jee International Airport, Amritsar",
        "continent": "AS",
        "type": "airport",
        "lat": "31.706741",
        "size": "large"
    },
    {
        "iata": "BKB",
        "lon": "73.3",
        "iso": "IN",
        "status": 1,
        "name": "Nal Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.016666",
        "size": "medium"
    },
    {
        "iata": "VNS",
        "lon": "82.85373",
        "iso": "IN",
        "status": 1,
        "name": "Lal Bahadur Shastri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.449697",
        "size": "medium"
    },
    {
        "iata": "KUU",
        "lon": "77.1",
        "iso": "IN",
        "status": 1,
        "name": "Kullu Manali Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.983334",
        "size": "medium"
    },
    {
        "iata": "BUP",
        "lon": "74.96667",
        "iso": "IN",
        "status": 1,
        "name": "Bhatinda Air Force Station",
        "continent": "AS",
        "type": "airport",
        "lat": "30.166668",
        "size": "medium"
    },
    {
        "iata": "IXC",
        "lon": "76.797386",
        "iso": "IN",
        "status": 1,
        "name": "Chandigarh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.674921",
        "size": "medium"
    },
    {
        "iata": "DED",
        "lon": "78.03333",
        "iso": "IN",
        "status": 1,
        "name": "Dehradun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.316668",
        "size": "medium"
    },
    {
        "iata": "DEL",
        "lon": "77.10079",
        "iso": "IN",
        "status": 1,
        "name": "Indira Gandhi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.556555",
        "size": "large"
    },
    {
        "iata": "DHM",
        "lon": "76.263336",
        "iso": "IN",
        "status": 1,
        "name": "Kangra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.165",
        "size": "medium"
    },
    {
        "iata": "GWL",
        "lon": "78.23",
        "iso": "IN",
        "status": 1,
        "name": "Gwalior Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.293888",
        "size": "medium"
    },
    {
        "iata": "HSS",
        "lon": "75.8",
        "iso": "IN",
        "status": 1,
        "name": "Hissar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.166668",
        "size": "medium"
    },
    {
        "iata": "JDH",
        "lon": "73.05055",
        "iso": "IN",
        "status": 1,
        "name": "Jodhpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.263948",
        "size": "medium"
    },
    {
        "iata": "JAI",
        "lon": "75.80151",
        "iso": "IN",
        "status": 1,
        "name": "Jaipur International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.820772",
        "size": "medium"
    },
    {
        "iata": "JSA",
        "lon": "70.9",
        "iso": "IN",
        "status": 1,
        "name": "Jaisalmer Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.916668",
        "size": "medium"
    },
    {
        "iata": "IXJ",
        "lon": "74.84283",
        "iso": "IN",
        "status": 1,
        "name": "Jammu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.68077",
        "size": "medium"
    },
    {
        "iata": "KNU",
        "lon": "80.4125",
        "iso": "IN",
        "status": 1,
        "name": "Kanpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.402779",
        "size": "medium"
    },
    {
        "iata": "KTU",
        "lon": "76.5",
        "iso": "IN",
        "status": 1,
        "name": "Kota Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.0",
        "size": "medium"
    },
    {
        "iata": "LUH",
        "lon": "75.85",
        "iso": "IN",
        "status": 1,
        "name": "Ludhiana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.9",
        "size": "medium"
    },
    {
        "iata": "IXL",
        "lon": "77.54746",
        "iso": "IN",
        "status": 1,
        "name": "Leh Kushok Bakula Rimpochee Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.14035",
        "size": "medium"
    },
    {
        "iata": "LKO",
        "lon": "80.884186",
        "iso": "IN",
        "status": 1,
        "name": "Chaudhary Charan Singh International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.764257",
        "size": "medium"
    },
    {
        "iata": "IXP",
        "lon": "75.63333",
        "iso": "IN",
        "status": 1,
        "name": "Pathankot Air Force Station",
        "continent": "AS",
        "type": "airport",
        "lat": "32.216667",
        "size": "medium"
    },
    {
        "iata": "PGH",
        "lon": "79.47361",
        "iso": "IN",
        "status": 1,
        "name": "Pantnagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.031944",
        "size": "medium"
    },
    {
        "iata": "SLV",
        "lon": "77.15",
        "iso": "IN",
        "status": 1,
        "name": "Shimla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.116667",
        "size": "large"
    },
    {
        "iata": "SXR",
        "lon": "74.762634",
        "iso": "IN",
        "status": 1,
        "name": "Sheikh ul Alam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.002308",
        "size": "medium"
    },
    {
        "iata": "TNI",
        "lon": "80.85181",
        "iso": "IN",
        "status": 1,
        "name": "Satna Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.564325",
        "size": "medium"
    },
    {
        "iata": "AOU",
        "lon": "106.833336",
        "iso": "LA",
        "status": 1,
        "name": "Attopeu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.8",
        "size": "small"
    },
    {
        "iata": "OUI",
        "lon": "102.67083",
        "iso": "LA",
        "status": 1,
        "name": "Ban Huoeisay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.936111",
        "size": "small"
    },
    {
        "iata": "LPQ",
        "lon": "102.16371",
        "iso": "LA",
        "status": 1,
        "name": "Luang Phabang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.897055",
        "size": "medium"
    },
    {
        "iata": "LXG",
        "lon": "101.46667",
        "iso": "LA",
        "status": 1,
        "name": "Luang Namtha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.05",
        "size": "small"
    },
    {
        "iata": "ODY",
        "lon": "104.166664",
        "iso": "LA",
        "status": 1,
        "name": "Oudomsay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.583332",
        "size": "small"
    },
    {
        "iata": "PKZ",
        "lon": "105.781296",
        "iso": "LA",
        "status": 1,
        "name": "Pakse International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.136257",
        "size": "medium"
    },
    {
        "iata": "ZBY",
        "lon": "101.7",
        "iso": "LA",
        "status": 1,
        "name": "Sayaboury Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.25",
        "size": "small"
    },
    {
        "iata": "ZVK",
        "lon": "104.7625",
        "iso": "LA",
        "status": 1,
        "name": "Savannakhet Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.553612",
        "size": "medium"
    },
    {
        "iata": "NEU",
        "lon": "104.066666",
        "iso": "LA",
        "status": 1,
        "name": "Sam Neua Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.416668",
        "size": "medium"
    },
    {
        "iata": "VNA",
        "lon": "106.42611",
        "iso": "LA",
        "status": 1,
        "name": "Saravane Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.692778",
        "size": "small"
    },
    {
        "iata": "THK",
        "lon": "104.816666",
        "iso": "LA",
        "status": 1,
        "name": "Thakhek Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.4",
        "size": "small"
    },
    {
        "iata": "VTE",
        "lon": "102.56772",
        "iso": "LA",
        "status": 1,
        "name": "Wattay International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.975431",
        "size": "medium"
    },
    {
        "iata": "XKH",
        "lon": "103.36667",
        "iso": "LA",
        "status": 1,
        "name": "Xieng Khouang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.3",
        "size": "small"
    },
    {
        "iata": "VMI",
        "lon": "-58.0",
        "iso": "PY",
        "status": 1,
        "name": "Dr Juan Plate Airport",
        "continent": "SA",
        "type": "airport",
        "lat": "-22.25",
        "size": "small"
    },
    {
        "iata": "MFM",
        "lon": "113.57285",
        "iso": "MO",
        "status": 1,
        "name": "Macau International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.156588",
        "size": "large"
    },
    {
        "iata": "VDH",
        "lon": "106.59055",
        "iso": "VN",
        "status": 1,
        "name": "Dong Hoi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.515",
        "size": "medium"
    },
    {
        "iata": "KON",
        "lon": "108.021385",
        "iso": "VN",
        "status": 1,
        "name": "Kontum Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.354167",
        "size": "medium"
    },
    {
        "iata": "BJH",
        "lon": "81.25",
        "iso": "NP",
        "status": 1,
        "name": "Bajhang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.55",
        "size": "small"
    },
    {
        "iata": "BHP",
        "lon": "87.05",
        "iso": "NP",
        "status": 1,
        "name": "Bhojpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.183332",
        "size": "small"
    },
    {
        "iata": "BGL",
        "lon": "83.683334",
        "iso": "NP",
        "status": 1,
        "name": "Baglung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.215279",
        "size": "small"
    },
    {
        "iata": "BHR",
        "lon": "84.4325",
        "iso": "NP",
        "status": 1,
        "name": "Bharatpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.67639",
        "size": "small"
    },
    {
        "iata": "BJU",
        "lon": "81.333336",
        "iso": "NP",
        "status": 1,
        "name": "Bajura Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.366667",
        "size": "small"
    },
    {
        "iata": "BIT",
        "lon": "80.5",
        "iso": "NP",
        "status": 1,
        "name": "Baitadi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.0",
        "size": "small"
    },
    {
        "iata": "BWA",
        "lon": "83.42",
        "iso": "NP",
        "status": 1,
        "name": "Bhairahawa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.504168",
        "size": "medium"
    },
    {
        "iata": "BDP",
        "lon": "88.083336",
        "iso": "NP",
        "status": 1,
        "name": "Bhadrapur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.533333",
        "size": "small"
    },
    {
        "iata": "DNP",
        "lon": "82.316666",
        "iso": "NP",
        "status": 1,
        "name": "Tulsipur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.116667",
        "size": "small"
    },
    {
        "iata": "DHI",
        "lon": "80.63333",
        "iso": "NP",
        "status": 1,
        "name": "Dhangarhi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.683332",
        "size": "small"
    },
    {
        "iata": "DAP",
        "lon": "80.5",
        "iso": "NP",
        "status": 1,
        "name": "Darchula Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.666668",
        "size": "small"
    },
    {
        "iata": "DOP",
        "lon": "82.816666",
        "iso": "NP",
        "status": 1,
        "name": "Dolpa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.0",
        "size": "small"
    },
    {
        "iata": "SIH",
        "lon": "80.975",
        "iso": "NP",
        "status": 1,
        "name": "Silgadi Doti Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.266666",
        "size": "small"
    },
    {
        "iata": "GKH",
        "lon": "84.675",
        "iso": "NP",
        "status": 1,
        "name": "Gorkha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.0",
        "size": "small"
    },
    {
        "iata": "JIR",
        "lon": "86.23333",
        "iso": "NP",
        "status": 1,
        "name": "Jiri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.633333",
        "size": "small"
    },
    {
        "iata": "JUM",
        "lon": "82.19194",
        "iso": "NP",
        "status": 1,
        "name": "Jumla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.273611",
        "size": "small"
    },
    {
        "iata": "JKR",
        "lon": "85.92389",
        "iso": "NP",
        "status": 1,
        "name": "Janakpur Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.708332",
        "size": "medium"
    },
    {
        "iata": "KTM",
        "lon": "85.35657",
        "iso": "NP",
        "status": 1,
        "name": "Tribhuvan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.699905",
        "size": "medium"
    },
    {
        "iata": "LDN",
        "lon": "86.71667",
        "iso": "NP",
        "status": 1,
        "name": "Lamidanda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.25",
        "size": "small"
    },
    {
        "iata": "LUA",
        "lon": "86.73333",
        "iso": "NP",
        "status": 1,
        "name": "Lukla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.683332",
        "size": "small"
    },
    {
        "iata": "LTG",
        "lon": "85.6",
        "iso": "NP",
        "status": 1,
        "name": "Langtang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.233334",
        "size": "small"
    },
    {
        "iata": "NGX",
        "lon": "85.36305",
        "iso": "NP",
        "status": 1,
        "name": "Manang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.700556",
        "size": "small"
    },
    {
        "iata": "MEY",
        "lon": "84.23139",
        "iso": "NP",
        "status": 1,
        "name": "Meghauli Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.57639",
        "size": "small"
    },
    {
        "iata": "XMG",
        "lon": "80.2",
        "iso": "NP",
        "status": 1,
        "name": "Mahendranagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.966667",
        "size": "small"
    },
    {
        "iata": "KEP",
        "lon": "81.576385",
        "iso": "NP",
        "status": 1,
        "name": "Nepalgunj Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.1325",
        "size": "medium"
    },
    {
        "iata": "PKR",
        "lon": "83.986115",
        "iso": "NP",
        "status": 1,
        "name": "Pokhara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.197222",
        "size": "medium"
    },
    {
        "iata": "PPL",
        "lon": "86.6",
        "iso": "NP",
        "status": 1,
        "name": "Phaplu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.516666",
        "size": "small"
    },
    {
        "iata": "RJB",
        "lon": "86.833336",
        "iso": "NP",
        "status": 1,
        "name": "Rajbiraj Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.483334",
        "size": "small"
    },
    {
        "iata": "RHP",
        "lon": "86.083336",
        "iso": "NP",
        "status": 1,
        "name": "Ramechhap Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.333332",
        "size": "small"
    },
    {
        "iata": "RUK",
        "lon": "82.46667",
        "iso": "NP",
        "status": 1,
        "name": "Rukumkot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.625",
        "size": "small"
    },
    {
        "iata": "RUM",
        "lon": "86.53333",
        "iso": "NP",
        "status": 1,
        "name": "Rumjatar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.316668",
        "size": "small"
    },
    {
        "iata": "SIF",
        "lon": "84.979195",
        "iso": "NP",
        "status": 1,
        "name": "Simara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.163563",
        "size": "small"
    },
    {
        "iata": "SKH",
        "lon": "82.50833",
        "iso": "NP",
        "status": 1,
        "name": "Surkhet Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.333332",
        "size": "small"
    },
    {
        "iata": "FEB",
        "lon": "81.21667",
        "iso": "NP",
        "status": 1,
        "name": "Sanfebagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.233334",
        "size": "small"
    },
    {
        "iata": "IMK",
        "lon": "81.816666",
        "iso": "NP",
        "status": 1,
        "name": "Simikot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.966667",
        "size": "small"
    },
    {
        "iata": "TMI",
        "lon": "87.2",
        "iso": "NP",
        "status": 1,
        "name": "Tumling Tar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.3",
        "size": "small"
    },
    {
        "iata": "BIR",
        "lon": "87.28333",
        "iso": "NP",
        "status": 1,
        "name": "Biratnagar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.434723",
        "size": "medium"
    },
    {
        "iata": "LTU",
        "lon": "76.46629",
        "iso": "IN",
        "status": 1,
        "name": "Murod Kond Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.409552",
        "size": "medium"
    },
    {
        "iata": "AGX",
        "lon": "72.17987",
        "iso": "IN",
        "status": 1,
        "name": "Agatti Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.828967",
        "size": "medium"
    },
    {
        "iata": "BEP",
        "lon": "76.9",
        "iso": "IN",
        "status": 1,
        "name": "Bellary Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.183333",
        "size": "medium"
    },
    {
        "iata": "BLR",
        "lon": "77.70556",
        "iso": "IN",
        "status": 1,
        "name": "Bengaluru International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.198889",
        "size": "large"
    },
    {
        "iata": "VGA",
        "lon": "80.79722",
        "iso": "IN",
        "status": 1,
        "name": "Vijayawada Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.529444",
        "size": "medium"
    },
    {
        "iata": "CJB",
        "lon": "77.03893",
        "iso": "IN",
        "status": 1,
        "name": "Coimbatore International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.031026",
        "size": "medium"
    },
    {
        "iata": "COK",
        "lon": "76.39053",
        "iso": "IN",
        "status": 1,
        "name": "Cochin International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.155644",
        "size": "large"
    },
    {
        "iata": "CCJ",
        "lon": "75.950584",
        "iso": "IN",
        "status": 1,
        "name": "Calicut International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.14025",
        "size": "large"
    },
    {
        "iata": "CDP",
        "lon": "78.78333",
        "iso": "IN",
        "status": 1,
        "name": "Cuddapah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.516667",
        "size": "medium"
    },
    {
        "iata": "CBD",
        "lon": "92.816666",
        "iso": "IN",
        "status": 1,
        "name": "Car Nicobar Air Force Station",
        "continent": "AS",
        "type": "airport",
        "lat": "9.15",
        "size": "medium"
    },
    {
        "iata": "HYD",
        "lon": "78.428055",
        "iso": "IN",
        "status": 1,
        "name": "Rajiv Gandhi International Airport, Shamshabad",
        "continent": "AS",
        "type": "airport",
        "lat": "17.24",
        "size": "large"
    },
    {
        "iata": "IXM",
        "lon": "78.09112",
        "iso": "IN",
        "status": 1,
        "name": "Madurai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.837471",
        "size": "medium"
    },
    {
        "iata": "IXE",
        "lon": "74.890144",
        "iso": "IN",
        "status": 1,
        "name": "Mangalore International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.963543",
        "size": "medium"
    },
    {
        "iata": "MAA",
        "lon": "80.16378",
        "iso": "IN",
        "status": 1,
        "name": "Chennai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.982267",
        "size": "large"
    },
    {
        "iata": "MYQ",
        "lon": "76.653",
        "iso": "IN",
        "status": 1,
        "name": "Mysore Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.229991",
        "size": "small"
    },
    {
        "iata": "IXZ",
        "lon": "92.73227",
        "iso": "IN",
        "status": 1,
        "name": "Vir Savarkar International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.650083",
        "size": "medium"
    },
    {
        "iata": "PNY",
        "lon": "79.81284",
        "iso": "IN",
        "status": 1,
        "name": "Pondicherry Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.968967",
        "size": "medium"
    },
    {
        "iata": "HYD",
        "lon": "78.428055",
        "iso": "IN",
        "status": 1,
        "name": "Rajiv Gandhi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.24",
        "size": "large"
    },
    {
        "iata": "RJA",
        "lon": "81.82081",
        "iso": "IN",
        "status": 1,
        "name": "Rajahmundry Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.109638",
        "size": "medium"
    },
    {
        "iata": "SXV",
        "lon": "78.166664",
        "iso": "IN",
        "status": 1,
        "name": "Salem Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.65",
        "size": "medium"
    },
    {
        "iata": "TJV",
        "lon": "79.2",
        "iso": "IN",
        "status": 1,
        "name": "Tanjore Air Force Base",
        "continent": "AS",
        "type": "airport",
        "lat": "10.8",
        "size": "small"
    },
    {
        "iata": "TIR",
        "lon": "79.54293",
        "iso": "IN",
        "status": 1,
        "name": "Tirupati Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.635721",
        "size": "small"
    },
    {
        "iata": "TRZ",
        "lon": "78.70724",
        "iso": "IN",
        "status": 1,
        "name": "Tiruchirapally Civil Airport Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.762168",
        "size": "medium"
    },
    {
        "iata": "TRV",
        "lon": "76.91907",
        "iso": "IN",
        "status": 1,
        "name": "Trivandrum International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.476126",
        "size": "large"
    },
    {
        "iata": "WGC",
        "lon": "79.6",
        "iso": "IN",
        "status": 1,
        "name": "Warangal Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.916668",
        "size": "small"
    },
    {
        "iata": "PBH",
        "lon": "89.416664",
        "iso": "BT",
        "status": 1,
        "name": "Paro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.433332",
        "size": "medium"
    },
    {
        "iata": "FVM",
        "lon": "73.43278",
        "iso": "MV",
        "status": 1,
        "name": "Fuvahmulah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.309444",
        "size": "small"
    },
    {
        "iata": "GAN",
        "lon": "73.15654",
        "iso": "MV",
        "status": 1,
        "name": "Gan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.690003",
        "size": "medium"
    },
    {
        "iata": "HAQ",
        "lon": "73.16838",
        "iso": "MV",
        "status": 1,
        "name": "Hanimaadhoo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.748433",
        "size": "medium"
    },
    {
        "iata": "KDO",
        "lon": "73.51667",
        "iso": "MV",
        "status": 1,
        "name": "Kadhdhoo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.883333",
        "size": "medium"
    },
    {
        "iata": "MLE",
        "lon": "73.524445",
        "iso": "MV",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "4.201389",
        "size": "large"
    },
    {
        "iata": "KDM",
        "lon": "72.99593",
        "iso": "MV",
        "status": 1,
        "name": "Kaadedhdhoo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.491527",
        "size": "medium"
    },
    {
        "iata": "VAM",
        "lon": "9.458333",
        "iso": "MV",
        "status": 1,
        "name": "Villa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "55.46389",
        "size": "medium"
    },
    {
        "iata": "DMK",
        "lon": "100.60667",
        "iso": "TH",
        "status": 1,
        "name": "Don Mueang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.9125",
        "size": "large"
    },
    {
        "iata": "KKM",
        "lon": "100.61667",
        "iso": "TH",
        "status": 1,
        "name": "Sa Pran Nak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.8",
        "size": "small"
    },
    {
        "iata": "KKM",
        "lon": "100.61667",
        "iso": "TH",
        "status": 1,
        "name": "Khok Kathiam Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.8",
        "size": "medium"
    },
    {
        "iata": "TDX",
        "lon": "102.31867",
        "iso": "TH",
        "status": 1,
        "name": "Trat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.273953",
        "size": "medium"
    },
    {
        "iata": "BKK",
        "lon": "100.752045",
        "iso": "TH",
        "status": 1,
        "name": "Suvarnabhumi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.693062",
        "size": "large"
    },
    {
        "iata": "UTP",
        "lon": "101.01667",
        "iso": "TH",
        "status": 1,
        "name": "U-Tapao International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.683333",
        "size": "medium"
    },
    {
        "iata": "CNX",
        "lon": "98.96841",
        "iso": "TH",
        "status": 1,
        "name": "Chiang Mai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.769573",
        "size": "large"
    },
    {
        "iata": "HGN",
        "lon": "97.97523",
        "iso": "TH",
        "status": 1,
        "name": "Mae Hong Son Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.29968",
        "size": "medium"
    },
    {
        "iata": "PYY",
        "lon": "98.43571",
        "iso": "TH",
        "status": 1,
        "name": "Mae Hong Son Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.371445",
        "size": "small"
    },
    {
        "iata": "LPT",
        "lon": "99.507225",
        "iso": "TH",
        "status": 1,
        "name": "Lampang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.274166",
        "size": "medium"
    },
    {
        "iata": "NNT",
        "lon": "100.78333",
        "iso": "TH",
        "status": 1,
        "name": "Nan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.8",
        "size": "medium"
    },
    {
        "iata": "PRH",
        "lon": "100.16411",
        "iso": "TH",
        "status": 1,
        "name": "Phrae Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.131594",
        "size": "medium"
    },
    {
        "iata": "CEI",
        "lon": "99.878815",
        "iso": "TH",
        "status": 1,
        "name": "Chiang Rai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.954609",
        "size": "medium"
    },
    {
        "iata": "BAO",
        "lon": "102.79278",
        "iso": "TH",
        "status": 1,
        "name": "Udorn Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "17.385",
        "size": "small"
    },
    {
        "iata": "PHY",
        "lon": "101.19833",
        "iso": "TH",
        "status": 1,
        "name": "Phetchabun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.681944",
        "size": "medium"
    },
    {
        "iata": "HHQ",
        "lon": "99.95145",
        "iso": "TH",
        "status": 1,
        "name": "Hua Hin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.6274",
        "size": "medium"
    },
    {
        "iata": "TKH",
        "lon": "100.35",
        "iso": "TH",
        "status": 1,
        "name": "Takhli Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.25",
        "size": "medium"
    },
    {
        "iata": "MAQ",
        "lon": "98.54453",
        "iso": "TH",
        "status": 1,
        "name": "Mae Sot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.700369",
        "size": "medium"
    },
    {
        "iata": "THS",
        "lon": "99.82222",
        "iso": "TH",
        "status": 1,
        "name": "Sukhothai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.223057",
        "size": "medium"
    },
    {
        "iata": "PHS",
        "lon": "100.2812",
        "iso": "TH",
        "status": 1,
        "name": "Phitsanulok Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.771307",
        "size": "medium"
    },
    {
        "iata": "TKT",
        "lon": "99.15222",
        "iso": "TH",
        "status": 1,
        "name": "Tak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.878332",
        "size": "medium"
    },
    {
        "iata": "UTR",
        "lon": "100.24389",
        "iso": "TH",
        "status": 1,
        "name": "Uttaradit Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.669443",
        "size": "small"
    },
    {
        "iata": "URT",
        "lon": "99.143196",
        "iso": "TH",
        "status": 1,
        "name": "Surat Thani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.133865",
        "size": "medium"
    },
    {
        "iata": "NAW",
        "lon": "101.75",
        "iso": "TH",
        "status": 1,
        "name": "Narathiwat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.516667",
        "size": "medium"
    },
    {
        "iata": "CJM",
        "lon": "99.361374",
        "iso": "TH",
        "status": 1,
        "name": "Chumphon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.711122",
        "size": "medium"
    },
    {
        "iata": "NST",
        "lon": "99.95",
        "iso": "TH",
        "status": 1,
        "name": "Nakhon Si Thammarat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.466667",
        "size": "medium"
    },
    {
        "iata": "KBV",
        "lon": "98.98062",
        "iso": "TH",
        "status": 1,
        "name": "Krabi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.097281",
        "size": "medium"
    },
    {
        "iata": "SGZ",
        "lon": "100.61667",
        "iso": "TH",
        "status": 1,
        "name": "Songkhla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.183889",
        "size": "medium"
    },
    {
        "iata": "PAN",
        "lon": "101.15833",
        "iso": "TH",
        "status": 1,
        "name": "Pattani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.777222",
        "size": "medium"
    },
    {
        "iata": "USM",
        "lon": "100.0628",
        "iso": "TH",
        "status": 1,
        "name": "Samui Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.555315",
        "size": "medium"
    },
    {
        "iata": "NST",
        "lon": "99.95",
        "iso": "TH",
        "status": 1,
        "name": "Cha Ian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.466667",
        "size": "medium"
    },
    {
        "iata": "HKT",
        "lon": "98.306435",
        "iso": "TH",
        "status": 1,
        "name": "Phuket International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "8.107619",
        "size": "medium"
    },
    {
        "iata": "UNN",
        "lon": "98.629166",
        "iso": "TH",
        "status": 1,
        "name": "Ranong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "9.852778",
        "size": "medium"
    },
    {
        "iata": "HDY",
        "lon": "100.39356",
        "iso": "TH",
        "status": 1,
        "name": "Hat Yai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.936764",
        "size": "medium"
    },
    {
        "iata": "TST",
        "lon": "99.61667",
        "iso": "TH",
        "status": 1,
        "name": "Trang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "7.5",
        "size": "medium"
    },
    {
        "iata": "UTH",
        "lon": "102.774506",
        "iso": "TH",
        "status": 1,
        "name": "Udon Thani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.38661",
        "size": "medium"
    },
    {
        "iata": "SNO",
        "lon": "104.1",
        "iso": "TH",
        "status": 1,
        "name": "Sakon Nakhon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.283333",
        "size": "medium"
    },
    {
        "iata": "KKC",
        "lon": "102.78749",
        "iso": "TH",
        "status": 1,
        "name": "Khon Kaen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.46467",
        "size": "medium"
    },
    {
        "iata": "LOE",
        "lon": "101.72187",
        "iso": "TH",
        "status": 1,
        "name": "Loei Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.437847",
        "size": "medium"
    },
    {
        "iata": "BFV",
        "lon": "103.25528",
        "iso": "TH",
        "status": 1,
        "name": "Buri Ram Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.226944",
        "size": "medium"
    },
    {
        "iata": "NAK",
        "lon": "102.31411",
        "iso": "TH",
        "status": 1,
        "name": "Nakhon Ratchasima Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.950625",
        "size": "medium"
    },
    {
        "iata": "UBP",
        "lon": "104.87068",
        "iso": "TH",
        "status": 1,
        "name": "Ubon Ratchathani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.246491",
        "size": "medium"
    },
    {
        "iata": "ROI",
        "lon": "103.77695",
        "iso": "TH",
        "status": 1,
        "name": "Roi Et Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.115278",
        "size": "medium"
    },
    {
        "iata": "KOP",
        "lon": "104.75",
        "iso": "TH",
        "status": 1,
        "name": "Nakhon Phanom Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "17.4",
        "size": "medium"
    },
    {
        "iata": "VUU",
        "lon": "35.0",
        "iso": "MW",
        "status": 1,
        "name": "Mvuu Camp Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-15.683333",
        "size": "small"
    },
    {
        "iata": "BMV",
        "lon": "108.05",
        "iso": "VN",
        "status": 1,
        "name": "Buon Ma Thuot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.666667",
        "size": "medium"
    },
    {
        "iata": "VCL",
        "lon": "108.70389",
        "iso": "VN",
        "status": 1,
        "name": "Chu Lai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.422222",
        "size": "small"
    },
    {
        "iata": "HPH",
        "lon": "106.727325",
        "iso": "VN",
        "status": 1,
        "name": "Cat Bi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.823315",
        "size": "medium"
    },
    {
        "iata": "CXR",
        "lon": "109.225",
        "iso": "VN",
        "status": 1,
        "name": "Cam Ranh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.993611",
        "size": "medium"
    },
    {
        "iata": "VCS",
        "lon": "106.63333",
        "iso": "VN",
        "status": 1,
        "name": "Co Ong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.371389",
        "size": "medium"
    },
    {
        "iata": "VCA",
        "lon": "105.76639",
        "iso": "VN",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "10.047222",
        "size": "medium"
    },
    {
        "iata": "DIN",
        "lon": "103.0",
        "iso": "VN",
        "status": 1,
        "name": "Dien Bien Phu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.383333",
        "size": "medium"
    },
    {
        "iata": "DLI",
        "lon": "108.37603",
        "iso": "VN",
        "status": 1,
        "name": "Lien Khuong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.749689",
        "size": "small"
    },
    {
        "iata": "DAD",
        "lon": "108.20298",
        "iso": "VN",
        "status": 1,
        "name": "Da Nang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.055399",
        "size": "large"
    },
    {
        "iata": "VVN",
        "iso": "PE",
        "status": 1,
        "name": "Malvinas Airport",
        "continent": "SA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "HAN",
        "lon": "105.802826",
        "iso": "VN",
        "status": 1,
        "name": "Noi Bai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.214184",
        "size": "large"
    },
    {
        "iata": "SQH",
        "lon": "104.025",
        "iso": "VN",
        "status": 1,
        "name": "Na-San Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.218056",
        "size": "small"
    },
    {
        "iata": "NHA",
        "lon": "109.19666",
        "iso": "VN",
        "status": 1,
        "name": "Nha Trang Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "12.227912",
        "size": "medium"
    },
    {
        "iata": "HUI",
        "lon": "107.700874",
        "iso": "VN",
        "status": 1,
        "name": "Phu Bai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.398224",
        "size": "medium"
    },
    {
        "iata": "UIH",
        "lon": "109.22639",
        "iso": "VN",
        "status": 1,
        "name": "Phu Cat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.766111",
        "size": "medium"
    },
    {
        "iata": "PXU",
        "lon": "108.00896",
        "iso": "VN",
        "status": 1,
        "name": "Pleiku Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.006346",
        "size": "medium"
    },
    {
        "iata": "PQC",
        "lon": "103.96324",
        "iso": "VN",
        "status": 1,
        "name": "Phu Quoc Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.223722",
        "size": "medium"
    },
    {
        "iata": "PHA",
        "lon": "108.95",
        "iso": "VN",
        "status": 1,
        "name": "Phan Rang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "11.633333",
        "size": "small"
    },
    {
        "iata": "PHH",
        "lon": "108.066666",
        "iso": "VN",
        "status": 1,
        "name": "Phan Thiet Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.9",
        "size": "small"
    },
    {
        "iata": "VKG",
        "lon": "105.083336",
        "iso": "VN",
        "status": 1,
        "name": "Rach Gia Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.083333",
        "size": "medium"
    },
    {
        "iata": "TBB",
        "lon": "109.33972",
        "iso": "VN",
        "status": 1,
        "name": "Dong Tac Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "13.044167",
        "size": "medium"
    },
    {
        "iata": "SGN",
        "lon": "106.662476",
        "iso": "VN",
        "status": 1,
        "name": "Tan Son Nhat International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.813045",
        "size": "large"
    },
    {
        "iata": "VII",
        "lon": "105.63333",
        "iso": "VN",
        "status": 1,
        "name": "Vinh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.7",
        "size": "medium"
    },
    {
        "iata": "VTG",
        "lon": "107.09111",
        "iso": "VN",
        "status": 1,
        "name": "Vung Tau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.374444",
        "size": "small"
    },
    {
        "iata": "NYU",
        "lon": "94.92832",
        "iso": "MM",
        "status": 1,
        "name": "Bagan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.175343",
        "size": "small"
    },
    {
        "iata": "BMO",
        "lon": "97.25166",
        "iso": "MM",
        "status": 1,
        "name": "Banmaw Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.27389",
        "size": "small"
    },
    {
        "iata": "TVY",
        "lon": "98.2",
        "iso": "MM",
        "status": 1,
        "name": "Dawei Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.1",
        "size": "medium"
    },
    {
        "iata": "NYT",
        "lon": "96.201385",
        "iso": "MM",
        "status": 1,
        "name": "Naypyidaw Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.6225",
        "size": "small"
    },
    {
        "iata": "GAW",
        "lon": "94.13333",
        "iso": "MM",
        "status": 1,
        "name": "Gangaw Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.166668",
        "size": "small"
    },
    {
        "iata": "GWA",
        "lon": "94.0",
        "iso": "MM",
        "status": 1,
        "name": "Gwa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.0",
        "size": "small"
    },
    {
        "iata": "HEH",
        "lon": "96.79341",
        "iso": "MM",
        "status": 1,
        "name": "Heho Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.743862",
        "size": "medium"
    },
    {
        "iata": "HOX",
        "lon": "94.916664",
        "iso": "MM",
        "status": 1,
        "name": "Hommalinn Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.866667",
        "size": "small"
    },
    {
        "iata": "TIO",
        "lon": "96.0",
        "iso": "MM",
        "status": 1,
        "name": "Tilin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.0",
        "size": "small"
    },
    {
        "iata": "KET",
        "lon": "99.61667",
        "iso": "MM",
        "status": 1,
        "name": "Kengtung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.3",
        "size": "medium"
    },
    {
        "iata": "KHM",
        "lon": "95.67965",
        "iso": "MM",
        "status": 1,
        "name": "Kanti Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.993513",
        "size": "small"
    },
    {
        "iata": "KMV",
        "lon": "94.05",
        "iso": "MM",
        "status": 1,
        "name": "Kalay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.183332",
        "size": "small"
    },
    {
        "iata": "KYP",
        "lon": "93.53333",
        "iso": "MM",
        "status": 1,
        "name": "Kyaukpyu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.433332",
        "size": "medium"
    },
    {
        "iata": "KAW",
        "lon": "98.51667",
        "iso": "MM",
        "status": 1,
        "name": "Kawthoung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "10.05",
        "size": "medium"
    },
    {
        "iata": "KYT",
        "lon": "94.14411",
        "iso": "MM",
        "status": 1,
        "name": "Kyauktu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.414576",
        "size": "small"
    },
    {
        "iata": "LIW",
        "lon": "97.21694",
        "iso": "MM",
        "status": 1,
        "name": "Loikaw Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.68861",
        "size": "medium"
    },
    {
        "iata": "LSH",
        "lon": "97.75",
        "iso": "MM",
        "status": 1,
        "name": "Lashio Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.966667",
        "size": "medium"
    },
    {
        "iata": "MDL",
        "lon": "96.0875",
        "iso": "MM",
        "status": 1,
        "name": "Mandalay International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.940052",
        "size": "large"
    },
    {
        "iata": "MGZ",
        "lon": "98.61667",
        "iso": "MM",
        "status": 1,
        "name": "Myeik Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "12.45",
        "size": "medium"
    },
    {
        "iata": "MYT",
        "lon": "97.29889",
        "iso": "MM",
        "status": 1,
        "name": "Myitkyina Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.354168",
        "size": "medium"
    },
    {
        "iata": "MNU",
        "lon": "97.66067",
        "iso": "MM",
        "status": 1,
        "name": "Mawlamyine Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.444748",
        "size": "small"
    },
    {
        "iata": "MGU",
        "lon": "93.73889",
        "iso": "MM",
        "status": 1,
        "name": "Manaung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.958332",
        "size": "small"
    },
    {
        "iata": "MOE",
        "lon": "96.65",
        "iso": "MM",
        "status": 1,
        "name": "Momeik Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.1",
        "size": "medium"
    },
    {
        "iata": "MOG",
        "lon": "99.26667",
        "iso": "MM",
        "status": 1,
        "name": "Mong Hsat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.533333",
        "size": "medium"
    },
    {
        "iata": "MGK",
        "lon": "98.9",
        "iso": "MM",
        "status": 1,
        "name": "Mong Tong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.283333",
        "size": "small"
    },
    {
        "iata": "MWQ",
        "lon": "94.94111",
        "iso": "MM",
        "status": 1,
        "name": "Magway Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.166668",
        "size": "small"
    },
    {
        "iata": "NMS",
        "lon": "97.75",
        "iso": "MM",
        "status": 1,
        "name": "Namsang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.883333",
        "size": "medium"
    },
    {
        "iata": "NMT",
        "lon": "97.4",
        "iso": "MM",
        "status": 1,
        "name": "Namtu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.083332",
        "size": "small"
    },
    {
        "iata": "PAA",
        "lon": "97.67833",
        "iso": "MM",
        "status": 1,
        "name": "Hpa-N Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.892778",
        "size": "small"
    },
    {
        "iata": "PAU",
        "lon": "94.51667",
        "iso": "MM",
        "status": 1,
        "name": "Pauk Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.45",
        "size": "small"
    },
    {
        "iata": "BSX",
        "lon": "94.78333",
        "iso": "MM",
        "status": 1,
        "name": "Pathein Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.8",
        "size": "small"
    },
    {
        "iata": "PPU",
        "lon": "97.45",
        "iso": "MM",
        "status": 1,
        "name": "Hpapun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.066668",
        "size": "small"
    },
    {
        "iata": "PBU",
        "lon": "97.416664",
        "iso": "MM",
        "status": 1,
        "name": "Putao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.333332",
        "size": "medium"
    },
    {
        "iata": "PKK",
        "lon": "95.10639",
        "iso": "MM",
        "status": 1,
        "name": "Pakhokku Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.343332",
        "size": "small"
    },
    {
        "iata": "PRU",
        "lon": "95.26861",
        "iso": "MM",
        "status": 1,
        "name": "Pyay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.825",
        "size": "small"
    },
    {
        "iata": "AKY",
        "lon": "92.88028",
        "iso": "MM",
        "status": 1,
        "name": "Sittwe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.130278",
        "size": "medium"
    },
    {
        "iata": "SNW",
        "lon": "94.30038",
        "iso": "MM",
        "status": 1,
        "name": "Thandwe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.453876",
        "size": "medium"
    },
    {
        "iata": "THL",
        "lon": "99.95",
        "iso": "MM",
        "status": 1,
        "name": "Tachileik Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "20.45",
        "size": "medium"
    },
    {
        "iata": "XYE",
        "lon": "97.85",
        "iso": "MM",
        "status": 1,
        "name": "Ye Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "15.25",
        "size": "small"
    },
    {
        "iata": "RGN",
        "lon": "96.134155",
        "iso": "MM",
        "status": 1,
        "name": "Yangon International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "16.900068",
        "size": "large"
    },
    {
        "iata": "FBS",
        "iso": "US",
        "status": 1,
        "name": "Friday Harbor Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "UPG",
        "lon": "119.54589",
        "iso": "ID",
        "status": 1,
        "name": "Hasanuddin International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.058312",
        "size": "large"
    },
    {
        "iata": "MJU",
        "lon": "118.833336",
        "iso": "ID",
        "status": 1,
        "name": "Tampa Padang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.5",
        "size": "small"
    },
    {
        "iata": "BIK",
        "lon": "136.10582",
        "iso": "ID",
        "status": 1,
        "name": "Frans Kaisiepo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.191919",
        "size": "medium"
    },
    {
        "iata": "ONI",
        "lon": "135.5",
        "iso": "ID",
        "status": 1,
        "name": "Moanamani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.333333",
        "size": "small"
    },
    {
        "iata": "WET",
        "lon": "135.83333",
        "iso": "ID",
        "status": 1,
        "name": "Wagethe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.166667",
        "size": "small"
    },
    {
        "iata": "NBX",
        "lon": "135.43333",
        "iso": "ID",
        "status": 1,
        "name": "Nabire Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.366667",
        "size": "medium"
    },
    {
        "iata": "ILA",
        "lon": "133.73334",
        "iso": "ID",
        "status": 1,
        "name": "Illaga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.65",
        "size": "small"
    },
    {
        "iata": "KOX",
        "lon": "136.41667",
        "iso": "ID",
        "status": 1,
        "name": "Kokonau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.716667",
        "size": "small"
    },
    {
        "iata": "ZRI",
        "lon": "136.23334",
        "iso": "ID",
        "status": 1,
        "name": "Serui Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.883333",
        "size": "small"
    },
    {
        "iata": "TIM",
        "lon": "136.88748",
        "iso": "ID",
        "status": 1,
        "name": "Moses Kilangin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.525666",
        "size": "medium"
    },
    {
        "iata": "EWI",
        "lon": "136.33333",
        "iso": "ID",
        "status": 1,
        "name": "Enarotali Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.966667",
        "size": "small"
    },
    {
        "iata": "AMI",
        "lon": "116.10237",
        "iso": "ID",
        "status": 1,
        "name": "Selaparang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.563165",
        "size": "small"
    },
    {
        "iata": "BMU",
        "lon": "118.916664",
        "iso": "ID",
        "status": 1,
        "name": "Muhammad Salahuddin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.5",
        "size": "small"
    },
    {
        "iata": "DPS",
        "lon": "115.1675",
        "iso": "ID",
        "status": 1,
        "name": "Ngurah Rai (Bali) International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.748056",
        "size": "large"
    },
    {
        "iata": "LOP",
        "lon": "116.27667",
        "iso": "ID",
        "status": 1,
        "name": "Bandara International Lombok Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.757322",
        "size": "medium"
    },
    {
        "iata": "SWQ",
        "lon": "117.41217",
        "iso": "ID",
        "status": 1,
        "name": "Sumbawa Besar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.489621",
        "size": "small"
    },
    {
        "iata": "TMC",
        "lon": "119.18306",
        "iso": "ID",
        "status": 1,
        "name": "Tambolaka Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-9.340278",
        "size": "small"
    },
    {
        "iata": "WGP",
        "lon": "120.3",
        "iso": "ID",
        "status": 1,
        "name": "Waingapu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-9.666667",
        "size": "small"
    },
    {
        "iata": "ARJ",
        "lon": "140.78334",
        "iso": "ID",
        "status": 1,
        "name": "Arso Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.933333",
        "size": "small"
    },
    {
        "iata": "BUI",
        "lon": "133.58333",
        "iso": "ID",
        "status": 1,
        "name": "Bokondini Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.0",
        "size": "small"
    },
    {
        "iata": "ZRM",
        "lon": "138.75",
        "iso": "ID",
        "status": 1,
        "name": "Sarmi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.85",
        "size": "small"
    },
    {
        "iata": "DJJ",
        "lon": "140.51299",
        "iso": "ID",
        "status": 1,
        "name": "Sentani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.569887",
        "size": "medium"
    },
    {
        "iata": "LHI",
        "lon": "139.9",
        "iso": "ID",
        "status": 1,
        "name": "Lereh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.133333",
        "size": "small"
    },
    {
        "iata": "LII",
        "lon": "137.96666",
        "iso": "ID",
        "status": 1,
        "name": "Mulia Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.733333",
        "size": "small"
    },
    {
        "iata": "OKL",
        "lon": "140.66667",
        "iso": "ID",
        "status": 1,
        "name": "Oksibil Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.1",
        "size": "small"
    },
    {
        "iata": "WAR",
        "lon": "140.88333",
        "iso": "ID",
        "status": 1,
        "name": "Waris Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.116667",
        "size": "small"
    },
    {
        "iata": "SEH",
        "lon": "140.81667",
        "iso": "ID",
        "status": 1,
        "name": "Senggeh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.433333",
        "size": "small"
    },
    {
        "iata": "UBR",
        "lon": "140.85",
        "iso": "ID",
        "status": 1,
        "name": "Ubrub Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.666667",
        "size": "small"
    },
    {
        "iata": "WMX",
        "lon": "138.9536",
        "iso": "ID",
        "status": 1,
        "name": "Wamena Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.099906",
        "size": "medium"
    },
    {
        "iata": "MDP",
        "lon": "140.83333",
        "iso": "ID",
        "status": 1,
        "name": "Mindiptana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.833333",
        "size": "small"
    },
    {
        "iata": "BXD",
        "lon": "139.5",
        "iso": "ID",
        "status": 1,
        "name": "Bade Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.166667",
        "size": "small"
    },
    {
        "iata": "MKQ",
        "lon": "140.41895",
        "iso": "ID",
        "status": 1,
        "name": "Mopah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.519837",
        "size": "medium"
    },
    {
        "iata": "OKQ",
        "lon": "139.7",
        "iso": "ID",
        "status": 1,
        "name": "Okaba Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.1",
        "size": "small"
    },
    {
        "iata": "KEI",
        "lon": "139.33333",
        "iso": "ID",
        "status": 1,
        "name": "Kepi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.566667",
        "size": "small"
    },
    {
        "iata": "TMH",
        "lon": "140.26666",
        "iso": "ID",
        "status": 1,
        "name": "Tanah Merah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.083333",
        "size": "small"
    },
    {
        "iata": "TJS",
        "lon": "117.36667",
        "iso": "ID",
        "status": 1,
        "name": "Tanjung Harapan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.85",
        "size": "small"
    },
    {
        "iata": "DTD",
        "lon": "116.60037",
        "iso": "ID",
        "status": 1,
        "name": "Datadawai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.621316",
        "size": "small"
    },
    {
        "iata": "BEJ",
        "lon": "117.43349",
        "iso": "ID",
        "status": 1,
        "name": "Barau(Kalimaru) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.155327",
        "size": "small"
    },
    {
        "iata": "BPN",
        "lon": "116.90082",
        "iso": "ID",
        "status": 1,
        "name": "Sepinggan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.260623",
        "size": "medium"
    },
    {
        "iata": "TRK",
        "lon": "117.56528",
        "iso": "ID",
        "status": 1,
        "name": "Juwata Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.327222",
        "size": "small"
    },
    {
        "iata": "SRI",
        "lon": "117.15786",
        "iso": "ID",
        "status": 1,
        "name": "Temindung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.483589",
        "size": "small"
    },
    {
        "iata": "TSX",
        "lon": "117.433334",
        "iso": "ID",
        "status": 1,
        "name": "Tanjung Santan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.083333",
        "size": "small"
    },
    {
        "iata": "BYQ",
        "lon": "117.833336",
        "iso": "ID",
        "status": 1,
        "name": "Bunyu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.583333",
        "size": "small"
    },
    {
        "iata": "GLX",
        "lon": "127.833336",
        "iso": "ID",
        "status": 1,
        "name": "Gamarmalamo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.833333",
        "size": "small"
    },
    {
        "iata": "GTO",
        "lon": "122.850876",
        "iso": "ID",
        "status": 1,
        "name": "Jalaluddin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.639039",
        "size": "small"
    },
    {
        "iata": "NAH",
        "lon": "125.36667",
        "iso": "ID",
        "status": 1,
        "name": "Naha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.716667",
        "size": "medium"
    },
    {
        "iata": "TLI",
        "lon": "120.79515",
        "iso": "ID",
        "status": 1,
        "name": "Toli Toli Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.123356",
        "size": "small"
    },
    {
        "iata": "GEB",
        "lon": "129.41667",
        "iso": "ID",
        "status": 1,
        "name": "Gebe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.083333",
        "size": "small"
    },
    {
        "iata": "KAZ",
        "lon": "127.86667",
        "iso": "ID",
        "status": 1,
        "name": "Kao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.166667",
        "size": "small"
    },
    {
        "iata": "PLW",
        "lon": "119.90973",
        "iso": "ID",
        "status": 1,
        "name": "Mutiara Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.918617",
        "size": "medium"
    },
    {
        "iata": "MDC",
        "lon": "124.92203",
        "iso": "ID",
        "status": 1,
        "name": "Sam Ratulangi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.543533",
        "size": "medium"
    },
    {
        "iata": "MNA",
        "lon": "126.7",
        "iso": "ID",
        "status": 1,
        "name": "Melangguane Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.05",
        "size": "small"
    },
    {
        "iata": "PSJ",
        "lon": "120.73333",
        "iso": "ID",
        "status": 1,
        "name": "Kasiguncu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.383333",
        "size": "medium"
    },
    {
        "iata": "OTI",
        "lon": "128.5",
        "iso": "ID",
        "status": 1,
        "name": "Pitu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.833333",
        "size": "medium"
    },
    {
        "iata": "TTE",
        "lon": "127.4",
        "iso": "ID",
        "status": 1,
        "name": "Sultan Khairun Babullah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.8",
        "size": "medium"
    },
    {
        "iata": "LUW",
        "lon": "122.77642",
        "iso": "ID",
        "status": 1,
        "name": "Bubung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.038668",
        "size": "medium"
    },
    {
        "iata": "UOL",
        "lon": "121.41603",
        "iso": "ID",
        "status": 1,
        "name": "Buol Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.104373",
        "size": "small"
    },
    {
        "iata": "BTW",
        "lon": "116.0",
        "iso": "ID",
        "status": 1,
        "name": "Batu Licin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.0",
        "size": "small"
    },
    {
        "iata": "PKN",
        "lon": "111.666664",
        "iso": "ID",
        "status": 1,
        "name": "Iskandar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.7",
        "size": "small"
    },
    {
        "iata": "KBU",
        "lon": "116.16528",
        "iso": "ID",
        "status": 1,
        "name": "Stagen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.293738",
        "size": "small"
    },
    {
        "iata": "MTW",
        "lon": "-87.68222",
        "iso": "ID",
        "status": 1,
        "name": "Beringin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "44.129723",
        "size": "small"
    },
    {
        "iata": "TJG",
        "lon": "103.96667",
        "iso": "ID",
        "status": 1,
        "name": "Warukin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.4",
        "size": "medium"
    },
    {
        "iata": "BDJ",
        "lon": "114.75425",
        "iso": "ID",
        "status": 1,
        "name": "Syamsudin Noor Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.43804",
        "size": "small"
    },
    {
        "iata": "PKY",
        "lon": "113.945786",
        "iso": "ID",
        "status": 1,
        "name": "Tjilik Riwut Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.223958",
        "size": "small"
    },
    {
        "iata": "SMQ",
        "lon": "112.979004",
        "iso": "ID",
        "status": 1,
        "name": "Sampit(Hasan) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.502806",
        "size": "small"
    },
    {
        "iata": "AHI",
        "lon": "128.91667",
        "iso": "ID",
        "status": 1,
        "name": "Amahai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.333333",
        "size": "small"
    },
    {
        "iata": "DOB",
        "lon": "134.21361",
        "iso": "ID",
        "status": 1,
        "name": "Dobo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.772536",
        "size": "small"
    },
    {
        "iata": "MAL",
        "lon": "125.916664",
        "iso": "ID",
        "status": 1,
        "name": "Mangole Airport, Falabisahaya",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.833333",
        "size": "small"
    },
    {
        "iata": "NRE",
        "lon": "126.71667",
        "iso": "ID",
        "status": 1,
        "name": "Namrole Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.816667",
        "size": "small"
    },
    {
        "iata": "LAH",
        "lon": "127.48333",
        "iso": "ID",
        "status": 1,
        "name": "Oesman Sadik Airport, Labuha",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.616667",
        "size": "small"
    },
    {
        "iata": "SXK",
        "lon": "131.30482",
        "iso": "ID",
        "status": 1,
        "name": "Saumlaki Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.988139",
        "size": "small"
    },
    {
        "iata": "BJK",
        "lon": "134.25",
        "iso": "ID",
        "status": 1,
        "name": "Nangasuri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.083333",
        "size": "small"
    },
    {
        "iata": "LUV",
        "lon": "132.71666",
        "iso": "ID",
        "status": 1,
        "name": "Dumatumbun Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.666667",
        "size": "medium"
    },
    {
        "iata": "SQN",
        "lon": "125.916664",
        "iso": "ID",
        "status": 1,
        "name": "Emalamo Sanana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.2",
        "size": "small"
    },
    {
        "iata": "AMQ",
        "lon": "128.08888",
        "iso": "ID",
        "status": 1,
        "name": "Pattimura Airport, Ambon",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.704996",
        "size": "medium"
    },
    {
        "iata": "NAM",
        "lon": "127.1",
        "iso": "ID",
        "status": 1,
        "name": "Namlea Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.3",
        "size": "small"
    },
    {
        "iata": "TAX",
        "lon": "124.8",
        "iso": "ID",
        "status": 1,
        "name": "Taliabu Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.766667",
        "size": "small"
    },
    {
        "iata": "MLG",
        "lon": "112.71099",
        "iso": "ID",
        "status": 1,
        "name": "Abdul Rachman Saleh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.930615",
        "size": "small"
    },
    {
        "iata": "CPF",
        "lon": "111.583336",
        "iso": "ID",
        "status": 1,
        "name": "Cepu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.15",
        "size": "small"
    },
    {
        "iata": "JOG",
        "lon": "110.43706",
        "iso": "ID",
        "status": 1,
        "name": "Adi Sutjipto International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.785572",
        "size": "small"
    },
    {
        "iata": "SOC",
        "lon": "110.75667",
        "iso": "ID",
        "status": 1,
        "name": "Adi Sumarmo Wiryokusumo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.51583",
        "size": "small"
    },
    {
        "iata": "SUB",
        "lon": "112.77724",
        "iso": "ID",
        "status": 1,
        "name": "Juanda International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.38387",
        "size": "large"
    },
    {
        "iata": "SRG",
        "lon": "110.37855",
        "iso": "ID",
        "status": 1,
        "name": "Achmad Yani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.979155",
        "size": "small"
    },
    {
        "iata": "SUP",
        "lon": "113.933334",
        "iso": "ID",
        "status": 1,
        "name": "Trunojoyo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.066667",
        "size": "small"
    },
    {
        "iata": "NTI",
        "lon": "133.25",
        "iso": "ID",
        "status": 1,
        "name": "Stenkol Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.333333",
        "size": "small"
    },
    {
        "iata": "RSK",
        "lon": "134.18333",
        "iso": "ID",
        "status": 1,
        "name": "Abresso Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.5",
        "size": "small"
    },
    {
        "iata": "KEQ",
        "lon": "134.83333",
        "iso": "ID",
        "status": 1,
        "name": "Kebar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.833333",
        "size": "small"
    },
    {
        "iata": "FKQ",
        "lon": "132.3",
        "iso": "ID",
        "status": 1,
        "name": "Fakfak Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.916667",
        "size": "medium"
    },
    {
        "iata": "INX",
        "lon": "132.23334",
        "iso": "ID",
        "status": 1,
        "name": "Inanwatan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.166667",
        "size": "small"
    },
    {
        "iata": "KNG",
        "lon": "133.75",
        "iso": "ID",
        "status": 1,
        "name": "Kaimana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.65",
        "size": "medium"
    },
    {
        "iata": "RDE",
        "lon": "133.33333",
        "iso": "ID",
        "status": 1,
        "name": "Merdei Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.583333",
        "size": "small"
    },
    {
        "iata": "BXB",
        "lon": "133.44115",
        "iso": "ID",
        "status": 1,
        "name": "Babo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.532486",
        "size": "medium"
    },
    {
        "iata": "MKW",
        "lon": "134.05278",
        "iso": "ID",
        "status": 1,
        "name": "Rendani Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.893056",
        "size": "medium"
    },
    {
        "iata": "SOQ",
        "lon": "131.29059",
        "iso": "ID",
        "status": 1,
        "name": "Sorong (Jefman) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.890214",
        "size": "medium"
    },
    {
        "iata": "TXM",
        "lon": "132.01666",
        "iso": "ID",
        "status": 1,
        "name": "Teminabuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.433333",
        "size": "small"
    },
    {
        "iata": "WSR",
        "lon": "134.5",
        "iso": "ID",
        "status": 1,
        "name": "Wasior Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.716667",
        "size": "small"
    },
    {
        "iata": "BJW",
        "lon": "120.98333",
        "iso": "ID",
        "status": 1,
        "name": "Bajawa Padhameleda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.766667",
        "size": "small"
    },
    {
        "iata": "MOF",
        "lon": "122.25",
        "iso": "ID",
        "status": 1,
        "name": "Maumere(Wai Oti) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.633333",
        "size": "small"
    },
    {
        "iata": "ENE",
        "lon": "121.65",
        "iso": "ID",
        "status": 1,
        "name": "Ende (H Hasan Aroeboesman) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.833333",
        "size": "small"
    },
    {
        "iata": "RTG",
        "lon": "120.47835",
        "iso": "ID",
        "status": 1,
        "name": "Satar Tacik Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.596921",
        "size": "small"
    },
    {
        "iata": "ARD",
        "lon": "124.59811",
        "iso": "ID",
        "status": 1,
        "name": "Mali Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.132639",
        "size": "small"
    },
    {
        "iata": "LBJ",
        "lon": "119.88928",
        "iso": "ID",
        "status": 1,
        "name": "Komodo (Mutiara II) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.486215",
        "size": "small"
    },
    {
        "iata": "KOE",
        "lon": "123.66725",
        "iso": "ID",
        "status": 1,
        "name": "El Tari Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-10.168536",
        "size": "small"
    },
    {
        "iata": "BUW",
        "lon": "122.63333",
        "iso": "ID",
        "status": 1,
        "name": "Betoambari Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.466667",
        "size": "small"
    },
    {
        "iata": "MXB",
        "lon": "120.32389",
        "iso": "ID",
        "status": 1,
        "name": "Andi Jemma Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.557957",
        "size": "small"
    },
    {
        "iata": "SQR",
        "lon": "122.666664",
        "iso": "ID",
        "status": 1,
        "name": "Soroako Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.166667",
        "size": "small"
    },
    {
        "iata": "TTR",
        "lon": "119.82094",
        "iso": "ID",
        "status": 1,
        "name": "Pongtiku Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.042564",
        "size": "small"
    },
    {
        "iata": "KDI",
        "lon": "122.409546",
        "iso": "ID",
        "status": 1,
        "name": "Wolter Monginsidi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-4.085501",
        "size": "small"
    },
    {
        "iata": "BTU",
        "lon": "113.04346",
        "iso": "MY",
        "status": 1,
        "name": "Bintulu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.171772",
        "size": "medium"
    },
    {
        "iata": "BLG",
        "lon": "113.78333",
        "iso": "MY",
        "status": 1,
        "name": "Belaga Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.7",
        "size": "small"
    },
    {
        "iata": "LSM",
        "lon": "118.00833",
        "iso": "MY",
        "status": 1,
        "name": "Long Semado Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.002778",
        "size": "small"
    },
    {
        "iata": "LGL",
        "lon": "114.25",
        "iso": "MY",
        "status": 1,
        "name": "Long Lellang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.183333",
        "size": "small"
    },
    {
        "iata": "KCH",
        "lon": "110.340836",
        "iso": "MY",
        "status": 1,
        "name": "Kuching International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.484167",
        "size": "medium"
    },
    {
        "iata": "ODN",
        "lon": "115.066666",
        "iso": "MY",
        "status": 1,
        "name": "Long Seridan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.033333",
        "size": "small"
    },
    {
        "iata": "LMN",
        "lon": "115.0",
        "iso": "MY",
        "status": 1,
        "name": "Limbang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.666667",
        "size": "medium"
    },
    {
        "iata": "MKM",
        "lon": "112.07972",
        "iso": "MY",
        "status": 1,
        "name": "Mukah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.906944",
        "size": "small"
    },
    {
        "iata": "LKH",
        "lon": "114.78566",
        "iso": "MY",
        "status": 1,
        "name": "Long Akah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.312934",
        "size": "small"
    },
    {
        "iata": "MUR",
        "lon": "114.316666",
        "iso": "MY",
        "status": 1,
        "name": "Marudi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.183333",
        "size": "medium"
    },
    {
        "iata": "BSE",
        "lon": "109.76667",
        "iso": "MY",
        "status": 1,
        "name": "Sematan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.8",
        "size": "small"
    },
    {
        "iata": "KPI",
        "lon": "112.92944",
        "iso": "MY",
        "status": 1,
        "name": "Kapit Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.010556",
        "size": "small"
    },
    {
        "iata": "BKM",
        "lon": "115.61667",
        "iso": "MY",
        "status": 1,
        "name": "Bakalalan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.966667",
        "size": "small"
    },
    {
        "iata": "MYY",
        "lon": "113.983116",
        "iso": "MY",
        "status": 1,
        "name": "Miri Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.325261",
        "size": "medium"
    },
    {
        "iata": "SBW",
        "lon": "111.98657",
        "iso": "MY",
        "status": 1,
        "name": "Sibu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.254997",
        "size": "medium"
    },
    {
        "iata": "TGC",
        "lon": "111.20335",
        "iso": "MY",
        "status": 1,
        "name": "Tanjung Manis Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.176315",
        "size": "small"
    },
    {
        "iata": "LSU",
        "lon": "115.5",
        "iso": "MY",
        "status": 1,
        "name": "Long Sukang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.55",
        "size": "small"
    },
    {
        "iata": "LWY",
        "lon": "115.416664",
        "iso": "MY",
        "status": 1,
        "name": "Lawas Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.916667",
        "size": "small"
    },
    {
        "iata": "SGG",
        "lon": "-36.378273",
        "iso": "MY",
        "status": 1,
        "name": "Simanggang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "65.90608",
        "size": "small"
    },
    {
        "iata": "BBN",
        "lon": "115.46667",
        "iso": "MY",
        "status": 1,
        "name": "Bario Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.683333",
        "size": "medium"
    },
    {
        "iata": "SMM",
        "lon": "118.833336",
        "iso": "MY",
        "status": 1,
        "name": "Semporna Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.416667",
        "size": "small"
    },
    {
        "iata": "LDU",
        "lon": "118.32611",
        "iso": "MY",
        "status": 1,
        "name": "Lahad Datu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.035278",
        "size": "medium"
    },
    {
        "iata": "TEL",
        "lon": "117.11667",
        "iso": "MY",
        "status": 1,
        "name": "Telupid Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.583333",
        "size": "small"
    },
    {
        "iata": "KGU",
        "lon": "116.16194",
        "iso": "MY",
        "status": 1,
        "name": "Keningau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.356667",
        "size": "small"
    },
    {
        "iata": "SXS",
        "lon": "119.09167",
        "iso": "MY",
        "status": 1,
        "name": "Sahabat [Sahabat 16] Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.089722",
        "size": "small"
    },
    {
        "iata": "BKI",
        "lon": "116.05075",
        "iso": "MY",
        "status": 1,
        "name": "Kota Kinabalu International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.923961",
        "size": "medium"
    },
    {
        "iata": "LBU",
        "lon": "115.24916",
        "iso": "MY",
        "status": 1,
        "name": "Labuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.294355",
        "size": "medium"
    },
    {
        "iata": "TMG",
        "lon": "118.65",
        "iso": "MY",
        "status": 1,
        "name": "Tomanggong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.4",
        "size": "small"
    },
    {
        "iata": "GSA",
        "lon": "115.76667",
        "iso": "MY",
        "status": 1,
        "name": "Long Pasia Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.416667",
        "size": "small"
    },
    {
        "iata": "SPE",
        "lon": "116.45695",
        "iso": "MY",
        "status": 1,
        "name": "Sepulot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.712222",
        "size": "small"
    },
    {
        "iata": "PAY",
        "lon": "117.39445",
        "iso": "MY",
        "status": 1,
        "name": "Pamol Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.993056",
        "size": "small"
    },
    {
        "iata": "RNU",
        "lon": "116.666664",
        "iso": "MY",
        "status": 1,
        "name": "Ranau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.95",
        "size": "small"
    },
    {
        "iata": "SDK",
        "lon": "118.06184",
        "iso": "MY",
        "status": 1,
        "name": "Sandakan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.896877",
        "size": "medium"
    },
    {
        "iata": "KUD",
        "lon": "116.83417",
        "iso": "MY",
        "status": 1,
        "name": "Kudat Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.925",
        "size": "small"
    },
    {
        "iata": "TWU",
        "lon": "117.884865",
        "iso": "MY",
        "status": 1,
        "name": "Tawau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.26275",
        "size": "medium"
    },
    {
        "iata": "MZV",
        "lon": "114.8",
        "iso": "MY",
        "status": 1,
        "name": "Mulu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.033333",
        "size": "medium"
    },
    {
        "iata": "BWN",
        "lon": "114.93375",
        "iso": "BN",
        "status": 1,
        "name": "Brunei International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.945197",
        "size": "large"
    },
    {
        "iata": "TKG",
        "lon": "105.1758",
        "iso": "ID",
        "status": 1,
        "name": "Radin Inten II (Branti) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-5.242783",
        "size": "medium"
    },
    {
        "iata": "PKU",
        "lon": "101.446884",
        "iso": "ID",
        "status": 1,
        "name": "Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.464563",
        "size": "medium"
    },
    {
        "iata": "DUM",
        "lon": "101.46667",
        "iso": "ID",
        "status": 1,
        "name": "Pinang Kampai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.583333",
        "size": "medium"
    },
    {
        "iata": "RKO",
        "lon": "116.0",
        "iso": "ID",
        "status": 1,
        "name": "Rokot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.083333",
        "size": "small"
    },
    {
        "iata": "SEQ",
        "lon": "114.066666",
        "iso": "ID",
        "status": 1,
        "name": "Sungai Pakning Bengkalis Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.8",
        "size": "small"
    },
    {
        "iata": "TJB",
        "lon": "99.73333",
        "iso": "ID",
        "status": 1,
        "name": "Tanjung Balai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.916667",
        "size": "small"
    },
    {
        "iata": "BDO",
        "lon": "107.58178",
        "iso": "ID",
        "status": 1,
        "name": "Husein Sastranegara International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.904648",
        "size": "medium"
    },
    {
        "iata": "CBN",
        "lon": "108.53333",
        "iso": "ID",
        "status": 1,
        "name": "Penggung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.75",
        "size": "small"
    },
    {
        "iata": "TSY",
        "lon": "108.2",
        "iso": "ID",
        "status": 1,
        "name": "Cibeureum Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.3",
        "size": "small"
    },
    {
        "iata": "BTH",
        "lon": "104.11528",
        "iso": "ID",
        "status": 1,
        "name": "Hang Nadim Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.123627",
        "size": "medium"
    },
    {
        "iata": "PPR",
        "lon": "100.36667",
        "iso": "ID",
        "status": 1,
        "name": "Pasir Pangaraan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.833333",
        "size": "small"
    },
    {
        "iata": "TNJ",
        "lon": "104.53611",
        "iso": "ID",
        "status": 1,
        "name": "Kijang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.919167",
        "size": "small"
    },
    {
        "iata": "SIQ",
        "lon": "104.416664",
        "iso": "ID",
        "status": 1,
        "name": "Dabo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.5",
        "size": "small"
    },
    {
        "iata": "HLP",
        "lon": "106.89028",
        "iso": "ID",
        "status": 1,
        "name": "Halim Perdanakusuma International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.268056",
        "size": "medium"
    },
    {
        "iata": "CXP",
        "lon": "109.0",
        "iso": "ID",
        "status": 1,
        "name": "Tunggul Wulung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-7.733333",
        "size": "medium"
    },
    {
        "iata": "PCB",
        "lon": "106.76667",
        "iso": "ID",
        "status": 1,
        "name": "Pondok Cabe Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.35",
        "size": "small"
    },
    {
        "iata": "CGK",
        "lon": "106.655525",
        "iso": "ID",
        "status": 1,
        "name": "Soekarno-Hatta International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-6.130643",
        "size": "large"
    },
    {
        "iata": "LLG",
        "lon": "144.53334",
        "iso": "ID",
        "status": 1,
        "name": "Silampari Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-17.133333",
        "size": "small"
    },
    {
        "iata": "GNS",
        "lon": "97.5",
        "iso": "ID",
        "status": 1,
        "name": "Binaka Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.25",
        "size": "medium"
    },
    {
        "iata": "AEG",
        "lon": "99.416664",
        "iso": "ID",
        "status": 1,
        "name": "Aek Godang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.399722",
        "size": "medium"
    },
    {
        "iata": "PDG",
        "lon": "100.280556",
        "iso": "ID",
        "status": 1,
        "name": "Tabing Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.786667",
        "size": "medium"
    },
    {
        "iata": "MES",
        "lon": "98.672775",
        "iso": "ID",
        "status": 1,
        "name": "Polonia International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.561111",
        "size": "medium"
    },
    {
        "iata": "TJQ",
        "lon": "107.65",
        "iso": "ID",
        "status": 1,
        "name": "Buluh Tumbang (H A S Hanandjoeddin) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.75",
        "size": "small"
    },
    {
        "iata": "NPO",
        "lon": "111.73333",
        "iso": "ID",
        "status": 1,
        "name": "Nanga Pinoh I Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.333333",
        "size": "medium"
    },
    {
        "iata": "KTG",
        "lon": "109.98333",
        "iso": "ID",
        "status": 1,
        "name": "Ketapang(Rahadi Usman) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.833333",
        "size": "medium"
    },
    {
        "iata": "MWK",
        "lon": "106.26667",
        "iso": "ID",
        "status": 1,
        "name": "Tarempa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.3",
        "size": "small"
    },
    {
        "iata": "NTX",
        "lon": "108.38333",
        "iso": "ID",
        "status": 1,
        "name": "Ranai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.95",
        "size": "medium"
    },
    {
        "iata": "PNK",
        "lon": "109.40533",
        "iso": "ID",
        "status": 1,
        "name": "Supadio Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.14735",
        "size": "medium"
    },
    {
        "iata": "PSU",
        "lon": "112.933334",
        "iso": "ID",
        "status": 1,
        "name": "Pangsuma Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.833333",
        "size": "medium"
    },
    {
        "iata": "SQG",
        "lon": "111.583336",
        "iso": "ID",
        "status": 1,
        "name": "Sintang(Susilo) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.083333",
        "size": "medium"
    },
    {
        "iata": "DJB",
        "lon": "103.63936",
        "iso": "ID",
        "status": 1,
        "name": "Sultan Thaha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.630432",
        "size": "small"
    },
    {
        "iata": "PGK",
        "lon": "106.13707",
        "iso": "ID",
        "status": 1,
        "name": "Pangkal Pinang (Depati Amir) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.164017",
        "size": "small"
    },
    {
        "iata": "BKS",
        "lon": "102.34349",
        "iso": "ID",
        "status": 1,
        "name": "Padang Kemiling (Fatmawati Soekarno) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.866159",
        "size": "medium"
    },
    {
        "iata": "PLM",
        "lon": "104.69861",
        "iso": "ID",
        "status": 1,
        "name": "Sultan Mahmud Badaruddin Ii Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.900146",
        "size": "medium"
    },
    {
        "iata": "PDO",
        "lon": "103.86667",
        "iso": "ID",
        "status": 1,
        "name": "Pendopo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-3.283333",
        "size": "medium"
    },
    {
        "iata": "RGT",
        "lon": "102.55",
        "iso": "ID",
        "status": 1,
        "name": "Japura Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.4",
        "size": "medium"
    },
    {
        "iata": "PDG",
        "lon": "100.280556",
        "iso": "ID",
        "status": 1,
        "name": "Minangkabau Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.786667",
        "size": "medium"
    },
    {
        "iata": "MPC",
        "lon": "101.1",
        "iso": "ID",
        "status": 1,
        "name": "Muko Muko Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.55",
        "size": "small"
    },
    {
        "iata": "KLQ",
        "lon": "103.9",
        "iso": "ID",
        "status": 1,
        "name": "Keluang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-2.683333",
        "size": "small"
    },
    {
        "iata": "TPK",
        "lon": "97.183334",
        "iso": "ID",
        "status": 1,
        "name": "Teuku Cut Ali Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.266667",
        "size": "small"
    },
    {
        "iata": "SBG",
        "lon": "95.333336",
        "iso": "ID",
        "status": 1,
        "name": "Maimun Saleh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.55",
        "size": "small"
    },
    {
        "iata": "MEQ",
        "lon": "96.11667",
        "iso": "ID",
        "status": 1,
        "name": "Seunagan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.15",
        "size": "small"
    },
    {
        "iata": "LSX",
        "lon": "97.316666",
        "iso": "ID",
        "status": 1,
        "name": "Lhok Sukon Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.066667",
        "size": "medium"
    },
    {
        "iata": "LSW",
        "lon": "97.166664",
        "iso": "ID",
        "status": 1,
        "name": "Malikus Saleh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.166667",
        "size": "small"
    },
    {
        "iata": "BTJ",
        "lon": "95.41833",
        "iso": "ID",
        "status": 1,
        "name": "Sultan Iskandarmuda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.518021",
        "size": "medium"
    },
    {
        "iata": "SXT",
        "lon": "102.39667",
        "iso": "MY",
        "status": 1,
        "name": "Sungai Tiang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.330556",
        "size": "small"
    },
    {
        "iata": "MEP",
        "lon": "103.833336",
        "iso": "MY",
        "status": 1,
        "name": "Mersing Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.416667",
        "size": "small"
    },
    {
        "iata": "SWY",
        "lon": "100.70029",
        "iso": "MY",
        "status": 1,
        "name": "Sitiawan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.220703",
        "size": "small"
    },
    {
        "iata": "TPG",
        "lon": "100.73333",
        "iso": "MY",
        "status": 1,
        "name": "Taiping (Tekah) Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.85",
        "size": "small"
    },
    {
        "iata": "TOD",
        "lon": "104.166664",
        "iso": "MY",
        "status": 1,
        "name": "Pulau Tioman Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.75",
        "size": "medium"
    },
    {
        "iata": "AOR",
        "lon": "100.405266",
        "iso": "MY",
        "status": 1,
        "name": "Sultan Abdul Halim Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.201171",
        "size": "medium"
    },
    {
        "iata": "BWH",
        "lon": "100.3925",
        "iso": "MY",
        "status": 1,
        "name": "Butterworth Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.466667",
        "size": "medium"
    },
    {
        "iata": "KBR",
        "lon": "102.29092",
        "iso": "MY",
        "status": 1,
        "name": "Sultan Ismail Petra Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.17057",
        "size": "medium"
    },
    {
        "iata": "KUA",
        "lon": "103.21518",
        "iso": "MY",
        "status": 1,
        "name": "Kuantan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.780765",
        "size": "medium"
    },
    {
        "iata": "KTE",
        "lon": "103.42833",
        "iso": "MY",
        "status": 1,
        "name": "Kerteh Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.538333",
        "size": "medium"
    },
    {
        "iata": "IPH",
        "lon": "101.09583",
        "iso": "MY",
        "status": 1,
        "name": "Sultan Azlan Shah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.566944",
        "size": "medium"
    },
    {
        "iata": "JHB",
        "lon": "103.67083",
        "iso": "MY",
        "status": 1,
        "name": "Senai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.638889",
        "size": "medium"
    },
    {
        "iata": "KUL",
        "lon": "101.70539",
        "iso": "MY",
        "status": 1,
        "name": "Kuala Lumpur International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.755672",
        "size": "large"
    },
    {
        "iata": "LGK",
        "lon": "99.7314",
        "iso": "MY",
        "status": 1,
        "name": "Langkawi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "6.342174",
        "size": "medium"
    },
    {
        "iata": "MKZ",
        "lon": "102.253334",
        "iso": "MY",
        "status": 1,
        "name": "Malacca Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "2.264167",
        "size": "medium"
    },
    {
        "iata": "TGG",
        "lon": "103.10642",
        "iso": "MY",
        "status": 1,
        "name": "Sultan Mahmud Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.380504",
        "size": "medium"
    },
    {
        "iata": "PEN",
        "lon": "100.265175",
        "iso": "MY",
        "status": 1,
        "name": "Penang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.292961",
        "size": "medium"
    },
    {
        "iata": "PKG",
        "lon": "100.55355",
        "iso": "MY",
        "status": 1,
        "name": "Pulau Pangkor Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.244177",
        "size": "small"
    },
    {
        "iata": "RDN",
        "lon": "103.00694",
        "iso": "MY",
        "status": 1,
        "name": "LTS Pulau Redang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "5.765278",
        "size": "small"
    },
    {
        "iata": "SZB",
        "lon": "101.558075",
        "iso": "MY",
        "status": 1,
        "name": "Sultan Abdul Aziz Shah International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "3.130644",
        "size": "medium"
    },
    {
        "iata": "AUT",
        "lon": "125.583336",
        "iso": "TL",
        "status": 1,
        "name": "Atauro Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.216667",
        "size": "small"
    },
    {
        "iata": "UAI",
        "lon": "125.28333",
        "iso": "TL",
        "status": 1,
        "name": "Suai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-9.3",
        "size": "medium"
    },
    {
        "iata": "DIC",
        "iso": "TL",
        "status": 1,
        "name": "Dili City Heliport",
        "continent": "AS",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "DIL",
        "lon": "125.525",
        "iso": "TL",
        "status": 1,
        "name": "Presidente Nicolau Lobato International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.549616",
        "size": "medium"
    },
    {
        "iata": "BCH",
        "lon": "126.39972",
        "iso": "TL",
        "status": 1,
        "name": "Cakung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.491667",
        "size": "medium"
    },
    {
        "iata": "MPT",
        "lon": "125.25",
        "iso": "TL",
        "status": 1,
        "name": "Maliana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.066667",
        "size": "small"
    },
    {
        "iata": "OEC",
        "lon": "124.34167",
        "iso": "TL",
        "status": 1,
        "name": "Oecussi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-9.205",
        "size": "small"
    },
    {
        "iata": "VIQ",
        "lon": "126.36667",
        "iso": "TL",
        "status": 1,
        "name": "Viqueque Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.883333",
        "size": "small"
    },
    {
        "iata": "ABU",
        "lon": "124.88333",
        "iso": "ID",
        "status": 1,
        "name": "Haliwen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-9.133333",
        "size": "small"
    },
    {
        "iata": "BJW",
        "lon": "120.98333",
        "iso": "ID",
        "status": 1,
        "name": "Padhameleda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.766667",
        "size": "small"
    },
    {
        "iata": "LKA",
        "lon": "123.00131",
        "iso": "ID",
        "status": 1,
        "name": "Gewayentana Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-8.274301",
        "size": "small"
    },
    {
        "iata": "SAU",
        "lon": "121.9",
        "iso": "ID",
        "status": 1,
        "name": "Sawu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-10.483333",
        "size": "small"
    },
    {
        "iata": "SGQ",
        "lon": "117.333336",
        "iso": "ID",
        "status": 1,
        "name": "Sanggata Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.5",
        "size": "small"
    },
    {
        "iata": "LBW",
        "lon": "115.166664",
        "iso": "ID",
        "status": 1,
        "name": "Long Bawan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.583333",
        "size": "small"
    },
    {
        "iata": "BXT",
        "lon": "117.5",
        "iso": "ID",
        "status": 1,
        "name": "Bontang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "0.166667",
        "size": "small"
    },
    {
        "iata": "NNX",
        "lon": "117.65",
        "iso": "ID",
        "status": 1,
        "name": "Nunukan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "4.15",
        "size": "small"
    },
    {
        "iata": "TNB",
        "lon": "116.2",
        "iso": "ID",
        "status": 1,
        "name": "Tanah Grogot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-1.916667",
        "size": "small"
    },
    {
        "iata": "LPU",
        "lon": "114.969986",
        "iso": "ID",
        "status": 1,
        "name": "Long Apung Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.707091",
        "size": "small"
    },
    {
        "iata": "SZH",
        "lon": "117.13333",
        "iso": "ID",
        "status": 1,
        "name": "Senipah Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-0.966667",
        "size": "small"
    },
    {
        "iata": "QPG",
        "lon": "103.9",
        "iso": "SG",
        "status": 1,
        "name": "Paya Lebar Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "1.35",
        "size": "medium"
    },
    {
        "iata": "TGA",
        "lon": "103.780556",
        "iso": "SG",
        "status": 1,
        "name": "Tengah Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "1.426665",
        "size": "medium"
    },
    {
        "iata": "XSP",
        "lon": "103.96667",
        "iso": "SG",
        "status": 1,
        "name": "Seletar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.366667",
        "size": "medium"
    },
    {
        "iata": "SIN",
        "lon": "103.990204",
        "iso": "SG",
        "status": 1,
        "name": "Singapore Changi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "1.361173",
        "size": "large"
    },
    {
        "iata": "WWT",
        "iso": "US",
        "status": 1,
        "name": "Newtok Seaplane Base",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "BCD",
        "iso": "PH",
        "status": 0,
        "name": "Bacolod City Domestic Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "MNI",
        "iso": "MS",
        "status": 0,
        "name": "W. H. Bramble Airport",
        "continent": "NA",
        "type": "closed",
        "size": null
    },
    {
        "iata": "SKL",
        "lon": "-6.25",
        "iso": "GB",
        "status": 1,
        "name": "Skye Bridge Ashaig Airport",
        "continent": "EU",
        "type": "airport",
        "lat": "57.333332",
        "size": "small"
    },
    {
        "iata": "MUC",
        "iso": "DE",
        "status": 0,
        "name": "Munich-Reim Airport",
        "continent": "EU",
        "type": "closed",
        "size": null
    },
    {
        "iata": "ALH",
        "lon": "117.804",
        "iso": "AU",
        "status": 1,
        "name": "Albany Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.944996",
        "size": "medium"
    },
    {
        "iata": "ABG",
        "lon": "143.2",
        "iso": "AU",
        "status": 1,
        "name": "Abingdon Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.666668",
        "size": "small"
    },
    {
        "iata": "AWN",
        "lon": "138.66667",
        "iso": "AU",
        "status": 1,
        "name": "Alton Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.25",
        "size": "small"
    },
    {
        "iata": "AUD",
        "lon": "139.88167",
        "iso": "AU",
        "status": 1,
        "name": "Augustus Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.506945",
        "size": "small"
    },
    {
        "iata": "MRP",
        "lon": "135.0",
        "iso": "AU",
        "status": 1,
        "name": "Marla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.0",
        "size": "small"
    },
    {
        "iata": "AXL",
        "lon": "136.66667",
        "iso": "AU",
        "status": 1,
        "name": "Alexandria Homestead Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.083332",
        "size": "small"
    },
    {
        "iata": "AXC",
        "lon": "145.2425",
        "iso": "AU",
        "status": 1,
        "name": "Aramac Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.958055",
        "size": "small"
    },
    {
        "iata": "ADO",
        "lon": "137.15",
        "iso": "AU",
        "status": 1,
        "name": "Andamooka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.016666",
        "size": "small"
    },
    {
        "iata": "AMX",
        "lon": "135.24",
        "iso": "AU",
        "status": 1,
        "name": "Ammaroo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.74",
        "size": "small"
    },
    {
        "iata": "AMT",
        "lon": "132.03334",
        "iso": "AU",
        "status": 1,
        "name": "Amata Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.766666",
        "size": "small"
    },
    {
        "iata": "AYL",
        "lon": "135.53334",
        "iso": "AU",
        "status": 1,
        "name": "Anthony Lagoon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.033333",
        "size": "small"
    },
    {
        "iata": "ABH",
        "lon": "146.63333",
        "iso": "AU",
        "status": 1,
        "name": "Alpha Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.65",
        "size": "medium"
    },
    {
        "iata": "ARY",
        "lon": "143.0",
        "iso": "AU",
        "status": 1,
        "name": "Ararat Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.316666",
        "size": "medium"
    },
    {
        "iata": "GYL",
        "lon": "128.66667",
        "iso": "AU",
        "status": 1,
        "name": "Argyle Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.333332",
        "size": "small"
    },
    {
        "iata": "ARM",
        "lon": "151.61493",
        "iso": "AU",
        "status": 1,
        "name": "Armidale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.532297",
        "size": "medium"
    },
    {
        "iata": "AAB",
        "lon": "141.04167",
        "iso": "AU",
        "status": 1,
        "name": "Arrabury Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.7",
        "size": "small"
    },
    {
        "iata": "AUU",
        "lon": "142.25",
        "iso": "AU",
        "status": 1,
        "name": "Aurukun Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.166667",
        "size": "small"
    },
    {
        "iata": "AWP",
        "lon": "137.75",
        "iso": "AU",
        "status": 1,
        "name": "Austral Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.5",
        "size": "small"
    },
    {
        "iata": "AVG",
        "lon": "130.01666",
        "iso": "AU",
        "status": 1,
        "name": "Auvergne Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.65",
        "size": "small"
    },
    {
        "iata": "AYQ",
        "lon": "130.97658",
        "iso": "AU",
        "status": 1,
        "name": "Ayers Rock Connellan Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.190878",
        "size": "medium"
    },
    {
        "iata": "AYR",
        "lon": "147.32445",
        "iso": "AU",
        "status": 1,
        "name": "Ayr Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.596666",
        "size": "small"
    },
    {
        "iata": "ACF",
        "iso": "AU",
        "status": 1,
        "name": "Brisbane Archerfield Airport",
        "continent": "OC",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "ABM",
        "lon": "142.45033",
        "iso": "AU",
        "status": 1,
        "name": "Bamaga Injinoo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.942619",
        "size": "small"
    },
    {
        "iata": "BCI",
        "lon": "145.30194",
        "iso": "AU",
        "status": 1,
        "name": "Barcaldine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.559168",
        "size": "medium"
    },
    {
        "iata": "ASP",
        "lon": "133.90338",
        "iso": "AU",
        "status": 1,
        "name": "Alice Springs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.80139",
        "size": "medium"
    },
    {
        "iata": "BDD",
        "lon": "142.17166",
        "iso": "AU",
        "status": 1,
        "name": "Badu Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.148655",
        "size": "small"
    },
    {
        "iata": "BBE",
        "lon": "117.583336",
        "iso": "AU",
        "status": 1,
        "name": "Big Bell Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.333332",
        "size": "small"
    },
    {
        "iata": "BNE",
        "lon": "153.10905",
        "iso": "AU",
        "status": 1,
        "name": "Brisbane International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.40303",
        "size": "large"
    },
    {
        "iata": "OOL",
        "lon": "153.51314",
        "iso": "AU",
        "status": 1,
        "name": "Gold Coast Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.166164",
        "size": "medium"
    },
    {
        "iata": "BKQ",
        "lon": "145.42833",
        "iso": "AU",
        "status": 1,
        "name": "Blackall Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.434168",
        "size": "medium"
    },
    {
        "iata": "CNS",
        "lon": "145.75404",
        "iso": "AU",
        "status": 1,
        "name": "Cairns International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.876537",
        "size": "medium"
    },
    {
        "iata": "CTL",
        "lon": "146.25842",
        "iso": "AU",
        "status": 1,
        "name": "Charleville Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.413334",
        "size": "medium"
    },
    {
        "iata": "BXG",
        "lon": "144.30194",
        "iso": "AU",
        "status": 1,
        "name": "Bendigo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.734722",
        "size": "small"
    },
    {
        "iata": "BVI",
        "lon": "139.34334",
        "iso": "AU",
        "status": 1,
        "name": "Birdsville Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.898333",
        "size": "medium"
    },
    {
        "iata": "BTX",
        "lon": "140.73334",
        "iso": "AU",
        "status": 1,
        "name": "Betoota Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.7",
        "size": "small"
    },
    {
        "iata": "BQW",
        "lon": "127.8",
        "iso": "AU",
        "status": 1,
        "name": "Balgo Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.183332",
        "size": "small"
    },
    {
        "iata": "BHQ",
        "lon": "141.46857",
        "iso": "AU",
        "status": 1,
        "name": "Broken Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.998983",
        "size": "medium"
    },
    {
        "iata": "HTI",
        "lon": "148.94847",
        "iso": "AU",
        "status": 1,
        "name": "Hamilton Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.35175",
        "size": "medium"
    },
    {
        "iata": "BEU",
        "lon": "139.445",
        "iso": "AU",
        "status": 1,
        "name": "Bedourie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.23",
        "size": "medium"
    },
    {
        "iata": "BRK",
        "lon": "145.93333",
        "iso": "AU",
        "status": 1,
        "name": "Bourke Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.083332",
        "size": "medium"
    },
    {
        "iata": "BUC",
        "lon": "139.53334",
        "iso": "AU",
        "status": 1,
        "name": "Burketown Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.75",
        "size": "small"
    },
    {
        "iata": "BLN",
        "lon": "145.98334",
        "iso": "AU",
        "status": 1,
        "name": "Benalla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.55",
        "size": "medium"
    },
    {
        "iata": "LCN",
        "lon": "139.35",
        "iso": "AU",
        "status": 1,
        "name": "Balcanoona Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.516666",
        "size": "small"
    },
    {
        "iata": "BLS",
        "lon": "147.48334",
        "iso": "AU",
        "status": 1,
        "name": "Bollon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.05",
        "size": "small"
    },
    {
        "iata": "BQB",
        "lon": "115.40204",
        "iso": "AU",
        "status": 1,
        "name": "Busselton Regional Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.68688",
        "size": "small"
    },
    {
        "iata": "ISA",
        "lon": "139.49179",
        "iso": "AU",
        "status": 1,
        "name": "Mount Isa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.667637",
        "size": "medium"
    },
    {
        "iata": "MCY",
        "lon": "153.08821",
        "iso": "AU",
        "status": 1,
        "name": "Sunshine Coast Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.60538",
        "size": "medium"
    },
    {
        "iata": "MKY",
        "lon": "149.1818",
        "iso": "AU",
        "status": 1,
        "name": "Mackay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.176285",
        "size": "medium"
    },
    {
        "iata": "BNK",
        "lon": "153.5564",
        "iso": "AU",
        "status": 1,
        "name": "Ballina Byron Gateway Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.837606",
        "size": "medium"
    },
    {
        "iata": "BSJ",
        "lon": "147.63333",
        "iso": "AU",
        "status": 1,
        "name": "Bairnsdale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.833332",
        "size": "medium"
    },
    {
        "iata": "GIC",
        "lon": "142.21666",
        "iso": "AU",
        "status": 1,
        "name": "Boigu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.233333",
        "size": "small"
    },
    {
        "iata": "OKY",
        "lon": "151.735",
        "iso": "AU",
        "status": 1,
        "name": "Oakey Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.406668",
        "size": "medium"
    },
    {
        "iata": "BQL",
        "lon": "139.9",
        "iso": "AU",
        "status": 1,
        "name": "Boulia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.9",
        "size": "medium"
    },
    {
        "iata": "BMP",
        "lon": "149.23334",
        "iso": "AU",
        "status": 1,
        "name": "Brampton Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.783333",
        "size": "small"
    },
    {
        "iata": "PPP",
        "lon": "148.55194",
        "iso": "AU",
        "status": 1,
        "name": "Proserpine Whitsunday Coast Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.488922",
        "size": "medium"
    },
    {
        "iata": "ROK",
        "lon": "150.47841",
        "iso": "AU",
        "status": 1,
        "name": "Rockhampton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.377993",
        "size": "medium"
    },
    {
        "iata": "BOX",
        "lon": "136.28334",
        "iso": "AU",
        "status": 1,
        "name": "Borroloola Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.066668",
        "size": "small"
    },
    {
        "iata": "BME",
        "lon": "122.23429",
        "iso": "AU",
        "status": 1,
        "name": "Broome International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.95264",
        "size": "medium"
    },
    {
        "iata": "BZD",
        "lon": "143.61667",
        "iso": "AU",
        "status": 1,
        "name": "Balranald Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.61667",
        "size": "medium"
    },
    {
        "iata": "BWQ",
        "lon": "146.86667",
        "iso": "AU",
        "status": 1,
        "name": "Brewarrina Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.95",
        "size": "medium"
    },
    {
        "iata": "BHS",
        "lon": "149.65518",
        "iso": "AU",
        "status": 1,
        "name": "Bathurst Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.41387",
        "size": "medium"
    },
    {
        "iata": "BRT",
        "lon": "130.63333",
        "iso": "AU",
        "status": 1,
        "name": "Bathurst Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.766667",
        "size": "medium"
    },
    {
        "iata": "TSV",
        "lon": "146.7708",
        "iso": "AU",
        "status": 1,
        "name": "Townsville Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.256506",
        "size": "medium"
    },
    {
        "iata": "BLT",
        "lon": "148.8084",
        "iso": "AU",
        "status": 1,
        "name": "Blackwater Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.602184",
        "size": "medium"
    },
    {
        "iata": "BDB",
        "lon": "152.32187",
        "iso": "AU",
        "status": 1,
        "name": "Bundaberg Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.898708",
        "size": "medium"
    },
    {
        "iata": "BUY",
        "lon": "115.63333",
        "iso": "AU",
        "status": 1,
        "name": "Bunbury Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.316666",
        "size": "small"
    },
    {
        "iata": "ZBO",
        "lon": "148.21666",
        "iso": "AU",
        "status": 1,
        "name": "Bowen Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.016666",
        "size": "small"
    },
    {
        "iata": "WEI",
        "lon": "141.9247",
        "iso": "AU",
        "status": 1,
        "name": "Weipa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.681317",
        "size": "medium"
    },
    {
        "iata": "BWB",
        "lon": "115.416664",
        "iso": "AU",
        "status": 1,
        "name": "Barrow Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.783333",
        "size": "small"
    },
    {
        "iata": "BVZ",
        "lon": "124.0",
        "iso": "AU",
        "status": 1,
        "name": "Beverley Springs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.0",
        "size": "small"
    },
    {
        "iata": "CGV",
        "lon": "125.46667",
        "iso": "AU",
        "status": 1,
        "name": "Caiguna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.266666",
        "size": "small"
    },
    {
        "iata": "CLH",
        "lon": "149.7",
        "iso": "AU",
        "status": 1,
        "name": "Coolah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.833332",
        "size": "small"
    },
    {
        "iata": "CVQ",
        "lon": "113.66358",
        "iso": "AU",
        "status": 1,
        "name": "Carnarvon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.883429",
        "size": "medium"
    },
    {
        "iata": "CSI",
        "lon": "153.05833",
        "iso": "AU",
        "status": 1,
        "name": "Casino Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.883333",
        "size": "small"
    },
    {
        "iata": "CAZ",
        "lon": "145.79659",
        "iso": "AU",
        "status": 1,
        "name": "Cobar Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.536797",
        "size": "medium"
    },
    {
        "iata": "COJ",
        "lon": "149.27194",
        "iso": "AU",
        "status": 1,
        "name": "Coonabarabran Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.337221",
        "size": "medium"
    },
    {
        "iata": "CBY",
        "lon": "140.92612",
        "iso": "AU",
        "status": 1,
        "name": "Canobie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.478611",
        "size": "small"
    },
    {
        "iata": "CBI",
        "lon": "148.01344",
        "iso": "AU",
        "status": 1,
        "name": "Cape Barren Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.3885",
        "size": "small"
    },
    {
        "iata": "CPD",
        "lon": "134.75278",
        "iso": "AU",
        "status": 1,
        "name": "Coober Pedy Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.044443",
        "size": "medium"
    },
    {
        "iata": "CRB",
        "lon": "148.58333",
        "iso": "AU",
        "status": 1,
        "name": "Collarenebri Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.55",
        "size": "small"
    },
    {
        "iata": "CCL",
        "lon": "150.63333",
        "iso": "AU",
        "status": 1,
        "name": "Chinchilla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.75",
        "size": "medium"
    },
    {
        "iata": "CNC",
        "lon": "143.06659",
        "iso": "AU",
        "status": 1,
        "name": "Coconut Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.050179",
        "size": "small"
    },
    {
        "iata": "CNJ",
        "lon": "140.50851",
        "iso": "AU",
        "status": 1,
        "name": "Cloncurry Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.668905",
        "size": "medium"
    },
    {
        "iata": "CBX",
        "lon": "148.25",
        "iso": "AU",
        "status": 1,
        "name": "Condobolin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.86667",
        "size": "small"
    },
    {
        "iata": "CUD",
        "lon": "153.15",
        "iso": "AU",
        "status": 1,
        "name": "Caloundra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.8",
        "size": "small"
    },
    {
        "iata": "CED",
        "lon": "133.70122",
        "iso": "AU",
        "status": 1,
        "name": "Ceduna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.12378",
        "size": "medium"
    },
    {
        "iata": "CVC",
        "lon": "136.5",
        "iso": "AU",
        "status": 1,
        "name": "Cleve Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.7",
        "size": "medium"
    },
    {
        "iata": "CFI",
        "lon": "131.28334",
        "iso": "AU",
        "status": 1,
        "name": "Camfield Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.033333",
        "size": "small"
    },
    {
        "iata": "LLG",
        "lon": "144.53334",
        "iso": "AU",
        "status": 1,
        "name": "Chillagoe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.133333",
        "size": "small"
    },
    {
        "iata": "CXT",
        "lon": "146.26917",
        "iso": "AU",
        "status": 1,
        "name": "Charters Towers Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.046667",
        "size": "small"
    },
    {
        "iata": "CKI",
        "lon": "132.53334",
        "iso": "AU",
        "status": 1,
        "name": "Croker Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.2",
        "size": "small"
    },
    {
        "iata": "CTN",
        "lon": "145.18333",
        "iso": "AU",
        "status": 1,
        "name": "Cooktown Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.443333",
        "size": "medium"
    },
    {
        "iata": "CMQ",
        "lon": "147.62189",
        "iso": "AU",
        "status": 1,
        "name": "Clermont Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.77181",
        "size": "medium"
    },
    {
        "iata": "CMA",
        "lon": "145.6161",
        "iso": "AU",
        "status": 1,
        "name": "Cunnamulla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.03139",
        "size": "medium"
    },
    {
        "iata": "CML",
        "lon": "138.11667",
        "iso": "AU",
        "status": 1,
        "name": "Camooweal Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.933332",
        "size": "small"
    },
    {
        "iata": "NIF",
        "lon": "121.58611",
        "iso": "AU",
        "status": 1,
        "name": "Camp Nifty Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.675",
        "size": "small"
    },
    {
        "iata": "CES",
        "lon": "151.35",
        "iso": "AU",
        "status": 1,
        "name": "Cessnock Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.833332",
        "size": "small"
    },
    {
        "iata": "CNB",
        "lon": "148.37833",
        "iso": "AU",
        "status": 1,
        "name": "Coonamble Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.980833",
        "size": "medium"
    },
    {
        "iata": "ODL",
        "lon": "140.63333",
        "iso": "AU",
        "status": 1,
        "name": "Cordillo Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.716667",
        "size": "small"
    },
    {
        "iata": "CUQ",
        "lon": "143.11333",
        "iso": "AU",
        "status": 1,
        "name": "Coen Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.763889",
        "size": "medium"
    },
    {
        "iata": "OOM",
        "lon": "148.97333",
        "iso": "AU",
        "status": 1,
        "name": "Cooma Snowy Mountains Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.308334",
        "size": "medium"
    },
    {
        "iata": "CDA",
        "lon": "132.5225",
        "iso": "AU",
        "status": 1,
        "name": "Cooinda Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.905278",
        "size": "small"
    },
    {
        "iata": "CWW",
        "lon": "146.35",
        "iso": "AU",
        "status": 1,
        "name": "Corowa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.966667",
        "size": "medium"
    },
    {
        "iata": "CYG",
        "lon": "147.9",
        "iso": "AU",
        "status": 1,
        "name": "Corryong Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.25",
        "size": "medium"
    },
    {
        "iata": "CDQ",
        "lon": "142.25",
        "iso": "AU",
        "status": 1,
        "name": "Croydon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.3",
        "size": "small"
    },
    {
        "iata": "KCE",
        "lon": "147.86667",
        "iso": "AU",
        "status": 1,
        "name": "Collinsville Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.55",
        "size": "small"
    },
    {
        "iata": "CMD",
        "lon": "148.04333",
        "iso": "AU",
        "status": 1,
        "name": "Cootamundra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.628334",
        "size": "medium"
    },
    {
        "iata": "CUG",
        "lon": "151.0",
        "iso": "AU",
        "status": 1,
        "name": "Cudal Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.0",
        "size": "small"
    },
    {
        "iata": "CUY",
        "lon": "117.916664",
        "iso": "AU",
        "status": 1,
        "name": "Cue Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.45",
        "size": "small"
    },
    {
        "iata": "CWR",
        "lon": "138.33333",
        "iso": "AU",
        "status": 1,
        "name": "Cowarie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.716667",
        "size": "small"
    },
    {
        "iata": "CCW",
        "lon": "136.83333",
        "iso": "AU",
        "status": 1,
        "name": "Cowell Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.63333",
        "size": "small"
    },
    {
        "iata": "CWT",
        "lon": "148.64667",
        "iso": "AU",
        "status": 1,
        "name": "Cowra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.855",
        "size": "medium"
    },
    {
        "iata": "DJR",
        "iso": "AU",
        "status": 1,
        "name": "Dajarra Airport",
        "continent": "OC",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "DBY",
        "lon": "151.26666",
        "iso": "AU",
        "status": 1,
        "name": "Dalby Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.15",
        "size": "small"
    },
    {
        "iata": "DRN",
        "lon": "148.21527",
        "iso": "AU",
        "status": 1,
        "name": "Dirranbandi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.586666",
        "size": "medium"
    },
    {
        "iata": "DNB",
        "lon": "142.39055",
        "iso": "AU",
        "status": 1,
        "name": "Dunbar Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.010834",
        "size": "small"
    },
    {
        "iata": "DRB",
        "lon": "123.6525",
        "iso": "AU",
        "status": 1,
        "name": "Derby Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.373333",
        "size": "medium"
    },
    {
        "iata": "DFP",
        "lon": "143.33333",
        "iso": "AU",
        "status": 1,
        "name": "Drumduff Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.0",
        "size": "small"
    },
    {
        "iata": "DLK",
        "lon": "139.46666",
        "iso": "AU",
        "status": 1,
        "name": "Dulkaninna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.016666",
        "size": "small"
    },
    {
        "iata": "DDN",
        "lon": "141.3",
        "iso": "AU",
        "status": 1,
        "name": "Delta Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.916668",
        "size": "small"
    },
    {
        "iata": "DLV",
        "lon": "130.63333",
        "iso": "AU",
        "status": 1,
        "name": "Delissaville Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.566667",
        "size": "small"
    },
    {
        "iata": "DMD",
        "lon": "138.8216",
        "iso": "AU",
        "status": 1,
        "name": "Doomadgee Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.939201",
        "size": "small"
    },
    {
        "iata": "DVR",
        "lon": "130.68333",
        "iso": "AU",
        "status": 1,
        "name": "Daly River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.75",
        "size": "small"
    },
    {
        "iata": "NLF",
        "lon": "143.7869",
        "iso": "AU",
        "status": 1,
        "name": "Darnley Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.584515",
        "size": "small"
    },
    {
        "iata": "DRD",
        "lon": "142.33333",
        "iso": "AU",
        "status": 1,
        "name": "Dorunda Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.5",
        "size": "small"
    },
    {
        "iata": "DVP",
        "lon": "141.11667",
        "iso": "AU",
        "status": 1,
        "name": "Davenport Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.133333",
        "size": "small"
    },
    {
        "iata": "DPO",
        "lon": "146.42754",
        "iso": "AU",
        "status": 1,
        "name": "Devonport Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.172054",
        "size": "medium"
    },
    {
        "iata": "DHD",
        "lon": "149.08333",
        "iso": "AU",
        "status": 1,
        "name": "Durham Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.1",
        "size": "small"
    },
    {
        "iata": "DRR",
        "lon": "140.21666",
        "iso": "AU",
        "status": 1,
        "name": "Durrie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.616667",
        "size": "small"
    },
    {
        "iata": "DKV",
        "lon": "129.08333",
        "iso": "AU",
        "status": 1,
        "name": "Docker River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.866667",
        "size": "small"
    },
    {
        "iata": "DYA",
        "lon": "148.0",
        "iso": "AU",
        "status": 1,
        "name": "Dysart Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.5",
        "size": "medium"
    },
    {
        "iata": "WDA",
        "lon": "45.55",
        "iso": "YE",
        "status": 1,
        "name": "Wadi Ain Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "14.866667",
        "size": "small"
    },
    {
        "iata": "ECH",
        "lon": "144.75",
        "iso": "AU",
        "status": 1,
        "name": "Echuca Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.15",
        "size": "medium"
    },
    {
        "iata": "EUC",
        "lon": "128.86667",
        "iso": "AU",
        "status": 1,
        "name": "Eucla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.716667",
        "size": "small"
    },
    {
        "iata": "ETD",
        "lon": "138.0",
        "iso": "AU",
        "status": 1,
        "name": "Etadunna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.5",
        "size": "small"
    },
    {
        "iata": "ENB",
        "lon": "114.98333",
        "iso": "AU",
        "status": 1,
        "name": "Eneabba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.083332",
        "size": "small"
    },
    {
        "iata": "EIH",
        "lon": "144.1",
        "iso": "AU",
        "status": 1,
        "name": "Einasleigh Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.5",
        "size": "small"
    },
    {
        "iata": "ELC",
        "lon": "135.5698",
        "iso": "AU",
        "status": 1,
        "name": "Elcho Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.019817",
        "size": "medium"
    },
    {
        "iata": "EMD",
        "lon": "148.1744",
        "iso": "AU",
        "status": 1,
        "name": "Emerald Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.56861",
        "size": "medium"
    },
    {
        "iata": "ERB",
        "lon": "132.11667",
        "iso": "AU",
        "status": 1,
        "name": "Ernabella Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.283333",
        "size": "small"
    },
    {
        "iata": "EPR",
        "lon": "121.83039",
        "iso": "AU",
        "status": 1,
        "name": "Esperance Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.68248",
        "size": "medium"
    },
    {
        "iata": "EVD",
        "lon": "134.86667",
        "iso": "AU",
        "status": 1,
        "name": "Eva Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.016666",
        "size": "small"
    },
    {
        "iata": "EVH",
        "lon": "153.41667",
        "iso": "AU",
        "status": 1,
        "name": "Evans Head Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.1",
        "size": "small"
    },
    {
        "iata": "EXM",
        "lon": "114.25",
        "iso": "AU",
        "status": 1,
        "name": "Exmouth Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.25",
        "size": "small"
    },
    {
        "iata": "FRB",
        "lon": "148.05",
        "iso": "AU",
        "status": 1,
        "name": "Forbes Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.4",
        "size": "medium"
    },
    {
        "iata": "KFE",
        "lon": "119.437225",
        "iso": "AU",
        "status": 1,
        "name": "Fortescue - Dave Forrest Aerodrome",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.291945",
        "size": "small"
    },
    {
        "iata": "FLY",
        "lon": "145.58333",
        "iso": "AU",
        "status": 1,
        "name": "Finley Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.65",
        "size": "small"
    },
    {
        "iata": "FLS",
        "lon": "147.99667",
        "iso": "AU",
        "status": 1,
        "name": "Flinders Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.095",
        "size": "small"
    },
    {
        "iata": "FVL",
        "lon": "128.41667",
        "iso": "AU",
        "status": 1,
        "name": "Flora Valley Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.283333",
        "size": "small"
    },
    {
        "iata": "FIK",
        "lon": "134.58333",
        "iso": "AU",
        "status": 1,
        "name": "Finke Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.566668",
        "size": "small"
    },
    {
        "iata": "FOS",
        "lon": "128.05",
        "iso": "AU",
        "status": 1,
        "name": "Forrest Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.816668",
        "size": "medium"
    },
    {
        "iata": "FOT",
        "lon": "151.51666",
        "iso": "AU",
        "status": 1,
        "name": "Forster (Wallis Is) Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.183334",
        "size": "small"
    },
    {
        "iata": "FIZ",
        "lon": "125.55417",
        "iso": "AU",
        "status": 1,
        "name": "Fitzroy Crossing Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.178333",
        "size": "medium"
    },
    {
        "iata": "GAH",
        "lon": "151.48334",
        "iso": "AU",
        "status": 1,
        "name": "Gayndah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.433332",
        "size": "small"
    },
    {
        "iata": "GBL",
        "lon": "133.43333",
        "iso": "AU",
        "status": 1,
        "name": "South Goulburn Is Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.55",
        "size": "small"
    },
    {
        "iata": "GUH",
        "lon": "150.23334",
        "iso": "AU",
        "status": 1,
        "name": "Gunnedah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.95",
        "size": "medium"
    },
    {
        "iata": "GOO",
        "lon": "150.31944",
        "iso": "AU",
        "status": 1,
        "name": "Goondiwindi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.524445",
        "size": "small"
    },
    {
        "iata": "GDD",
        "lon": "128.55",
        "iso": "AU",
        "status": 1,
        "name": "Gordon Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.716667",
        "size": "small"
    },
    {
        "iata": "GGD",
        "lon": "139.23334",
        "iso": "AU",
        "status": 1,
        "name": "Gregory Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.633333",
        "size": "small"
    },
    {
        "iata": "GTS",
        "lon": "133.61667",
        "iso": "AU",
        "status": 1,
        "name": "Granite Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.966667",
        "size": "small"
    },
    {
        "iata": "GET",
        "lon": "114.70205",
        "iso": "AU",
        "status": 1,
        "name": "Geraldton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.796078",
        "size": "medium"
    },
    {
        "iata": "GFN",
        "lon": "153.02834",
        "iso": "AU",
        "status": 1,
        "name": "Grafton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.759521",
        "size": "medium"
    },
    {
        "iata": "GBV",
        "lon": "126.63333",
        "iso": "AU",
        "status": 1,
        "name": "Gibb River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.65",
        "size": "small"
    },
    {
        "iata": "GLT",
        "lon": "151.22351",
        "iso": "AU",
        "status": 1,
        "name": "Gladstone Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.87147",
        "size": "medium"
    },
    {
        "iata": "GUL",
        "lon": "149.71666",
        "iso": "AU",
        "status": 1,
        "name": "Goulburn Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.75",
        "size": "medium"
    },
    {
        "iata": "GLG",
        "lon": "139.6",
        "iso": "AU",
        "status": 1,
        "name": "Glengyle Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.816668",
        "size": "small"
    },
    {
        "iata": "GEX",
        "lon": "144.35",
        "iso": "AU",
        "status": 1,
        "name": "Geelong Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.13333",
        "size": "small"
    },
    {
        "iata": "GLI",
        "lon": "151.69083",
        "iso": "AU",
        "status": 1,
        "name": "Glen Innes Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.676666",
        "size": "medium"
    },
    {
        "iata": "GLM",
        "lon": "138.83333",
        "iso": "AU",
        "status": 1,
        "name": "Glenormiston Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.916668",
        "size": "small"
    },
    {
        "iata": "GVP",
        "lon": "144.61667",
        "iso": "AU",
        "status": 1,
        "name": "Greenvale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.783333",
        "size": "small"
    },
    {
        "iata": "GPN",
        "lon": "130.01666",
        "iso": "AU",
        "status": 1,
        "name": "Garden Point Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.766667",
        "size": "medium"
    },
    {
        "iata": "GTE",
        "lon": "136.46002",
        "iso": "AU",
        "status": 1,
        "name": "Groote Eylandt Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-13.973887",
        "size": "medium"
    },
    {
        "iata": "GFF",
        "lon": "146.0627",
        "iso": "AU",
        "status": 1,
        "name": "Griffith Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.255432",
        "size": "medium"
    },
    {
        "iata": "GTT",
        "lon": "143.78334",
        "iso": "AU",
        "status": 1,
        "name": "Georgetown Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.416668",
        "size": "small"
    },
    {
        "iata": "GEE",
        "lon": "143.55",
        "iso": "AU",
        "status": 1,
        "name": "Georgetown (Tas) Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.283333",
        "size": "small"
    },
    {
        "iata": "GYP",
        "lon": "152.63333",
        "iso": "AU",
        "status": 1,
        "name": "Gympie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.183332",
        "size": "small"
    },
    {
        "iata": "HWK",
        "lon": "138.41667",
        "iso": "AU",
        "status": 1,
        "name": "Wilpena Pound Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.716667",
        "size": "small"
    },
    {
        "iata": "HXX",
        "lon": "144.83333",
        "iso": "AU",
        "status": 1,
        "name": "Hay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.516666",
        "size": "medium"
    },
    {
        "iata": "HVB",
        "lon": "152.88521",
        "iso": "AU",
        "status": 1,
        "name": "Hervey Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.319868",
        "size": "medium"
    },
    {
        "iata": "HUB",
        "lon": "130.75",
        "iso": "AU",
        "status": 1,
        "name": "Humbert River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.5",
        "size": "small"
    },
    {
        "iata": "HIP",
        "lon": "138.28334",
        "iso": "AU",
        "status": 1,
        "name": "Headingly Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.316668",
        "size": "small"
    },
    {
        "iata": "HID",
        "lon": "142.28334",
        "iso": "AU",
        "status": 1,
        "name": "Horn Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.583333",
        "size": "medium"
    },
    {
        "iata": "HCQ",
        "lon": "127.666664",
        "iso": "AU",
        "status": 1,
        "name": "Halls Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.233334",
        "size": "medium"
    },
    {
        "iata": "HMG",
        "lon": "132.75",
        "iso": "AU",
        "status": 1,
        "name": "Hermannsburg Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.95",
        "size": "small"
    },
    {
        "iata": "HLT",
        "lon": "142.06056",
        "iso": "AU",
        "status": 1,
        "name": "Hamilton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.65",
        "size": "medium"
    },
    {
        "iata": "HOK",
        "lon": "130.6325",
        "iso": "AU",
        "status": 1,
        "name": "Hooker Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.335",
        "size": "small"
    },
    {
        "iata": "MHU",
        "lon": "147.18333",
        "iso": "AU",
        "status": 1,
        "name": "Mount Hotham Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.966667",
        "size": "medium"
    },
    {
        "iata": "HTU",
        "lon": "142.36667",
        "iso": "AU",
        "status": 1,
        "name": "Hopetoun Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.7",
        "size": "medium"
    },
    {
        "iata": "HSM",
        "lon": "142.16667",
        "iso": "AU",
        "status": 1,
        "name": "Horsham Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.666668",
        "size": "medium"
    },
    {
        "iata": "HGD",
        "lon": "144.22444",
        "iso": "AU",
        "status": 1,
        "name": "Hughenden Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.81889",
        "size": "small"
    },
    {
        "iata": "IDK",
        "lon": "133.325",
        "iso": "AU",
        "status": 1,
        "name": "Indulkana Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.966667",
        "size": "small"
    },
    {
        "iata": "IFL",
        "lon": "146.01666",
        "iso": "AU",
        "status": 1,
        "name": "Innisfail Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.55",
        "size": "small"
    },
    {
        "iata": "IFF",
        "lon": "141.21666",
        "iso": "AU",
        "status": 1,
        "name": "Iffley Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.9",
        "size": "small"
    },
    {
        "iata": "IGH",
        "lon": "146.16667",
        "iso": "AU",
        "status": 1,
        "name": "Ingham Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.716667",
        "size": "small"
    },
    {
        "iata": "IKP",
        "lon": "141.5",
        "iso": "AU",
        "status": 1,
        "name": "Inkerman Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.25",
        "size": "small"
    },
    {
        "iata": "INJ",
        "lon": "148.53334",
        "iso": "AU",
        "status": 1,
        "name": "Injune Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.883333",
        "size": "small"
    },
    {
        "iata": "INM",
        "lon": "140.73334",
        "iso": "AU",
        "status": 1,
        "name": "Innamincka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.75",
        "size": "small"
    },
    {
        "iata": "IVW",
        "lon": "129.63333",
        "iso": "AU",
        "status": 1,
        "name": "Inverway Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.833332",
        "size": "small"
    },
    {
        "iata": "ISI",
        "lon": "144.42334",
        "iso": "AU",
        "status": 1,
        "name": "Isisford Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.263332",
        "size": "small"
    },
    {
        "iata": "IVR",
        "lon": "151.14056",
        "iso": "AU",
        "status": 1,
        "name": "Inverell Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.883896",
        "size": "medium"
    },
    {
        "iata": "JAB",
        "lon": "132.89194",
        "iso": "AU",
        "status": 1,
        "name": "Jabiru Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.659722",
        "size": "small"
    },
    {
        "iata": "JUN",
        "lon": "143.06667",
        "iso": "AU",
        "status": 1,
        "name": "Jundah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.833332",
        "size": "small"
    },
    {
        "iata": "QJD",
        "iso": "AU",
        "status": 1,
        "name": "Jindabyne Airport",
        "continent": "OC",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "JCK",
        "lon": "141.7",
        "iso": "AU",
        "status": 1,
        "name": "Julia Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.583332",
        "size": "small"
    },
    {
        "iata": "JUR",
        "lon": "115.05",
        "iso": "AU",
        "status": 1,
        "name": "Jurien Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.266666",
        "size": "small"
    },
    {
        "iata": "UBU",
        "lon": "126.65",
        "iso": "AU",
        "status": 1,
        "name": "Kalumburu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.3",
        "size": "small"
    },
    {
        "iata": "KAX",
        "lon": "114.26052",
        "iso": "AU",
        "status": 1,
        "name": "Kalbarri Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.691929",
        "size": "small"
    },
    {
        "iata": "KBY",
        "lon": "134.0",
        "iso": "AU",
        "status": 1,
        "name": "Streaky Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.0",
        "size": "small"
    },
    {
        "iata": "KCS",
        "lon": "131.68333",
        "iso": "AU",
        "status": 1,
        "name": "Kings Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.383333",
        "size": "small"
    },
    {
        "iata": "KRA",
        "lon": "143.91667",
        "iso": "AU",
        "status": 1,
        "name": "Kerang Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.666668",
        "size": "medium"
    },
    {
        "iata": "KNS",
        "lon": "143.88168",
        "iso": "AU",
        "status": 1,
        "name": "King Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-39.879776",
        "size": "medium"
    },
    {
        "iata": "KBB",
        "lon": "129.21666",
        "iso": "AU",
        "status": 1,
        "name": "Kirkimbie Station Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.766666",
        "size": "small"
    },
    {
        "iata": "KFG",
        "lon": "130.81667",
        "iso": "AU",
        "status": 1,
        "name": "Kalkgurung Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.433332",
        "size": "small"
    },
    {
        "iata": "KKP",
        "lon": "143.95",
        "iso": "AU",
        "status": 1,
        "name": "Koolburra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.583333",
        "size": "small"
    },
    {
        "iata": "KRB",
        "lon": "140.83194",
        "iso": "AU",
        "status": 1,
        "name": "Karumba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.456667",
        "size": "small"
    },
    {
        "iata": "KML",
        "lon": "140.05",
        "iso": "AU",
        "status": 1,
        "name": "Kamileroi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.366667",
        "size": "small"
    },
    {
        "iata": "KPS",
        "lon": "152.76666",
        "iso": "AU",
        "status": 1,
        "name": "Kempsey Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.083332",
        "size": "medium"
    },
    {
        "iata": "KNI",
        "lon": "117.55",
        "iso": "AU",
        "status": 1,
        "name": "Katanning Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.7",
        "size": "small"
    },
    {
        "iata": "KWM",
        "lon": "141.74834",
        "iso": "AU",
        "status": 1,
        "name": "Kowanyama Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.4875",
        "size": "medium"
    },
    {
        "iata": "KGY",
        "lon": "151.83333",
        "iso": "AU",
        "status": 1,
        "name": "Kingaroy Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.55",
        "size": "medium"
    },
    {
        "iata": "KGC",
        "lon": "137.52332",
        "iso": "AU",
        "status": 1,
        "name": "Kingscote Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.709305",
        "size": "medium"
    },
    {
        "iata": "KUG",
        "lon": "142.3",
        "iso": "AU",
        "status": 1,
        "name": "Kubin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.233333",
        "size": "small"
    },
    {
        "iata": "LWH",
        "lon": "138.58333",
        "iso": "AU",
        "status": 1,
        "name": "Lawn Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.583332",
        "size": "small"
    },
    {
        "iata": "LGH",
        "lon": "138.41667",
        "iso": "AU",
        "status": 1,
        "name": "Leigh Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.466667",
        "size": "medium"
    },
    {
        "iata": "LNO",
        "lon": "121.316925",
        "iso": "AU",
        "status": 1,
        "name": "Leonora Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.879345",
        "size": "medium"
    },
    {
        "iata": "LEL",
        "lon": "135.8",
        "iso": "AU",
        "status": 1,
        "name": "Lake Evella Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.508333",
        "size": "small"
    },
    {
        "iata": "LDH",
        "lon": "159.07816",
        "iso": "AU",
        "status": 1,
        "name": "Lord Howe Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.540808",
        "size": "small"
    },
    {
        "iata": "IRG",
        "lon": "143.3125",
        "iso": "AU",
        "status": 1,
        "name": "Lockhart River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.788889",
        "size": "medium"
    },
    {
        "iata": "LTP",
        "lon": "138.35",
        "iso": "AU",
        "status": 1,
        "name": "Lyndhurst Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.283333",
        "size": "small"
    },
    {
        "iata": "LDC",
        "lon": "149.0",
        "iso": "AU",
        "status": 1,
        "name": "Lindeman Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.0",
        "size": "small"
    },
    {
        "iata": "LSY",
        "lon": "153.26337",
        "iso": "AU",
        "status": 1,
        "name": "Lismore Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.836653",
        "size": "medium"
    },
    {
        "iata": "LOC",
        "lon": "135.75",
        "iso": "AU",
        "status": 1,
        "name": "Lock Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.55",
        "size": "small"
    },
    {
        "iata": "LOA",
        "lon": "139.9",
        "iso": "AU",
        "status": 1,
        "name": "Lorraine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.0",
        "size": "small"
    },
    {
        "iata": "LTV",
        "lon": "141.38333",
        "iso": "AU",
        "status": 1,
        "name": "Lotus Vale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.05",
        "size": "small"
    },
    {
        "iata": "LUU",
        "lon": "144.16667",
        "iso": "AU",
        "status": 1,
        "name": "Laura Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.666667",
        "size": "small"
    },
    {
        "iata": "LHG",
        "lon": "147.98334",
        "iso": "AU",
        "status": 1,
        "name": "Lightning Ridge Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.458332",
        "size": "medium"
    },
    {
        "iata": "LRE",
        "lon": "144.27147",
        "iso": "AU",
        "status": 1,
        "name": "Longreach Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.439474",
        "size": "medium"
    },
    {
        "iata": "LUT",
        "lon": "144.81667",
        "iso": "AU",
        "status": 1,
        "name": "New Laura Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.433333",
        "size": "small"
    },
    {
        "iata": "LER",
        "lon": "120.61667",
        "iso": "AU",
        "status": 1,
        "name": "Leinster Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.833332",
        "size": "medium"
    },
    {
        "iata": "LVO",
        "lon": "122.36667",
        "iso": "AU",
        "status": 1,
        "name": "Laverton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.633333",
        "size": "small"
    },
    {
        "iata": "TGN",
        "lon": "146.56667",
        "iso": "AU",
        "status": 1,
        "name": "Latrobe Valley Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.2",
        "size": "medium"
    },
    {
        "iata": "LZR",
        "lon": "145.46666",
        "iso": "AU",
        "status": 1,
        "name": "Lizard Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.666667",
        "size": "small"
    },
    {
        "iata": "UBB",
        "lon": "142.18333",
        "iso": "AU",
        "status": 1,
        "name": "Mabuiag Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.95",
        "size": "small"
    },
    {
        "iata": "AVV",
        "lon": "144.47308",
        "iso": "AU",
        "status": 1,
        "name": "Avalon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.02637",
        "size": "medium"
    },
    {
        "iata": "ABX",
        "lon": "146.95453",
        "iso": "AU",
        "status": 1,
        "name": "Albury Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.069626",
        "size": "medium"
    },
    {
        "iata": "MRG",
        "lon": "145.46666",
        "iso": "AU",
        "status": 1,
        "name": "Mareeba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.0",
        "size": "medium"
    },
    {
        "iata": "MBB",
        "lon": "119.583336",
        "iso": "AU",
        "status": 1,
        "name": "Marble Bar Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.25",
        "size": "small"
    },
    {
        "iata": "XMC",
        "lon": "149.71666",
        "iso": "AU",
        "status": 1,
        "name": "Mallacoota Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.6",
        "size": "small"
    },
    {
        "iata": "MFP",
        "lon": "137.98334",
        "iso": "AU",
        "status": 1,
        "name": "Manners Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.1",
        "size": "small"
    },
    {
        "iata": "MLR",
        "lon": "140.01666",
        "iso": "AU",
        "status": 1,
        "name": "Millicent Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.483334",
        "size": "small"
    },
    {
        "iata": "DGE",
        "lon": "149.61667",
        "iso": "AU",
        "status": 1,
        "name": "Mudgee Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.566666",
        "size": "medium"
    },
    {
        "iata": "MQA",
        "lon": "120.833336",
        "iso": "AU",
        "status": 1,
        "name": "Mandora Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.75",
        "size": "small"
    },
    {
        "iata": "MKR",
        "lon": "118.54596",
        "iso": "AU",
        "status": 1,
        "name": "Meekatharra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.61113",
        "size": "medium"
    },
    {
        "iata": "MEB",
        "lon": "144.8975",
        "iso": "AU",
        "status": 1,
        "name": "Melbourne Essendon Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.736668",
        "size": "medium"
    },
    {
        "iata": "MIM",
        "lon": "149.90202",
        "iso": "AU",
        "status": 1,
        "name": "Merimbula Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.910046",
        "size": "medium"
    },
    {
        "iata": "SXE",
        "lon": "147.0",
        "iso": "AU",
        "status": 1,
        "name": "RAAF Base East Sale",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.166668",
        "size": "medium"
    },
    {
        "iata": "MGT",
        "lon": "134.89352",
        "iso": "AU",
        "status": 1,
        "name": "Milingimbi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.093703",
        "size": "small"
    },
    {
        "iata": "MNG",
        "lon": "134.23227",
        "iso": "AU",
        "status": 1,
        "name": "Maningrida Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.05492",
        "size": "medium"
    },
    {
        "iata": "GSN",
        "lon": "137.76666",
        "iso": "AU",
        "status": 1,
        "name": "Mount Gunson Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.666668",
        "size": "small"
    },
    {
        "iata": "MGV",
        "lon": "126.86667",
        "iso": "AU",
        "status": 1,
        "name": "Margaret River (Station) Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.616667",
        "size": "small"
    },
    {
        "iata": "MQZ",
        "lon": "115.1",
        "iso": "AU",
        "status": 1,
        "name": "Margaret River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.933334",
        "size": "small"
    },
    {
        "iata": "HBA",
        "lon": "147.50519",
        "iso": "AU",
        "status": 1,
        "name": "Hobart International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-42.837257",
        "size": "medium"
    },
    {
        "iata": "MHO",
        "lon": "125.76667",
        "iso": "AU",
        "status": 1,
        "name": "Mount House Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.133333",
        "size": "small"
    },
    {
        "iata": "MCV",
        "lon": "136.095",
        "iso": "AU",
        "status": 1,
        "name": "McArthur River Mine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.466667",
        "size": "small"
    },
    {
        "iata": "MQL",
        "lon": "142.08455",
        "iso": "AU",
        "status": 1,
        "name": "Mildura Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.230835",
        "size": "medium"
    },
    {
        "iata": "XML",
        "lon": "137.5",
        "iso": "AU",
        "status": 1,
        "name": "Minlaton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.0",
        "size": "small"
    },
    {
        "iata": "MIH",
        "lon": "147.96666",
        "iso": "AU",
        "status": 1,
        "name": "Mitchell Plateau Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.483334",
        "size": "small"
    },
    {
        "iata": "MTQ",
        "lon": "147.96666",
        "iso": "AU",
        "status": 1,
        "name": "Mitchell Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.583332",
        "size": "small"
    },
    {
        "iata": "MJP",
        "lon": "116.1",
        "iso": "AU",
        "status": 1,
        "name": "Manjimup Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.233334",
        "size": "small"
    },
    {
        "iata": "LST",
        "lon": "147.20532",
        "iso": "AU",
        "status": 1,
        "name": "Launceston Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.54351",
        "size": "medium"
    },
    {
        "iata": "MBW",
        "lon": "145.10222",
        "iso": "AU",
        "status": 1,
        "name": "Melbourne Moorabbin Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.975834",
        "size": "medium"
    },
    {
        "iata": "MEL",
        "lon": "144.84978",
        "iso": "AU",
        "status": 1,
        "name": "Melbourne International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.669613",
        "size": "large"
    },
    {
        "iata": "MMM",
        "lon": "148.70473",
        "iso": "AU",
        "status": 1,
        "name": "Middlemount Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.8025",
        "size": "small"
    },
    {
        "iata": "MTL",
        "lon": "151.55",
        "iso": "AU",
        "status": 1,
        "name": "Maitland Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.733334",
        "size": "small"
    },
    {
        "iata": "WME",
        "lon": "120.549446",
        "iso": "AU",
        "status": 1,
        "name": "Mount Keith Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.287222",
        "size": "medium"
    },
    {
        "iata": "ONR",
        "lon": "140.56667",
        "iso": "AU",
        "status": 1,
        "name": "Monkira Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.816668",
        "size": "small"
    },
    {
        "iata": "OXY",
        "lon": "141.36667",
        "iso": "AU",
        "status": 1,
        "name": "Morney Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.35",
        "size": "small"
    },
    {
        "iata": "MMG",
        "lon": "117.85",
        "iso": "AU",
        "status": 1,
        "name": "Mount Magnet Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.083332",
        "size": "medium"
    },
    {
        "iata": "OOR",
        "lon": "141.23334",
        "iso": "AU",
        "status": 1,
        "name": "Mooraberree Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.05",
        "size": "small"
    },
    {
        "iata": "MRZ",
        "lon": "149.85019",
        "iso": "AU",
        "status": 1,
        "name": "Moree Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.496346",
        "size": "medium"
    },
    {
        "iata": "MET",
        "lon": "142.76666",
        "iso": "AU",
        "status": 1,
        "name": "Moreton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.75",
        "size": "small"
    },
    {
        "iata": "MIN",
        "lon": "134.83333",
        "iso": "AU",
        "status": 1,
        "name": "Minnipa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.833332",
        "size": "small"
    },
    {
        "iata": "MQE",
        "lon": "137.35",
        "iso": "AU",
        "status": 1,
        "name": "Marqua Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.816668",
        "size": "small"
    },
    {
        "iata": "MOV",
        "lon": "148.07442",
        "iso": "AU",
        "status": 1,
        "name": "Moranbah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.06057",
        "size": "medium"
    },
    {
        "iata": "RRE",
        "lon": "138.06667",
        "iso": "AU",
        "status": 1,
        "name": "Marree Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.65",
        "size": "small"
    },
    {
        "iata": "MWB",
        "lon": "116.0225",
        "iso": "AU",
        "status": 1,
        "name": "Morawa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.204166",
        "size": "small"
    },
    {
        "iata": "MYA",
        "lon": "150.14717",
        "iso": "AU",
        "status": 1,
        "name": "Moruya Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.90251",
        "size": "medium"
    },
    {
        "iata": "MTD",
        "lon": "130.56667",
        "iso": "AU",
        "status": 1,
        "name": "Mount Sanford Station Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.0",
        "size": "small"
    },
    {
        "iata": "UTB",
        "lon": "144.525",
        "iso": "AU",
        "status": 1,
        "name": "Muttaburra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.5875",
        "size": "small"
    },
    {
        "iata": "MGB",
        "lon": "140.78271",
        "iso": "AU",
        "status": 1,
        "name": "Mount Gambier Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.744072",
        "size": "medium"
    },
    {
        "iata": "ONG",
        "lon": "139.17004",
        "iso": "AU",
        "status": 1,
        "name": "Mornington Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.662558",
        "size": "small"
    },
    {
        "iata": "MNQ",
        "lon": "151.1",
        "iso": "AU",
        "status": 1,
        "name": "Monto Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.883333",
        "size": "small"
    },
    {
        "iata": "MUQ",
        "lon": "120.0",
        "iso": "AU",
        "status": 1,
        "name": "Muccan Station Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.666668",
        "size": "small"
    },
    {
        "iata": "MNE",
        "lon": "138.6",
        "iso": "AU",
        "status": 1,
        "name": "Mungeranie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.0",
        "size": "small"
    },
    {
        "iata": "MYI",
        "lon": "144.06497",
        "iso": "AU",
        "status": 1,
        "name": "Murray Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.920066",
        "size": "small"
    },
    {
        "iata": "MVK",
        "lon": "138.0",
        "iso": "AU",
        "status": 1,
        "name": "Mulka Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.25",
        "size": "small"
    },
    {
        "iata": "MXU",
        "lon": "115.51667",
        "iso": "AU",
        "status": 1,
        "name": "Mullewa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.55",
        "size": "small"
    },
    {
        "iata": "MXD",
        "lon": "139.6",
        "iso": "AU",
        "status": 1,
        "name": "Marion Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.333332",
        "size": "small"
    },
    {
        "iata": "MBH",
        "lon": "152.71333",
        "iso": "AU",
        "status": 1,
        "name": "Maryborough Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.516666",
        "size": "small"
    },
    {
        "iata": "RTY",
        "lon": "139.0",
        "iso": "AU",
        "status": 1,
        "name": "Merty Merty Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.25",
        "size": "small"
    },
    {
        "iata": "NMR",
        "lon": "141.11667",
        "iso": "AU",
        "status": 1,
        "name": "Nappa Merrie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.6",
        "size": "small"
    },
    {
        "iata": "NRA",
        "lon": "146.5117",
        "iso": "AU",
        "status": 1,
        "name": "Narrandera Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.705643",
        "size": "medium"
    },
    {
        "iata": "NAA",
        "lon": "149.82912",
        "iso": "AU",
        "status": 1,
        "name": "Narrabri Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.31829",
        "size": "medium"
    },
    {
        "iata": "RPM",
        "lon": "134.0",
        "iso": "AU",
        "status": 1,
        "name": "Ngukurr Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.933333",
        "size": "medium"
    },
    {
        "iata": "NBH",
        "lon": "152.98334",
        "iso": "AU",
        "status": 1,
        "name": "Nambucca Heads Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.633333",
        "size": "small"
    },
    {
        "iata": "NLS",
        "lon": "128.89583",
        "iso": "AU",
        "status": 1,
        "name": "Nicholson Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.050833",
        "size": "small"
    },
    {
        "iata": "NAC",
        "lon": "140.83333",
        "iso": "AU",
        "status": 1,
        "name": "Naracoorte Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.95",
        "size": "small"
    },
    {
        "iata": "QRM",
        "lon": "148.36389",
        "iso": "AU",
        "status": 1,
        "name": "Narromine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.244446",
        "size": "medium"
    },
    {
        "iata": "RVT",
        "lon": "120.201385",
        "iso": "AU",
        "status": 1,
        "name": "Ravensthorpe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.785557",
        "size": "small"
    },
    {
        "iata": "NSV",
        "lon": "153.03334",
        "iso": "AU",
        "status": 1,
        "name": "Noosa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.416668",
        "size": "small"
    },
    {
        "iata": "NSM",
        "lon": "121.75",
        "iso": "AU",
        "status": 1,
        "name": "Norseman Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.2",
        "size": "small"
    },
    {
        "iata": "NTN",
        "lon": "141.07307",
        "iso": "AU",
        "status": 1,
        "name": "Normanton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.685398",
        "size": "medium"
    },
    {
        "iata": "NUR",
        "lon": "130.91667",
        "iso": "AU",
        "status": 1,
        "name": "Nullabor Motel Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.433332",
        "size": "small"
    },
    {
        "iata": "NLL",
        "lon": "120.2",
        "iso": "AU",
        "status": 1,
        "name": "Nullagine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.75",
        "size": "small"
    },
    {
        "iata": "NUB",
        "lon": "135.71638",
        "iso": "AU",
        "status": 1,
        "name": "Numbulwar Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.271667",
        "size": "small"
    },
    {
        "iata": "ZNE",
        "lon": "119.80137",
        "iso": "AU",
        "status": 1,
        "name": "Newman Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.416517",
        "size": "medium"
    },
    {
        "iata": "NYN",
        "lon": "147.18333",
        "iso": "AU",
        "status": 1,
        "name": "Nyngan Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.566668",
        "size": "small"
    },
    {
        "iata": "OPI",
        "lon": "133.06667",
        "iso": "AU",
        "status": 1,
        "name": "Oenpelli Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.333333",
        "size": "small"
    },
    {
        "iata": "YOI",
        "iso": "CA",
        "status": 1,
        "name": "Opinaca Aerodrome",
        "continent": "NA",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "XCO",
        "lon": "143.58333",
        "iso": "AU",
        "status": 1,
        "name": "Colac Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.35",
        "size": "small"
    },
    {
        "iata": "OLP",
        "lon": "136.88411",
        "iso": "AU",
        "status": 1,
        "name": "Olympic Dam Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.483898",
        "size": "small"
    },
    {
        "iata": "ODD",
        "lon": "135.45",
        "iso": "AU",
        "status": 1,
        "name": "Oodnadatta Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.550278",
        "size": "small"
    },
    {
        "iata": "MOO",
        "lon": "149.83333",
        "iso": "AU",
        "status": 1,
        "name": "Moomba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.5",
        "size": "small"
    },
    {
        "iata": "RBS",
        "lon": "148.5",
        "iso": "AU",
        "status": 1,
        "name": "Orbost Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.7",
        "size": "small"
    },
    {
        "iata": "OAG",
        "lon": "149.12447",
        "iso": "AU",
        "status": 1,
        "name": "Orange Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.3816",
        "size": "medium"
    },
    {
        "iata": "ODR",
        "lon": "128.8",
        "iso": "AU",
        "status": 1,
        "name": "Ord River Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.5",
        "size": "small"
    },
    {
        "iata": "OSO",
        "iso": "AU",
        "status": 1,
        "name": "Osborne Mine Airport",
        "continent": "OC",
        "type": "airport",
        "size": "small"
    },
    {
        "iata": "OYN",
        "lon": "142.33333",
        "iso": "AU",
        "status": 1,
        "name": "Ouyen Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.066666",
        "size": "small"
    },
    {
        "iata": "ADL",
        "lon": "138.53735",
        "iso": "AU",
        "status": 1,
        "name": "Adelaide International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.938175",
        "size": "medium"
    },
    {
        "iata": "PUG",
        "lon": "137.76666",
        "iso": "AU",
        "status": 1,
        "name": "Port Augusta Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.5",
        "size": "medium"
    },
    {
        "iata": "PMK",
        "lon": "146.66667",
        "iso": "AU",
        "status": 1,
        "name": "Palm Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.333332",
        "size": "small"
    },
    {
        "iata": "PBO",
        "lon": "117.748",
        "iso": "AU",
        "status": 1,
        "name": "Paraburdoo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.173908",
        "size": "medium"
    },
    {
        "iata": "CCK",
        "lon": "96.916664",
        "iso": "CC",
        "status": 1,
        "name": "Cocos (Keeling) Islands Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-12.166667",
        "size": "medium"
    },
    {
        "iata": "PDE",
        "lon": "138.66667",
        "iso": "AU",
        "status": 1,
        "name": "Pandie Pandie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.0",
        "size": "small"
    },
    {
        "iata": "DRW",
        "lon": "130.87752",
        "iso": "AU",
        "status": 1,
        "name": "Darwin International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.407805",
        "size": "medium"
    },
    {
        "iata": "PRD",
        "lon": "119.11667",
        "iso": "AU",
        "status": 1,
        "name": "Pardoo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.1",
        "size": "small"
    },
    {
        "iata": "BEO",
        "lon": "151.66667",
        "iso": "AU",
        "status": 1,
        "name": "Aeropelican Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.033333",
        "size": "small"
    },
    {
        "iata": "GOV",
        "lon": "136.82265",
        "iso": "AU",
        "status": 1,
        "name": "Gove Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.269595",
        "size": "medium"
    },
    {
        "iata": "PPI",
        "lon": "138.0",
        "iso": "AU",
        "status": 1,
        "name": "Port Pirie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.233334",
        "size": "medium"
    },
    {
        "iata": "JAD",
        "lon": "115.88333",
        "iso": "AU",
        "status": 1,
        "name": "Perth Jandakot Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.083332",
        "size": "medium"
    },
    {
        "iata": "KTA",
        "lon": "116.77006",
        "iso": "AU",
        "status": 1,
        "name": "Karratha Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.70826",
        "size": "medium"
    },
    {
        "iata": "KGI",
        "lon": "121.45792",
        "iso": "AU",
        "status": 1,
        "name": "Kalgoorlie Boulder Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.785246",
        "size": "medium"
    },
    {
        "iata": "PKE",
        "lon": "148.2329",
        "iso": "AU",
        "status": 1,
        "name": "Parkes Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.138454",
        "size": "medium"
    },
    {
        "iata": "PKT",
        "lon": "129.53334",
        "iso": "AU",
        "status": 1,
        "name": "Port Keats Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.25",
        "size": "medium"
    },
    {
        "iata": "KNX",
        "lon": "128.71268",
        "iso": "AU",
        "status": 1,
        "name": "Kununurra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.784031",
        "size": "medium"
    },
    {
        "iata": "PLO",
        "lon": "135.87454",
        "iso": "AU",
        "status": 1,
        "name": "Port Lincoln Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.603333",
        "size": "medium"
    },
    {
        "iata": "LEA",
        "lon": "114.09427",
        "iso": "AU",
        "status": 1,
        "name": "Learmonth Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.239883",
        "size": "medium"
    },
    {
        "iata": "EDR",
        "lon": "141.61667",
        "iso": "AU",
        "status": 1,
        "name": "Pormpuraaw Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.9",
        "size": "small"
    },
    {
        "iata": "PQQ",
        "lon": "152.86723",
        "iso": "AU",
        "status": 1,
        "name": "Port Macquarie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.431402",
        "size": "medium"
    },
    {
        "iata": "PTJ",
        "lon": "141.625",
        "iso": "AU",
        "status": 1,
        "name": "Portland Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.384167",
        "size": "medium"
    },
    {
        "iata": "PHE",
        "lon": "118.63164",
        "iso": "AU",
        "status": 1,
        "name": "Port Hedland International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.377945",
        "size": "medium"
    },
    {
        "iata": "PER",
        "lon": "115.960236",
        "iso": "AU",
        "status": 1,
        "name": "Perth International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.933603",
        "size": "large"
    },
    {
        "iata": "PEA",
        "lon": "137.93333",
        "iso": "AU",
        "status": 1,
        "name": "Penneshaw Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.733334",
        "size": "small"
    },
    {
        "iata": "KTR",
        "lon": "132.2714",
        "iso": "AU",
        "status": 1,
        "name": "Tindal Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-14.4575",
        "size": "medium"
    },
    {
        "iata": "UMR",
        "lon": "136.81667",
        "iso": "AU",
        "status": 1,
        "name": "Woomera Airfield",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.15",
        "size": "small"
    },
    {
        "iata": "XCH",
        "lon": "105.666664",
        "iso": "CX",
        "status": 1,
        "name": "Christmas Island Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "-10.5",
        "size": "medium"
    },
    {
        "iata": "UIR",
        "lon": "150.51666",
        "iso": "AU",
        "status": 1,
        "name": "Quirindi Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.5",
        "size": "small"
    },
    {
        "iata": "ULP",
        "lon": "144.3",
        "iso": "AU",
        "status": 1,
        "name": "Quilpie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.633333",
        "size": "medium"
    },
    {
        "iata": "UEE",
        "lon": "145.55",
        "iso": "AU",
        "status": 1,
        "name": "Queenstown Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-42.083332",
        "size": "small"
    },
    {
        "iata": "RMK",
        "lon": "140.68333",
        "iso": "AU",
        "status": 1,
        "name": "Renmark Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.2",
        "size": "medium"
    },
    {
        "iata": "RCM",
        "lon": "143.11667",
        "iso": "AU",
        "status": 1,
        "name": "Richmond Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.70389",
        "size": "small"
    },
    {
        "iata": "RAM",
        "lon": "134.81667",
        "iso": "AU",
        "status": 1,
        "name": "Ramingining Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-12.0",
        "size": "small"
    },
    {
        "iata": "ROH",
        "lon": "144.0",
        "iso": "AU",
        "status": 1,
        "name": "Robinhood Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.25",
        "size": "small"
    },
    {
        "iata": "RBU",
        "lon": "117.166664",
        "iso": "AU",
        "status": 1,
        "name": "Roebourne Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.8",
        "size": "small"
    },
    {
        "iata": "RBC",
        "lon": "142.75",
        "iso": "AU",
        "status": 1,
        "name": "Robinvale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.666668",
        "size": "small"
    },
    {
        "iata": "RMA",
        "lon": "148.77943",
        "iso": "AU",
        "status": 1,
        "name": "Roma Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.543552",
        "size": "medium"
    },
    {
        "iata": "RSB",
        "lon": "139.63333",
        "iso": "AU",
        "status": 1,
        "name": "Roseberth Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.833332",
        "size": "small"
    },
    {
        "iata": "RTS",
        "lon": "115.53667",
        "iso": "AU",
        "status": 1,
        "name": "Rottnest Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.006943",
        "size": "medium"
    },
    {
        "iata": "RTP",
        "lon": "141.83333",
        "iso": "AU",
        "status": 1,
        "name": "Rutland Plains Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.6",
        "size": "small"
    },
    {
        "iata": "RHL",
        "lon": "120.0",
        "iso": "AU",
        "status": 1,
        "name": "Roy Hill Station Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.75",
        "size": "small"
    },
    {
        "iata": "NDS",
        "lon": "119.3",
        "iso": "AU",
        "status": 1,
        "name": "Sandstone Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.983334",
        "size": "small"
    },
    {
        "iata": "BWU",
        "lon": "151.03334",
        "iso": "AU",
        "status": 1,
        "name": "Sydney Bankstown Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.916668",
        "size": "medium"
    },
    {
        "iata": "CBR",
        "lon": "149.19052",
        "iso": "AU",
        "status": 1,
        "name": "Canberra International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.30735",
        "size": "large"
    },
    {
        "iata": "CFS",
        "lon": "153.1153",
        "iso": "AU",
        "status": 1,
        "name": "Coffs Harbour Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.322863",
        "size": "medium"
    },
    {
        "iata": "CDU",
        "lon": "124.416664",
        "iso": "AU",
        "status": 1,
        "name": "Camden Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.45",
        "size": "medium"
    },
    {
        "iata": "NSO",
        "lon": "150.8325",
        "iso": "AU",
        "status": 1,
        "name": "Scone Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.03611",
        "size": "small"
    },
    {
        "iata": "SQC",
        "lon": "119.35",
        "iso": "AU",
        "status": 1,
        "name": "Southern Cross Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.25",
        "size": "small"
    },
    {
        "iata": "DBO",
        "lon": "148.56956",
        "iso": "AU",
        "status": 1,
        "name": "Dubbo City Regional Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.218822",
        "size": "medium"
    },
    {
        "iata": "SGO",
        "lon": "148.59389",
        "iso": "AU",
        "status": 1,
        "name": "St George Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.053333",
        "size": "small"
    },
    {
        "iata": "SIX",
        "lon": "151.16667",
        "iso": "AU",
        "status": 1,
        "name": "Singleton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.566666",
        "size": "small"
    },
    {
        "iata": "ZGL",
        "lon": "142.11667",
        "iso": "AU",
        "status": 1,
        "name": "South Galway Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.683332",
        "size": "small"
    },
    {
        "iata": "SGP",
        "lon": "120.23333",
        "iso": "AU",
        "status": 1,
        "name": "Shay Gap Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.816668",
        "size": "small"
    },
    {
        "iata": "DNM",
        "lon": "113.53333",
        "iso": "AU",
        "status": 1,
        "name": "Shark Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.916668",
        "size": "medium"
    },
    {
        "iata": "JHQ",
        "lon": "147.0",
        "iso": "AU",
        "status": 1,
        "name": "Shute Harbour Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.0",
        "size": "small"
    },
    {
        "iata": "SHT",
        "lon": "145.43333",
        "iso": "AU",
        "status": 1,
        "name": "Shepparton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.38333",
        "size": "medium"
    },
    {
        "iata": "SBR",
        "lon": "142.63333",
        "iso": "AU",
        "status": 1,
        "name": "Saibai Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.383333",
        "size": "small"
    },
    {
        "iata": "SIO",
        "lon": "145.08167",
        "iso": "AU",
        "status": 1,
        "name": "Smithton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.835835",
        "size": "medium"
    },
    {
        "iata": "SHU",
        "lon": "132.13333",
        "iso": "AU",
        "status": 1,
        "name": "Smith Point Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.133333",
        "size": "small"
    },
    {
        "iata": "STH",
        "lon": "142.58333",
        "iso": "AU",
        "status": 1,
        "name": "Strathmore Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.833332",
        "size": "small"
    },
    {
        "iata": "SNB",
        "lon": "130.66667",
        "iso": "AU",
        "status": 1,
        "name": "Snake Bay Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-11.416667",
        "size": "medium"
    },
    {
        "iata": "NLK",
        "lon": "167.93944",
        "iso": "NF",
        "status": 1,
        "name": "Norfolk Island International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.0425",
        "size": "medium"
    },
    {
        "iata": "NOA",
        "lon": "150.5375",
        "iso": "AU",
        "status": 1,
        "name": "Nowra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.950558",
        "size": "medium"
    },
    {
        "iata": "SNH",
        "lon": "151.95",
        "iso": "AU",
        "status": 1,
        "name": "Stanthorpe Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.65",
        "size": "small"
    },
    {
        "iata": "SCG",
        "lon": "142.41667",
        "iso": "AU",
        "status": 1,
        "name": "Spring Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.016666",
        "size": "small"
    },
    {
        "iata": "SHQ",
        "lon": "153.42778",
        "iso": "AU",
        "status": 1,
        "name": "Southport Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.951944",
        "size": "small"
    },
    {
        "iata": "KSV",
        "lon": "140.68333",
        "iso": "AU",
        "status": 1,
        "name": "Springvale Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-23.55",
        "size": "small"
    },
    {
        "iata": "SRN",
        "lon": "145.31667",
        "iso": "AU",
        "status": 1,
        "name": "Strahan Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-42.15",
        "size": "small"
    },
    {
        "iata": "SYD",
        "lon": "151.1799",
        "iso": "AU",
        "status": 1,
        "name": "Sydney Kingsford Smith International Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.932922",
        "size": "large"
    },
    {
        "iata": "HLS",
        "lon": "148.25",
        "iso": "AU",
        "status": 1,
        "name": "St Helens Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-41.266666",
        "size": "small"
    },
    {
        "iata": "TMW",
        "lon": "150.8485",
        "iso": "AU",
        "status": 1,
        "name": "Tamworth Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.0845",
        "size": "medium"
    },
    {
        "iata": "WGA",
        "lon": "147.46626",
        "iso": "AU",
        "status": 1,
        "name": "Wagga Wagga City Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.15992",
        "size": "medium"
    },
    {
        "iata": "SWH",
        "lon": "143.61667",
        "iso": "AU",
        "status": 1,
        "name": "Swan Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.38333",
        "size": "medium"
    },
    {
        "iata": "SWC",
        "lon": "142.76666",
        "iso": "AU",
        "status": 1,
        "name": "Stawell Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-37.066666",
        "size": "medium"
    },
    {
        "iata": "XTR",
        "lon": "150.46666",
        "iso": "AU",
        "status": 1,
        "name": "Tara Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.283333",
        "size": "small"
    },
    {
        "iata": "TBL",
        "lon": "126.833336",
        "iso": "AU",
        "status": 1,
        "name": "Tableland Homestead Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.3",
        "size": "small"
    },
    {
        "iata": "XTO",
        "lon": "149.8",
        "iso": "AU",
        "status": 1,
        "name": "Taroom Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.433332",
        "size": "small"
    },
    {
        "iata": "TAQ",
        "lon": "134.55",
        "iso": "AU",
        "status": 1,
        "name": "Tarcoola Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.683332",
        "size": "small"
    },
    {
        "iata": "TBK",
        "lon": "130.48334",
        "iso": "AU",
        "status": 1,
        "name": "Timber Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.65",
        "size": "small"
    },
    {
        "iata": "TDR",
        "lon": "150.08333",
        "iso": "AU",
        "status": 1,
        "name": "Theodore Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.983334",
        "size": "small"
    },
    {
        "iata": "TEF",
        "lon": "122.21222",
        "iso": "AU",
        "status": 1,
        "name": "Telfer Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.713057",
        "size": "medium"
    },
    {
        "iata": "TEM",
        "lon": "147.5",
        "iso": "AU",
        "status": 1,
        "name": "Temora Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.416668",
        "size": "medium"
    },
    {
        "iata": "TAN",
        "lon": "153.25",
        "iso": "AU",
        "status": 1,
        "name": "Tangalooma Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.083332",
        "size": "small"
    },
    {
        "iata": "XTG",
        "lon": "143.76666",
        "iso": "AU",
        "status": 1,
        "name": "Thargomindah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.966667",
        "size": "medium"
    },
    {
        "iata": "TYG",
        "lon": "143.45555",
        "iso": "AU",
        "status": 1,
        "name": "Thylungra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.098612",
        "size": "small"
    },
    {
        "iata": "TYB",
        "lon": "142.06223",
        "iso": "AU",
        "status": 1,
        "name": "Tibooburra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-29.456944",
        "size": "medium"
    },
    {
        "iata": "TKY",
        "lon": "128.25",
        "iso": "AU",
        "status": 1,
        "name": "Turkey Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.066668",
        "size": "small"
    },
    {
        "iata": "TUM",
        "lon": "148.21666",
        "iso": "AU",
        "status": 1,
        "name": "Tumut Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.3",
        "size": "medium"
    },
    {
        "iata": "TYP",
        "lon": "137.96666",
        "iso": "AU",
        "status": 1,
        "name": "Tobermorey Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.283333",
        "size": "small"
    },
    {
        "iata": "THG",
        "lon": "150.57639",
        "iso": "AU",
        "status": 1,
        "name": "Thangool Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.494162",
        "size": "medium"
    },
    {
        "iata": "TCA",
        "lon": "134.18083",
        "iso": "AU",
        "status": 1,
        "name": "Tennant Creek Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.638056",
        "size": "medium"
    },
    {
        "iata": "TCW",
        "lon": "145.6",
        "iso": "AU",
        "status": 1,
        "name": "Tocumwal Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.816666",
        "size": "small"
    },
    {
        "iata": "TRO",
        "lon": "152.50896",
        "iso": "AU",
        "status": 1,
        "name": "Taree Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.890272",
        "size": "medium"
    },
    {
        "iata": "YTU",
        "iso": "CA",
        "status": 1,
        "name": "Tasu Water Aerodrome",
        "continent": "NA",
        "type": "seaplanes",
        "size": null
    },
    {
        "iata": "TWB",
        "lon": "151.9139",
        "iso": "AU",
        "status": 1,
        "name": "Toowoomba Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.542221",
        "size": "medium"
    },
    {
        "iata": "UDA",
        "lon": "144.6",
        "iso": "AU",
        "status": 1,
        "name": "Undara Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-18.183332",
        "size": "small"
    },
    {
        "iata": "CZY",
        "lon": "139.53334",
        "iso": "AU",
        "status": 1,
        "name": "Cluny Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-24.516666",
        "size": "small"
    },
    {
        "iata": "USL",
        "lon": "113.11667",
        "iso": "AU",
        "status": 1,
        "name": "Useless Loop Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.966667",
        "size": "small"
    },
    {
        "iata": "VCD",
        "lon": "131.0",
        "iso": "AU",
        "status": 1,
        "name": "Victoria River Downs Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.4",
        "size": "small"
    },
    {
        "iata": "VNR",
        "lon": "141.91667",
        "iso": "AU",
        "status": 1,
        "name": "Vanrook Station Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.866667",
        "size": "small"
    },
    {
        "iata": "WLA",
        "lon": "120.666664",
        "iso": "AU",
        "status": 1,
        "name": "Wallal Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-19.783333",
        "size": "small"
    },
    {
        "iata": "WAV",
        "lon": "130.95",
        "iso": "AU",
        "status": 1,
        "name": "Wave Hill Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-17.483334",
        "size": "small"
    },
    {
        "iata": "WMB",
        "lon": "142.44833",
        "iso": "AU",
        "status": 1,
        "name": "Warrnambool Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-38.295",
        "size": "small"
    },
    {
        "iata": "SYU",
        "lon": "142.81667",
        "iso": "AU",
        "status": 1,
        "name": "Warraber Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.208333",
        "size": "small"
    },
    {
        "iata": "WIO",
        "lon": "143.55",
        "iso": "AU",
        "status": 1,
        "name": "Wilcannia Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.083332",
        "size": "small"
    },
    {
        "iata": "WLC",
        "lon": "151.6",
        "iso": "AU",
        "status": 1,
        "name": "Walcha Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.983334",
        "size": "small"
    },
    {
        "iata": "WAZ",
        "lon": "151.95",
        "iso": "AU",
        "status": 1,
        "name": "Warwick Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.15",
        "size": "small"
    },
    {
        "iata": "WND",
        "lon": "121.833336",
        "iso": "AU",
        "status": 1,
        "name": "Windarra Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.266666",
        "size": "small"
    },
    {
        "iata": "WNR",
        "lon": "142.66667",
        "iso": "AU",
        "status": 1,
        "name": "Windorah Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-25.416668",
        "size": "medium"
    },
    {
        "iata": "WGT",
        "lon": "146.33333",
        "iso": "AU",
        "status": 1,
        "name": "Wangaratta Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.36667",
        "size": "medium"
    },
    {
        "iata": "WYA",
        "lon": "137.52197",
        "iso": "AU",
        "status": 1,
        "name": "Whyalla Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.05241",
        "size": "medium"
    },
    {
        "iata": "WIT",
        "lon": "118.333336",
        "iso": "AU",
        "status": 1,
        "name": "Wittenoom Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.266666",
        "size": "small"
    },
    {
        "iata": "WKB",
        "lon": "142.43333",
        "iso": "AU",
        "status": 1,
        "name": "Warracknabeal Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-36.15",
        "size": "medium"
    },
    {
        "iata": "WGE",
        "lon": "148.13333",
        "iso": "AU",
        "status": 1,
        "name": "Walgett Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.033333",
        "size": "medium"
    },
    {
        "iata": "NTL",
        "lon": "151.84006",
        "iso": "AU",
        "status": 1,
        "name": "Newcastle Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-32.804592",
        "size": "medium"
    },
    {
        "iata": "WUN",
        "lon": "120.21667",
        "iso": "AU",
        "status": 1,
        "name": "Wiluna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.633333",
        "size": "medium"
    },
    {
        "iata": "WPK",
        "lon": "144.0",
        "iso": "AU",
        "status": 1,
        "name": "Wrotham Park Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.633333",
        "size": "small"
    },
    {
        "iata": "WDI",
        "lon": "151.81667",
        "iso": "AU",
        "status": 1,
        "name": "Wondai Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-26.333332",
        "size": "small"
    },
    {
        "iata": "WOL",
        "lon": "150.78334",
        "iso": "AU",
        "status": 1,
        "name": "Wollongong Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.566666",
        "size": "medium"
    },
    {
        "iata": "QRR",
        "lon": "147.825",
        "iso": "AU",
        "status": 1,
        "name": "Warren Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.733334",
        "size": "medium"
    },
    {
        "iata": "WLO",
        "lon": "129.31667",
        "iso": "AU",
        "status": 1,
        "name": "Waterloo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-16.633333",
        "size": "small"
    },
    {
        "iata": "WIN",
        "lon": "143.06667",
        "iso": "AU",
        "status": 1,
        "name": "Winton Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.35",
        "size": "medium"
    },
    {
        "iata": "WUD",
        "lon": "135.61945",
        "iso": "AU",
        "status": 1,
        "name": "Wudinna Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-33.016666",
        "size": "small"
    },
    {
        "iata": "WEW",
        "lon": "149.43333",
        "iso": "AU",
        "status": 1,
        "name": "Wee Waa Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-30.233334",
        "size": "small"
    },
    {
        "iata": "WRW",
        "lon": "120.71667",
        "iso": "AU",
        "status": 1,
        "name": "Warrawagine Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-20.866667",
        "size": "small"
    },
    {
        "iata": "WWI",
        "lon": "121.23333",
        "iso": "AU",
        "status": 1,
        "name": "Woodie Woodie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-21.633333",
        "size": "small"
    },
    {
        "iata": "WYN",
        "lon": "128.1375",
        "iso": "AU",
        "status": 1,
        "name": "Wyndham Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-15.507222",
        "size": "small"
    },
    {
        "iata": "BWT",
        "lon": "145.72507",
        "iso": "AU",
        "status": 1,
        "name": "Wynyard Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-40.993145",
        "size": "medium"
    },
    {
        "iata": "YLG",
        "lon": "116.666664",
        "iso": "AU",
        "status": 1,
        "name": "Yalgoo Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-28.35",
        "size": "small"
    },
    {
        "iata": "OKR",
        "lon": "143.40556",
        "iso": "AU",
        "status": 1,
        "name": "Yorke Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-9.751593",
        "size": "small"
    },
    {
        "iata": "KYF",
        "lon": "120.075",
        "iso": "AU",
        "status": 1,
        "name": "Yeelirrie Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-27.277779",
        "size": "small"
    },
    {
        "iata": "XMY",
        "lon": "142.45",
        "iso": "AU",
        "status": 1,
        "name": "Yam Island Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-10.483333",
        "size": "small"
    },
    {
        "iata": "YUE",
        "lon": "131.81667",
        "iso": "AU",
        "status": 1,
        "name": "Yuendumu Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-22.266666",
        "size": "small"
    },
    {
        "iata": "NGA",
        "lon": "148.25",
        "iso": "AU",
        "status": 1,
        "name": "Young Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-34.25",
        "size": "medium"
    },
    {
        "iata": "ORR",
        "lon": "137.58333",
        "iso": "AU",
        "status": 1,
        "name": "Yorketown Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-35.033333",
        "size": "small"
    },
    {
        "iata": "KYI",
        "lon": "131.86667",
        "iso": "AU",
        "status": 1,
        "name": "Yalata Mission Airport",
        "continent": "OC",
        "type": "airport",
        "lat": "-31.483334",
        "size": "small"
    },
    {
        "iata": "HPR",
        "iso": "ZA",
        "status": 1,
        "name": "Pretoria Central Heliport",
        "continent": "AF",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "ULX",
        "lon": "31.35",
        "iso": "ZA",
        "status": 1,
        "name": "Ulusaba Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.783333",
        "size": "small"
    },
    {
        "iata": "TDT",
        "lon": "31.3",
        "iso": "ZA",
        "status": 1,
        "name": "Tanda Tula Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.533333",
        "size": "small"
    },
    {
        "iata": "HZV",
        "lon": "31.130556",
        "iso": "ZA",
        "status": 1,
        "name": "Hazyview Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-25.066668",
        "size": "small"
    },
    {
        "iata": "KHO",
        "lon": "31.416668",
        "iso": "ZA",
        "status": 1,
        "name": "Khoka Moya Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.583332",
        "size": "small"
    },
    {
        "iata": "MBM",
        "lon": "29.966667",
        "iso": "ZA",
        "status": 1,
        "name": "Mkambati Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-31.283333",
        "size": "small"
    },
    {
        "iata": "INY",
        "lon": "31.383333",
        "iso": "ZA",
        "status": 1,
        "name": "Inyati Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-24.783333",
        "size": "small"
    },
    {
        "iata": "TSD",
        "lon": "30.166668",
        "iso": "ZA",
        "status": 1,
        "name": "Tshipise Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-22.616667",
        "size": "small"
    },
    {
        "iata": "KIG",
        "lon": "17.266666",
        "iso": "ZA",
        "status": 1,
        "name": "Koingnaas Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-30.2",
        "size": "small"
    },
    {
        "iata": "PEK",
        "lon": "116.5871",
        "iso": "CN",
        "status": 1,
        "name": "Beijing Capital International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.078537",
        "size": "large"
    },
    {
        "iata": "CIF",
        "lon": "118.91089",
        "iso": "CN",
        "status": 1,
        "name": "Chifeng Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.237392",
        "size": "medium"
    },
    {
        "iata": "CIH",
        "lon": "113.12158",
        "iso": "CN",
        "status": 1,
        "name": "Changzhi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.24588",
        "size": "small"
    },
    {
        "iata": "DSN",
        "lon": "109.86053",
        "iso": "CN",
        "status": 1,
        "name": "Ordos Ejin Horo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.49672",
        "size": "medium"
    },
    {
        "iata": "DAT",
        "lon": "113.48136",
        "iso": "CN",
        "status": 1,
        "name": "Datong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.05554",
        "size": "small"
    },
    {
        "iata": "ERL",
        "lon": "112.09845",
        "iso": "CN",
        "status": 1,
        "name": "Erenhot Saiwusu International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.426674",
        "size": "medium"
    },
    {
        "iata": "HDG",
        "lon": "114.425",
        "iso": "CN",
        "status": 1,
        "name": "Handan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.525833",
        "size": "medium"
    },
    {
        "iata": "HET",
        "lon": "111.814156",
        "iso": "CN",
        "status": 1,
        "name": "Baita International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.854713",
        "size": "medium"
    },
    {
        "iata": "ZBK",
        "lon": "19.116667",
        "iso": "ME",
        "status": 1,
        "name": null,
        "continent": "EU",
        "type": "airport",
        "lat": "43.15",
        "size": "small"
    },
    {
        "iata": "HLD",
        "lon": "119.80654",
        "iso": "CN",
        "status": 1,
        "name": "Dongshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "49.209873",
        "size": "medium"
    },
    {
        "iata": "NAY",
        "lon": "116.38778",
        "iso": "CN",
        "status": 1,
        "name": "Beijing Nanyuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.7825",
        "size": "large"
    },
    {
        "iata": "BAV",
        "lon": "110.0009",
        "iso": "CN",
        "status": 1,
        "name": "Baotou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.563328",
        "size": "medium"
    },
    {
        "iata": "SHP",
        "lon": "119.71656",
        "iso": "CN",
        "status": 1,
        "name": "Shanhaiguan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.965214",
        "size": "medium"
    },
    {
        "iata": "SJW",
        "lon": "114.69443",
        "iso": "CN",
        "status": 1,
        "name": "Shijiazhuang Daguocun International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.274376",
        "size": "medium"
    },
    {
        "iata": "TSN",
        "lon": "117.3399",
        "iso": "CN",
        "status": 1,
        "name": "Tianjin Binhai International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.122627",
        "size": "large"
    },
    {
        "iata": "TGO",
        "lon": "122.204605",
        "iso": "CN",
        "status": 1,
        "name": "Tongliao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.55701",
        "size": "medium"
    },
    {
        "iata": "HLH",
        "lon": "122.00148",
        "iso": "CN",
        "status": 1,
        "name": "Ulanhot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.19349",
        "size": "small"
    },
    {
        "iata": "XIL",
        "lon": "115.95924",
        "iso": "CN",
        "status": 1,
        "name": "Xilinhot Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.918762",
        "size": "medium"
    },
    {
        "iata": "YCU",
        "lon": "110.993",
        "iso": "CN",
        "status": 1,
        "name": "Yuncheng Guangong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.018",
        "size": "small"
    },
    {
        "iata": "TYN",
        "lon": "112.62585",
        "iso": "CN",
        "status": 1,
        "name": "Taiyuan Wusu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.754997",
        "size": "large"
    },
    {
        "iata": "BHY",
        "lon": "109.28785",
        "iso": "CN",
        "status": 1,
        "name": "Beihai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.540333",
        "size": "small"
    },
    {
        "iata": "CGD",
        "lon": "111.63886",
        "iso": "CN",
        "status": 1,
        "name": "Changde Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.924467",
        "size": "medium"
    },
    {
        "iata": "HJJ",
        "lon": "109.69972",
        "iso": "CN",
        "status": 1,
        "name": "Zhijiang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.44139",
        "size": "medium"
    },
    {
        "iata": "DYG",
        "lon": "110.44569",
        "iso": "CN",
        "status": 1,
        "name": "Dayong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.10712",
        "size": "small"
    },
    {
        "iata": "CAN",
        "lon": "113.29734",
        "iso": "CN",
        "status": 1,
        "name": "Guangzhou Baiyun International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.387861",
        "size": "large"
    },
    {
        "iata": "CSX",
        "lon": "113.21459",
        "iso": "CN",
        "status": 1,
        "name": "Changsha Huanghua Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.193336",
        "size": "large"
    },
    {
        "iata": "HNY",
        "lon": "112.5",
        "iso": "CN",
        "status": 1,
        "name": "Hengyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.85",
        "size": "small"
    },
    {
        "iata": "KWL",
        "lon": "110.316666",
        "iso": "CN",
        "status": 1,
        "name": "Guilin Liangjiang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.133333",
        "size": "large"
    },
    {
        "iata": "MXZ",
        "lon": "116.10449",
        "iso": "CN",
        "status": 1,
        "name": "Meixian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.266811",
        "size": "small"
    },
    {
        "iata": "NNG",
        "lon": "108.1675",
        "iso": "CN",
        "status": 1,
        "name": "Nanning Wuxu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.61321",
        "size": "large"
    },
    {
        "iata": "SWA",
        "lon": "116.75859",
        "iso": "CN",
        "status": 1,
        "name": "Shantou Waisha Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.430237",
        "size": "medium"
    },
    {
        "iata": "ZUH",
        "lon": "113.3706",
        "iso": "CN",
        "status": 1,
        "name": "Zhuhai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.011787",
        "size": "medium"
    },
    {
        "iata": "SZX",
        "lon": "113.81084",
        "iso": "CN",
        "status": 1,
        "name": "Shenzhen Bao'an International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.639444",
        "size": "large"
    },
    {
        "iata": "WUZ",
        "lon": "111.25191",
        "iso": "CN",
        "status": 1,
        "name": "Changzhoudao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "23.456062",
        "size": "small"
    },
    {
        "iata": "XIN",
        "lon": "116.0",
        "iso": "CN",
        "status": 1,
        "name": "Xingning Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.0",
        "size": "small"
    },
    {
        "iata": "LZH",
        "lon": "109.39843",
        "iso": "CN",
        "status": 1,
        "name": "Bailian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.206156",
        "size": "medium"
    },
    {
        "iata": "ZHA",
        "lon": "110.36272",
        "iso": "CN",
        "status": 1,
        "name": "Zhanjiang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.214727",
        "size": "medium"
    },
    {
        "iata": "AYN",
        "lon": "114.35",
        "iso": "CN",
        "status": 1,
        "name": "Anyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.1",
        "size": "small"
    },
    {
        "iata": "CGO",
        "lon": "113.84024",
        "iso": "CN",
        "status": 1,
        "name": "Xinzheng Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.52752",
        "size": "large"
    },
    {
        "iata": "ENH",
        "lon": "109.48201",
        "iso": "CN",
        "status": 1,
        "name": "Enshi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.321934",
        "size": "small"
    },
    {
        "iata": "LHK",
        "lon": "111.63333",
        "iso": "CN",
        "status": 1,
        "name": "Guangzhou MR Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "32.266666",
        "size": "medium"
    },
    {
        "iata": "WUH",
        "lon": "114.209625",
        "iso": "CN",
        "status": 1,
        "name": "Wuhan Tianhe International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.776598",
        "size": "large"
    },
    {
        "iata": "LYA",
        "lon": "112.38541",
        "iso": "CN",
        "status": 1,
        "name": "Luoyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.736362",
        "size": "small"
    },
    {
        "iata": "NNY",
        "lon": "112.61305",
        "iso": "CN",
        "status": 1,
        "name": "Nanyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.9837",
        "size": "small"
    },
    {
        "iata": "SHS",
        "lon": "112.23333",
        "iso": "CN",
        "status": 1,
        "name": "Shashi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.316668",
        "size": "small"
    },
    {
        "iata": "XFN",
        "lon": "112.28627",
        "iso": "CN",
        "status": 1,
        "name": "Xiangfan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.151558",
        "size": "small"
    },
    {
        "iata": "YIH",
        "lon": "111.478935",
        "iso": "CN",
        "status": 1,
        "name": "Yichang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.549934",
        "size": "medium"
    },
    {
        "iata": "ZIZ",
        "iso": "PK",
        "status": 1,
        "name": "Zamzama Heliport",
        "continent": "AS",
        "type": "heliport",
        "size": null
    },
    {
        "iata": "HAK",
        "lon": "110.45717",
        "iso": "CN",
        "status": 1,
        "name": "Haikou Meilan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "19.941612",
        "size": "large"
    },
    {
        "iata": "SYX",
        "lon": "109.409706",
        "iso": "CN",
        "status": 1,
        "name": "Sanya Phoenix International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "18.31063",
        "size": "large"
    },
    {
        "iata": "FNJ",
        "lon": "125.666664",
        "iso": "KP",
        "status": 1,
        "name": "Sunan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.2",
        "size": "medium"
    },
    {
        "iata": "AKA",
        "lon": "108.936",
        "iso": "CN",
        "status": 1,
        "name": "Ankang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.7091",
        "size": "small"
    },
    {
        "iata": "LHW",
        "iso": "CN",
        "status": 0,
        "name": "Lanzhou City Airport",
        "continent": "AS",
        "type": "closed",
        "size": null
    },
    {
        "iata": "DNH",
        "lon": "94.683334",
        "iso": "CN",
        "status": 1,
        "name": "Dunhuang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "40.2",
        "size": "medium"
    },
    {
        "iata": "GOQ",
        "lon": "94.78931",
        "iso": "CN",
        "status": 1,
        "name": "Golmud Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.404633",
        "size": "medium"
    },
    {
        "iata": "GYU",
        "lon": "106.218025",
        "iso": "CN",
        "status": 1,
        "name": "Guyuan Liupanshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.075325",
        "size": "medium"
    },
    {
        "iata": "HZG",
        "lon": "107.183334",
        "iso": "CN",
        "status": 1,
        "name": "Hanzhong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.983334",
        "size": "small"
    },
    {
        "iata": "INC",
        "lon": "106.35",
        "iso": "CN",
        "status": 1,
        "name": "Yinchuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.35",
        "size": "medium"
    },
    {
        "iata": "JNG",
        "lon": "116.53333",
        "iso": "CN",
        "status": 1,
        "name": "Jining Qufu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.416668",
        "size": "medium"
    },
    {
        "iata": "JGN",
        "lon": "98.416664",
        "iso": "CN",
        "status": 1,
        "name": "Jiayuguan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.833332",
        "size": "medium"
    },
    {
        "iata": "ZGC",
        "lon": "103.85",
        "iso": "CN",
        "status": 1,
        "name": "Lanzhou Zhongchuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.05",
        "size": "medium"
    },
    {
        "iata": "IQN",
        "lon": "107.6044",
        "iso": "CN",
        "status": 1,
        "name": "Qingyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.8001",
        "size": "medium"
    },
    {
        "iata": "SIA",
        "lon": "108.75874",
        "iso": "CN",
        "status": 1,
        "name": "Xiguan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.44104",
        "size": "medium"
    },
    {
        "iata": "XNN",
        "lon": "102.03754",
        "iso": "CN",
        "status": 1,
        "name": "Xining Caojiabu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.525322",
        "size": "medium"
    },
    {
        "iata": "XIY",
        "lon": "108.75605",
        "iso": "CN",
        "status": 1,
        "name": "Xi'an Xianyang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.441154",
        "size": "large"
    },
    {
        "iata": "ENY",
        "lon": "109.55148",
        "iso": "CN",
        "status": 1,
        "name": "Yan'an Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.639366",
        "size": "small"
    },
    {
        "iata": "UYN",
        "lon": "109.73038",
        "iso": "CN",
        "status": 1,
        "name": "Yulin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.274155",
        "size": "small"
    },
    {
        "iata": "CGJ",
        "lon": "27.883333",
        "iso": "ZM",
        "status": 1,
        "name": "Chingola Airport",
        "continent": "AF",
        "type": "airport",
        "lat": "-12.516667",
        "size": "small"
    },
    {
        "iata": "AVK",
        "lon": "102.78333",
        "iso": "MN",
        "status": 1,
        "name": "Arvaikheer Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.266666",
        "size": "medium"
    },
    {
        "iata": "LTI",
        "lon": "96.416664",
        "iso": "MN",
        "status": 1,
        "name": "Altai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.65",
        "size": "medium"
    },
    {
        "iata": "BYN",
        "lon": "100.683334",
        "iso": "MN",
        "status": 1,
        "name": "Bayankhongor Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.1",
        "size": "medium"
    },
    {
        "iata": "UGA",
        "lon": "103.55",
        "iso": "MN",
        "status": 1,
        "name": "Bulgan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "48.8",
        "size": "medium"
    },
    {
        "iata": "UGT",
        "lon": "104.12778",
        "iso": "MN",
        "status": 1,
        "name": "Bulagtai Resort Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.752777",
        "size": "small"
    },
    {
        "iata": "HBU",
        "lon": "91.58611",
        "iso": "MN",
        "status": 1,
        "name": "Bulgan Sum Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.102222",
        "size": "small"
    },
    {
        "iata": "UUN",
        "lon": "113.28333",
        "iso": "MN",
        "status": 1,
        "name": "Baruun Urt Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.683334",
        "size": "medium"
    },
    {
        "iata": "COQ",
        "lon": "114.9",
        "iso": "MN",
        "status": 1,
        "name": "Choibalsan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "48.13333",
        "size": "medium"
    },
    {
        "iata": "DLZ",
        "lon": "104.683334",
        "iso": "MN",
        "status": 1,
        "name": "Dalanzadgad Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.966667",
        "size": "medium"
    },
    {
        "iata": "HVD",
        "lon": "91.65",
        "iso": "MN",
        "status": 1,
        "name": "Khovd Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "48.016666",
        "size": "medium"
    },
    {
        "iata": "MXV",
        "lon": "100.166664",
        "iso": "MN",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "49.63333",
        "size": "medium"
    },
    {
        "iata": "ULN",
        "lon": "106.76327",
        "iso": "MN",
        "status": 1,
        "name": "Chinggis Khaan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "47.85275",
        "size": "medium"
    },
    {
        "iata": "ULG",
        "lon": "89.96667",
        "iso": "MN",
        "status": 1,
        "name": "Ulgii Mongolei Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "48.966667",
        "size": "small"
    },
    {
        "iata": "DLU",
        "lon": "100.32316",
        "iso": "CN",
        "status": 1,
        "name": "Dali Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.65135",
        "size": "medium"
    },
    {
        "iata": "DIG",
        "lon": "99.67778",
        "iso": "CN",
        "status": 1,
        "name": "Diqing Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.789722",
        "size": "medium"
    },
    {
        "iata": "JHG",
        "lon": "100.76646",
        "iso": "CN",
        "status": 1,
        "name": "Xishuangbanna Gasa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "21.973421",
        "size": "medium"
    },
    {
        "iata": "LJG",
        "lon": "100.25",
        "iso": "CN",
        "status": 1,
        "name": "Lijiang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.683332",
        "size": "medium"
    },
    {
        "iata": "LUM",
        "lon": "98.5319",
        "iso": "CN",
        "status": 1,
        "name": "Mangshi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.40528",
        "size": "medium"
    },
    {
        "iata": "KMG",
        "lon": "102.92917",
        "iso": "CN",
        "status": 1,
        "name": "Kunming Wujiaba International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.101944",
        "size": "large"
    },
    {
        "iata": "SYM",
        "lon": "100.96222",
        "iso": "CN",
        "status": 1,
        "name": "Simao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "22.79657",
        "size": "small"
    },
    {
        "iata": "ZAT",
        "lon": "103.8",
        "iso": "CN",
        "status": 1,
        "name": "Zhaotong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.316668",
        "size": "small"
    },
    {
        "iata": "XMN",
        "lon": "118.1275",
        "iso": "CN",
        "status": 1,
        "name": "Xiamen Gaoqi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.536882",
        "size": "large"
    },
    {
        "iata": "AQG",
        "lon": "117.0509",
        "iso": "CN",
        "status": 1,
        "name": "Anqing Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.5825",
        "size": "medium"
    },
    {
        "iata": "CZX",
        "lon": "119.77976",
        "iso": "CN",
        "status": 1,
        "name": "Changzhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.914116",
        "size": "small"
    },
    {
        "iata": "KHN",
        "lon": "115.907845",
        "iso": "CN",
        "status": 1,
        "name": "Nanchang Changbei International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.859901",
        "size": "medium"
    },
    {
        "iata": "FOC",
        "lon": "119.66923",
        "iso": "CN",
        "status": 1,
        "name": "Fuzhou Changle International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.93123",
        "size": "large"
    },
    {
        "iata": "KOW",
        "lon": "114.776665",
        "iso": "CN",
        "status": 1,
        "name": "Ganzhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.851667",
        "size": "small"
    },
    {
        "iata": "HGH",
        "lon": "120.43236",
        "iso": "CN",
        "status": 1,
        "name": "Hangzhou Xiaoshan International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.236935",
        "size": "large"
    },
    {
        "iata": "JDZ",
        "lon": "117.17879",
        "iso": "CN",
        "status": 1,
        "name": "Jingdezhen Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.33549",
        "size": "medium"
    },
    {
        "iata": "JIU",
        "lon": "115.96667",
        "iso": "CN",
        "status": 1,
        "name": "Jiujiang Lushan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.7",
        "size": "small"
    },
    {
        "iata": "TNA",
        "lon": "117.20688",
        "iso": "CN",
        "status": 1,
        "name": "Yaoqiang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.85769",
        "size": "large"
    },
    {
        "iata": "JUZ",
        "lon": "118.833336",
        "iso": "CN",
        "status": 1,
        "name": "Quzhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.966667",
        "size": "medium"
    },
    {
        "iata": "LCX",
        "lon": "116.74556",
        "iso": "CN",
        "status": 1,
        "name": "Longyan Guanzhishan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.675556",
        "size": "medium"
    },
    {
        "iata": "LYG",
        "lon": "118.87698",
        "iso": "CN",
        "status": 1,
        "name": "Lianyungang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.565937",
        "size": "small"
    },
    {
        "iata": "HYN",
        "lon": "121.41675",
        "iso": "CN",
        "status": 1,
        "name": "Huangyan Luqiao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.558096",
        "size": "medium"
    },
    {
        "iata": "LYI",
        "lon": "118.40707",
        "iso": "CN",
        "status": 1,
        "name": "Shubuling Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "35.047478",
        "size": "small"
    },
    {
        "iata": "NGB",
        "lon": "121.462395",
        "iso": "CN",
        "status": 1,
        "name": "Ningbo Lishe International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.820415",
        "size": "large"
    },
    {
        "iata": "NKG",
        "lon": "118.86652",
        "iso": "CN",
        "status": 1,
        "name": "Nanjing Lukou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.735737",
        "size": "large"
    },
    {
        "iata": "HFE",
        "lon": "116.975296",
        "iso": "CN",
        "status": 1,
        "name": "Hefei Luogang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.985506",
        "size": "medium"
    },
    {
        "iata": "PVG",
        "lon": "121.799805",
        "iso": "CN",
        "status": 1,
        "name": "Shanghai Pudong International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.151825",
        "size": "large"
    },
    {
        "iata": "TAO",
        "lon": "120.38236",
        "iso": "CN",
        "status": 1,
        "name": "Liuting Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.265884",
        "size": "medium"
    },
    {
        "iata": "JJN",
        "lon": "118.587296",
        "iso": "CN",
        "status": 1,
        "name": "Quanzhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.802809",
        "size": "medium"
    },
    {
        "iata": "RUG",
        "lon": "120.555275",
        "iso": "CN",
        "status": 1,
        "name": "Rugao Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "32.388332",
        "size": "medium"
    },
    {
        "iata": "SHA",
        "lon": "121.34197",
        "iso": "CN",
        "status": 1,
        "name": "Shanghai Hongqiao International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.196815",
        "size": "large"
    },
    {
        "iata": "SZV",
        "lon": "120.63333",
        "iso": "CN",
        "status": 1,
        "name": "Guangfu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.3",
        "size": "medium"
    },
    {
        "iata": "TXN",
        "lon": "118.25646",
        "iso": "CN",
        "status": 1,
        "name": "Tunxi International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.72962",
        "size": "medium"
    },
    {
        "iata": "WEF",
        "lon": "119.11347",
        "iso": "CN",
        "status": 1,
        "name": "Weifang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "36.641075",
        "size": "medium"
    },
    {
        "iata": "WEH",
        "lon": "122.23607",
        "iso": "CN",
        "status": 1,
        "name": "Weihai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.18814",
        "size": "medium"
    },
    {
        "iata": "WHU",
        "lon": "118.36667",
        "iso": "CN",
        "status": 1,
        "name": "Wuhu Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "31.366667",
        "size": "medium"
    },
    {
        "iata": "WUX",
        "lon": "120.42438",
        "iso": "CN",
        "status": 1,
        "name": "Sunan Shuofang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.49289",
        "size": "medium"
    },
    {
        "iata": "WUS",
        "lon": "117.96667",
        "iso": "CN",
        "status": 1,
        "name": "Nanping Wuyishan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.716667",
        "size": "medium"
    },
    {
        "iata": "WNZ",
        "lon": "120.84738",
        "iso": "CN",
        "status": 1,
        "name": "Wenzhou Yongqiang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.91566",
        "size": "large"
    },
    {
        "iata": "XUZ",
        "lon": "117.55408",
        "iso": "CN",
        "status": 1,
        "name": "Xuzhou Guanyin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "34.052925",
        "size": "medium"
    },
    {
        "iata": "YNZ",
        "lon": "120.20418",
        "iso": "CN",
        "status": 1,
        "name": "Yancheng Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "33.43468",
        "size": "small"
    },
    {
        "iata": "YNT",
        "lon": "121.3711",
        "iso": "CN",
        "status": 1,
        "name": "Yantai Laishan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.407326",
        "size": "medium"
    },
    {
        "iata": "YIW",
        "lon": "120.02881",
        "iso": "CN",
        "status": 1,
        "name": "Yiwu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.344563",
        "size": "medium"
    },
    {
        "iata": "HSN",
        "lon": "122.35691",
        "iso": "CN",
        "status": 1,
        "name": "Zhoushan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.935259",
        "size": "small"
    },
    {
        "iata": "NGQ",
        "lon": "80.054276",
        "iso": "CN",
        "status": 1,
        "name": "Ngari Gunsa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.103817",
        "size": "medium"
    },
    {
        "iata": "AVA",
        "lon": "105.87281",
        "iso": "CN",
        "status": 1,
        "name": "Anshun Huangguoshu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.26005",
        "size": "medium"
    },
    {
        "iata": "BPX",
        "lon": "97.10667",
        "iso": "CN",
        "status": 1,
        "name": "Qamdo Bangda Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.555834",
        "size": "medium"
    },
    {
        "iata": "CKG",
        "lon": "106.63408",
        "iso": "CN",
        "status": 1,
        "name": "Chongqing Jiangbei International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.72034",
        "size": "large"
    },
    {
        "iata": "DAX",
        "lon": "107.42689",
        "iso": "CN",
        "status": 1,
        "name": "Dachuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.135012",
        "size": "small"
    },
    {
        "iata": "GYS",
        "lon": "105.69972",
        "iso": "CN",
        "status": 1,
        "name": "Guangyuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.395",
        "size": "medium"
    },
    {
        "iata": "KWE",
        "lon": "106.79598",
        "iso": "CN",
        "status": 1,
        "name": "Longdongbao Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "26.544216",
        "size": "large"
    },
    {
        "iata": "JZH",
        "lon": "103.68306",
        "iso": "CN",
        "status": 1,
        "name": "Jiuzhai Huanglong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.856667",
        "size": "medium"
    },
    {
        "iata": "LIA",
        "lon": "107.7",
        "iso": "CN",
        "status": 1,
        "name": "Liangping Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.816668",
        "size": "small"
    },
    {
        "iata": "LXA",
        "lon": "90.90051",
        "iso": "CN",
        "status": 1,
        "name": "Lhasa Gonggar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.29305",
        "size": "medium"
    },
    {
        "iata": "LZO",
        "lon": "105.37902",
        "iso": "CN",
        "status": 1,
        "name": "Luzhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.844757",
        "size": "small"
    },
    {
        "iata": "MIG",
        "lon": "104.73805",
        "iso": "CN",
        "status": 1,
        "name": "Mianyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.43111",
        "size": "medium"
    },
    {
        "iata": "NAO",
        "lon": "106.066666",
        "iso": "CN",
        "status": 1,
        "name": "Nanchong Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.8",
        "size": "small"
    },
    {
        "iata": "LZY",
        "lon": "94.335884",
        "iso": "CN",
        "status": 1,
        "name": "Nyingchi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "29.309076",
        "size": "medium"
    },
    {
        "iata": "TCZ",
        "lon": "98.482025",
        "iso": "CN",
        "status": 1,
        "name": "Tengchong Tuofeng Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "24.939379",
        "size": "medium"
    },
    {
        "iata": "CTU",
        "lon": "103.9568",
        "iso": "CN",
        "status": 1,
        "name": "Chengdu Shuangliu International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.581135",
        "size": "large"
    },
    {
        "iata": "WXN",
        "lon": "108.42695",
        "iso": "CN",
        "status": 1,
        "name": "Wanxian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "30.799679",
        "size": "small"
    },
    {
        "iata": "XIC",
        "lon": "102.187645",
        "iso": "CN",
        "status": 1,
        "name": "Xichang Qingshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "27.981922",
        "size": "medium"
    },
    {
        "iata": "YBP",
        "lon": "104.54395",
        "iso": "CN",
        "status": 1,
        "name": "Yibin Caiba Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "28.797659",
        "size": "small"
    },
    {
        "iata": "ACX",
        "lon": "104.95944",
        "iso": "CN",
        "status": 1,
        "name": "Xingyi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "25.085556",
        "size": "medium"
    },
    {
        "iata": "AKU",
        "lon": "80.29262",
        "iso": "CN",
        "status": 1,
        "name": "Aksu Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.259983",
        "size": "medium"
    },
    {
        "iata": "BPL",
        "lon": "82.024185",
        "iso": "CN",
        "status": 1,
        "name": "Alashankou Bole (Bortala) airport",
        "continent": "AS",
        "type": "airport",
        "lat": "44.904232",
        "size": "medium"
    },
    {
        "iata": "IQM",
        "lon": "85.53333",
        "iso": "CN",
        "status": 1,
        "name": "Qiemo Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.13333",
        "size": "medium"
    },
    {
        "iata": "HMI",
        "lon": "93.416664",
        "iso": "CN",
        "status": 1,
        "name": "Hami Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.916668",
        "size": "medium"
    },
    {
        "iata": "KCA",
        "lon": "83.7",
        "iso": "CN",
        "status": 1,
        "name": "Kuqa Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.166668",
        "size": "small"
    },
    {
        "iata": "KRL",
        "lon": "86.12434",
        "iso": "CN",
        "status": 1,
        "name": "Korla Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.689896",
        "size": "medium"
    },
    {
        "iata": "KRY",
        "lon": "84.88333",
        "iso": "CN",
        "status": 1,
        "name": "Karamay Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.61667",
        "size": "small"
    },
    {
        "iata": "KHG",
        "lon": "76.01107",
        "iso": "CN",
        "status": 1,
        "name": "Kashgar Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "39.534973",
        "size": "medium"
    },
    {
        "iata": "SXJ",
        "lon": "90.23333",
        "iso": "CN",
        "status": 1,
        "name": "Shanshan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.816666",
        "size": "medium"
    },
    {
        "iata": "TCG",
        "lon": "83.333336",
        "iso": "CN",
        "status": 1,
        "name": "Tacheng Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.666668",
        "size": "small"
    },
    {
        "iata": "HTN",
        "lon": "79.87245",
        "iso": "CN",
        "status": 1,
        "name": "Hotan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "37.040436",
        "size": "medium"
    },
    {
        "iata": "URC",
        "lon": "87.47515",
        "iso": "CN",
        "status": 1,
        "name": null,
        "continent": "AS",
        "type": "airport",
        "lat": "43.90126",
        "size": "large"
    },
    {
        "iata": "YIN",
        "lon": "81.33144",
        "iso": "CN",
        "status": 1,
        "name": "Yining Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.952",
        "size": "small"
    },
    {
        "iata": "AOG",
        "lon": "123.857834",
        "iso": "CN",
        "status": 1,
        "name": "Anshan Air Base",
        "continent": "AS",
        "type": "airport",
        "lat": "41.103523",
        "size": "medium"
    },
    {
        "iata": "CGQ",
        "lon": "125.217545",
        "iso": "CN",
        "status": 1,
        "name": "Longjia Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.902836",
        "size": "medium"
    },
    {
        "iata": "CNI",
        "lon": "121.433334",
        "iso": "CN",
        "status": 1,
        "name": "Changhai Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "31.25",
        "size": "medium"
    },
    {
        "iata": "CHG",
        "lon": "120.4372",
        "iso": "CN",
        "status": 1,
        "name": "Chaoyang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.545082",
        "size": "medium"
    },
    {
        "iata": "HRB",
        "lon": "126.23644",
        "iso": "CN",
        "status": 1,
        "name": "Taiping Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "45.620853",
        "size": "large"
    },
    {
        "iata": "HEK",
        "lon": "127.433334",
        "iso": "CN",
        "status": 1,
        "name": "Heihe Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "50.216667",
        "size": "medium"
    },
    {
        "iata": "JIL",
        "lon": "126.65",
        "iso": "CN",
        "status": 1,
        "name": "Jilin Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "43.86667",
        "size": "small"
    },
    {
        "iata": "JMU",
        "lon": "130.45541",
        "iso": "CN",
        "status": 1,
        "name": "Jiamusi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "46.845394",
        "size": "medium"
    },
    {
        "iata": "JNZ",
        "lon": "121.01667",
        "iso": "CN",
        "status": 1,
        "name": "Jinzhou Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.11667",
        "size": "medium"
    },
    {
        "iata": "YUS",
        "lon": "97.03748",
        "iso": "CN",
        "status": 1,
        "name": "Yushu Batang Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "32.837975",
        "size": "medium"
    },
    {
        "iata": "MDG",
        "lon": "129.58385",
        "iso": "CN",
        "status": 1,
        "name": "Mudanjiang Hailang International Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "44.534943",
        "size": "medium"
    },
    {
        "iata": "OHE",
        "lon": "122.42059",
        "iso": "CN",
        "status": 1,
        "name": "Gu-Lian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "52.92113",
        "size": "medium"
    },
    {
        "iata": "NDG",
        "lon": "123.916664",
        "iso": "CN",
        "status": 1,
        "name": "Qiqihar Sanjiazi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "47.316666",
        "size": "medium"
    },
    {
        "iata": "DLC",
        "lon": "121.53999",
        "iso": "CN",
        "status": 1,
        "name": "Zhoushuizi Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "38.96102",
        "size": "large"
    },
    {
        "iata": "SHE",
        "lon": "123.426926",
        "iso": "CN",
        "status": 1,
        "name": "Taoxian Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "41.861084",
        "size": "large"
    },
    {
        "iata": "YNJ",
        "lon": "129.43936",
        "iso": "CN",
        "status": 1,
        "name": "Yanji Chaoyangchuan Airport",
        "continent": "AS",
        "type": "airport",
        "lat": "42.885662",
        "size": "medium"
    }
];

export default data;