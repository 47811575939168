import { Box, Button, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { useRef, useState } from "react";
import api from "../../lib/api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const options = [
//     {
//         id:"13123",
//         name:"forwarder1"
//     },
//     {
//         id:"13123",
//         name:"forwarder2"
//     }
// ]

export function AddFowarderModal({isActive, forwardersThatAvailable,handleClose,userId,userForwardersList,setUserForwardersList}){
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const [forwarderId, setForwarderId] = useState("");

    const handleForwarderSelect = (event) => {
        setForwarderId(event.target.value)
    }

    const handleClick = async () =>{
        await api.addForwarder(userId,forwarderId,nameRef.current.value,emailRef.current.value)
        api.getUserForwarders(userId).then((res)=> {
            setUserForwardersList(res.data.forwarders);
            handleClose()
          })
    }

    return <>
        <Modal
            open={isActive}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box sx={style}>
                <h2 id="parent-modal-title">Add forwarder</h2>
                <div style={{display:"flex",flexDirection:"column",gap:"1rem", paddingBottom:"2rem"}}>
                    <TextField id="standard-basic" inputRef={nameRef} label="Name" variant="standard" />
                    <TextField id="standard-basic" inputRef={emailRef} label="Email" variant="standard" />
                    <Select
                        style={{paddingTop:"1rem"}}
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Forwarder"
                        value={forwarderId}
                        onChange={handleForwarderSelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem disabled value="">
                            <em>Select Company</em>
                        </MenuItem>
                        {forwardersThatAvailable.filter(i => !userForwardersList.some(x=> x.orgName === i.name)).map(option=> 
                                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)
                        }
                    </Select>
                </div>
                <Button variant="contained" onClick={handleClick} color="primary">Save</Button>
            </Box>
        </Modal>
    </>
}