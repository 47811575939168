import React from 'react';
import i18n from 'i18next';
import { Media, Card, Button, Container, Row, Col } from 'react-bootstrap';

import api from '../lib/api';
import format from './format';
import action from './action';
import val, { ShipmentValidationSchema } from './validation';

import containerIcon from '../assets/icons/container.svg';
import palletIcon from '../assets/icons/pallet.svg';
import shipmentIcon from '../assets/icons/warehouse.svg';

import { FaExclamationTriangle } from 'react-icons/fa';

import ShipmentForm from './shipment.form';

export const CompactShipmentContent = ({ value }) => {
  const i = value.body;

  const content = [];

  const pallets = i.shipmentContent.filter(i => i.type === 'pallet' && i.physicalProps).map(i => {
    const p = i.physicalProps;
    const cbm = (p.width / 100) * (p.height / 100) * (p.depth / 100);
    
    return { cbm: cbm, weight: p.weight, quantity: i.quantity };
  }).reduce((acc, curr) => {
    acc.cbm += curr.cbm;
    acc.weight += curr.weight;
    acc.quantity += curr.quantity;
  
    return acc;
  }, { cbm: 0, weight: 0, quantity: 0 });

  const containers = i.shipmentContent.filter(i => i.type === 'container');

  const hazMat = i.shipmentContent.filter(i => i.hazardousMaterial).length;

  if (containers.length > 0) {
    const totalContainers = containers.reduce((acc, curr) => acc + curr.quantity, 0);

    content.push(<>
      <img src={containerIcon} className="medium-icon" alt="Container" />
      {' '}
      <format.NumericString value={totalContainers} feedback={true} /> containers
    </>);
  }

  if (pallets.quantity > 0) {
    content.push(<>
      <img src={palletIcon} className="medium-icon" alt="Container" />
      {' '}
      <format.NumericString value={pallets.quantity} feedback={true} /> pallets
      (<format.NumericString value={pallets.cbm.toFixed(2)} feedback={true} /> CBM / <format.NumericString value={pallets.weight} feedback={true} /> Kg)
    </>);
  }

  if (hazMat > 0) {
    content.push(<>
      <FaExclamationTriangle className="medium-icon warning" /> with Hazardous Materials
    </>);
  }

  console.log('*** i ***: ', i);
  return <>
    <format.ShippingTerms value={i.shippingTerms} />,
    {' '}
    <format.Incoterms value={i.incoterms} />,
    {' '}
    <format.TransportType value={i.transportType} explicit={true} />,
    {' '}
    <format.TransportMode value={i.transportMode} readOnly={true} />
    {' '}
    transport of
    {' '}
    {content.map((i, index) => {
      return <React.Fragment key={index}>
        {' '}{i}{' '}
      </React.Fragment>
    })}
  </>;
};

export const CompactShipmentAddresses = ({ value, mode = 'vertical' }) => {
  const From = <>
    <h6>{i18n.t('common:fields.shipment.pickupFrom')}</h6>
    <div>
      <format.Address value={value.body.pickupFrom} showIcon={false} />
    </div>
  </>;

  const To = <>
    <h6>{i18n.t('common:fields.shipment.deliverTo')}</h6>
    <div>
      <format.Address value={value.body.deliverTo} showIcon={false} />
    </div>
  </>;

  if (mode === 'vertical') {
    return <>
      {From}
      <p></p>
      {To}
    </>;
  } else {
    return <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          {From}
        </div>
        <div style={{ width: '50%' }}>
          {To}
        </div>
      </div>
    </>;
  }
};

const Main = ({ value, marketplace = false, mode = 'compact' }) => {
  const i = value.body;

  const state = i.state;

  const showProgress = [ null, 'active' ].includes(state) || true;
  const showQuote = [ null, 'active', 'complete' ].includes(state) || true;
  const showTracking = [ null, 'active', 'complete' ].includes(state) || true;

  const compactMode = mode === 'compact';

  return (
    <val.Container value={value.validation}>
      <Card>
        <Card.Header>
          <h6>{value.body.name}</h6>
          {' '}
          <CompactShipmentContent value={value} />
        </Card.Header>
        <Card.Body>
          { compactMode ? <>
            <Media style={{ display: 'flex', alignItems: 'stretch' }}>
            <div style={{ marginRight: '1em', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div>
                <div className="large-icon-container">
                  <img className="large-icon" src={shipmentIcon} alt="Shipment" />
                </div>
                <div className="text-center block-hsep-50">
                  <div>
                    <div>
                      <format.ShipmentState value={i.state} />
                    </div>
                  </div>
                  { [ 'draft', 'rfp' ].includes(i.state) ? <>
                    <strong>
                      Expires <format.DateDistance value={i.expiresAt} />
                    </strong>
                  </> : <></> }
                </div>
              </div>
            </div>

            <Media.Body>
              <CompactShipmentAddresses value={value} mode="vertical" />
              
              <val.SummaryContainer>
                <h6 className="block-hsep-50">Errors</h6>
                <val.Summary schema={ShipmentValidationSchema} />
              </val.SummaryContainer>
              
              <val.SuccessContainer>
                { i.state === 'rfp' ? <>
                  <h6 className="block-hsep-50">Bids</h6>
                  <div>
                    {i.rfpResponsesCount ? <>
                      {i.rfpResponsesCount} Bids
                    </> : <>
                      This RFP has no bids yet.
                    </>}
                  </div>
                    </> : <></> }
              </val.SuccessContainer>
            </Media.Body>
          </Media>
          </> : <>
            <ShipmentForm value={value} readOnly={true} />
          </> }
        </Card.Body>
        <Card.Footer>
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col size="auto" className="px-0">
                <action.DeleteShipmentDraft value={value} className="block-rsep-50" />
                <val.SuccessContainer>
                  { marketplace ? <></> : <>
                    <action.UpdateShipmentState value={value} className="block-rsep-50" state="cancelled" />
                  </> }
                </val.SuccessContainer>
              </Col>
              <Col sm={true} className="pr-0 nowrap text-right">
                { marketplace ? <></> : <action.CreateShipmentCopy value={value} className="block-vsep-50" /> }
                { compactMode ? <action.ShipmentDetails value={value} className="block-vsep-50" /> : <></> }
                <val.SuccessContainer>
                  { marketplace ? <></> : <action.UpdateShipmentState value={value} className="block-vsep-50" state="rfp" /> }
                </val.SuccessContainer>
                { marketplace ? <action.CreateShipmentBid shipment={value} className="block-vsep-50" /> : <></>}
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
      <p></p>
      {/*
      <div className="primary-list-item">
        { showQuote ? (<>
          <td>Quote</td>
          <td>Forwarder</td>
        </>) : <></>}
        { showTracking ? <td><format.ShipmentTrackingCode value={i.shipmentTrackingCode} /></td> : <></> }
        { showProgress ? (<>
          <td><format.ShipmentProgress value={i.shipmentProgress} /></td>
        </>) : <></> }
        <td></td>
      </div>*/}
    </val.Container>
  );
};

export default Main;
