import React from 'react';
import ReactDOM from 'react-dom';
import Lottie from 'react-lottie';
import i18n from 'i18next';
import useSound from 'use-sound';

import lottie_unicorn1 from '../assets/lottie/unicorn1.json';
import lottie_ghost1 from '../assets/lottie/ghost1.json';
import lottie_green1 from '../assets/lottie/green1.json';
import lottie_green2 from '../assets/lottie/green2.json';
import lottie_green3 from '../assets/lottie/green3.json';
import lottie_llama1 from '../assets/lottie/llama1.json';
import lottie_mouse1 from '../assets/lottie/mouse1.json';
import lottie_shark1 from '../assets/lottie/shark1.json';
import lottie_star1 from '../assets/lottie/star1.json';
import lottie_star2 from '../assets/lottie/star2.json';

import sound_success_bell from '../assets/sound/success-bell.mp3';
import sound_success2 from '../assets/sound/success2.mp3';
import sound_success3 from '../assets/sound/success3.mp3';
import sound_success4 from '../assets/sound/success4.mp3';

const animations = [
  { data: lottie_unicorn1, style: { right: '5em', bottom: 0 }, maxLoops: 3 },
  { data: lottie_ghost1, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_green1, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_llama1, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_mouse1, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_green2, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_shark1, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_star1, style: { right: '5em', bottom: '5em' }, maxLoops: 1 },
  { data: lottie_green3, style: { right: '5em', bottom: '5em' }, maxLoops: 3 },
  { data: lottie_star2, style: { right: '5em', bottom: '5em' }, maxLoops: 1 },
];

const sounds = [
  { data: sound_success_bell, volume: 1 },
  /*{ data: sound_success2, volume: 0.1 },
  { data: sound_success3, volume: 0.1 },
  { data: sound_success4, volume: 0.1 },*/
];

const getNextAnimationIndex = () => {
  const prevIndex = JSON.parse(window.localStorage.getItem('delight.lastAnimationIndex') || '-1');
  const newIndex = (prevIndex + 1) % animations.length;

  window.localStorage.setItem('delight.lastAnimationIndex', newIndex);

  return newIndex;
};

const getNextSoundIndex = () => {
  const prevIndex = JSON.parse(window.localStorage.getItem('delight.lastSoundIndex') || '-1');
  const newIndex = (prevIndex + 1) % sounds.length;

  window.localStorage.setItem('delight.lastSoundIndex', newIndex);

  return newIndex;
};

export const DelightCanvas = ({ action }) => {
  const ref = React.useRef();
  const [ state, setState ] = React.useState(null);

  React.useEffect(() => {
    if (state) return;

    setState({
      animation: animations[getNextAnimationIndex()],
      sound: sounds[getNextSoundIndex()],
    })
  });

  const [ isStopped, setIsStopped ] = React.useState(false);

  const onDOMLoaded = React.useCallback(() => {
    ref.current.style.opacity = 1;
    ref.current.style.visibility = 'visible';
  }, [ ref ]);

  const onLoopComplete = React.useCallback(({ currentLoop }) => {
    if (currentLoop >= state.animation.maxLoops) {
      ref.current.style.opacity = 0;
      ref.current.style.visibility = 'hidden';
    }
  }, [ ref, state ]);

  if (!state) return <></>;

  const { animation, sound } = state;
  const { data, maxLoops = 3, style = { right: 0, bottom: 0 } } = animation;

  return <div style={{
    position: 'fixed',
    zIndex: 99999999,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out, visibility 0.5s',
    pointerEvents: 'none',
    background: 'none !important',
  }} ref={ref}>
    <Lottie
      width="25%"
      height="25%"
      options={{
        loop: maxLoops + 1,
        autoplay: true,
        animationData: data,
        isClickToPauseDisabled: true,
        rendererSettings: {
          preserveAspectRatio: 'xMaxYMax meet'
        },
      }}
      eventListeners={[
        { eventName: 'DOMLoaded', callback: onDOMLoaded },
        { eventName: 'loopComplete', callback: onLoopComplete },
      ]}
      style={{
        position: 'fixed',
        ...style,
      }}
      isStopped={isStopped}
    />
    <audio src={sound.data} volume={sound.volume} autoPlay={true} />
  </div>
};