import React, { useEffect } from 'react';
import i18n from 'i18next';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import logo from './assets/brand.svg';
import 'fontsource-roboto';
import { useSnackbar, withSnackbar } from 'notistack';

import {
  Button, IconButton, Paper, Typography, Toolbar, Avatar, Container, Grid,
  AppBar, Link, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Menu, MenuItem, CircularProgress, Box, Snackbar, Backdrop,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  // BrowserRouter as Router,
  Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import history from './lib/history';
import api from './lib/api';
import global, { ModalCleanupContext, GlobalPortalControl } from './lib/global';
import action from './components/action';
import input from './components/input';
import format from './components/format';
import { globalPushPremiumFeature } from './components/premium';
import * as ui from './components/ui';
import * as upload from './components/upload';

import { globalPushModal, globalPopModal } from './lib/global';

import Dashboard from './components/dashboard';
import ShipmentsList from './components/shipments.list';
import ShipmentContractsList from './components/shipmentContracts.list';
import UsersList from './components/users.list';
import Report from './components/report';
import ShipmentsSummary from './components/shipments.summary';

import { HazardousMaterialSelect, HazardousMaterialIcon, PackageControl, PackageCompactView, ShipmentContentControl } from './components/input/goods';
import FwdForm from './components/forwarders.form';
import { UserForm } from './components/user.form';
import { OrgUnitsList } from './components/orgUnits.list';
import { Logs } from './components/logs';
import { AttachmentsList } from './components/attachments.list';
import { ChatSession } from './components/chat';
import ShipmentOpCenter from './components/shipmentOpCenter';
import { CompleteShipmentContractForm } from './components/completeShipmentContract.form';
import { ForwarderFeedbackForm, OrgUnitFeedbackList } from './components/feedback';
import { ShipmentInvoiceValidationForm } from './components/invoice.form';
import { ProposeForwarderForm } from './components/forwarders.form';
import { globalPushApiKeysListModal, ApiKeysList } from './components/apiKeys';
import { globalPushPublicForwarderProfileModal, PublicForwarderProfileForm } from './components/publicForwarderProfile.form';
import { DelightCanvas } from './components/delight';
import { Statistics } from './components/statistics';
import { width } from '@material-ui/system';
import config from './lib/config';

const withBreadcrumbs = (parent, location, children) => {
  return <>
    <GlobalPortalControl anchor="breadcrumbs">
      <ui.Breadcrumbs parent={parent} location={location} />
    </GlobalPortalControl>
    {children}
  </>;
};

const withFormattedBreadcrumbs = (parent, location, children) => {
  return <>
    <GlobalPortalControl anchor="breadcrumbs">
      <ui.FormattedBreadcrumbs parent={parent} location={location} />
    </GlobalPortalControl>
    {children}
  </>;
};

const withShipmentBreadcrumbs = (routeMatch, children) => {
  const { state } = routeMatch.params;

  let key = null;

  if (!state) {
    key = 'allShipments';
  } else if (state.indexOf('rfp') >= 0) {
    key = 'shipmentRFPs';
  } else if (state.indexOf('active') >= 0) {
    key = 'activeShipments';
  }

  return withBreadcrumbs('orders', key, children);
};

const withShipmentContractBreadcrumbs = (routeMatch, children) => {
  const { state } = routeMatch.params;

  let key = null;

  if (!state) {
    key = 'allShipmentContracts';
  } else if (state.indexOf('contract') >= 0) {
    key = 'activeShipmentContracts';
  } else if (state.indexOf('bid') >= 0) {
    key = 'bidShipmentContracts';
  }

  return withBreadcrumbs('sales', key, children);
};

const Main = ({}) => {
  return <>
    <Observer>{() => (
      <React.Fragment>
        <Busy />
        <Error />
      </React.Fragment>
    )}</Observer>
    <Authenticated />
    <Observer>{() => (
      <React.Fragment>
        <Modals />
      </React.Fragment>
    )}</Observer>
  </>;
}

const Busy = () => {
  const onClick = React.useCallback((e) => {
    e.preventDefault();
  }, []);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 6000000,
      color: theme.palette.secondary.light,
    },
  }));

  const classes = useStyles();

  return (
    <Observer>{() => (
      <Backdrop
        open={api.isBusy}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )}</Observer>
  );
};

const Error = () => {
  const onDismiss = React.useCallback(() => {
    api.clearLastError();
  }, []);

  return (
    <Observer>{() => (
      <Dialog open={api.lastError !== null} onClose={onDismiss}>
        <DialogTitle>
          Oops! Something went wrong...
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {api.lastError ? api.lastError.message : ''}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box p={2}>
            <action.Action action="dismiss" onClick={onDismiss} mode="button">
              {i18n.t('common:actions.dismiss.label')}
            </action.Action>
          </Box>
        </DialogActions>
      </Dialog>
    )}</Observer>
  );
};

const HSpacer = () => {
  return (
    <div style={{ display: 'inline-block', width: 10 }} />
  );
};

const ProfilePicture = () => {
  return (<Observer>{() => {
    return global.user ? (<>
      <ui.NavMenu
        label={
          <>
            <Avatar src={global.user.user.photo} variant="circle" referrerPolicy="no-referrer" />
            <HSpacer />
            {global.user.user.name}
          </>
        }
        items={[
          { onClick: globalPushApiKeysListModal, text: i18n.t('common:navigation.userApiKeys.text') },
          null,
          { onClick: api.logout, text: i18n.t('common:navigation.userSignOut.text') },
        ]}
      />
    </>) : '';
  }}</Observer>);
};

const Authenticated = () => {
  if (global.user === null) return <></>;

  const shipments = [
    { onClick: () => api.navigateShipmentState('rfp'), text: i18n.t('common:navigation.shipmentRFPs.text') },
    { onClick: () => api.navigateShipmentState('active'), text: i18n.t('common:navigation.activeShipments.text') },
    null,
    { onClick: () => api.navigateShipmentState(null), text: i18n.t('common:navigation.allShipments.text') },
    null,
    { to: '/shipmentsSummary', text: i18n.t('common:navigation.shipmentsSummary.text') },
  ];

  const forwarder = [
    { onClick: () => api.navigateShipmentState('marketplace'), text: i18n.t('common:navigation.marketplace.text') },
    null,
    { onClick: () => api.navigateShipmentContractState('bid'), text: i18n.t('common:navigation.bidShipmentContracts.text') },
    { onClick: () => api.navigateShipmentContractState('contract'), text: i18n.t('common:navigation.activeShipmentContracts.text') },
    null,
    { onClick: () => api.navigateShipmentContractState(null), text: i18n.t('common:navigation.allShipmentContracts.text') },
  ];

  const admin = (() => {
    const result = [];

    if (api.isOrgAdmin() || api.isPlatformAdmin()) {
      if (api.hasModule('forwarder')) {
        const forwarderPublicProfileEnabled = !!((global.user.orgUnit.options || {}).forwarder || {}).publicProfileEnabled;

        if (forwarderPublicProfileEnabled) {
          result.push(
            { name: i18n.t('common:navigation.adminPublicForwarderProfile.text'), onClick: globalPushPublicForwarderProfileModal },
            null,
          );
        } else {
          result.push(
            {
              name: i18n.t('common:navigation.adminPublicForwarderProfile.text'),
              icon: <format.PremiumFeatureIcon />,
              onClick: () => {
                globalPushPremiumFeature('publicForwarderProfile');
              },
            },
            null,
          );
        }
      }

      result.push(
        { to: '/users', name: i18n.t('common:navigation.adminUsers.text') }
      );
    }

    if (api.isPlatformAdmin()) {
      result.push(
        { to: '/orgUnits', name: i18n.t('common:navigation.adminOrgUnits.text') },
        { to: '/logs', name: i18n.t('common:navigation.adminLogs.text') },
        { to: '/statistics', name: i18n.t('common:navigation.adminStatistics.text') },
        null,
        { to: '/invoiceValidation', name: i18n.t('common:navigation.adminInvoiceValidation.text') },
      );
    }

    return result;
  })();

  const reports = (() => {
    const result = [];

    console.debug('global.reports: ', global.reports);
    const source = global.reports.body;

    const system = source.filter(i => i.scope === 'system');
    const initiator = source.filter(i => i.scope === 'initiator');
    const forwarder = source.filter(i => i.scope === 'forwarder');
    const custom = source.filter(i => i.scope === 'custom');

    const list = [];

    [ 'system', 'initiator', 'forwarder', 'custom' ].forEach(scope => {
      list.push(source.filter(i => i.scope === scope).map(i => ({ to: `/reports/${i.scope}/${i.id}`, name: i18n.t(`reports:${i.scope}.reports.${i.id}`) })));
    });

    list.filter(i => i.length > 0).forEach((i, index) => {
      if (index > 0) {
        result.push(null);
      }

      i.forEach(item => {
        result.push(item);
      });
    });
    
    
    
    return result;
  })();
  
  const [isShowAds, setIsShowAds] = React.useState(false);
  const loadAds = () => {
    const frame = document.getElementById('ads-frame').contentWindow.document.body.innerHTML
    console.log(frame);
    setIsShowAds(!!frame.trim())
  }


  useEffect(() => {
    
    const iframe = document.getElementById('ads-frame')
    iframe.src = `${process.env.REACT_APP_URL}/Ads.html`
    iframe.contentWindow.location.reload()


    setTimeout(() => {
      loadAds()
    }, 1000);
  }, [history]);

  if (global.user) {
    return (<>
      <Router history={history}>
        <div className="root-grid-container">
          <div>
            <img src={logo} alt="" style={{ margin: '1em' }} />
            <AppBar position="static">
              <Toolbar>
                <Button id="dashboard" variant="text" color="inherit" onClick={() => api.navigateUrl('/')}>{i18n.t('common:navigation.dashboard.text')}</Button>
                { api.hasModule('initiator') ? <>
                  <ui.NavMenu id="nav-menu-orders" label={i18n.t('common:navigation.orders.text')} items={shipments} />
                </> : <></> }
                { api.hasModule('forwarder') ? <>
                  <ui.NavMenu id="nav-menu-sales" label={i18n.t('common:navigation.sales.text')} items={forwarder} />
                </> : <></> }
                { reports.length > 0 ? <>
                  <ui.NavMenu id="nav-menu-reports" label={i18n.t('common:navigation.reports.text')} items={reports} />
                </> : <></> }
                { admin.length > 0 ? <>
                  <ui.NavMenu id="nav-menu-admin" label={i18n.t('common:navigation.admin.text')} items={admin} />
                </> : <></> }
                <Typography style={{ flexGrow: 1 }}></Typography>
                <Observer>{() => 
                  Object.keys(global.navControls).map(key => {
                    const control = global.navControls[key];

                    return <React.Fragment key={key}>
                      {control}
                    </React.Fragment>;
                  })
                }</Observer>
                {api.isPlatformAdmin() && <action.GlobalUserOrganization className="block-vsep-50" /> }
                <ProfilePicture />
              </Toolbar>
            </AppBar>
          </div>
          <div className="App-background">
            <Paper className="App-content" elevation={8}>
              <div
              style={{
                // position: 'relative',
              }}
              className="App-content-container">
                <div 
                style={isShowAds?{
                  paddingBottom: "200px"
                }:{}}
                className="App-content-breadcrumbs">
                  <Observer>{() => global.portalControls.breadcrumbs ? <Box p={1} style={{ textTransform: 'uppercase' }}>{global.portalControls.breadcrumbs}</Box> : <></>}</Observer>
                </div>
               
                <iframe 
                src={`${process.env.REACT_APP_URL}/Ads.html`}
                id="ads-frame" style={{
                  position: 'absolute',
                  top: "30px",
                  width: "100%",
                  height: "200px",
                  opacity: isShowAds?1:0,
                  pointerEvents: isShowAds?'auto':'none',
                }}  />
                <div className="App-content-header">
                  <Observer>{() => global.portalControls.header ? <Box p={1}>{global.portalControls.header}</Box> : <></>}</Observer>
                </div>
                <div className="App-content-inner-container">
                  <Switch>
                    <Route path="/shipments/marketplace" component={({match}) => withBreadcrumbs('sales', 'marketplace', ShipmentsList({ ...match.params, marketplace: true, mode: 'form' }))}>
                    </Route>
                    <Route path="/shipments/:state" component={({match}) => withShipmentBreadcrumbs(match, ShipmentsList(match.params))}>
                    </Route>
                    <Route path="/shipments" component={({match}) => withShipmentBreadcrumbs(match, ShipmentsList(match.params))}>
                    </Route>
                    <Route path="/shipmentContracts/:state" component={({match}) => withShipmentContractBreadcrumbs(match, ShipmentContractsList(match.params))}>
                    </Route>
                    <Route path="/shipmentContracts" component={({match}) => withShipmentContractBreadcrumbs(match, ShipmentContractsList(match.params))}>
                    </Route>
                    <Route path="/users" component={({match}) => withBreadcrumbs('admin', 'adminUsers', UsersList(match.params))}>
                    </Route>
                    <Route path="/orgUnits" component={({match}) => withBreadcrumbs('admin', 'adminOrgUnits', OrgUnitsList(match.params))}>
                    </Route>
                    <Route path="/logs" component={({match}) => withBreadcrumbs('admin', 'adminLogs', Logs(match.params))}>
                    </Route>
                    <Route path="/statistics" component={({match}) => withBreadcrumbs('admin', 'adminStatistics', Statistics(match.params))}>
                    </Route>
                    <Route path="/shipmentsSummary" component={({match}) => ShipmentsSummary(match.params)}>
                    </Route>
                    <Route path="/invoiceValidation" component={({match}) => withBreadcrumbs('admin', 'invoiceValidation', ShipmentInvoiceValidationForm(match.params))}>
                    </Route>
                    <Route
                      path="/reports/:scope/:report"
                      component={({match}) => withFormattedBreadcrumbs(
                        i18n.t('common:navigation.reports.text'),
                        i18n.t(`reports:${match.params.scope}.reports.${match.params.report}`),
                        Report(match.params)
                      )}
                    >
                    </Route>
                    <Route path="/test" component={({match}) => TestPage(match.params)}>
                    </Route>
                    <Route path="/">
                      <Dashboard />
                    </Route>
                  </Switch>
                </div>
                <div className="App-content-footer">
                  <Observer>{() => global.portalControls.footer ? <Box p={1}>{global.portalControls.footer}</Box> : <></>}</Observer>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </Router>
    </>);
  } else {
    return <></>;
  }
};

const Modals = () => {
  return <Observer>{() => {
    return <>{ global.modalsMeta.changeSequence !== null && global.modals.map((modal, index) => {
      const style = {
        content: {},
        disalog: {},
      };

      if (modal.options.fixedHeight) {
        style.dialog = {
          height: '90vh',
          width: '100%',
        };

        style.content = {
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'stretch',
        };
      }

      if (modal.options.smallWidth) {
        style.dialog = {
          maxWidth: '500px',
        };
      }

      return <React.Fragment key={index}>
        <Dialog
          open={true}
          fullWidth={modal.options.fullWidth}
          maxWidth={modal.options.maxWidth || 'lg'}
          PaperProps={{
            style: style.dialog,
          }}
        >
          <ModalCleanupContext.Provider value={modal}>
            { modal.title && <DialogTitle>
              <Toolbar disableGutters variant="dense">
                <Typography variant="h6" edge="start" style={{ flexGrow: 1 }}>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: modal.titleActions ? '1fr 1fr' : '1fr',
                  }}>
                    <div>{modal.title}</div>
                    { modal.titleActions && <Box ml={2} mr={3}>
                      {modal.titleActions}
                    </Box> }
                  </div>
                </Typography>
                <IconButton aria-label="close" size="small" edge="end" onClick={globalPopModal}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </DialogTitle> }
            { modal.toolbar && <DialogTitle>
              <Toolbar disableGutters variant="dense">
                {modal.toolbar}
              </Toolbar>
            </DialogTitle>}
            <DialogContent dividers style={style.content}>
              {modal.body}
            </DialogContent>
            { modal.footer && <DialogActions>
              {modal.footer}
            </DialogActions> }
          </ModalCleanupContext.Provider>
        </Dialog>
      </React.Fragment>;
    }) }</>;
  }}</Observer>;
};

const TestPage = ({}) => {
  /*
  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbar('Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1 Test 1');
  enqueueSnackbar('Test 2', { variant: 'success' });
  enqueueSnackbar('Test 3');
  */

  let lastTick = new Date();
  const tick = () => {
    lastTick.setDate(lastTick.getDate() + 7);

    return lastTick.valueOf().toString();
  };

  return <>
    <GlobalPortalControl anchor="breadcrumbs">
      Breadcrumbs
    </GlobalPortalControl>
    {/*
    <ShipmentInvoiceValidationForm />
    <ApiKeysList />
    */}
    <input.TrackingCode value="123" transportMode="air" onChange={val => console.log(val)} />
    <input.TrackingCode value="ABC" transportMode="sea" onChange={val => console.log(val)} />

    <DelightCanvas action="acceptBid" />
  </>;
};

const App = ({}) => {
  return <>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Main />
    </MuiPickersUtilsProvider>
  </>;  
};

export default App;
