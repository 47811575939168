import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import api from '../lib/api';
import global from '../lib/global';
import format from './format';
import action from './action';
import uiControls from './ui';
import data from './data';

import { GlobalNavControl } from '../lib/global';
import { Button } from '@material-ui/core';
import { globalPushUserFormModal, globalPushAddUserFormModal } from './user.form';

import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper } from '@material-ui/core';

const Child = ({ autoSize = true, scope, report }) => {
  const { t } = useTranslation();

  const uiState = observable({
    data: null,
  });

  const setData = (data = []) => uiState.data = data;

  const onFetchDataAsyncHandler = async (query = {}) => {
    const result = await api.queryReport({
      scope: scope,
      id: report,
    }, query);

    return result;
  };

  const onDataHandler = (data) => {
    setData(data.data);
  };

  return <uiControls.MainContentContainer autoSize={autoSize}>
    <uiControls.TableContainer
      autoSize={autoSize}
      header={<>
        <data.SimpleDataToolbar
          onFetchDataAsync={onFetchDataAsyncHandler}
          onData={onDataHandler}
        />
      </>}
    >
      <Observer>{() => {
        if (uiState.data === null) return <uiControls.TableSkeleton />;
        if (uiState.data.body.length === 0) return <uiControls.EmptyTable />;

        return (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                { uiState.data.columns.map((i, index) => {
                  return <TableCell key={index} align="center">{i18n.t(`common:fields.${i.id}`)}</TableCell>;
                }) }
              </TableRow>
            </TableHead>
            <TableBody>
              {(uiState.data.body || []).map((dataItem, index) => <TableRow  key={index}>
                { uiState.data.columns.map((col, colIndex) => {
                  return <TableCell key={colIndex} align="center">
                    {
                      (() => {
                        const val = dataItem[col.id];

                        if (col.type === 'date') {
                          return <format.DateText value={val} />;
                        } else if (col.i18n) {
                          return i18n.t(`${col.i18n}.${val}`);
                        } else {
                          return val;
                        }
                      })()
                    }
                  </TableCell>;
                }) }
              </TableRow >)}
            </TableBody>
          </Table>
        );
      }}</Observer>
    </uiControls.TableContainer>
  </uiControls.MainContentContainer>;
};

const Main = ({ autoSize = true, scope, report }) => {
  return <Child autoSize={autoSize} scope={scope} report={report} />;
};

export default Main;
