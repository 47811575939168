import React from 'react';
import i18n from 'i18next';

import api from '../lib/api';
import ui from './ui';
import chart from './chart';
import { Box } from '@material-ui/core';
import { css } from 'emotion';

const ChartContainer = ({ children }) => {
  return <Box>
    <ui.Container variant='outlined'>
      <Box width='100%' className={css({
        '@media screen and (max-height: 900px)': {
          zoom: 0.8,
        },
        '@media screen and (max-width: 1800px)': {
          zoom: 0.8,
        }
      })}>
        {children}
      </Box>
    </ui.Container>
  </Box>;
};

const DataWrapper = ({ data, children }) => {
  return <><Box width='100%'>{children}</Box></>;
};


const users_icon = () => {
  return <svg width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'/><circle cx='12' cy='7' r='4'/></svg>
}

const forwarders_icon = () => {
  return <svg width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><line x1='22' y1='2' x2='11' y2='13'/><polygon points='22 2 15 22 11 13 2 9 22 2'/></svg>
}

const drafts_icon = () => {
  return <svg width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><path d='M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48'/></svg>
}

const shipments_icon = () => {
  return <svg width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><rect x='1' y='3' width='15' height='13'/><polygon points='16 8 20 8 23 11 23 16 16 16 16 8'/><circle cx='5.5' cy='18.5' r='2.5'/><circle cx='18.5' cy='18.5' r='2.5'/></svg>
}

const active_shipments_icon = () => {
  return <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"/></svg>
}


export const Statistics = ({ autoSize = true }) => {
  const SHIPMENTS_LIMIT = 30;

  const [uiState, setUIState] = React.useState({ statistics: null });

  React.useEffect(() => {
    const fetch = async () => {
      if (onFetchDataAsyncHandler) {
        const args = { limit: SHIPMENTS_LIMIT };
        const response = await onFetchDataAsyncHandler(args);

        if (response.success) {
          if (onDataHandler)
            onDataHandler(response);
        }
      }
    };

    fetch();
  }, []);

  const setStatistics = (data) => {
    const sanitize = (data) => {
      const final = {};

      final.users = [
        {
          type: i18n.t('common:statistics.users.enabled'),
          value: data.users.enabled,
        },
        {
          type: i18n.t('common:statistics.users.disabled'),
          value: (data.users.total - data.users.enabled),
        }
      ];

      final.forwarders = [
        {
          type: i18n.t('common:statistics.forwarders.enabled'),
          value: data.orgUnits.enabled,
        },
        {
          type: i18n.t('common:statistics.forwarders.disabled'),
          value: (data.orgUnits.total - data.orgUnits.enabled),
        }
      ];

      final.shipments = {};
      final.shipments.draft = [
        {
          type: i18n.t('common:statistics.shipments.drafts.enabled'),
          value: data.shipments.draft.enabled,
        },
        {
          type: i18n.t('common:statistics.shipments.drafts.disabled'),
          value: (data.shipments.draft.total - data.shipments.draft.enabled),
        }
      ];

      final.shipments.rfp = [
        {
          type: i18n.t('common:statistics.shipments.rfps.disabled'),
          value: data.shipments.rfp.enabled,
        },
        {
          type: i18n.t('common:statistics.shipments.rfps.disabled'),
          value: (data.shipments.rfp.total - data.shipments.rfp.enabled),
        }
      ];

      final.shipments.active = [
        {
          type: i18n.t('common:statistics.shipments.active.disabled'),
          value: data.shipments.active.enabled,
        },
        {
          type: i18n.t('common:statistics.shipments.active.disabled'),
          value: (data.shipments.rfp.total - data.shipments.active.enabled),
        }
      ];

      final.usersVsForwarders = [
        {
          type: i18n.t('common:statistics.users.name'),
          value: data.users.total,
        },
        {
          type: i18n.t('common:statistics.forwarders.name'),
          value: data.orgUnits.total,
        }
      ];

      final.draftsVsRFPsVsActive = [
        {
          type: i18n.t('common:statistics.shipments.drafts.name'),
          value: data.shipments.draft.total,
        },
        {
          type: i18n.t('common:statistics.shipments.rfps.name'),
          value: data.shipments.rfp.total,
        },
        {
          type: i18n.t('common:statistics.shipments.active.name'),
          value: data.shipments.active.total,
        }
      ];

      final.shipmentsPerDay = [];
      
      const length = Object.keys(data.days_data).length;

      Object.keys(data.days_data).forEach(key => {
        final.shipmentsPerDay.push({
          day: (length - key),
          shipmentsTotal: data.days_data[key].shipments.total,
          shipmentsEnabled: data.days_data[key].shipments.enabled,
        });
      });

      return final;
    }

    setUIState({ statistics: sanitize(data) });
  }

  const onFetchDataAsyncHandler = async ({ limit }) => {
    const result = await api.queryStatistics({ limit: limit });
    return result;
  };

  const onDataHandler = (data) => {
    setStatistics({ ...data.data });
  };

  const prettify = (data) => {
    const twoDecimals = (number) => {
      number = number * 100;
      number = Math.round(number);
      return number / 100;
    }

    const number = parseInt(data);

    if(number < 1000)
      return number;
    if(number < 1000000)
      return twoDecimals(number / 1000) + 'K';
    if(number < 1000000000)
      return twoDecimals(number / 1000000) + 'M';
    return number;
  }

  console.log(uiState.statistics);

  return <>
    { uiState.statistics && <ui.Container autoSize autoScroll variant='outlined'>
      {/* <ui.MainContentContainer autoSize={autoSize}> */}
        {/* <DataWrapper> */}
          <ui.PrecisedColumnLayout minColumnWidth={250} wantedNumberOfItems={5}>
            <ChartContainer>
              <chart.TextBox
                title={ i18n.t('common:statistics.users.name') }
                height={ 200 }
                text={ prettify(uiState.statistics ? uiState.statistics.users[0].value : 0) }
                textColor={ 'rgb(66, 165, 245)' }
                icon={ users_icon }
              />
            </ChartContainer>

            <ChartContainer>
              <chart.TextBox
                title={ i18n.t('common:statistics.forwarders.name') }
                height={ 200 }
                text={ prettify(uiState.statistics ? uiState.statistics.forwarders[0].value : 0) }
                textColor={ 'rgb(255, 112, 67)' }
                icon={ forwarders_icon }
              />
            </ChartContainer>

            <ChartContainer>
              <chart.TextBox
                title={ i18n.t('common:statistics.shipments.drafts.name') }
                height={ 200 }
                text={ prettify(uiState.statistics ? uiState.statistics.shipments.draft[0].value : 0) }
                textColor={ 'rgb(66, 165, 245)' }
                icon={ drafts_icon }

              />
            </ChartContainer>

            <ChartContainer>
              <chart.TextBox
                title={ i18n.t('common:statistics.shipments.rfps.name') }
                height={ 200 }
                text={ prettify(uiState.statistics ? uiState.statistics.shipments.rfp[0].value : 0) }
                textColor={ 'rgb(255, 112, 67)' }
                icon={ shipments_icon }
              />
            </ChartContainer>

            <ChartContainer>
              <chart.TextBox
                title={ i18n.t('common:statistics.shipments.active.name') }
                height={ 200 }
                text={ prettify(uiState.statistics ? uiState.statistics.shipments.active[0].value : 0) }
                textColor={ 'rgb(66, 165, 245)' }
                icon={ active_shipments_icon }
              />
            </ChartContainer>
          </ui.PrecisedColumnLayout>
          
          <ui.PrecisedColumnLayout minColumnWidth={350} wantedNumberOfItems={2}>
            <ChartContainer>
              <chart.PieChart
                title={ i18n.t('common:statistics.usersVsForwarders') }
                height={ 400 }
                data={uiState.statistics ? uiState.statistics.usersVsForwarders : []}
                series={[
                  { argumentField: 'type', valueField: 'value', name: 'usersvsforwarders' },
                ]}
              />
            </ChartContainer>

            <ChartContainer>
              <chart.PieChart
                title={ i18n.t('common:statistics.draftsVsRFPsVsActive') }
                height={ 400 }
                data={uiState.statistics ? uiState.statistics.draftsVsRFPsVsActive : []}
                series={[
                  { argumentField: 'type', valueField: 'value', name: 'draftsvsrfpsvsactive' },
                ]}
              />
            </ChartContainer>
          </ui.PrecisedColumnLayout>

          <ui.PrecisedColumnLayout minColumnWidth={350} wantedNumberOfItems={1}>
            <ChartContainer>
              <chart.LineChart
                title={ i18n.t('common:statistics.shipmentsPerDay').replace("%i", SHIPMENTS_LIMIT) }
                height={ 500 }
                data={uiState.statistics ? uiState.statistics.shipmentsPerDay : []}
                series={[
                  { argumentField: 'day', valueField: 'shipmentsTotal', name: 'Total Shipments' },
                  { argumentField: 'day', valueField: 'shipmentsEnabled', name: 'Enabled Shipments' },
                ]}
              />
            </ChartContainer>
          </ui.PrecisedColumnLayout>
        {/* </DataWrapper> */}
      {/* </ui.MainContentContainer> */}
    </ui.Container> }
  </>
};
