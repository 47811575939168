import { Box, Button, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../lib/api";
import { getShipmentRequiredPriceFields } from "../shipmentContract.view";
import { PdfViewer } from "./PdfViewer";
import format from '../format';
import { ShowFilesContract } from "../ShowFilesContract";

export function InvoiceCompare({shipmentContractId,shipmentId}){
    const { t, i18n } = useTranslation();
    const [fileName,setFileName] = useState("");
    const [shipmentRequiredPriceFields,setShipmentRequiredPriceFields] = useState(null);
    const [shipmentContract,setShipmentContract] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
        api.queryShipmentById(shipmentId).then(response => {
            if (!response.success) return;
            const fields = getShipmentRequiredPriceFields(response.data.body);
            setShipmentRequiredPriceFields(fields)
        })
        api.queryShipmentContractById(shipmentContractId).then(response => {
          if (!response.success) return;
          setShipmentContract(response.data.body)
        })
        api.queryAttachments({
            filter: {
              shipmentContractId: api.ObjectId(shipmentContractId),
            }
          }).then(response => {
            if (!response.success) return;
            const found = response.data.find(file => file.body.disposition == "invoice")
            if(found){
                console.log(found);
                setFileName(found.body.content.fileId)
            }
          });
    },[])

    return <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",width:"100%"}}>
      {fileName&&<PdfViewer fileName={fileName} />}
      <div style={{height:"100%",width:"100%"}}>
      {shipmentRequiredPriceFields&&shipmentContract&&<TableContainer component={Paper}>
      <Table border="0" style={{ width: '100%' }}>
        <TableBody>
          {shipmentRequiredPriceFields.map((field, index) => {
            return <Fragment key={index}>
              <TableRow className={index > 0 ? 'border-top' : ''}>
                <TableCell className="border-right">
                  {t(`common:fields.shipmentContractPrices.${field}`)}:
                </TableCell>
                <TableCell className="text-right">
                  {console.log(field)}
                  <format.Money value={shipmentContract.prices[field]} />
                </TableCell>
              </TableRow>
            </Fragment>;
          })}
          <TableRow className="border-top">
            <TableCell className="border-right">
              <strong>{t(`common:fields.shipmentContractPrices.total`)}:</strong>
            </TableCell>
            <TableCell className="text-right">
              <strong><format.Money value={shipmentContract.prices.total} /></strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>}
    <Button variant='contained' color="primary" onClick={handleClick} style={{marginTop:"30px"}}>Show files</Button>
    <ShowFilesContract files={shipmentContract?.files??[]} handleClose={handleClick} isActive={isOpen}/>
      </div>
    </div>
}
