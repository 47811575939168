import React, { Component } from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import { FaShip, FaPaperPlane, FaTruck } from 'react-icons/fa';

import input from '../input';
import action from '../action';
import format from '../format';
import val from '../validation';
import api from '../../lib/api';

import { globalPushModal } from '../../lib/global';

import { Container, Row, Col } from 'react-bootstrap';

import { Autocomplete } from '@material-ui/lab';
import { TextField, Button, FormLabel, FormControl, Box } from '@material-ui/core';

import countriesList from 'countries-list';
import airportsList from './places/airports';
import seaportsList from 'sea-ports';

const allCountries = Object.keys(countriesList.countries).map(countryCode => {
  const i = countriesList.countries[countryCode];

  return {
    name: i.name,
    nativeName: i.native,
    continent: i.continent,
    capital: i.capital,
    currency: i.currency,
    languages: i.languages,
    countryCode: countryCode,
  };
});

const countryNamesMap = allCountries.reduce((acc, cur) => {
  acc[cur.name] = cur;
  return acc;
}, {});

const countryCodesMapMap = allCountries.reduce((acc, cur) => {
  acc[cur.countryCode] = cur;
  return acc;
}, {});

const allSeaports = Object.keys(seaportsList.JSON).map(unloc => {
  const i = seaportsList.JSON[unloc];

  return {
    name: i.name,
    city: i.city,
    country: i.country,
    coordinates: i.coordinates,
    province: i.province,
    code: i.code,
    unloc: unloc,
  };
}).map(port => {
  const country = countryNamesMap[port.country];
  const continentCode = (country || {}).continent || '';
  const continent = countriesList.continents[continentCode || ''] || '';

  const [ lat = null, lon = null ] = port.coordinates || [];

  return {
    label: `${port.name} (${port.unloc}), ${port.city}, ${port.country}`,
    country: port.country,
    countryCode: (country || {}).countryCode || null,
    city: port.city,
    port: `${port.name} (${port.unloc})`,
    portType: 'seaport',
    portSubtype: 'seaport',
    portUnloc: port.unloc,
    continent: continent,
    lat: lat,
    lon: lon,
    matchLevel: 'port',
    type: 'seaport',
  };
}).sort((a, b) => a.label - b.label);

const allAirports = airportsList.filter(i => i.status && i.iso in countryCodesMapMap).map(port => {
  const country = countryCodesMapMap[port.iso] || { name: '', countryCode: port.iso };
  const continent = countriesList.continents[port.continent || ''] || '';

  return {
    label: `${port.name}, ${country.name}`,
    country: country.name,
    countryCode: port.iso,
    port: port.name,
    portType: 'airport',
    portSubtype: port.type,
    continent: continent,
    lat: port.lat,
    lon: port.lon,
    matchLevel: 'port',
    type: 'airport',
  };
}).sort((a, b) => a.label - b.label);

const API_KEY = 'He6X9h5JXtC1uObuAjZu4KbI6IQJ-o7VMn0k25hqIMA';

const CountryTypeaheadControl = ({ value = null, onChange, readOnly = false, label, error, helperText }) => {
  const [ text, setText ] = React.useState((value || {}).name || (value || ''));

  const defaultSelected = [];
  if (value) {
    const filtered = allCountries.filter(i => i.name === value || i.countryCode === value || i.nativeName === value);
    
    if (filtered.length > 0) {
      defaultSelected.push(filtered[0]);
    }
  }

  const handleChange = ([ country ]) => {
    console.log('handleChange: ', country);
    const { name = '' } = country || {};

    setText(name);

    if (!onChange) return;

    onChange(country);
  };

  const isValid = () => {
    return text !== '';
  }

  const filterOptions = (options, state) => {
    const result = options.filter(i => {
      const lowerInput = state.inputValue.toLowerCase();

      return i.name.toLowerCase().indexOf(lowerInput) >= 0;
    });

    return result;
  };

  return <>
    <Autocomplete
      value={(text && { name: text })}
      onChange={(e, newValue) => handleChange([ newValue ])}
      //onInputChange={(e, newValue) => handleSearch(newValue)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      renderOption={(option) => {
        return <><span>{option.name}</span></>;
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth error={error || !isValid()} helperText={helperText} readOnly={readOnly} disabled={readOnly} />
      )}
      filterOptions={filterOptions}
      options={allCountries}
      autoComplete
      includeInputInList
      filterSelectedOptions
    />
  </>;
};

const AddressTypeaheadControl = ({ onChange, enableLandAddresses = true, enableAirports = true, enableSeaports = true, label, error, errorText }) => {
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ options, setOptions ] = React.useState([]);

  const defaultSelected = [];

  const handleSearch = api.throttle(async (query) => {
    setIsLoading(true);

    const results = [];

    const match = (query, name) => {
      if (!name) return false;
      if (!query) return false;

      return name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    };

    if (enableAirports) {
      for (const port of allAirports.filter(i => match(query, i.label)).slice(0, 5)) {
        results.push(port);
      }
    }

    if (enableSeaports) {
      for (const port of allSeaports.filter(i => match(query, i.label)).slice(0, 5)) {
        results.push(port);
      }
    }

    if (enableLandAddresses) {
      const fetchHEREResponseJson = async () => {
        return await api.asyncCache(`geocoder:${query}`, async () => {
          const url = new URL('https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json');
          url.searchParams.set('apiKey', API_KEY);
          url.searchParams.set('query', query);
          url.searchParams.set('maxresults', 10);

          const response = await window.fetch(url.href);

          return await response.json();
        });
      };
  
      try {
        const json = await fetchHEREResponseJson();

        if (json.suggestions && json.suggestions.length > 0) {
          for (const i of json.suggestions) {
            const addr = i.address;

            const country = countryNamesMap[addr.country] || addr.country;
            const continentCode = (country || {}).continent || '';
            const continent = countriesList.continents[continentCode || ''] || '';

            results.push({
              label: i.label,
              //country: addr.country,
              //city: addr.city,
              //county: addr.county,
              //district: addr.district,
              //postalCode: addr.postalCode,
              //state: addr.state,
              //street: addr.street,*/
              ...addr,
              countryCode: country.countryCode,
              continent: continent,
              matchLevel: i.matchLevel,
              type: 'land',
            });
          }
        }
      } catch (e) {
      }
    }

    console.log('results: ', results);
    setOptions(results);
    setIsLoading(false);
  }, 500);

  const handleChange = ([ option ]) => {
    if (!onChange) return;

    console.log('handleChange: ', option);

    if (!option) {
      onChange(null);
    } else {
      onChange(option);
    }
  };

  return <>
    <Autocomplete
      onChange={(e, newValue) => handleChange([ newValue ])}
      onInputChange={(e, newValue) => handleSearch(newValue)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || option.label)}
      filterOptions={(x) => x}
      renderOption={(option) => {
        return <CompactAddressView value={option} />
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth error={error} helperText={errorText} />
      )}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
    />
  </>;
};

export const CompactAddressView = ({ value, showIcon = true }) => {
  if (!value || !value.country) {
    return <>
      N/A
    </>;
  }

  return (<>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      { showIcon ? <>
        <div className="icon-container">
          <AddressType value={value.type} />
        </div>
        <div className="block-vsep-50"></div>
      </> : <></> }
      <div style={{ display: 'flex' }}>
        <AddressDetails value={value} />
      </div>
    </div>
  </>);
};

const AddressDetails = ({ value }) => {
  const F = ({ label, value, last }) => {
    if (!value || value.toString() === '') return <></>;

    return <>
      {label}:
      {' '}
      <strong>{value}</strong>
      {last ? '' : ', '}
    </>;
  };

  const fields = [
    { label: i18n.t('common:fields.location.continent'), value: value.continent },
    { label: i18n.t('common:fields.location.postalCode'), value: value.postalCode },
    { label: i18n.t('common:fields.location.country'), value: value.country },
    { label: i18n.t('common:fields.location.state'), value: value.state },
    { label: i18n.t('common:fields.location.city'), value: value.city },
    { label: i18n.t('common:fields.location.port'), value: value.port },
    { label: i18n.t('common:fields.location.street'), value: value.street },
    { label: i18n.t('common:fields.location.district'), value: value.district },
    { label: i18n.t('common:fields.location.county'), value: value.county },
    { label: i18n.t('common:fields.location.portUnloc'), value: value.portUnloc },
    { label: i18n.t('common:fields.location.comments'), value: value.comments }
  ].filter(i => i.value && i.value.toString() !== '');

  return <>
    <div className="clearfix">
      {fields.map((i, index) => <F key={index} label={i.label} value={i.value} last={index + 1 >= fields.length} />)}
    </div>
  </>;
};

const AddressType = ({ value }) => {
  const style = {
  };

  if (value === 'airport') {
    return <format.TransportMode value="air" mode="icon" />
  } else if (value === 'seaport') {
    return <format.TransportMode value="sea" mode="icon" />
  } else {
    return <format.TransportMode value="land" mode="icon" />
  }
};

const AddressDetailsEditor = ({ value, readOnly = false, onChange }) => {
  const [ state ] = React.useState(value || {});

  const countryReadOnly = readOnly;

  const calcRestReadOnly = () => {
    return countryReadOnly || (state.country || '') === '';
  };

  const [ restReadOnly, setRestReadOnly ] = React.useState(calcRestReadOnly());

  const set = (field) => {
    return (val) => {
      state[field] = val;

      if (onChange) onChange(state);
    };
  };

  const setCountry = (field) => {
    return (val) => {
      state[field] = (val || {}).name || null;

      setRestReadOnly(calcRestReadOnly());

      if (onChange) onChange(state);
    };
  }

  return <>
    <input.Field label={i18n.t('common:fields.location.country')}>
      <CountryTypeaheadControl field="country" value={state.country} onChange={setCountry('country')} readOnly={countryReadOnly} />
    </input.Field>
    { state.type !== 'land' ? (
      <input.Text value={state.port} label={state.type === 'airport' ? 'Airport' : 'Port'} onChange={set('port')} readOnly={restReadOnly} />
    ) : <></>}
    <input.Text field="city" value={state.city} label={i18n.t('common:fields.location.city')} onChange={set('city')} readOnly={restReadOnly} />
    <input.Text field="postalCode" value={state.postalCode} label={i18n.t('common:fields.location.postalCode')} onChange={set('postalCode')} readOnly={restReadOnly} />
    <input.Text field="street" value={state.street} label={i18n.t('common:fields.location.street')} onChange={set('street')} readOnly={restReadOnly} />
    <input.Text field="comments" value={state.comments} label={i18n.t('common:fields.location.comments')} onChange={set('comments')} readOnly={restReadOnly} />
  </>;
};

export const AddressEditorControl = ({ value, label, type = null, readOnly = false, error, errorText, onChange }) => {
  const validationContext = React.useContext(val.ValidationContext);
  const [ state, setState ] = React.useState(value || {});
  const [ mode, setMode ] = React.useState(
    readOnly ? 'view' : (
      (state.country || '') === '' ? 'new' : 'view'
    )
  );

  const handleAddressChange = (addr) => {
    setState({
      ...(addr || {})
    });
    setMode('view');

    if (onChange) onChange(addr);

    handleEditRequest(addr || {});
  };

  const handleEditRequest = (initValue = {}) => {
    console.log('validationContext: ', validationContext);
    let editState = observable({ ...state, ...initValue });

    const handleChange = (addr) => {
      editState = addr;
    };

    const onSave = async () => {
      setState({ ...editState });

      if (onChange) onChange({ ...editState });

      setMode('view');
    }
  
    globalPushModal({
      title: i18n.t('common:title.address.edit'),
      body: <>
        <val.Container value={validationContext} type="location">
          <AddressDetailsEditor value={editState} onChange={handleChange} />
        </val.Container>
      </>,
      footer: <Observer>{() => (<action.ModalButtons onAsyncSave={onSave} disabled={!editState.country} />)}</Observer>,
      options: {
        large: false,
      },
    });
  };

  const clearAddress = () => {
    setState({});
    setMode('new');

    if (onChange) onChange(null);
  };

  return <Observer>{() => {
    if (mode === 'new') {
      // New address
      return <>
        <AddressTypeaheadControl
          onChange={handleAddressChange}
          enableLandAddresses={(type || 'land') === 'land'}
          enableAirports={(type || 'airport') === 'airport'}
          enableSeaports={(type || 'seaport') === 'seaport'}
          error={error}
          errorText={errorText}
          label={label}
        />
      </>
    } else {
      return <>
        <FormLabel>{label}</FormLabel>
        <Box border={1} padding={1} borderRadius={2} borderColor="rgba(0, 0, 0, 0.2)">
          <Box
            display="grid"
            gridTemplateColumns="auto max-content max-content"
            alignItems="center"
            gridColumnGap={"1em"}
          >
            <CompactAddressView value={state} />
            { !readOnly ? (<>
              <Button onClick={clearAddress} variant="outlined">{i18n.t('common:actions.clearAddress.label')}</Button>
              <Button onClick={() => handleEditRequest()} variant="contained">{i18n.t('common:actions.changeAddress.label')}</Button>
            </>) : <></>}
          </Box>
        </Box>
      </>;
    }
  }}</Observer>
};

//export default AddressDetailsEditor;
//export default CountryTypeaheadControl;
//export default AddressTypeaheadControl;
//export default CompactAddressView;
//export default AddressForm;