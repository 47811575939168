import { Box, Button, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import api from "../../lib/api";
import global from "../../lib/global";
import { AddModal } from "./AddModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const options = [
//     {
//         id:"13123",
//         name:"forwarder1"
//     },
//     {
//         id:"13123",
//         name:"forwarder2"
//     }
// ]

export function LocationModal({isActive,handleClose,changeOrigin,changeDestination,orgUnitId}){

    const [locations, setLocations] = useState([]);
    const [addingModalActive, setAddingModalActive] = useState(false);

    useEffect(() => {
        api.getCompanyLocations(orgUnitId??global.user.orgUnit._id).then((res) => {
            setLocations(res.data);
        })
    },[])

    const handleAddClose = () => {
        setAddingModalActive(!addingModalActive);
        api.getCompanyLocations(orgUnitId??global.user.orgUnit._id).then((res) => {
            setLocations(res.data);
        })
    }

    const deleteLocation = (id) => {
        api.deleteCompanyLocations(orgUnitId??global.user.orgUnit._id,id).then((res) => {
            api.getCompanyLocations(orgUnitId??global.user.orgUnit._id).then((res) => {
                setLocations(res.data);
            })
        })
    }

    const LoadDestination = ({city,continent,country,port,street,type,postalCode,comments}) => {
        changeDestination({city,continent,country,port,street,type,postalCode,comments,portSubtype:type,portType:type});
        handleClose();
    }

    const LoadOrigin = ({city,continent,country,port,street,type,postalCode,comments}) => {
        changeOrigin({city,continent,country,port,street,type,postalCode,comments,portSubtype:type,portType:type});
        handleClose();
    }

    return <>
        <Modal
            open={isActive}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box sx={style}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h2 id="parent-modal-title">Suppliers</h2>
                    {!orgUnitId&&<div style={{alignSelf:"center", fontWeight:"600"}}>{global.user.orgUnit.name}</div>}
                </div>
                <div style={{display:"flex",flexDirection:"column",gap:"1rem", paddingBottom:"2rem"}}>
                    {locations.map((location,index) => {
                        return (<div key={index} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                                <div>{location.name}</div>
                            </div>
                            <div style={{display:"flex",gap:"0.5rem"}}>
                                {!orgUnitId&&<Button variant="contained" color="primary" 
                                onClick={() => LoadOrigin({city:location.city,continent:location.state,country:location.country,port:location.port,street:"12",type:location.type,postalCode:"1231",comments:location.comments})}
                                >Load to origin</Button>}
                                {!orgUnitId&&<Button variant="contained" color="primary"
                                onClick={() => LoadDestination({city:location.city,continent:location.state,country:location.country,port:location.port,street:"12",type:location.type,postalCode:"1231",comments:location.comments})}
                                >Load to destination</Button>}
                                <Button variant="contained" style={{color:"white",backgroundColor:"red"}} onClick={() => deleteLocation(location._id)}>Delete</Button>
                            </div>
                        </div>)
                    })}
                </div>
                <Button variant="contained" onClick={() => {setAddingModalActive(!addingModalActive)}} color="primary">Add Supplier</Button>
            </Box>
        </Modal>
        <AddModal isActive={addingModalActive} handleClose={handleAddClose} orgUnitId={orgUnitId}/>
    </>
}