const events = {
  // event: [ listener ],
};

const api = {
  subscribe: (eventName, listener) => {
    if (!events[eventName]) {
      events[eventName] = [];
    }

    events[eventName].push(listener);
  },

  unsubscribe: (eventName, listener) => {
    if (!events[eventName]) return;

    events[eventName] = events[eventName].filter(i => i !== listener);
  },

  dispatchEvent: (eventName, payload = null) => {
    if (!events[eventName]) return;

    events[eventName].forEach(listener => {
      listener(payload);
    });
  },
};

export const Events = {
  NavigateStart: 'navigateStart',
  NavigateEnd: 'navigateEnd',
};

export default api;
