import React from 'react';
import i18n from 'i18next';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import api from '../lib/api';

import ui from './ui';
import format from './format';
import input from './input';
import action from './action';
import { globalPushModal } from '../lib/global';

import {
  Table, TableHead, TableBody, TableRow, TableCell,
  Typography, Box,
} from '@material-ui/core';

import MdDelete from '@material-ui/icons/DeleteForever';

const MAX_API_KEYS_COUNT = 5;

export const globalPushApiKeysListModal = () => {
  globalPushModal({
    title: i18n.t('common:navigation.userApiKeys.text'),
    body: <ApiKeysList />,
    footer: <action.ModalButtons />,
    options: {
      fixedHeight: true,
    }
  });
};

const base64ArrayBuffer = (arrayBuffer) => {
  let base64    = ''
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  const bytes         = new Uint8Array(arrayBuffer)
  const byteLength    = bytes.byteLength
  const byteRemainder = byteLength % 3
  const mainLength    = byteLength - byteRemainder

  let a, b, c, d;
  let chunk;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength]

    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4 // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2 // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }
  
  return base64
};

const ApiKeyDetailsView = ({
  clientId,
  clientSecret,
}) => {
  return <>
    <Box pb={2}>
      <Typography variant="subtitle1">
        {i18n.t('common:forms.apiClientDetails.prompt')}
      </Typography>
    </Box>
    <input.Field label={i18n.t('common:forms.apiClientDetails.fields.client_id')}>
      <input.Text type="key" readOnly={true} forceTextField={true} value={clientId} />
    </input.Field>
    <input.Field label={i18n.t('common:forms.apiClientDetails.fields.client_secret')}>
      <input.Text type="key" readOnly={true} forceTextField={true} value={clientSecret} />
    </input.Field>
  </>;
};

export const ApiKeysList = ({}) => {
  const uiState = observable({
    data: [],
  });

  React.useEffect(() => {
    refresh();
  });

  const refresh = async () => {
    const response = await api.queryUserApiKeys();

    if (response.success) {
      uiState.data = response.data;
    }
  };

  const createCryptoKeyString = async () => {
    const cryptoKey = await window.crypto.subtle.generateKey({
      name: 'AES-GCM',
      length: 256,
    }, true, [ 'encrypt', 'decrypt' ])

    const exportedBuffer = await window.crypto.subtle.exportKey('raw', cryptoKey);

    return base64ArrayBuffer(exportedBuffer);
  };

  const handleCreate = React.useCallback(async () => {
    api.lock();

    try {
      const client_secret = await createCryptoKeyString() + await createCryptoKeyString() + await createCryptoKeyString();

      const response = await api.createUserApiKey({
        client_secret: client_secret,
      });

      if (response.success) {
        globalPushModal({
          title: i18n.t('common:forms.apiClientDetails.title'),
          body: <ApiKeyDetailsView clientId={response.data.client_id} clientSecret={client_secret} />,
          footer: <action.ModalButtons />,
          options: {
            maxWidth: 'md',
            fullWidth: false,
          }
        });

        await refresh();
      }
    } catch(e) {
      // NOP
    }

    api.unlock();
  }, []);

  const handleDelete = React.useCallback(async (client_id) => {
    api.confirm('deleteApiKey', null, async (confirmed) => {
      if (!confirmed) return;

      const response = await api.deleteUserApiKey({ client_id: client_id });

      if (response.success) {
        await refresh();
      }
    });
  }, []);

  return <ui.MainContentContainer>
    <ui.TableContainer autoSize>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{i18n.t('common:fields.apiClient.client_id')}</TableCell>
            <TableCell align="right">
              <Observer>{() => <>
                <action.Action mode="button" action="createApiKey" color="secondary" onClick={handleCreate} disabled={uiState.data.length >= MAX_API_KEYS_COUNT}>
                  {i18n.t('common:actions.createApiKey.label')}
                </action.Action>
              </>}</Observer>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Observer>{() => <>
            {uiState.data.map((i, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box display="grid" gridTemplateColumns="min-content auto" alignItems="center">
                    <Box pr={1}>
                      <action.Action mode="icon" action="delete" onClick={() => handleDelete(i.client_id)}>
                        <MdDelete />
                      </action.Action>
                    </Box>
                    <Box>
                      {i.client_id}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            ))}
          </>}</Observer>
        </TableBody>
      </Table>
    </ui.TableContainer>
  </ui.MainContentContainer>;
};
