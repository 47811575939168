import React from 'react';
import config from './config';
import { observable, action } from 'mobx';
import i18n from 'i18next';
import axios from 'axios';
import ReactGA from 'react-ga';

import global, { globalPushModal, globalPopAllModals } from './global';
import realtime from './realtime';
import events, { Events } from './events';
import history from './history';

import localAction from '../components/action';

class ObjectId {
  value;

  constructor (value) {
    this.value = value;
  }

  toString () {
    return this.value.toString();
  }
};

const internalState = observable({
  lockLevel: 0,
});

const api = observable({
  isReady: false,
  isBusy: false,
  lastError: null,
});

const setError = (error) => {
  if (!api.lastError) {
    api.lastError = error;
  }

  return error;
};

api.ObjectId = (value) => {
  return new ObjectId(value);
};

api.clearLastError = () => {
  api.lastError = null;
};

api.setLastError = ({ message }) => {
  setError({
    success: false,
    message: message,
  })
};

api.lock = () => {
  ++internalState.lockLevel;

  api.isBusy = internalState.lockLevel > 0;
};

api.unlock = () => {
  --internalState.lockLevel;

  api.isBusy = internalState.lockLevel > 0;
};

api.appBaseUrl = (() => {
  return `${window.location.protocol}//${window.location.host}`;
})();

api.throttleInterval = (func, intervalMilliseconds) => {
  let lastTimeMs = 0;

  return (...args) => {
    const context = this;

    const now = new Date();
    const deltaMs = now.valueOf() - lastTimeMs;

    if (deltaMs < intervalMilliseconds) return null;

    lastTimeMs = now.valueOf();

    return func.apply(context, ...args);
  };
};

api.throttle = (func, delayMilliseconds) => {
  let timerHandle = null;
  let isRunning = false;
  let isPending = false;

  return (...args) => {
    const context = this;

    const process = (...args) => {
      if (timerHandle !== null) {
        clearTimeout(timerHandle);
        timerHandle = null;
      }

      if (isRunning) {
        isPending = true;

        return;
      }

      timerHandle = setTimeout(() => {
        timerHandle = null;

        isRunning = true;
        isPending = false;

        const promise = func.apply(context, ...args);

        const next = (...args) => {
          isRunning = false;

          if (isPending) {
            isPending = false;

            process(args);
          }
        };

        promise
          .then(() => {
            next.apply(context);
          })
          .catch(() => {
            next.apply(context);
          });
      }, delayMilliseconds);
    };

    process(args);
  };
};

api.confirm = (key, options, callback) => {
  const opt = options || {};
  let hadResult = false;

  const i18nKeyPrompt = `common:confirm.${key}.prompt`;
  const i18nKeyTitle = `common:confirm.${key}.title`;

  const prompt = i18n.exists(i18nKeyPrompt) ? i18n.t(i18nKeyPrompt) : i18n.t('common:confirm.default.prompt');
  const title = i18n.exists(i18nKeyTitle) ? i18n.t(i18nKeyTitle) : i18n.t('common:confirm.default.title');

  const onConfirm = () => {
    hadResult = true;

    const promise = callback(true);

    if (promise && promise.then) {
      return promise;
    } else {
      return Promise.resolve();
    }
  };

  const onHide = () => {
    if (hadResult) return;

    callback(false);
  }

  globalPushModal({
    title: title,
    body: <>
      {prompt.split(/\n/g).map(i => {
        return <span key={i}>{i}<br /></span>
      })}
    </>,
    footer: <localAction.ModalButtons onAsyncSave={onConfirm} action={opt.action || 'confirm'} />,
    options: {
      large: false
    },
    onHide: onHide,
  });
};

api.prompt = (key, options, callback) => {
  const i18nKey = `common:prompt.${key}.prompt`;

  const result = window.prompt(i18n.t(i18nKey));

  callback(result);
};

api.alert = (key, options, callback) => {
  const i18nKey = `common:alert.${key}.prompt`;

  const result = window.alert(i18n.t(i18nKey));

  callback();
};

api.isPlatformAdmin = () => {
  if (global.user === null) return false;

  return global.user.user.isPlatformAdmin;
};

api.isOrgAdmin = () => {
  if (global.user === null) return false;

  return global.user.role.perms.orgAdmin || api.isPlatformAdmin();
};

api.canOrgWrite = () => {
  if (global.user === null) return false;

  return api.isOrgAdmin() || global.user.role.perms.orgWrite;
};

api.canOrgRead = () => {
  if (global.user === null) return false;

  return api.isOrgAdmin() || global.user.role.perms.orgRead;
};

api.now = () => {
  const bias = global.time.client.valueOf() - global.time.server.valueOf();
  const server = new Date().valueOf() - bias;

  return new Date(server);
};

api.today = () => {
  return api.dateOnly(api.now());
};

api.dateOnly = (dt) => {
  const result = new Date(dt);

  result.setMinutes(0);
  result.setHours(0);
  result.setSeconds(0);
  result.setMilliseconds(0);

  return result;
};

api.future = () => {
  return {
    days: (val) => {
      const date = api.today();
      date.setDate(date.getDate() + val);
      return date;
    },
    months: (val) => {
      const date = api.today();
      date.setMonth(date.getMonth() + val);
      return date;
    },
    years: (val) => {
      const date = api.today();
      date.setYear(date.getYear() + val);
      return date;
    },
  }
};

api.downloadBlob = async (blob, fileName) => {
  const a = window.document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.style.display = 'none';
  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
};

api.browserDownloadUrl = async (relativeUrl) => {
  const frame = window.document.createElement("iframe");
  frame.style.cssText = "position: absolute; display: none; top: -20; left: -20; width: 1; height: 1";
  frame.src = api.createAbsoluteUrl(relativeUrl).toString();
  window.document.body.appendChild(frame);
};

api.downloadUrl = async (relativeUrl, fileName) => {
  try {
    const response = await api.fetch(relativeUrl, { rawResponse: true, responseType: 'blob' });
    
    if (response.status < 200 || response.status >= 300) {
      const err = { message: 'Download failed.' };
      
      api.setLastError(err);
      
      throw new Error(err.message);
    } else {
      const blob = response.data;
      
      await api.downloadBlob(blob, fileName);

      return { success: true, message: 'OK' };
    };
  } catch (e) {
    api.setLastError(e.toString());

    throw e;
  }
};

api.reload = async ({ replace = true } = {}) => {
  //api.lock();

  //events.dispatchEvent(Events.NavigateStart);

  //window.location.reload();

  api.navigateUrl(window.location.href, { replace: replace });
};

api.navigateToLogin = async () => {
  return await api.navigateUrl(`${config.apiBaseUrl}/login`);
};

api.forcePageReload = async () => {
  window.location.href = '/';
};

api.navigateHome = async () => {
  return await api.navigateUrl('/');
};

api.navigateShipmentState = async (state) => {
  if (!state) {
    api.trackEvent({
      category: 'Navigation',
      action: 'Navigate to Shipment State',
      label: 'All States',
    });

    return await api.navigateUrl(`/shipments`);
  } else {
    api.trackEvent({
      category: 'Navigation',
      action: 'Navigate to Shipment State',
      label: state,
    });

    const stateGroups = [
      [ 'marketplace' ],
      [ 'draft', 'rfp', 'cancelled', 'expired' ],
      [ 'active', 'completed', 'closed', 'dispute' ]
    ];
  
    const stateGroup = stateGroups.filter(i => i.includes(state))[0].join(',');
  
    return await api.navigateUrl(`/shipments/${stateGroup}`);
  }
};

api.navigateShipmentContractState = async (state) => {
  if (!state) {
    api.trackEvent({
      category: 'Navigation',
      action: 'Navigate to ShipmentContract State',
      label: 'All States',
    });

    return await api.navigateUrl(`/shipmentContracts`);
  } else {
    api.trackEvent({
      category: 'Navigation',
      action: 'Navigate to ShipmentContract State',
      label: state,
    });

    const stateGroups = [
      [ 'draft', 'bid', 'archived', 'rejected', 'expired' ],
      [ 'contract', 'completed', 'dispute' ],
      [ 'archived' ],
    ];

    const stateGroup = stateGroups.filter(i => i.includes(state))[0].join(',');

    return await api.navigateUrl(`/shipmentContracts/${stateGroup}`);
  }
};

api.navigateOrgUnits = async () => {
  api.trackEvent({
    category: 'Navigation',
    action: 'Navigate to OrgUnits Admin',
  });

  return await api.navigateUrl('/orgUnits');
};

api.navigateUrl = async (href, { replace = true } = {}) => {
  api.lock();

  if (replace) {
    globalPopAllModals();
  }

  events.dispatchEvent(Events.NavigateStart);

  const newUrl = new URL(href, window.location.href);
  const currUrl = new URL(window.location.href);

  if (newUrl.origin !== currUrl.origin) {
    window.location.href = newUrl.href;
  } else {
    const url = newUrl.pathname + newUrl.search;
    history.push(url);

    events.dispatchEvent(Events.NavigateEnd);

    api.unlock();
  }

  return Promise.resolve();
};

api.hasModule = (module) => {
  if (!global.user) return false;

  return global.user.orgUnit.modules.includes(module);
};

api.createAbsoluteUrl = (relativeUrl) => {
  if (relativeUrl.substr(0, 5) === 'http:' || relativeUrl.substr(0, 6) === 'https:') {
    return relativeUrl;
  } else {
    return new URL(`secure/${relativeUrl}`, `${config.apiBaseUrl}`);
  }
};

api.asyncCache = async (key, fetchFunc, options = {}) => {
  const duration = options.duration || 1000 * 60 * 5;

  const cacheKey = `cache:${key}`;

  const cached = window.sessionStorage.getItem(cacheKey);

  if (cached) {
    const { expires, value } = JSON.parse(cached);

    if (expires < new Date()) {
      window.sessionStorage.removeItem(cacheKey);
    } else {
      return value;
    }
  }

  const value = await fetchFunc();

  const expires = new Date();
  expires.setMilliseconds(expires.getMilliseconds() + duration);

  window.sessionStorage.setItem(cacheKey, JSON.stringify({
    value: value,
    expires: expires,
  }));

  return value;
};

api.fetch = async (relativeUrl, options = { method: 'GET', rawResponse: false, responseType: 'json' }, query) => {
  const url = api.createAbsoluteUrl(relativeUrl);

  const {
    own = false,
    silent = false,
    filter = {},
    sort = {},
    search = null,
    limit = null,
    skip = null,
    onValidationError = null,
    startDate = null,
    endDate = null
  } = query || {};

  if (own) {
    url.searchParams.set('own', '1');
  }

  const convertValue = input => {
    if (input instanceof ObjectId) return `id:${input}`;
    if (typeof input === 'boolean') return `b:${input}`;
    if (typeof input === 'number') return `n:${input}`;
    return `s:${input}`;
  };

  Object.keys(filter).forEach(i => {
    if (filter[i] === null || filter[i] === undefined) return;

    url.searchParams.set(`where.${i}`, convertValue(filter[i]));
  });

  Object.keys(sort).forEach(i => {
    url.searchParams.set(`sort.${i}`, sort[i]);
  });

  if (search) {
    url.searchParams.set('search', search);
  }

  if (limit) {
    url.searchParams.set('limit', limit);
  }

  if (skip) {
    url.searchParams.set('skip', skip);
  }

  if (startDate) {
    url.searchParams.set('startDate', startDate);
  }

  if (endDate) {
    url.searchParams.set('endDate', endDate);
  }

  if (!silent) api.lock();

  const result = await (async () => {
    try {
      const opt = {
        ...options,
        //credentials: 'include',
        withCredentials: true,
      };

      const response = await axios.request({
        url: url.href,
        ...opt
      });

      if (options.rawResponse) {
        return response;
      }

      console.debug('response: ', response);

      const setServerTimestamp = (timestamp) => {
        if (timestamp) {
          global.time.server = new Date(timestamp);
          global.time.client = new Date();
        }
      };

      if (response.status >= 200 && response.status <= 299) {
        const json = response.data;

        if (json) {
          setServerTimestamp(json.timestamp);
        }

        console.debug('json: ', json);
        return json;
      } else {
        try {
          const json = response.data;

          if (json) {
            setServerTimestamp(json.timestamp);
          }

          if (json.validation && onValidationError) {
            onValidationError(json.validation);
          } else if (!silent) {
            return setError(json);
          }
        } catch (err) {
          if (!silent) {
            return setError({ success: false, message: err.message });
          }
        }
      }

      return response;
    } catch (e) {
      if (e?.response?.data?.validation && onValidationError) {
        onValidationError(e.response.data.validation);

        return e.response.data;
      } else if (!silent) {
        if (e.response) {
          return setError(e.response.data);
        } else {
          return setError({ success: false, message: e.message });
        }
      } else {
        if (e.response) {
          return e.response.data;
        } else {
          return { success: false, message: e.message };
        }
      }
    }
  })();

  if (!silent) api.unlock();

  return result;
};

api.get = async (relativeUrl, query) => {
  return await api.fetch(relativeUrl, { method: 'GET' }, query);
};

api.delete = async (relativeUrl, query) => {
  return await api.fetch(relativeUrl, { method: 'DELETE' }, query);
};

api.put = async (relativeUrl, body, query) => {
  return await api.fetch(relativeUrl, {
    method: 'PUT',
    data: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  },
  query);
};

api.post = async (relativeUrl, body, query) => {
  return await api.fetch(relativeUrl, {
    method: 'POST',
    data: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  },
  query);
};

api.login = async (provider) => {
  // const url = new URL(`/auth/${provider}/auth?returnUrl=${api.appBaseUrl}&errorUrl=${api.appBaseUrl}`, config.apiBaseUrl);
  const url = new URL(`/login`);
  return await api.navigateUrl(url.href);
};

api.logout = async () => {
  const url = new URL(`/logout`, config.apiBaseUrl);

  return await api.navigateUrl(url.href);
}

api.queryUser = async (query) => {
  return await api.get('users/me', query);
};

api.queryUserApiKeys = async (query) => {
  return await api.get('users/api/keys', query);
};

api.createUserApiKey = async ({ client_secret }) => {
  api.trackEvent({
    category: 'Action',
    action: 'Create',
    label: 'API Key',
  });

  return await api.post('users/api/keys', { client_secret: client_secret });
};

api.deleteUserApiKey = async ({ client_id }) => {
  api.trackEvent({
    category: 'Action',
    action: 'Delete',
    label: 'API Key',
  });

  return await api.delete(`users/api/keys/${client_id}`);
};

api.queryCurrencyExchangeRates = async (query) => {
  return await api.get('currency/exchangeRates', query);
};

api.queryOrgUnitSummary = async () => {
  return await api.get('orgUnits/summary', {});
};

api.queryOrgUnit = async () => {
  return await api.get('orgUnits/me', {});
};

api.updateOrgUnitPublicForwarderProfile = async ({ body = '', tags = [] }) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Public Forwarder Profile',
    body: body,
    tags: tags.join(','),
  });

  return await api.put(`orgUnits/publicForwarderProfile`, { body, tags });
};

api.queryOrgUnitHistory = async (epoch) => {
  return await api.get(`orgUnits/history/${epoch}`, {});
};

api.queryUserProfile = async (query) => {
  return await api.get('users/profile', query);
};

api.querySharedForwarderUsers = async (forwarderId, query) => {
  return await api.get(`users/shared/forwarder/${forwarderId}`, query);
};

api.updateUserProfile = async (profile) => {
  return await api.put('users/profile', profile);
};

api.queryShipments = async (query = {}) => {
  return await api.get('shipments', query);
};

api.querySharedShipmentsRFPs = async (query = {}) => {
  return await api.get('shipments/shared/rfp', query);
};

api.queryShipmentById = async (id) => {
  return await api.get(`shipments/${id}`);
};

api.queryShipmentBidsByShipmentId = async (id, query = {}) => {
  api.trackEvent({
    category: 'Action',
    action: 'Query',
    label: 'Shipment Bids',
    shipmentId: id,
  });

  return await api.get(`shipments/${id}/bids`, query);
};

api.createShipment = async (shipment) => {
  api.trackEvent({
    ...shipment,
    category: 'Action',
    action: 'Create',
    label: 'Shipment',
  });

  return await api.post(`shipments`, shipment);
};

api.updateShipment = async (shipment) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Shipment',
    shipmentId: shipment._id,
  });

  return await api.put(`shipments/${shipment._id}`, shipment);
};

api.updateShipmentExpiresAtById = async (shipmentId, expiresAt) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Shipment Auction Expiration Date',
    shipmentId: shipmentId,
    expiresAt: expiresAt,
    expired: new Date(expiresAt) <= api.now(),
  });

  return await api.put(`shipments/${shipmentId}/expiresAt`, { expiresAt: expiresAt });
};

api.createShipmentCopyById = async (shipmentId, nameOrData) => {
  const data = (typeof(nameOrData) === 'string') ? { name: nameOrData } : nameOrData;

  api.trackEvent({
    category: 'Action',
    action: 'Duplicate',
    label: 'Shipment',
    shipmentId: shipmentId,
    nameOfNewShipment: data.name,
  });

  return await api.post(`shipments/copy/${shipmentId}`, data);
};

api.updateShipmentStateById = async (shipmentId, state, { ...rest }) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Shipment State',
    shipmentId: shipmentId,
    state: state,
  });

  return await api.put(`shipments/${shipmentId}/state`, { ...rest, state: state });
};

api.updateShipmentCommentById = async (shipmentId, comment) => {
  return await api.put(`shipments/${shipmentId}/comment`, { comment:comment });
};

api.getShipmentCommentById = async (shipmentId) => {
  return await api.get(`shipments/${shipmentId}/comment`);
};

api.addForwarder = async (userId,forwarderId,name,email) => {
  return await api.post(`users/addForwarder`,{
    userId,
    orgId:forwarderId,
    name,
    email
  });
};

api.getUserForwarders = async (userId) => {
  return await api.get(`users/forwarders/${userId}`);
};

api.getMyForwarders = async () => {
  return await api.get(`users/getMyForwarders`);
};

api.deleteUserForwarders = async (userId,forwarderId) => {
  return await api.delete(`users/deleteForwarder/${userId}/${forwarderId}`);
};

api.addCompanyLocations = async ({orgUnitId,name,type,port,country,city,state,comments}) => {
  return await api.post(`orgUnits/location`, {orgUnitId,name,type,port,country,city,state,comments});
};

api.getCompanyLocations = async (orgUnitId) => {
  return await api.get(`orgUnits/location/${orgUnitId}`);
};

api.deleteCompanyLocations = async (orgUnitId,locationId) => {
  return await api.delete(`orgUnits/location/${orgUnitId}/${locationId}`);
};

api.completeShipment = async (shipmentId, { ...rest }) => {
  api.trackEvent({
    category: 'Action',
    action: 'Complete',
    label: 'Shipment',
    shipmentId: shipmentId,
  });

  return await api.put(`shipments/${shipmentId}/complete`, { ...rest });
};

api.deleteShipmentById = async (shipmentId) => {
  console.log('deleteShipmentById');
  api.trackEvent({
    category: 'Action',
    action: 'Delete',
    label: 'Shipment',
    shipmentId: shipmentId,
  });

  return await api.delete(`shipments/${shipmentId}`);
};

api.deleteShipmentContractById = async (contractId) => {
  api.trackEvent({
    category: 'Action',
    action: 'Delete',
    label: 'Shipment Contract',
    shipmentContractId: contractId,
  });

  return await api.delete(`shipmentContracts/${contractId}`);
};

api.acceptShipmentContractById = async (contractId) => {
  api.trackEvent({
    category: 'Action',
    action: 'Accept',
    label: 'Shipment Contract',
    shipmentContractId: contractId,
  });

  return await api.put(`shipmentContracts/${contractId}/accept`, {});
};

api.createShipmentContract = async (contract) => {
  api.trackEvent({
    ...contract,
    category: 'Action',
    action: 'Create',
    label: 'Shipment Contract',
  });

  return await api.post('shipmentContracts', contract);
};

api.updateShipmentContract = async (contract, { onValidationError = null }) => {
  const data = { ...contract };

  delete data._id;
  delete data.state;
  delete data.enabled;
  delete data.readOnly;
  
  api.trackEvent({
    ...contract,
    category: 'Action',
    action: 'Update',
    label: 'Shipment Contract',
    shipmentContractId: contract._id,
  });

  return await api.put(`shipmentContracts/${contract._id}`, contract, {
    onValidationError: onValidationError,
  });
};

api.updateShipmentTrackingByShipmentContractId = async (contractId, tracking) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Shipment Contract Tracking',
    shipmentContractId: contractId,
  });

  return await api.put(`shipmentContracts/${contractId}/shipmentTracking`, tracking);
};

api.updateShipmentOperationalStateByShipmentContractId = async (contractId, operationalState) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Shipment Contract Operational State',
    shipmentContractId: contractId,
    operationalState: operationalState,
  });

  return await api.put(`shipmentContracts/${contractId}/operationalState`, { operationalState: operationalState });
};

api.queryShipmentContracts = async (query = {}) => {
  return await api.get('shipmentContracts', query);
};

api.getShipmentContractsNiv = async (shipmentId) => {
  return await api.get('shipmentContracts/niv/' + shipmentId);
};

api.logSendMail = async (shipmentId,orgId, email) => {
  return await api.post('logs/sendMail', {shipmentId,orgId, email});
}

api.queryShipmentContractById = async (id, query = {}) => {
  return await api.get(`shipmentContracts/${id}`, query);
};

api.updateShipmentContractStateById = async (contractId, state, query = {}) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'Shipment Contract State',
    shipmentContractId: contractId,
    state: state,
  });

  return await api.put(`shipmentContracts/${contractId}/state`, { state: state }, query);
};

api.uploadShipmentContractInvoiceById = async (contractId, file) => {
  const data = new FormData();
  data.append('file', file);

  return await api.fetch(`shipmentContracts/${contractId}/invoice`, {
    method: 'POST',
    data: data,
  }, {
    silent: false,
  });
};

// TODO: delete this function after migrating forwarders
api.uploadShipmentContractInvoiceByIdClientPerms = async (contractId, file) => {
  try {
    const data = new FormData();
    data.append('file', file);

    const result = await api.fetch(`shipmentContracts/${contractId}/invoiceclientperms`, {
      method: 'POST',
      data: data,
      customErrorHandler: true,
    }, {
      silent: true,
    });
    return result;
  } catch (e) {
    return e.response;
  }
};

api.querySharedUserForwarders = async (query = {}) => {
  return await api.get('orgUnits/shared/forwarders', query);
};


api.postForwarderProposal = async (data) => {
  api.trackEvent({
    ...data,
    category: 'Action',
    action: 'Create',
    label: 'New Forwarder Proposal',
  });

  return await api.post('orgUnits/shared/forwarders', data);
};

api.postForwarderUserProposal = async (orgUnitId, data) => {
  api.trackEvent({
    ...data,
    category: 'Action',
    action: 'Create',
    label: 'New Forwarder User Proposal',
    orgUnitId: orgUnitId,
  });

  return await api.post(`orgUnits/shared/forwarders/${orgUnitId}/user`, data);
};

api.querySharedOrgUnitRating = async (orgUnitId, query = {}) => {
  return await api.get(`orgUnits/shared/rating/${orgUnitId}`, query);
};

api.queryLogs = async (query) => {
  return await api.get('logs', query);
};

api.queryStatistics = async(query) => {
  return await api.get('statistics', query);
}

api.queryOrgUnits = async (query) => {
  return await api.get('orgUnits', query);
}

api.queryOrgUnitNameNiv = async (orgUnitId) => {
  return await api.get('orgUnits/niv/getName/' + orgUnitId);
};

api.deleteOrgUnitUserByIds = async (orgUnitId, userId) => {
  api.trackEvent({
    category: 'Action',
    action: 'Delete',
    label: 'User from OrgUnit',
    orgUnitId: orgUnitId,
    userId: userId,
  });

  return await api.delete(`orgUnits/${orgUnitId}/users/${userId}`);
};

api.updateOrgUnit = async (orgUnitId, update) => {
  api.trackEvent({
    ...update,
    category: 'Action',
    action: 'Update',
    label: 'OrgUnit',
    orgUnitId: orgUnitId,
  });

  return await api.put(`orgUnits/${orgUnitId}`, update);
};

api.createOrgUnit = async (create) => {
  api.trackEvent({
    ...create,
    category: 'Action',
    action: 'Create',
    label: 'OrgUnit',
  });

  return await api.post(`orgUnits`, create);
};

api.createOrgUnitUserById = async (orgUnitId, { email, roleId, name }) => {
  api.trackEvent({
    category: 'Action',
    action: 'Create',
    label: 'OrgUnit User',
    orgUnitId: orgUnitId,
    email: email,
    roleId: roleId,
    name: name,
  });

  return await api.post(`orgUnits/${orgUnitId}/users`, {
    email: email,
    roleId: roleId,
    name: name,
  });
};

api.setOrgUnitById = async (orgUnitId) => {
  api.trackEvent({
    category: 'Action',
    action: 'Set Context',
    label: 'OrgUnit',
    orgUnitId: orgUnitId,
  });

  return await api.put('auth/orgUnit', { orgUnitId: orgUnitId });
};

api.queryRoles = async (query) => {
  return await api.get('roles', query);
};

api.queryUsers = async (query) => {
  return await api.get('users', query);
};

api.updateUserById = async (userId, user) => {
  api.trackEvent({
    ...user,
    category: 'Action',
    action: 'Update',
    label: 'User',
    userId: userId,
  });

  return await api.put(`users/${userId}`, user);
};

api.updateUserPasswordById = async (userId, password) => {
  api.trackEvent({
    category: 'Action',
    action: 'Update',
    label: 'User Password',
    userId: userId,
  });

  return await api.put(`users/${userId}/password`, { password: password });
};

api.postAttachment = async (file, model, id) => {
  api.trackEvent({
    category: 'Action',
    action: 'Create',
    label: 'Attachment',
    model: model,
    id: id,
    fileName: file.name,
    fileSize: file.size,
    fileType: file.type,
  });

  // relation.shipmentId
  const onUploadProgress = (progressEvent) => {
    console.log('onUploadProgress: ', progressEvent);
  };

  try {
    const data = new FormData();
    data.append('file', file);

    const result = await api.fetch(`attachments/${model}/${id}`, {
      method: 'POST',
      data: data,
      onUploadProgress: onUploadProgress,
    });

    return result;
  } catch (e) {
    return e.response;
  }
};

api.queryAttachments = async (query) => {
  return await api.get(`attachments`, query);
};

api.queryAttachmentMetadataById = async (id, query) => {
  return await api.get(`attachments/${id}/metadata`, query);
};

api.queryChatSessionById = async (id) => {
  return await api.get(`chatSessions/${id}`, {});
};

api.queryChatSessionItemsById = async (id, query) => {
  return await api.get(`chatSessions/${id}/items`, query);
};

api.postChatSessionTextItem = async (id, { text }) => {
  return await api.post(`chatSessions/${id}/text`, {
    text: text,
  });
};

api.adviseChatSessionKeyboardActivity = async (id) => {
  return await api.put(`chatSessions/${id}/keyboardActivity`, {});
};

api.adviseChatSessionReadHistoryItemId = async (id, historyItemId) => {
  return await api.put(`chatSessions/${id}/readHistoryItemId`, { historyItemId: historyItemId });
};

api.uploadChatSessionFileItem = async (id, { file, onUploadProgress = null, silent = false }) => {
  try {
    const data = new FormData();
    data.append('file', file);

    const result = await api.fetch(`chatSessions/${id}/file`, {
      method: 'POST',
      data: data,
      onUploadProgress: onUploadProgress,
      customErrorHandler: true,
    }, {
      silent: silent,
    });

    return result;
  } catch (e) {
    return e.response;
  }
};

api.downloadAttachmentById = async (id) => {
  return await api.browserDownloadUrl(`attachments/${id}/data`);
};


api.downloadPdf = async (attachedId) => {
  console.log(attachedId);
  //window.open(`attachments/${attachedId}/data`, '_blank');
  return await api.get(`attachments/${attachedId}/data`);
};

api.queryPlatformShipmentContracts = async (query = {}) => {
  // All. Requires Platform Admin privileges
  return await api.get('platform/shipmentContracts', query);
};

api.putPlatformShipmentContractInvoiceValidationStateById = async (id, invoiceValidationState, query = {}) => {
  return await api.put(`platform/shipmentContracts/${id}/invoiceValidationState`, {
    invoiceValidationState: invoiceValidationState,
  }, query);
};

api.queryReports = async ({ type = 'simple' } = {}, query = {}) => {
  // All. Requires Platform Admin privileges
  return await api.get(`reports/${type}`, query);
};

api.queryReport = async ({ type = 'simple', scope, id }, query = {}) => {
  // All. Requires Platform Admin privileges
  return await api.get(`reports/${type}/${scope}/${id}`, query);
};

api.addScript = ({ id, src }) => {
  return new Promise((resolve, reject) => {
    const e = window.document.createElement("script");

    e.async = true;
    e.type = "text/javascript";
    e.src = src;
    e.id = id;
    e.onload = () => { resolve() };
    e.onerror = () => { reject(new Error('Failed loading script: ' + src)) };

    window.document.body.appendChild(e);
  });
};

api.addAuthenticatedScripts = () => {
  return Promise.all(config.scripts.authenticated.map(script => api.addScript(script)));
};

api.trackEvent = async ({ category, action, label, value, nonInteraction = false, ...rest }) => {
  const contextData = {
    userId: global.user.user._id.toString(),
    orgUnitId: global.user.orgUnit._id.toString(),
    userName: global.user.user.name,
    userEMail: global.user.user.email,
    orgUnitName: global.user.orgUnit.name,
    orgUnitIsForwarder: api.hasModule('forwarder'),
    orgUnitIsInitiator: api.hasModule('initiator'),
  };

  const list = [];
  if (category) list.push(category);
  if (action) list.push(action);
  if (label) list.push(label);

  const fullEventName = list.join(': ');

  const fullEventData = {
    ...rest,
    ...contextData,
    category,
    action,
    label,
    value,
    nonInteraction,
  };
  
  // if (window.mixpanel) {
    // window.mixpanel.track(fullEventName, fullEventData);
  // }

  if (window.heap) {
    window.heap.track(fullEventName, fullEventData);
  }

  ReactGA.event(fullEventData);
};

api.init = async () => {
  if (api.isReady) return;

  global.user = null;

  const request = new URL(window.location.href);

  const authCode = request.searchParams.get('auth.code');
  const authMessage = request.searchParams.get('auth.message');
  const authError = request.searchParams.get('auth.error');

  if (authCode) {
    if (authCode !== 'AUTH_ERROR_UNKNOWN_USER') {
      // api.navigateToLogin();
    } else {
      // // TODO: Redirect to page explaining how to sign up
      // api.logout();
    }
    return;
  }

  const user = await api.queryUser({ silent: true });

  if (!user || !user.success) {
    api.navigateToLogin();

    return;
  } else {
    global.user = user.data;

    const reportsResponse = await api.queryReports();

    if (reportsResponse.success) {
      global.reports = reportsResponse.data;
    }

    if (window.FS) {
      // Init FullStory analytics identity

      window.FS.identify(global.user.user._id, {
        displayName: global.user.user.name,
        email: global.user.user.email,

        // Custom user variables here, details at
        // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
        userId: global.user.user._id.toString(),
        lastOrgUnitName: global.user.orgUnit.name,
        lastOrgUnitId: global.user.orgUnit._id.toString(),
      });
    }

    if (window.heap) {
      // Init HEAP analytics identity
      window.heap.identify(global.user.user._id);

      // Add HEAP user props
      window.heap.addUserProperties({
        userId: global.user.user._id.toString(),
        lastOrgUnitId: global.user.orgUnit._id.toString(),
        userName: global.user.user.name,
        userEMail: global.user.user.email,
        lastOrgUnitName: global.user.orgUnit.name,
      });
    }

    if (window.mixpanel) {
      // Init MixPanel analytics identity
      window.mixpanel.identify(global.user.user._id);

      // Add MixPanel user props
      window.mixpanel.people.set({
        userId: global.user.user._id.toString(),
        lastOrgUnitId: global.user.orgUnit._id.toString(),
        userName: global.user.user.name,
        userEMail: global.user.user.email,
        lastOrgUnitName: global.user.orgUnit.name,
      });
    }

    // Init Google Analytics identity
    ReactGA.initialize(config.analytics.googleAnalytics.id, {
      debug: process.env.REACT_APP_ENV === 'development',
      gaOptions: {
        userId: global.user.user._id,
      },
    });
    window.gtag('config', config.analytics.googleAnalytics.id);

    realtime.connect();

    // Report sign-in
    api.trackEvent({
      category: 'Tracking',
      action: 'User',
      label: 'Login',
    });
  }

  console.debug('global.user: ', global.user);

  const rates = await api.queryCurrencyExchangeRates({ silent: true });

  if (!rates || !rates.success) {
    global.currencyExchangeRates = {};
  } else {
    global.currencyExchangeRates = rates.data;
  }

  const preferredCurrencyCodes = [
    'USD',
    'EUR',
    'CAD',
  ];

  if (global.user !== null && !preferredCurrencyCodes.includes(global.user.orgUnit.currencyCode)) {
    preferredCurrencyCodes.push(global.user.orgUnit.currencyCode);
  }

  global.preferredCurrencyCodes = preferredCurrencyCodes;

  ++global.userMeta.changeSequence;

  api.isReady = true;
};

export default api;
