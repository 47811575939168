console.debug('config: process.env: ', process.env);

const heapConfig = {
  development: {
    id: '3451888135',
  },
  staging: {
    id: '3451888135',
  },
  demo: {
    id: '3451888135',
  },
  production: {
    id: '394021883',
  },
};

const googleAnalyticsConfig = {
  development: {
    id: 'UA-178424290-2',
  },
  staging: {
    id: 'UA-178424290-2',
  },
  demo: {
    id: 'UA-178424290-2',
  },
  production: {
    id: 'UA-178424290-3',
  },
};

const DEFAULT_API_BASE_URL = ({
  development: `http://localhost:7070`,
  production: 'https://api.cargosys.net',
  staging: 'https://api.staging.cargosys.net',
  demo: 'https://api.demo.cargosys.net',
})[process.env.REACT_APP_NODE_ENV || 'development'];

const DEFAULT_REALTIME_BASE_URL = ({
  development: `ws://localhost:7070/secure/ws`,
  production: 'wss://api.cargosys.net/secure/ws',
  staging: 'wss://api.staging.cargosys.net/secure/ws',
  demo: 'wss://api.demo.cargosys.net/secure/ws',
})[process.env.REACT_APP_NODE_ENV || 'development'];

const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL,
  realtimeBaseUrl: process.env.REACT_APP_CARGOSYS_WEBSOCKET_URL || DEFAULT_REALTIME_BASE_URL,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyBwRp1e12ec1vOTtGiA4fcCt2sCUS78UYc',
  shipmentTracking: {
    sea: {
      mask: 'aaaa9999999',
      //urlTemplate: `https://sirius.searates.com/tracking?sealine=0&container={0}`,
      //urlTemplate: `https://realtracking.info/en/search?tid={0}`,
      urlTemplate: 'http://www.shippingline.org/track/?container={0}',
    },
    air: {
      mask: '999-99999999',
      urlTemplate: 'https://en.trackitonline.ru/?tn={0}',
      // urlTemplate: 'https://www.afklcargo.com/mycargo/shipment/detail/{0}',
      // urlTemplate: `https://realtracking.info/en/search?tid={0}`,
      // urlTemplate: 'https://t.17track.net/en#nums={0}',
      // urlTemplate: 'https://realtracking.info/en/search?tid={0}',
      // urlTemplate: 'https://track.aftership.com/?tracking-numbers={0}',
    }
  },
  acceptedFileTypes: [
    'image/*', 
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/html',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/*',
    'application/pdf',
    'application/rtf',
    'application/vnd.visio',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  analytics: {
    heap: heapConfig[process.env.REACT_APP_ENV || 'development'],
    googleAnalytics: googleAnalyticsConfig[process.env.REACT_APP_ENV || 'development'],
  },
  scripts: {
    authenticated: [
      { id: 'kompassious', src: 'https://player.kompassify.com/kompassifyPlayer.js?uuid=09ec274c-0386-445d-b9c9-364cb1a5031a' },
    ],
  },
};

export default config;
