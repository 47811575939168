import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Card, Container, Row, Col, Accordion } from 'react-bootstrap';

import api from '../lib/api';
import global, { globalPushModal } from '../lib/global';
import format from './format';
import input from './input';
import action from './action';
import val, { ShipmentValidationSchema } from './validation';
import { AttachmentsList } from './attachments.list';

import { CompactShipmentContent, CompactShipmentAddresses } from './shipment.view';

import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper, Box, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ShowFilesContract } from './ShowFilesContract';

export const getShipmentRequiredPriceFields = (shipment) => {
  const shippingTerms = shipment.shippingTerms;

  const genericMatrix = [
    { shippingTerms: [ 'D2D', 'D2P' ], field: 'originHaulage' },
    { shippingTerms: [ 'D2D', 'D2P', 'P2D', 'P2P' ], field: 'originCustomsClearance' },
    { shippingTerms: [ 'D2D', 'D2P',  ], field: 'originTerminalHandlingCharges' },
    { shippingTerms: [ 'D2D', 'D2P', 'P2D', 'P2P' ], field: 'originLocalCharges' },
    { shippingTerms: [ 'D2D', 'D2P', 'P2D', 'P2P' ], field: 'freight' },
    { shippingTerms: [ 'D2D', 'D2P', 'P2D', 'P2P' ], field: 'destLocalCharges' },
    { shippingTerms: [ 'D2D', 'D2P', 'P2D', 'P2P' ], field: 'destCustomsClearance' },
    { shippingTerms: [ 'D2D', 'P2D', 'P2P' ], field: 'destTerminalHandlingCharges' },
    { shippingTerms: [ 'D2D', 'P2D' ], field: 'destHaulage' },
  ];

  let fields = genericMatrix.filter(i => i.shippingTerms.includes(shippingTerms)).map(i => i.field);

  if (shipment.insurance) {
    fields.push('insurance');
  }

  if (shipment.incoterms === 'FOB') {
    fields = fields.filter(i => i !== 'originLocalCharges');
  }

  if (shipment.transportMode !== 'sea') {
    fields = fields.filter(i => !['originTerminalHandlingCharges', 'destTerminalHandlingCharges'].includes(i));
  }

  if (shipment.shipmentType === 'import') {
    // Import
    const originRequired = [ 'EXW' ].includes(shipment.incoterms);
    const destRequired =
      shipment.customsClearance &&
      [ 'CFR', 'CIF', 'CIP', 'CPT', 'EXW', 'FAS', 'FCA', 'FOB' ].includes(shipment.incoterms);

    if (!originRequired) {
      fields = fields.filter(i => !['originCustomsClearance'].includes(i));
    }

    if (!destRequired) {
      fields = fields.filter(i => !['destCustomsClearance'].includes(i));
    }
  } else {
    // Export
    const destRequired = [ 'DDP' ].includes(shipment.incoterms);
    const originRequired =
      shipment.customsClearance &&
      [ 'CFR', 'CIF', 'CIP', 'CPT', 'DAP', 'DDP', 'DPU', 'FAS', 'FCA', 'FOB' ].includes(shipment.incoterms);

    if (!originRequired) {
      fields = fields.filter(i => !['originCustomsClearance'].includes(i));
    }

    if (!destRequired) {
      fields = fields.filter(i => !['destCustomsClearance'].includes(i));
    }
  }

  return fields;
};

const getRequiredPriceFields = (value) => {
  console.debug('getRequiredPriceFields: ', value);

  return getShipmentRequiredPriceFields(value.body.shipment.body);
};

export const CompactShipmentPricesView = ({ value }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const i = value.body;

  const priceFields = getRequiredPriceFields(value);

  return <>
    <TableContainer component={Paper}>
      <Table border="0" style={{ width: '100%' }}>
        <TableBody>
          {priceFields.map((field, index) => {
            return <React.Fragment key={index}>
              <TableRow className={index > 0 ? 'border-top' : ''}>
                <TableCell className="border-right">
                  {t(`common:fields.shipmentContractPrices.${field}`)}:
                </TableCell>
                <TableCell className="text-right">
                  {console.log(field)}
                  <format.Money value={i.prices[field]} />
                </TableCell>
              </TableRow>
            </React.Fragment>;
          })}
          <TableRow className="border-top">
            <TableCell className="border-right">
              <strong>{t(`common:fields.shipmentContractPrices.total`)}:</strong>
            </TableCell>
            <TableCell className="text-right">
              <strong><format.Money value={i.prices.total} /></strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Button variant='contained' color="primary" onClick={handleClick} style={{marginTop:"30px"}}>Show files</Button>
    <ShowFilesContract files={i.files} handleClose={handleClick} isActive={isOpen}/>
  </>;
};

export const CompactShipmentPricesForm = ({ value, readOnly, onChange }) => {
  const { t } = useTranslation();
  const state = observable({ ...value });

  const i = state.body;
  const currencyCode = i.shipment.body.currencyCode;

  const priceFields = getRequiredPriceFields(value);

  useEffect(() => {
    console.log(i.prices);
  },[])

  return <>
    <val.Container value={state.validation}>
      <val.Container field="prices">
        <TableContainer component={Paper}>
          <Table border="0" style={{ width: '100%' }}>
            <TableBody>
              {priceFields.map((field, index) => {
                return <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      {t(`common:fields.shipmentContractPrices.${field}`)}
                    </TableCell>
                    <TableCell>
                      <input.Money asField={false} value={i.prices[field]} field={field} currencyCode={currencyCode} readOnly={readOnly} formGroup={false} onChange={val => {
                        state.body.prices[field] = val;

                        if (onChange) onChange(toJS(state));
                      }} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>;
              })}
              <TableRow>
                <TableCell>
                  <strong>{t(`common:fields.shipmentContractPrices.total`)}:</strong>
                </TableCell>
                <TableCell>
                  <Observer>{() => {
                    const total = {
                      currencyCode: global.user.orgUnit.currencyCode,
                      value: 0,
                    };

                    priceFields.forEach(field => {
                      const price = i.prices[field] || { value: 0, currencyCode: 'USD' };

                      const rate = global.currencyExchangeRates[price.currencyCode];

                      if (rate) {
                        total.value += (price.value / rate);
                      }
                    });

                    return <strong><format.Money value={total} /></strong>;
                  }}</Observer>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <val.Text field="total" /> */}
      </val.Container>
    </val.Container>
  </>;
};

export const CompactShipmentContractDetailsView = ({ value }) => {
  const hasDemurrage = !!value.body.shipment.body.shipmentContent.find(item => item.type === 'container');

  const onViewAttachments = React.useCallback(() => {
    globalPushModal({
      title: `${value.body.globalRecordNumber} - ${i18n.t('common:fields.shipmentContract.attachments')}`,
      body: <AttachmentsList shipmentContractId={value.body._id} autoSize={false} />,
    });
  }, [ value.body._id, value.body.globalRecordNumber ]);

  return <>
    <Container fluid>
      { value.body.attachments?.hasItems && <>
        <Row>
          <Col className="pl-0 pr-0">
            <Box mb={2}>
              <Alert severity="warning">
                <AlertTitle>{i18n.t('common:alert.bidHasAttachments.title')}</AlertTitle>
                <p>{i18n.t('common:alert.bidHasAttachments.prompt')}</p>
                <action.Action mode="text" onClick={onViewAttachments}>
                  {i18n.t('common:alert.bidHasAttachments.action.primary')}
                </action.Action>
              </Alert>
            </Box>
          </Col>
        </Row>
      </> }
      <Row>
        <Col className="pl-0">
          <h6>{i18n.t('common:fields.shipmentContract.freightCarrierName')}</h6>
          <format.Text value={value.body.freightCarrierName} feedback={true} />
        </Col>
        <Col>
          <h6>{i18n.t('common:fields.shipmentContract.transitDays')}</h6>
          <format.PositiveNumericString value={value.body.transitDays} feedback={true} /> days
        </Col>
        { hasDemurrage && <Col>
          <h6>{i18n.t('common:fields.shipmentContract.demurrageFreeDays')}</h6>
          <format.PositiveNumericString value={value.body.demurrageFreeDays} feedback={true} /> days
        </Col> }
        <Col className="pr-0">
          <h6>{i18n.t('common:fields.shipmentContract.expiresAt')}</h6>
          <format.DateText value={value.body.expiresAt} />
        </Col>
      </Row>
      <Row>
        <Col className="pl-0 pr-0">
          <h6 className="block-hsep-50">Comments</h6>
          <format.Text value={value.body.comments || 'N/A'} feedback={true} />
        </Col>
      </Row>
    </Container>
  </>;
};

export const CompactShipmentContractDetailsForm = ({ value, readOnly, onChange }) => {
  let state = value;

  const change = data => {
    if (onChange) onChange(data);
  };

  const setCarrierName = val => {
    state.body.freightCarrierName = val;
    change(state);
  };

  const setTransitDays = val => {
    let num = Number(val);
    if (isNaN(num)) num = 0;

    state.body.transitDays = val;
    change(state);
  };

  const setDemurrageFreeDays = val => {
    let num = Number(val);
    if (isNaN(num)) num = 0;

    state.body.demurrageFreeDays = val;
    change(state);
  };

  const setComments = val => {
    state.body.comments = val;
    change(state);
  };

  const setExpiresAt = val => {
    state.body.expiresAt = val;
    change(state);
  };

  const hasDemurrage = !!value.body.shipment.body.shipmentContent.find(item => item.type === 'container');

  return <>
    <val.Container value={state.validation}>
      <input.Field>
        <Container fluid>
          <Row>
            <Col className="pl-0">
              <input.FreightCarrierName
                field="freightCarrierName"
                label={i18n.t('common:fields.shipmentContract.freightCarrierName')}
                value={state.body.freightCarrierName}
                onChange={setCarrierName}
                readOnly={readOnly}
                transportMode={value.body.shipment.body.transportMode}
              />
            </Col>
            <Col className={!hasDemurrage && 'pr-0'}>
              <input.PositiveIntegerNumber field="transitDays" label={i18n.t('common:fields.shipmentContract.transitDays')} value={state.body.transitDays} onChange={setTransitDays} readOnly={readOnly} />
            </Col>
            { hasDemurrage && <Col className="pr-0">
              <input.PositiveIntegerNumber field="demurrageFreeDays" label={i18n.t('common:fields.shipmentContract.demurrageFreeDays')} value={state.body.demurrageFreeDays} onChange={setDemurrageFreeDays} readOnly={readOnly} />
            </Col> }
          </Row>
        </Container>
      </input.Field>
      <input.Field>
        <Container fluid>
          <Row>
            <Col className="pl-0">
              <input.DatePicker field="expiresAt" label={i18n.t('common:fields.shipmentContract.expiresAt')} value={state.body.expiresAt} onChange={setExpiresAt} readOnly={readOnly} />
            </Col>
          </Row>
        </Container>
        <input.Text field="comments" label={i18n.t('common:fields.shipmentContract.comments')} type="textarea" rows={5} value={state.body.comments || ''} onChange={setComments} readOnly={readOnly} />
      </input.Field>
    </val.Container>
  </>;
};

const Main = ({ value, marketplace = false, mode = 'default' }) => {
  const i = value.body;
  const shipment = i.shipment;

  return (
    <val.Container value={value.validation}>
      <Accordion>
        <Card>
          <Card.Header>
            <CompactShipmentContent value={shipment} />
          </Card.Header>
          <Card.Header>
            <CompactShipmentAddresses value={shipment} mode="horizontal" />
            <div>
              <action.ShipmentDetails value={shipment} variant="link" className="px-0" />
            </div>
          </Card.Header>
          <Card.Body>
            { mode === 'default'
              ? <>
                  <Container fluid>
                    <Row>
                      <Col className="pl-0">
                        <CompactShipmentContractDetailsView value={value} />
                        <val.SummaryContainer>
                          <div className="block-hsep-50">
                            <val.Summary schema={ShipmentValidationSchema} />
                          </div>
                        </val.SummaryContainer>
                      </Col>
                      <Col className="pr-0" lg={4}>
                        <Card>
                          <Card.Body>
                            <CompactShipmentPricesView value={value} />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </>
              : <>
                  <CompactShipmentContractDetailsView value={value} />
                  <CompactShipmentPricesView value={value} />
                </>
            }
          </Card.Body>
          <Card.Footer>
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col size="auto" className="px-0">
                  <action.UpdateShipmentContractState value={value} state="rejected" className="block-rsep-50" />
                  <action.DeleteContractDraft value={value} className="block-rsep-50" />
                </Col>
                <Col sm={true} className="pr-0 nowrap text-right">
                  <action.ShipmentContractDetails value={value} state="bid" className="block-vsep-50" />
                  <val.SuccessContainer>
                    <action.UpdateShipmentContractState value={value} state="bid" className="block-vsep-50" />
                  </val.SuccessContainer>
                </Col>
              </Row>
            </Container>
          </Card.Footer>
        </Card>
      </Accordion>
      <p></p>
    </val.Container>
  );
};

export default Main;
