import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'react-bootstrap';

import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Paper } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import MdDelete from '@material-ui/icons/DeleteForever';

import input from './input';
import action from './action';
import format from './format';
import config from '../lib/config';

import { globalPushModal, globalPopModal } from '../lib/global';

const openTrackingScreen = (urlTemplate, trackingCode) => {
  const url = urlTemplate.replace('{0}', trackingCode);

  globalPushModal({
    title: trackingCode,
    body: <iframe src={url} style={{ width: '100%', height: '70vh' }}></iframe>,
    footer: <action.ModalButtons />,
    options: {
      large: true,
    },
  });
};

export const ShipmentTrackingView = ({ shipmentTracking, shipment }) => {
  const { t } = useTranslation();

  const trackingUrlTemplate = (config.shipmentTracking[shipment.body.transportMode] || {}).urlTemplate;

  return <>
    <input.Field type="shipmentTracking" field="trackingCode" label={t('common:fields.shipmentTracking.trackingCode')}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className>{t('common:fields.shipmentTracking.trackingCode')}</TableCell>
              <TableCell className="text-right">{t('common:actions.trackShipment.label')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipmentTracking.body.map((i, itemIndex) => {
              const localHandleClick = () => {
                openTrackingScreen(trackingUrlTemplate, i.trackingCode);
              };

              return <TableRow key={itemIndex}>
                <TableCell xs>
                  {i.trackingCode}
                </TableCell>
                <TableCell className="text-right">
                  { trackingUrlTemplate && <action.Action mode="button" action="trackShipment" onClick={localHandleClick}>
                    {t('common:actions.trackShipment.label')}
                  </action.Action> }
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </input.Field>
  </>;
};

export const ShipmentContractTrackingForm = ({ shipmentTracking, shipment, shipmentContract, onChange }) => {
  const { t } = useTranslation();
  const [ state, setState ] = React.useState({ ...shipmentTracking });

  const trackingUrlTemplate = (config.shipmentTracking[shipment.body.transportMode] || {}).urlTemplate;

  const createEmpty = () => {
    return {
      trackingCode: '',
    };
  };

  if (!state.body.length) {
    state.body.push(createEmpty());
  }

  const change = (updateState = false) => {
    if (onChange) onChange({ ...state });

    if (updateState) {
      setState({ ...state });
    }
  };

  const handleChange = (index, val) => {
    state.body[index].trackingCode = val;

    //change(false);
  };
  
  const handleAddItem = () => {
    state.body.push(createEmpty());

    change(true);
  };

  const handleRemoveItem = (index) => {
    state.body = state.body.splice(index, 1);

    change(true);
  }

  return <>
    <input.Field type="shipmentTracking" field="trackingCode" label={t('common:fields.shipmentTracking.trackingCode', { count: state.body.length })}>
      <Container fluid>
        {state.body.map((i, itemIndex) => {
          const localHandleChange = (val) => {
            handleChange(itemIndex, val);
          };

          const localHandleRemove = () => {
            handleRemoveItem(itemIndex);
          };

          const localHandleTrack = () => {
            openTrackingScreen(trackingUrlTemplate, i.trackingCode);
          };

          return <Row key={itemIndex} className="justify-content-md-center">
            <Col className="col-margin-tb pl-0">
              <input.TrackingCode
                transportMode={shipment.body.transportMode}
                value={i.trackingCode}
                onChange={localHandleChange}
                required
              />
            </Col>
            <Col className="col-margin-tb col-align-middle" xs="auto" sm="auto" lg="auto" xl="auto" size="auto">
              { trackingUrlTemplate && <action.Action mode="button" action="trackShipment" onClick={localHandleTrack}>
                {t('common:actions.trackShipment.label')}
              </action.Action> }
            </Col>
            <Col className="col-margin-tb pl-0 pr-0 col-align-middle" xs="auto" sm="auto" lg="auto" xl="auto" size="auto">
              <action.Action action="removeListItem" onClick={localHandleRemove}>
                <MdDelete />
              </action.Action>
            </Col>
          </Row>;
        })}
      </Container>
    </input.Field>
    <action.Action mode="button" action="addListItem" onClick={handleAddItem}>
      {t('common:actions.addListItem.label')}
    </action.Action>
  </>;
};
