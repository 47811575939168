import React from 'react';
import { observable } from 'mobx';
import { v1 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';

export const ModalCleanupContext = React.createContext(null);

export const ModalCleanupDetector = React.memo(({ onCleanup }) => {
  const modal = React.useContext(ModalCleanupContext);

  React.useEffect(() => {
    return () => {
      if (modal && onCleanup) {
        if (!modal.__cleanupHandlers.find(i => i == onCleanup)) {
          modal.__cleanupHandlers.push(onCleanup);
        }
      }
    };
  }, [ onCleanup, modal ]);

  return <></>;
});

const global = {
  user: observable.box(null),
  currencyExchangeRates: observable({}),
  preferredCurrencyCodes: observable([]),
  userMeta: observable({
    changeSequence: 0,
  }),
  time: observable({
    server: new Date(),
    client: new Date(),
  }),
  modals: [],
  modalsMeta: observable({
    changeSequence: 0,
  }),
  toasts: observable([]),
  navControls: observable({}),
  portalControls: observable({}),
  reports: observable([]),
};

export const globalSetPortalControl = ({ key, control }) => {
  global.portalControls[key] = control;
};

export const globalRemovePortalControl = ({ key }) => {
  delete global.portalControls[key];
};

export const globalSetNavControl = ({ key, control }) => {
  global.navControls[key] = control;
};

export const globalRemoveNavControl = ({ key }) => {
  delete global.navControls[key];
};

export const globalPushModal = ({
  title = null,
  body,
  footer = null,
  toolbar = null,
  titleActions = null,
  onHide,
  options = { large: false, fixedHeight: false, fullWidth: false, smallWidth: false }
}) => {
  if (!body) {
    throw new Error('body argument is required');
  }

  global.modals.push({
    title: title,
    titleActions: titleActions,
    body: body,
    footer: footer,
    toolbar: toolbar,
    options: options,
    onHide: onHide,
    __cleanupHandlers: [],
  });

  ++global.modalsMeta.changeSequence;
};

export const globalPopModal = () => {
  const modal = global.modals.pop();

  if (!modal) return;

  if (modal && modal.__cleanupHandlers) {
    for (const handler of modal.__cleanupHandlers) {
      handler();
    }
  }

  if (modal.onHide) modal.onHide();

  ++global.modalsMeta.changeSequence;
};

export const globalPopAllModals = () => {
  while (global.modals.length > 0) {
    globalPopModal();
  }
};

export const GlobalNavControl = ({ children }) => {
  const [ id ] = React.useState(uuid());

  React.useEffect(() => {
    globalSetNavControl({ key: id, control: <>{children}</> });

    return () => {
      globalRemoveNavControl({ key: id });
    };
  });

  return <></>;
};

export const GlobalPortalControl = ({ anchor, children }) => {
  React.useEffect(() => {
    globalSetPortalControl({ key: anchor, control: <>{children}</> });

    return () => {
      globalRemovePortalControl({ key: anchor });
    };
  });

  return <></>;
};

export default global;
