import React from 'react';
import i18n from 'i18next';
import { Media, Card, Button, Container, Row, Col, Accordion } from 'react-bootstrap';
import { toJS } from 'mobx';

import api from '../lib/api';
import format from './format';
import input from './input';
import action from './action';
import val from './validation';

import { CompactShipmentContent, CompactShipmentAddresses } from './shipment.view';
import { CompactShipmentContractDetailsForm, CompactShipmentPricesForm } from './shipmentContract.view';

const Main = ({ value, readOnly: forceReadOnly, onChange }) => {
  // Create a deep copy of the data so we do not update parent
  // down the road this will have to be replaced by a more efficient mechanism
  let state = toJS(value);

  const readOnly = forceReadOnly || state.body.readOnly || ![ 'draft', 'bid' ].includes(state.body.state);

  const i = state.body;
  const shipment = i.shipment;

  const handleChange = val => {
    const savedPrices = state.body.prices;

    state = val;
    
    state.body.prices = savedPrices;

    if (onChange) onChange({ ...state });
  };

  const handlePricesChange = val => {
    state.body.prices = val.body.prices;

    if (onChange) onChange({ ...state });
  }

  return (<>
    <val.Container value={state.validation || {}}>
      <CompactShipmentContent value={shipment} />
      <p></p>
      <hr />
      <p></p>
      <CompactShipmentAddresses value={shipment} mode="vertical" />
      <p></p>
      <hr />
      <p></p>
      <CompactShipmentContractDetailsForm value={state} readOnly={readOnly} onChange={handleChange} />
      <input.Field label={i18n.t('common:fields.bid.prices')}>
        <CompactShipmentPricesForm value={state} readOnly={readOnly} onChange={handlePricesChange} />
      </input.Field>
    </val.Container>
  </>);
};

export default Main;
