import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { css, cx } from 'emotion';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';

import {
  Button, Menu, MenuItem, Paper, Typography, TableContainer as MuiTableContainer,
  AppBar, Tabs as MuiTabs, Tab as MuiTab, Box, Popover, TablePagination,
  Breadcrumbs as MuiBreadcrumbs,
} from '@material-ui/core';

import {
  Pagination,
  Skeleton,
} from '@material-ui/lab';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import noDataIllustration from '../assets/illustrations/noData.svg';

import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import api from '../lib/api';
import history from '../lib/history';
import { globalPushModal } from '../lib/global';
import action from './action';
import format from './format';

const styles = {
  container: (variant, hasTitle) => css({
    display: 'grid',
    gridTemplateRows: hasTitle ? 'min-content auto' : 'auto',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  containerTitle: variant => css({
  }),
};

export const Container = ({ title = null, bgcolor = null, variant, onClick, children, contentClassName, elevation = 3, autoSize = false, autoScroll = false, ...rest }) => {
  const Content = () => {
    const Wrapper = ({ children }) => {
      if (autoScroll) {
        return <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflowY: 'auto' }}>
          {children}
        </div>;
      } else {
        return <>{children}</>;
      }
    };

    return <>
      { title && <Typography variant="h6" gutterBottom>
        {title}
      </Typography> }
      <Paper elevation={elevation} variant={variant} style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
        <Box style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'stretch', width: '100%', position: 'relative' }} bgcolor={bgcolor}>
          <Wrapper>
            {children}
          </Wrapper>
        </Box>
      </Paper>
    </>;
  };

  if (autoSize) {
    const style = {
      position: 'absolute',
      top: 0, left: 0, right: 0, bottom: 0,
    };
    if (onClick) {
      style.cursor = 'pointer';
    }

    return <div style={{ position: 'relative', width: '100%' }}>
      <Box
        {...rest}
        className={cx(styles.container(variant, !!title), css(style))}
        onClick={onClick}
      >
        <Content />
      </Box>
    </div>;
  } else {
    return <div
      {...rest}
      className={cx(styles.container(variant, !!title),
      onClick ? css({ cursor: 'pointer' }) : null)}
      onClick={onClick}
    >
      <Content />
    </div>;
  }
};

export const NavPopover = ({ label, children, ...rest }) => {
  const popupState = usePopupState({ variant: 'popover' })

  React.useEffect(() => {
    const unlisten = history.listen(({ action, location }) => {
      if (popupState.isOpen) {
        popupState.close();
      }
    });

    return () => {
      unlisten();
    };
  });

  return <>
    <Button variant="text" color="inherit" {...bindTrigger(popupState)} {...rest}>
      {label}
      {' '}
      <ArrowDropDownIcon />
    </Button>
    <Popover
      {...bindMenu(popupState)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {children}
    </Popover>
  </>;
};

export const NavMenu = ({ label, id, items }) => {
  const popupState = usePopupState({ variant: 'popover' })

  React.useEffect(() => {
    const unlisten = history.listen(({ action, location }) => {
      if (popupState.isOpen) {
        popupState.close();
      }
    });

    return () => {
      unlisten();
    };
  });

  return <>
    <Button variant="text" color="inherit" {...bindTrigger(popupState)} id={id}>
      {label}
      {' '}
      <ArrowDropDownIcon />
    </Button>
    <Menu
      {...bindMenu(popupState)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      { items.map((item, index) => {
        const handleClick = () => {
          popupState.close();

          if (item.onClick) {
            item.onClick();
          }

          if (item.to) {
            api.navigateUrl(item.to);
          }
        };

        if (item) {
          return <MenuItem key={index} onClick={handleClick}>
            <Box
              display="grid"
              gridTemplateColumns="auto max-content"
              alignItems="center"
            >
              <Box display="inline">
                {item.text || item?.name}
              </Box>
              <Box ml={!!item.icon ? 2 : 0} display="inline" style={{ opacity: 0.4, fontSize: '1em' }}>
                {item.icon}
              </Box>
            </Box>
          </MenuItem>;
        } else {
          return <hr key={index} style={{ height: '2px', overflow: 'hidden', margin: '4px' }} />;
        }
      }) }
    </Menu>
  </>;
};

export const TableContainer = ({
  autoSize = false,
  header = null,
  footer = null,
  children,
  ...rest
}) => {
  const Content = ({ children }) => {
    return <>
      <Box
        display="grid"
        gridTemplateRows="min-content auto min-content"
        width="100%"
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Box width="100%">
          { header && <Box pb={2}>{header}</Box> }
        </Box>
        <Box width="100%" display="flex" alignItems="stretch" justifyContent="stretch">{children}</Box>
        <Box width="100%">
          { footer && <Box pt={2}>{footer}</Box> }
        </Box>
      </Box>
    </>;
  };

  if (!autoSize) {
    return <Content>
      <MuiTableContainer component={Paper} style={{ width: '100%' }} {...rest}>
        {children}
      </MuiTableContainer>
    </Content>;
  } else {
    return <Content>
      <div style={{ position: 'relative', width: '100%' }}>
        <MuiTableContainer component={Paper} style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }} {...rest}>
          {children}
        </MuiTableContainer>
      </div>
    </Content>;
  }
};

export const MainContentContainer = ({ autoSize = true, children, scroll = false, ...rest }) => {
  if (autoSize) {
    return <div style={{ position: 'relative', width: '100%' }}>
      <Box
        p={1}
        style={{
          overflowY: scroll ? "scroll" : "hidden",
          position: 'absolute',
          left: 0, top: 0, right: 0, bottom: 0,
          display: 'flex', alignItems: 'stretch', justifyContent: 'stretch'
        }}
        {...rest}
      >
        {children}
      </Box>
    </div>
  }

  return <Box p={1} width="100%" {...rest}>
    {children}
  </Box>;
};

// tabs: [ { label, content }]
export const Tabs = ({ tabs = [], activeIndex = 0 }) => {
  const uiState = observable({
    activeIndex: 0,
  });

  const containerRef = React.useRef();

  React.useEffect(() => {
    setActiveIndex(activeIndex);
  });

  const setActiveIndex = (activeIndex) => {
    uiState.activeIndex = activeIndex;

    containerRef.current.childNodes.forEach((node, index) => {
      node.style.display = index === activeIndex ? 'flex' : 'none';

      if (index === activeIndex) {
        const element = getTabContent(tabs[index]);

        // TODO: problematic. Causing a warning
        ReactDOM.render(element, node);
      }
    });
  };

  const getTabContent = (tab) => {
    if (typeof tab.content === 'function') {
      return <>{tab.content()}</>;
    } else {
      return <>{tab.content}</>;
    }
  };

  return <>
    <div
      style={{ display: 'grid', gridTemplateRows: 'min-content auto', width: '100%' }}
    >
      <AppBar position="static">
        <Observer>{() => {
          return <MuiTabs value={uiState.activeIndex} onChange={(_, newValue) => setActiveIndex(newValue)}>
            { tabs.map((i, index) => <MuiTab label={i.label} key={index} value={index} /> )}
          </MuiTabs>;
        }}</Observer>
      </AppBar>
      <div ref={containerRef} style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'stretch', width: '100%' }}>
        { tabs.map((i, index) => <>
          <div key={index} style={{ display: 'none', justifyContent: 'stretch', alignItems: 'stretch', width: '100%' }}>
            { (typeof i.content !== 'function') && getTabContent(i)}
          </div>
        </> )}
      </div>
    </div>
  </>;
};

export const TablePager = ({ count, skip = 0, limit = 10, onChange }) => {
  const handleChangePage = (e, page) => {
    if (onChange) {
      onChange({
        skip: page * limit,
        limit: limit,
      });
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const rowsPerPage = parseInt(e.target.value);

    if (onChange) {
      onChange({
        skip: 0,
        limit: rowsPerPage,
      });
    }
  };

  return <Observer>{() => {
    const pageIndex = Math.floor(skip / limit);

    return <>
      <TablePagination
        color="primary"
        component="div"
        count={count}
        page={pageIndex}
        //rowsPerPageOptions={[10, 25, 50, 75, 100]}
        rowsPerPage={limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //showFirstButton
        //showLastButton
      />
    </>;
  }}</Observer>;
};

export const Breadcrumbs = ({ parent = null, location }) => {
  return <MuiBreadcrumbs>
    { parent && <Typography variant="subtitle2" color="primary">{i18n.t(`common:navigation.${parent}.text`)}</Typography> }
    <Typography color="primary" variant="subtitle2">{i18n.t(`common:navigation.${location}.text`)}</Typography>
  </MuiBreadcrumbs>
};

export const FormattedBreadcrumbs = ({ parent = null, location }) => {
  return <MuiBreadcrumbs>
    { parent && <Typography variant="subtitle2" color="primary">{parent}</Typography> }
    <Typography color="primary" variant="subtitle2">{location}</Typography>
  </MuiBreadcrumbs>
};

export const DocumentViewer = ({ fileName, fileUrl }) => {
  const uiState = observable({
    loading: true,
    iframeSrc: null,
  });

  React.useEffect(() => {
    if (!fileUrl || !fileName) return;
    const fileExtension = fileName.split('.').pop();
    const encodedFileUrl = encodeURIComponent(fileUrl);

    switch (fileExtension) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "pdf":
        window.fetch(fileUrl).then(response => {
          if (!response.ok) {
            uiState.loading = false;
          } else {
            return response.blob().then(blob => {
              uiState.iframeSrc = URL.createObjectURL(blob);

              uiState.loading = false;
            });
          }
        }).catch(() => uiState.loading = false);
        break;

      case "ppt":
      case "pptx":
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
        uiState.iframeSrc = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedFileUrl}`;
        uiState.loading = false;
        break;

      default:
        uiState.iframeSrc = `https://docs.google.com/viewer?url=${encodedFileUrl}&embedded=true&nc=${new Date().valueOf()}`;
        uiState.loading = false;
        break;
    }  
  });

  return <Observer>{() => {
    if (uiState.loading) return <Box display="flex" justifyContent="center" alignItems="center" width="100%">
      <format.Loading />
    </Box>;

    if (uiState.iframeSrc) {
      const style = {
        border: 'none',
        width: '100%',
      };
  
      return <iframe src={uiState.iframeSrc} style={style}></iframe>;
    } else {
      return <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <Typography variant="body1">
          {i18n.t('common:documentView.messages.notSupported')}
        </Typography>
      </Box>;
    } 
  }}</Observer>;
};

export const globalPushAttachmentContentViewerModal = (attachmentContent) => {
  globalPushModal({
    title: attachmentContent?.name,
    titleActions: <Box width="100%" display="flex" justifyContent="flex-end">
      <action.DownloadAttachment content={attachmentContent} color="secondary" />
    </Box>,
    footer: <action.ModalButtons />,
    body: <DocumentViewer fileName={attachmentContent?.name} fileUrl={attachmentContent.url} />,
    options: {
      maxWidth: 'lg',
      fixedHeight: true,
    },
  })
};

export const DynamicColumnLayout = ({
  minColumnWidth = 250,
  children
}) => {
  const containerRef = React.useRef();
  const [ numColumns, setNumColumns ] = React.useState(null);

  const handleResize = React.useCallback(() => {
    const rect = containerRef.current.getBoundingClientRect();

    const wantNumCols = Math.max(1, Math.floor(rect.width / minColumnWidth));

    if (wantNumCols !== numColumns) {
      setNumColumns(wantNumCols);
    }
  }, [ numColumns, minColumnWidth ]);

  React.useEffect(() => {
    handleResize();

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      // Cleanup
      resizeObserver.disconnect();
    };
  }, []);

  const columns = [];
  for (let i = 0; i < numColumns; ++i) {
    columns.push('1fr')
  };

  return <div style={{ width: '100%' }} ref={containerRef}>
    <Box
      display="grid"
      gap="1em"
      gridGap="1em"
      gridTemplateColumns={columns.join(' ')}
      gridAutoRows="min-content"
    >
      {children}
    </Box>
  </div>;
};

export const PrecisedColumnLayout = ({
  minColumnWidth = 250,
  wantedNumberOfItems = 4,
  children
}) => {
  const containerRef = React.useRef();
  const [ numColumns, setNumColumns ] = React.useState(wantedNumberOfItems);

  const handleResize = React.useCallback(() => {
    const rect = containerRef.current.getBoundingClientRect();

    const wantNumCols = Math.max(1, Math.floor(rect.width / minColumnWidth));

    if (wantNumCols !== numColumns && wantNumCols <= wantedNumberOfItems)
      setNumColumns(wantNumCols);
    else if (wantNumCols > wantedNumberOfItems)
      setNumColumns(wantedNumberOfItems);
  }, [ numColumns, minColumnWidth ]);

  React.useEffect(() => {
    handleResize();

    const resizeObserver = new ResizeObserver(() => handleResize());
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();;
  }, []);

  const columns = [];
  for (let i = 0; i < numColumns; ++i) {
    columns.push('1fr')
  };

  return <div style={{ width: '100%', marginBottom: "1em"}} ref={containerRef}>
    <Box
      display="grid"
      gap="1em"
      gridGap="1em"
      gridTemplateColumns={columns.join(' ')}
      gridAutoRows="min-content"
    >
      {children}
    </Box>
  </div>;
};

export const TableSkeleton = ({}) => {
  return <Box p={1}>
    <Typography variant="h3">
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
    </Typography>
  </Box>;
};

export const EmptyTable = ({}) => {
  return <Box
    style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <img src={noDataIllustration} style={{ maxHeight: '50vh', filter: 'grayscale(100%)' }} />
    <Box position="absolute" style={{ top: 0, left: 0, right: 0, bottom: 0 }} display="flex" justifyContent="center" alignItems="center">
      <Paper style={{ opacity: 0.8 }}>
        <Box p={4}>
          <Typography variant="subtitle2">
            { i18n.t('common:tables.messages.emptyDataSet') }
          </Typography>
        </Box>
      </Paper>
    </Box>
  </Box>;
};

export default {
  globalPushAttachmentContentViewerModal,
  Container,
  NavPopover,
  NavMenu,
  TableContainer,
  TableSkeleton,
  EmptyTable,
  MainContentContainer,
  TablePager,
  Tabs,
  Breadcrumbs,
  FormattedBreadcrumbs,
  DocumentViewer,
  DynamicColumnLayout,
  PrecisedColumnLayout,
};
