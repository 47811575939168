import React from 'react';
import i18n from 'i18next';
import input from './input';

import {
  Typography, Box,
} from '@material-ui/core';

export const CompleteShipmentContractForm = ({ onChange }) => {
  const handleChangeAttachment = (val) => {
    if (onChange) {
      onChange({
        invoice: val,
      });
    }
  };

  return <div>
    <Typography variant="body1">
      {i18n.t('common:forms.completeShipmentContract.prompt').split(/\n/g).map(i => {
        return <>{i}<br /></>
      })}
    </Typography>
    <Box mt={1}>
      <input.Field>
        <input.Attachment
          label={i18n.t('common:forms.completeShipmentContract.fields.invoice')}
          required={true}
          mode="file"
          onChange={handleChangeAttachment}
        />
      </input.Field>
    </Box>
  </div>;
};
