import React from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { css } from 'emotion';

import { Box, Paper, Typography } from '@material-ui/core';

import global, { GlobalPortalControl, GlobalNavControl } from '../lib/global';
import api from '../lib/api';

import action from './action';
import format from './format';
import ui from './ui';
import chart from './chart';

import GaugeIcon from '@material-ui/icons/Speed';

import waitForAnalyticsIllustration from '../assets/illustrations/visualData1.svg';

const tablet = '@media (max-width: 768px)';
const mobile = '@media (max-width: 700px)';

const styles = {
  tablesContainer: css({
    width: '100%',
    display: 'grid',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    alignContent: 'stretch',
    rowGap: '1em',
    gridRowGap: '1em',
    columnGap: '1em',
    gridColumnGap: '1em',

    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(auto-fill)',
    [tablet]: {
      gridTemplateColumns: '100%',
    },
    [mobile]: {
      gridTemplateColumns: '100%',
    },
  }),

  tableContainer: css({
    //maxHeight: '50vh',
    minHeight: '10rem',
  }),

  table: css({
    //minHeight: '40vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    '& div': {
      width: '100% !important',
      height: '100% !important',
    },
  }),

  gaugesContainer: css({
    display: 'grid',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    alignContent: 'stretch',
    columnGap: '1em',
    gridColumnGap: '1em',
    rowGap: '1em',
    gridRowGap: '1em',

    gridTemplateColumns: 'repeat(5, 1fr)',
    [tablet]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [mobile]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },

    '& *': {
      //textAlign: 'center',
    }
  }),

  gaugeContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'flex-start',
    padding: 0,
    width: '100%',
  }),

  gaugeTitle: css({
    fontSize: '0.8em',
    fontWeight: 'bold',
  }),

  gaugeValue: css({
    fontSize: '1em',
    fontWeight: 'bold',
  })
};

const Gauge = ({ label, variant, children, onClick }) => {
  return <ui.Container variant={variant} onClick={onClick}>
    <Box
      p={1}
      display="grid"
      gridTemplateColumns="min-content auto"
      justifyContent="stretch"
      alignItems="stretch"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="stretch"
        pr={1}
      >
        <div className="icon-container">
          <GaugeIcon />
        </div>
      </Box>
      <div className={styles.gaugeContainer}>
        <div className={styles.gaugeTitle}>{label}</div>
        <div className={styles.gaugeValue}>
          {children}
        </div>
      </div>
    </Box>
  </ui.Container>;
};

const ChartContainer = ({ children }) => {
  return <Box>
    <ui.Container variant="outlined">
      <Box width="100%" className={css({
        '@media screen and (max-height: 900px)': {
          zoom: 0.8,
        },
        '@media screen and (max-width: 1800px)': {
          zoom: 0.8,
        }
      })}>
        {children}
      </Box>
    </ui.Container>
  </Box>;
};

const DataWrapper = ({ data, children }) => {
  if (data && data.length > 0) {
    return <><Box width="100%">{children}</Box></>;
  } else {
    return <>
      <Box p={2} display="flex" justifyContent="center" alignItems="stretch" width="100%" position="relative" style={{ overflow: 'hidden' }}>
        <img src={waitForAnalyticsIllustration} style={{ filter: 'grayscale(100%)' }} />
        <Box position="absolute" style={{ top: 0, left: 0, right: 0, bottom: 0 }} display="flex" justifyContent="center" alignItems="center">
          <Paper>
            <Box p={4}>
              <Typography variant="subtitle2">
                { i18n.t('common:charts.messages.emptyDataSet') }
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>;
  }
};

const HistoricData = React.memo(({ epoch = 'month' }) => {
  const [ history, setHistory ] = React.useState(null);

  React.useEffect(() => {
    api.queryOrgUnitHistory(epoch).then(response => {
      if (!response.success) return;

      setHistory(response.data);
    });
  }, [ epoch ]);

  if (!history) return <></>;

  const mapped = history.map(i => {
    const acc = i.body.accumulation || {};

    let tick = i.body._id;

    switch (epoch) {
      case 'month':
        tick = moment(i.body.periodStartTimestamp).format('MMM YYYY');
        break;
      case 'week':
        tick = moment(i.body.periodStartTimestamp).format('W, YY');
        break;
      case 'day':
        tick = moment(i.body.periodStartTimestamp).format('MMM DDDo');
        break;
    }

    const rating = acc.rating || {};
    const totalShipmentsCount = acc.totalShipmentsCount || {};
    const totalShipmentContractsCount = acc.totalShipmentContractsCount || {};
    const incoming = acc.incoming || {};

    return {
      _id: i.body._id,
      tick: tick,
      rating_avgRating: rating.ratingsCount ? acc.rating.ratingsTotalScore / acc.rating.ratingsCount : 0,
      rating_ratingsCount: rating.ratingsCount || 0,
      rating_ratingsTotalScore: rating.ratingsTotalScore || 0,
      totalShipmentsCount_draft: totalShipmentsCount.draft || 0,
      totalShipmentsCount_rfp: totalShipmentsCount.rfp || 0,
      totalShipmentsCount_active: totalShipmentsCount.active || 0,
      totalShipmentsCount_completed: totalShipmentsCount.completed || 0,
      totalShipmentsCount_expired: totalShipmentsCount.expired || 0,
      totalShipmentsCount_cancelled: totalShipmentsCount.cancelled || 0,
      totalShipmentsCount_dispute: totalShipmentsCount.dispute || 0,
      totalShipmentsCount_closed: totalShipmentsCount.closed || 0,
      incoming_rfp: incoming.rfp || 0,
      totalShipmentContractsCount_draft: totalShipmentContractsCount.draft || 0,
      totalShipmentContractsCount_bid: totalShipmentContractsCount.bid || 0,
      totalShipmentContractsCount_contract: totalShipmentContractsCount.contract || 0,
      totalShipmentContractsCount_archived: totalShipmentContractsCount.archived || 0,
      totalShipmentContractsCount_expired: totalShipmentContractsCount.expired || 0,
      totalShipmentContractsCount_rejected: totalShipmentContractsCount.rejected || 0,
      totalShipmentContractsCount_completed: totalShipmentContractsCount.completed || 0,
      totalShipmentContractsCount_dispute: totalShipmentContractsCount.dispute || 0,
      totalShipmentContractsCount_closed: totalShipmentContractsCount.closed || 0,
      totalShipmentContractsCount_missed: totalShipmentContractsCount.missed || 0,
      totalWonShipmentContractsEstValue: acc.totalWonShipmentContractsEstValue || 0,
      totalAcceptedShipmentContractsEstValue: acc.totalAcceptedShipmentContractsEstValue || 0,
      totalMoneySavingsFromAvgEstValue: acc.totalMoneySavingsFromAvgEstValue || 0,
      totalMoneySavingsFromMaxEstValue: acc.totalMoneySavingsFromMaxEstValue || 0,
      totalShipmentsTransportModesCount_air: (acc.totalShipmentsTransportModesCount || {}).air || 0,
      totalShipmentsTransportModesCount_sea: (acc.totalShipmentsTransportModesCount || {}).sea || 0,
      totalShipmentsTransportModesCount_land: (acc.totalShipmentsTransportModesCount || {}).land || 0,
      totalShipmentsShipmentTypesCount_import: (acc.totalShipmentsShipmentTypesCount || {}).import || 0,
      totalShipmentsShipmentTypesCount_export: (acc.totalShipmentsShipmentTypesCount || {}).export || 0,
      totalShipmentsShippingTermsCount_D2D: (acc.totalShipmentsShippingTermsCount || {}).D2D || 0,
      totalShipmentsShippingTermsCount_P2P: (acc.totalShipmentsShippingTermsCount || {}).P2P || 0,
      totalShipmentsShippingTermsCount_D2P: (acc.totalShipmentsShippingTermsCount || {}).D2P || 0,
      totalShipmentsShippingTermsCount_P2D: (acc.totalShipmentsShippingTermsCount || {}).P2D || 0,
      totalShipmentsIncotermsCount_EXW: (acc.totalShipmentsIncotermsCount || {}).EXW || 0,
      totalShipmentsIncotermsCount_FCA: (acc.totalShipmentsIncotermsCount || {}).FCA || 0,
      totalShipmentsIncotermsCount_CPT: (acc.totalShipmentsIncotermsCount || {}).CPT || 0,
      totalShipmentsIncotermsCount_CIP: (acc.totalShipmentsIncotermsCount || {}).CIP || 0,
      totalShipmentsIncotermsCount_DAP: (acc.totalShipmentsIncotermsCount || {}).DAP || 0,
      totalShipmentsIncotermsCount_DPU: (acc.totalShipmentsIncotermsCount || {}).DPU || 0,
      totalShipmentsIncotermsCount_DDP: (acc.totalShipmentsIncotermsCount || {}).DDP || 0,
      totalShipmentsIncotermsCount_FAS: (acc.totalShipmentsIncotermsCount || {}).FAS || 0,
      totalShipmentsIncotermsCount_FOB: (acc.totalShipmentsIncotermsCount || {}).FOB || 0,
      totalShipmentsIncotermsCount_CFR: (acc.totalShipmentsIncotermsCount || {}).CFR || 0,
      totalShipmentsIncotermsCount_CIF: (acc.totalShipmentsIncotermsCount || {}).CIF || 0,
      totalShipmentsTransportTypesCount_direct: (acc.totalShipmentsTransportTypesCount || {}).direct || 0,
      totalShipmentsTransportTypesCount_transshipment: (acc.totalShipmentsTransportTypesCount || {}).transshipment || 0,
      totalShipmentsTransportTypesCount_any: (acc.totalShipmentsTransportTypesCount || {}).any || 0,
    };
  });

  const t = (key) => {
    return i18n.t(`common:dashboard.orgUnit.accumulation.${key}`);
  };

  const CHART_HEIGHT = 300;
  const CHART_WIDTH = 600;

  const formatCurrency = num => {
    return `${Math.floor(num).toLocaleString()} ${global.user.orgUnit.currencyCode}`;
  };

  return <>
    { api.hasModule('forwarder') && <>
      <ui.Container autoSize autoScroll variant="outlined" title={i18n.t('common:dashboard.section.forwarder.charts')} contentClassName={styles.tableContainer}>
        <ui.MainContentContainer autoSize={false}>
          <DataWrapper data={mapped}>
            <ui.DynamicColumnLayout minColumnWidth={CHART_WIDTH}>
              <ChartContainer>
                <chart.BarChart
                  title={`${i18n.t('common:dashboard.charts.forwarder.securedRevenue')} (${global.user.orgUnit.currencyCode})`}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalWonShipmentContractsEstValue', name: t('totalWonShipmentContractsEstValue'), format: formatCurrency },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.BarChart
                  title={i18n.t('common:dashboard.charts.forwarder.activity')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'incoming_rfp', name: t('incoming.rfp') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_bid', name: t('totalShipmentContractsCount.bid') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_contract', name: t('totalShipmentContractsCount.contract') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_closed', name: t('totalShipmentContractsCount.closed') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.StackedAreaChart
                  title={i18n.t('common:dashboard.charts.forwarder.avgRating')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'rating_avgRating', name: t('rating.avgRating') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.FullStackedAreaChart
                  title={i18n.t('common:dashboard.charts.forwarder.qualityOfService')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_completed', name: t('totalShipmentContractsCount.completed') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_dispute', name: t('totalShipmentContractsCount.dispute') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.FullStackedAreaChart
                  title={i18n.t('common:dashboard.charts.forwarder.marketFit')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_draft', name: t('totalShipmentContractsCount.draft') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_archived', name: t('totalShipmentContractsCount.archived') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.FullStackedAreaChart
                  title={i18n.t('common:dashboard.charts.forwarder.opportunityAnalysis')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_archived', name: t('totalShipmentContractsCount.archived') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_missed', name: t('totalShipmentContractsCount.missed') },
                    { argumentField: 'tick', valueField: 'totalShipmentContractsCount_bid', name: t('totalShipmentContractsCount.bid') },
                  ]}
                />
              </ChartContainer>
            </ui.DynamicColumnLayout>
          </DataWrapper>
        </ui.MainContentContainer>
      </ui.Container>
    </> }
    { api.hasModule('initiator') && <>
      <ui.Container autoSize autoScroll variant="outlined" title={i18n.t('common:dashboard.section.initiator.charts')} contentClassName={styles.tableContainer}>
        <ui.MainContentContainer autoSize={false}>
          <DataWrapper data={mapped}>
            <ui.DynamicColumnLayout minColumnWidth={CHART_WIDTH}>
              <ChartContainer>
                <chart.StackedBarChart
                  title={`${i18n.t('common:dashboard.charts.initiator.finance')} (${global.user.orgUnit.currencyCode})`}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalAcceptedShipmentContractsEstValue', name: t('totalAcceptedShipmentContractsEstValue'), format: formatCurrency },
                    { argumentField: 'tick', valueField: 'totalMoneySavingsFromAvgEstValue', name: t('totalMoneySavingsFromAvgEstValue'), format: formatCurrency },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.BarChart
                  title={i18n.t('common:dashboard.charts.initiator.activity')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentsCount_rfp', name: t('totalShipmentsCount.rfp') },
                    { argumentField: 'tick', valueField: 'totalShipmentsCount_active', name: t('totalShipmentsCount.active') },
                    { argumentField: 'tick', valueField: 'totalShipmentsCount_closed', name: t('totalShipmentsCount.closed') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.FullStackedAreaChart
                  title={i18n.t('common:dashboard.charts.initiator.qualityOfService')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentsCount_completed', name: t('totalShipmentsCount.completed') },
                    { argumentField: 'tick', valueField: 'totalShipmentsCount_dispute', name: t('totalShipmentsCount.dispute') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.StackedBarChart
                  title={i18n.t('common:dashboard.charts.initiator.transportMode')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentsTransportModesCount_air', name: i18n.t('common:fieldValues.shipment.transportMode.air') },
                    { argumentField: 'tick', valueField: 'totalShipmentsTransportModesCount_sea', name: i18n.t('common:fieldValues.shipment.transportMode.sea') },
                    { argumentField: 'tick', valueField: 'totalShipmentsTransportModesCount_land', name: i18n.t('common:fieldValues.shipment.transportMode.land') },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.FullStackedBarChart
                  title={i18n.t('common:dashboard.charts.initiator.shippingTerms')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentsShippingTermsCount_D2D', name: i18n.t(`common:fieldValues.shipment.shippingTerms.D2D`) },
                    { argumentField: 'tick', valueField: 'totalShipmentsShippingTermsCount_P2P', name: i18n.t(`common:fieldValues.shipment.shippingTerms.P2P`) },
                    { argumentField: 'tick', valueField: 'totalShipmentsShippingTermsCount_P2D', name: i18n.t(`common:fieldValues.shipment.shippingTerms.P2D`) },
                    { argumentField: 'tick', valueField: 'totalShipmentsShippingTermsCount_D2P', name: i18n.t(`common:fieldValues.shipment.shippingTerms.D2P`) },
                  ]}
                />
              </ChartContainer>
              <ChartContainer>
                <chart.FullStackedBarChart
                  title={i18n.t('common:dashboard.charts.initiator.transportType')}
                  height={CHART_HEIGHT}
                  data={mapped}
                  series={[
                    { argumentField: 'tick', valueField: 'totalShipmentsTransportTypesCount_any', name: i18n.t(`common:fieldValues.shipment.transportType.any`) },
                    { argumentField: 'tick', valueField: 'totalShipmentsTransportTypesCount_direct', name: i18n.t(`common:fieldValues.shipment.transportType.direct`) },
                    { argumentField: 'tick', valueField: 'totalShipmentsTransportTypesCount_transshipment', name: i18n.t(`common:fieldValues.shipment.transportType.transshipment`) },
                  ]}
                />
              </ChartContainer>
            </ui.DynamicColumnLayout>
          </DataWrapper>
        </ui.MainContentContainer>
      </ui.Container>
    </> }
 </>;
});

const Main = ({}) => {
  const [ summary, setSummary ] = React.useState(null);

  React.useEffect(() => {
    if (summary !== null) return;

    api.queryOrgUnitSummary().then(response => {
      if (!response.success) return;

      setSummary(response.data);
    });
  }, []);

  const onClickShipmentState = React.useCallback((state) => {
    return () => {
      api.navigateShipmentState(state);
    };
  });

  const onClickShipmentContractState = React.useCallback((state) => {
    return () => {
      api.navigateShipmentContractState(state);
    };
  });

  if (global.user === null) return <></>;
  if (summary === null) return <></>;
  if (!api.canOrgRead()) return <></>;

  const hasTwo = api.canOrgRead() && api.hasModule('initiator') && api.hasModule('forwarder');
  const gridStyle = {};

  if (!hasTwo) {
    gridStyle.gridTemplateColumns = 'auto';
  }

  return <>
    { api.hasModule('initiator') && <GlobalNavControl>
      <action.CreateNewShipment id="global-nav-create-shipment" className="block-vsep-50" />
    </GlobalNavControl> }
    <GlobalPortalControl anchor="breadcrumbs">
      <ui.Breadcrumbs location="dashboard" />
    </GlobalPortalControl>
    <Box 
      display="grid"
      gridTemplateRows="min-content auto"
      alignItems="stretch"
      justifyContent="stretch"
      p={1}
    >
      <div className={styles.gaugesContainer}>
        { api.hasModule('initiator') && <>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalShipmentsCount.rfp')} variant="outlined" onClick={onClickShipmentState('rfp')}>
            <format.NumericString value={summary.totalShipmentsCount.rfp || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalShipmentsCount.active')} variant="outlined" onClick={onClickShipmentState('active')}>
            <format.NumericString value={summary.totalShipmentsCount.active || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalShipmentsCount.completed')} variant="outlined" onClick={onClickShipmentState('completed')}>
            <format.NumericString value={summary.totalShipmentsCount.completed || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalAcceptedShipmentContractsEstValue')} variant="outlined" onClick={onClickShipmentState('active')}>
            <format.Money value={summary.totalAcceptedShipmentContractsEstValue} format="0,0" showCurrencyCode={false} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalMoneySavingsFromAvgEstValue')} variant="outlined" onClick={onClickShipmentState('active')}>
            <format.Money value={summary.totalMoneySavingsFromAvgEstValue} format="0,0" showCurrencyCode={false} />
          </Gauge>
        </> }
        { api.hasModule('forwarder') && <>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalIncomingCount.rfp')} variant="outlined" onClick={onClickShipmentState('marketplace')}>
            <format.NumericString value={summary.totalIncomingCount.rfp || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalShipmentContractsCount.bid')} variant="outlined" onClick={onClickShipmentContractState('bid')}>
            <format.NumericString value={summary.totalShipmentContractsCount.bid || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalShipmentContractsCount.contract')} variant="outlined" onClick={onClickShipmentContractState('contract')}>
            <format.NumericString value={summary.totalShipmentContractsCount.contract || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalShipmentContractsCount.dispute')} variant="outlined" onClick={onClickShipmentContractState('dispute')}>
            <format.NumericString value={summary.totalShipmentContractsCount.dispute || 0} />
          </Gauge>
          <Gauge label={i18n.t('common:dashboard.orgUnit.summary.totalWonShipmentContractsEstValue')} variant="outlined" onClick={onClickShipmentContractState('contract')}>
            <format.Money value={summary.totalWonShipmentContractsEstValue} format="0,0" showCurrencyCode={false} />
          </Gauge>
        </> }
      </div>
      <Box mt={2} style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'stretch' }}>
        <div className={styles.tablesContainer} style={gridStyle}>
          <HistoricData />
          {/*
          { api.canOrgRead() && api.hasModule('initiator') && <>
            <ui.Container autoSize variant="output" title={i18n.t('common:dashboard.orgUnit.summary.totalShipmentsCount.active')} contentClassName={styles.tableContainer}>
              <ShipmentsList state="active" hideChat={true} dashboard={true} elevation={0} />
            </ui.Container>
          </> }
          { api.canOrgRead() && api.hasModule('forwarder') && <>
            <ui.Container autoSize variant="output" title={i18n.t('common:dashboard.orgUnit.summary.totalShipmentContractsCount.contract')} contentClassName={styles.tableContainer}>
              <ShipmentContractsList state="contract" hideChat={true} dashboard={true} elevation={0} />
            </ui.Container>
          </> }
          */}
        </div>
      </Box>
    </Box>
  </>;
};

export default Main;
